import { Button, Icons, Input, Modal, Select, Tag } from '@pankod/refine-antd'
import { useOne } from '@pankod/refine-core'
import { useState } from 'react'
import React from 'react'

import { TDistrictData, TProvinceData } from '../types'

type ModalFilterLevelProps = {
  administrativeLevel: TProvinceData[]
  programId?: string
  onApply: (value: any) => void
  onReset: () => void
}

const { SearchOutlined } = Icons

export const FILTER_STEPS = [
  {
    title: 'Provinsi',
    key: 'province',
    payload: 'kodeProvinsi',
  },
  {
    title: 'Kota / Kabupaten',
    key: 'city',
    payload: 'kodeKabupaten',
  },
  {
    title: 'Kecamatan',
    key: 'district',
    payload: 'kodeKecamatan',
  },
]

export const ModalFilterLevel = (props: ModalFilterLevelProps) => {
  const { administrativeLevel, onApply, onReset } = props

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [currentFilterStep, setCurrentFilterStep] = useState<string>('province')
  const [selectedType, setSelectedType] = useState<string>()
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [filterState, setFilterState] = useState<any>({
    province: null,
    city: null,
    district: null,
  })
  const [selectedFilterOptions, setSelectedFilterOptions] = useState<any[]>()

  const [isFilterLocked, setIsFilterLocked] = useState({
    type: false,
    province: false,
    city: false,
    district: false,
  })

  const { data: districtOptions } = useOne<TDistrictData>({
    resource: `programs/participants/filter-options`,
    id: `kecamatan?kodeKabupaten=${filterState.city}`,
    dataProviderName: 'lms',
    queryOptions: {
      enabled: filterState.city !== null && selectedType === 'Kecamatan',
    },
  })

  const currentFilterSteps = React.useMemo(() => {
    const filterSteps = [...FILTER_STEPS]
    const currentStepIndex = filterSteps.findIndex(
      (step) => step.title === selectedType
    )
    // remove all steps after current step
    filterSteps.splice(currentStepIndex + 1)

    return filterSteps
  }, [selectedType])

  const currentFilterStepIndex = currentFilterSteps.findIndex(
    (step) => step.key === currentFilterStep
  )

  const getCityFilterOptions = React.useCallback(() => {
    return administrativeLevel
      .filter((level) => level.kode === filterState.province)
      .map((level) => {
        return level.kabupaten
      })
      .flat()
  }, [administrativeLevel, filterState.province])

  const getLevelNameById = React.useCallback(
    ({ key, id }: { key: string; id: string }) => {
      const getProvinceNameById = (id: string) => {
        return administrativeLevel.find((level) => level.kode === id)?.name
      }

      const getKecamatanNameById = (id: string) => {
        return districtOptions?.data?.kecamatan.find(
          (kecamatan) => kecamatan.kode === id
        )?.name
      }

      const getCityNameById = (id: string) => {
        return getCityFilterOptions().find((city) => city.kode === id)?.name
      }

      const getFilterNameById = (key: string, id: string) => {
        switch (key) {
          case 'province':
            return getProvinceNameById(id)
          case 'city':
            return getCityNameById(id)
          case 'district':
            return getKecamatanNameById(id)
          default:
            return ''
        }
      }

      return getFilterNameById(key, id)
    },
    [administrativeLevel, districtOptions, getCityFilterOptions]
  )

  const handleMutateFilterState = ({
    type,
    id,
  }: {
    type: string
    id: string
  }) => {
    const isMultiple = selectedType === currentFilterStepLabel

    if (!isMultiple) {
      setFilterState({
        ...filterState,
        [type]: id,
      })
    } else {
      const currentFilterValue = filterState[type] || []
      const isAlreadySelected = currentFilterValue.includes(id)
      let newFilterState = { ...filterState }

      if (isAlreadySelected) {
        newFilterState = {
          ...filterState,
          [type]: currentFilterValue.filter((item: string) => item !== id),
        }
      } else {
        newFilterState = {
          ...filterState,
          [type]: [...currentFilterValue, id],
        }
      }

      setFilterState(newFilterState)
      setSelectedFilterOptions(generateSelectedAsOptions(newFilterState))
    }
  }

  const resetFilterState = () => {
    setFilterState({
      province: null,
      city: null,
      district: null,
    })
  }

  const handleSelectFilterType = (value: string) => {
    setSelectedType(value)
    resetFilterState()
    setIsFilterLocked({
      ...isFilterLocked,
      type: true,
    })
  }

  const handleResetFilter = () => {
    setSearchQuery('')
    setSelectedType(undefined)
    resetFilterState()
    setIsFilterLocked({
      type: false,
      province: false,
      city: false,
      district: false,
    })
    setCurrentFilterStep('province')
    setSelectedFilterOptions([])
    onApply({
      kodeProvinsi: null,
      kodeKotaKabupaten: null,
      kodeKecamatan: null,
    })
  }

  const getNextFilterStep = () => {
    return currentFilterSteps[currentFilterStepIndex + 1] || null
  }

  const handleNextFilterStep = () => {
    const nextFilterStep = getNextFilterStep()
    setSearchQuery('')

    if (nextFilterStep) {
      setIsFilterLocked({
        ...isFilterLocked,
        [currentFilterStep]: true,
      })
      setCurrentFilterStep(nextFilterStep.key)
    }
  }

  const currentFilterStepLabel = React.useMemo(() => {
    return currentFilterSteps[currentFilterStepIndex]?.title || null
  }, [currentFilterSteps, currentFilterStepIndex])

  const isMatchSearchQuery = ({
    type,
    id,
    name,
  }: {
    type: string
    id: string
    name: string
  }) => {
    if (!searchQuery) {
      return true
    }

    const isMultiple = selectedType === currentFilterStepLabel

    if (isMultiple) {
      return (
        name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        filterState[type]?.includes(id)
      )
    }

    return (
      name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      filterState[type] === id
    )
  }

  const isActive = ({ type, id }: { type: string; id: string }) => {
    const isMultiple = selectedType === currentFilterStepLabel

    if (isMultiple) {
      return filterState[type]?.includes(id)
    }

    return filterState[type] === id
  }

  const handleBackFilterStep = () => {
    if (currentFilterStepIndex > 0) {
      setCurrentFilterStep(currentFilterSteps[currentFilterStepIndex - 1].key)
    }
  }

  const generateSelectedAsOptions = (filterState: any) => {
    const prevSelectedFilterOptions = [...(selectedFilterOptions || [])]

    const getLabelInPrevSelectedFilterOptions = (id: string) => {
      return prevSelectedFilterOptions.find((option) => option.id === id)?.label
    }

    const newFilterOptions: any = []

    Object.keys(filterState).forEach((key) => {
      if (filterState[key]) {
        if (Array.isArray(filterState[key])) {
          filterState[key].forEach((id: string) => {
            newFilterOptions.push({
              id,
              label:
                getLevelNameById({ key, id }) ||
                getLabelInPrevSelectedFilterOptions(id),
            })
          })
        }
      }
    })

    return newFilterOptions
  }

  const handleApplyFilter = () => {
    const currentFilterState = { ...filterState }

    Object.keys(currentFilterState).forEach((key) => {
      if (!Array.isArray(currentFilterState[key])) {
        currentFilterState[key] = null
      }
    })

    onApply({
      kodeProvinsi: currentFilterState.province,
      kodeKabupaten: currentFilterState.city,
      kodeKecamatan: currentFilterState.district,
    })
    setIsModalVisible(false)
  }

  const handleOpenModal = () => {
    if (!!selectedFilterOptions?.length) {
      setCurrentFilterStep('province')
    }

    setIsModalVisible(true)
  }

  const triggerReset = () => {
    handleResetFilter()
    onReset()
  }

  const handleRemoveSelectedFilter = (id: string) => {
    // remove id from filterState
    const currentFilterState = { ...filterState }

    Object.keys(currentFilterState).forEach((key) => {
      if (currentFilterState[key] && currentFilterState[key]?.includes(id)) {
        if (typeof currentFilterState[key] === 'string') {
          currentFilterState[key] = null
          return
        }

        currentFilterState[key] = currentFilterState[key]?.filter(
          (item: string) => item !== id
        )
      }
    })

    setFilterState(currentFilterState)
    const seletedRegion = generateSelectedAsOptions(currentFilterState)

    setSelectedFilterOptions(seletedRegion)

    if (!seletedRegion?.length) {
      handleResetFilter()
    }

    Object.keys(currentFilterState).forEach((key) => {
      if (!Array.isArray(currentFilterState[key])) {
        currentFilterState[key] = null
      }
    })

    onApply({
      kodeProvinsi: currentFilterState.province,
      kodeKabupaten: currentFilterState.city,
      kodeKecamatan: currentFilterState.district,
    })
  }

  return (
    <div>
      {!!selectedFilterOptions?.length && (
        <div>
          <p className="text-xs mb-0">Tipe Filter</p>
          <p className="text-xs font-bold">{selectedType}</p>
          <div className="flex flex-row flex-wrap">
            {selectedFilterOptions.map((item: any) => (
              <Tag
                key={item.id}
                closable
                onClose={() => handleRemoveSelectedFilter(item.id)}
                className="mb-2 mr-2"
                color="blue"
                style={{ marginBottom: '8px' }}
              >
                {item.label}
              </Tag>
            ))}
          </div>
        </div>
      )}

      <p
        onClick={() => handleOpenModal()}
        className="cursor-pointer text-bold text-xs text-blue-500 mt-4"
      >
        {!!selectedFilterOptions?.length
          ? `Tambah ${selectedType}`
          : 'Pilih Daerah'}
      </p>

      {!!selectedFilterOptions?.length && (
        <p
          onClick={() => triggerReset()}
          className="cursor-pointer text-bold text-xs text-blue-500 mt-4 mb-0"
        >
          Reset Filter
        </p>
      )}
      <Modal
        title="Filter Level Administrasi"
        visible={isModalVisible}
        footer={false}
        onCancel={() => setIsModalVisible(false)}
      >
        {/* choose filter type */}

        <div className="flex flex-row items-center">
          {!isFilterLocked.type ? (
            <div>
              <div className="text-sm font-bold mb-2">Pilih Jenis Filter</div>
              <Select
                onChange={(_value, option) => {
                  // @ts-ignore
                  handleSelectFilterType(option.children)
                }}
                className="w-[20vw]"
              >
                {FILTER_STEPS.map((step) => {
                  return (
                    <Select.Option value={step.key} key={step.key}>
                      {step.title}
                    </Select.Option>
                  )
                })}
              </Select>
            </div>
          ) : (
            <div className="flex flex-row justify-between">
              <div className="mr-4">
                <p className="text-xs">Tipe Filter</p>
                <p className="text-sm font-bold">{selectedType}</p>
              </div>
              {isFilterLocked.province && filterState.province && (
                <div className="mr-4">
                  <p className="text-xs">Provinsi</p>
                  <p className="text-sm font-bold">
                    {getLevelNameById({
                      key: 'province',
                      id: filterState.province as string,
                    })}
                  </p>
                </div>
              )}
              {isFilterLocked.city && filterState.city && (
                <div>
                  <p className="text-xs">Kota/Kabupaten</p>
                  <p className="text-sm font-bold">
                    {getLevelNameById({
                      key: 'city',
                      id: filterState.city as string,
                    })}
                  </p>
                </div>
              )}
            </div>
          )}
        </div>

        {isFilterLocked.type && (
          <div>
            <hr className="mb-4" />
            <p className="text-xs">
              Pilih {selectedType}{' '}
              {currentFilterStepLabel !== selectedType &&
                `di ${currentFilterStepLabel}`}
            </p>
            <Input
              prefix={<SearchOutlined className="mr-4" />}
              placeholder={`Cari ${currentFilterStepLabel}`}
              onChange={(e) => {
                setSearchQuery(e.target.value)
              }}
            />

            <div className="py-4 border border-subdued rounded h-[300px] overflow-y-auto">
              {currentFilterStep === 'province' &&
                administrativeLevel.map((level) => {
                  if (
                    isMatchSearchQuery({
                      type: 'province',
                      id: level.kode,
                      name: level.name,
                    })
                  ) {
                    return (
                      <Tag
                        key={level.kode}
                        icon={
                          isActive({
                            type: 'province',
                            id: level.kode,
                          }) && <Icons.CheckOutlined />
                        }
                        color={
                          (isActive({
                            type: 'province',
                            id: level.kode,
                          }) &&
                            'blue') ||
                          'default'
                        }
                        onClick={() => {
                          handleMutateFilterState({
                            type: 'province',
                            id: level.kode,
                          })
                        }}
                        style={{
                          marginBottom: '8px',
                        }}
                      >
                        {level.name}
                      </Tag>
                    )
                  }
                })}

              {currentFilterStep === 'city' &&
                getCityFilterOptions().map((city) => {
                  if (
                    isMatchSearchQuery({
                      type: 'city',
                      id: city.kode,
                      name: city.name,
                    })
                  ) {
                    return (
                      <Tag
                        key={city.kode}
                        icon={
                          isActive({
                            type: 'city',
                            id: city.kode,
                          }) && <Icons.CheckOutlined />
                        }
                        color={
                          (isActive({
                            type: 'city',
                            id: city.kode,
                          }) &&
                            'blue') ||
                          'default'
                        }
                        onClick={() => {
                          handleMutateFilterState({
                            type: 'city',
                            id: city.kode,
                          })
                        }}
                        style={{ cursor: 'pointer', marginBottom: '8px' }}
                      >
                        {city.name}
                      </Tag>
                    )
                  }
                })}

              {currentFilterStep === 'district' &&
                districtOptions &&
                districtOptions?.data?.kecamatan.map((district) => {
                  if (
                    isMatchSearchQuery({
                      type: 'district',
                      id: district.kode,
                      name: district.name,
                    })
                  ) {
                    return (
                      <Tag
                        key={district.kode}
                        icon={
                          isActive({
                            type: 'district',
                            id: district.kode,
                          }) && <Icons.CheckOutlined />
                        }
                        color={
                          (isActive({
                            type: 'district',
                            id: district.kode,
                          }) &&
                            'blue') ||
                          'default'
                        }
                        onClick={() => {
                          handleMutateFilterState({
                            type: 'district',
                            id: district.kode,
                          })
                        }}
                        style={{ cursor: 'pointer', marginBottom: '8px' }}
                      >
                        {district.name}
                      </Tag>
                    )
                  }
                })}
            </div>

            <div>
              {/* apply filter footer */}
              <div className="flex flex-row justify-between">
                {/* button back step */}
                {currentFilterStep !== 'type' && currentFilterStepIndex > 0 && (
                  <Button
                    type="text"
                    onClick={() => {
                      handleBackFilterStep()
                    }}
                  >
                    Kembali
                  </Button>
                )}

                {!selectedFilterOptions?.length &&
                currentFilterStepIndex === 0 ? (
                  <Button
                    type="text"
                    onClick={() => {
                      handleResetFilter()
                    }}
                  >
                    Reset
                  </Button>
                ) : (
                  <div />
                )}

                {filterState[currentFilterStep] && (
                  <>
                    {getNextFilterStep() ? (
                      <Button
                        type="primary"
                        onClick={() => {
                          handleNextFilterStep()
                        }}
                      >
                        Pilih {currentFilterStepLabel}
                      </Button>
                    ) : (
                      <Button
                        type="primary"
                        onClick={() => handleApplyFilter()}
                      >
                        Terapkan
                      </Button>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        )}
      </Modal>
    </div>
  )
}
