import React from 'react'
import { useNode, Node } from '@craftjs/core'
import { Form } from '@pankod/refine-antd'
import clsx from 'clsx'
import parse from 'html-react-parser'
import { Text as LibraryText } from '@instructure/ui-text'

import ContentForm from '@components/ContentBank/ContentForm'
import { TextCustomProps, TText, WIDGET_NAME } from '../../schema'
import {
  getWidgetSettingsFields,
  mappedWidgetSettingsError,
  widgetSettingsOnValueChange,
} from '../../utils'

export const defaultProps: TText = {
  text: '<p>[Lorem <b>ipsum dolor</b> sit <u>amet</u>, consectetur <i>adipiscing elit</i>. In a rutrum nibh, eget sodales est.]</p>',
}

const Text = ({ text }: TText) => {
  const {
    connectors: { connect, drag },
  } = useNode()

  const renderedText = parse(text)

  return (
    <div
      ref={(ref) => connect(drag(ref!))}
      className={clsx(
        'simple-wysiwyg-content',
        'widget-component',
        'widget-component--text',
        'text-black',
        'mb-2 last:mb-0'
      )}
    >
      <LibraryText as="div">{renderedText}</LibraryText>
    </div>
  )
}

const TextSettings = () => {
  const {
    actions: { setProp, setCustom },
    props,
    custom,
  } = useNode((node) => ({
    props: node.data.props,
    custom: node.data.custom,
  }))
  const errors = mappedWidgetSettingsError(custom.errors)
  return (
    <Form
      onValuesChange={(changedValue) =>
        widgetSettingsOnValueChange(changedValue, custom, setCustom)
      }
      fields={getWidgetSettingsFields(props)}
      layout="vertical"
    >
      <Form.Item
        label="Text"
        name="text"
        validateStatus={errors['text'] && 'error'}
        help={errors['text']}
      >
        <ContentForm
          index="widgetText"
          showDeleteButton={false}
          showIndentation={false}
          showUploader={false}
          showTitle={false}
          showList={false}
          value={props.text}
          onChange={(text) => setProp((props: TText) => (props.text = text))}
        />
      </Form.Item>
    </Form>
  )
}

Text.craft = {
  rules: {
    canDrop: (targetNode: Node) => {
      return targetNode.data.name === WIDGET_NAME.Container
    },
  },
  props: defaultProps,
  custom: TextCustomProps,
  related: {
    settings: TextSettings,
  },
}

export { Text }
