import {
  Icons,
  List,
  Result,
  useSelect,
  useSimpleList,
} from '@pankod/refine-antd'
import { IResourceComponentsProps, useGetIdentity } from '@pankod/refine-core'
import Head from 'next/head'
import React from 'react'

import { AngkatanLPTKListResponse } from './types'
import { useGetLptkId } from './utils'
import SelectLPTK from './block/SelectLPTK'
import { AngkatanTabList } from './block/AngkatanTabList'

export const AngkatanPPGList: React.FC<IResourceComponentsProps> = ({
  options,
}) => {
  const [lptkId, setLocalLptkId] = useGetLptkId()
  const { data } = useGetIdentity()
  const LMSRole = data?.user?.LMSRole || ''
  const shouldShowSelection = ['super-admin', 'admin-pusat'].includes(LMSRole)

  const { selectProps, queryResult } = useSelect<{ name: string; id: number }>({
    resource: 'lptk',
    filters: [
      {
        field: 'pageSize',
        operator: 'eq',
        value: -1,
      },
    ],
    optionLabel: 'name',
    optionValue: 'id',
    queryOptions: {
      enabled: shouldShowSelection,
    },
    defaultValueQueryOptions: {
      enabled: false,
    },
  })

  const { listProps, queryResult: listQueryResult } =
    useSimpleList<AngkatanLPTKListResponse>({
      resource: `lptk/${lptkId}/angkatan`,
      queryOptions: {
        enabled: !!lptkId,
      },
      initialPageSize: -1,
    })

  const selectLoading = queryResult.isFetching || listQueryResult.isFetching
  return (
    <List
      title="Daftar Angkatan PPG"
      headerButtons={() =>
        shouldShowSelection &&
        lptkId && (
          <SelectLPTK
            loading={selectLoading}
            onChange={setLocalLptkId}
            selectProps={selectProps}
            value={lptkId}
          />
        )
      }
    >
      <Head>
        <title>LMS Admin | {options?.label} </title>
      </Head>
      {!lptkId && (
        <Result
          icon={
            shouldShowSelection ? (
              <Icons.BankTwoTone />
            ) : (
              <Icons.WarningTwoTone twoToneColor="#faad14" />
            )
          }
          title={
            shouldShowSelection
              ? 'Harap pilih LPTK untuk melihat daftar Angkatan'
              : 'Anda belum terdaftar ke dalam LPTK, harap hubungi administrator'
          }
          extra={
            shouldShowSelection ? (
              <SelectLPTK
                loading={selectLoading}
                onChange={setLocalLptkId}
                selectProps={selectProps}
              />
            ) : null
          }
        />
      )}

      {lptkId && (
        <AngkatanTabList
          listProps={listProps}
          isLoading={listQueryResult.isFetching}
          lptkId={lptkId}
        />
      )}
    </List>
  )
}
