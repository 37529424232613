import { Button, RcFile, Tabs, Tooltip } from '@pankod/refine-antd'
import { Canvas } from 'fabric'
import React, { useState } from 'react'
import { BookCheck, HardDriveDownload, Save } from 'lucide-react'

import CommonComponents from './Common'
import {
  CertificateTemplateVariables,
  UserVariable,
} from '@resources/lms-management/certificate-template/types'
import ActorComponents from './Actor'
import CustomComponents from './Custom'

type MainToolbar = {
  activeTab: 'front' | 'back'
  canvas: Canvas | null
  onAddTextbox: () => void
  onAddImage: (file: RcFile) => void
  onExportSVG: () => void
  variables?: CertificateTemplateVariables
  onCreateVariable: (data: UserVariable) => Promise<any>
  onDeleteVariable: (key: string) => Promise<any>
  onSaveClick: () => Promise<any>
  onPublishClick: () => Promise<any>
  onExportClick: () => Promise<any>
}

const MainToolbar = ({
  activeTab,
  canvas,
  onAddImage,
  onAddTextbox,
  onExportSVG,
  variables,
  onCreateVariable,
  onDeleteVariable,
  onPublishClick,
  onSaveClick,
  onExportClick,
}: MainToolbar) => {
  const [state, setState] = useState<'idle' | 'publishing' | 'saving'>('idle')
  return (
    <div className="absolute right-8 top-24 z-10 w-[250px] flex flex-col gap-2 select-none">
      <div className="rounded bg-white shadow p-2">
        <Tabs size="small" centered>
          <Tabs.TabPane
            className="h-[300px]"
            key="common"
            tab="General"
            tabKey="common"
          >
            <CommonComponents
              canvas={canvas}
              onAddImage={onAddImage}
              onAddTextbox={onAddTextbox}
              onExportSVG={onExportSVG}
            />
          </Tabs.TabPane>
          <Tabs.TabPane
            className="h-[300px]"
            key="actor"
            tab="Aktor"
            tabKey="actor"
          >
            <ActorComponents
              canvas={canvas}
              variable={variables?.systemVariable || []}
            />
          </Tabs.TabPane>
          <Tabs.TabPane
            className="h-[300px]"
            key="custom"
            tab="Custom"
            tabKey="custom"
          >
            <CustomComponents
              canvas={canvas}
              variable={variables?.userVariable || []}
              onCreateVariable={onCreateVariable}
              onDeleteVariable={onDeleteVariable}
            />
          </Tabs.TabPane>
        </Tabs>
      </div>
      <div className="rounded bg-white shadow p-2 flex flex-col gap-2">
        <Button.Group>
          <Button
            icon={<Save className="h-5 w-5 mr-0.5" />}
            loading={state === 'saving'}
            className="!flex gap-2 items-center justify-start"
            onClick={async () => {
              setState('saving')
              await onSaveClick()
              setState('idle')
            }}
            block
          >
            Simpan
            <div className="-ml-1 text-xs text-ellipsis overflow-hidden">
              {activeTab === 'front' ? 'Hlm. Depan' : 'Hlm. Belakang'}
            </div>
          </Button>
          <Tooltip title="Unduh template (*.svg)">
            <Button
              icon={<HardDriveDownload className="h-5 w-5" />}
              className="!flex gap-2 items-center justify-center !w-10"
              onClick={() => onExportClick()}
            />
          </Tooltip>
        </Button.Group>
        <Button
          icon={<BookCheck className="h-5 w-5" />}
          className="!flex gap-2 items-center justify-center"
          block
          type="primary"
          loading={state === 'publishing'}
          onClick={async () => {
            setState('publishing')
            await onPublishClick()
            setState('idle')
          }}
        >
          Publish Template
        </Button>
      </div>
    </div>
  )
}

export default MainToolbar
