import { Modal, Form, Input, ModalProps } from '@pankod/refine-antd'

import { SelectBidangStudyOptions } from '../SelectLPTKDataOptions'
import { phone } from 'src/helpers/validator'

export type ModalFormAdminKelasProps = {
  modalProps: ModalProps
  formProps: any
  onSubmit?: () => void
  mode: 'create' | 'edit'
}

export const ModalFormAdminKelas = (props: ModalFormAdminKelasProps) => {
  const { modalProps, formProps, mode, onSubmit } = props

  return (
    <Modal
      {...modalProps}
      title={mode === 'create' ? 'Tambah Admin Kelas Baru' : 'Edit Admin Kelas'}
      okText="Simpan"
      cancelText="Kembali"
      {...(onSubmit && {
        okButtonProps: {
          onClick: async () => {
            const data = await onSubmit()
            formProps.onFinish(data)
          },
        },
      })}
    >
      <Form
        {...formProps}
        {...(mode === 'edit' && {
          initialValues: {
            ...formProps.initialValues?.data,
          },
        })}
        layout="vertical"
        validateMessages={{ required: '${label} must be filled.' }}
      >
        <Form.Item
          label="Name"
          name="name"
          required
          rules={[{ required: true }]}
        >
          <Input autoComplete="off" />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          required
          rules={[
            { required: true },
            { type: 'email', message: '${label} format is invalid.' },
          ]}
        >
          <Input autoComplete="off" disabled={mode === 'edit'} />
        </Form.Item>
        <Form.Item
          label="No HP"
          name="phone"
          required
          rules={[
            { required: true },
            {
              validator: (_, value) => phone(value),
              message: '${label} format is invalid.',
            },
          ]}
        >
          <Input autoComplete="off" />
        </Form.Item>
        <Form.Item
          label="bidang studi"
          name="studyField"
          required
          rules={[{ required: true }]}
        >
          <SelectBidangStudyOptions visible={modalProps.visible} />
        </Form.Item>
      </Form>
    </Modal>
  )
}
