import {
  Form,
  FormProps,
  Icons,
  Input,
  InputNumber,
  Modal,
  ModalProps,
  Select,
  useSelect,
} from '@pankod/refine-antd'
import React from 'react'

type ModalFormProps = {
  modalProps: ModalProps
  formProps: FormProps
  onClose: () => void
  programId: string | string[]
}
export const ModalForm = ({
  formProps,
  modalProps,
  onClose,
  programId,
}: ModalFormProps) => {
  const { selectProps: selectPaketModulProps } = useSelect({
    dataProviderName: 'lms',
    resource: `programs/${programId}/paket-modul`,
    optionValue: 'id',
    optionLabel: 'name',
    queryOptions: {
      enabled: modalProps.visible,
      select({ data }) {
        return {
          data,
          total: data.length,
        }
      },
    },
    filters: [
      {
        field: 'pageSize',
        operator: 'eq',
        value: '-1',
      },
    ],
    defaultValueQueryOptions: {
      enabled: false,
    },
  })
  return (
    <Modal
      {...modalProps}
      onCancel={onClose}
      title={'Buat Modul'}
      cancelText="Batal"
      okText="Simpan"
      afterClose={() => formProps.form?.resetFields()}
      bodyStyle={{ maxHeight: 700, overflow: 'auto' }}
    >
      <Form
        {...formProps}
        initialValues={formProps?.initialValues?.data}
        layout="vertical"
        validateMessages={{ required: '${label} harus diisi.' }}
      >
        <Form.Item
          label="Nama Modul"
          name="name"
          required
          rules={[{ required: true }]}
        >
          <Input autoComplete="off" />
        </Form.Item>
        <Form.Item
          label="Paket Modul"
          name="programModuleGroupId"
          required
          rules={[{ required: true }]}
        >
          <Select
            {...selectPaketModulProps}
            onSearch={() => {}}
            filterOption={(input, option) =>
              (option!.label as unknown as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          />
        </Form.Item>
        <Form.Item
          label="Prioritas Urutan"
          name="sortPriority"
          required
          initialValue={1}
          rules={[
            { required: true },
            {
              validator: (_, value) => {
                if (value <= 0)
                  return Promise.reject('Prioritas Urutan harus lebih dari 0.')
                return Promise.resolve()
              },
            },
          ]}
        >
          <InputNumber
            autoComplete="false"
            addonBefore={<Icons.SortAscendingOutlined />}
            min={1}
            controls={false}
            precision={0}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}
