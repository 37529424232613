export interface MatrikulasiStatus {
  color: 'red' | 'green'
  text: string
}

export const MATRIKULASI_STATUS_MAP: { [key: string]: MatrikulasiStatus } = {
  MATRIKULASI_KOSONG: {
    color: 'red',
    text: 'BELUM SELESAI',
  },
  SELESAI: {
    color: 'green',
    text: 'SELESAI',
  },
  BELUM_SELESAI: {
    color: 'red',
    text: 'BELUM SELESAI',
  },
}

export const getMatrikulasiStatus = (key: string): MatrikulasiStatus => {
  return (
    MATRIKULASI_STATUS_MAP[key] || {
      color: 'danger',
      text: 'BELUM SELESAI',
    }
  )
}

export const getRegisteredMatrikulasiStatus = (val: boolean) => {
  if (val) {
    return {
      color: 'green',
      text: 'TERDAFTAR',
    }
  }

  return {
    color: 'red',
    text: 'TIDAK TERDAFTAR',
  }
}
