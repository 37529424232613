import { Popconfirm, PopconfirmProps } from '@pankod/refine-antd'
import React from 'react'

type MenuItemPopconfirmProps = PopconfirmProps
const MenuItemPopconfirm = ({
  children,
  ...props
}: MenuItemPopconfirmProps) => {
  return (
    <Popconfirm {...props} zIndex={1060}>
      <div className="before:content-[''] before:absolute before:top-0 before:right-0 before:bottom-0 before:left-0">
        {children}
      </div>
    </Popconfirm>
  )
}

export default MenuItemPopconfirm
