import { Modal, Descriptions, Divider, Table } from '@pankod/refine-antd'
import { useResource } from '@pankod/refine-core'
import { useRouter } from 'next/router'
import React, { useState } from 'react'

import { TLMSClass } from '@resources/lms-management/class/types'
import { kapabilitasTags } from '@components/DataTableParticipants/blocks/utils'
import useTableWithMeta from 'src/hooks/useTableWithMeta'
import { TCommonError } from 'src/interfaces/common'

type ModalAssignUnallocatedParticipantProps = {
  visible: boolean
  onSubmit?: (data: {
    userId: string
    name: string
    email: string
    kelasId: string
  }) => Promise<any>
  onClose?: () => void
  selectedUser?: {
    userId: string
    email: string
    name: string
    kapabilitas: string
  }
}
const ModalAssignUnallocatedParticipant = ({
  visible,
  selectedUser,
  onClose,
  onSubmit,
}: ModalAssignUnallocatedParticipantProps) => {
  const { id: localProgramId } = useResource()
  const router = useRouter()
  const programId = router.query.programId
  const [selectedKelas, setSelectedKelas] = useState<React.Key[]>([])
  const { tableProps } = useTableWithMeta<TLMSClass, TCommonError>({
    resource: `programs/${programId}/local-programs/${localProgramId}/classes`,
    dataProviderName: 'lms',
    queryOptions: {
      enabled: visible,
    },
  })

  const handleClose = () => {
    setSelectedKelas([])
    onClose?.()
  }
  return (
    <Modal
      visible={visible}
      title="Tambah Peserta ke dalam Kelas"
      width={600}
      onCancel={() => handleClose()}
      cancelText="Kembali"
      okText={
        <>
          <i className="mr-1">Assign</i>Peserta
        </>
      }
      okButtonProps={{
        disabled: selectedKelas.length == 0,
      }}
      onOk={async () => {
        if (!selectedUser) return
        await onSubmit?.({
          ...selectedUser,
          kelasId: String(selectedKelas[0]),
        })
        handleClose()
      }}
    >
      <Descriptions title="Detail Peserta" bordered column={1} size="small">
        <Descriptions.Item label="User ID">
          {selectedUser?.userId}
        </Descriptions.Item>
        <Descriptions.Item label="Email Peserta">
          {selectedUser?.email}
        </Descriptions.Item>
        <Descriptions.Item label="Nama Peserta">
          {selectedUser?.name}
        </Descriptions.Item>
        <Descriptions.Item label="Kapabilitas">
          {kapabilitasTags(selectedUser?.kapabilitas)}
        </Descriptions.Item>
      </Descriptions>
      <Divider dashed orientation="left">
        Pilih Kelas
      </Divider>
      <Table
        {...tableProps}
        rowKey={'id'}
        rowSelection={{
          type: 'radio',
          selectedRowKeys: selectedKelas,
          onChange: (selectedKeys) => setSelectedKelas(selectedKeys),
        }}
        columns={[
          { title: 'ID', dataIndex: 'id' },
          { title: 'Nama Kelas', dataIndex: 'name' },
        ]}
      />
    </Modal>
  )
}

export default ModalAssignUnallocatedParticipant
