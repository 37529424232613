import React, { useMemo } from 'react'
import {
  TableColumnType,
  TableColumnGroupType,
  Table,
  Button,
  Icons,
  useModalForm,
  useTable,
  Tag,
} from '@pankod/refine-antd'
import {
  useDelete,
  useGetIdentity,
  useList,
  useResource,
} from '@pankod/refine-core'

import { ParticipantBatchResponse } from '../../types'
import { HeaderTitle } from '@components/HeaderTableTitle'
import { TableTitle } from '@components/TableTitle'
import ModalParseCSV from '@components/ModalParseCSV'
import { TCommonError, TLMSCommonError } from 'src/interfaces/common'
import { PopDeleteConfirm } from '@resources/lms-management/components/PopDeleteConfirm'
import { showErrorNotification } from '@resources/angkatan-ppg-management/utils'
import {
  getMatrikulasiStatus,
  getRegisteredMatrikulasiStatus,
} from '@resources/basic-ppg-management/utils/matrikulasi_status_map'

export const ParticipantTab = () => {
  const resource = useResource()
  const id = resource.id as number
  const { data } = useGetIdentity()
  const LMSRole = data?.user?.LMSRole || ''
  const { tableProps, tableQueryResult } = useTable<
    ParticipantBatchResponse,
    TCommonError
  >({
    dataProviderName: 'lms',
    resource: `angkatan/${id}/peserta`,
  })

  const { modalProps, submit, formProps, show, close } =
    useModalForm<ParticipantBatchResponse>({
      action: 'create',
      resource: `angkatan/${id}/peserta`,
      dataProviderName: 'lms',
      autoResetForm: true,
      redirect: false,
      submit: (formValue) => {
        return {
          data: formValue.data,
        }
      },
      successNotification: {
        message: 'Peserta successfuly uploaded',
        type: 'success',
        description: 'Successful',
      },
      errorNotification: (error) => {
        return {
          description: 'Error',
          type: 'error',
          message:
            (error as TLMSCommonError).response?.data.error.details?.[0]
              ?.message ||
            (error as TLMSCommonError).response?.data.error.message ||
            'There was a problem when uploading Peserta',
        }
      },
    })

  const { mutateAsync: doDelete, isLoading } = useDelete()

  const { data: studyFieldList } = useList({
    resource: 'kv?key=lms_ppg_study_field',
    dataProviderName: 'microlearning',
    queryOptions: {
      enabled: !!modalProps.visible,
    },
  })

  const studyFieldMap = useMemo(() => {
    const { data } = studyFieldList || {}
    const studyFieldMap = new Map()

    data?.forEach((item: any) => {
      studyFieldMap.set(item.label, item.value)
    })

    return studyFieldMap
  }, [studyFieldList])

  const MAHASISWA_STATUS_MAP: any = {
    TERDAFTAR: 'Terdaftar',
    TIDAK_LAPOR_DIRI: 'Tidak Lapor Diri',
    MENGUNDURKAN_DIRI: 'Mengundurkan Diri',
    EMPTY: 'Belum Diketahui',
  }

  const columns: (
    | TableColumnType<ParticipantBatchResponse>
    | TableColumnGroupType<ParticipantBatchResponse>
  )[] = [
    {
      title: <HeaderTitle align="left" title="Nama" />,
      key: 'name',
      dataIndex: 'name',
    },
    {
      title: <HeaderTitle align="left" title="LPTK" />,
      key: 'lptkName',
      dataIndex: 'lptkName',
    },
    {
      title: <HeaderTitle align="left" title="Bidang Studi" />,
      key: 'studyField',
      dataIndex: 'studyField',
    },
    {
      title: <HeaderTitle align="left" title="Email" />,
      key: 'email',
      dataIndex: 'email',
    },
    {
      title: <HeaderTitle align="left" title="Nomor HP" />,
      key: 'phone',
      dataIndex: 'phone',
    },
    {
      title: <HeaderTitle align="left" title="Nomor WA" />,
      key: 'noWa',
      dataIndex: 'noWa',
    },

    {
      title: 'Pendaftaran Matrikulasi',
      key: 'isMatrikulasi',
      dataIndex: 'isMatrikulasi',
      render: (status: boolean) => (
        <Tag color={getRegisteredMatrikulasiStatus(status).color}>
          {getRegisteredMatrikulasiStatus(status).text}
        </Tag>
      ),
    },
    {
      title: 'Status Matrikulasi',
      key: 'matrikulasi',
      dataIndex: 'matrikulasi',
      render: (status: string) => (
        <Tag color={getMatrikulasiStatus(status).color}>
          {getMatrikulasiStatus(status).text}
        </Tag>
      ),
    },
    {
      title: <HeaderTitle align="left" title="Kategori Matrikulasi" />,
      key: 'kategoriMatrikulasi',
      dataIndex: 'kategoriMatrikulasi',
    },
    {
      title: <HeaderTitle align="left" title="Lulusan Matrikulasi" />,
      key: 'lulusanMatrikulasi',
      dataIndex: 'lulusanMatrikulasi',
    },
    {
      title: <HeaderTitle align="left" title="Status" />,
      key: 'status',
      dataIndex: 'status',
      render: (value) => (
        <Tag color="blue">{MAHASISWA_STATUS_MAP[value || 'EMPTY']}</Tag>
      ),
    },
    {
      key: 'action',
      render: (_, record) => (
        <PopDeleteConfirm
          title="Apakah Anda yakin ingin menghapus peserta ini?"
          validateValue={record.email}
          placeholder="Tulis ulang email peserta"
          errorMessage="Email peserta tidak sesuai."
          children={
            <Button danger icon={<Icons.DeleteOutlined />}>
              Hapus
            </Button>
          }
          onConfirm={() => {
            return doDelete({
              id: 'peserta',
              resource: `angkatan/${id}`,
              values: { pesertaId: record.id },
              dataProviderName: 'lms',
              invalidates: ['all'],
              successNotification: {
                message: 'Peserta successfuly removed',
                type: 'success',
                description: 'Successful',
              },
              errorNotification: (err) =>
                showErrorNotification(
                  err,
                  'There was a problem when removing Peserta'
                ),
            })
          }}
          okText="Hapus"
          cancelText="Kembali"
          okButtonProps={{ danger: true, loading: isLoading }}
        />
      ),
    },
  ]

  const csvHeaders = [
    'name',
    'lptkId',
    'studyField',
    'email',
    'phone',
    {
      name: 'kelurahan',
      optional: true,
    },
    {
      name: 'kecamatan',
      optional: true,
    },
    {
      name: 'kotaKabupaten',
      optional: true,
    },
    {
      name: 'provinsi',
      optional: true,
    },
    {
      name: 'rumpun',
      optional: true,
    },
    {
      name: 'nik',
      optional: true,
    },
    {
      name: 'tempatLahir',
      optional: true,
    },
    {
      name: 'tanggalLahir',
      optional: true,
    },
    {
      name: 'alamat',
      optional: true,
    },
    {
      name: 'jenisKelamin',
      optional: true,
    },
    {
      name: 'noWa',
      optional: true,
    },
    'kategoriMatrikulasi',
    'lulusanMatrikulasi',
    {
      name: 'isMatrikulasi',
      optional: true,
    },
  ]

  const userCanAddStatus = ['super-admin', 'admin-pusat'].includes(LMSRole)

  if (userCanAddStatus) {
    // @ts-ignore
    csvHeaders.push({
      name: 'status',
      optional: true,
    })
  }

  const defaultCSVFields = [
    {
      name: 'studyField',
      mapEntry: studyFieldMap,
    },
    {
      name: 'isMatrikulasi',
      entries: ['true', 'false'],
      optional: true,
    },
  ]

  return (
    <>
      <Table
        {...tableProps}
        loading={tableQueryResult.isFetching}
        columns={columns}
        bordered
        rowKey="id"
        title={() => (
          <TableTitle
            title="Daftar Peserta"
            extra={
              <Button
                icon={<Icons.UploadOutlined />}
                type="primary"
                onClick={() => show()}
              >
                Upload CSV Peserta
              </Button>
            }
          />
        )}
      />

      <ModalParseCSV
        formProps={formProps}
        modalProps={modalProps}
        onSubmit={submit}
        onClose={close}
        validCSVHeaders={csvHeaders}
        title="Peserta"
        presetValidate={
          userCanAddStatus
            ? [
                {
                  name: 'status',
                  entries: Object.keys(MAHASISWA_STATUS_MAP).filter(
                    (status) => status !== 'EMPTY'
                  ),
                  optional: true,
                },
                ...defaultCSVFields,
              ]
            : defaultCSVFields
        }
      />
    </>
  )
}
