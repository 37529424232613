import {
  Button,
  Icons,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from '@pankod/refine-antd'
import { useCan, useResource } from '@pankod/refine-core'
import React, { useState } from 'react'

import ModalExport from './ModalExport'
import KategoriPenilaianKinerjaTable from './laporan-perhitungan-hasil-akhir/KategoriPenilaianKinerjaTable'
import PenilaianInstrukturWebinarTable from './penilaian-instruktur-webinar/PenilaianInstrukturWebinarTable'
import { useProgramStore } from '../../store'
import ExportReportButton from '@components/ExportReportButton'

const TabLaporanPenilaianKinerja = () => {
  const { id: programId } = useResource()
  const [showKeterisianExport, setShowKeterisianExport] = useState(false)
  const [showReportExport, setShowReportExport] = useState(false)
  const [activeKey, setActiveKey] = useState('hasil-akhir')
  const { data: canExport } = useCan({
    action: 'field',
    resource: 'penilaian-kinerja-report',
    params: {
      field: 'export',
    },
  })
  const isOwner = useProgramStore(({ isOwner }) => isOwner)
  return (
    <div>
      <Tabs
        tabPosition="left"
        className="!mt-4"
        size="small"
        activeKey={activeKey}
        onChange={setActiveKey}
        destroyInactiveTabPane
        tabBarExtraContent={
          (canExport?.can || isOwner) && (
            <Button
              type="dashed"
              icon={<Icons.ExportOutlined />}
              onClick={() => setShowKeterisianExport(true)}
              title=""
              size="small"
              className="mt-4"
            >
              Ekspor Keterisian
            </Button>
          )
        }
        tabBarStyle={{ width: 200 }}
      >
        <Tabs.TabPane
          tab={
            <Tooltip placement="right" title="Laporan Perhitungan Hasil Akhir">
              <TextField
                className="ant-tabs-tab-btn w-[150px]"
                value="Laporan Perhitungan Hasil Akhir"
                ellipsis
              />
            </Tooltip>
          }
          key="hasil-akhir"
        >
          <div className="flex gap-3 mb-3">
            <Typography.Title level={5}>
              Daftar Nilai Berdasarkan Kategori Penilaian Kinerja
            </Typography.Title>
            {(canExport?.can || isOwner) && (
              <Button
                type="dashed"
                icon={<Icons.ExportOutlined />}
                onClick={() => setShowReportExport(true)}
                size="small"
              >
                Ekspor Laporan Kinerja
              </Button>
            )}
          </div>
          <KategoriPenilaianKinerjaTable />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={
            <Tooltip
              placement="right"
              title="Penilaian Kinerja Instruktur Webinar"
            >
              <TextField
                className="ant-tabs-tab-btn w-[150px]"
                value="Penilaian Kinerja Instruktur Webinar"
                ellipsis
              />
            </Tooltip>
          }
          key="aktor"
        >
          <div className="flex gap-3 mb-3">
            <Typography.Title level={5}>
              Daftar Nilai Instruktur Webinar
            </Typography.Title>
            <ExportReportButton
              can={canExport?.can || isOwner}
              fileName={`PenilaianInstrukturWebinar_ProgramId_${programId}`}
              url={`${process.env.NEXT_PUBLIC_LMS_API_URL}/programs/${programId}/penilaian-webinar/export`}
              size="small"
              title="Ekspor Laporan Penilaian"
            />
          </div>
          <PenilaianInstrukturWebinarTable />
        </Tabs.TabPane>
      </Tabs>
      <ModalExport
        show={showKeterisianExport}
        onClose={() => {
          setShowKeterisianExport(false)
        }}
        type="keterisian"
      />
      <ModalExport
        show={showReportExport}
        onClose={() => {
          setShowReportExport(false)
        }}
        type="report"
      />
    </div>
  )
}

export default TabLaporanPenilaianKinerja
