import { create } from 'zustand'

export const useProgramStore = create<{
  entityId: string | null
  hasEntity: boolean
  isOwner: boolean
  setProgram: (param: { isOwner: boolean; entityId?: string }) => void
}>((set) => ({
  entityId: null,
  hasEntity: false,
  isOwner: false,
  setProgram: ({
    entityId,
    isOwner,
  }: {
    entityId?: string
    isOwner: boolean
  }) =>
    set({
      entityId: Boolean(Number(entityId)) ? entityId : null,
      hasEntity: Boolean(Number(entityId)),
      isOwner,
    }),
}))
