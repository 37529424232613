import {
  Descriptions,
  Empty,
  Divider,
  Collapse,
  Slider,
  Row,
  Col,
  Input,
  InputNumber,
  Button,
} from '@pankod/refine-antd'
import { useCreate, useOne } from '@pankod/refine-core'
import React from 'react'
import { DeleteOutlined } from '@ant-design/icons'

import { TProgramGradeData } from '../../types'

const MAX_GRADE = 100
const DEFAULT_GRADE_CONFIG = [
  {
    label: 'Amat Baik',
    from: 90,
    to: 100,
  },
  {
    label: 'Baik',
    from: 80,
    to: 89,
  },
  {
    label: 'Cukup',
    from: 70,
    to: 79,
  },
  {
    label: 'Tidak Lulus',
    from: 0,
    to: 69,
  },
]

export type GradeRangeFormProps = {
  programId: string | number | null | undefined
  hideSlider?: boolean
  onSubmitted?: () => void
}

export const GradeRangeForm = (props: GradeRangeFormProps) => {
  const { programId, hideSlider = false, onSubmitted } = props
  const { mutate: createGrades } = useCreate()

  const { data, refetch } = useOne<TProgramGradeData>({
    resource: `programs/${programId}`,
    id: 'grades',
    dataProviderName: 'lms',
    queryOptions: {
      enabled: !!programId,
    },
  })

  const [gradeConfig, setGradeConfig] = React.useState(
    data?.data?.data || DEFAULT_GRADE_CONFIG
  )

  const isGradeOverlapping = gradeConfig.some((grade, index) => {
    const nextGrade = gradeConfig[index + 1]
    return nextGrade ? grade.from <= nextGrade.from : false
  })

  const generateSliderMarks = (grades: TProgramGradeData['data']) => {
    const marks: any = {}

    grades.forEach((grade, index) => {
      const color = `hsl(${Math.round(
        (1 - index / gradeConfig.length) * 100
      )}, 100%, 50%)`
      const translateY = index % 2 === 0 ? '-200%' : '40%'
      const isLast = index === gradeConfig.length - 1
      const translateX = isLast ? '0px' : '200px'

      marks[grade.to] = {
        style: {
          color,
          transform: `translate(${translateX}, ${translateY})`,
          minWidth: isLast ? 'auto' : '200px',
          textAlign: 'left',
          fontSize: '8pt',
          cursor: 'default',
        },
        label: (
          <strong>
            {grade.label} ({grade.to} ~ {grade.from})
          </strong>
        ),
      }
    })

    return marks
  }

  const [sliderMarks, setSliderMarks] = React.useState<any>(
    generateSliderMarks(data?.data?.data || DEFAULT_GRADE_CONFIG)
  )

  const handleAddGradeConfig = () => {
    setGradeConfig((prev) => [
      ...prev,
      {
        label: '',
        from: 1,
        to: prev[prev.length - 1].from - 1 || 1,
      },
    ])
  }

  const handleRemoveGradeConfigByLabel = (label: string) => {
    const prevGradeConfig = gradeConfig
    const nextGradeConfig = prevGradeConfig.filter(
      (grade) => grade.label !== label
    )

    setGradeConfig(nextGradeConfig)
    setSliderMarks(generateSliderMarks(nextGradeConfig))
  }
  // value: [80, 89],
  const handleChangeGradeConfig = (
    label: string,
    from: number | null,
    to: number | null
  ) => {
    const prevGradeConfig = gradeConfig
    const nextGradeConfig = prevGradeConfig.map((grade) => {
      if (grade.label === label) {
        return {
          ...grade,
          from: from || grade.from,
          to: to || grade.to,
        }
      }
      return grade
    })

    // change the next grade's to value
    const nextGradeIndex = nextGradeConfig.findIndex(
      (grade) => grade.label === label
    )
    if (nextGradeIndex !== -1) {
      const nextGrade = nextGradeConfig[nextGradeIndex + 1]
      if (nextGrade) {
        nextGradeConfig[nextGradeIndex + 1] = {
          ...nextGrade,
          to: nextGradeConfig[nextGradeIndex].from - 1,
        }
      }
    }

    // set last grade's to value to 0
    const lastGrade = nextGradeConfig[nextGradeConfig.length - 1]
    if (lastGrade) {
      nextGradeConfig[nextGradeConfig.length - 1] = {
        ...lastGrade,
        from: 0,
      }
    }

    setGradeConfig(nextGradeConfig)
    setSliderMarks(generateSliderMarks(nextGradeConfig))
  }

  const handleChangeGradeConfigLabel = (label: string, newLabel: string) => {
    const prevGradeConfig = gradeConfig
    const nextGradeConfig = prevGradeConfig.map((grade) => {
      if (grade.label === label) {
        return {
          ...grade,
          label: newLabel,
        }
      }
      return grade
    })

    setGradeConfig(nextGradeConfig)
    setSliderMarks(generateSliderMarks(nextGradeConfig))
  }

  const handleAppyGradeRange = () => {
    const forceZeroGradeConfig = gradeConfig.map((grade, index) => {
      if (index === gradeConfig.length - 1) {
        return {
          ...grade,
          from: 0,
        }
      }
      return grade
    })

    setGradeConfig(forceZeroGradeConfig)

    createGrades({
      resource: `programs/${programId}/grades`,
      values: {
        data: forceZeroGradeConfig,
      },
      dataProviderName: 'lms',
      successNotification: () => {
        onSubmitted?.()
        refetch()
        return {
          message: 'Success',
          description: 'Grade range has been updated',
          type: 'success',
        }
      },
    })
  }

  React.useEffect(() => {
    const gradeData = data?.data?.data.length
      ? data?.data?.data
      : DEFAULT_GRADE_CONFIG

    setGradeConfig(gradeData)
    setSliderMarks(generateSliderMarks(gradeData))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <>
      {data?.data?.data.length !== 0 ? (
        <Descriptions
          title="Range Nilai Saat ini"
          column={1}
          bordered
          size="small"
        >
          {data?.data?.data.map((grade) => {
            return (
              <Descriptions.Item label={grade.label} key={grade.label}>
                {grade.from} - {grade.to}
              </Descriptions.Item>
            )
          })}
        </Descriptions>
      ) : (
        <Empty description="Tidak ada range nilai"></Empty>
      )}
      <Divider />
      <Collapse
        className="max-h-[600px] overflow-auto"
        defaultActiveKey="general"
      >
        <Collapse.Panel header="Pengaturan Range Nilai" key="general">
          <>
            {!hideSlider && (
              <div className="mt-16 mb-16 z-2">
                <Slider
                  reverse
                  range
                  disabled
                  className="!cursor-default"
                  min={0}
                  max={gradeConfig[0]?.to || MAX_GRADE}
                  value={[0, gradeConfig[0]?.to || MAX_GRADE]}
                  marks={sliderMarks}
                  trackStyle={[
                    {
                      background:
                        'linear-gradient(to right, rgb(132 255 132), rgb(255 176 34), rgb(166 0 0))',
                    },
                  ]}
                  handleStyle={[
                    {
                      cursor: 'default',
                    },
                    {
                      cursor: 'default',
                    },
                  ]}
                  tooltipPlacement="bottom"
                />
              </div>
            )}

            <Row gutter={12}>
              <Col span={5}>
                <span>Grade Label</span>
              </Col>
              <Col span={6}>
                <span>From</span>
              </Col>
              <Col span={8}>
                <span>To (minimum value: 0)</span>
              </Col>
            </Row>

            <Divider style={{ marginTop: 4, marginBottom: 8 }} />

            {gradeConfig.map((grade, index) => (
              <div className="mb-4" key={grade.label}>
                <Input.Group size="large">
                  <Row gutter={12}>
                    <Col span={5}>
                      <Input
                        defaultValue={grade.label}
                        onBlur={(e) => {
                          handleChangeGradeConfigLabel(
                            grade.label,
                            e.target.value
                          )
                        }}
                      />
                    </Col>

                    <Col span={3}>
                      <InputNumber
                        size="large"
                        min={grade.from}
                        max={gradeConfig[index - 1]?.from || 100}
                        value={gradeConfig[index - 1]?.from - 1 || 100}
                        disabled
                      />
                    </Col>
                    <Col
                      span={3}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      ~
                    </Col>

                    <Col span={3}>
                      <InputNumber
                        size="large"
                        max={grade.to}
                        // min={gradeConfig[index + 1]?.to || 0}
                        min={0}
                        disabled={index === gradeConfig.length - 1}
                        value={
                          index === gradeConfig.length - 1 ? 0 : grade.from
                        }
                        onChange={(e) => {
                          handleChangeGradeConfig(
                            grade.label,
                            e,
                            gradeConfig[index - 1]?.from - 1 || 100
                          )
                        }}
                      />
                    </Col>

                    <Col span={1}>
                      <Button
                        className="ml-4"
                        danger
                        size="large"
                        onClick={() =>
                          handleRemoveGradeConfigByLabel(grade.label)
                        }
                      >
                        <DeleteOutlined />
                      </Button>
                    </Col>
                  </Row>
                </Input.Group>
              </div>
            ))}

            <Button onClick={handleAddGradeConfig} type="ghost">
              Add Range
            </Button>
            <Divider />
            <div className="flex flex-row-reverse">
              <Button
                onClick={handleAppyGradeRange}
                type="primary"
                disabled={isGradeOverlapping}
              >
                Apply Grade Range
              </Button>
            </div>
          </>
        </Collapse.Panel>
      </Collapse>
    </>
  )
}
