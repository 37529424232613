export const handleUncheckModule = (
  nodeKey: string,
  nodeChecked: boolean,
  value: {
    checked: (string | number)[]
    halfChecked: (string | number)[]
  }
) => {
  if (nodeKey === 'PROGRAM_CONFIGURATION_PROGRAM_MODULE' && nodeChecked) {
    value.checked = value.checked.filter(
      (value) =>
        ![
          'PROGRAM_CONFIGURATION_JURNAL_PEMANTAUAN,PROGRAM_CONFIGURATION_PENDAMPINGAN_INDIVIDU,PROGRAM_CONFIGURATION_LOKA_KARYA',
          'PROGRAM_CONFIGURATION_PRESENSI',
          'PROGRAM_CONFIGURATION_PEMBOBOTAN_NILAI_PGP',
          'PROGRAM_CONFIGURATION_JURNAL_FASILITASI',
          'PROGRAM_CONFIGURATION_PERIODE_PENILAIAN',
        ].includes(value as string)
    )
  }
}

export const handleUncheckPembobotanDependencies = (
  nodeKey: string,
  nodeChecked: boolean,
  value: {
    checked: (string | number)[]
    halfChecked: (string | number)[]
  }
) => {
  if (
    (nodeKey ===
      'PROGRAM_CONFIGURATION_JURNAL_PEMANTAUAN,PROGRAM_CONFIGURATION_PENDAMPINGAN_INDIVIDU,PROGRAM_CONFIGURATION_LOKA_KARYA' ||
      nodeKey === 'PROGRAM_CONFIGURATION_PRESENSI') &&
    nodeChecked
  ) {
    value.checked = value.checked.filter(
      (value) => value !== 'PROGRAM_CONFIGURATION_PEMBOBOTAN_NILAI_PGP'
    )
  }
}
