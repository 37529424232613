import {
  Button,
  Descriptions,
  Divider,
  List,
  Slider,
  Table,
  useForm,
  Form,
  Modal,
  Input,
  Tooltip,
} from '@pankod/refine-antd'
import { useOne } from '@pankod/refine-core'
import dayjs from 'dayjs'
import Head from 'next/head'
import { useState } from 'react'

import { TCommonError } from 'src/interfaces/common'

export const LMSCanvasReplicaSetupList = () => {
  const [confirmationInputVal, setConfirmationInputVal] = useState('')

  const { data: kvData } = useOne<any>({
    resource: 'kv?key=ops_toolkit',
    id: '',
    dataProviderName: 'microlearning',
  })
  const { minimumPod = 2, maximumPod = 18 } = (kvData?.data?.data as any) || {}
  const [confirmationModal, setConfirmationModal] = useState(false)
  const { formProps, onFinish } = useForm<{ data: any }, TCommonError, any>({
    action: 'create',
    resource: `ops-tools/canvas-worker/scale`,
    dataProviderName: 'lms',
    redirect: false,
    onMutationSuccess: () => {
      refetch()
    },
    errorNotification: (err: any) => {
      const errResponse = err.response?.data?.error || ''

      return {
        message: errResponse.status,
        description: 'please check your input or try again later',
        type: 'error',
      }
    },
  })

  const { data: canvasWorkerData, refetch } = useOne<any>({
    resource: 'ops-tools/canvas-worker/scale',
    id: '',
  })

  const replicaCountUpdate = Form.useWatch('replicas', formProps?.form)

  return (
    <List title="LMS Canvas Replica Setup">
      <Head>
        <title>Current Worker State</title>
      </Head>

      <div className="mt-8 ">
        <Descriptions
          bordered
          title="Current Worker Pod"
          column={1}
          size="small"
        >
          <Descriptions.Item label="Current Worker Pod">
            {canvasWorkerData?.data?.currentReplicas}
          </Descriptions.Item>

          <Descriptions.Item label="Current Migration Job">
            {canvasWorkerData?.data?.contentMigrationJobCount}
          </Descriptions.Item>

          <Descriptions.Item label="Estimated Job Count">
            {canvasWorkerData?.data?.estimatedAllJobCount}
          </Descriptions.Item>
        </Descriptions>
        <Divider />

        <div className="flex flex-col ">
          <Form {...formProps} onFinish={onFinish}>
            <div className="flex flex-row items-center justify-center ">
              <span className="mt-1 mr-2">{minimumPod}</span>
              <Form.Item
                name="replicas"
                initialValue={canvasWorkerData?.data?.currentReplicas}
                className="w-1/2"
              >
                <Slider
                  defaultValue={canvasWorkerData?.data?.currentReplicas}
                  min={minimumPod}
                  max={maximumPod}
                  tooltipVisible
                  tooltipPlacement="bottom"
                />
              </Form.Item>
              <span className="mt-1 ml-2">{maximumPod}</span>
            </div>

            <div className="flex flex-row items-center justify-center ">
              <Button
                disabled={
                  canvasWorkerData?.data?.canScale
                    ? canvasWorkerData?.data?.currentReplicas ===
                        replicaCountUpdate && replicaCountUpdate !== undefined
                    : true
                }
                className="w-1/2"
                onClick={() => {
                  setConfirmationModal(true)
                }}
              >
                Update Pod Count
              </Button>
            </div>

            <Modal
              title="Konfirmasi Perubahan Jumlah Pod Replica"
              visible={confirmationModal}
              footer={null}
              onCancel={() => {
                setConfirmationInputVal('')
                setConfirmationModal(false)
              }}
            >
              <p>
                Apakah anda yakin ingin mengubah jumlah pod replica ?
                <br />
                Silahkan ketik ulang jumlah pod replica yang diinginkan untuk
                konfirmasi ulang
              </p>

              <Input
                value={confirmationInputVal}
                onChange={(e) => {
                  setConfirmationInputVal(e.target.value)
                }}
              />
              <Tooltip
                title={
                  confirmationInputVal !== String(replicaCountUpdate)
                    ? 'Input tidak valid atau angka replica masih sama dengan angka sebelumnya'
                    : ''
                }
                className="w-full flex"
              >
                <Button
                  className="w-full mt-8"
                  block
                  disabled={
                    confirmationInputVal
                      ? confirmationInputVal !== String(replicaCountUpdate)
                      : true
                  }
                  onClick={() => {
                    onFinish()
                  }}
                >
                  Apply Pod Count Update
                </Button>
              </Tooltip>
            </Modal>
          </Form>
          <Divider />

          <Table
            dataSource={canvasWorkerData?.data?.histories}
            rowKey="id"
            className="w-full"
          >
            <Table.Column title="No." dataIndex="id" key="id" />
            <Table.Column
              title="Date"
              dataIndex="date"
              key="date"
              render={(val) => {
                return dayjs(val).format('DD MMM YYYY HH:mm:ss')
              }}
            />
            <Table.Column
              title="Pod Count"
              dataIndex="replicas"
              key="replicas"
            />
            <Table.Column title="Status" dataIndex="status" key="status" />
          </Table>
        </div>
      </div>
    </List>
  )
}
