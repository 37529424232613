import {
  Checkbox,
  Divider,
  Form,
  FormProps,
  Icons,
  Input,
  Radio,
  Select,
} from '@pankod/refine-antd'
import { debounce } from 'lodash'
import React from 'react'

import { TFilterAdministrativeLevelPayload } from '../types'
import { ModalFilterLevel } from './ModalFilterLevel'
// import { useForm } from '@pankod/refine-core'
import { useParticipantFilterContext } from 'src/hooks/context/useParticipantFilterOptionsContext'
import { kapabilitasMapper } from './utils'
const { SearchOutlined } = Icons

type SideFilterParticipantFormProps = {
  children: React.ReactNode
  searchRowChildren?: React.ReactNode
  formInstance?: FormProps
  programId?: string
  withClassRoleFilter?: boolean
}

const PARTICIPANT_FILTER_OPTIONS = [
  {
    label: 'Semua',
    value: '',
  },
  {
    label: 'Pengajar Praktik',
    value: 'asisten',
  },
  {
    label: 'Fasilitator',
    value: 'instruktur',
  },
  {
    label: 'Peserta',
    value: 'peserta',
  },
  {
    label: 'Admin Kelas',
    value: 'admin_kelas',
  },
]

// TODO: MAKE THIS REUSABLE
// TODO: REFACTOR THIS
export const SideFilterParticipantForm = ({
  children,
  searchRowChildren,
  formInstance,
  withClassRoleFilter = false,
}: SideFilterParticipantFormProps) => {
  const [searchType, setSearchType] = React.useState('name')
  const { data: filterOptions } = useParticipantFilterContext()

  const handleDebounceSubmit = debounce(() => {
    formInstance?.form?.setFieldsValue({
      searchMode: searchType,
    })

    formInstance?.form?.submit()
  }, 500)

  const handleApplyLevelFilter = (value: TFilterAdministrativeLevelPayload) => {
    formInstance?.form?.setFieldsValue(value)
    formInstance?.form?.submit()
  }

  return (
    <div>
      <div className="flex flex-row justify-between items-center">
        <Form layout="vertical" {...formInstance}>
          <Form.Item name="searchMode" hidden />
          <Form.Item name="kodeProvinsi" hidden />
          <Form.Item name="kodeKabupaten" hidden />
          <Form.Item name="kodeKecamatan" hidden />
          <p className="text-xs font-bold  mb-2">Cari Peserta</p>
          <Form.Item name="searchQuery" className="w-[25vw]">
            <Input
              onChange={() => {
                handleDebounceSubmit()
              }}
              addonBefore={
                <Select
                  defaultValue="name"
                  onChange={(e) => {
                    setSearchType(e)
                  }}
                >
                  <Select.Option value="name">Nama</Select.Option>
                  <Select.Option value="email">Email</Select.Option>
                </Select>
              }
              placeholder={`Cari berdasarkan ${
                searchType === 'name' ? 'nama' : 'email'
              }`}
              prefix={<SearchOutlined className="mr-4" />}
              allowClear
            />
          </Form.Item>
        </Form>
        {withClassRoleFilter && (
          <Form layout="vertical" {...formInstance}>
            <p className="text-xs font-bold  mb-2 text-end">Tipe Peserta</p>
            <Form.Item name="roleKelas" className="">
              <Radio.Group
                options={PARTICIPANT_FILTER_OPTIONS}
                onChange={() => handleDebounceSubmit()}
                optionType="button"
                buttonStyle="outline"
                defaultValue={''}
              />
            </Form.Item>
          </Form>
        )}
        {searchRowChildren && searchRowChildren}
      </div>
      <Divider style={{ marginTop: 0 }} />
      <div className="flex flex-row">
        <div className="w-[10vw] mr-4  rounded">
          <div>
            <Form
              layout="vertical"
              {...formInstance}
              onValuesChange={() => {
                handleDebounceSubmit()
              }}
            >
              <Form.Item name="level">
                <p className="text-xs font-bold">Level Administrasi</p>
                <ModalFilterLevel
                  administrativeLevel={filterOptions?.data?.regions || []}
                  onApply={(value) => {
                    handleApplyLevelFilter(value)
                  }}
                  onReset={() => {
                    handleApplyLevelFilter({
                      kodeProvinsi: null,
                      kodeKabupaten: null,
                      kodeKecamatan: null,
                    })
                  }}
                />
              </Form.Item>
              <Divider />
              <p className="text-xs font-bold mt-4 mb-2">Jenjang</p>
              <Form.Item name="jenjang">
                <Select mode="multiple">
                  {filterOptions?.data?.jenjang.map((item: any) => (
                    <Select.Option
                      className="capitalize"
                      key={item}
                      value={item}
                    >
                      {item}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Divider />
              <p className="text-xs font-bold mt-4 mb-2">Kapabilitas</p>
              <Form.Item name="kapabilitas">
                <Checkbox.Group>
                  {filterOptions?.data?.kapabilitas?.map((item: any) => (
                    <Checkbox
                      key={item}
                      value={item}
                      style={{
                        marginLeft: '0px',
                        marginBottom: 8,
                        display: 'flex',
                      }}
                    >
                      {kapabilitasMapper(item)}
                    </Checkbox>
                  ))}
                </Checkbox.Group>
              </Form.Item>
            </Form>
          </div>
        </div>
        <Divider type="vertical" style={{ height: '50vh' }} />
        <div className="w-[70vw] ml-2 overflow-x-auto">{children}</div>
      </div>
    </div>
  )
}
