import { Descriptions, Spin } from '@pankod/refine-antd'
import clsx from 'clsx'
import React from 'react'
import { useShow, useResource } from '@pankod/refine-core'

import styles from './styles.module.css'
import { dateToStringFmt } from 'src/helpers/date'
import { BatchResponse } from '../types'
import { TCommonResponse } from 'src/interfaces/common'
const BATCH_RESOURCE = 'angkatan'

export const DetailTab = () => {
  const resource = useResource()
  const id = resource.id as number

  const { queryResult } = useShow<TCommonResponse<BatchResponse>>({
    id,
    resource: BATCH_RESOURCE,
    dataProviderName: 'lms',
  })

  const data = queryResult?.data?.data?.data
  const isLoading = queryResult.isFetching

  return (
    <Spin spinning={isLoading}>
      <Descriptions
        size="small"
        title="Periode Pengelompokan Semester Satu"
        column={1}
        bordered
        className={clsx('mb-9', styles['semester-date'])}
      >
        <Descriptions.Item label="Tanggal Mulai">
          {dateToStringFmt(
            data?.firstSemesterCollectionDate.startDate,
            'DD MMMM YYYY'
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Tanggal Selesai">
          {dateToStringFmt(
            data?.firstSemesterCollectionDate.endDate,
            'DD MMMM YYYY'
          )}
        </Descriptions.Item>
      </Descriptions>
      <Descriptions
        size="small"
        title="Periode Pengelompokan Semester Dua"
        column={1}
        bordered
        className={clsx('mb-9', styles['semester-date'])}
      >
        <Descriptions.Item label="Tanggal Mulai">
          {dateToStringFmt(
            data?.secondSemesterCollectionDate.startDate,
            'DD MMMM YYYY'
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Tanggal Selesai">
          {dateToStringFmt(
            data?.secondSemesterCollectionDate.endDate,
            'DD MMMM YYYY'
          )}
        </Descriptions.Item>
      </Descriptions>
    </Spin>
  )
}
