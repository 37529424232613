import { List } from '@pankod/refine-antd'
import { IResourceComponentsProps, useGetIdentity } from '@pankod/refine-core'
import Head from 'next/head'
import React from 'react'

import { AdminKelasList } from './blocks/AdminKelas'
import { DosenList } from './blocks/Dosen'
import { GuruPamong } from './blocks/GuruPamong'
// import { SekolahList } from './blocks/Sekolah'
import { MahasiswaList } from './blocks/Mahasiswa'
import { Instruktur } from './blocks/Instruktur'

export const AdminLPTKList: React.FC<IResourceComponentsProps> = (props) => {
  const { data } = useGetIdentity()

  const currentUserLPTKId = data.user.lptkId as number

  const COMPONENT_BY_KEY: {
    [key: string]: JSX.Element
  } = {
    dosen: <DosenList lptkId={currentUserLPTKId} isMasterData enableFilter />,
    'guru-pamong': (
      <GuruPamong lptkId={currentUserLPTKId} isMasterData enableFilter />
    ),
    instruktur: (
      <Instruktur lptkId={currentUserLPTKId} isMasterData enableFilter />
    ),
    // sekolah: <SekolahList lptkId={currentUserLPTKId} />,
    mahasiswa: (
      <MahasiswaList lptkId={currentUserLPTKId} isMasterData enableFilter />
    ),
    'admin-kelas': (
      <AdminKelasList lptkId={currentUserLPTKId} isMasterData enableFilter />
    ),
  }

  return (
    <List title={props.options?.label}>
      <Head>
        <title>LMS Admin | {props.options?.label} </title>
      </Head>

      {COMPONENT_BY_KEY[props.options?.key]}
    </List>
  )
}
