import { Button, Tag, notification } from '@pankod/refine-antd'
import _ from 'lodash'
import router from 'next/router'

export const kapabilitasMapper = (
  item: string,
  customMapper: (item: string) => string = (item) => item
) => {
  const globallyParsed: Record<string, string> = {
    instruktur: 'fasilitator',
    asisten: 'pengajar praktik',
  }
  return _.startCase(customMapper(globallyParsed[item] || item))
}

export const kapabilitasTags = (items?: string[] | string | null) => {
  if (!items || (Array.isArray(items) && !items.length))
    return <Tag data-empty>Peserta</Tag>
  if (!Array.isArray(items)) return <Tag>{kapabilitasMapper(items)}</Tag>
  items.sort()
  return (
    <>
      {items.map((item) => (
        <Tag key={item}>{kapabilitasMapper(item)}</Tag>
      ))}
    </>
  )
}

export const successMigrateNotification = ({
  type,
  programId,
}: {
  type: 'add' | 'move' | 'deactivate' | 'reactivate'
  programId?: string | null | number
}) => {
  const textByType = {
    add: 'menambah',
    move: 'memindah',
    deactivate: 'menonaktifkan',
    reactivate: 'mengaktifkan',
  }
  const key = `${type}_${Date.now()}`
  notification.success({
    description: `Permintaan ${textByType[type]} peserta kelas sedang diproses, klik tombol di bawah untuk melihat progres.`,
    type: 'success',
    message: 'Sukses',
    btn: (
      <Button
        size="small"
        type="primary"
        onClick={() => {
          router.push(
            `/lms-program-management/show/${programId}?activeTab=canvasSync`
          )
          notification.close(key)
        }}
      >
        Buka Canvas Migration
      </Button>
    ),
    key,
  })
}
