import React from 'react'
import { useNode, Node } from '@craftjs/core'
import clsx from 'clsx'
import {
  RadioInput,
  RadioInputGroup as LibraryRadio,
} from '@instructure/ui-radio-input'
import { Controller } from 'react-hook-form'
import { capitalize } from 'lodash'
import { Form, Input, Switch } from '@pankod/refine-antd'

import { useFormContext } from '@components/FormBuilder/formContext'
import {
  LikertQuestionCustomProps,
  TLikertQuestion,
  WIDGET_NAME,
} from '@components/FormBuilder/schema'
import { optionsParser } from '../utils/MenuOptions'
import {
  mappedWidgetSettingsError,
  widgetSettingsOnValueChange,
  getWidgetSettingsFields,
} from '@components/FormBuilder/utils'
import ContentForm from '@components/ContentBank/ContentForm'

export const defaultProps: TLikertQuestion = {
  label: 'Likert question',
  name: 'likertQuestion',
  options: [],
}

const LikertQuestion = ({ name, label, helper, options }: TLikertQuestion) => {
  const { control, formInformationInstance } = useFormContext()
  const likertOptions = Form.useWatch(
    'likertOptions',
    formInformationInstance
  )?.map((label: string, index: number) => ({
    label,
    value: String(index + 1),
  }))

  const {
    connectors: { connect, drag },
  } = useNode()
  return (
    <div
      ref={(ref) => connect(drag(ref!))}
      className={clsx(
        'mb-3 last:mb-0',
        'widget-component',
        'widget-component--checkbox-group',
        'text-default'
      )}
    >
      <Controller
        name={name}
        control={control}
        render={({
          field: { name, onChange, value },
          fieldState: { error },
        }) => (
          <LibraryRadio
            onChange={onChange}
            description={
              <div
                className="form-label ql-editor"
                dangerouslySetInnerHTML={{ __html: label }}
              />
            }
            name={name}
            value={value}
            messages={
              error?.message
                ? [{ text: capitalize(error.message), type: 'error' }]
                : [{ text: helper || null, type: 'hint' }]
            }
          >
            {optionsParser(likertOptions || options).map((v, i) => (
              <RadioInput key={i} label={v.label} value={v.value} />
            ))}
          </LibraryRadio>
        )}
      />
    </div>
  )
}

const LikertQuestionSettings = () => {
  const {
    actions: { setProp, setCustom },
    props,
    custom,
  } = useNode((node) => ({
    props: node.data.props,
    custom: node.data.custom,
  }))

  const errors = mappedWidgetSettingsError(custom.errors)
  return (
    <Form
      onValuesChange={(changedValue) =>
        widgetSettingsOnValueChange(changedValue, custom, setCustom)
      }
      fields={getWidgetSettingsFields(props)}
      layout="vertical"
    >
      <Form.Item
        label="Label"
        name="label"
        validateStatus={errors['label'] && 'error'}
        help={errors['label']}
      >
        <ContentForm
          index="widgetText"
          showDeleteButton={false}
          showIndentation={false}
          showUploader={false}
          showTitle={false}
          value={props.text}
          onChange={(text) =>
            setProp((props: TLikertQuestion) => (props.label = text))
          }
        />
      </Form.Item>
      <Form.Item
        label="Required"
        name="isRequired"
        validateStatus={errors['isRequired'] && 'error'}
        help={errors['isRequired']}
      >
        <Switch
          checked={props.isRequired}
          onChange={(e) =>
            setProp((props: TLikertQuestion) => (props.isRequired = e))
          }
        />
      </Form.Item>
      <Form.Item
        label="Helper Text"
        name="helper"
        validateStatus={errors['helper'] && 'error'}
        help={errors['helper']}
      >
        <Input
          autoComplete="off"
          value={props.helper}
          onChange={(e) =>
            setProp((props: TLikertQuestion) => (props.helper = e.target.value))
          }
        />
      </Form.Item>
    </Form>
  )
}

LikertQuestion.craft = {
  rules: {
    canDrop: (targetNode: Node) => {
      return targetNode.data.name === WIDGET_NAME.Container
    },
  },
  props: defaultProps,
  custom: LikertQuestionCustomProps,
  related: {
    settings: LikertQuestionSettings,
  },
}

export { LikertQuestion }
