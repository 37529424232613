import {
  Form,
  Modal,
  Select,
  SelectProps,
  notification,
  useSelect,
} from '@pankod/refine-antd'
import { useCustomMutation, useResource } from '@pankod/refine-core'
import React from 'react'

import { downloadTemplate } from '@components/ModalParseCSV/generateCSVTemplate'
import { TCommonError } from 'src/interfaces/common'

const evaluatedRole: SelectProps['options'] = [
  {
    label: 'Fasilitator',
    value: 'instruktur',
  },
  {
    label: 'Pengajar Praktik',
    value: 'asisten',
  },
]

const evaluatorRole: SelectProps['options'] = [
  {
    label: 'Admin Kelas (Satker/UPT)',
    value: 'admin_kelas',
  },
  {
    label: 'Fasilitator',
    value: 'instruktur',
  },
  {
    label: 'Murid (CGP)',
    value: 'peserta',
  },
  {
    label: 'Pengajar Praktik',
    value: 'asisten',
  },
]

type ModalExportProps = {
  show: boolean
  type: 'keterisian' | 'report'
  onClose: () => void
}

const ModalExport = ({ show, onClose, type }: ModalExportProps) => {
  const [form] = Form.useForm()
  const { id: programId } = useResource()
  const { mutateAsync, isLoading } = useCustomMutation<
    // @ts-expect-error
    string,
    TCommonError,
    { evaluatorRole?: string; programLocalId?: string; evaluatedRole?: string }
  >()
  const { selectProps } = useSelect({
    resource: `programs/${programId}/local-programs`,
    dataProviderName: 'lms',
    optionLabel: 'name',
    optionValue: 'id',
    filters: [
      {
        field: 'pageSize',
        operator: 'eq',
        value: -1,
      },
    ],
    queryOptions: {
      enabled: !!show && type === 'keterisian',
    },
    defaultValueQueryOptions: {
      enabled: false,
    },
  })

  return (
    <Modal
      title="Ekspor Keterisian Penilaian Kinerja"
      visible={show}
      onCancel={onClose}
      afterClose={form.resetFields}
      onOk={() => form.submit()}
      okButtonProps={{
        disabled: isLoading,
      }}
      okText="Unduh"
      cancelText="Batal"
    >
      <Form
        form={form}
        layout="vertical"
        validateMessages={{ required: '${label} harus diisi.' }}
        onFinish={async (data: {
          evaluatorRole?: string
          programLocalId?: string
          evaluatedRole?: string
        }) => {
          const props = {
            keterisian: {
              url: `${process.env.NEXT_PUBLIC_LMS_API_URL}/programs/${programId}/laporan-penilaian-kinerja/laporan-keterisian/export`,
              title: `ProgramId_${programId}${
                data.programLocalId
                  ? `_ProgramLocalId_${data.programLocalId}`
                  : '_All'
              }`,
              prefix: 'LaporanKeterisianPenilaianKinerja',
            },
            report: {
              url: `${process.env.NEXT_PUBLIC_LMS_API_URL}/programs/${programId}/laporan-penilaian-kinerja/export`,
              title: `ProgramId_${programId}_Role_${
                evaluatedRole.find((v) => v.value === data.evaluatedRole)?.label
              }`,
              prefix: 'LaporanPenilaianKinerja',
            },
          }

          const { data: csvData } = await mutateAsync({
            url: props[type].url,
            method: 'post',
            values: data,
            dataProviderName: 'lms',
          })

          if (!csvData) {
            notification.error({
              message: 'Error',
              description: 'Data tidak tersedia',
            })
            return
          }
          downloadTemplate(csvData, props[type].title, props[type].prefix)
          notification.success({
            message: 'Sukses',
            description: 'Laporan berhasil diunduh',
          })
          onClose()
        }}
      >
        {type === 'report' && (
          <Form.Item
            label="Role sasaran"
            required
            rules={[{ required: true }]}
            name="evaluatedRole"
          >
            <Select options={evaluatedRole} />
          </Form.Item>
        )}
        {type === 'keterisian' && (
          <Form.Item
            label="Aktor yang menilai"
            required
            rules={[{ required: true }]}
            name="evaluatorRole"
          >
            <Select options={evaluatorRole} />
          </Form.Item>
        )}
        {type === 'keterisian' && (
          <Form.Item label="Program Lokal" name="programLocalId">
            <Select
              {...selectProps}
              onSearch={() => {}}
              filterOption={(input, option) =>
                (option!.label as unknown as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            />
          </Form.Item>
        )}
      </Form>
    </Modal>
  )
}

export default ModalExport
