import { Icons, Modal, Table, Tag, useTable } from '@pankod/refine-antd'
import dayjs from 'dayjs'

export type ModalShowRombelSyncProgressProps = {
  modalProps: any
  angkatanId: number
  lptkId: number | null
  semester: string
}

const parseDate = (dateStr?: string, format = 'DD MMMM YYYY hh:mm:ss') => {
  if (!dateStr) return '-'
  return dayjs(dateStr).format(format)
}

export const ModalShowRombelSyncProgress = (
  props: ModalShowRombelSyncProgressProps
) => {
  const { modalProps, angkatanId, semester, lptkId } = props

  const { tableProps, tableQueryResult } = useTable({
    resource: `angkatan/${angkatanId}/lptk/${lptkId}/semester/${semester}/sis-import/histories`,
    queryOptions: {
      enabled: !!angkatanId && !!lptkId && !!semester && modalProps.visible,
    },
  })

  const statusMap: any = {
    init: 'Ter-inisiasi',
    processed: 'Diproses',
    failed: 'Gagal',
    completed: 'Berhasil',
    completed_with_messages: 'Berhasil',
    created: 'Dalam Proses',
    imported: 'Berhasil Import Canvas',
    imported_with_messages: 'Berhasil Import Canvas',
  }

  const tagColorMap: any = {
    init: 'blue',
    processed: 'blue',
    failed: 'red',
    completed: 'green',
    completed_with_messages: 'green',
    created: 'blue',
    imported: 'green',
    imported_with_messages: 'green',
  }

  const iconMap: any = (progressVal: number) => {
    if (progressVal === 100) {
      return <Icons.CheckCircleFilled color="green" />
    }
    if (progressVal === 0) {
      return <Icons.ClockCircleFilled />
    }
    return <Icons.SyncOutlined spin />
  }

  return (
    <Modal
      {...modalProps}
      width={1000}
      title="Sinkronisasi Rombel"
      cancelText="Kembali"
    >
      <Table
        {...tableProps}
        scroll={{ x: 'max-content' }}
        // @ts-ignore
        dataSource={tableQueryResult.data?.syncProgresses as any[]}
      >
        <Table.Column
          dataIndex="createdAt"
          title="created At"
          render={(value) => parseDate(value)}
        />
        <Table.Column
          dataIndex="updatedAt"
          title="updated At"
          render={(value) => parseDate(value)}
        />
        <Table.Column
          dataIndex="progress"
          title="Progress"
          render={(value) => (
            <div className="gap-2 flex items-center">
              {iconMap(value)}
              {value}%
            </div>
          )}
        />
        <Table.Column
          dataIndex="status"
          title="Status"
          render={(value) => (
            <Tag color={tagColorMap[value]}>{statusMap[value] || value}</Tag>
          )}
        />

        <Table.Column
          dataIndex="importStatus"
          title="Import Status"
          render={(value) => (
            <Tag color={tagColorMap[value]}>{statusMap[value] || value}</Tag>
          )}
        />
      </Table>
    </Modal>
  )
}
