import {
  Alert,
  Form,
  FormProps,
  Input,
  Modal,
  ModalProps,
  Select,
} from '@pankod/refine-antd'
import React from 'react'

import { LMSClassGroup, LMSClassGroupClass } from '../types'

type TModalFormProps = {
  modalProps: ModalProps
  formProps: FormProps
  onClose: () => void
  vacantClasses: LMSClassGroupClass[]
}
export const ClassGroupModalForm = ({
  modalProps,
  formProps,
  onClose,
  vacantClasses,
}: TModalFormProps) => {
  return (
    <Modal
      {...modalProps}
      onCancel={onClose}
      title="Buat Kelompok Kelas Baru"
      maskClosable={false}
    >
      <Alert
        className="!mb-5"
        message="Saran"
        description="Jumlah yang efektif untuk kelompok kelas adalah 5-7 kelas untuk dikelompokkan sebagai 1 kelas webinar."
        showIcon
      />
      <Form
        {...formProps}
        layout="vertical"
        validateMessages={{ required: '${label} tidak boleh kosong.' }}
      >
        <Form.Item<LMSClassGroup>
          label="Nama Kelompok Kelas"
          name="name"
          required
          rules={[{ required: true }]}
        >
          <Input autoComplete="off" />
        </Form.Item>
        <Form.Item
          label="Kelas LMS"
          name="classIds"
          required
          rules={[{ required: true }]}
        >
          <Select
            mode="multiple"
            options={vacantClasses.map(({ id, name }) => ({
              label: name,
              value: id,
            }))}
            filterOption={(input, option) =>
              ((option?.label ?? '') as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}
