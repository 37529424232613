import React from 'react'
import { TitleProps } from '@pankod/refine-core'
import { Tooltip, Typography } from '@pankod/refine-antd'
import Link from 'next/link'
import _ from 'lodash'
import nookies from 'nookies'

import { BASE_PATH, SESSION } from 'src/constant'

const { Title: AntDTitle } = Typography

export const Title: React.FC<TitleProps> = ({ collapsed }) => {
  const userSession = nookies.get()[SESSION.USER_SESSION]
  const lmsRole = userSession ? JSON.parse(userSession).user.LMSRole : ''

  return (
    <Link href="/">
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div className="flex flex-col justify-center" suppressHydrationWarning>
          <AntDTitle
            level={3}
            style={{
              margin: '0',
              marginLeft: '24px',
              flexShrink: 0,
              display: collapsed ? 'none' : 'initial',
            }}
          >
            LMS Admin
          </AntDTitle>
          {lmsRole && !collapsed && (
            <Tooltip title={_.startCase(lmsRole)} placement="right">
              <Typography.Text
                type="secondary"
                className="ml-6 italic !max-w-[130px]"
                ellipsis
              >
                {_.startCase(lmsRole)}
              </Typography.Text>
            </Tooltip>
          )}
        </div>
        <img
          src={`${BASE_PATH}/dashkit.svg`}
          alt="logo"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '12px 24px',
            paddingLeft: '10px',
            height: '64px',
          }}
        />
      </div>
    </Link>
  )
}
