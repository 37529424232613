import {
  Button,
  Descriptions,
  Icons,
  Show,
  Spin,
  Tabs,
  useSimpleList,
} from '@pankod/refine-antd'
import {
  IResourceComponentsProps,
  useNavigation,
  useOne,
  useResource,
  useRouterContext,
} from '@pankod/refine-core'
import Head from 'next/head'
import React, { useState } from 'react'
import { useRouter } from 'next/router'

import { AngkatanTabList } from '../block/AngkatanTabList'
import { useGetLptkId } from '../utils'
import { AngkatanLPTKListResponse, RombelResponse } from '../types'
import { MataKuliahTabContent } from './blocks/MataKuliahTabContent'
import { RombelBreadcrumb } from './blocks/RombelBreadcrumb'
import { useErrorBoundary } from '@components/ErrorBoundary/hook'
import { TCommonResponse } from 'src/interfaces/common'
import { PesertaTabContent } from './blocks/PesertaTabContent'
import { trackTabChange } from 'src/helpers/url'

type RombelType = 'UTAMA' | 'PPL' | 'SEMINAR' | 'KEPEMIMPINAN'

export const RombelShow: React.FC<IResourceComponentsProps> = ({ options }) => {
  const [preventTabChange, setPreventTabChange] = useState(false)
  const { list } = useNavigation()
  const { showBoundary } = useErrorBoundary()
  const router = useRouter()
  const { angkatanId, activeTab } = router.query

  const { Link } = useRouterContext()

  const [lptkId] = useGetLptkId()
  const resource = useResource()
  const rombelId = resource.id

  const [activeKey, setActiveKey] = useState<string>(
    (activeTab as string) || 'peserta'
  )

  const { listProps, queryResult: listQueryResult } =
    useSimpleList<AngkatanLPTKListResponse>({
      resource: `lptk/${lptkId}/angkatan`,
      permanentFilter: [
        {
          field: 'pageSize',
          operator: 'eq',
          value: -1,
        },
      ],
      queryOptions: {
        enabled: !!lptkId,
      },
    })
  const { data, isFetching } = useOne<TCommonResponse<RombelResponse>>({
    resource: `angkatan/${angkatanId}/lptk/${lptkId}/rombel`,
    id: rombelId,
    queryOptions: {
      enabled: !!lptkId && !!angkatanId && !!rombelId,
    },
  })

  if (!angkatanId) {
    showBoundary({
      status: 404,
      onBackClick: () => list('angkatan-ppg-management'),
      backButtonText: 'Kembali ke Angkatan',
      title: 'Halaman tidak ditemukan',
    })
    return null
  }

  const rombelListUrl = `/rombel-ppg?angkatanId=${angkatanId}`

  return (
    <Show
      title="Detail Rombel"
      breadcrumb={
        <RombelBreadcrumb
          items={[
            {
              label: 'Angkatan',
              href: '/angkatan-ppg',
            },
            {
              label: 'Rombel',
              href: rombelListUrl,
            },
            {
              label: 'Show',
            },
          ]}
        />
      }
      goBack={null}
      contentProps={{ bodyStyle: { padding: 0 } }}
      headerButtons={() => (
        <Link to={rombelListUrl}>
          <Button icon={<Icons.UnorderedListOutlined />}>Daftar Rombel</Button>
        </Link>
      )}
    >
      <AngkatanTabList
        lptkId={lptkId}
        angkatanId={angkatanId as string}
        isLoading={listQueryResult.isFetching}
        listProps={listProps}
        rombelId={rombelId}
      >
        <Head>
          <title>LMS Admin | {options?.label} </title>
        </Head>

        <Spin spinning={isFetching}>
          <Descriptions
            column={1}
            title="Informasi Rombel"
            bordered
            className="mb-6"
          >
            <Descriptions.Item label="ID">
              {data?.data.data.id}
            </Descriptions.Item>
            <Descriptions.Item label="Nama Rombel">
              {data?.data.data.name}
            </Descriptions.Item>
            <Descriptions.Item label="Jenis Rombel">
              {data?.data.data.type}
            </Descriptions.Item>
          </Descriptions>

          <Tabs
            destroyInactiveTabPane
            type="card"
            activeKey={activeKey}
            onChange={(activeKey) => {
              if (preventTabChange) {
                if (
                  !window.confirm(
                    'Perubahan Data Aktor belum diaplikasikan, Silahkan Klik Apply Perubahan Aktor Terlebih Dahulu'
                  )
                ) {
                  return
                }
              }

              setActiveKey(activeKey)
              trackTabChange(router, activeKey, 'activeTab')
              setPreventTabChange(false)
            }}
          >
            <Tabs.TabPane tab="Peserta" key="peserta">
              <PesertaTabContent
                angkatanId={angkatanId as string}
                lptkId={lptkId}
                rombelId={rombelId}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Mata Kuliah" key="mata-kuliah">
              <MataKuliahTabContent
                rombelId={resource.id as string}
                // TODO: populate this
                rombelType={
                  (data?.data.data.type.toUpperCase() as RombelType) || 'UTAMA'
                }
                isPreventTabChange={setPreventTabChange}
              />
            </Tabs.TabPane>
          </Tabs>
        </Spin>
      </AngkatanTabList>
    </Show>
  )
}
