import { Button, Icons, Table, useTable } from '@pankod/refine-antd'
import React from 'react'
import dayjs from 'dayjs'

import { ProgramLocalWebinar, ProgramLocalWebinarClass } from './types'
import { TCommonError } from 'src/interfaces/common'
import { groupBySequence, renderGroupBySequence } from 'src/helpers/date'
import { WebinarClassGroupDrawer } from './blocks/WebinarClassGroupDrawer'
import { useWebinarDrawer } from './hooks'

const DATE_FORMAT = 'DD MMM YYYY'

type WebinarClassGroupProps = {
  programLocalId?: number
}
export const WebinarClassGroup = ({
  programLocalId,
}: WebinarClassGroupProps) => {
  const { tableProps } = useTable<ProgramLocalWebinar, TCommonError>({
    resource: `local-programs/${programLocalId}/webinars`,
    dataProviderName: 'lms',
  })
  const { open, ...webinarDrawerProps } =
    useWebinarDrawer<ProgramLocalWebinarClass>({
      resource: `local-programs/${programLocalId}/webinars`,
      enabled: !!programLocalId,
    })
  return (
    <>
      <Table {...tableProps} rowKey="id" scroll={{ x: 1500 }}>
        <Table.Column
          dataIndex="title"
          fixed="left"
          title="Judul Webinar"
          width={300}
        />
        <Table.Column
          dataIndex="code"
          className="break-words"
          title="Kode Unik"
          width={100}
        />
        <Table.Column
          dataIndex="instructorSelectionDate"
          title="Tgl. Pemilihan Instruktur"
          width={210}
          render={(value) => (
            <p className="whitespace-nowrap mb-0">{`${dayjs(
              value?.startDate
            ).format(DATE_FORMAT)} - ${dayjs(value.endDate).format(
              DATE_FORMAT
            )}`}</p>
          )}
        />
        <Table.Column
          dataIndex="executionDate"
          title="Tgl. Pelaksanaan Webinar"
          width={210}
          render={(value = []) => {
            const group = groupBySequence(value)
            return renderGroupBySequence(group)
          }}
        />
        <Table.Column<ProgramLocalWebinar>
          width={150}
          dataIndex={['statistic', 'assigned']}
          title="Terjadwal"
          render={(value, record) => `${value}/${record.statistic.total}`}
        />
        <Table.Column<ProgramLocalWebinar>
          width={150}
          dataIndex={['statistic', 'prepared']}
          title="Siap Melaksanakan"
          render={(value, record) => `${value}/${record.statistic.total}`}
        />
        <Table.Column<ProgramLocalWebinar>
          width={150}
          dataIndex={['statistic', 'reported']}
          title="Laporan Tersedia"
          render={(value, record) => `${value}/${record.statistic.total}`}
        />
        <Table.Column<ProgramLocalWebinar>
          fixed="right"
          render={(_, { id }) => (
            <Button
              icon={<Icons.UnorderedListOutlined />}
              onClick={() => open(+id)}
            >
              Kelompok Kelas
            </Button>
          )}
        />
      </Table>

      <WebinarClassGroupDrawer {...webinarDrawerProps} />
    </>
  )
}
