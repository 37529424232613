import {
  CheckboxGroup,
  Container,
  ContentWrapper,
  DatePicker,
  Heading,
  LikertQuestion,
  NumberInput,
  RadioGroup,
  RichTextEditor,
  Select,
  Text,
  TextArea,
  TextInput,
} from '@components/FormBuilder/widgets'

const resolver = {
  CheckboxGroup,
  Container,
  ContentWrapper,
  DatePicker,
  Heading,
  LikertQuestion,
  NumberInput,
  RadioGroup,
  RichTextEditor,
  Select,
  Text,
  TextArea,
  TextInput,
}

export default resolver
