import {
  AntdList,
  Card,
  Image,
  Icons,
  Radio,
  Button,
  Typography,
  Tooltip,
  Space,
  Input,
} from '@pankod/refine-antd'
import { useCustom } from '@pankod/refine-core'
import React from 'react'

import { IMAGE_MIME_LIST, PDF_MIME_LIST } from 'src/constant'
import { IListCursorMeta, IMediaLibrary } from 'src/interfaces'

const { FilePdfFilled } = Icons
const { Search } = Input

type TMediaLibraryProps = {
  onSelected: (fileUrl: string, fileType: 'image' | 'pdf') => void
}
const MediaLibrary = ({ onSelected }: TMediaLibraryProps) => {
  const lastCursor = React.useRef<number | undefined>(undefined)
  const prefixSearch = React.useRef<string>('')
  const [listData, setListData] = React.useState<IMediaLibrary[]>([])
  const { data, refetch, isFetching } = useCustom<{
    data: IMediaLibrary[]
    meta: IListCursorMeta
  }>({
    url: `${process.env.NEXT_PUBLIC_API_URL}/upload-list`,
    method: 'get',

    queryOptions: {
      keepPreviousData: true,
      onSuccess: (data) => {
        setListData((list) => [...list, ...(data?.data.data || [])])
      },
    },
  })

  const handleLoadMore = () => {
    if (!data) return

    lastCursor.current = data.data.meta.nextCursor
    refetch()
  }

  const handleSearchByPrefix = (value: string) => {
    if (value === prefixSearch.current) return

    setListData([])

    lastCursor.current = undefined
    prefixSearch.current = value
    refetch()
  }

  return (
    <Space
      direction="vertical"
      align="center"
      size="middle"
      className="upload-media-library-wrapper"
    >
      <Search
        onSearch={handleSearchByPrefix}
        enterButton
        placeholder="Search by prefix name"
        allowClear
        loading={isFetching}
      />
      <Radio.Group
        onChange={(e) => {
          const { url, type } = JSON.parse(e.target.value)
          onSelected(url, type)
        }}
        className="upload-media-library-radio-group"
      >
        <AntdList
          grid={{
            gutter: 10,
            xs: 2,
            sm: 3,
            md: 5,
            lg: 5,
            xl: 5,
            xxl: 6,
          }}
          loading={isFetching}
          loadMore={
            (data?.data?.meta.nextCursor ?? 0) > -1 && (
              <Button
                className="upload-media-library__load-more-button"
                onClick={handleLoadMore}
                disabled={isFetching}
              >
                Load More
              </Button>
            )
          }
          size="large"
          className="upload-media-library"
          dataSource={listData}
          renderItem={(item) => {
            const itemData = item.valueJSON
            const isImage = IMAGE_MIME_LIST.includes(itemData.mimeType)
            const isPdf = PDF_MIME_LIST.includes(itemData.mimeType)
            return (
              <AntdList.Item>
                <Radio.Button
                  value={JSON.stringify({
                    url: itemData.url,
                    type: itemData.mimeType,
                  })}
                  type="ghost"
                >
                  <Card
                    size="small"
                    cover={
                      <div className="upload-media-library__cover-wrapper">
                        {isImage && (
                          <Image
                            alt={itemData.originalName}
                            src={itemData.url}
                            width="100%"
                          />
                        )}
                        {isPdf && (
                          <FilePdfFilled
                            style={{
                              fontSize: '56px',
                              display: 'block',
                              margin: '20px 0',
                            }}
                          />
                        )}
                      </div>
                    }
                  >
                    <Tooltip
                      overlayInnerStyle={{ textAlign: 'center' }}
                      title={itemData.originalName}
                      placement="bottom"
                    >
                      <Card.Meta
                        style={{ textAlign: 'center' }}
                        description={
                          <Typography.Text ellipsis>
                            {itemData.originalName}
                          </Typography.Text>
                        }
                      />
                    </Tooltip>
                  </Card>
                </Radio.Button>
              </AntdList.Item>
            )
          }}
        />
      </Radio.Group>
    </Space>
  )
}

export default MediaLibrary
