import { Button, Icons, Popconfirm, Space, Table } from '@pankod/refine-antd'
import { useUpdate } from '@pankod/refine-core'
import React from 'react'

import useTableWithMeta from 'src/hooks/useTableWithMeta'

type CertificateApprovalListProps = {
  programId?: string | number
  localProgramId?: string | number
}

type CertificateApprovalItem = {
  email: string
  id: string
  kelasId: string
  kelasName: string
  name: string
  programId: string
  programLocalId: string
  programLocalName: string
  programName: string
  unitKerja: string
  userId: string
}

const CertificateApprovalList = ({
  programId,
  localProgramId,
}: CertificateApprovalListProps) => {
  const { tableProps } = useTableWithMeta<CertificateApprovalItem>({
    dataProviderName: 'lms',
    resource: `programs/${programId}/certificate-approvals`,
    queryOptions: {
      enabled: !!programId && !!localProgramId,
    },
    permanentFilter: [
      { field: 'programLocalId', operator: 'eq', value: localProgramId },
    ],
  })
  const { mutateAsync } = useUpdate()

  const doConfirmation = async (approved: boolean, id: string | number) => {
    return mutateAsync({
      dataProviderName: 'lms',
      resource: `programs/${programId}/certificate-approvals`,
      id,
      values: {
        approved,
      },
      metaData: {
        httpMethod: 'post',
      },
    })
  }

  return (
    <Table
      {...tableProps}
      rowKey={'id'}
      tableLayout="fixed"
      scroll={{ x: 300 }}
      columns={[
        { title: 'ID', dataIndex: 'id', width: 100 },
        { title: 'User ID', dataIndex: 'userId', width: 150 },
        { title: 'Email', dataIndex: 'email', width: 250 },
        { title: 'Nama', dataIndex: 'name', width: 250 },
        { title: 'Unit Kerja', dataIndex: 'unitKerja', width: 200 },
        {
          title: 'Aksi',
          width: 150,
          fixed: 'right',
          render: (_, record) => (
            <Space direction="vertical">
              <Popconfirm
                title="Apakah Anda yakin ingin menyetujui perubahan data sertifikat ini?"
                okText="Setuju"
                cancelText="Kembali"
                onConfirm={async () => doConfirmation(true, record.id)}
              >
                <Button icon={<Icons.CheckOutlined />} block type="primary">
                  Disetujui
                </Button>
              </Popconfirm>
              <Popconfirm
                title="Apakah Anda yakin ingin menolak perubahan data sertifikat ini?"
                okText="Tolak"
                cancelText="Kembali"
                onConfirm={async () => doConfirmation(false, record.id)}
                okButtonProps={{ danger: true }}
              >
                <Button icon={<Icons.CloseOutlined />} block danger>
                  Ditolak
                </Button>
              </Popconfirm>
            </Space>
          ),
        },
      ]}
    />
  )
}

export default CertificateApprovalList
