import { Tabs, Empty, Spin } from '@pankod/refine-antd'
import React from 'react'
import { useSimpleListReturnType } from '@pankod/refine-antd/dist/hooks/list/useSimpleList/useSimpleList'
import { useRouter } from 'next/router'

import { AngkatanLPTKListResponse } from '../types'
import { SemesterDetail } from './Semester/SemesterDetail'

type AngkatanTabListProps = {
  isLoading: boolean
  lptkId: number | null
  listProps: useSimpleListReturnType<AngkatanLPTKListResponse>['listProps']
  angkatanId?: string
  rombelId?: string
  children?: React.ReactNode
}
export const AngkatanTabList = ({
  isLoading,
  lptkId,
  listProps,
  children,
  angkatanId,
}: // rombelId,
AngkatanTabListProps) => {
  const items = listProps.dataSource
  const router = useRouter()

  if (!lptkId || !items?.length)
    return (
      <Spin spinning={isLoading}>
        <Empty />
      </Spin>
    )

  return (
    <Spin spinning={isLoading}>
      <Tabs
        tabPosition="left"
        type="line"
        destroyInactiveTabPane
        size="small"
        style={{ maxHeight: angkatanId ? 950 : 1200 }}
        {...(angkatanId && { activeKey: `${lptkId}_${angkatanId}` })}
      >
        {items.map(({ id, name }) => (
          <Tabs.TabPane
            key={`${lptkId}_${id}`}
            tab={
              <div
                className="text-ellipsis max-w-[200px] overflow-hidden"
                title={name}
                onClick={() => {
                  // if (rombelId) {
                  //   router.replace({
                  //     pathname: `/rombel-ppg/show/${rombelId}`,
                  //     query: {
                  //       angkatanId: id,
                  //     },
                  //   })
                  //   return
                  // }

                  if (angkatanId) {
                    router.replace({
                      pathname: '/rombel-ppg',
                      query: {
                        angkatanId: id,
                      },
                    })
                    return
                  }
                }}
              >
                {name}
              </div>
            }
          >
            {children ? (
              children
            ) : (
              <SemesterDetail lptkId={lptkId} angkatanId={id} />
            )}
          </Tabs.TabPane>
        ))}
      </Tabs>
    </Spin>
  )
}
