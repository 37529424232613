import clsx from 'clsx'
import React from 'react'

export const HeaderTitle = ({
  title,
  align = 'center',
}: {
  title: React.ReactNode
  align?: 'left' | 'center' | 'right'
}) => (
  <div
    className={clsx('w-full', {
      ['text-left']: align === 'left',
      ['text-center']: align === 'center',
      ['text-right']: align === 'right',
    })}
  >
    {title}
  </div>
)
