import {
  Table,
  TextField,
  Space,
  Button,
  useTable,
  useModalForm,
} from '@pankod/refine-antd'

import { showErrorNotification } from '@resources/angkatan-ppg-management/utils'
import { ModalKonfigurasiClassItem } from './ModalKonfigurasiClassItem'

export type TabContentAllocFacilProps = {
  dasusProgramId: string | number
  dasusProgramLocalId: string | number
  classId: string | number
}

export const TabContentAllocFacil = (props: TabContentAllocFacilProps) => {
  const { dasusProgramId, dasusProgramLocalId, classId } = props

  const mockFacilName = {
    data: [
      {
        id: '1',
        name: 'Program 1',
        facilName: 'some user name',
      },
      {
        id: '2',
        name: 'Program 2',
        facilName: 'some user name',
      },
      {
        id: '3',
        name: 'Program 3',
        facilName: 'some user name',
      },
    ],

    total: 3,
  }

  const { tableProps: ptmTableProps } = useTable({
    resource: `program-dasus/${dasusProgramId}/local-programs/${dasusProgramLocalId}/classes/${classId}/ptm`,
    dataProviderName: 'lms',
    queryOptions: {
      initialData: mockFacilName,
    },
  })

  const { tableProps: fasilTableProps } = useTable({
    resource: `program-dasus/${dasusProgramId}/local-programs/${dasusProgramLocalId}/classes/${classId}/facil`,
    dataProviderName: 'lms',
    queryOptions: {
      initialData: mockFacilName,
    },
  })

  const {
    modalProps: editModalProps,
    formProps: editFormProps,
    show: editShow,
    close: editClose,
  } = useModalForm({
    action: 'create',
    autoSubmitClose: true,
    resource: 'PROGRAM_LOCAL_RESOURCE',
    dataProviderName: 'lms',
    autoResetForm: true,
    redirect: false,
    successNotification: {
      message: 'Program local successfully edited',
      type: 'success',
      description: 'Successful',
    },
    errorNotification: (error: any) =>
      showErrorNotification(
        error,
        'There was a problem when editing program local'
      ),
  })

  return (
    <div>
      <p className="font-exxtra-bold text-lg">Alokasi Fasil PTM</p>

      <Table {...ptmTableProps} rowKey="id" scroll={{ y: 300 }}>
        <Table.Column
          dataIndex="id"
          title="ID"
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="name"
          title="Nama PTM"
          render={(value) => <TextField value={value} />}
        />
        <Table.Column dataIndex="facilName" title="Fasilitator" />
        <Table.Column<any>
          title="Actions"
          render={(_, record) => (
            <Space direction="vertical">
              <Button
                size="small"
                block
                danger
                onClick={() => {
                  editShow(record.id)
                }}
              >
                Konfigurasi PTM
              </Button>
            </Space>
          )}
        />
      </Table>

      <p className="font-exxtra-bold text-lg">Alokasi Fasil FI</p>

      <Table {...fasilTableProps} rowKey="id" scroll={{ y: 300 }}>
        <Table.Column
          dataIndex="id"
          title="ID"
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="name"
          title="Nama PTM"
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="facilName"
          title="Fasilitator"
          render={(value) => <TextField value={value} />}
        />
        <Table.Column<any>
          title="Actions"
          render={(_, record) => (
            <Space direction="vertical">
              <Button
                size="small"
                block
                danger
                onClick={() => {
                  editShow(record.id)
                }}
              >
                Konfigurasi FI
              </Button>
            </Space>
          )}
        />
      </Table>

      <ModalKonfigurasiClassItem
        onClose={editClose}
        modalProps={editModalProps}
        formProps={editFormProps}
        type="PTM"
        itemName="PTM"
      />
    </div>
  )
}
