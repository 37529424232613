import {
  Button,
  // Divider,
  EditButton,
  Icons,
  Modal,
  Result,
  Show,
  Space,
  Tabs,
  useModalForm,
} from '@pankod/refine-antd'
import {
  IResourceComponentsProps,
  useDelete,
  useInvalidate,
  useNavigation,
  useOne,
  useResource,
} from '@pankod/refine-core'
import Head from 'next/head'
import { useRouter } from 'next/router'
import React from 'react'

import { CreateParticipantSection } from '@components/DataTableParticipants/blocks/CreateParticipantSection'
import { showErrorNotification } from '@resources/angkatan-ppg-management/utils'
import { NestedBreadcrumb } from '@resources/lms-management/components/NestedBreadcrumb'
import { PopDeleteConfirm } from '@components/PopDeleteConfirm'
// import { TableResourceParticipantAlloc } from '@components/DataTableParticipants/TableResourceParticipantAlloc'
import { ModalDasusClassForm } from './block/ModalDasusClassForm'
import { TabContentAllocFacil } from './block/TabContentAllocFacil'

export const DasusClassManagementShow: React.FC<IResourceComponentsProps> = ({
  options,
}) => {
  const { query } = useRouter()
  const { goBack, showUrl, listUrl } = useNavigation()
  const { id } = useResource({
    resourceNameOrRouteName: 'dasus-class-management',
  })
  const dasusProgramId = query.dasusProgramId as string
  const dasusProgramLocalId = query.dasusProgramLocalId as string

  const CLASS_RESOURCE = `program-dasus/${dasusProgramId}/local-programs/${dasusProgramLocalId}/classes`

  const [activeKey, setActiveKey] = React.useState('fasil')
  const [showImportModal, setShowImportModal] = React.useState(false)

  const invalidate = useInvalidate()

  const { data: classData, isLoading } = useOne({
    resource: CLASS_RESOURCE,
    dataProviderName: 'lms',
    id,
    queryOptions: {
      enabled: !!dasusProgramId && !!dasusProgramLocalId,
    },
  })

  const { mutateAsync: doDelete } = useDelete()

  const {
    modalProps: editModalProps,
    formProps: editFormProps,
    show: editShow,
    close: editClose,
  } = useModalForm({
    action: 'edit',
    resource: CLASS_RESOURCE,
    dataProviderName: 'lms',
    autoResetForm: true,
    redirect: false,
    successNotification: {
      message: 'Kelas successfully updated',
      type: 'success',
      description: 'Successful',
    },
    errorNotification: (err) =>
      showErrorNotification(err, 'There was a problem when updating Kelas'),
  })

  if (!dasusProgramId || !dasusProgramLocalId)
    return (
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
      />
    )

  return (
    <>
      <Show
        title="Kelas Detail"
        resource={CLASS_RESOURCE}
        isLoading={isLoading}
        canDelete={false}
        breadcrumb={
          <NestedBreadcrumb
            injectedItems={[
              {
                label: `Program`,
                href: listUrl('dasus-program-management'),
              },
              {
                label: dasusProgramId,
                href: showUrl('dasus-program-management', dasusProgramId),
              },
              {
                label: `Program Local - Kelas List`,
                href:
                  showUrl(
                    'dasus-program-local-management',
                    dasusProgramLocalId
                  ) +
                  `?activeTab=programLocal&dasusProgramId=${dasusProgramId}`,
              },
              {
                label: `${id}`,
              },
            ]}
          />
        }
        headerButtons={() => (
          <Space>
            {activeKey === 'detail' && (
              <>
                <EditButton
                  onClick={() => {
                    editShow(id)
                  }}
                >
                  Edit Kelas
                </EditButton>
                <PopDeleteConfirm
                  title={`Are you sure want to delete Kelas "${classData?.data.data.name}"?`}
                  placeholder="Type Kelas name to confirm"
                  errorMessage="Kelas Name did not match"
                  placement="bottomRight"
                  onConfirm={() =>
                    doDelete(
                      {
                        id,
                        resource: CLASS_RESOURCE,
                        dataProviderName: 'lms',
                        successNotification: {
                          message: 'Kelas successfully deleted',
                          type: 'success',
                          description: 'Successful',
                        },
                      },
                      {
                        onSuccess: () => goBack(),
                      }
                    )
                  }
                  okButtonProps={{
                    danger: true,
                    type: 'default',
                  }}
                  okText="Delete"
                  validateValue={classData?.data.data.name!}
                >
                  <Button danger icon={<Icons.DeleteOutlined />}>
                    Delete Kelas
                  </Button>
                </PopDeleteConfirm>
              </>
            )}
          </Space>
        )}
      >
        <Head>
          <title>Dasus Admin | {options?.label} </title>
        </Head>
        <Modal
          title="Tambah Partisipan"
          visible={showImportModal}
          onCancel={() => setShowImportModal(false)}
          className="upload-or-select-modal"
          destroyOnClose
          footer={null}
        >
          <CreateParticipantSection
            onCancel={() => setShowImportModal(false)}
            customExampleUrl={`${process.env.BASE_PATH}/api/sample/csv-file?file=csv-peserta-kelas`}
            onSuccess={() => {
              invalidate({
                resource: `program-dasus/${dasusProgramId}/local-programs/${dasusProgramLocalId}/classes/${id}/participants`,
                dataProviderName: 'lms',
                invalidates: ['list'],
              })
            }}
            customSubmitResources={{
              resource: `program-dasus/${dasusProgramId}/local-programs/${dasusProgramLocalId}/classes/${id}/participants/import`,
            }}
          />
        </Modal>

        <Tabs
          defaultActiveKey="fasil"
          activeKey={activeKey}
          onChange={setActiveKey}
          destroyInactiveTabPane
        >
          {/* <Tabs.TabPane tab="Detail" key="detail">
            <Divider style={{ marginBottom: 0 }} />
            <div className="mt-4">
              <div className="flex flex-row items-center justify-between mb-4">
                <p className="text-lg font-bold">Peserta Kelas</p>

                <Button
                  type="ghost"
                  icon={<Icons.UploadOutlined />}
                  onClick={() => setShowImportModal(true)}
                >
                  Import Peserta
                </Button>
              </div>
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Daftar Peserta" key="peserta">
            <TableResourceParticipantAlloc listResource="program-dasus" />
          </Tabs.TabPane> */}
          <Tabs.TabPane tab="Daftar Fasil" key="fasil">
            <TabContentAllocFacil
              dasusProgramId={dasusProgramId}
              dasusProgramLocalId={dasusProgramLocalId}
              classId={id}
            />
          </Tabs.TabPane>
          {/* <Tabs.TabPane tab="Alokasi Peserta" key="alloc-peserta">
            <TableResourceParticipantAlloc listResource="program-dasus" />
          </Tabs.TabPane> */}
        </Tabs>
      </Show>

      <ModalDasusClassForm
        formProps={editFormProps}
        modalProps={editModalProps}
        mode="edit"
        onClose={() => {
          editFormProps.form?.resetFields()
          editClose()
        }}
      />
    </>
  )
}
