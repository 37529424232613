import clsx from 'clsx'
import { capitalize } from 'lodash'
import moment, { Moment } from 'moment'
import React, { useState } from 'react'
import { Controller, UseFormReturn, useController } from 'react-hook-form'
import 'moment/locale/id'
import { IconButton } from '@instructure/ui-buttons'
import { Calendar } from '@instructure/ui-calendar'
import {
  DateInputProps,
  DateInput as LibraryDateInput,
} from '@instructure/ui-date-input'
import {
  IconArrowOpenEndSolid,
  IconArrowOpenStartSolid,
} from '@instructure/ui-icons'
import { ViewProps } from '@instructure/ui-view'
moment.locale('id')

const parseDate = (dateStr: string | null) => {
  return moment(dateStr, [
    moment.ISO_8601,
    'llll',
    'LLLL',
    'lll',
    'LLL',
    'll',
    'LL',
    'l',
    'L',
  ])
}

type TDateInput = {
  control?: UseFormReturn['control']
  name: string
  label: string
  required?: boolean
  maxDate?: string
  minDate?: string
  placeholder?: string
}

type Message = Exclude<DateInputProps['messages'], undefined>

const DateInput = (props: TDateInput) => {
  const { field } = useController({ control: props.control, name: props.name })
  const [state, setState] = useState<{
    value: string
    isShowingCalendar: boolean
    todayDate: string
    selectedDate: string | null
    renderedDate: string
    messages: Message
  }>({
    value: field.value ? moment(field.value).format('DD MMMM YYYY') : '',
    isShowingCalendar: false,
    todayDate: moment().toISOString(),
    selectedDate: field.value ? moment(field.value).toISOString() : null,
    renderedDate: moment().toISOString(),
    messages: [],
  })

  function generateMonth(renderedDate = state.renderedDate) {
    const date = parseDate(renderedDate).startOf('month').startOf('week')

    return [...Array(Calendar.DAY_COUNT)].map(() => {
      const currentDate = date.clone()
      date.add(1, 'days')
      return currentDate
    })
  }

  function formatDate(dateInput: string) {
    const date = parseDate(dateInput)
    return date.format('DD MMMM YYYY')
  }

  function handleChange(
    event: React.ChangeEvent<HTMLInputElement>,
    { value }: { value: string },
    onChange: (...event: unknown[]) => void
  ) {
    const newDateStr = parseDate(value).toISOString()

    setState(({ renderedDate, ...rest }) => ({
      ...rest,
      value,
      selectedDate: newDateStr,
      renderedDate: newDateStr || renderedDate,
      messages: [],
    }))

    if (!parseDate(value).isValid()) {
      onChange(undefined)
      return
    }

    onChange(parseDate(value).format('YYYY-MM-DD'))
  }

  function handleShowCalendar() {
    setState((state) => ({ ...state, isShowingCalendar: true }))
  }

  function handleHideCalendar() {
    setState((state) => {
      return {
        ...state,
        isShowingCalendar: false,
        value: state.selectedDate
          ? formatDate(state.selectedDate)
          : state.value,
      }
    })
  }

  function handleValidateDate() {
    setState(({ selectedDate, value, ...rest }) => {
      // We don't have a selectedDate but we have a value. That means that the value
      // could not be parsed and so the date is invalid
      if (!selectedDate && value) {
        const todayISOString = moment().toISOString()
        return {
          ...rest,
          selectedDate: todayISOString,
          value: formatDate(todayISOString),
          messages: [
            { type: 'error', text: 'This date is invalid' },
          ] as Message,
        }
      }
      // Display a message if the user has typed in a value that corresponds to a
      // disabledDate
      if (isDisabledDate(parseDate(selectedDate))) {
        const todayISOString = moment().toISOString()
        return {
          ...rest,
          selectedDate: todayISOString,
          value: formatDate(todayISOString),
          messages: [
            { type: 'error', text: 'This date is disabled' },
          ] as Message,
        }
      }

      return {
        ...rest,
        selectedDate,
        value,
      }
    })
  }

  function handleDayClick(
    event: React.MouseEvent<ViewProps, MouseEvent>,
    { date }: { date: string },
    onChange: (...event: unknown[]) => void
  ) {
    setState((state) => ({
      ...state,
      selectedDate: date,
      renderedDate: date,
      messages: [],
    }))

    onChange(moment(date).format('YYYY-MM-DD'))
  }

  function handleSelectNextDay() {
    modifySelectedDate('day', 1)
  }

  function handleSelectPrevDay() {
    modifySelectedDate('day', -1)
  }

  function handleRenderNextMonth() {
    modifyRenderedDate('month', 1)
  }

  function handleRenderPrevMonth() {
    modifyRenderedDate('month', -1)
  }

  function modifyRenderedDate(type: 'day' | 'month', step: number) {
    setState(({ renderedDate, ...rest }) => {
      return { ...rest, renderedDate: modifyDate(renderedDate, type, step) }
    })
  }

  function modifySelectedDate(type: 'day' | 'month', step: number) {
    setState(({ selectedDate, renderedDate, ...rest }) => {
      // We are either going to increase or decrease our selectedDate by 1 day.
      // If we do not have a selectedDate yet, we'll just select the first day of
      // the currently rendered month instead.
      const newDate = selectedDate
        ? modifyDate(selectedDate, type, step)
        : parseDate(renderedDate).startOf('month').toISOString()

      const isDateDisabled = isDisabledDate(moment(newDate))
      const oldDate = selectedDate || moment().toISOString()

      return {
        ...rest,
        selectedDate: isDateDisabled ? oldDate : newDate,
        renderedDate: isDateDisabled ? oldDate : newDate,
        value: formatDate(isDateDisabled ? oldDate : newDate),
        messages: [],
      }
    })
  }

  function modifyDate(dateStr: string, type: 'day' | 'month', step: number) {
    const date = parseDate(dateStr)
    date.add(step, type)
    return date.toISOString()
  }

  function isDisabledDate(date: Moment) {
    if (!props.maxDate || !props.minDate) return false

    return !date.isBetween(props.minDate, props.maxDate, 'date', '[]')
  }

  function renderWeekdayLabels() {
    const date = parseDate(state.renderedDate).startOf('week')

    return new Array(7).fill(null).map((_, idx) => {
      const currentDate = date.clone()
      date.add(1, 'day')

      return (
        <div
          key={currentDate.format('dddd')}
          className={clsx([0, 6].includes(idx) && 'text-critical')}
        >
          {currentDate.format('dd')}
        </div>
      )
    })
  }

  function renderDays(onChange: (...event: unknown[]) => void) {
    const { renderedDate, selectedDate, todayDate } = state

    return generateMonth().map((date) => {
      const dateStr = date.toISOString()

      return (
        <LibraryDateInput.Day
          key={dateStr}
          date={dateStr}
          interaction={isDisabledDate(date) ? 'disabled' : 'enabled'}
          isSelected={date.isSame(selectedDate, 'day')}
          isToday={date.isSame(todayDate, 'day')}
          isOutsideMonth={!date.isSame(renderedDate, 'month')}
          label={date.format('D MMMM YYYY')}
          onClick={(e, { date }) => handleDayClick(e, { date }, onChange)}
        >
          {date.format('D')}
        </LibraryDateInput.Day>
      )
    })
  }

  const buttonProps = (type = 'prev') => ({
    withBackground: false,
    withBorder: false,
    renderIcon:
      type === 'prev' ? (
        <IconArrowOpenStartSolid color="primary" />
      ) : (
        <IconArrowOpenEndSolid color="primary" />
      ),
    screenReaderLabel: type === 'prev' ? 'Previous month' : 'Next month',
  })

  const date = parseDate(state.renderedDate)

  return (
    <Controller
      name={props.name}
      control={props.control}
      render={({ field: { onChange, ref }, fieldState: { error } }) => (
        <LibraryDateInput
          inputRef={ref}
          renderLabel={
            <div
              className="form-label ql-editor"
              dangerouslySetInnerHTML={{ __html: props.label }}
            />
          }
          assistiveText="Type a date or use arrow keys to navigate date picker."
          value={state.value}
          onChange={(e, { value }) => handleChange(e, { value }, onChange)}
          width="100%"
          required={props.required}
          messages={[
            ...state.messages,
            ...(error?.message
              ? ([
                  { type: 'error', text: capitalize(error.message) },
                ] as Message)
              : []),
          ]}
          placeholder={props.placeholder}
          isShowingCalendar={state.isShowingCalendar}
          onRequestValidateDate={() => handleValidateDate()}
          onRequestShowCalendar={() => handleShowCalendar()}
          onRequestHideCalendar={() => handleHideCalendar()}
          onRequestSelectNextDay={() => handleSelectNextDay()}
          onRequestSelectPrevDay={() => handleSelectPrevDay()}
          onRequestRenderNextMonth={() => handleRenderNextMonth()}
          onRequestRenderPrevMonth={() => handleRenderPrevMonth()}
          renderNavigationLabel={
            <span>
              <div>{date.format('MMMM')}</div>
              <div>{date.format('YYYY')}</div>
            </span>
          }
          renderPrevMonthButton={
            <IconButton size="small" {...buttonProps('prev')} />
          }
          renderNextMonthButton={
            <IconButton size="small" {...buttonProps('next')} />
          }
          renderWeekdayLabels={renderWeekdayLabels()}
        >
          {renderDays(onChange)}
        </LibraryDateInput>
      )}
    />
  )
}

export default DateInput
