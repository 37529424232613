import React from 'react'
import {
  List,
  // Select,
  useTable,
  Table,
  TextField,
  Space,
  EditButton,
  Button,
  Popconfirm,
  Icons,
} from '@pankod/refine-antd'
import Head from 'next/head'
import {
  IResourceComponentsProps,
  GetListResponse,
  CrudFilters,
  useGetIdentity,
} from '@pankod/refine-core'

import { TCommonError } from 'src/interfaces/common'
import Filter, { TFilterForm } from '@components/ContentFilter'
import { ModalLMSAdmin } from './blocks/ModalLMSAdmin'

import type {
  TLMSAdmin,
  TLMSRoleOption,
  TLMSModalType,
  TLMSAdminRole,
} from './types'

export const ROLE_OPTIONS: TLMSRoleOption[] = [
  {
    label: 'super-admin',
    value: 'super-admin',
  },
  {
    label: 'admin-entitas',
    value: 'admin-entitas',
  },
  {
    label: 'admin-pusat',
    value: 'admin-pusat',
  },
  {
    label: 'admin-lptk',
    value: 'admin-lptk',
  },
  {
    label: 'instruktur-webinar-pgp',
    value: 'instruktur-webinar-pgp',
  },
]

const ROLE_OPTIONS_RULES: Record<
  TLMSAdminRole,
  Record<'delete' | 'update', TLMSAdminRole[]>
> = {
  'super-admin': {
    update: [
      'admin-pusat',
      'admin-entitas',
      'admin-lptk',
      'instruktur-webinar-pgp',
    ],
    delete: [
      'admin-pusat',
      'admin-entitas',
      'admin-lptk',
      'instruktur-webinar-pgp',
    ],
  },
  'admin-pusat': {
    update: [],
    delete: ['admin-entitas', 'admin-lptk', 'instruktur-webinar-pgp'],
  },
  'admin-entitas': {
    update: [],
    delete: [],
  },
  'admin-lptk': {
    update: [],
    delete: [],
  },
  'instruktur-webinar-pgp': {
    update: [],
    delete: [],
  },
}

export const LMSAdminManagementList: React.FC<
  IResourceComponentsProps<GetListResponse<any>>
> = ({ options }) => {
  const { tableProps, searchFormProps } = useTable<
    TLMSAdmin,
    TCommonError,
    TFilterForm<TLMSAdmin>
  >({
    resource: 'admins',
    dataProviderName: 'lms',
    queryOptions: {
      initialData: {
        data: [],
        total: 0,
      },
    },
    onSearch: (params) => {
      const filters: CrudFilters = []
      const { email } = params
      filters.push(
        {
          field: 'email',
          operator: 'eq',
          value: email,
        },
        // disable for now
        // {
        //   field: 'role',
        //   operator: 'eq',
        //   value: role,
        // },
        {
          field: 't',
          operator: 'eq',
          value: new Date().getTime(),
        }
      )
      return filters
    },
    syncWithLocation: true,
  })

  const { data } = useGetIdentity<{ user: { LMSRole: TLMSAdminRole } }>()
  const LMSRole = data?.user?.LMSRole || ''

  const getActionACL = ({
    action,
    targetRole,
  }: {
    action: 'update' | 'delete'
    targetRole: TLMSAdminRole
  }) => {
    if (!LMSRole) return false
    const allowedRoles = ROLE_OPTIONS_RULES[LMSRole][action]

    return allowedRoles.includes(targetRole)
  }

  const [showModal, setShowModal] = React.useState<TLMSModalType>(null)
  const [modalFormInstance, setModalFormInstance] = React.useState<any>(null)

  return (
    <List
      title="LMS Admin Management"
      createButtonProps={{
        children: 'Assign New Admin',
        onClick: () => {
          setShowModal('create')
        },
      }}
    >
      <Head>
        <title>LMS Admin | {options?.label}</title>
      </Head>

      <ModalLMSAdmin
        showModal={showModal}
        setShowModal={setShowModal}
        roleOptions={ROLE_OPTIONS}
        onModalInit={setModalFormInstance}
      />

      <Filter
        formProps={searchFormProps}
        items={[
          {
            label: 'User Email',
            name: 'email',
            placeholder: 'Search by user email',
          },
          // {
          //   label: 'Role',
          //   name: 'role',
          //   component: <Select options={ROLE_OPTIONS} allowClear />,
          // },
        ]}
      />

      <Table {...tableProps} rowKey="email">
        <Table.Column
          dataIndex="email"
          title="User Email"
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="role"
          title="Role"
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          title="Actions"
          render={(_, record: any) => (
            <Space>
              {getActionACL({
                action: 'update',
                targetRole: record.role,
              }) && (
                <EditButton
                  size="small"
                  onClick={() => {
                    modalFormInstance.setFieldsValue({
                      email: record.email,
                      role: record.role,
                    })
                    setShowModal('update')
                  }}
                  disabled={
                    !getActionACL({
                      action: 'update',
                      targetRole: record.role,
                    })
                  }
                />
              )}
              <Popconfirm
                title="Are you sure want to delete this user?"
                onConfirm={() => {
                  modalFormInstance.setFieldsValue({
                    email: record.email,
                    role: record.role,
                  })
                  setShowModal('delete')
                }}
                okButtonProps={{
                  danger: true,
                  type: 'default',
                }}
                okText="Delete"
                disabled={
                  !getActionACL({
                    action: 'delete',
                    targetRole: record.role,
                  })
                }
              >
                <Button
                  size="small"
                  danger
                  icon={<Icons.DeleteOutlined />}
                  disabled={
                    !getActionACL({
                      action: 'delete',
                      targetRole: record.role,
                    })
                  }
                >
                  Delete
                </Button>
              </Popconfirm>
            </Space>
          )}
        />
      </Table>
    </List>
  )
}
