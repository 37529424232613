import { Divider, Form, FormInstance, Input, Select } from '@pankod/refine-antd'
import { useOne } from '@pankod/refine-core'
import React, { useMemo } from 'react'

import { TDistrictData } from '@components/DataTableParticipants/types'
import { useParticipantFilterContext } from 'src/hooks/context/useParticipantFilterOptionsContext'

const ROLE_OPTIONS = [
  {
    label: 'Asisten',
    value: 'asisten',
  },
  {
    label: 'Fasilitator',
    value: 'instruktur',
  },
  {
    label: 'Peserta',
    value: 'peserta',
  },
  {
    label: 'Admin Kelas',
    value: 'admin_kelas',
  },
]

type ParticipantDataProps = {
  form: FormInstance
  isReadOnly?: boolean
  programId?: string
  localProgramId?: string
  classId?: string
}
const ParticipantData = ({
  form,
  isReadOnly = false,
}: ParticipantDataProps) => {
  const { data: filterOptions } = useParticipantFilterContext()
  const kabupatenByProvinsi = useMemo(() => {
    return filterOptions?.data?.regions.reduce((prev, current) => {
      return { ...prev, [current.kode]: current.kabupaten }
    }, {} as Record<string, { kode: string; name: string }[]>)
  }, [filterOptions?.data?.regions])
  const kodeProvinsi = Form.useWatch(['provinsi', 'kode'], form)
  const kodeKotaKab = Form.useWatch(['kabupaten', 'kode'], form)
  const { data: districtOptions } = useOne<TDistrictData>({
    resource: `programs/participants/filter-options`,
    id: `kecamatan?kodeKabupaten=${kodeKotaKab}`,
    dataProviderName: 'lms',
    queryOptions: {
      enabled: !!kodeKotaKab,
    },
  })
  return (
    <Form
      form={form}
      layout="vertical"
      className="grid grid-cols-2 grid-flow-row gap-2"
      validateMessages={{ required: "'${label}' harus diisi" }}
    >
      <Form.Item hidden name="userId">
        <Input />
      </Form.Item>
      <Form.Item
        required
        rules={[{ required: true }]}
        name="email"
        label="Email"
        className="col-span-2"
      >
        <Input autoComplete="off" disabled />
      </Form.Item>
      <Form.Item required rules={[{ required: true }]} name="name" label="Nama">
        <Input disabled={isReadOnly} autoComplete="off" />
      </Form.Item>
      <Form.Item name="jenisPtk" label="Jenis PTK">
        <Input disabled={isReadOnly} autoComplete="off" />
      </Form.Item>
      <Form.Item name="nuptk" label="NUPTK">
        <Input disabled={isReadOnly} autoComplete="off" />
      </Form.Item>
      <Form.Item name="npsn" label="NPSN">
        <Input disabled={isReadOnly} autoComplete="off" />
      </Form.Item>
      <Divider className="col-span-2 !my-3" dashed />
      <Form.Item name={['provinsi', 'kode']} label="Provinsi">
        <Select
          options={filterOptions?.data?.regions?.map(({ kode, name }) => ({
            label: name,
            value: kode,
          }))}
          showSearch
          disabled={isReadOnly}
          filterOption={(input, option) =>
            ((option?.label ?? '') as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
        />
      </Form.Item>
      <Form.Item
        name={['kabupaten', 'kode']}
        label="Kota/Kabupaten"
        help={!kodeProvinsi ? 'Pilih provinsi terlebih dahulu' : undefined}
      >
        <Select
          options={kabupatenByProvinsi[kodeProvinsi]?.map(({ kode, name }) => ({
            label: name,
            value: kode,
          }))}
          showSearch
          disabled={!kodeProvinsi || isReadOnly}
          filterOption={(input, option) =>
            ((option?.label ?? '') as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
        />
      </Form.Item>
      <Form.Item
        name={['kecamatan', 'kode']}
        label="Kecamatan"
        help={!kodeKotaKab ? 'Pilih kota/kabupaten terlebih dahulu' : undefined}
      >
        <Select
          options={districtOptions?.data?.kecamatan?.map(({ kode, name }) => ({
            label: name,
            value: kode,
          }))}
          showSearch
          disabled={!kodeKotaKab || isReadOnly}
          filterOption={(input, option) =>
            ((option?.label ?? '') as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
        />
      </Form.Item>
      <Divider dashed className="col-span-2 !my-3" />
      <Form.Item name="jenjang" label="Jenjang">
        <Select disabled={isReadOnly}>
          {filterOptions?.data?.jenjang?.map((item: any) => (
            <Select.Option key={item} value={item}>
              {item}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name="mataPelajaran" label="Mata Pelajaran">
        <Input disabled={isReadOnly} />
      </Form.Item>
      <Form.Item
        name="roleKelas"
        label="Peran/Role"
        rules={[{ required: true }]}
      >
        <Select>
          {ROLE_OPTIONS.map((item) => (
            <Select.Option key={item.value} value={item.value}>
              {item.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </Form>
  )
}

export default ParticipantData
