import { Icons } from '@pankod/refine-antd'
import { ResourceProps } from '@pankod/refine-core'

import { OpsToolkitCSVUploaderList } from './csv-uploader/list'
import { LMSCanvasReplicaSetupList } from './lms-canvas-replica-setup/list'
import { PPGSyncProgressList } from './ppg-sync-progress/list'

export const opsToolkitManagementResource: ResourceProps[] = [
  {
    name: 'ops-toolkit',
    icon: <Icons.SettingOutlined />,
    options: {
      label: 'Ops Toolkit',
      route: 'ops-toolkit',
    },
  },
  {
    name: 'encoded-id-management',
    parentName: 'ops-toolkit',
    options: {
      label: 'Bulk User Encoded ID Getter',
      route: 'encoded-id-management',
    },
    list: OpsToolkitCSVUploaderList,
    show: OpsToolkitCSVUploaderList,
  },
  {
    name: 'lms-canvas-replica-setup',
    parentName: 'ops-toolkit',
    options: {
      label: 'LMS Canvas Replica Setup',
      route: 'lms-canvas-replica-setup',
    },
    list: LMSCanvasReplicaSetupList,
    // show: LMSCanvasReplicaSetupList,
  },
  {
    name: 'ppg-sync-progress',
    parentName: 'ops-toolkit',
    options: {
      label: 'PPG Sync Progress',
      route: 'ppg-sync-progress',
    },
    list: PPGSyncProgressList,
    // show: LMSCanvasReplicaSetupList,
  },
]
