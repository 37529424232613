import {
  Icons,
  message,
  Modal,
  RcFile,
  Space,
  Tabs,
  Typography,
  Upload,
  UploadFile,
  UploadProps,
  notification,
} from '@pankod/refine-antd'
import { UploadChangeParam } from 'antd/lib/upload'
import React from 'react'

import { PDF_MIME_LIST, IMAGE_MIME_LIST } from 'src/constant'
import { getGuruToken } from 'src/helpers/session'
import MediaLibrary from './MediaLibrary'

const { TabPane } = Tabs
const {
  AppstoreFilled,
  UploadOutlined,
  CloudUploadOutlined,
  LoadingOutlined,
  FilePdfFilled,
  FileFilled,
  SyncOutlined,
} = Icons

const getHelperText = (type?: string): string | null => {
  switch (type) {
    case 'image':
      return 'Only accept file with *.jpeg, *.jpg and *.png extension'
    case 'pdf':
      return 'Only accept file with *.pdf extension'
    default:
      return null
  }
}

type TPreviewFile = {
  fileUrl: string
  fileType: string | 'other'
}
const PreviewFile = (props: TPreviewFile) => {
  switch (props.fileType) {
    case 'image/png':
    case 'image/jpeg':
      return (
        <Space direction="vertical" size="middle">
          <img
            src={props.fileUrl}
            alt="Uploaded image"
            style={{ maxWidth: '100%', height: 'auto', maxHeight: '400px' }}
          />
          <Typography.Text ellipsis style={{ maxWidth: '400px' }} underline>
            {props.fileUrl}
          </Typography.Text>
        </Space>
      )
    case 'application/pdf':
      return (
        <Space direction="vertical" size="middle">
          <FilePdfFilled style={{ fontSize: '36px' }} />
          <Typography.Text ellipsis style={{ maxWidth: '400px' }} underline>
            {props.fileUrl}
          </Typography.Text>
        </Space>
      )
    default:
      return (
        <Space direction="vertical" size="middle">
          <FileFilled style={{ fontSize: '36px' }} />
          <Typography.Text ellipsis style={{ maxWidth: '400px' }} underline>
            {props.fileUrl}
          </Typography.Text>
        </Space>
      )
  }
}

const isFileValid = (file: File) => {
  const isPdf = PDF_MIME_LIST.includes(file.type)
  const isImage = IMAGE_MIME_LIST.includes(file.type)

  if (!isImage && !isPdf) {
    message.error('You can only upload Image or PDF file!')
  }
  return isImage || isPdf
}

const getAcceptableType = (type?: 'image' | 'pdf') => {
  const imageType = 'image/png, image/jpeg'
  const pdfType = 'application/pdf, application/x-pdf'
  if (!type) return `${imageType}, ${pdfType}`
  if (type === 'image') return imageType
  return pdfType
}

export type TUploadOrSelectModalProps = {
  isShown: boolean
  setIsShown: (shown: boolean) => void
  onOk: (fileUrl: string | null, fileType: string) => void | Promise<void>
  onCancel: () => void
  type?: 'image' | 'pdf'
}
const UploadOrSelectModal = ({
  onCancel,
  onOk,
  isShown,
  setIsShown,
  type,
}: TUploadOrSelectModalProps) => {
  const [fileUrl, setFileUrl] = React.useState<string | null>(null)
  const [isUploading, setIsUploading] = React.useState(false)
  const [fileType, setFileType] = React.useState<string>('other')

  const resetField = () => {
    setFileType('other')
    setFileUrl(null)
  }

  const handleOk = async () => {
    setIsUploading(true)
    await onOk(fileUrl, fileType)
    setIsUploading(false)
    setIsShown(false)
    resetField()
  }

  const handleCancel = () => {
    onCancel()
    setIsShown(false)
    resetField()
  }

  const handleBeforeUpload = (file: RcFile) => {
    const isValid = isFileValid(file)

    return isValid || Upload.LIST_IGNORE
  }

  const handleOnChange = (info: UploadChangeParam<UploadFile>) => {
    if (info.file.status === 'uploading') {
      setIsUploading(true)
      setFileType(info.file.type || 'other')
      return
    }
    if (info.file.status === 'done') {
      const url = info.file.response?.data?.url
      setFileUrl(url)
      setIsUploading(false)
    }
    if (info.file.status === 'error') {
      setIsUploading(false)
      const errorMessage =
        info.file.response.errors?.[0]?.message ||
        'Unknown error, please try again later.'

      notification.error({ message: errorMessage })
    }
  }

  const handleOnDrop: UploadProps['onDrop'] = (e) => {
    const file = e.dataTransfer.files[0]
    isFileValid(file)
  }

  const helperText = getHelperText(type)
  return (
    <Modal
      title="Upload File or Select From Library"
      visible={isShown}
      onOk={handleOk}
      confirmLoading={isUploading}
      onCancel={handleCancel}
      className="upload-or-select-modal"
      destroyOnClose
      width={1200}
      okButtonProps={{ disabled: !fileUrl }}
    >
      <Tabs
        centered
        size="large"
        onChange={(activeKey) => {
          if (activeKey === 'upload') resetField()
        }}
      >
        <TabPane
          tab={
            <span>
              <UploadOutlined />
              Upload
            </span>
          }
          key="upload"
        >
          <Upload.Dragger
            accept={getAcceptableType(type)}
            action={`${process.env.NEXT_PUBLIC_LMS_API_URL}/upload`}
            beforeUpload={handleBeforeUpload}
            onDrop={handleOnDrop}
            headers={{
              Authorization: `Bearer ${getGuruToken()}`,
            }}
            name="file"
            onChange={handleOnChange}
            maxCount={1}
            showUploadList={false}
            disabled={isUploading}
          >
            {fileUrl && (
              <div className="upload-or-select-modal__preview-container">
                <PreviewFile fileUrl={fileUrl} fileType={fileType} />
                <div className="upload-or-select-modal__action-buttons">
                  <Space direction="vertical">
                    <SyncOutlined style={{ fontSize: '36px' }} />
                    <Typography.Text>Re-upload Image</Typography.Text>
                  </Space>
                </div>
              </div>
            )}
            {!fileUrl && (
              <>
                {isUploading ? (
                  <div>
                    <LoadingOutlined spin />
                    <div style={{ marginTop: 8 }}>Upload</div>
                  </div>
                ) : (
                  <div>
                    <p className="ant-upload-drag-icon">
                      <CloudUploadOutlined />
                    </p>
                    <p className="ant-upload-text">
                      Click or drag file to this area to upload
                    </p>
                    {helperText && (
                      <p className="ant-upload-hint">{helperText}</p>
                    )}
                  </div>
                )}
              </>
            )}
          </Upload.Dragger>
        </TabPane>
        <TabPane
          tab={
            <span>
              <AppstoreFilled />
              Library
            </span>
          }
          key="library"
          disabled
        >
          <MediaLibrary
            onSelected={(fileUrl, fileType) => {
              setFileUrl(fileUrl)
              setFileType(fileType)
            }}
          />
        </TabPane>
      </Tabs>
    </Modal>
  )
}

export default UploadOrSelectModal
