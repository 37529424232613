import {
  Form,
  FormInstance,
  InputNumber,
  Modal,
  Radio,
} from '@pankod/refine-antd'
import { useRouter } from 'next/router'
import React, { useState } from 'react'

export const useSelectFormTypeModal = (initialShow: boolean = false) => {
  const [form] = Form.useForm()
  const [visible, setVisible] = useState(initialShow)

  return {
    visible,
    show: () => setVisible(true),
    onClose: () => {
      setVisible(false)
      form.resetFields()
    },
    form,
  }
}

type SelectFormTypeModalProps = {
  visible: boolean
  onClose: () => void
  form: FormInstance
  onSubmit: (type: string, numberOfQuestions?: number) => void
}
const SelectFormTypeModal = ({
  form,
  onClose,
  visible,
  onSubmit,
}: SelectFormTypeModalProps) => {
  const router = useRouter()
  const formType = Form.useWatch('formType', form)
  return (
    <Modal
      title="Select Form Type"
      visible={visible}
      onCancel={() => {
        router.back()
      }}
      maskClosable={false}
      closable={false}
      onOk={() => form.submit()}
      okText="Create Form"
      cancelText="Back"
    >
      <Form
        form={form}
        layout="horizontal"
        onFinish={(values) => {
          onSubmit(values.formType, values.numberOfQuestions)
          onClose()
        }}
      >
        <Form.Item
          name="formType"
          label="Form Type"
          rules={[{ required: true }]}
          required
          initialValue={'general'}
        >
          <Radio.Group
            optionType="button"
            buttonStyle="solid"
            options={[
              { label: 'General', value: 'general' },
              { label: 'Likert Scale', value: 'likert' },
              { label: 'Quantitative', value: 'quantitative' },
            ]}
          />
        </Form.Item>
        {formType === 'likert' && (
          <Form.Item
            name="numberOfQuestions"
            label="Number of Questions (1 to 10)"
            rules={[{ required: true }]}
            required
          >
            <InputNumber min={1} max={10} />
          </Form.Item>
        )}
      </Form>
    </Modal>
  )
}

export default SelectFormTypeModal
