import {
  Element,
  useEditorReturnType,
  CreateHandlerOptions,
} from '@craftjs/core'
import { Button, Form } from '@pankod/refine-antd'
import clsx from 'clsx'
import { useRouter } from 'next/router'

import { WIDGET_NAME } from '@components/FormBuilder/schema'
import { pascalToTextCase } from 'src/helpers/text'
import { useFormContext } from '@components/FormBuilder/formContext'

export type TGenerateToolboxButtonsParams = {
  widget: {
    name: WIDGET_NAME
    widget: (props: any) => JSX.Element
    /** If `true` you can drop other widget inside this widget */
    isCanvas?: boolean
    defaultProps: Record<
      string,
      string | number | boolean | Function | Array<unknown>
    > | null
    canBePlacedOnRoot: boolean
    toolboxOptions?: {
      span?: 1 | 2 | 3
    }
  }
  options: {
    connectors: useEditorReturnType['connectors']
    isPreview: boolean
    disableNonRootWidget: boolean
    disabledWidgets?: string[]
    createCallback: CreateHandlerOptions
  }
}

export const generateWidgetButtons =
  (options: TGenerateToolboxButtonsParams['options']) =>
  (widgets: TGenerateToolboxButtonsParams['widget'][]) =>
    generateToolboxButtons(widgets, options)

const generateToolboxButtons = (
  widgets: TGenerateToolboxButtonsParams['widget'][],
  options: TGenerateToolboxButtonsParams['options']
) => {
  const {
    connectors,
    createCallback,
    disableNonRootWidget,
    isPreview,
    disabledWidgets = [],
  } = options
  const buttons: JSX.Element[] = widgets.map(
    ({
      canBePlacedOnRoot,
      name,
      widget: Widget,
      defaultProps,
      isCanvas = false,
      toolboxOptions,
    }) => {
      const reference = (ref: HTMLElement | null) =>
        isCanvas
          ? connectors.create(
              ref!,
              <Element is={Widget} canvas />,
              createCallback
            )
          : connectors.create(
              ref!,
              <Widget {...defaultProps} />,
              createCallback
            )
      const disabled = disabledWidgets.includes(name)
      const widgetName = pascalToTextCase(name)
      const spanClassname = ['col-span-1', 'col-span-2', 'col-span-3']

      return (
        <Button
          key={name}
          disabled={
            disabled ||
            isPreview ||
            (canBePlacedOnRoot ? false : disableNonRootWidget)
          }
          className={clsx(
            !disabled && !isPreview && !disableNonRootWidget && '!cursor-move',
            toolboxOptions?.span && spanClassname[toolboxOptions.span - 1]
          )}
          type="dashed"
          ref={reference}
        >
          <div className="overflow-hidden text-ellipsis" title={widgetName}>
            {widgetName}
          </div>
        </Button>
      )
    }
  )
  return buttons
}

export const useGetFormType = (): {
  formType: string
  questionsNo?: number
  quantitativeTotalMax?: number
} => {
  const MAX_QUESTIONS_NUMBER = 10
  const router = useRouter()
  const { formInformationInstance } = useFormContext()
  const likertOptions = Form.useWatch('likertOptions', formInformationInstance)
  const quantitativeTotalMax = Form.useWatch(
    'quantitativeTotalMax',
    formInformationInstance
  )

  if (likertOptions) {
    return {
      formType: 'likert',
      questionsNo: likertOptions.length,
    }
  }

  if (quantitativeTotalMax) {
    return {
      formType: 'quantitative',
    }
  }

  const { formType, questionsNo } = router.query
  let parsedQuestionsNo = Number.isNaN(questionsNo)
    ? undefined
    : Number(questionsNo)
  if (
    parsedQuestionsNo &&
    (parsedQuestionsNo < 1 || parsedQuestionsNo > MAX_QUESTIONS_NUMBER)
  ) {
    parsedQuestionsNo = undefined
  }
  return {
    formType: formType ? String(formType) : '',
    questionsNo: parsedQuestionsNo,
  }
}
