import { Descriptions, Show, Tabs } from '@pankod/refine-antd'
import {
  IResourceComponentsProps,
  useResource,
  useShow,
} from '@pankod/refine-core'
import Head from 'next/head'
import React, { useState } from 'react'

import { LPTKSingleResponse } from './types'
import { TCommonResponse } from 'src/interfaces/common'
import { AdminLPTKTable } from './blocks/AdminLPTK/AdminLPTKTable'
import { AdminKelasList } from './blocks/AdminKelas'
import { DosenList } from './blocks/Dosen'
import { GuruPamong } from './blocks/GuruPamong'
// import { SekolahList } from './blocks/Sekolah'
import { MahasiswaList } from './blocks/Mahasiswa'

const LPTK_RESOURCE = 'lptk'

export const LPTKShow: React.FC<IResourceComponentsProps> = ({ options }) => {
  const [activeTab, setActiveTab] = useState('admin-lptk')
  const resource = useResource()
  const id = resource.id as number
  const { queryResult } = useShow<TCommonResponse<LPTKSingleResponse>>({
    id,
    resource: LPTK_RESOURCE,
    dataProviderName: 'lms',
  })

  const data = queryResult?.data?.data?.data
  return (
    <Show
      title="Detail LPTK"
      isLoading={queryResult.isFetching}
      resource={LPTK_RESOURCE}
      dataProviderName="lms"
      canDelete={false}
      headerButtons={<></>}
    >
      <Head>
        <title>LMS Admin | {options?.label} </title>
      </Head>

      <Descriptions bordered column={1} className="mb-6">
        <Descriptions.Item label="ID">{data?.id}</Descriptions.Item>
        <Descriptions.Item label="Nama LPTK">{data?.name}</Descriptions.Item>
      </Descriptions>

      <Tabs
        activeKey={activeTab}
        onChange={setActiveTab}
        destroyInactiveTabPane
      >
        <Tabs.TabPane key="admin-lptk" tab="Admin LPTK">
          <AdminLPTKTable lptkId={id} dataSource={data?.admins} />
        </Tabs.TabPane>
        <Tabs.TabPane key="dosen" tab="Dosen">
          <DosenList lptkId={id} enableFilter />
        </Tabs.TabPane>
        <Tabs.TabPane key="guru-pamong" tab="Guru Pamong">
          <GuruPamong lptkId={id} enableFilter />
        </Tabs.TabPane>
        <Tabs.TabPane key="admin-kelas" tab="Admin Kelas">
          <AdminKelasList lptkId={id} enableFilter />
        </Tabs.TabPane>
        {/* <Tabs.TabPane key="sekolah" tab="Sekolah">
          <SekolahList lptkId={id} />
        </Tabs.TabPane> */}
        <Tabs.TabPane key="mahasiswa" tab="Mahasiswa">
          <MahasiswaList lptkId={id} enableFilter />
        </Tabs.TabPane>
      </Tabs>
    </Show>
  )
}
