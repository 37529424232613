import { Result, Icons } from '@pankod/refine-antd'
import { useGetIdentity } from '@pankod/refine-core'
import Head from 'next/head'
import React from 'react'

const Dashboard = () => {
  const identity = useGetIdentity()
  const name = identity.data?.user?.name

  return (
    <>
      <Head>
        <title>LMS Admin</title>
      </Head>
      <Result
        icon={<Icons.SmileTwoTone />}
        title={
          <div>
            Welcome aboard, <br />
            <strong>{name}</strong> <br />
            Have a great day!
          </div>
        }
      />
    </>
  )
}

export default Dashboard
