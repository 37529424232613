export enum PROGRAM_LOCAL_STATE {
  DRAFT = 'draft',
  PUBLISHED = 'published',
}

export type TLMSProgramLocal = {
  name: string
  id: number
  slug: string
  entity: {
    name: string
    id: number
  }
  status: PROGRAM_LOCAL_STATE
  programId: number
}

export type TLMSProgramLocalRequest = Omit<
  TLMSProgramLocal,
  'id' | 'slug' | 'entity' | 'program'
> & {
  entityId: number
}

export type TimespendList = {
  id: string
  name: string
  email: string
  phone: string
  kelas: Kelas
  duration: {
    async: number
    sync: number
  }
}

export type TimespendPaymentStatus =
  | 'PAYMENT_STATUS_NOT_PAID'
  | 'PAYMENT_STATUS_PAID'
export type TimespendEligibleStatus =
  | 'ELIGIBLE_STATUS_ELIGIBLE'
  | 'ELIGIBLE_STATUS_NOT_ELIGIBLE'

export type SyncRecording = {
  id: string
  url: string
  title: string
  duration: number
}

type Kelas = {
  id: string
  name: string
}

export type PeriodePenilaian = {
  id: string
  name: string
  asyncTargetDuration: number
  syncTargetDuration: number
}

export type PaymentProof = {
  id: string
  imageUrl: string
  amount: string
  paymentDate: string
  notes?: string
  paymentStatus: TimespendPaymentStatus
  eligibleStatus: TimespendEligibleStatus
}

export type Timespend = {
  periode: (TimespendDetail &
    PeriodePenilaian & { paymentProof: PaymentProof })[]
  info: {
    fasilitatorName: string
    kelasName: string
    targetDuration: {
      async: number
      sync: number
    }
  }
}

export type TimespendDetail = {
  async: {
    id: string
    name: string
    duration: number
  }[]
  sync: {
    id: string
    name: string
    recordings: SyncRecording[]
  }[]
}
