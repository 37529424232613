import { IResourceComponentsProps, GetListResponse } from '@pankod/refine-core'
import React from 'react'
import {
  Button,
  Icons,
  List,
  ShowButton,
  Space,
  Table,
  TableColumnGroupType,
  TableColumnType,
  useModalForm,
  useTable,
} from '@pankod/refine-antd'
import Head from 'next/head'

import { BatchRequest, BatchResponse } from './types'
import { TCommonError } from 'src/interfaces/common'
import { ModalForm } from './blocks/ModalForm'
import { HeaderTitle } from '@components/HeaderTableTitle'
import { showErrorNotification } from '@resources/angkatan-ppg-management/utils'

const BATCH_RESOURCE = 'angkatan'

export const BatchList: React.FC<
  IResourceComponentsProps<GetListResponse<BatchResponse>>
> = ({ options }) => {
  const { tableProps } = useTable<BatchResponse, TCommonError>({
    resource: BATCH_RESOURCE,
    dataProviderName: 'lms',
    syncWithLocation: true,
  })

  const {
    modalProps: createModalProps,
    formProps: createFormProps,
    show: createShow,
    close: createClose,
  } = useModalForm({
    action: 'create',
    resource: BATCH_RESOURCE,
    dataProviderName: 'lms',
    warnWhenUnsavedChanges: true,
    redirect: false,
    autoResetForm: true,
    autoSubmitClose: true,
    invalidates: ['list'],
    successNotification: {
      message: 'Batch (Angkatan) successfully created',
      type: 'success',
      description: 'Successful',
    },
    errorNotification: (err) =>
      showErrorNotification(
        err,
        'There was a problem when creating Batch (Angkatan)'
      ),
  })

  const {
    modalProps: editModalProps,
    formProps: editFormProps,
    show: editShow,
    close: editClose,
  } = useModalForm<BatchResponse, TCommonError, BatchRequest>({
    action: 'edit',
    resource: BATCH_RESOURCE,
    dataProviderName: 'lms',
    autoResetForm: true,
    redirect: false,
    successNotification: {
      message: 'Batch (Angkatan) successfully updated',
      type: 'success',
      description: 'Successful',
    },
    errorNotification: (err) =>
      showErrorNotification(
        err,
        'There was a problem when updating Batch (Angkatan)'
      ),
  })

  const columns: (
    | TableColumnType<BatchResponse>
    | TableColumnGroupType<BatchResponse>
  )[] = [
    {
      title: <HeaderTitle align="left" title="ID" />,
      key: 'id',
      dataIndex: 'id',
    },
    {
      title: <HeaderTitle align="left" title="Slug" />,
      key: 'slug',
      dataIndex: 'slug',
    },
    {
      title: <HeaderTitle align="left" title="Nama Angkatan" />,
      key: 'name',
      dataIndex: 'name',
    },
    {
      key: 'actions',
      render: (_, record) => (
        <Space>
          <ShowButton resource="batch-ppg-management" recordItemId={record.id}>
            Lihat
          </ShowButton>
          <Button
            icon={<Icons.EditOutlined />}
            onClick={() => editShow(record.id)}
          >
            Edit
          </Button>
        </Space>
      ),
    },
  ]

  return (
    <>
      <List
        title="Batch (Angkatan) List"
        headerButtons={() => (
          <Button
            icon={<Icons.PlusOutlined />}
            type="primary"
            onClick={() => createShow()}
          >
            Buat Angkatan
          </Button>
        )}
      >
        <Head>
          <title>LMS Admin | {options?.label}</title>
        </Head>

        <Table<BatchResponse> {...tableProps} columns={columns} rowKey="id" />
      </List>

      <ModalForm
        formProps={createFormProps}
        modalProps={createModalProps}
        mode="create"
        onClose={createClose}
      />

      <ModalForm
        formProps={editFormProps}
        modalProps={editModalProps}
        mode="edit"
        onClose={editClose}
      />
    </>
  )
}
