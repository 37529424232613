import React, { useCallback, useState } from 'react'
import {
  Button,
  Drawer,
  Icons,
  Space,
  Table,
  Tag,
  TextField,
} from '@pankod/refine-antd'
import nookies from 'nookies'
import { useCustomMutation } from '@pankod/refine-core'

import {
  FormDataStatusType,
  PenilaianWebinar,
} from '@resources/webinar-management/types'
import PagePreviewDrawer from '@components/FormBuilder/PagePreviewDrawer'
import { SESSION } from 'src/constant'
import { useGetFormResponse } from './hooks'

export type PenilaianWebinarDrawerState = {
  show: boolean
  webinarName: string
  webinarId: string
  webinarKelasGroupId: string
}

const getTagStatus = (
  status?: FormDataStatusType,
  isPending: boolean = false
) => {
  if (isPending)
    return (
      <Tag icon={<Icons.SyncOutlined spin />} color="processing">
        Diproses
      </Tag>
    )
  switch (status) {
    case 'FORM_FINISHED':
      return (
        <Tag icon={<Icons.CheckCircleFilled />} color="success">
          Selesai
        </Tag>
      )
    case 'FORM_DRAFT':
      return (
        <Tag icon={<Icons.ExclamationCircleFilled />} color="warning">
          Draft
        </Tag>
      )
    default:
      return (
        <Tag icon={<Icons.CloseCircleFilled />} color="error">
          Belum Diisi
        </Tag>
      )
  }
}

export const usePenilaianWebinarDrawer = () => {
  const [ids, setIds] = useState<PenilaianWebinarDrawerState>({
    show: false,
    webinarName: '',
    webinarId: '',
    webinarKelasGroupId: '',
  })
  const onClose = useCallback(() => {
    setIds((state) => ({
      ...state,
      show: false,
    }))
  }, [])

  return {
    show: (param: Omit<PenilaianWebinarDrawerState, 'show'>) => {
      setIds({ show: true, ...param })
    },
    ids,
    onClose,
  }
}

type PenilaianWebinarDrawerProps = {
  ids: PenilaianWebinarDrawerState
  onClose: () => void
  loading?: boolean
  onSuccessSubmit: () => Promise<any>
  penilaianWebinarList: PenilaianWebinar['indicators']
}

const PenilaianWebinarDrawer = ({
  ids,
  onClose,
  loading,
  onSuccessSubmit,
  penilaianWebinarList,
}: PenilaianWebinarDrawerProps) => {
  const encodedUserId = JSON.parse(nookies.get()[SESSION.USER_SESSION])?.user
    ?.id
  const { mutateAsync, isLoading: isSubmitting } = useCustomMutation()
  const [selectedPage, setSelectedPage] = useState<{
    formId: string | null
    type: 'qualitative' | 'quantitative'
    penilaianId: string | null
    penilaianName: string | null
  }>({
    formId: null,
    type: 'qualitative',
    penilaianId: null,
    penilaianName: null,
  })
  const { isFetching, data } = useGetFormResponse({
    encodedUserId,
    ids,
    selectedPage,
  })

  const handleClose = () => {
    onClose()
  }

  const handleSubmit = async ({
    isDraft,
    formData,
    rules,
  }: {
    isDraft: boolean
    formData: any
    rules?: any
  }) => {
    const meta = {
      isDraft,
      encodedUserId,
      moduleId: ids.webinarId,
      webinarPenilaianId: selectedPage.penilaianId!,
      webinarKelasGroupId: ids.webinarKelasGroupId,
      responseType: selectedPage.type,
      formType: 'penilaianWebinar',
      isInstrukturWebinar: true,
    }
    const formIdentifier = `${meta.moduleId}_${meta.webinarKelasGroupId}_${meta.webinarPenilaianId}_${encodedUserId}_${selectedPage.type}_penilaianWebinar`
    const formId = selectedPage.formId

    const url = `/api/form-validator/${formId}/${formIdentifier}`

    const payload = {
      formData,
      meta,
      rules: rules || '{}',
    }

    await mutateAsync(
      {
        method: 'post',
        url,
        values: payload,
        successNotification: {
          message: 'Form berhasil dikirim',
          type: 'success',
          description: 'Sukses',
        },
        errorNotification: () => ({
          message: 'Terdapat gangguan saat mengirim form:',
          type: 'error',
          description: 'Error',
        }),
      },
      {
        onSuccess: async () => {
          await onSuccessSubmit()
          setSelectedPage((state) => ({ ...state, formId: null }))
        },
      }
    )
  }

  const isDraft =
    typeof data?.data?.meta?.isDraft === 'undefined' ||
    !!data?.data?.meta?.isDraft
  return (
    <Drawer
      visible={ids.show}
      onClose={handleClose}
      width={'75%'}
      title={`Daftar Penilaian Webinar | ${ids.webinarName}`}
    >
      <Table
        dataSource={penilaianWebinarList}
        rowKey={'id'}
        className="overflow-auto"
        loading={loading}
        columns={[
          { title: 'Nama Penilaian', dataIndex: 'name' },
          {
            title: 'Form Kuantitatif',
            dataIndex: 'formKuantitatif',
            render: ({ id }, record) => (
              <Space>
                <Button
                  icon={<Icons.FormOutlined />}
                  onClick={() =>
                    setSelectedPage({
                      formId: id,
                      type: 'quantitative',
                      penilaianId: record.id,
                      penilaianName: record.name,
                    })
                  }
                >
                  Isi Form
                </Button>
                {getTagStatus(
                  record.formKuantitatif.status,
                  record.formKuantitatif.isPending
                )}
              </Space>
            ),
          },
          {
            title: 'Form Kualitatif',
            dataIndex: 'formKualitatif',
            render: (value, record) =>
              !!value?.id ? (
                <Space>
                  <Button
                    icon={<Icons.FormOutlined />}
                    onClick={() =>
                      setSelectedPage({
                        formId: value.id,
                        type: 'qualitative',
                        penilaianId: record.id,
                        penilaianName: record.name,
                      })
                    }
                  >
                    Isi Form
                  </Button>
                  {getTagStatus(
                    record.formKualitatif?.status,
                    record.formKualitatif?.isPending
                  )}
                </Space>
              ) : (
                <TextField value="Tidak ada form" italic type="secondary" />
              ),
          },
        ]}
      />
      <PagePreviewDrawer
        initialValue={data?.data?.formValue}
        selectedId={selectedPage.formId}
        setSelectedId={(id) =>
          setSelectedPage((state) => ({ ...state, formId: id }))
        }
        title={`Form Penilaian ${
          selectedPage.type === 'qualitative' ? 'Kualitatif' : 'Kuantitatif'
        } | ${selectedPage.penilaianName}`}
        loading={isSubmitting || isFetching}
        submitButtonProps={{
          color: 'primary',
          children: 'Simpan',
        }}
        extra={(() => {
          if (typeof data?.data?.meta?.isDraft === 'undefined')
            return <Tag color="error">Belum Diisi</Tag>
          if (isDraft) return <Tag color="warning">Draft</Tag>
          return <Tag color="success">Selesai</Tag>
        })()}
        draftButtonProps={
          isDraft
            ? {
                children: 'Draf',
              }
            : undefined
        }
        drawerProps={{
          maskClosable: false,
          width: '50%',
        }}
        backButtonProps={{
          children: 'Kembali',
          onClick: () =>
            setSelectedPage((state) => ({ ...state, formId: null })),
        }}
        onSubmit={(formData, rules) =>
          handleSubmit({
            isDraft: false,
            formData,
            rules,
          })
        }
        onDraft={(formData) =>
          handleSubmit({
            isDraft: true,
            formData,
          })
        }
      />
    </Drawer>
  )
}

export default PenilaianWebinarDrawer
