import React from 'react'
import { useNode, Node, useEditor } from '@craftjs/core'
import clsx from 'clsx'
import { Form, Input, Switch } from '@pankod/refine-antd'
import dayjs, { Dayjs } from 'dayjs'

import {
  DatePickerCustomProps,
  TDatePicker,
  WIDGET_NAME,
} from '@components/FormBuilder/schema'
import {
  mappedWidgetSettingsError,
  widgetSettingsOnValueChange,
  getWidgetSettingsFields,
} from '@components/FormBuilder/utils'
import DatePickerWS from '@components/FormBuilder/editor/widgetSettings/DatePickerWS'
import { useFormContext } from '@components/FormBuilder/formContext'
import DateInput from './DateInput'
import ContentForm from '@components/ContentBank/ContentForm'

export const defaultProps: TDatePicker = {
  label: 'Date label',
  name: 'dateName',
  placeholder: 'Pilih tanggal..',
}

const DatePicker = ({
  label,
  placeholder,
  minValue,
  maxValue,
  isRequired,
  name,
}: TDatePicker) => {
  const {
    connectors: { connect, drag },
  } = useNode()

  const {
    query: { getState },
  } = useEditor()

  const {
    options: { enabled },
  } = getState()

  const { control } = useFormContext()

  return (
    <div
      ref={(ref) => connect(drag(ref!))}
      className={clsx(
        'mb-3 last:mb-0',
        'widget-component',
        'widget-component--date-picker',
        'text-default',
        'relative'
      )}
    >
      {enabled && (
        <div className="italic block absolute right-2 text-subdued">
          Drag Here
        </div>
      )}
      <DateInput
        control={control}
        label={label}
        name={name}
        maxDate={maxValue}
        minDate={minValue}
        required={isRequired}
        placeholder={placeholder}
      />
    </div>
  )
}

const DatePickerSettings = () => {
  const {
    actions: { setProp, setCustom },
    props,
    custom,
  } = useNode((node) => ({
    props: node.data.props,
    custom: node.data.custom,
  }))
  const errors = mappedWidgetSettingsError(custom.errors)
  return (
    <Form
      onValuesChange={(changedValue) =>
        widgetSettingsOnValueChange(changedValue, custom, setCustom)
      }
      fields={getWidgetSettingsFields(props)}
      layout="vertical"
    >
      <Form.Item
        label="Name Key"
        name="name"
        validateStatus={errors['name'] && 'error'}
        help={errors['name']}
      >
        <Input
          autoComplete="off"
          value={props.name}
          onChange={(e) =>
            setProp(
              (props: TDatePicker) =>
                (props.name = e.target.value.replaceAll(' ', ''))
            )
          }
        />
      </Form.Item>
      <Form.Item
        label="Label"
        name="label"
        validateStatus={errors['label'] && 'error'}
        help={errors['label']}
      >
        <ContentForm
          index="widgetText"
          showDeleteButton={false}
          showIndentation={false}
          showUploader={false}
          showTitle={false}
          value={props.text}
          onChange={(text) =>
            setProp((props: TDatePicker) => (props.label = text))
          }
        />
      </Form.Item>
      <Form.Item
        label="Required"
        name="isRequired"
        validateStatus={errors['isRequired'] && 'error'}
        help={errors['isRequired']}
      >
        <Switch
          checked={props.isRequired}
          onChange={(e) =>
            setProp((props: TDatePicker) => (props.isRequired = e))
          }
        />
      </Form.Item>
      <Form.Item
        label="Minimum Date Value"
        name="minValue"
        validateStatus={errors['minValue'] && 'error'}
        help={errors['minValue']}
        getValueProps={(value) => (value ? dayjs(value) : value)}
      >
        <DatePickerWS
          value={props.minValue ? dayjs(props.minValue) : props.minValue}
          onChange={(date?: Dayjs) => {
            setProp(
              (props: TDatePicker) =>
                (props.minValue = date?.format('YYYY-MM-DD'))
            )
          }}
        />
      </Form.Item>
      <Form.Item
        label="Maximum Date Value"
        name="maxValue"
        validateStatus={errors['maxValue'] && 'error'}
        help={errors['maxValue']}
        getValueProps={(value) => (value ? dayjs(value) : value)}
      >
        <DatePickerWS
          value={props.maxValue ? dayjs(props.maxValue) : props.maxValue}
          onChange={(date?: Dayjs) =>
            setProp(
              (props: TDatePicker) =>
                (props.maxValue = date?.format('YYYY-MM-DD'))
            )
          }
        />
      </Form.Item>
      <Form.Item
        label="Placeholder"
        name="placeholder"
        validateStatus={errors['placeholder'] && 'error'}
        help={errors['placeholder']}
      >
        <Input
          autoComplete="off"
          value={props.placeholder}
          onChange={(e) =>
            setProp(
              (props: TDatePicker) => (props.placeholder = e.target.value)
            )
          }
        />
      </Form.Item>
    </Form>
  )
}

DatePicker.craft = {
  rules: {
    canDrop: (targetNode: Node) => {
      return targetNode.data.name === WIDGET_NAME.Container
    },
  },
  props: defaultProps,
  custom: DatePickerCustomProps,
  related: {
    settings: DatePickerSettings,
  },
}

export { DatePicker }
