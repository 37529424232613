import { Form, Icons, InputNumber, Table, TextField } from '@pankod/refine-antd'
import React from 'react'
import { set } from 'lodash'

type ActivityMaxDurationTableProps = {
  value?: any[]
  onChange?: (value: any[]) => void
}

const ActivityMaxDurationTable = ({
  value = [],
  onChange,
}: ActivityMaxDurationTableProps) => {
  return (
    <Table
      dataSource={value}
      scroll={{ y: 200, x: 400 }}
      rowKey={({ id, type }) => `${id}_${type}`}
      pagination={false}
      tableLayout="fixed"
      columns={[
        {
          title: 'ID',
          dataIndex: 'id',
          render: (value) => <TextField value={value} code />,
          width: 150,
          onCell: () => ({
            className: 'align-top',
          }),
        },
        {
          title: 'Nama',
          dataIndex: 'title',
          ellipsis: true,
          onCell: () => ({
            className: 'align-top',
          }),
        },
        {
          title: 'Tipe',
          dataIndex: 'type',
          onCell: () => ({
            className: 'align-top',
          }),
        },
        {
          title: 'Durasi Maksimum',
          dataIndex: 'maxDuration',
          width: 200,
          fixed: 'right',
          render: (currentValue, _rec, index) => (
            <InputNumber
              value={currentValue / 60}
              addonAfter="Menit"
              className="w-44"
              addonBefore={<Icons.ClockCircleOutlined />}
              onChange={(val) => {
                onChange?.([...set(value, [index, 'maxDuration'], val * 60)])
              }}
              controls={false}
            />
          ),
          onCell: () => ({
            className: 'align-top',
          }),
        },
        {
          title: 'Prioritas Urutan',
          dataIndex: 'sortPriority',
          width: 220,
          fixed: 'right',
          render: (currentValue, _rec, index) => {
            const hasError = !currentValue
            const errorMessage = 'Prioritas Urutan harus diisi.'
            return (
              <Form.Item
                validateStatus={hasError ? 'error' : ''}
                help={hasError ? errorMessage : ''}
              >
                <InputNumber
                  value={currentValue}
                  className="w-44"
                  addonBefore={<Icons.SortAscendingOutlined />}
                  min={1}
                  onChange={(val) => {
                    onChange?.([...set(value, [index, 'sortPriority'], val)])
                  }}
                  controls={false}
                />
              </Form.Item>
            )
          },
        },
      ]}
    />
  )
}

export default ActivityMaxDurationTable
