import { SerializedNodes } from '@craftjs/core'
import { ZodError } from 'zod'
import _ from 'lodash'

import { SCHEMA_VALIDATOR, TWidgetCustomProps, WIDGET_TYPE } from './schema'

type TValidateContentSchema = (param: SerializedNodes) => Promise<boolean>
export const validateContentSchema: TValidateContentSchema = (
  serializedNodes
) => {
  const validWidgetIds = Object.keys(serializedNodes)
  const formWidgetNameSet = new Set<string>([])
  const formWidgetNameDuplicates: string[] = []

  try {
    const validationResults: ZodError<{
      [x: string]: any
    }>[] = []
    validWidgetIds.forEach((key) => {
      const nodeObject = serializedNodes[key]
      const nodeName = (nodeObject.custom as TWidgetCustomProps).name
      const validator = SCHEMA_VALIDATOR[nodeName]

      if (validator) {
        /**
         * Parse/validate your data from schema.
         * `checkProps` is the location where your data will be validated.
         * `schema` is the data prototype that will be used to check the data.
         * */
        const result = validator.schema.safeParse(
          nodeObject[validator.checkProps],
          {
            path: [key],
          }
        )
        if (!result.success) {
          validationResults.push(result.error)
        }
        if (nodeObject.custom?.type === WIDGET_TYPE.Form) {
          if (formWidgetNameSet.has(nodeObject.props.name)) {
            formWidgetNameDuplicates.push(key)
          }
          formWidgetNameSet.add(nodeObject.props.name)
        }
      } else {
        Promise.reject(`Widget not recogized (ID: ${key})`)
      }
    })
    if (validationResults.length > 0) {
      const errors = validationResults.flatMap(({ issues }) => issues)
      return Promise.reject(errors)
    }
    if (formWidgetNameDuplicates.length > 0) {
      const errors = formWidgetNameDuplicates.map((key) => ({
        message: 'Found duplicated name key in other field',
        path: [key, 'name'],
      }))
      return Promise.reject(errors)
    }
    return Promise.resolve(true)
  } catch (error: unknown) {
    return Promise.reject(error)
  }
}

export const widgetSettingsOnValueChange = (
  changedValue: Record<string, any> | undefined,
  custom: { errors: { label: string; message: string }[] },
  setCustom: (cb: any, throttleRate?: number | undefined) => void
) => {
  if (
    (custom.errors && custom.errors?.length === 0) ||
    typeof changedValue === 'undefined'
  )
    return

  if (custom.errors?.length > 0) {
    setCustom((custom: { errors: { label: string; message: string }[] }) => {
      custom.errors = custom.errors.filter(
        ({ label }) => label !== Object.keys(changedValue)[0]
      )
    })
  }
}

export const mappedWidgetSettingsError = (
  errors: { label: string; message: string }[] = []
) => {
  const result: Record<string, string> = {}
  errors.forEach(({ label, message }) => {
    result[label] = message
  })
  return result
}

export const getWidgetSettingsFields = (
  props: Record<string, string | number>
): { name: string; value: string | number }[] =>
  Object.keys(props).map((key) => ({
    name: key,
    value: props[key],
  }))

export const capitalized = (word?: string) =>
  !!word ? word.charAt(0).toUpperCase() + word.slice(1) : undefined

export const isOptionsUnique = (
  arr: { label: string; value: string }[]
): boolean => {
  const [labels, values] = arr.reduce(
    ([labels, values], current) => {
      return [
        [...labels, current.label],
        [...values, current.value],
      ]
    },
    [[], []] as [string[], string[]]
  )
  const hasDuplicate =
    _.uniq(values).length !== values.length ||
    _.uniq(labels).length !== labels.length
  return !hasDuplicate
}
