import { StepsProps } from '@pankod/refine-antd'
import clsx from 'clsx'
import { useOne } from '@pankod/refine-core'

import {
  FINALIZATION_CONFIG_STAGE,
  PROGRAM_STATE,
  TLMSProgramDetail,
} from './types'

export const getAvailableStatus = (
  status: PROGRAM_STATE | undefined
): {
  status: [StepsProps['status'], StepsProps['status'], StepsProps['status']]
  nextState: PROGRAM_STATE | null
} => {
  if (!status) return { status: ['wait', 'wait', 'wait'], nextState: null }
  const options: Record<
    string,
    {
      status: [StepsProps['status'], StepsProps['status'], StepsProps['status']]
      nextState: PROGRAM_STATE | null
    }
  > = {
    [PROGRAM_STATE.PREPARATION]: {
      status: ['process', 'wait', 'wait'],
      nextState: PROGRAM_STATE.RUNNING,
    },
    [PROGRAM_STATE.RUNNING]: {
      status: ['finish', 'process', 'wait'],
      nextState: PROGRAM_STATE.ENDED,
    },
    [PROGRAM_STATE.ENDED]: {
      status: ['finish', 'finish', 'finish'],
      nextState: null,
    },
  }
  return options[status]
}

export const getProgressDot: StepsProps['progressDot'] = (
  dot,
  { status, index }
) => {
  switch (status) {
    case 'process':
      return <span className={clsx(index === 1 && 'animate-ping')}>{dot}</span>
    default:
      return dot
  }
}

export const useGetProgramDetail = (programId: string | number) => {
  const { data: programData, isFetching } = useOne<{ data: TLMSProgramDetail }>(
    {
      id: programId,
      resource: 'programs',
      dataProviderName: 'lms',
      queryOptions: {
        enabled: !!programId,
      },
    }
  )
  return {
    programData: programData?.data.data,
    isFetching,
    isPPConfigFinalized:
      programData?.data.data.ppConfigStage ===
      FINALIZATION_CONFIG_STAGE.FINALIZED,
    isJurnalFasilitasiFinalized:
      programData?.data.data.jurnalFasilitasiConfigStage ===
      FINALIZATION_CONFIG_STAGE.FINALIZED,
  }
}

export const secondToMinuteText = (
  second?: number | string,
  prefix = { second: 'detik', minute: 'menit' }
) => {
  if (typeof second === 'undefined' || isNaN(+second)) return '-'

  const seconds = +second % 60
  const minutes = Math.floor(+second / 60)
  return `${minutes} ${prefix.minute}${
    seconds !== 0 ? `,\n${seconds} ${prefix.second}` : ''
  }`
}
