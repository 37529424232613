import { Drawer, Space, Tabs, Tag } from '@pankod/refine-antd'
import React, { useCallback, useState } from 'react'

import { JurnalPemantauanBelajarList } from './jurnal-pemantauan-belajar/JurnalPemantauanBelajarList'
import { JurnalPendampinganIndividuList } from './jurnal-pendampingan-individu/JurnalPendampinganIndividuList'
import { LokaKaryaList } from './loka-karya/LokaKaryaList'

export const usePengajarPraktikConfigDrawer = () => {
  const [showId, setShowId] = useState<{
    show: boolean
    programId: string | number
  }>({ show: false, programId: '' })
  const onClose = useCallback(() => {
    setShowId((state) => ({
      ...state,
      show: false,
    }))
  }, [])

  return {
    show: (id: number | string) => {
      setShowId({ show: true, programId: id })
    },
    visible: !!showId.show,
    id: showId.programId,
    onClose,
  }
}

type PengajarPraktikConfigDrawerProps = {
  visible: boolean
  id: number | string
  onClose: () => void
  isFinalized?: boolean
}
const PengajarPraktikConfigDrawer = ({
  onClose,
  id,
  visible,
  isFinalized,
}: PengajarPraktikConfigDrawerProps) => {
  return (
    <Drawer
      title="Jurnal Pengajar Praktik"
      visible={visible}
      onClose={onClose}
      destroyOnClose
      width="70%"
      extra={
        typeof isFinalized !== 'undefined' ? (
          <Space>
            Status:
            <Tag color={isFinalized ? 'processing' : 'default'}>
              {isFinalized ? 'FINALIZED' : 'DRAFT'}
            </Tag>
          </Space>
        ) : null
      }
    >
      <Tabs>
        <Tabs.TabPane
          tab="Jurnal Pemantauan Belajar"
          key="jurnalPemantauanBelajar"
        >
          <JurnalPemantauanBelajarList programId={id} />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab="Jurnal Pendampingan Individu"
          key="jurnalPendampinganIndividu"
        >
          <JurnalPendampinganIndividuList programId={id} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Loka Karya" key="lokaKarya">
          <LokaKaryaList programId={id} />
        </Tabs.TabPane>
      </Tabs>
    </Drawer>
  )
}

export default PengajarPraktikConfigDrawer
