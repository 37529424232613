import React from 'react'
import { useGetIdentity } from '@pankod/refine-core'
import {
  AntdLayout,
  Typography,
  Avatar,
  Space,
  Button,
  Tooltip,
  Badge,
  Icon,
  Divider,
} from '@pankod/refine-antd'
import clsx from 'clsx'

import {
  getTheme,
  LOCAL_STORAGE_THEME_VAR,
  THEME,
  toggleTheme,
} from 'src/helpers/theme'
import MoonSvg from 'public/img/theme/moon.svg'
import SunSvg from 'public/img/theme/sun.svg'

const { Text } = Typography

export const Header: React.FC = () => {
  const { data } = useGetIdentity()

  const user = data?.user
  const shouldRenderHeader = user && (user.name || user.picture)
  const isDarkTheme = getTheme() === THEME.DARK

  return shouldRenderHeader ? (
    <AntdLayout.Header
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        padding: '0px 24px',
        height: '64px',
        lineHeight: 'unset',
        zIndex: 99,
        position: 'sticky',
        top: 0,
      }}
      className="layout-header"
    >
      <Space>
        <Tooltip
          placement="bottom"
          color={isDarkTheme ? '#FFF' : undefined}
          overlayInnerStyle={{
            color: isDarkTheme ? 'rgba(0, 0, 0, 0.85)' : '#FFF',
          }}
          title={
            <>
              Switch to <strong>{isDarkTheme ? 'Light' : 'Dark'} Theme</strong>
            </>
          }
        >
          <Badge
            offset={[-5, 5]}
            color="red"
            dot={!localStorage.getItem(LOCAL_STORAGE_THEME_VAR)}
          >
            <Button
              className="!p-0"
              shape="circle"
              icon={
                <Icon
                  height={32}
                  width={32}
                  style={{
                    fontSize: isDarkTheme ? '24px' : '32px',
                    color: isDarkTheme ? '#FFF' : 'rgba(0, 0, 0, 0.85)',
                  }}
                  component={isDarkTheme ? SunSvg : MoonSvg}
                  className={clsx(
                    !localStorage.getItem(LOCAL_STORAGE_THEME_VAR) &&
                      'animate-[pulse_1s_4]'
                  )}
                />
              }
              type="text"
              onClick={() => toggleTheme()}
            />
          </Badge>
        </Tooltip>
        <Divider type="vertical" />
        <div className="app-header__user-info">
          {user.name && (
            <Text ellipsis strong>
              {user.name}
            </Text>
          )}
          <br />
          {user.email && (
            <Text ellipsis type="secondary">
              {user.email}
            </Text>
          )}
        </div>
        {user.picture && (
          <Avatar size="large" src={user?.picture} alt={user?.name} />
        )}
      </Space>
    </AntdLayout.Header>
  ) : null
}
