import { newModel, StringAdapter, newEnforcer } from 'casbin'

/** Custom matcher to cater multiple groups/roles of a user */
const multiRolesMatcher = (requestRoles: string[], policyRole: string) => {
  return requestRoles.includes(policyRole)
}

const model = newModel(`
  [request_definition]
  r = sub, obj, act

  [policy_definition]
  p = sub, obj, act, eft

  [role_definition]
  g = _, _

  [policy_effect]
  e = some(where (p.eft == allow)) && !some(where (p.eft == deny))

  [matchers]
  m = multi_roles(r.sub, p.sub) && keyMatch(r.obj, p.obj) && regexMatch(r.act, p.act)
`)

export const enforcer = (adapter: StringAdapter) =>
  newEnforcer(model, adapter).then((e) => {
    e.addFunction('multi_roles', multiRolesMatcher)
    return e
  })
