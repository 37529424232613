import {
  Form,
  FormProps,
  Input,
  Modal,
  ModalProps,
  Select,
  useSelect,
} from '@pankod/refine-antd'
import React from 'react'

type ModalFormProps = {
  modalProps: ModalProps
  formProps: FormProps
  onClose: () => void
  programId: string | number
}
export const ModalForm = ({
  formProps,
  modalProps,
  onClose,
  programId,
}: ModalFormProps) => {
  const { selectProps } = useSelect({
    resource: `programs/${programId}/program-modules`,
    dataProviderName: 'lms',
    optionValue: 'id',
    optionLabel: 'name',
    queryOptions: {
      enabled: modalProps.visible,
      select({ data }) {
        return {
          data,
          total: data.length,
        }
      },
    },
    filters: [
      {
        field: 'pageSize',
        operator: 'eq',
        value: '-1',
      },
    ],
    defaultValueQueryOptions: {
      enabled: false,
    },
  })
  return (
    <Modal
      {...modalProps}
      onCancel={onClose}
      title={'Buat Modul'}
      cancelText="Batal"
      okText="Simpan"
      afterClose={() => formProps.form?.resetFields()}
      bodyStyle={{ maxHeight: 700, overflow: 'auto' }}
    >
      <Form
        {...formProps}
        initialValues={formProps?.initialValues?.data}
        layout="vertical"
        validateMessages={{ required: '${label} harus diisi.' }}
      >
        <Form.Item
          label="Pilih Modul"
          name="moduleId"
          required
          rules={[{ required: true }]}
        >
          <Select
            {...selectProps}
            onSearch={() => {}}
            filterOption={(input, option) =>
              (option!.label as unknown as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          />
        </Form.Item>
        <Form.Item
          label="Nama Aktivitas"
          name="name"
          required
          rules={[{ required: true }]}
        >
          <Input autoComplete="off" />
        </Form.Item>
      </Form>
    </Modal>
  )
}
