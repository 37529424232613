import { message } from '@pankod/refine-antd'
import axios from 'axios'
import { parseCookies } from 'nookies'
import { useState } from 'react'

interface Option {
  value?: string | null | number
  label: string | React.ReactNode
  children?: Option[]
  isLeaf?: boolean
  loading?: boolean
}

export const useCascaderLoadData = <T extends Option>({
  initialOptions = [],
  resourceUrl,
  optionLabelKey = 'name',
  optionValueKey = 'id',
}: {
  resourceUrl: (id?: string | null | number) => string
  initialOptions?: T[]
  optionLabelKey?: string
  optionValueKey?: string
}) => {
  const [options, setOptions] = useState<T[]>(initialOptions)
  const [fetchedId, setFetchedId] = useState(new Set<string>())
  const { userSession } = parseCookies()
  const { guruToken } = JSON.parse(userSession)

  const getLocalPrograms = async (id?: string | null | number) => {
    if (fetchedId.has(String(id))) return null

    try {
      const response = await axios.get(resourceUrl(id), {
        headers: {
          Authorization: `Bearer ${guruToken}`,
        },
      })
      setFetchedId((state) => {
        state.add(String(id))
        return state
      })
      return response
    } catch {
      message.error('Terdapat gangguan saat mendapatkan daftar item')
      return null
    }
  }

  return {
    loadData: async (selectedOptions: Option[]) => {
      const targetOption = selectedOptions[selectedOptions.length - 1]
      targetOption.loading = true

      const response = await getLocalPrograms(targetOption.value)
      if (!response) {
        targetOption.loading = false
        return
      }

      const childrenOptions =
        response?.data?.data?.map((item: any) => ({
          label: item[optionLabelKey],
          value: item[optionValueKey],
        })) || []

      targetOption.loading = false
      targetOption.children = childrenOptions
      setOptions([...options])
    },
    options,
    setOptions,
  }
}
