import React, { useEffect, useContext, createContext, Dispatch } from 'react'

export const OutsideClickContext = createContext<{
  state: { enabled: boolean }
  setEnabled: Dispatch<{ enabled: boolean }>
}>({
  state: { enabled: true },
  setEnabled: () => {},
})

function reducer(_: { enabled: boolean }, action: { enabled: boolean }) {
  return { enabled: action.enabled }
}

export const OutsideClickProvider: React.FC = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, { enabled: true })

  return (
    <OutsideClickContext.Provider value={{ state, setEnabled: dispatch }}>
      {children}
    </OutsideClickContext.Provider>
  )
}

function useOnClickOutside(
  refs: React.RefObject<HTMLDivElement>[],
  handler: () => void
) {
  const {
    state: { enabled },
  } = useContext(OutsideClickContext)

  useEffect(
    () => {
      const listener = (event: any) => {
        // If not enabled do nothing
        if (!enabled) {
          return
        }
        // Do nothing if clicking ref's element or descendent elements
        if (
          refs.some((ref) => !ref.current) ||
          refs.some((ref) => ref.current?.contains(event.target))
        ) {
          return
        }
        handler()
      }
      document.addEventListener('mousedown', listener)
      document.addEventListener('touchstart', listener)
      return () => {
        document.removeEventListener('mousedown', listener)
        document.removeEventListener('touchstart', listener)
      }
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [refs, handler, enabled]
  )
}

export default useOnClickOutside
