import {
  AntdList,
  Button,
  Descriptions,
  Divider,
  EditButton,
  Form,
  Icons,
  Input,
  Modal,
  Show,
  Space,
  Tabs,
  Tag,
  useModalForm,
  useSimpleList,
} from '@pankod/refine-antd'
import {
  IResourceComponentsProps,
  useCreate,
  useDelete,
  useNavigation,
  useResource,
  useShow,
} from '@pankod/refine-core'
import Head from 'next/head'
import React, { useState } from 'react'

import { TCommonError, TCommonResponse } from 'src/interfaces/common'
import { TLMSAdmin } from '../admin/types'
import { PopDeleteConfirm } from '../components/PopDeleteConfirm'
import {
  TLMSEntity,
  TLMSEntityAssignAdmin,
  TLMSEntityDetail,
  TLMSEntityRequest,
  TLMSEntityUnassignAdmin,
} from './types'
import { PROGRAM_LOCAL_STATE, TLMSProgramLocal } from '../program-lokal/types'
import ShowProgramLocalButton from '../program-lokal/blocks/ShowProgramLocalButton'
import AddAdminModal from './blocks/AddAdminModal'

const ENTITIES_RESOURCE = 'entities'

export const LMSEntityManagementShow: React.FC<IResourceComponentsProps> = ({
  options,
}) => {
  const resource = useResource()
  const { list } = useNavigation()
  const id = resource.id as number
  const { mutateAsync: doDelete } = useDelete()
  const { mutateAsync: doUpdate } = useCreate()
  const { queryResult } = useShow<TCommonResponse<TLMSEntityDetail>>({
    id,
    resource: ENTITIES_RESOURCE,
    dataProviderName: 'lms',
  })
  const {
    modalProps: editEntityModalProps,
    formProps: editEntityFormProps,
    show: editShow,
    queryResult: editQueryResult,
  } = useModalForm<TLMSEntity, TCommonError, TLMSEntityRequest>({
    action: 'edit',
    dataProviderName: 'lms',
    resource: ENTITIES_RESOURCE,
    redirect: false,
  })
  const {
    modalProps: addAdminModalProps,
    formProps: addAdminFormProps,
    show: addAdminShow,
  } = useModalForm<TLMSEntityAssignAdmin>({
    action: 'create',
    resource: `entities/${id}/assign-admins`,
    dataProviderName: 'lms',
    onMutationError: () => adminEntity.refetch(),
    onMutationSuccess: () => adminEntity.refetch(),
    redirect: false,
    successNotification: {
      type: 'success',
      message: 'Successfully added admin entitas',
      description: 'Successful',
    },
  })

  const data = queryResult.data?.data.data
  const [activeTab, setActiveTab] = useState('admins')

  const { listProps: adminListProps, queryResult: adminEntity } =
    useSimpleList<TLMSAdmin>({
      resource: `entities/${id}/admins`,
      dataProviderName: 'lms',
      pagination: {
        pageSize: 20,
      },
    })
  const { listProps: programListProps } = useSimpleList<TLMSProgramLocal>({
    resource: `entities/${id}/local-programs`,
    dataProviderName: 'lms',
    pagination: {
      pageSize: 20,
    },
  })

  return (
    <>
      <Show
        title="Entitas Detail"
        isLoading={queryResult.isFetching}
        resource={ENTITIES_RESOURCE}
        dataProviderName="lms"
        canDelete={false}
        headerButtons={() => (
          <Space>
            <EditButton
              onClick={() => {
                editShow(id)
              }}
            />
            <PopDeleteConfirm
              title={`Are you sure want to delete Entitas "${queryResult.data?.data?.data.name}"?`}
              placeholder="Type Entitas name to confirm"
              errorMessage="Entitas Name did not match"
              onConfirm={() =>
                doDelete(
                  {
                    id,
                    resource: ENTITIES_RESOURCE,
                    dataProviderName: 'lms',
                  },
                  {
                    onSuccess: () => list('lms-entity-management'),
                  }
                )
              }
              okButtonProps={{
                danger: true,
                type: 'default',
              }}
              okText="Delete"
              validateValue={queryResult.data?.data?.data.name!}
            >
              <Button danger icon={<Icons.DeleteOutlined />}>
                Delete
              </Button>
            </PopDeleteConfirm>
          </Space>
        )}
      >
        <Head>
          <title>LMS Admin | {options?.label} </title>
        </Head>

        <Descriptions
          bordered
          column={1}
          className="mt-4 mb-3"
          labelStyle={{ fontWeight: 600 }}
        >
          <Descriptions.Item label="ID">{data?.id}</Descriptions.Item>
          <Descriptions.Item label="Name">{data?.name}</Descriptions.Item>
          <Descriptions.Item label="Slug">{data?.slug}</Descriptions.Item>
        </Descriptions>

        <Divider dashed />

        <Tabs
          activeKey={activeTab}
          tabBarExtraContent={{
            right: activeTab === 'admins' && (
              <Button icon={<Icons.UserAddOutlined />} onClick={addAdminShow}>
                Add Admin
              </Button>
            ),
          }}
          onChange={setActiveTab}
        >
          <Tabs.TabPane tab="Admin" key="admins">
            <AntdList
              {...adminListProps}
              renderItem={(item, index) => (
                <AntdList.Item
                  key={index}
                  extra={
                    <PopDeleteConfirm
                      title="Are you sure want to remove this Admin?"
                      validateValue={item.email}
                      onConfirm={() => {
                        const values: TLMSEntityUnassignAdmin = {
                          email: item.email,
                        }

                        return doUpdate(
                          {
                            dataProviderName: 'lms',
                            resource: `entities/${id}/unassign-admins`,
                            values,
                            successNotification: {
                              message: 'Successfully removed admin entitas',
                              type: 'success',
                              description: 'Succesful',
                            },
                          },
                          {
                            onSuccess: () => adminEntity.refetch(),
                          }
                        )
                      }}
                      placeholder="Type admin email to confirm"
                      okButtonProps={{ danger: true }}
                      okText="Remove"
                      errorMessage="Email Admin did not match"
                      placement="bottomLeft"
                    >
                      <Button danger type="text">
                        Remove
                      </Button>
                    </PopDeleteConfirm>
                  }
                >
                  <AntdList.Item.Meta
                    title={item.email}
                    description={item.role}
                  />
                </AntdList.Item>
              )}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Local Program" key="local-programs">
            <AntdList
              {...programListProps}
              renderItem={(item, index) => (
                <AntdList.Item
                  key={index}
                  extra={
                    <Space>
                      <Tag
                        className="uppercase"
                        color={
                          item.status === PROGRAM_LOCAL_STATE.PUBLISHED
                            ? 'success'
                            : 'default'
                        }
                      >
                        {item.status}
                      </Tag>
                      <ShowProgramLocalButton
                        block
                        size="small"
                        recordItemId={item.id}
                        programId={item.programId}
                      />
                    </Space>
                  }
                >
                  <AntdList.Item.Meta title={item.name} />
                </AntdList.Item>
              )}
            />
          </Tabs.TabPane>
        </Tabs>
      </Show>

      <AddAdminModal
        name={data?.name}
        formProps={addAdminFormProps}
        modalProps={addAdminModalProps}
        adminListProps={adminListProps}
      />

      <Modal {...editEntityModalProps} title="Edit Entitas">
        <Form
          {...editEntityFormProps}
          initialValues={editQueryResult.data?.data?.data}
          layout="vertical"
          validateMessages={{ required: '${label} must be filled.' }}
          resource={ENTITIES_RESOURCE}
        >
          <Form.Item
            label="Entitas Name"
            name="name"
            required
            rules={[{ required: true }]}
          >
            <Input autoComplete="off" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}
