import {
  Form,
  FormProps,
  Select,
  Modal,
  ModalProps,
  useSelect,
} from '@pankod/refine-antd'
import React from 'react'

import { FormSelectComponent } from '../../FormSelectComponent'
import { LIST_FILTER } from './constant'

type ModalFormProps = {
  modalProps: ModalProps
  formProps: FormProps
  mode: 'create' | 'edit'
  onClose: () => void
  programId: string | number
}
export const ModalForm = ({
  formProps,
  modalProps,
  mode,
  onClose,
  programId,
}: ModalFormProps) => {
  const isEditMode = mode === 'edit'
  const { selectProps } = useSelect({
    resource: `pp/${programId}/jurnal-pemantauan`,
    dataProviderName: 'lms',
    optionValue: 'id',
    optionLabel: 'title',
    queryOptions: {
      enabled: modalProps.visible,
      select({ data }) {
        return {
          data,
          total: data.length,
        }
      },
    },
    defaultValueQueryOptions: {
      enabled: false,
    },
    filters: [
      {
        field: 'pageSize',
        operator: 'eq',
        value: -1,
      },
      {
        field: 'filter',
        operator: 'eq',
        value:
          mode === 'create' ? LIST_FILTER.UNASSIGNED : LIST_FILTER.ASSIGNED,
      },
    ],
  })
  return (
    <Modal
      {...modalProps}
      onCancel={onClose}
      title={
        isEditMode
          ? 'Edit Jurnal Pemantauan Belajar'
          : 'Buat Jurnal Pemantauan Belajar'
      }
      cancelText="Batal"
      okText="Simpan"
      afterClose={() => formProps.form?.resetFields()}
      bodyStyle={{ maxHeight: 700, overflow: 'auto' }}
    >
      <Form
        {...formProps}
        initialValues={formProps?.initialValues?.data}
        layout="vertical"
        validateMessages={{ required: '${label} harus diisi.' }}
        onFinish={({ forms, id }) => {
          formProps.onFinish?.({
            formIds: forms.map(({ id }: { id: string; title: string }) => id),
            ...(mode === 'create' ? { moduleId: id } : {}),
          })
        }}
      >
        <Form.Item
          label="Pilih Modul"
          name="id"
          required
          rules={[{ required: true }]}
        >
          <Select
            {...selectProps}
            disabled={isEditMode}
            onSearch={() => {}}
            filterOption={(input, option) =>
              (option!.label as unknown as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          />
        </Form.Item>
        <Form.Item
          label="Pilih Form"
          name="forms"
          required
          rules={[{ required: true }]}
        >
          <FormSelectComponent multiple />
        </Form.Item>
      </Form>
    </Modal>
  )
}
