import {
  AntdList,
  Button,
  Divider,
  Drawer,
  Icons,
  Popconfirm,
  Space,
  Table,
  TableColumnGroupType,
  TableColumnType,
  Tag,
  TextField,
  useModalForm,
} from '@pankod/refine-antd'
import React, { useCallback, useState } from 'react'
import { useDelete } from '@pankod/refine-core'

import { PenilaianKinerjaRequest, PenilaianKinerjaResponse } from './types'
import { TCommonError, TLMSCommonError } from 'src/interfaces/common'
import { kapabilitasMapper } from '@components/DataTableParticipants/blocks/utils'
import { dateToStringFmt } from 'src/helpers/date'
import { showErrorNotification } from '@resources/angkatan-ppg-management/utils'
import ModalForm from './ModalForm'
import useTableWithMeta from 'src/hooks/useTableWithMeta'

export const usePenilaianKinerjaDrawer = () => {
  const [showId, setShowId] = useState<{
    show: boolean
    programId: string | number
    programName: string
  }>({ show: false, programId: '', programName: '' })
  const onClose = useCallback(() => {
    setShowId((state) => ({
      ...state,
      show: false,
    }))
  }, [])

  return {
    show: (id: number | string, name: string) => {
      setShowId({ show: true, programId: id, programName: name })
    },
    visible: !!showId.show,
    id: showId.programId,
    name: showId.programName,
    onClose,
  }
}

type PenilaianKinerjaDrawerProps = {
  visible: boolean
  id: number | string
  onClose: () => void
  name: string
  isFinalized: boolean
}
const PenilaianKinerjaDrawer = ({
  id,
  name,
  onClose,
  visible,
  isFinalized,
}: PenilaianKinerjaDrawerProps) => {
  const { mutateAsync: doDelete } = useDelete()
  const { tableProps } = useTableWithMeta<
    PenilaianKinerjaResponse,
    TCommonError
  >({
    resource: `programs/${id}/penilaian-kinerja`,
    dataProviderName: 'lms',
    queryOptions: { enabled: visible && !!id },
  })

  const {
    modalProps: createModalProps,
    formProps: createFormProps,
    show: createShow,
    close: createClose,
  } = useModalForm<
    PenilaianKinerjaResponse,
    TLMSCommonError,
    PenilaianKinerjaRequest
  >({
    action: 'create',
    resource: `programs/${id}/penilaian-kinerja`,
    dataProviderName: 'lms',
    autoResetForm: true,
    redirect: false,
    successNotification: {
      message: 'Penilaian kinerja berhasil dibuat',
      type: 'success',
      description: 'Sukses',
    },
    errorNotification: (error) =>
      showErrorNotification(
        error,
        'There was a problem when creating penilaian kinerja'
      ),
  })

  const {
    modalProps: editModalProps,
    formProps: editFormProps,
    show: editShow,
    close: editClose,
  } = useModalForm<
    PenilaianKinerjaResponse,
    TLMSCommonError,
    PenilaianKinerjaRequest
  >({
    action: 'edit',
    resource: `programs/${id}/penilaian-kinerja`,
    dataProviderName: 'lms',
    autoResetForm: true,
    redirect: false,
    successNotification: (data?: any) => ({
      message: data?.message || 'Penilaian kinerja berhasil diubah',
      type: 'success',
      description: 'Sukses',
    }),
    errorNotification: (error) =>
      showErrorNotification(
        error,
        'There was a problem when updating penilaian kinerja'
      ),
  })

  const columns: (
    | TableColumnType<PenilaianKinerjaResponse>
    | TableColumnGroupType<PenilaianKinerjaResponse>
  )[] = [
    { title: 'ID', dataIndex: 'id', key: '_id' },
    { title: 'Nama Penilaian', dataIndex: 'name', key: '_name' },
    {
      title: 'Form Penilaian',
      dataIndex: ['form', 'title'],
      key: '_formTitle',
    },
    {
      title: 'Role',
      key: '_role',
      children: [
        {
          title: 'Yang Dinilai',
          dataIndex: 'evaluatedRole',
          filters: [
            {
              text: 'Fasilitator',
              value: 'PENILAIAN_KINERJA_EVALUATED_ROLE_INSTRUKTUR',
            },
            {
              text: 'Pengajar Praktik',
              value: 'PENILAIAN_KINERJA_EVALUATED_ROLE_ASISTEN',
            },
          ],
          filterMultiple: false,
          render: (item) => kapabilitasMapper(item),
        },
        {
          title: 'Aktor Penilai',
          dataIndex: ['evaluatorRoles'],
          key: '_evaluatorRoles',
          render: (value, record) => (
            <AntdList
              dataSource={value}
              size="small"
              renderItem={(item: { role: string; weight?: number }, i) => (
                <AntdList.Item key={i} className="!px-0">
                  {kapabilitasMapper(item.role, (item) =>
                    item === 'self' ? 'diri sendiri' : item
                  )}
                  {record.type === 'quantitative' && (
                    <TextField
                      type="secondary"
                      className="block"
                      value={`(${item.weight?.toFixed(2)}%)`}
                    />
                  )}
                </AntdList.Item>
              )}
            />
          ),
        },
      ],
    },
    {
      title: 'Periode Pengisian',
      key: '_periode',
      children: [
        {
          title: 'Mulai',
          key: '_start',
          dataIndex: 'startDate',
          render: (value) => dateToStringFmt(value, 'DD MMM YYYY'),
        },
        {
          title: 'Selesai',
          key: '_end',
          dataIndex: 'endDate',
          render: (value) => dateToStringFmt(value, 'DD MMM YYYY'),
        },
      ],
    },
    {
      title: 'Tipe',
      dataIndex: 'type',
      key: '_type',
      className: 'capitalize',
    },
    {
      key: '_action',
      render: (_, record) => (
        <Space direction="vertical">
          <Button
            block
            size="small"
            icon={<Icons.EditOutlined />}
            disabled={isFinalized}
            onClick={() => editShow(record.id)}
          >
            Ubah
          </Button>
          <Popconfirm
            title="Apakah Anda yakin ingin menghapus penilaian kinerja ini?"
            okText="Hapus"
            cancelText="Batal"
            okButtonProps={{ danger: true }}
            disabled={isFinalized}
            placement="topRight"
            onConfirm={async () =>
              doDelete({
                id: record.id,
                resource: `programs/${id}/penilaian-kinerja`,
                dataProviderName: 'lms',
                invalidates: ['list'],
                successNotification: (data?: any) => ({
                  message:
                    data?.message || 'Penilaian kinerja berhasil dihapus',
                  type: 'success',
                  description: 'Sukses',
                }),
                errorNotification: (error: any) =>
                  showErrorNotification(
                    error,
                    'There was a problem when removing penilaian kinerja'
                  ),
              })
            }
          >
            <Button
              block
              size="small"
              danger
              icon={<Icons.DeleteOutlined />}
              disabled={isFinalized}
            >
              Hapus
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ]

  return (
    <Drawer
      title={`Daftar Penilaian Kinerja | ${name}`}
      visible={visible}
      onClose={onClose}
      destroyOnClose
      width="70%"
      extra={
        <Space>
          Status:
          <Tag color={isFinalized ? 'processing' : 'default'}>
            {isFinalized ? 'FINALIZED' : 'DRAFT'}
          </Tag>
          <Divider type="vertical" />
          <Button
            type="primary"
            icon={<Icons.PlusOutlined />}
            onClick={() => {
              createShow()
            }}
            disabled={isFinalized}
          >
            Tambah Penilaian
          </Button>
        </Space>
      }
    >
      <Table {...tableProps} bordered columns={columns} rowKey="id" />
      <ModalForm
        formProps={createFormProps}
        modalProps={createModalProps}
        mode="create"
        onClose={createClose}
      />
      <ModalForm
        formProps={editFormProps}
        modalProps={editModalProps}
        mode="edit"
        onClose={editClose}
      />
    </Drawer>
  )
}

export default PenilaianKinerjaDrawer
