import { Tag } from '@pankod/refine-antd'
import React from 'react'

export const TagPaymentStatus = ({
  status,
}: {
  status: 'PAID' | 'NOT_PAID'
}) => {
  return status === 'PAID' ? (
    <Tag className="uppercase" color="success">
      Sudah Dibayar
    </Tag>
  ) : (
    <Tag className="uppercase" color="error">
      Belum Dibayar
    </Tag>
  )
}
