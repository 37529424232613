import { z, ZodArray, ZodObject, ZodString } from 'zod'

import { isOptionsUnique } from './utils'

export enum WIDGET_NAME {
  Button = 'Button',
  Container = 'Container',
  ContentWrapper = 'ContentWrapper',
  Heading = 'Heading',
  Image = 'Image',
  Text = 'Text',
  TextInput = 'TextInput',
  DatePicker = 'DatePicker',
  Select = 'Select',
  TextArea = 'TextArea',
  CheckboxGroup = 'CheckboxGroup',
  RadioGroup = 'RadioGroup',
  RichTextEditor = 'RichTextEditor',
  LikertQuestion = 'LikertQuestion',
  NumberInput = 'NumberInput',
}

export enum WIDGET_TYPE {
  General = 'General',
  Form = 'Form',
}

export const ButtonCustomProps = {
  name: WIDGET_NAME.Button,
  type: WIDGET_TYPE.General,
} as const
export const SButton = z.object({
  color: z.enum(['blue', 'black', 'white', 'red'], {
    invalid_type_error: 'Button Color is not recognized',
    required_error: 'Button Color is required',
  }),
  label: z
    .string({ required_error: 'Button Label is required' })
    .min(1, 'Button Label can not be empty'),
  url: z
    .string({ required_error: 'Button URL/Link is required' })
    .url({ message: 'Button URL/Link is invalid' }),
})
export type TButton = z.infer<typeof SButton>

export const ContainerCustomProps = {
  name: WIDGET_NAME.Container,
  type: WIDGET_TYPE.General,
} as const
export const SContainer = z
  .string()
  .array()
  .min(1, 'Container must not be empty')

export const HeadingCustomProps = {
  name: WIDGET_NAME.Heading,
  type: WIDGET_TYPE.General,
} as const
export const SHeading = z.object({
  size: z.enum(['heading-md', 'heading-lg']).optional(),
  text: z
    .string({ required_error: 'Heading Text is required' })
    .min(1, 'Heading Text can not be empty'),
})
export type THeading = z.infer<typeof SHeading>

export const ImageCustomProps = {
  name: WIDGET_NAME.Image,
  type: WIDGET_TYPE.General,
} as const
export const SImage = z.object({
  altText: z.string().optional(),
  imageUrl: z
    .string({ required_error: 'Image URL/Link is required' })
    .url({ message: 'Image URL/Link is invalid' }),
  ratio: z.enum(['aspect-auto', 'aspect-3/1', 'aspect-2/1', 'aspect-square'], {
    required_error: 'Image ratio can not be empty',
  }),
})
export type TImage = z.infer<typeof SImage>

export const TextCustomProps = {
  name: WIDGET_NAME.Text,
  type: WIDGET_TYPE.General,
} as const
export const SText = z.object({
  text: z
    .string({ required_error: 'Text is required' })
    .min(1, 'Text can not be empty'),
})
export type TText = z.infer<typeof SText>

export const ContentWrapperCustomProps = {
  name: WIDGET_NAME.ContentWrapper,
  type: WIDGET_TYPE.Form,
} as const
export const SContentWrapper = z
  .string() // Check the node ids (children)
  .array() // In the form of arrays
  .min(1, 'Content Page must not be empty') // That should have at least 1 child

export const TextInputCustomProps = {
  name: WIDGET_NAME.TextInput,
  type: WIDGET_TYPE.Form,
} as const
export const STextInput = z.object({
  name: z
    .string({ required_error: 'Name key is required' })
    .min(1, 'Name key can not be empty'),
  label: z
    .string({ required_error: 'Label is required' })
    .min(1, 'Label can not be empty'),
  isRequired: z.boolean().optional(),
  showCounter: z.boolean().optional(),
  placeholder: z.string().optional(),
  minChar: z.number().optional(),
  maxChar: z.number().optional(),
  helper: z.string().optional(),
  type: z.enum(['text', 'number', 'email', 'uri']),
})
export type TTextInput = z.infer<typeof STextInput>

export const DatePickerCustomProps = {
  name: WIDGET_NAME.DatePicker,
  type: WIDGET_TYPE.Form,
} as const
export const SDatePicker = z.object({
  name: z
    .string({ required_error: 'Name key is required' })
    .min(1, 'Name key can not be empty'),
  label: z
    .string({ required_error: 'Label is required' })
    .min(1, 'Label can not be empty'),
  isRequired: z.boolean().optional(),
  minValue: z.string().optional(),
  maxValue: z.string().optional(),
  placeholder: z.string().optional(),
})
export type TDatePicker = z.infer<typeof SDatePicker>

export const SelectCustomProps = {
  name: WIDGET_NAME.Select,
  type: WIDGET_TYPE.Form,
} as const
export const SSelect = z.object({
  name: z
    .string({ required_error: 'Name key is required' })
    .min(1, 'Name key can not be empty'),
  label: z
    .string({ required_error: 'Label is required' })
    .min(1, 'Label can not be empty'),
  options: z
    .object({ label: z.string(), value: z.string() })
    .array()
    .min(1, 'Options can not be empty')
    .refine(isOptionsUnique, {
      message: 'Options label and value must be unique',
    })
    .or(z.string()),
  isRequired: z.boolean().optional(),
  placeholder: z.string().optional(),
  helper: z.string().optional(),
})
export type TSelect = z.infer<typeof SSelect>

export const TextAreaCustomProps = {
  name: WIDGET_NAME.TextArea,
  type: WIDGET_TYPE.Form,
} as const
export const STextArea = z.object({
  name: z
    .string({ required_error: 'Name key is required' })
    .min(1, 'Name key can not be empty'),
  label: z
    .string({ required_error: 'Label is required' })
    .min(1, 'Label can not be empty'),
  isRequired: z.boolean().optional(),
  placeholder: z.string().optional(),
  minChar: z.number().optional(),
  maxChar: z.number().optional(),
  helper: z.string().optional(),
})
export type TTextArea = z.infer<typeof STextArea>

export const CheckboxGroupCustomProps = {
  name: WIDGET_NAME.CheckboxGroup,
  type: WIDGET_TYPE.Form,
} as const
export const SCheckboxGroup = z.object({
  name: z
    .string({ required_error: 'Name key is required' })
    .min(1, 'Name key can not be empty'),
  label: z
    .string({ required_error: 'Label is required' })
    .min(1, 'Label can not be empty'),
  options: z
    .object({ label: z.string(), value: z.string() })
    .array()
    .min(1, 'Options can not be empty')
    .refine(isOptionsUnique, {
      message: 'Options label and value must be unique',
    })
    .or(z.string()),
  minSelected: z.number().optional(),
  maxSelected: z.number().optional(),
  isRequired: z.boolean().optional(),
  helper: z.string().optional(),
})
export type TCheckboxGroup = z.infer<typeof SCheckboxGroup>

export const RadioGroupCustomProps = {
  name: WIDGET_NAME.RadioGroup,
  type: WIDGET_TYPE.Form,
} as const
export const SRadioGroup = z.object({
  name: z
    .string({ required_error: 'Name key is required' })
    .min(1, 'Name key can not be empty'),
  label: z
    .string({ required_error: 'Label is required' })
    .min(1, 'Label can not be empty'),
  options: z
    .object({ label: z.string(), value: z.string() })
    .array()
    .min(1, 'Options can not be empty')
    .refine(isOptionsUnique, {
      message: 'Options label and value must be unique',
    })
    .or(z.string()),
  isRequired: z.boolean().optional(),
  helper: z.string().optional(),
})
export type TRadioGroup = z.infer<typeof SRadioGroup>

export const RichTextEditorCustomProps = {
  name: WIDGET_NAME.RichTextEditor,
  type: WIDGET_TYPE.Form,
} as const
export const SRichTextEditor = z.object({
  name: z
    .string({ required_error: 'Name key is required' })
    .min(1, 'Name key can not be empty'),
  label: z
    .string({ required_error: 'Label is required' })
    .min(1, 'Label can not be empty'),
  isRequired: z.boolean().optional(),
  placeholder: z.string().optional(),
  helper: z.string().optional(),
})
export type TRichTextEditor = z.infer<typeof SRichTextEditor>

export const LikertQuestionCustomProps = {
  name: WIDGET_NAME.LikertQuestion,
  type: WIDGET_TYPE.Form,
} as const
export const SLikertQuestion = z.object({
  name: z
    .string({ required_error: 'Name key is required' })
    .min(1, 'Name key can not be empty'),
  label: z
    .string({ required_error: 'Label is required' })
    .min(1, 'Label can not be empty'),
  options: z.object({ label: z.string(), value: z.string() }).array(),
  isRequired: z.boolean().optional(),
  placeholder: z.string().optional(),
  helper: z.string().optional(),
})
export type TLikertQuestion = z.infer<typeof SLikertQuestion>

export const NumberInputCustomProps = {
  name: WIDGET_NAME.NumberInput,
  type: WIDGET_TYPE.Form,
} as const
export const SNumberInput = z.object({
  name: z
    .string({ required_error: 'Name key is required' })
    .min(1, 'Name key can not be empty'),
  label: z
    .string({ required_error: 'Label is required' })
    .min(1, 'Label can not be empty'),
  isRequired: z.boolean().optional(),
  placeholder: z.string().optional(),
  minValue: z.number().optional(),
  maxValue: z.number().optional(),
  helper: z.string().optional(),
})
export type TNumberInput = z.infer<typeof SNumberInput>

export type TWidgetCustomProps =
  | typeof ButtonCustomProps
  | typeof ContainerCustomProps
  | typeof HeadingCustomProps
  | typeof ImageCustomProps
  | typeof TextCustomProps
  | typeof ContentWrapperCustomProps
  | typeof TextInputCustomProps
  | typeof DatePickerCustomProps
  | typeof SelectCustomProps
  | typeof TextAreaCustomProps
  | typeof CheckboxGroupCustomProps
  | typeof RadioGroupCustomProps
  | typeof RichTextEditorCustomProps
  | typeof LikertQuestionCustomProps
  | typeof NumberInputCustomProps

export const SCHEMA_VALIDATOR: Record<
  TWidgetCustomProps['name'],
  {
    schema: ZodObject<any> | ZodArray<ZodString>
    // `nodes` is usually checked for it's nodes length | `props` is for it's form data
    checkProps: 'nodes' | 'props'
  } | null
> = {
  Button: { schema: SButton, checkProps: 'props' },
  Container: { schema: SContainer, checkProps: 'nodes' },
  ContentWrapper: { schema: SContentWrapper, checkProps: 'nodes' },
  Heading: { schema: SHeading, checkProps: 'props' },
  Image: { schema: SImage, checkProps: 'props' },
  Text: { schema: SText, checkProps: 'props' },
  TextInput: { schema: STextInput, checkProps: 'props' },
  TextArea: { schema: STextArea, checkProps: 'props' },
  DatePicker: { schema: SDatePicker, checkProps: 'props' },
  Select: { schema: SSelect, checkProps: 'props' },
  CheckboxGroup: { schema: SCheckboxGroup, checkProps: 'props' },
  RadioGroup: { schema: SRadioGroup, checkProps: 'props' },
  RichTextEditor: { schema: SRichTextEditor, checkProps: 'props' },
  LikertQuestion: { schema: SLikertQuestion, checkProps: 'props' },
  NumberInput: { schema: SNumberInput, checkProps: 'props' },
}
