import { List, Table, TextField, useTable } from '@pankod/refine-antd'
import { GetListResponse, IResourceComponentsProps } from '@pankod/refine-core'
import Head from 'next/head'
import React from 'react'

import { TCommonError } from 'src/interfaces/common'
import ShowProgramLocalButton from './blocks/ShowProgramLocalButton'
import { TLMSProgramLocal } from './types'

export const LMSProgramLocalManagementList: React.FC<
  IResourceComponentsProps<GetListResponse<TLMSProgramLocal>>
> = ({ options }) => {
  const { tableProps } = useTable<TLMSProgramLocal, TCommonError>({
    resource: 'local-programs',
    dataProviderName: 'lms',
  })

  return (
    <>
      <List title="LMS Program Local Management">
        <Head>
          <title>LMS Admin | {options?.label}</title>
        </Head>

        <Table {...tableProps} rowKey="id">
          <Table.Column
            dataIndex="id"
            title="ID"
            render={(value) => <TextField value={value} />}
          />
          <Table.Column
            dataIndex="slug"
            title="Slug"
            render={(value) => <TextField value={value} />}
          />
          <Table.Column
            dataIndex="name"
            title="Name"
            render={(value) => <TextField value={value} />}
          />
          <Table.Column<TLMSProgramLocal>
            title="Actions"
            render={(_, record) => (
              <ShowProgramLocalButton
                size="small"
                recordItemId={record.id}
                programId={record.programId}
              />
            )}
          />
        </Table>
      </List>
    </>
  )
}
