import {
  DatePicker,
  Form,
  FormProps,
  Input,
  Modal,
  ModalProps,
  Select,
  Switch,
} from '@pankod/refine-antd'
import dayjs from 'dayjs'
import React from 'react'

type ModalKonfigurasiClassItemProps = {
  modalProps: ModalProps
  formProps: FormProps
  type: 'PTM' | 'FI'
  itemName?: string
  onClose: () => void
}
export const ModalKonfigurasiClassItem = ({
  modalProps,
  formProps,
  type,
  onClose,
  itemName,
}: ModalKonfigurasiClassItemProps) => {
  const { Option } = Select

  const startDate = Form.useWatch('startDate', formProps?.form)
  const endDate = Form.useWatch('endDate', formProps?.form)

  const currentDateRange = React.useMemo(() => {
    const start = dayjs(startDate)
    const end = dayjs(endDate)
    const dayList = []

    for (let dt = start; dt <= end; dt = dt.add(1, 'day')) {
      dayList.push(dt.format('YYYY-MM-DD'))
    }

    return dayList
  }, [startDate, endDate])

  return (
    <Modal
      {...modalProps}
      onCancel={onClose}
      title={`Konfigurasi ${type}: ${itemName}`}
    >
      <Form
        {...formProps}
        layout="horizontal"
        labelCol={{ span: 6 }}
        validateMessages={{ required: '${label} must be filled.' }}
        initialValues={(() => {
          const { entity, ...rest } = formProps?.initialValues?.data || {}
          return {
            ...rest,
            entityId: entity?.id,
          }
        })()}
      >
        <Form.Item
          label="Kelas Name"
          name="name"
          required
          rules={[{ required: true }]}
        >
          <Input autoComplete="off" />
        </Form.Item>

        <Form.Item
          label="Start Date"
          name="startDate"
          rules={[{ required: true, message: 'Please select a start date.' }]}
        >
          <DatePicker format="YYYY-MM-DD" />
        </Form.Item>

        <Form.Item
          label="End Date"
          name="endDate"
          rules={[
            { required: true, message: 'Please select an end date.' },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('startDate') < value) {
                  return Promise.resolve()
                }
                return Promise.reject(
                  new Error('End date should be higher than start date.')
                )
              },
            }),
          ]}
        >
          <DatePicker format="YYYY-MM-DD" />
        </Form.Item>

        {type === 'PTM' && (
          <Form.Item
            label="Date Range"
            name="dateRange"
            rules={[{ required: true, message: 'Please select a date.' }]}
          >
            <Select mode="multiple" disabled={!startDate && !endDate}>
              {currentDateRange.map((date, index) => (
                <Option key={index} value={date}>
                  {date}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}
        <Form.Item label="Is Active" name="isActive" valuePropName="checked">
          <Switch />
        </Form.Item>
      </Form>
    </Modal>
  )
}
