import React, { useState } from 'react'
import { Canvas } from 'fabric'
import { Button, Empty, Popconfirm, Tooltip } from '@pankod/refine-antd'
import {
  FileType,
  FileImage,
  Trash,
  DiamondPlus,
  PackagePlus,
} from 'lucide-react'
import clsx from 'clsx'

import {
  CertificateTemplateVariables,
  UserVariable,
} from '@resources/lms-management/certificate-template/types'
import CreateCustomVariableModal from './CreateCustomVariableModal'
import { addText } from '../../utils/text'
import { addImage } from '../../utils/image'
import ImageRenderPreview from '../ImageRenderPreview'

type CustomComponents = {
  canvas: Canvas | null
  variable: CertificateTemplateVariables['userVariable']
  onCreateVariable: (data: UserVariable) => Promise<any>
  onDeleteVariable: (key: string) => Promise<any>
}

const CustomComponents = (props: CustomComponents) => {
  const [showCreateModal, setShowCreateModal] = useState(false)
  const handleAddComponent = (params: UserVariable) => {
    if (params.type === 'TEXT') {
      addText({
        canvas: props.canvas,
        value: params.value,
        customVariableKey: `attributes['${params.key}']`,
      })
    }
    if (params.type === 'IMAGE') {
      addImage({
        canvas: props.canvas,
        base64String: params.value,
        customVariableKey: `attributes['${params.key}']`,
      })
    }
  }
  return (
    <div className="flex gap-2 flex-col w-full justify-between h-full">
      <div className="grid gap-2 grid-flow-row h-auto overflow-y-auto">
        {!props.variable.length && (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="Tidak ada komponen"
          />
        )}
        {props.variable.map(({ key, value, type }, i) => (
          <div
            key={key + i}
            className="w-full h-fit relative flex flex-col gap-2 rounded p-2 border border-slate-400"
          >
            <div className="absolute top-1.5 right-1.5">
              {type === 'TEXT' ? (
                <FileType className="text-subdued" />
              ) : (
                <FileImage className="text-subdued" />
              )}
            </div>
            <div
              className={clsx(type === 'IMAGE' && 'flex gap-2 items-center')}
            >
              {type === 'TEXT' ? (
                <Tooltip title={value} placement="left">
                  <h4 className="m-0 font-semibold max-w-[186px] text-ellipsis whitespace-nowrap overflow-hidden">
                    {value}
                  </h4>
                </Tooltip>
              ) : (
                <h4 className="m-0 italic font-semibold max-w-[156px] text-ellipsis whitespace-nowrap overflow-hidden">
                  <div className="h-10 w-10 relative">
                    <ImageRenderPreview className="!h-10 w-10" value={value} />
                  </div>
                </h4>
              )}
              <h5
                title={key}
                className={clsx(
                  'font-mono text-subdued  text-ellipsis overflow-hidden',
                  type === 'IMAGE' ? 'max-w-[142px]' : 'max-w-[186px]'
                )}
              >
                {key}
              </h5>
            </div>
            <div className="flex gap-1">
              <Button
                onClick={() => {
                  handleAddComponent({ key, value, type })
                }}
                className="!flex gap-2 items-center justify-center !text-xs"
                block
                size="small"
                icon={<DiamondPlus className="h-3.5 w-3.5" />}
              >
                Masukkan Komponen
              </Button>
              <Popconfirm
                okText="Hapus Komponen"
                cancelText="Batal"
                okButtonProps={{ danger: true }}
                placement="left"
                title={
                  <div className="w-[200px]">
                    Komponen ini <b>sedang digunakan</b> pada desain.
                    <br />
                    Apabila komponen dihapus, komponen sama yang telah
                    ditambahkan pada desain akan dihapus, lanjutkan?
                  </div>
                }
                onConfirm={async () => {
                  return await props.onDeleteVariable(key)
                }}
              >
                <Button
                  onClick={() => {}}
                  size="small"
                  icon={<Trash className="h-3.5 w-3.5" />}
                  danger
                  className="!flex gap-2 items-center justify-center"
                />
              </Popconfirm>
            </div>
          </div>
        ))}
      </div>
      <div className="w-full">
        <Button
          type="dashed"
          icon={<PackagePlus className="h-5 w-5" />}
          className="!flex gap-2 items-center justify-center"
          block
          onClick={() => setShowCreateModal(true)}
        >
          Buat Komponen Baru
        </Button>
      </div>
      <CreateCustomVariableModal
        onClose={() => setShowCreateModal(false)}
        onSubmit={props.onCreateVariable}
        open={showCreateModal}
      />
    </div>
  )
}

export default CustomComponents
