import {
  AntdList,
  Button,
  Divider,
  Drawer,
  Icons,
  Popconfirm,
  Space,
  Table,
  Tag,
  TextField,
  Tooltip,
  useModalForm,
  useTable,
} from '@pankod/refine-antd'
import {
  useCustomMutation,
  useDelete,
  useInvalidate,
  useResource,
} from '@pankod/refine-core'
import React, { useCallback, useState } from 'react'

import { showErrorNotification } from '@resources/angkatan-ppg-management/utils'
import { TCommonError, TLMSCommonError } from 'src/interfaces/common'
import { ModalForm } from './ModalForm'
import {
  EvaluatorRole,
  EvaluatorRoleLabel,
  PenilaianWebinarRequest,
  PenilaianWebinarResponse,
} from './types'

export const usePenilaianWebinarConfigDrawer = () => {
  const [showId, setShowId] = useState<{
    show: boolean
    webinarId: string | number
  }>({ show: false, webinarId: '' })
  const onClose = useCallback(() => {
    setShowId((state) => ({
      ...state,
      show: false,
    }))
  }, [])

  return {
    show: (id: number | string) => {
      setShowId({ show: true, webinarId: id })
    },
    visible: !!showId.show,
    id: showId.webinarId,
    onClose,
  }
}

type PenilaianWebinarConfigDrawerProps = {
  visible: boolean
  id: string | number
  onClose: () => void
  isFinalized?: boolean
}

const PenilaianWebinarConfigDrawer = ({
  id,
  onClose,
  visible,
  isFinalized,
}: PenilaianWebinarConfigDrawerProps) => {
  const { id: programId } = useResource()
  const invalidate = useInvalidate()
  const resource = `programs/${programId}/webinars/${id}/penilaians`

  const { show: createShow, ...createModalProps } = useModalForm<
    PenilaianWebinarResponse,
    TLMSCommonError,
    PenilaianWebinarRequest
  >({
    action: 'create',
    dataProviderName: 'lms',
    resource,
    autoResetForm: true,
    redirect: false,
    successNotification: {
      message: 'Penilaian webinar berhasil dibuat',
      type: 'success',
      description: 'Sukses',
    },
    errorNotification: (error) =>
      showErrorNotification(
        error,
        'Terdapat gangguan ketika membuat penilaian webinar'
      ),
  })

  const { show: editShow, ...editModalProps } = useModalForm<
    PenilaianWebinarResponse,
    TLMSCommonError,
    PenilaianWebinarRequest
  >({
    action: 'edit',
    dataProviderName: 'lms',
    resource,
    autoResetForm: true,
    redirect: false,
    successNotification: {
      message: 'Penilaian webinar berhasil diubah',
      type: 'success',
      description: 'Sukses',
    },
    errorNotification: (error) =>
      showErrorNotification(
        error,
        'Terdapat gangguan ketika menyimpan perubahan penilaian webinar'
      ),
  })

  const { mutateAsync: doDelete } = useDelete()

  const { mutateAsync: doFinalize } = useCustomMutation<
    {},
    TCommonError,
    void
  >()

  const { tableProps } = useTable<PenilaianWebinarResponse>({
    hasPagination: false,
    dataProviderName: 'lms',
    resource,
    queryOptions: {
      enabled: !!visible && !!programId && !!id,
    },
  })

  const handleClose = () => {
    onClose()
  }

  return (
    <Drawer
      title={`Konfigurasi Penilaian Webinar | Webinar ID ${id}`}
      visible={visible}
      onClose={handleClose}
      width={'70%'}
      extra={
        <Space>
          <div>
            Status:{' '}
            {isFinalized ? (
              <Tag color="processing">FINALIZED</Tag>
            ) : (
              <Tag>DRAFT</Tag>
            )}
          </div>
          <Divider type="vertical" />
          <Popconfirm
            title="Apakah Anda yakin ingin melakukan finalisasi penilaian webinar?"
            disabled={isFinalized}
            okText="Finalisasi"
            cancelText="Batal"
            cancelButtonProps={{
              danger: true,
            }}
            zIndex={1000}
            onConfirm={async () => {
              await doFinalize(
                {
                  method: 'post',
                  url: `${process.env.NEXT_PUBLIC_LMS_API_URL}/programs/${programId}/penilaian-webinar/finalize-config`,
                  values: undefined,
                  successNotification: {
                    message: 'Penilaian webinar berhasil difinalisasi',
                    type: 'success',
                    description: 'Sukses',
                  },
                  errorNotification: (error: any) =>
                    showErrorNotification(
                      error,
                      `There was a problem when finalizing penilaian webinar`
                    ),
                },
                {
                  onSuccess: () => {
                    invalidate({
                      invalidates: ['detail'],
                      dataProviderName: 'lms',
                      resource: 'programs',
                      id: programId,
                    })
                  },
                }
              )
            }}
          >
            <Button disabled={isFinalized} icon={<Icons.LockOutlined />}>
              Finalisasi
            </Button>
          </Popconfirm>
          <Button
            disabled={isFinalized}
            type="primary"
            icon={<Icons.PlusOutlined />}
            onClick={() => createShow()}
          >
            Tambah Penilaian
          </Button>
        </Space>
      }
    >
      <Table
        {...tableProps}
        rowKey="id"
        columns={[
          {
            title: 'ID',
            dataIndex: 'id',
          },
          {
            title: 'Nama',
            dataIndex: 'name',
          },
          {
            title: 'Form Penilaian Kuantitatif',
            dataIndex: ['formKuantitatif', 'title'],
            render(value, record) {
              return (
                <div>
                  <Tooltip title={value}>
                    <TextField
                      ellipsis
                      value={value}
                      className="block w-[150px]"
                    />
                  </Tooltip>
                  <TextField
                    type="secondary"
                    className="block"
                    value={`(${(record.weight || 0)?.toFixed(2)}%)`}
                  />
                </div>
              )
            },
          },
          {
            title: 'Bobot Kuantitatif',
            dataIndex: 'weight',
            render: (value = 0) => String(value.toFixed(2)) + '%',
          },
          {
            title: 'Form Penilaian Kualitatif',
            dataIndex: ['formKualitatif', 'title'],
            render: (value) =>
              value ? (
                <Tooltip title={value}>
                  <TextField
                    ellipsis
                    value={value}
                    className="block w-[150px]"
                  />
                </Tooltip>
              ) : (
                <TextField
                  value="Tidak ada form"
                  className="block w-[150px]"
                  type="secondary"
                  ellipsis
                  italic
                />
              ),
          },
          {
            title: 'Aktor Pengisi',
            dataIndex: ['evaluatorRoles'],
            render: (value) => (
              <AntdList
                dataSource={value}
                size="small"
                renderItem={(
                  item: { role: EvaluatorRole; weight?: number },
                  i
                ) => (
                  <AntdList.Item key={i} className="!px-0">
                    {EvaluatorRoleLabel[item.role]}
                    <TextField
                      type="secondary"
                      className="block"
                      value={`(${(item.weight || 0).toFixed(2)}%)`}
                    />
                  </AntdList.Item>
                )}
              />
            ),
          },
          {
            fixed: 'right',
            render: (_, record) => (
              <Space direction="vertical">
                <Button
                  disabled={isFinalized}
                  icon={<Icons.EditOutlined />}
                  block
                  onClick={() => editShow(record.id)}
                >
                  Ubah
                </Button>
                <Popconfirm
                  title="Apakah Anda yakin ingin menghapus penilaian webinar ini?"
                  disabled={isFinalized}
                  okText="Hapus"
                  cancelText="Batal"
                  okButtonProps={{
                    danger: true,
                  }}
                  onConfirm={() =>
                    doDelete({
                      dataProviderName: 'lms',
                      id: record.id,
                      resource,
                      successNotification: {
                        message: 'Penilaian webinar berhasil dihapus',
                        type: 'success',
                        description: 'Sukses',
                      },
                      errorNotification: (err) =>
                        showErrorNotification(
                          err,
                          'Terdapat gangguan saat menghapus penilaian webinar'
                        ),
                    })
                  }
                >
                  <Button
                    danger
                    disabled={isFinalized}
                    block
                    icon={<Icons.DeleteOutlined />}
                  >
                    Hapus
                  </Button>
                </Popconfirm>
              </Space>
            ),
          },
        ]}
      />

      <ModalForm {...createModalProps} mode="create" />
      <ModalForm {...editModalProps} mode="edit" />
    </Drawer>
  )
}

export default PenilaianWebinarConfigDrawer
