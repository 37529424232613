import {
  Table,
  TextField,
  List,
  UrlField,
  Space,
  Button,
  Icons,
} from '@pankod/refine-antd'
import { GetListResponse, IResourceComponentsProps } from '@pankod/refine-core'
import Head from 'next/head'

import { PopConfirmScheduleForm } from './blocks/PopConfirmScheduleForm'
import { isBeforeToday, parseDate } from '../utils/dateParser'
import {
  InstructorWebinarPenilaianStatus,
  InstructorWebinarSchedule,
  SessionTime,
} from '../types'
import PopConfirmCancelWebinar from '../blocks/PopConfirmCancelWebinar'
import { TagPaymentStatus } from './blocks/TagPaymentStatus'
import { TagPenilaianStatus } from './blocks/TagPenilaianStatus'
import PenilaianWebinarDrawer, {
  usePenilaianWebinarDrawer,
} from './blocks/PenilaianWebinarDrawer'
import { useQueryJadwalWebinar } from './blocks/hooks'

export const WebinarEvidenceManagementList: React.FC<
  IResourceComponentsProps<GetListResponse<any>>
> = ({ options }) => {
  const { data, isFetching, isFetchedAfterMount, refetch } =
    useQueryJadwalWebinar()
  const { show, ...penilaianWebinarDrawerProps } = usePenilaianWebinarDrawer()
  return (
    <List title="Jadwal Webinar">
      <Head>
        <title>LMS Admin | {options?.label}</title>
      </Head>
      <Table
        key={`${data?.data.meta.shouldPoll}`}
        dataSource={data?.data.data || []}
        loading={isFetching && !isFetchedAfterMount}
        rowKey="id"
        pagination={false}
        className="overflow-auto"
      >
        <Table.Column
          dataIndex={'date'}
          title="Tanggal"
          fixed="left"
          render={(value) => {
            const date = parseDate(value)
            return <TextField value={`${date.format('DD MMM YYYY')}`} />
          }}
        />

        <Table.Column
          dataIndex={'webinarSchedule'}
          title="Waktu"
          width={500}
          render={(value) => {
            return (
              value &&
              Object.entries<SessionTime>(value)
                .filter(([key]) => key === 'wib')
                .map(([key, { startTime, endTime }]) => {
                  return (
                    <div key={key} style={{ width: '125px' }}>
                      <span>{`${startTime} - ${endTime} ${key.toUpperCase()}`}</span>
                    </div>
                  )
                })
            )
          }}
        />

        <Table.Column
          dataIndex={'name'}
          title="Kelas"
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex={'programLocal'}
          title="Program Lokal"
          render={(value) => <TextField value={value?.name} />}
        />
        <Table.Column
          dataIndex={'webinar'}
          title="Judul Webinar"
          render={(value) => <TextField value={value?.title} />}
        />
        <Table.Column
          dataIndex={'webinarUrl'}
          title="Link Gmeet"
          render={(value) => <UrlField value={value} target="_blank" />}
        />
        <Table.Column
          dataIndex={'inquiryUrl'}
          title="Link Pertanyaan"
          render={(value) => <UrlField value={value} target="_blank" />}
        />
        <Table.Column
          dataIndex={'reportUrl'}
          title="Link Rekaman"
          render={(value) => <UrlField value={value} target="_blank" />}
        />
        <Table.Column
          dataIndex={['paymentProof', 'status']}
          title="Status Pembayaran"
          render={(value) => <TagPaymentStatus status={value} />}
        />
        <Table.Column<InstructorWebinarSchedule>
          dataIndex={['penilaianWebinar', 'status']}
          title="Status Penilaian"
          render={(value: InstructorWebinarPenilaianStatus, record) => (
            <TagPenilaianStatus status={value} date={record.date} />
          )}
        />
        {/* TODO: enrich this */}
        <Table.Column<InstructorWebinarSchedule>
          fixed="right"
          render={(_, record) => {
            const { date, id, timezone, webinarSchedule } = record
            const currentWebinarTimezone = timezone

            return (
              <Space direction="vertical">
                <PopConfirmScheduleForm
                  title="Pertanyaan Webinar"
                  record={record}
                  formType="inquiryUrl"
                  key={`inquiry-form-${id}`}
                  disabled={isBeforeToday(
                    date,
                    webinarSchedule[currentWebinarTimezone].endTime
                  )}
                />
                {record.penilaianWebinar && (
                  <Button
                    block
                    disabled={record.penilaianWebinar?.status === 'NOT_STARTED'}
                    icon={<Icons.FormOutlined />}
                    onClick={async () => {
                      refetch()
                      show({
                        webinarId: record.webinar.id,
                        webinarName: record.name,
                        webinarKelasGroupId: record.webinarKelasGroupId,
                      })
                    }}
                  >
                    Isi Penilaian
                  </Button>
                )}
                <PopConfirmCancelWebinar wkgId={record.webinarKelasGroupId} />
              </Space>
            )
          }}
        />
      </Table>
      <PenilaianWebinarDrawer
        {...penilaianWebinarDrawerProps}
        loading={isFetching && !isFetchedAfterMount}
        onSuccessSubmit={async () => {
          await refetch()
        }}
        penilaianWebinarList={
          data?.data?.data?.find(
            ({ webinarKelasGroupId }) =>
              webinarKelasGroupId ===
              penilaianWebinarDrawerProps.ids.webinarKelasGroupId
          )?.penilaianWebinar?.indicators || []
        }
      />
    </List>
  )
}
