import {
  Descriptions,
  Popconfirm,
  Button,
  Icons,
  Steps,
  StepsProps,
  useModalForm,
  TextField,
  Space,
  Divider,
} from '@pankod/refine-antd'
import dayjs from 'dayjs'
import React from 'react'
import { useCan, useCustomMutation, useOne } from '@pankod/refine-core'

import {
  PROGRAM_STATE,
  TLMSProgramDetail,
  TimespendDurationResponse,
} from '../types'
import { getProgressDot, secondToMinuteText } from '../utils'
import { showErrorNotification } from '@resources/angkatan-ppg-management/utils'
import { ModalFormProgramDuration } from './blocks/ModalFormProgramDuration'
import { TCommonError } from 'src/interfaces/common'
import { useProgramStore } from '../store'
import SyncClassesButton from './SyncClassesButton'
import ExportTimespendButton from './ExportTimespendButton'

const ENABLE_TIMESPEND_PROGRAM = false

type TTabProgramDetailProps = {
  data?: TLMSProgramDetail
  status: {
    status: [StepsProps['status'], StepsProps['status'], StepsProps['status']]
    nextState: PROGRAM_STATE | null
  }
  onUpdateStatus: () => void
  isUpdatingStatus: boolean
}
export const TabProgramDetail = ({
  data,
  status,
  onUpdateStatus,
  isUpdatingStatus,
}: TTabProgramDetailProps) => {
  const { data: canAccessTimespend } = useCan({
    action: 'field',
    resource: 'program-timespend',
    params: { field: 'detail' },
  })
  const { data: canSyncClass } = useCan({
    action: 'field',
    resource: 'lms-program-management',
    params: { field: 'sync-classes' },
  })
  const { data: canExportTimespend } = useCan({
    action: 'field',
    resource: 'program-timespend',
    params: { field: 'export' },
  })
  const isOwner = useProgramStore(({ isOwner }) => isOwner)
  const { mutateAsync, isLoading } = useCustomMutation<
    {},
    TCommonError,
    { isSyncBlueprint: boolean }
  >()

  const { data: targetDuration } = useOne<TimespendDurationResponse>({
    resource: `programs/${data?.id}/timespends`,
    dataProviderName: 'lms',
    id: 'target-duration',
    queryOptions: {
      enabled:
        ENABLE_TIMESPEND_PROGRAM &&
        Boolean(canAccessTimespend?.can && !!data?.id),
    },
  })

  const { data: canUpdateStatus } = useCan({
    action: 'field',
    resource: 'lms-program-management',
    params: { field: 'status' },
  })

  const {
    modalProps: configureModalProps,
    formProps: configureFormProps,
    submit: configureSubmit,
    show: configureShow,
  } = useModalForm({
    action: 'edit',
    resource: `programs/${data?.id}/timespends`,
    dataProviderName: 'lms',
    redirect: false,
    successNotification: {
      message: 'Target durasi berhasil diubah',
      type: 'success',
      description: 'Sukses',
    },
    errorNotification: (error) =>
      showErrorNotification(
        error,
        'There was a problem when creating jurnal modul'
      ),
  })

  const { async: asyncTarget, sync: syncTarget } = targetDuration?.data
    ?.data || { async: 0, sync: 0 }

  return (
    <>
      <Descriptions
        bordered
        column={2}
        className="mt-4 mb-5"
        labelStyle={{ fontWeight: 600 }}
      >
        <Descriptions.Item span={1} label="ID">
          {data?.id}
        </Descriptions.Item>
        <Descriptions.Item span={1} label="SIS Account ID">
          {data?.sisAccountId || (
            <TextField italic type="secondary" value="Tidak tersedia" />
          )}
        </Descriptions.Item>
        <Descriptions.Item span={2} label="Name">
          {data?.name}
        </Descriptions.Item>
        <Descriptions.Item span={2} label="Slug">
          {data?.slug}
        </Descriptions.Item>
        <Descriptions.Item span={1} label="Start Date">
          {dayjs(data?.startDate).format('DD MMM YYYY')}
        </Descriptions.Item>
        <Descriptions.Item span={1} label="End Date">
          {dayjs(data?.endDate).format('DD MMM YYYY')}
        </Descriptions.Item>
      </Descriptions>

      <Space>
        {(canSyncClass?.can || isOwner) &&
          data?.status === PROGRAM_STATE.PREPARATION && (
            <SyncClassesButton
              isLoading={isLoading}
              onConfirm={async (isSyncBlueprint) =>
                mutateAsync({
                  dataProviderName: 'lms',
                  method: 'post',
                  url: `${process.env.NEXT_PUBLIC_LMS_API_URL}/programs/${data?.id}/sync_classes`,
                  values: {
                    isSyncBlueprint,
                  },
                  successNotification: {
                    message: 'Kelas berhasil disinkronisasi',
                    type: 'success',
                    description: 'Sukses',
                  },
                  errorNotification: (err) =>
                    showErrorNotification(
                      err,
                      'Terdapat gangguan ketika sinkronisasi kelas'
                    ),
                })
              }
            />
          )}
        <ExportTimespendButton
          can={canExportTimespend?.can}
          isOwner={isOwner}
          programId={data?.id}
        />
      </Space>

      <Divider />

      <Descriptions
        title="Status Program"
        className="mt-6"
        extra={
          <Space>
            <Popconfirm
              placement="topRight"
              title={
                <span>
                  Apakah Anda ingin mengubah status program menjadi{' '}
                  <strong>{status.nextState?.toLocaleUpperCase()}</strong>?
                </span>
              }
              onConfirm={onUpdateStatus}
              okText="Ubah Status"
              cancelText="Batalkan"
              disabled={
                !status.nextState ||
                isUpdatingStatus ||
                (!canUpdateStatus?.can && !isOwner)
              }
            >
              <Button
                disabled={
                  !status.nextState || (!canUpdateStatus?.can && !isOwner)
                }
                loading={isUpdatingStatus}
                icon={<Icons.DoubleRightOutlined />}
              >
                Ubah Status
              </Button>
            </Popconfirm>
          </Space>
        }
      >
        <Descriptions.Item span={1}>
          <Steps
            current={[
              PROGRAM_STATE.PREPARATION,
              PROGRAM_STATE.RUNNING,
              PROGRAM_STATE.ENDED,
            ].findIndex((v) => v === data?.status)}
            progressDot={getProgressDot}
          >
            <Steps.Step
              title={PROGRAM_STATE.PREPARATION}
              className="capitalize"
              status={status.status[0]}
            />
            <Steps.Step
              title={PROGRAM_STATE.RUNNING}
              className="capitalize"
              status={status.status[1]}
            />
            <Steps.Step
              title={PROGRAM_STATE.ENDED}
              className="capitalize"
              status={status.status[2]}
            />
          </Steps>
        </Descriptions.Item>
      </Descriptions>

      {ENABLE_TIMESPEND_PROGRAM && canAccessTimespend?.can && (
        <>
          <div className="mt-8 mb-2 flex flex-row justify-between">
            <h3 className="text-lg font-semibold">Target Durasi Timespend</h3>
            <Button
              type="ghost"
              className="ml-2"
              onClick={() => configureShow('target-duration')}
              icon={<Icons.ToolOutlined />}
            >
              Ubah Target Durasi
            </Button>
          </div>

          <Descriptions
            bordered
            column={2}
            className="mt-4 mb-6"
            labelStyle={{ fontWeight: 600 }}
          >
            <Descriptions.Item span={2} label="Timespend (Async)">
              {secondToMinuteText(asyncTarget)}
            </Descriptions.Item>
            <Descriptions.Item span={2} label="Recording (Sync)">
              {secondToMinuteText(syncTarget)}
            </Descriptions.Item>

            <Descriptions.Item span={2} label="Durasi Total">
              {secondToMinuteText(asyncTarget + syncTarget)}
            </Descriptions.Item>
          </Descriptions>

          <ModalFormProgramDuration
            modalProps={configureModalProps}
            formProps={configureFormProps}
            onSubmit={configureSubmit}
          />
        </>
      )}
    </>
  )
}
