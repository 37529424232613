import {
  List,
  ShowButton,
  Space,
  Table,
  TableColumnGroupType,
  TableColumnType,
  // useModalForm,
  useTable,
} from '@pankod/refine-antd'
import React from 'react'
import {
  IResourceComponentsProps,
  GetListResponse,
  // useDelete,
} from '@pankod/refine-core'
import Head from 'next/head'

import { LPTKResponse } from './types'
import { HeaderTitle } from '@components/HeaderTableTitle'
// import { ModalForm } from './blocks/ModalForm'
// import { showErrorNotification } from '@resources/angkatan-ppg-management/utils'

const LPTK_RESOURCE = 'lptk'

export const LPTKList: React.FC<
  IResourceComponentsProps<GetListResponse<LPTKResponse>>
> = ({ options }) => {
  const { tableProps } = useTable<LPTKResponse>({
    resource: LPTK_RESOURCE,
    dataProviderName: 'lms',
    syncWithLocation: true,
  })

  // const { mutateAsync: doDelete, isLoading } = useDelete()

  // const {
  //   modalProps: createModalProps,
  //   formProps: createFormProps,
  //   show: createShow,
  //   close: createClose,
  // } = useModalForm({
  //   action: 'create',
  //   resource: LPTK_RESOURCE,
  //   dataProviderName: 'lms',
  //   warnWhenUnsavedChanges: true,
  //   redirect: false,
  //   autoResetForm: true,
  //   autoSubmitClose: true,
  //   invalidates: ['list'],
  //   successNotification: {
  //     message: 'LPTK successfully created',
  //     type: 'success',
  //     description: 'Successful',
  //   },
  //   errorNotification: (error) =>
  //     showErrorNotification(error, 'There was a problem when creating LPTK'),
  // })

  const columns: (
    | TableColumnType<LPTKResponse>
    | TableColumnGroupType<LPTKResponse>
  )[] = [
    {
      title: <HeaderTitle align="left" title="ID" />,
      key: 'id',
      dataIndex: 'id',
    },
    {
      title: <HeaderTitle align="left" title="Nama LPTK" />,
      key: 'name',
      dataIndex: 'name',
      width: '75%',
    },
    {
      key: 'action',
      width: '20%',
      render: (_, record) => (
        <Space>
          <ShowButton recordItemId={record.id}>Lihat</ShowButton>
          {/* <PopDeleteConfirm
            title="Apakah Anda yakin ingin menghapus LPTK ini?"
            onConfirm={() => {
              return doDelete({
                id: record.id,
                resource: 'lptk',
                dataProviderName: 'lms',
                invalidates: ['list'],
                successNotification: {
                  message: 'LPTK successfuly removed',
                  type: 'success',
                  description: 'Successful',
                },
                errorNotification: (error) =>
                  showErrorNotification(
                    error,
                    'There was an error when removing LPTK'
                  ),
              })
            }}
            children={
              <Button danger icon={<Icons.DeleteOutlined />}>
                Hapus
              </Button>
            }
            okText="Hapus"
            cancelText="Kembali"
            okButtonProps={{ danger: true, loading: isLoading }}
          /> */}
        </Space>
      ),
    },
  ]

  return (
    <List
      title="Daftar LPTK"
      // headerButtons={() => (
      //   // <Button
      //   //   icon={<Icons.PlusOutlined />}
      //   //   type="primary"
      //   //   onClick={() => createShow()}
      //   // >
      //   //   Buat LPTK
      //   // </Button>
      //   <></>
      // )}
    >
      <Head>
        <title>LMS Admin | {options?.label}</title>
      </Head>
      <Table<LPTKResponse> {...tableProps} columns={columns} rowKey="id" />
    </List>
  )
}
