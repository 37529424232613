import React, { useEffect, useRef, useState } from 'react'
import { Canvas } from 'fabric'
import { message, RcFile, Spin } from '@pankod/refine-antd'
// @ts-expect-error
import { initAligningGuidelines } from 'fabric/extensions'

import {
  CanvasTabObject,
  deleteObject,
  exportSVG,
  createCanvasOnTab,
  fontLoader,
  moveActiveObjectUp,
  moveActiveObjectDown,
  generateSVGPreview,
  clearAllSelection,
  getSVGStringFromUrl,
  getObjByKey,
} from './utils/canvas'
import { addTextbox } from './utils/textbox'
import useHotKey from './utils/hooks'
import { addImage } from './utils/image'
import { CanvasFooter } from './components/CanvasFooter'
import { useCanvasHistoryStack } from './hooks/useCanvasHistoryStack'
import { useCutCopyPaste } from './hooks/useCutCopyPaste'
import { PageRadio } from './components/PageRadio'
import { CANVAS_CONFIG, CANVAS_TAB_ID } from './constants'
import { OrderNavigator } from './components/OrderNavigator'
import SelectedComponentConfig from './components/SelectedComponentConfig'
import { ButtonSVGPreview } from './components/ModalPreviewSVG'
import MainToolbar from './components/MainToolbar'
import {
  CertificateTemplate,
  CertificateTemplateVariables,
  CertificateTemplatePayload,
  UserVariable,
  RAWCertificateTemplatePayload,
} from '../types'
import { certificateTabChangeEvent } from './utils/events'
import { exportObjectToSVG } from './utils/exportSVG'

type FabricWrapper = {
  templates?: CertificateTemplate
  variables?: CertificateTemplateVariables
  onCreateVariable: (variable: UserVariable) => Promise<any>
  onDeleteVariable: (key: string) => Promise<any>
  onPublish: () => Promise<any>
  onSave: (payload: CertificateTemplatePayload) => Promise<any>
  onExport: (payload: CertificateTemplatePayload) => Promise<any>
  loading?: boolean
  navigatorLoading?: boolean
}

const FabricWrapper = (props: FabricWrapper) => {
  const [isLoading, setLoading] = useState(false)
  const [activeTab, setActiveTab] = useState<number>(-1)
  const [initialMount, setInitialMount] = useState<boolean>(true)
  const [shouldLoadInitialTemplate, setShouldLoadInitialTemplate] = useState({
    front: true,
    back: true,
  })

  const [canvasTabObject, setCanvasTabObject] = useState<CanvasTabObject[]>([
    {
      id: 'canvas-front-page',
      canvasObj: {
        version: '6.1.0',
        objects: [],
      },
    },
    {
      id: 'canvas-back-page',
      canvasObj: {
        version: '6.1.0',
        objects: [],
      },
    },
  ])
  const [tabObjToDelete, setTabObjToDelete] = useState<any[]>([])

  const canvas = useRef<Canvas | null>(null)
  const container = useRef<HTMLDivElement>(null)
  const { undo, redo, saveState, updateTabHistoryStack, currentStackObj } =
    useCanvasHistoryStack(canvas.current)
  const { cut, copy, paste, duplicate } = useCutCopyPaste(canvas.current)

  useEffect(() => {
    const fetchFont = async () => {
      try {
        setLoading(true)
        await fontLoader()
      } catch (e) {
        message.error('Error loading font')
      } finally {
        setLoading(false)
      }
    }

    if (initialMount) {
      setInitialMount(false)
      setActiveTab(0)
      fetchFont()
    } else {
      canvas.current = createCanvasOnTab({
        canvasTabObject,
        activeTab,
        saveState,
        syncTab: handleActiveTabChange,
        shouldSaveOnObjectInit:
          shouldLoadInitialTemplate.back || shouldLoadInitialTemplate.front,
      })

      initAligningGuidelines(canvas.current, CANVAS_CONFIG.alignGuideline)

      // FORCE REMOVE DELETE STACK
      setTimeout(async () => {
        await deleteCustomObjectFromStackOnTab(activeTab, null, canvas.current)
      }, 0)
    }

    return () => {
      canvas.current?.dispose()
      canvas.current = null
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab])

  useEffect(() => {
    const { loading } = props

    if (
      !loading &&
      (shouldLoadInitialTemplate.back || shouldLoadInitialTemplate.front)
    ) {
      initCanvasFromTemplate(activeTab)
      setShouldLoadInitialTemplate((prev) => ({
        ...prev,
        [activeTab === 0 ? 'front' : 'back']: false,
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.loading, activeTab])

  useHotKey('mod+z', undo)
  useHotKey('mod+shift+z', redo)
  useHotKey('mod+c', copy)
  useHotKey('mod+x', cut)
  useHotKey('mod+v', paste)
  useHotKey('mod+d', duplicate)
  useHotKey('delete', () => deleteObject(canvas.current))
  useHotKey('backspace', () => deleteObject(canvas.current))
  useHotKey('mod+arrowup', () => moveActiveObjectUp(canvas.current))
  useHotKey('mod+arrowdown', () => moveActiveObjectDown(canvas.current))
  useHotKey('esc', () => clearAllSelection(canvas.current))

  const initCanvasFromTemplate = async (activeTab: number) => {
    const { frontTemplateUrl, backTemplateUrl } = props.templates || {}

    if (!frontTemplateUrl && !backTemplateUrl) return

    const currentActiveTabObj = canvasTabObject?.[activeTab].canvasObj || {}
    if (currentActiveTabObj.objects.length > 0) return

    const ACTIVE_TAB_TEMPLATE_URL: { [key: number]: string | undefined } = {
      0: frontTemplateUrl,
      1: backTemplateUrl,
    }

    const initialTabTemplateString = await getSVGStringFromUrl(
      ACTIVE_TAB_TEMPLATE_URL[activeTab] || '',
      props.variables
    )

    const svgBlob = new Blob([initialTabTemplateString], {
      type: 'image/svg+xml',
    })

    addImage({
      file: svgBlob as RcFile,
      canvas: canvas.current,
    })

    await handleActiveTabChange(activeTab)
  }

  const handleActiveTabChange = async (index: number) => {
    const currentCanvasObj = await canvas.current?.toObject([
      'customId',
      'customVariableKey',
      'customObjectType',
    ])

    if (currentCanvasObj) {
      setCanvasTabObject((prev: any) => {
        const newTabObject = [...prev]
        newTabObject[activeTab].canvasObj = currentCanvasObj
        return newTabObject
      })
    }

    setActiveTab(index)
    await updateTabHistoryStack(activeTab, index)
    // force render to appear last in eventloop queue; probably caused by dom canvas ID change

    deleteCustomObjectFromStackOnTab(activeTab, null, canvas.current)

    setTimeout(async () => {
      canvas.current?.renderAll()
      document.dispatchEvent(certificateTabChangeEvent)
    }, 0)
  }

  const handleSaveOrPublish = async (mode: 'SAVE' | 'PUBLISH' | 'EXPORT') => {
    await handleActiveTabChange(activeTab)

    if (mode === 'PUBLISH') {
      return await props.onPublish()
    }
    const { svgString, usedSystemVariables } = await exportObjectToSVG(
      canvasTabObject[activeTab].canvasObj
    )

    const payload: RAWCertificateTemplatePayload = {
      template: svgString,
      usedSystemVariables,
    }

    if (mode === 'EXPORT') {
      await props.onExport({
        ...payload,
        page: activeTab === 0 ? 'front' : 'back',
      })
      return
    }

    await props.onSave({
      ...payload,
      page: activeTab === 0 ? 'front' : 'back',
    })
  }

  const addCustomObjectToDeleteStack = (
    foundObj: any[],
    canvas: Canvas | null
  ) => {
    setTabObjToDelete((prevStack) => {
      const newStack = prevStack ? [...prevStack] : []
      foundObj.forEach((objs, index) => {
        if (!newStack[index]) {
          newStack[index] = []
        }
        newStack[index] = [...newStack[index], ...objs]
      })

      return newStack
    })
    deleteCustomObjectFromStackOnTab(activeTab, foundObj, canvas)
  }

  const deleteCustomObjectFromStackOnTab = async (
    tabIndex: number,
    foundObj?: any[] | null,
    canvas?: Canvas | null
  ) => {
    const currentListToDelete =
      foundObj?.[tabIndex] || tabObjToDelete[tabIndex] || []

    if (currentListToDelete.length === 0) return

    const currentCanvasObj = canvas?.getObjects()

    currentListToDelete.forEach((obj: any) => {
      const foundObj = currentCanvasObj?.find(
        (el) => el.customId === obj.customId
      )
      if (foundObj) {
        canvas?.remove(foundObj)
      }
    })

    // update list to delete
    setTabObjToDelete((prev: any) => {
      const newTabObjToDelete = [...prev]
      newTabObjToDelete[tabIndex] = []
      return newTabObjToDelete
    })

    setTimeout(async () => {
      canvas?.renderAll()
    }, 0)
  }

  const loading = isLoading || props.loading

  return (
    <>
      <ButtonSVGPreview
        onClick={() => generateSVGPreview(canvasTabObject)}
        initialTemplateUrl={{
          front: props.templates?.frontTemplateUrl || '',
          back: props.templates?.backTemplateUrl || '',
        }}
        variables={props.variables}
        shouldLoadInitialTemplate={{
          front: canvasTabObject[0].canvasObj.objects.length === 0,
          back: canvasTabObject[1].canvasObj.objects.length === 0,
        }}
      />
      <div
        ref={container}
        className="flex justify-center items-center p-3 bg-slate-300 fabric-wrapper"
        style={{
          zIndex: 2,
          overflow: 'hidden',
        }}
      >
        {loading && (
          <div className="absolute h-full w-full flex items-center justify-center z-40 before:bg-slate-500 before:absolute before:content-[''] before:w-full before:h-full before:opacity-25">
            <Spin size="large" className="z-50" />
          </div>
        )}
        <SelectedComponentConfig canvas={canvas.current} />
        <PageRadio
          onChange={(_, index) => {
            handleActiveTabChange(index)
          }}
          // TODO: find better condition later
          showFrontPageAlert={
            canvasTabObject[0].canvasObj.objects.length === 0 ||
            tabObjToDelete?.[0]?.length > 0
          }
          showBackPageAlert={
            canvasTabObject[1].canvasObj.objects.length === 0 ||
            tabObjToDelete?.[1]?.length > 0
          }
        />

        {/** Must be placed before canvas element so that the cursor grab styling worked */}
        <CanvasFooter
          canvas={canvas.current}
          targetWrapperId="canvas-wrapper"
        />

        <OrderNavigator
          canvas={canvas.current}
          currentStack={currentStackObj}
          loading={props.navigatorLoading}
        />

        <div
          id="canvas-wrapper"
          style={{
            width: '297mm',
            height: '210mm',
            background: 'white',
            zIndex: 1,
          }}
          className="peer-[.canvas-grab]:*:*:!cursor-grab peer-[.canvas-grabbing]:*:*:!cursor-grabbing"
        >
          <canvas id={CANVAS_TAB_ID[activeTab]} />
        </div>

        <MainToolbar
          activeTab={activeTab === 0 ? 'front' : 'back'}
          variables={props.variables}
          canvas={canvas.current}
          onCreateVariable={props.onCreateVariable}
          onDeleteVariable={async (key) => {
            const foundObjList = getObjByKey(
              canvasTabObject,
              key,
              props.variables
            )
            addCustomObjectToDeleteStack(foundObjList, canvas.current)
            await props.onDeleteVariable(key)
          }}
          onExportSVG={() => {
            exportSVG(canvas.current)
          }}
          onAddImage={(file) => {
            addImage({
              file,
              canvas: canvas.current,
            })
          }}
          onAddTextbox={() =>
            addTextbox({
              canvas: canvas.current,
            })
          }
          onPublishClick={async () => await handleSaveOrPublish('PUBLISH')}
          onSaveClick={async () => await handleSaveOrPublish('SAVE')}
          onExportClick={async () => await handleSaveOrPublish('EXPORT')}
        />
      </div>
    </>
  )
}

export default FabricWrapper
