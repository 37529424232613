import { useResource } from '@pankod/refine-core'
import React from 'react'
import { Table, TableProps, TextField, Tooltip } from '@pankod/refine-antd'

import useTableWithMeta from 'src/hooks/useTableWithMeta'
import { TCommonError } from 'src/interfaces/common'
import { ReportPenilaianKinerjaInstrukturWebinarResponse } from '../types'

const PenilaianInstrukturWebinarTable = () => {
  const { id: programId } = useResource()
  const { tableProps } = useTableWithMeta<
    ReportPenilaianKinerjaInstrukturWebinarResponse,
    TCommonError
  >({
    dataProviderName: 'lms',
    resource: `programs/${programId}/penilaian-webinar/report`,
    queryOptions: {
      select(data) {
        data.data.forEach(({ webinars }) => {
          webinars.sort((a, b) => +a.id - +b.id)
          webinars.forEach(({ penilaians }) => {
            penilaians.sort((a, b) => +a.id - +b.id)
          })
        })
        return data
      },
    },
  })

  const webinarsCols =
    (): TableProps<ReportPenilaianKinerjaInstrukturWebinarResponse>['columns'] => {
      return (
        tableProps?.dataSource?.[0]?.webinars?.map(
          ({ name, penilaians }, i) => ({
            title: (
              <Tooltip title={name}>
                <div className="flex text-center flex-col overflow-hidden">
                  <TextField value={name} ellipsis />
                </div>
              </Tooltip>
            ),
            ellipsis: { showTitle: false },
            width: 220,
            children: [
              ...penilaians.map(({ name, weight }, j) => {
                return {
                  ellipsis: { showTitle: false },
                  width: 220,
                  title: (
                    <Tooltip title={name}>
                      <div className="flex text-left flex-col overflow-hidden">
                        <TextField value={name} ellipsis />
                      </div>
                      <TextField
                        value={`(${String((weight || 0).toFixed(2))}%)`}
                        type="secondary"
                      />
                    </Tooltip>
                  ),
                  render: (
                    _: undefined,
                    record: ReportPenilaianKinerjaInstrukturWebinarResponse
                  ) => {
                    const finalScore =
                      record.webinars?.[i]?.penilaians?.[j]?.finalScore
                    return finalScore ? (
                      String(+finalScore.toFixed(2))
                    ) : (
                      <TextField value="-" type="secondary" />
                    )
                  },
                }
              }),
              {
                title: (
                  <Tooltip title={'Total Skor ' + name}>
                    <div className="flex text-center flex-col overflow-hidden">
                      <TextField italic value={'Total Skor ' + name} ellipsis />
                    </div>
                  </Tooltip>
                ),
                ellipsis: { showTitle: false },
                width: 220,
                render: (_, record) => {
                  const avgScore = record.webinars?.[i]?.averageScore
                  return avgScore ? (
                    <strong>{String(+avgScore.toFixed(2))}</strong>
                  ) : (
                    <TextField value="-" type="secondary" />
                  )
                },
              },
            ],
          })
        ) || []
      )
    }

  return (
    <Table
      {...tableProps}
      bordered
      className="w-full"
      scroll={{ x: 500 }}
      rowKey={({ userId, classGroupId, programLocalId }) =>
        `${userId}|${classGroupId}|${programLocalId}`
      }
      columns={[
        {
          title: 'Nama Instruktur',
          dataIndex: 'name',
          fixed: 'left',
          ellipsis: { showTitle: false },
          width: 200,
          render: (value) => (
            <Tooltip placement="topLeft" title={value}>
              {value}
            </Tooltip>
          ),
        },
        {
          title: 'Email',
          dataIndex: 'email',
          ellipsis: { showTitle: false },
          width: 200,
          render: (value) => (
            <Tooltip placement="topLeft" title={value}>
              {value}
            </Tooltip>
          ),
        },
        {
          title: 'Program Lokal',
          dataIndex: 'programLocalName',
          ellipsis: { showTitle: false },
          width: 200,
          render: (value) => (
            <Tooltip placement="topLeft" title={value}>
              {value}
            </Tooltip>
          ),
        },
        {
          title: 'Kelompok Kelas',
          dataIndex: 'classGroupName',
          ellipsis: { showTitle: false },
          width: 200,
          render: (value) => (
            <Tooltip placement="topLeft" title={value}>
              {value}
            </Tooltip>
          ),
        },
        {
          title: 'Jumlah Murid dalam Kelompok Kelas',
          dataIndex: 'numOfStudent',
          width: 150,
          render: (value) => (
            <Tooltip placement="topLeft" title={value}>
              {value}
            </Tooltip>
          ),
        },
        ...webinarsCols()!,
        {
          title: 'Rerata Total Skor',
          dataIndex: 'averageTotalScore',
          width: 100,
          render: (value) => (
            <strong>{String(+(value || 0).toFixed(2))}</strong>
          ),
        },
      ]}
    />
  )
}

export default PenilaianInstrukturWebinarTable
