import React from 'react'
import {
  TableColumnType,
  TableColumnGroupType,
  Table,
  Button,
  Icons,
  useModalForm,
  useTable,
  List,
  Drawer,
  Descriptions,
  Form,
  Input,
  useForm,
  useSelect,
  Select,
} from '@pankod/refine-antd'
import { MinusCircleOutlined } from '@ant-design/icons'
import {
  GetListResponse,
  IResourceComponentsProps,
  useDelete,
  useGetIdentity,
  useInvalidate,
} from '@pankod/refine-core'
import Head from 'next/head'

import { MataKuliahResponse } from './types'
import { HeaderTitle } from '@components/HeaderTableTitle'
import ModalParseCSV from '@components/ModalParseCSV'
import { TCommonError } from 'src/interfaces/common'
import { PopDeleteConfirm } from '@resources/lms-management/components/PopDeleteConfirm'
import { showErrorNotification } from '@resources/angkatan-ppg-management/utils'

export const MataKuliahList: React.FC<
  IResourceComponentsProps<GetListResponse<MataKuliahResponse>>
> = ({ options }) => {
  const invalidate = useInvalidate()

  const { mutateAsync: doDelete, isLoading } = useDelete()
  const { data } = useGetIdentity()
  const LMSRole = data?.user?.LMSRole || ''
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false)
  const [selectedMatkulData, setSelectedMatkulData] =
    React.useState<MataKuliahResponse>()

  const { tableProps, tableQueryResult } = useTable<
    MataKuliahResponse,
    TCommonError
  >({
    dataProviderName: 'lms',
    resource: `mata-kuliah`,
    queryOptions: {
      onSuccess(data) {
        const selectedDataInState = data?.data?.find(
          (item) => item.id === selectedMatkulData?.id
        )

        selectedDataInState &&
          subAccountFormProps?.form?.setFieldsValue({
            subAccountIds: selectedDataInState?.subAccountIds,
          })
      },
    },
  })

  const { selectProps: blueprintSelectProps } = useSelect({
    resource: `mata-kuliah/${selectedMatkulData?.id}/available-blueprint`,
    optionLabel: 'label',
    optionValue: 'name',
    queryOptions: {
      enabled: !!selectedMatkulData,
    },
    defaultValueQueryOptions: {
      enabled: false,
    },
    filters: [
      {
        field: 'pageSize',
        operator: 'eq',
        value: -1,
      },
    ],
  })

  const { modalProps, formProps, submit, show, close } = useModalForm({
    action: 'create',
    resource: `mata-kuliah`,
    submit: (formValue) => {
      return {
        data: (formValue.data as [])?.map((item: any) => {
          return {
            ...item,
            lptkId: Number(item.lptkId),
            semester: item.semester
              .split(',')
              .map((semesterNum: string) => Number(semesterNum)),
          }
        }),
      }
    },
    dataProviderName: 'lms',
    autoResetForm: true,
    redirect: false,
    successNotification: {
      message: 'Mata kuliah successfuly uploaded',
      type: 'success',
      description: 'Successful',
    },
    errorNotification: (error) =>
      showErrorNotification(
        error,
        'There was a problem when uploading mata kuliah'
      ),
  })

  const { formProps: subAccountFormProps, formLoading } = useForm({
    action: 'edit',
    resource: `mata-kuliah/${selectedMatkulData?.id}`,
    dataProviderName: 'lms',
    redirect: false,
    metaData: {
      httpMethod: 'patch',
    },
    onMutationSuccess: () => {
      invalidate({
        resource: `mata-kuliah`,
        dataProviderName: 'lms',
        invalidates: ['list'],
      })
    },
    errorNotification: (error) => {
      invalidate({
        resource: `mata-kuliah`,
        dataProviderName: 'lms',
        invalidates: ['list'],
      })

      return showErrorNotification(
        error,
        'There was a problem when updating mata kuliah'
      )
    },
  })

  const tableColumns: (
    | TableColumnType<MataKuliahResponse>
    | TableColumnGroupType<MataKuliahResponse>
  )[] = [
    {
      title: <HeaderTitle align="left" title="Semester" />,
      key: 'semester',
      dataIndex: 'semester',
      render: (_, record) => {
        // @ts-ignore
        return record.semester.join(', ')
      },
    },
    {
      title: <HeaderTitle align="left" title="Nama Label" />,
      key: 'label',
      dataIndex: 'label',
    },
    {
      title: <HeaderTitle align="left" title="Nama Mata Kuliah" />,
      key: 'name',
      dataIndex: 'name',
    },

    {
      title: <HeaderTitle align="left" title="LPTK ID" />,
      key: 'lptkId',
      dataIndex: 'lptkId',
    },
    {
      title: <HeaderTitle align="left" title="Nama LPTK" />,
      key: 'lptkName',
      dataIndex: 'lptkName',
    },
    {
      title: <HeaderTitle align="left" title="Jenis" />,
      key: 'type',
      dataIndex: 'type',
    },
    {
      title: <HeaderTitle align="left" title="Identifier" />,
      key: 'identifier',
      dataIndex: 'identifier',
    },
    {
      key: 'action',
      render: (_, record) => (
        <div className="flex flex-col">
          <PopDeleteConfirm
            title="Apakah Anda yakin ingin menghapus mata kuliah ini?"
            validateValue={record.course}
            placeholder="Tulis ulang nama mata kuliah"
            errorMessage="Nama mata kuliah mata kuliah tidak sesuai."
            children={
              <Button danger icon={<Icons.DeleteOutlined />}>
                Hapus
              </Button>
            }
            onConfirm={() => {
              return doDelete({
                id: record.id,
                resource: 'mata-kuliah',
                dataProviderName: 'lms',
                invalidates: ['all'],
                successNotification: {
                  message: 'Mata kuliah successfuly removed',
                  type: 'success',
                  description: 'Successful',
                },
                errorNotification: (error) =>
                  showErrorNotification(
                    error,
                    'There was a problem when removing mata kuliah'
                  ),
              })
            }}
            okText="Hapus"
            cancelText="Kembali"
            okButtonProps={{ danger: true, loading: isLoading }}
          />

          <Button
            className="mt-2"
            onClick={() => {
              setSelectedMatkulData(record)
              subAccountFormProps?.form?.setFieldsValue({
                subAccountIds: record.subAccountIds,
              })
              setIsDrawerOpen(true)
            }}
          >
            Atur Angkatan & Subaccount
          </Button>
        </div>
      ),
    },
  ]

  const [roleAffectedColumns, setRoleAffectedColumns] =
    React.useState(tableColumns)

  const isFieldFinalized = (field: any) => {
    try {
      return subAccountFormProps.form?.getFieldValue('subAccountIds')[field.key]
        .hasImports
    } catch {
      return false
    }
  }

  React.useEffect(() => {
    if (LMSRole !== 'super-admin') {
      const nonSuperColumn = roleAffectedColumns.filter(
        (column) => column.key !== 'lptkId' && column.key !== 'lptkName'
      )

      setRoleAffectedColumns(nonSuperColumn)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <List
      title="Daftar Mata Kuliah"
      headerButtons={() => (
        <Button
          icon={<Icons.UploadOutlined />}
          type="primary"
          onClick={() => show()}
        >
          Upload CSV Mata Kuliah
        </Button>
      )}
    >
      <Head>
        <title>LMS Admin | {options?.label}</title>
      </Head>
      <Table
        {...tableProps}
        loading={tableQueryResult.isFetching}
        columns={roleAffectedColumns}
      />

      <ModalParseCSV
        formProps={formProps}
        modalProps={modalProps}
        onClose={close}
        onSubmit={submit}
        validCSVHeaders={[
          'lptkId',
          'name',
          'semester',
          'label',
          'type',
          'identifier',
        ]}
        title="Mata Kuliah"
      />

      <Drawer
        title="Atur Angkatan dan subAccountId"
        width={'50%'}
        visible={isDrawerOpen}
        onClose={() => {
          setIsDrawerOpen(false)
        }}
      >
        <div className="flex flex-col">
          <Descriptions column={1} title="Mata Kuliah" bordered size="small">
            <Descriptions.Item label="Nama Mata Kuliah">
              {selectedMatkulData?.name}
            </Descriptions.Item>
            <Descriptions.Item label="Semester">
              {selectedMatkulData?.semester.join(', ')}
            </Descriptions.Item>
            <Descriptions.Item label="Label">
              {selectedMatkulData?.label}
            </Descriptions.Item>
            <Descriptions.Item label="Jenis">
              {selectedMatkulData?.type}
            </Descriptions.Item>
            <Descriptions.Item label="Identifier">
              {selectedMatkulData?.identifier}
            </Descriptions.Item>
            <Descriptions.Item label="LPTK ID">
              {selectedMatkulData?.lptkId}
            </Descriptions.Item>
            <Descriptions.Item label="Nama LPTK">
              {selectedMatkulData?.lptkName}
            </Descriptions.Item>
          </Descriptions>

          <div className="flex flex-col mt-4 w-full">
            <h3>Map Angkatan dan subAccount</h3>

            <Form
              {...subAccountFormProps}
              // onFinish={(values) => {
              //   console.log(values)

              //   const cleanupFinalizedStatus =
              //     values?.subAccountIds &&
              //     values?.subAccountIds?.map((item) => {
              //       delete item.hasImports

              //       return item
              //     })

              //   onFinish({
              //     subAccountIds: cleanupFinalizedStatus,
              //   })
              // }}
              layout="vertical"
            >
              <Form.List name="subAccountIds">
                {(fields, { add, remove }) => {
                  return (
                    <div className="flex flex-col w-full">
                      {fields.map((field) => (
                        <div
                          key={field.key}
                          className="w-full flex flex-row items-center justify-between gap-4"
                        >
                          <Form.Item
                            {...field}
                            name={[field.name, 'angkatanId']}
                            label="Angkatan ID"
                            rules={[
                              {
                                required: true,
                                message: 'Angkatan ID is required!',
                              },
                            ]}
                            className="w-full "
                          >
                            <Input
                              placeholder="Angkatan ID"
                              autoComplete="off"
                              disabled={formLoading || isFieldFinalized(field)}
                            />
                          </Form.Item>

                          {/* <Form.Item
                            {...field}
                            name={[field.name, 'subAccountId']}
                            label="Sub Account ID"
                            rules={[
                              {
                                required: true,
                                message: 'Sub Account ID is required!',
                              },
                            ]}
                          >
                            <Input
                              placeholder="Sub Account ID"
                              autoComplete="off"
                              disabled={formLoading || isFieldFinalized(field)}
                            />
                          </Form.Item> */}

                          <Form.Item
                            {...field}
                            name={[field.name, 'blueprintId']}
                            label="Blueprint ID"
                            className="w-full "
                            rules={[
                              {
                                required: true,
                                message: 'Blueprint ID is required!',
                              },
                            ]}
                          >
                            <Select
                              {...blueprintSelectProps}
                              placeholder="Blueprint ID"
                              filterOption={(input, option) =>
                                ((option?.label ?? '') as string)
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              showSearch
                              onSearch={() => {}}
                              disabled={formLoading || isFieldFinalized(field)}
                            />
                          </Form.Item>

                          <Button
                            size="small"
                            disabled={isFieldFinalized(field)}
                            onClick={() =>
                              !isFieldFinalized(field) && remove(field.name)
                            }
                          >
                            <MinusCircleOutlined />
                          </Button>
                        </div>
                      ))}
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          block
                          icon={<Icons.PlusOutlined />}
                        >
                          Tambah Map
                        </Button>
                      </Form.Item>

                      <Form.Item>
                        <Button type="primary" htmlType="submit">
                          Apply Map Angkatan dan Sub Account
                        </Button>
                      </Form.Item>
                    </div>
                  )
                }}
              </Form.List>
            </Form>
          </div>
        </div>
      </Drawer>
    </List>
  )
}
