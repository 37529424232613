import { Modal, Form, Select, Radio, Space } from '@pankod/refine-antd'
import { useOne } from '@pankod/refine-core'
import React from 'react'

export type ModalAddMatkulRombelProps = {
  modalProps: any
  formProps: any
  onSubmit?: () => void
  angkatanId: string
  lptkId: number | null
  rombelId: string
}

export const ModalAddMatkulRombel = (props: ModalAddMatkulRombelProps) => {
  const { modalProps, formProps, onSubmit, angkatanId, lptkId, rombelId } =
    props

  const selectedType = Form.useWatch('type', formProps?.form)
  const semester = Form.useWatch('semester', formProps?.form)

  // cannot useSelect somehow, it throwing runtime error (race condition)
  const { data, isLoading } = useOne({
    resource: `angkatan/${angkatanId}/lptk/${lptkId}/semester/${
      semester || '1'
    }/rombel/${rombelId}/mata-kuliah`,
    id: 'available-list',
    queryOptions: {
      enabled: !!angkatanId && !!lptkId && !!rombelId && modalProps.visible,
    },
  })

  const matkulOptions = data?.data.data
    ?.map((item: any) => ({
      label: item.name,
      value: item.id,
      type: item.type,
    }))
    .filter((item: any) => {
      return selectedType ? item.type === selectedType : item
    })

  React.useEffect(() => {
    formProps?.form?.setFieldsValue({
      mataKuliahId: undefined,
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedType])

  return (
    <Modal
      {...modalProps}
      title="Tambah Matkul (Kelas / Course)"
      {...(onSubmit && {
        okButtonProps: {
          onClick: async () => {
            const data = await onSubmit()
            formProps.onFinish(data)
          },
        },
      })}
      okText="Simpan"
      cancelText="Kembali"
    >
      <Form
        {...formProps}
        layout="vertical"
        validateMessages={{ required: '${label} must be filled.' }}
        initialValues={{
          semester: 1,
        }}
      >
        <Form.Item
          label="Pilih Semester"
          name="semester"
          required
          rules={[{ required: true }]}
        >
          <Radio.Group>
            <Space direction="vertical">
              <Radio value={1}>Semester 1</Radio>
              <Radio value={2}>Semester 2</Radio>
            </Space>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label="Pilih Jenis Mata Kuliah"
          name="type"
          required
          rules={[{ required: true }]}
        >
          <Radio.Group>
            <Space direction="vertical">
              <Radio value={'SELEKTIF'}>Selektif</Radio>
              <Radio value={'ELEKTIF'}>Elektif</Radio>
              <Radio value={'INTI'}>Inti</Radio>
            </Space>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label="Pilih Mata Kuliah"
          name="mataKuliahId"
          required
          rules={[{ required: true }]}
        >
          <Select
            options={matkulOptions}
            showSearch={false}
            loading={isLoading}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}
