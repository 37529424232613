/** Reference: https://paigekim29.medium.com/toast-ui-editor-next-js-c9b48927fbf7 */

import dynamic from 'next/dynamic'
import * as React from 'react'
import { Editor as EditorType, EditorProps } from '@toast-ui/react-editor'

import '@toast-ui/editor/dist/toastui-editor.css'
import { sanitizeHtml } from './utils'
import { TuiEditorWithForwardedProps } from './TuiEditorWrapper'
import { downscaleImage, file2b64 } from './utils'
import {
  DEFAULT_EDITOR_TYPE,
  DEFAULT_HEIGHT,
  DEFAULT_MAX_UPLOAD_IMAGE_HEIGHT,
  DEFAULT_MAX_UPLOAD_IMAGE_WIDTH,
  DEFAULT_PREVIEW_STYLE,
  DEFAULT_TOOLBAR_ITEMS,
  DEFAULT_VALUE_TYPE,
} from './config'

interface EditorPropsWithHandlers extends EditorProps {
  onChange?(value: string): void
}

const Editor = dynamic<TuiEditorWithForwardedProps>(
  () => import('./TuiEditorWrapper'),
  { ssr: false }
)
// eslint-disable-next-line react/display-name
const EditorWithForwardedRef = React.forwardRef<
  EditorType | undefined,
  EditorPropsWithHandlers
>((props, ref) => (
  <Editor {...props} forwardedRef={ref as React.MutableRefObject<EditorType>} />
))

interface Props extends EditorProps {
  onChange?: (value: string) => void
  value?: string
  valueType?: 'markdown' | 'html'
  maxUploadImage?: {
    height?: number
    width?: number
  }
  toolbarImageUploader?: boolean
}

const WysiwygEditor: React.FC<Props> = (props) => {
  const {
    initialValue,
    previewStyle,
    height,
    initialEditType,
    useCommandShortcut,
    value,
    maxUploadImage,
    toolbarImageUploader = true,
  } = props

  let TOOLBAR_ITEMS = DEFAULT_TOOLBAR_ITEMS

  if (!toolbarImageUploader) {
    TOOLBAR_ITEMS[3] = DEFAULT_TOOLBAR_ITEMS[3].filter(
      (item) => item !== 'image'
    )
  }
  const editorRef = React.useRef<EditorType>()
  const handleChange = React.useCallback(() => {
    if (!editorRef.current) {
      return
    }

    const instance = editorRef.current.getInstance()
    const valueType = props.valueType || DEFAULT_VALUE_TYPE

    props.onChange?.(
      valueType === 'markdown'
        ? instance.getMarkdown()
        : sanitizeHtml(instance.getHTML())
    )
  }, [props, editorRef])

  return (
    <div>
      <EditorWithForwardedRef
        {...props}
        initialValue={value || initialValue || ''}
        previewStyle={previewStyle || DEFAULT_PREVIEW_STYLE}
        height={height || DEFAULT_HEIGHT}
        initialEditType={initialEditType || DEFAULT_EDITOR_TYPE}
        hooks={{
          addImageBlobHook: async (blob, callback) => {
            const dataUrl = await file2b64(blob)

            const downScaled = await downscaleImage({
              dataUrl,
              maxHeight:
                maxUploadImage?.height || DEFAULT_MAX_UPLOAD_IMAGE_HEIGHT,
              maxWidth: maxUploadImage?.width || DEFAULT_MAX_UPLOAD_IMAGE_WIDTH,
            })

            callback(downScaled)

            return false
          },
        }}
        usageStatistics={false}
        useCommandShortcut={useCommandShortcut || true}
        ref={editorRef}
        onChange={handleChange}
        hideModeSwitch
        toolbarItems={TOOLBAR_ITEMS}
        linkAttributes={{
          target: '_blank',
          rel: 'noopener noreferrer',
        }}
      />
    </div>
  )
}

export default WysiwygEditor
