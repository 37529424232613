import { useNode, Node } from '@craftjs/core'
import React from 'react'
import clsx from 'clsx'
import { Form, Input, InputNumber, Switch } from '@pankod/refine-antd'
import { Controller } from 'react-hook-form'
import { capitalize } from 'lodash'
import { Text as LibraryText } from '@instructure/ui-text'
import {
  TextInput as LibraryTextInput,
  TextInputProps,
} from '@instructure/ui-text-input'

import {
  TextInputCustomProps,
  TTextInput,
  WIDGET_NAME,
} from '@components/FormBuilder/schema'
import {
  mappedWidgetSettingsError,
  widgetSettingsOnValueChange,
  getWidgetSettingsFields,
} from '@components/FormBuilder/utils'
import SelectWidgetSettings from '../../../editor/widgetSettings/SelectWS'
import { useFormContext } from '@components/FormBuilder/formContext'
import ContentForm from '@components/ContentBank/ContentForm'

export const defaultProps: TTextInput = {
  name: 'nameKey',
  label: 'Form label',
  type: 'text',
  showCounter: true,
}

const INPUT_TYPE: Record<TTextInput['type'], TextInputProps['type']> = {
  email: 'email',
  number: 'tel',
  text: 'text',
  uri: 'url',
}

const TextInput = ({
  name,
  label,
  helper,
  placeholder,
  showCounter = true,
  maxChar,
  minChar,
  isRequired,
  type,
}: TTextInput) => {
  const {
    connectors: { connect, drag },
  } = useNode()
  const { control } = useFormContext()

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    value: string,
    onChange: (...event: unknown[]) => void
  ) => {
    if (type === 'number' && isNaN(+value)) {
      const newValue = value.replace(/\D/g, '')
      e.currentTarget.value = newValue
      onChange(newValue)
      return
    }
    onChange(e)
  }

  return (
    <div
      ref={(ref) => connect(drag(ref!))}
      className={clsx(
        'mb-5 last:mb-0',
        'widget-component',
        'widget-component--text-input',
        'text-default'
      )}
    >
      <Controller
        control={control}
        name={name}
        render={({
          field: { onChange, onBlur, value, ref },
          fieldState: { error },
        }) => (
          <LibraryTextInput
            onChange={(e, value) => handleChange(e, value, onChange)}
            maxLength={maxChar || undefined}
            minLength={minChar || undefined}
            // onInput={handleInput}
            placeholder={placeholder}
            type={INPUT_TYPE[type]}
            renderLabel={
              <div
                className="form-label ql-editor"
                dangerouslySetInnerHTML={{ __html: label }}
              />
            }
            required={isRequired}
            onBlur={onBlur}
            inputRef={ref}
            value={value}
            messages={[
              {
                text: error ? capitalize(error?.message) : helper,
                type: error ? 'error' : 'hint',
              },
            ]}
            renderAfterInput={() =>
              showCounter && maxChar ? (
                <LibraryText color="secondary" size="small">
                  {value?.length || 0}/{maxChar}
                </LibraryText>
              ) : null
            }
          />
        )}
      />
    </div>
  )
}

const TextInputSettings = () => {
  const {
    actions: { setProp, setCustom },
    props,
    custom,
  } = useNode((node) => ({
    props: node.data.props,
    custom: node.data.custom,
  }))
  const errors = mappedWidgetSettingsError(custom.errors)
  return (
    <Form
      onValuesChange={(changedValue) =>
        widgetSettingsOnValueChange(changedValue, custom, setCustom)
      }
      fields={getWidgetSettingsFields(props)}
      layout="vertical"
    >
      <Form.Item
        label="Name Key"
        name="name"
        validateStatus={errors['name'] && 'error'}
        help={errors['name']}
      >
        <Input
          autoComplete="off"
          value={props.name}
          onChange={(e) =>
            setProp(
              (props: TTextInput) =>
                (props.name = e.target.value.replaceAll(' ', ''))
            )
          }
        />
      </Form.Item>
      <Form.Item
        label="Label"
        name="label"
        validateStatus={errors['label'] && 'error'}
        help={errors['label']}
      >
        <ContentForm
          index="widgetText"
          showDeleteButton={false}
          showIndentation={false}
          showUploader={false}
          showTitle={false}
          value={props.text}
          onChange={(text) =>
            setProp((props: TTextInput) => (props.label = text))
          }
        />
      </Form.Item>
      <Form.Item
        label="Type"
        name="type"
        validateStatus={errors['type'] && 'error'}
        help={errors['type']}
      >
        <SelectWidgetSettings
          value={props.type}
          onChange={(type) =>
            setProp((props: TTextInput) => (props.type = type))
          }
          options={[
            { label: 'Text', value: 'text' },
            { label: 'Number', value: 'number' },
            { label: 'Email', value: 'email' },
            { label: 'URL', value: 'uri' },
          ]}
        />
      </Form.Item>
      <Form.Item
        label="Required"
        name="isRequired"
        validateStatus={errors['isRequired'] && 'error'}
        help={errors['isRequired']}
      >
        <Switch
          checked={props.isRequired}
          onChange={(e) =>
            setProp((props: TTextInput) => (props.isRequired = e))
          }
        />
      </Form.Item>
      <Form.Item
        label="Show Counter"
        name="showCounter"
        validateStatus={errors['showCounter'] && 'error'}
        help={
          errors['showCounter'] || 'Show max character counter if available'
        }
      >
        <Switch
          checked={props.showCounter}
          onChange={(e) =>
            setProp((props: TTextInput) => (props.showCounter = e))
          }
        />
      </Form.Item>
      <Form.Item
        label="Minimum Character"
        name="minChar"
        validateStatus={errors['minChar'] && 'error'}
        help={errors['minChar']}
      >
        <InputNumber
          autoComplete="off"
          value={props.minChar}
          onChange={(e) =>
            setProp(
              (props: TTextInput) =>
                (props.minChar = isNaN(e) ? undefined : Number(e))
            )
          }
        />
      </Form.Item>
      <Form.Item
        label="Maximum Character"
        name="maxChar"
        validateStatus={errors['maxChar'] && 'error'}
        help={errors['maxChar'] || '0 is equal as no limitation'}
      >
        <InputNumber
          autoComplete="off"
          value={props.maxChar}
          onChange={(e) =>
            setProp(
              (props: TTextInput) =>
                (props.maxChar = isNaN(e) ? undefined : Number(e))
            )
          }
        />
      </Form.Item>
      <Form.Item
        label="Placeholder"
        name="placeholder"
        validateStatus={errors['placeholder'] && 'error'}
        help={errors['placeholder']}
      >
        <Input
          autoComplete="off"
          value={props.placeholder}
          onChange={(e) =>
            setProp((props: TTextInput) => (props.placeholder = e.target.value))
          }
        />
      </Form.Item>
      <Form.Item
        label="Helper Text"
        name="helper"
        validateStatus={errors['helper'] && 'error'}
        help={errors['helper']}
      >
        <Input
          autoComplete="off"
          value={props.helper}
          onChange={(e) =>
            setProp((props: TTextInput) => (props.helper = e.target.value))
          }
        />
      </Form.Item>
    </Form>
  )
}

TextInput.craft = {
  rules: {
    canDrop: (targetNode: Node) => {
      return targetNode.data.name === WIDGET_NAME.Container
    },
  },
  props: defaultProps,
  custom: TextInputCustomProps,
  related: {
    settings: TextInputSettings,
  },
}

export { TextInput }
