import { Descriptions } from '@pankod/refine-antd'
import React from 'react'

import { TLMSClass } from '../types'

const TabProgramLocalDetail = ({ data }: { data?: TLMSClass }) => {
  return (
    <Descriptions
      bordered
      column={2}
      className="mt-4 mb-6"
      labelStyle={{ fontWeight: 600 }}
    >
      <Descriptions.Item span={2} label="ID">
        {data?.id}
      </Descriptions.Item>
      <Descriptions.Item span={2} label="Kelas Name">
        {data?.name}
      </Descriptions.Item>
    </Descriptions>
  )
}

export default TabProgramLocalDetail
