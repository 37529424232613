export enum EvaluatorRoleLabel {
  instruktur = 'Fasilitator',
  peserta = 'Peserta',
  asisten = 'Pengajar Praktik (Asisten)',
  admin_kelas = 'Admin Kelas',
  instruktur_webinar = 'Instruktur Webinar',
}

export type EvaluatorRole = keyof typeof EvaluatorRoleLabel

type WeightedEvaluatorRole = { role: EvaluatorRole; weight: number }

export type PenilaianWebinarResponse = {
  id: string
  name: string
  form_kuantitatif: {
    id: string
    title: string
  }
  form_kualitatif?: {
    id: string
    title: string
  }
  evaluatorRoles: WeightedEvaluatorRole[]
  weight: number
}

export type PenilaianWebinarRequest = {
  name: string
  formKuantitatifId: string
  formKualitatifId?: string | null
  evaluatorRoles: WeightedEvaluatorRole[]
  weight: number
}
