import {
  Form,
  Button,
  Icons,
  Input,
  Typography,
  TextField,
  Table,
  Upload,
  Popconfirm,
} from '@pankod/refine-antd'
import { useOne, useUpdate } from '@pankod/refine-core'
import { FileImage, FileType } from 'lucide-react'
import React, { useState } from 'react'

import ImageRenderPreview from '@resources/lms-management/certificate-template/fabric/components/ImageRenderPreview'
import { generateUploadPropsFn } from '@resources/lms-management/certificate-template/fabric/utils/image'
import {
  CertificateTemplateVariablesWithDefault,
  UserVariable,
} from '@resources/lms-management/certificate-template/types'
import { ButtonSVGPreview } from '@resources/lms-management/certificate-template/fabric/components/ModalPreviewSVG'

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  dataIndex: string
  inputType: 'IMAGE' | 'TEXT'
  record: UserVariable & { defaultValue: string }
  index: number
  children: React.ReactNode
}

type TemplatePanel = {
  templateId: string
  programLocalId?: string | number | string[]
  templateUrl: {
    back: string
    front: string
  }
}
const TemplatePanel = ({
  templateId,
  programLocalId,
  templateUrl,
}: TemplatePanel) => {
  const [isEditing, setEditing] = useState(false)
  const [values, setValues] = useState<string[]>([])
  const { data, isFetching } = useOne<{
    data: CertificateTemplateVariablesWithDefault
  }>({
    dataProviderName: 'lms',
    id: 'variables',
    resource: `local-programs/${programLocalId}/certificate-templates/${templateId}`,
    queryOptions: {
      enabled: !!templateId && !!programLocalId,
    },
  })
  const { mutateAsync: doSave } = useUpdate()
  const [form] = Form.useForm()

  const handleValueChange = (value: string, index: number) => {
    const values = [...form.getFieldValue('values')]
    values[index] = value
    form.setFieldsValue({ values })
    form.setFields([{ name: ['values'], touched: true }])
    setValues((state) => {
      const newState = [...state]
      newState[index] = value
      return newState
    })
  }

  const EditableCell: React.FC<EditableCellProps> = ({
    dataIndex,
    title: _,
    inputType,
    record: __,
    index,
    children,
    ...restProps
  }) => {
    const uploadProps = generateUploadPropsFn({
      onLoaded: (result) => {
        handleValueChange(result as string, index)
      },
      onError: (_, message) => {
        form.setFields([
          {
            name: ['values', index],
            errors: [message],
          },
        ])
      },
      onRemove: () => {
        handleValueChange('', index)
      },
    })
    const inputNode =
      inputType === 'IMAGE' ? (
        <div className="grid gap-2 grid-cols-1">
          <Upload
            {...uploadProps}
            accept="image/png,image/jpeg,image/jpg,image/svg+xml"
            fileList={[values[index]]
              .filter((val) => !!val)
              .map((val) => ({
                name: 'image',
                uid: '-1',
                url: val,
              }))}
            listType="picture"
            className="grid grid-cols-[100px_1fr] gap-3"
            itemRender={(_, __, fileList, { remove }) => {
              return fileList?.[0]?.url !== '' ? (
                <div className="flex justify-between w-full gap-3">
                  <div className="h-[24px] w-full">
                    <ImageRenderPreview value={fileList[0].url} />
                  </div>
                  <Button
                    icon={<Icons.CloseCircleFilled />}
                    danger
                    onClick={remove}
                    size="small"
                    type="text"
                  />
                </div>
              ) : null
            }}
          >
            <Button
              type="dashed"
              className="w-[100px]"
              size="small"
              icon={<Icons.UploadOutlined />}
            >
              Unggah
            </Button>
          </Upload>
        </div>
      ) : (
        <Input autoComplete="off" size="small" />
      )

    return (
      <td key={index} {...restProps}>
        {isEditing && dataIndex === 'value' ? (
          <Form.Item
            className="!m-0"
            name={['values', index]}
            getValueFromEvent={(e) => {
              // Handle remove image insert object from Upload component
              return e?.target?.value ? e.target.value : ''
            }}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    )
  }

  const handleSave = async (values: string[]) => {
    const payload = values.map((value, index) => {
      const datum = data?.data?.data?.userVariable[index]!
      return {
        key: datum.key,
        type: datum.type,
        value,
      }
    })
    try {
      await doSave({
        values: { userVariable: payload },
        dataProviderName: 'lms',
        id: 'variables',
        resource: `local-programs/${programLocalId}/certificate-templates/${templateId}`,
        successNotification: {
          message: 'Perubahan value template berhasil disimpan',
          type: 'success',
          description: 'Sukses',
        },
      })
      setEditing(false)
    } catch (e) {}
  }

  return (
    <div>
      <Form form={form} component={false}>
        <Table
          loading={isFetching}
          dataSource={data?.data?.data?.userVariable}
          rowKey={'key'}
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          pagination={false}
          scroll={{ x: 600 }}
          title={() => (
            <div className="flex justify-between items-center">
              <Typography.Title level={5} className="!m-0">
                Daftar Variable
              </Typography.Title>
              {isEditing ? (
                <div className="flex gap-2">
                  <Popconfirm
                    title="Apakah Anda yakin ingin kembali tanpa menyimpan perubahan?"
                    cancelText="Batal"
                    okText="Lanjutkan"
                    placement="left"
                    onConfirm={() => {
                      setEditing(false)
                    }}
                  >
                    <Button icon={<Icons.ArrowLeftOutlined />}>Kembali</Button>
                  </Popconfirm>
                  <Button
                    icon={<Icons.SaveOutlined />}
                    onClick={() => {
                      handleSave(form.getFieldValue('values'))
                    }}
                    type="primary"
                  >
                    Simpan
                  </Button>
                </div>
              ) : (
                <div className="flex gap-2">
                  <Button
                    icon={<Icons.EditOutlined />}
                    onClick={() => {
                      const initialValues =
                        data?.data?.data?.userVariable?.map(
                          ({ value }) => value
                        ) || []
                      setEditing(true)
                      form.setFieldsValue({
                        values: initialValues,
                      })
                      setValues(initialValues)
                    }}
                  >
                    Ubah Value
                  </Button>
                  <ButtonSVGPreview
                    buttonProps={{ type: 'primary' }}
                    className="!w-fit !relative !top-0 !right-0 !m-0 z-10 !rounded-none !p-0 !shadow-none"
                    onClick={() =>
                      new Promise((resolve) => resolve({ back: '', front: '' }))
                    }
                    shouldLoadInitialTemplate={{
                      back: true,
                      front: true,
                    }}
                    initialTemplateUrl={{
                      back: templateUrl.back,
                      front: templateUrl.front,
                    }}
                    variables={{
                      systemVariable: data?.data?.data?.systemVariable || [],
                      userVariable: data?.data?.data?.userVariable || [],
                    }}
                  />
                </div>
              )}
            </div>
          )}
          columns={[
            {
              title: 'Key',
              dataIndex: 'key',
              width: 250,
              render: (value, { type }) => (
                <div className="grid grid-cols-[16px_1fr] gap-1 items-center">
                  {type === 'IMAGE' ? (
                    <FileImage className="h-4 w-4" />
                  ) : (
                    <FileType className="h-4 w-4" />
                  )}
                  <div className="overflow-hidden whitespace-nowrap">
                    <TextField title={value} ellipsis value={value} code />
                  </div>
                </div>
              ),
            },
            {
              title: 'Default Value',
              dataIndex: 'defaultValue',
              width: 200,
              ellipsis: true,
              render: (value, { type }) => {
                if (type === 'IMAGE') {
                  return <ImageRenderPreview value={value} />
                }
                return value
              },
            },
            {
              title: 'Value',
              dataIndex: 'value',
              width: 200,
              render: (value, { type }) => {
                if (!value) {
                  return (
                    <TextField
                      value={'Tidak ada value'}
                      type="secondary"
                      italic
                    />
                  )
                }
                if (type === 'IMAGE')
                  return <ImageRenderPreview value={value} />
                return <TextField value={value} />
              },
              onCell: (record, index) => ({
                record,
                dataIndex: 'value',
                title: 'Value',
                inputType: record.type,
                index,
              }),
            },
          ]}
        />
      </Form>
    </div>
  )
}

export default TemplatePanel
