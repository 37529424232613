import {
  useTable,
  Table,
  TextField,
  Button,
  Descriptions,
  Icons,
  useModalForm,
  EditButton,
  Space,
  Tag,
} from '@pankod/refine-antd'
import React from 'react'
import {
  useCan,
  useDelete,
  useGetIdentity,
  useInvalidate,
} from '@pankod/refine-core'

import { PopDeleteConfirm } from '@resources/lms-management/components/PopDeleteConfirm'
import { TCommonError, TLMSCommonError } from 'src/interfaces/common'
import { PROGRAM_STATE } from '../../program/types'
import { ModalForm } from './ModalForm'
import {
  PROGRAM_LOCAL_STATE,
  TLMSProgramLocal,
  TLMSProgramLocalRequest,
} from '../types'
import ShowProgramLocalButton from './ShowProgramLocalButton'
import { showErrorNotification } from '@resources/angkatan-ppg-management/utils'
import { useProgramStore } from '@resources/lms-management/program/store'

type ProgramLocalTableListProps = {
  programId?: number
  programName?: string
  programResourceName: string
  programStatus?: PROGRAM_STATE
  hideTitle?: boolean
}
export const ProgramLocalTableList = ({
  programId,
  programName,
  programResourceName,
  programStatus = PROGRAM_STATE.ENDED,
  hideTitle = false,
}: ProgramLocalTableListProps) => {
  const invalidate = useInvalidate()
  const { data, isSuccess } = useGetIdentity()
  const LMSRole = data?.user?.LMSRole || ''
  const PROGRAM_LOCAL_RESOURCE = `${programResourceName}/${programId}/local-programs`
  const PROGRAM_LOCAL_ENTITIES_RESOURCE = `entities/programs/${programId}/local-programs`
  const { tableProps } = useTable<TLMSProgramLocal, TCommonError>({
    resource:
      LMSRole === 'admin-entitas'
        ? PROGRAM_LOCAL_ENTITIES_RESOURCE
        : PROGRAM_LOCAL_RESOURCE,
    dataProviderName: 'lms',
    queryOptions: {
      enabled: !!programId && isSuccess,
    },
  })

  const {
    modalProps: createModalProps,
    formProps: createFormProps,
    show: createShow,
    close: createClose,
  } = useModalForm<TLMSProgramLocal, TLMSCommonError, TLMSProgramLocalRequest>({
    action: 'create',
    resource: PROGRAM_LOCAL_RESOURCE,
    dataProviderName: 'lms',
    autoResetForm: true,
    redirect: false,
    onMutationSuccess() {
      if (LMSRole === 'admin-entitas') {
        invalidate({
          resource: PROGRAM_LOCAL_ENTITIES_RESOURCE,
          dataProviderName: 'lms',
          invalidates: ['list'],
        })
      }
    },
    successNotification: {
      message: 'Program lokal berhasil dibuat',
      type: 'success',
      description: 'Sukses',
    },
    errorNotification: (error) =>
      showErrorNotification(
        error,
        'Terdapat gangguan saat membuat program lokal'
      ),
  })

  const {
    modalProps: editModalProps,
    formProps: editFormProps,
    show: editShow,
    close: editClose,
  } = useModalForm<TLMSProgramLocal, TLMSCommonError, TLMSProgramLocalRequest>({
    action: 'edit',
    resource: PROGRAM_LOCAL_RESOURCE,
    dataProviderName: 'lms',
    autoResetForm: true,
    redirect: false,
    onMutationSuccess() {
      if (LMSRole === 'admin-entitas') {
        invalidate({
          resource: PROGRAM_LOCAL_ENTITIES_RESOURCE,
          dataProviderName: 'lms',
          invalidates: ['list'],
        })
      }
    },
    successNotification: {
      message: 'Program lokal berhasil diubah',
      type: 'success',
      description: 'Sukses',
    },
    errorNotification: (error) =>
      showErrorNotification(
        error,
        'Terdapat gangguan saat mengubah program lokal'
      ),
  })

  const { mutateAsync } = useDelete()

  const { data: canDelete } = useCan({
    action: 'delete',
    resource: 'lms-program-local-management',
  })

  const { data: canEdit } = useCan({
    action: 'edit',
    resource: 'lms-program-local-management',
  })

  const { data: canCreate } = useCan({
    action: 'create',
    resource: 'lms-program-local-management',
  })

  const isOwner = useProgramStore(({ isOwner }) => isOwner)

  const disableDeleteAction =
    programStatus !== PROGRAM_STATE.PREPARATION || (!canDelete?.can && !isOwner)

  const disableEditAction =
    programStatus !== PROGRAM_STATE.PREPARATION || (!canEdit?.can && !isOwner)

  const disableCreateAction =
    programStatus !== PROGRAM_STATE.PREPARATION || (!canCreate?.can && !isOwner)

  return (
    <>
      <Descriptions
        title={hideTitle ? null : 'Program Lokal'}
        extra={
          <Button
            disabled={disableCreateAction}
            onClick={() => createShow()}
            icon={<Icons.PlusOutlined />}
          >
            Buat Program Lokal
          </Button>
        }
      >
        <Descriptions.Item span={1}>
          <Table {...tableProps} rowKey="id" className="w-full">
            <Table.Column
              dataIndex="id"
              title="ID"
              render={(value) => <TextField value={value} />}
            />
            <Table.Column
              dataIndex="slug"
              title="Slug"
              render={(value) => <TextField value={value} />}
            />
            <Table.Column
              dataIndex="name"
              title="Nama Program Lokal"
              render={(value) => <TextField value={value} />}
            />
            <Table.Column
              dataIndex={['entity', 'name']}
              title="Entitas"
              render={(value) => <TextField value={value} />}
            />
            <Table.Column
              dataIndex="status"
              title="Status"
              render={(value) => (
                <Tag
                  color={
                    value === PROGRAM_LOCAL_STATE.PUBLISHED
                      ? 'success'
                      : 'default'
                  }
                  className="uppercase"
                >
                  {value}
                </Tag>
              )}
            />
            <Table.Column<TLMSProgramLocal>
              render={(_, record) => (
                <Space direction="vertical">
                  <ShowProgramLocalButton
                    block
                    size="small"
                    recordItemId={record.id}
                    programId={programId}
                  >
                    Lihat
                  </ShowProgramLocalButton>
                  <EditButton
                    size="small"
                    block
                    recordItemId={record.id}
                    ignoreAccessControlProvider
                    disabled={disableEditAction}
                    onClick={() => {
                      editShow(record.id)
                    }}
                  >
                    Ubah
                  </EditButton>
                  <PopDeleteConfirm
                    title={`Apakah Anda yakin ingin menghapus Program Lokal "${record.name}"?`}
                    placeholder="Tulis ulang nama Program Lokal"
                    onConfirm={() =>
                      mutateAsync(
                        {
                          id: record.id,
                          resource: PROGRAM_LOCAL_RESOURCE,
                          dataProviderName: 'lms',
                          successNotification: {
                            message: 'Program lokal berhasil dihapus',
                            type: 'success',
                            description: 'Sukses',
                          },
                          errorNotification: (error) =>
                            showErrorNotification(
                              error,
                              'Terdapat gangguan saat menghapus program lokal'
                            ),
                        },
                        {
                          onSuccess() {
                            if (LMSRole === 'admin-entitas') {
                              invalidate({
                                resource: PROGRAM_LOCAL_ENTITIES_RESOURCE,
                                dataProviderName: 'lms',
                                invalidates: ['list'],
                              })
                            }
                          },
                        }
                      )
                    }
                    okButtonProps={{
                      danger: true,
                    }}
                    okText="Hapus"
                    cancelText="Batal"
                    validateValue={record.name}
                    errorMessage="Nama Program Lokal tidak sama"
                    disabled={disableDeleteAction}
                  >
                    <Button
                      size="small"
                      danger
                      block
                      disabled={disableDeleteAction}
                      icon={<Icons.DeleteOutlined />}
                    >
                      Hapus
                    </Button>
                  </PopDeleteConfirm>
                </Space>
              )}
            />
          </Table>
        </Descriptions.Item>
      </Descriptions>

      <ModalForm
        formProps={createFormProps}
        modalProps={createModalProps}
        mode="create"
        programName={programName}
        onClose={() => {
          createFormProps.form?.resetFields()
          createClose()
        }}
      />

      <ModalForm
        formProps={editFormProps}
        modalProps={editModalProps}
        mode="edit"
        programName={programName}
        onClose={() => {
          editFormProps.form?.resetFields()
          editClose()
        }}
      />
    </>
  )
}
