import { Select, SelectProps } from '@pankod/refine-antd'
import React from 'react'

import { OutsideClickContext } from 'src/hooks/useOnClickOutside'

type TSelectWS = SelectProps
const SelectWS = (props: TSelectWS) => {
  const { setEnabled: setEnableClickOutsideArena } =
    React.useContext(OutsideClickContext)
  return (
    <Select
      {...props}
      onDropdownVisibleChange={(open) => {
        setEnableClickOutsideArena({ enabled: !open })
      }}
    />
  )
}

export default SelectWS
