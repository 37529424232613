import {
  Alert,
  Button,
  Form,
  Icons,
  Input,
  Modal,
  Select,
  Upload,
  UploadProps,
} from '@pankod/refine-antd'
import React, { useState } from 'react'
import { ImageUp } from 'lucide-react'

import { UserVariable } from '@resources/lms-management/certificate-template/types'
import { generateUploadPropsFn } from '../../utils/image'

type CreateCustomVariableModal = {
  onSubmit: (data: UserVariable) => Promise<any>
  open?: boolean
  onClose: () => void
}

const CreateCustomVariableModal = (props: CreateCustomVariableModal) => {
  const [form] = Form.useForm()
  const handleClose = () => {
    form.resetFields()
    props.onClose()
  }
  const type = Form.useWatch('type', form)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const uploadProps: UploadProps = generateUploadPropsFn({
    onLoaded: (result) => {
      form.setFieldsValue({ value: result })
    },
    onError(_, message) {
      form.setFields([{ name: 'value', errors: [message] }])
    },
  })
  return (
    <Modal
      visible={props.open}
      onCancel={handleClose}
      maskClosable={false}
      onOk={async () => {
        try {
          setConfirmLoading(true)
          await form.validateFields()
          await props.onSubmit(form.getFieldsValue())
          handleClose()
        } catch (_) {
        } finally {
          setConfirmLoading(false)
        }
      }}
      confirmLoading={confirmLoading}
      title="Tambah Komponen Custom Baru"
      okText="Tambah"
      cancelText="Batal"
      width="600px"
    >
      <Form
        form={form}
        validateMessages={{ required: "'${label}' harus diisi" }}
        layout="vertical"
      >
        <Alert
          type="info"
          showIcon
          icon={<Icons.InfoCircleFilled className="self-start mt-1" />}
          className="!mb-2"
          message={
            <>
              <p className="m-0">Nama variable hanya boleh berisi kombinasi:</p>
              <ul className="m-0">
                <li>angka</li>
                <li>huruf</li>
                <li>
                  tanda pisah ( <code>-</code> )
                </li>
                <li>
                  garis bawah ( <code>_</code> )
                </li>
                <li>tidak boleh ada spasi</li>
              </ul>
            </>
          }
        />
        <Form.Item
          name="key"
          label="Nama Variable"
          required
          rules={[
            { required: true },
            {
              validator: (_, value) => {
                const regex = /^[a-zA-Z0-9\-_]+$/
                if (!regex.test(value)) {
                  return Promise.reject(
                    "'Nama Variable' belum memenuhi ketentuan"
                  )
                }
                return Promise.resolve()
              },
            },
          ]}
        >
          <Input autoComplete="off" />
        </Form.Item>
        <Form.Item
          initialValue={'TEXT'}
          name="type"
          label="Tipe"
          rules={[{ required: true }]}
        >
          <Select
            options={[
              { label: 'Teks', value: 'TEXT' },
              { label: 'Gambar', value: 'IMAGE' },
            ]}
          />
        </Form.Item>
        <Form.Item
          name="value"
          label="Default Value"
          required
          rules={[{ required: true }]}
          dependencies={['type']}
          valuePropName={type === 'TEXT' ? 'value' : ''}
        >
          {type === 'TEXT' ? (
            <Input autoComplete="off" />
          ) : (
            <Upload
              {...uploadProps}
              accept="image/png,image/jpeg,image/jpg,image/svg+xml"
            >
              <Button
                className="!flex gap-2 items-center justify-center"
                icon={<ImageUp className="h-5 w-5" />}
              >
                Unggah gambar
              </Button>
            </Upload>
          )}
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default CreateCustomVariableModal
