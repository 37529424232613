import {
  AntdList,
  Button,
  Drawer,
  Icons,
  Input,
  Popconfirm,
  Space,
  Tag,
  useSimpleList,
} from '@pankod/refine-antd'
import React, { useCallback, useState } from 'react'
import Highlighter from 'react-highlight-words'
import { useCreate } from '@pankod/refine-core'

import { CanvasActivity } from './types'
import { TCommonError } from 'src/interfaces/common'
import { showErrorNotification } from '@resources/angkatan-ppg-management/utils'

export const useActivityListDrawer = () => {
  const [showId, setShowId] = useState<{
    show: boolean
    programId: string | number
    programName: string
  }>({ show: false, programId: '', programName: '' })
  const onClose = useCallback(() => {
    setShowId((state) => ({
      ...state,
      show: false,
    }))
  }, [])

  return {
    show: (id: number | string, name: string) => {
      setShowId({ show: true, programId: id, programName: name })
    },
    visible: !!showId.show,
    id: showId.programId,
    name: showId.programName,
    onClose,
  }
}

type ActivityListDrawerProps = {
  visible: boolean
  id: number | string
  onClose: () => void
  name: string
}

const ActivityListDrawer = ({
  id,
  name,
  onClose,
  visible,
}: ActivityListDrawerProps) => {
  const { listProps } = useSimpleList<CanvasActivity>({
    dataProviderName: 'lms',
    resource: `programs/${id}/canvas-content`,
    queryOptions: {
      enabled: visible,
    },
    hasPagination: false,
  })
  const { mutateAsync } = useCreate<{}, TCommonError, void>()
  const [searchActivity, setSearchActivity] = useState('')
  return (
    <Drawer
      title={`Daftar Aktivitas Canvas | ${name}`}
      visible={visible}
      onClose={onClose}
      destroyOnClose
      afterVisibleChange={(visible) => {
        if (!visible) {
          setSearchActivity('')
        }
      }}
      width="70%"
      extra={
        <Popconfirm
          title="Apabila Anda melakukan refresh daftar aktivitas, maka record timespend untuk program ini akan tidak terbaca."
          okText="Lanjutkan"
          cancelText="Batal"
          placement="bottomRight"
          onConfirm={async () => {
            return mutateAsync({
              dataProviderName: 'lms',
              resource: `programs/${id}/canvas-content`,
              values: undefined,
              successNotification: {
                description: 'Sukses',
                message: 'Daftar aktivitas berhasil direfresh',
                type: 'success',
              },
              errorNotification: (error) =>
                showErrorNotification(
                  error,
                  'Terdapat gangguan saat melakukan refresh daftar aktivitas'
                ),
            })
          }}
        >
          <Button icon={<Icons.SyncOutlined />}>
            Refresh Daftar Aktivitas
          </Button>
        </Popconfirm>
      }
    >
      <AntdList
        {...listProps}
        dataSource={listProps.dataSource?.filter(({ title }) =>
          title.toLowerCase().includes(searchActivity.toLowerCase())
        )}
        pagination={{ pageSize: 10 }}
        size="small"
        header={
          <Input.Search
            placeholder="Cari nama aktivitas"
            value={searchActivity}
            onChange={(e) => {
              setSearchActivity(e.target.value)
            }}
            autoFocus
            allowClear
          />
        }
        renderItem={(item) => (
          <AntdList.Item>
            <AntdList.Item.Meta
              title={
                <Highlighter
                  highlightClassName="text-orange-40"
                  highlightStyle={{ padding: 0 }}
                  autoEscape
                  textToHighlight={item.title}
                  searchWords={[searchActivity]}
                />
              }
              description={<Tag color="processing">{item.contentType}</Tag>}
            />

            <Space size="small">
              <Tag>ID: {item.canvasContentId}</Tag>
            </Space>
          </AntdList.Item>
        )}
      />
    </Drawer>
  )
}

export default ActivityListDrawer
