import {
  Alert,
  Button,
  Checkbox,
  Collapse,
  Divider,
  Form,
  FormProps,
  Icons,
  Input,
  InputNumber,
  Modal,
  ModalProps,
  Select,
  useSelect,
} from '@pankod/refine-antd'
import React, { useState } from 'react'
import { uniqWith } from 'lodash'

import { CanvasActivity } from '../blueprint/types'
import { CanvasContentResponse } from './types'
import ActivityMaxDurationTable from './ActivityMaxDurationTable'
import SelectOptionLabel from './SelectOptionLabel'
import SelectOptionItem from './SelectOptionItem'
import { PROGRAM_STATE } from '@resources/lms-management/program/types'

const DEFAULT_MAX_DURATION = 480
const DEFAULT_SORT_PRIORITY = 1

const CONTENT_TYPE_OPTIONS = [
  'Assignment',
  'DiscussionTopic',
  'Quizzes::Quiz',
  'WikiPage',
]

type ModalFormProps = {
  modalProps: ModalProps
  formProps: FormProps
  mode: 'create' | 'edit'
  onClose: () => void
  programId: string | string[]
  programState?: PROGRAM_STATE
}
const ModalForm = ({
  formProps,
  modalProps,
  mode,
  onClose,
  programId,
  programState,
}: ModalFormProps) => {
  const isEditMode = mode === 'edit'
  const { selectProps: selectActivityProps, queryResult } =
    useSelect<CanvasActivity>({
      dataProviderName: 'lms',
      resource: `programs/${programId}/canvas-content`,
      optionValue: 'canvasContentId',
      optionLabel: 'title',
      queryOptions: {
        enabled: modalProps.visible,
        select({ data }) {
          return {
            data,
            total: data.length,
          }
        },
      },
      defaultValueQueryOptions: {
        enabled: false,
      },
    })
  const { selectProps: selectProgramModulesProps } = useSelect({
    dataProviderName: 'lms',
    resource: `programs/${programId}/program-modules`,
    optionValue: 'id',
    optionLabel: 'name',
    queryOptions: {
      enabled: modalProps.visible,
      select({ data }) {
        return {
          data,
          total: data.length,
        }
      },
    },
    filters: [
      {
        field: 'pageSize',
        operator: 'eq',
        value: '-1',
      },
    ],
    defaultValueQueryOptions: {
      enabled: false,
    },
  })
  const [filterAktivitas, setFilterAktivitas] = useState('')
  const [filterAktivitasType, setFilterAktivitasType] =
    useState<string[]>(CONTENT_TYPE_OPTIONS)
  const kategori = Form.useWatch('type', formProps?.form)

  const handleClickSelectAllFiltered = (form?: FormProps['form']) => {
    form?.setFieldsValue({
      canvasContents: uniqWith(
        [
          ...(formProps?.form?.getFieldValue('canvasContents') || []),
          ...(queryResult?.data?.data
            ?.filter(
              ({ title, activityGroupId, contentType, canvasContentId }) => {
                return (
                  (title
                    .toLowerCase()
                    .includes(filterAktivitas.toLowerCase()) ||
                    canvasContentId
                      .toLowerCase()
                      .includes(filterAktivitas.toLowerCase())) &&
                  filterAktivitasType.includes(contentType) &&
                  (activityGroupId === '0' ||
                    (isEditMode
                      ? activityGroupId === formProps?.initialValues?.data?.id
                      : false))
                )
              }
            )
            .map(({ canvasContentId, contentType, title }) => ({
              id: canvasContentId,
              type: contentType,
              title,
              maxDuration: DEFAULT_MAX_DURATION,
              sortPriority: DEFAULT_SORT_PRIORITY,
            })) || []),
        ],
        (a, b) => a.id === b.id && a.type === b.type
      ),
    })
  }

  const getFilteredCanvasContentOptions = (data?: CanvasActivity[]) =>
    data
      ?.filter(({ title, contentType, canvasContentId }) => {
        return (
          (title.toLowerCase().includes(filterAktivitas.toLowerCase()) ||
            canvasContentId
              .toLowerCase()
              .includes(filterAktivitas.toLowerCase())) &&
          filterAktivitasType.includes(contentType)
        )
      })
      ?.map(({ canvasContentId, title, activityGroupId, contentType }) => {
        return {
          value: `${canvasContentId}__${contentType}`,
          label: title,
          disabled:
            activityGroupId !== '0' &&
            (isEditMode
              ? activityGroupId !== formProps?.initialValues?.data?.id
              : true),
          key: `${canvasContentId}__${contentType}`,
          type: contentType,
          id: canvasContentId,
        }
      }) || []

  return (
    <Modal
      {...modalProps}
      onCancel={onClose}
      destroyOnClose
      title={isEditMode ? 'Edit Kelompok Aktivitas' : 'Buat Kelompok Aktivitas'}
      cancelText="Batal"
      okText="Simpan"
      afterClose={() => {
        formProps.form?.resetFields()
      }}
      okButtonProps={{
        ...modalProps.okButtonProps,
        icon: <Icons.SaveOutlined />,
      }}
      bodyStyle={{ maxHeight: 700, overflow: 'auto' }}
    >
      {isEditMode && programState === PROGRAM_STATE.RUNNING && (
        <Alert
          className="!mb-5"
          type="warning"
          message="Perhatian"
          showIcon
          description={
            <>
              Perubahan konfigurasi pada <strong>program berjalan</strong> tidak
              akan menyertakan data waktu yang sudah terlewat!
              <br />
              Pastikan Anda yakin dengan perubahan ini.
            </>
          }
        />
      )}
      <Form
        {...formProps}
        initialValues={{
          ...formProps?.initialValues?.data,
          duration: Math.round(
            (formProps?.initialValues?.data?.duration || 0) / 60
          ),
        }}
        layout="vertical"
        validateMessages={{ required: '${label} harus diisi.' }}
        onFinish={({ duration, canvasContents, ...data }) => {
          formProps.onFinish?.({
            ...data,
            duration: duration * 60,
            canvasContents:
              canvasContents?.map(
                ({ title: _, ...rest }: CanvasContentResponse) => rest
              ) || [],
          })
        }}
      >
        <Form.Item
          label="Nama Kelompok Aktivitas"
          name="name"
          required
          rules={[{ required: true }]}
        >
          <Input autoComplete="off" />
        </Form.Item>
        <Form.Item
          label="Program Module"
          name="programModuleId"
          required
          rules={[{ required: true }]}
        >
          <Select
            {...selectProgramModulesProps}
            onSearch={() => {}}
            filterOption={(input, option) =>
              (option!.label as unknown as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          />
        </Form.Item>
        <div className="grid grid-cols-2">
          <Form.Item
            label="Target Durasi"
            name="duration"
            required
            rules={[
              { required: true },
              {
                validator: (_, value) => {
                  if (value <= 0)
                    return Promise.reject('Target Durasi harus lebih dari 0.')
                  return Promise.resolve()
                },
              },
            ]}
          >
            <InputNumber
              autoComplete="false"
              addonBefore={<Icons.ClockCircleOutlined />}
              addonAfter={'menit'}
              min={0}
              controls={false}
              precision={0}
            />
          </Form.Item>
          <Form.Item
            label="Prioritas Urutan"
            name="sortPriority"
            required
            initialValue={1}
            rules={[
              { required: true },
              {
                validator: (_, value) => {
                  if (!!value && value <= 0)
                    return Promise.reject(
                      'Prioritas Urutan harus lebih dari 0.'
                    )
                  return Promise.resolve()
                },
              },
            ]}
          >
            <InputNumber
              autoComplete="false"
              addonBefore={<Icons.SortAscendingOutlined />}
              min={1}
              controls={false}
              precision={0}
            />
          </Form.Item>
        </div>
        <Form.Item
          label="Kategori"
          name="type"
          required
          rules={[{ required: true }]}
        >
          <Select
            disabled={isEditMode}
            options={[
              { label: 'Asinkronus', value: 'async' },
              { label: 'Sinkronus', value: 'sync' },
            ]}
          />
        </Form.Item>
        {kategori === 'async' && (
          <>
            <Form.Item
              name="canvasContents"
              label="Daftar Aktivitas"
              required
              rules={[{ required: true }]}
              getValueProps={(params) => {
                return {
                  value:
                    params?.map(
                      ({ id, type }: CanvasContentResponse) => `${id}__${type}`
                    ) || [],
                }
              }}
              getValueFromEvent={(activities) => {
                const result = queryResult?.data?.data
                  ?.filter(({ canvasContentId, contentType }) => {
                    return activities.includes(
                      `${canvasContentId}__${contentType}`
                    )
                  })
                  ?.map(({ canvasContentId, contentType, title }) => {
                    const currentActivity = formProps.form
                      ?.getFieldValue('canvasContents')
                      ?.find(
                        ({ id, type }: CanvasContentResponse) =>
                          id === canvasContentId && type === contentType
                      )
                    return {
                      id: canvasContentId,
                      type: contentType,
                      title,
                      maxDuration:
                        currentActivity?.maxDuration ?? DEFAULT_MAX_DURATION,
                      sortPriority:
                        currentActivity?.sortPriority ?? DEFAULT_SORT_PRIORITY,
                    }
                  })
                return result
              }}
            >
              <Select
                {...selectActivityProps}
                options={undefined}
                mode="multiple"
                menuItemSelectedIcon={<Icons.CheckOutlined className="-ml-4" />}
                allowClear
                placeholder="Cari dengan nama atau ID"
                onSearch={(value) => {
                  setFilterAktivitas(value)
                }}
                onDropdownVisibleChange={(open) => {
                  if (!open) {
                    setFilterAktivitas('')
                    setFilterAktivitasType(CONTENT_TYPE_OPTIONS)
                  }
                }}
                optionLabelProp="label"
                dropdownRender={(menu) => {
                  return (
                    <div>
                      {menu}
                      <Divider className="!my-3" />
                      <div className="px-2 flex justify-between items-center">
                        <Button
                          className="mb-1 w-[450px] !flex items-center justify-center gap-2"
                          icon={<Icons.SelectOutlined />}
                          onClick={() =>
                            handleClickSelectAllFiltered(formProps?.form)
                          }
                        >
                          <div className="inline-block max-w-[400px] overflow-hidden text-ellipsis">
                            Pilih Semua
                            {filterAktivitas !== ''
                              ? ` Item dengan Kata Kunci "${filterAktivitas}"`
                              : ' Item'}
                          </div>
                        </Button>
                        <Divider type="vertical" />
                        <Checkbox.Group
                          value={filterAktivitasType}
                          onChange={(value) =>
                            setFilterAktivitasType(value as string[])
                          }
                          className="!mb-1"
                        >
                          <div className="grid gap-2 grid-cols-2">
                            {CONTENT_TYPE_OPTIONS.map((type) => (
                              <Checkbox
                                className="!m-0"
                                key={type}
                                value={type}
                              >
                                {type}
                              </Checkbox>
                            ))}
                          </div>
                        </Checkbox.Group>
                      </div>
                    </div>
                  )
                }}
              >
                {getFilteredCanvasContentOptions(queryResult?.data?.data).map(
                  ({ key, label, value, type, disabled, id }) => (
                    <Select.Option
                      key={key}
                      value={value}
                      label={
                        <SelectOptionLabel id={id} label={label} type={type} />
                      }
                      disabled={disabled}
                    >
                      <SelectOptionItem
                        filter={filterAktivitas}
                        id={id}
                        label={label}
                        type={type}
                        disabled={disabled}
                      />
                    </Select.Option>
                  )
                )}
              </Select>
            </Form.Item>
            <Collapse destroyInactivePanel>
              <Collapse.Panel
                header="Konfigurasi Durasi Maksimum dan Prioritas Urutan Aktivitas"
                key={'maxDurationConfig_' + formProps?.initialValues?.data?.id}
              >
                <Form.Item
                  name="canvasContents"
                  rules={[
                    {
                      validator: (
                        _,
                        value: {
                          id: string
                          maxDuration: number
                          sortPriority: number
                          title: string
                          type: string
                        }[]
                      ) => {
                        const hasEmptySortPriority = value.some(
                          ({ sortPriority }) => !sortPriority
                        )
                        if (hasEmptySortPriority)
                          return Promise.reject(
                            'Semua Prioritas Urutan harus diisi.'
                          )
                        return Promise.resolve()
                      },
                    },
                  ]}
                  noStyle
                >
                  <ActivityMaxDurationTable />
                </Form.Item>
              </Collapse.Panel>
            </Collapse>
          </>
        )}
      </Form>
    </Modal>
  )
}

export default ModalForm
