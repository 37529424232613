import {
  Button,
  Drawer,
  Form,
  Icons,
  Input,
  Tabs,
  useForm,
} from '@pankod/refine-antd'
import React from 'react'

import { Editor } from '@components/TUIEditor'
import { showErrorNotification } from '@resources/angkatan-ppg-management/utils'
export type DrawerFormPenilaianProps = {
  visible: boolean
  drawerProps?: any
  onClose: () => void
  programId: string | number
}

export const DrawerFormPenilaianObservasi = (
  props: DrawerFormPenilaianProps
) => {
  const { visible, onClose, programId } = props

  const { formProps } = useForm({
    action: 'edit',
    resource: `pgp-dasus/programs/${programId}/fi/penilaian_observasi`,
    dataProviderName: 'lms',
    redirect: false,
    errorNotification: (err) =>
      showErrorNotification(
        err,
        'Terjadi Kesalahan melakukan update gelombang'
      ),
    queryOptions: {
      enabled: visible,
    },
  })

  return (
    <Drawer
      visible={visible}
      closable={false}
      onClose={onClose}
      width="50%"
      className="relative"
      destroyOnClose
      {...props.drawerProps}
    >
      <div className="flex justify-end items-end">
        <Button
          size="small"
          icon={<Icons.CloseOutlined />}
          onClick={() => {
            window.confirm(
              'Are you sure you want to close this form? Your changes will be lost'
            ) && onClose()
          }}
        />
      </div>
      <Tabs defaultActiveKey="rubrik" destroyInactiveTabPane>
        <Tabs.TabPane tab="Rubrik" key="rubrik">
          <h3 className="mb-8">Form Penilaian: Rubrik</h3>

          <Form {...formProps} layout="vertical">
            <Form.Item
              name="title"
              label="Title"
              rules={[{ required: true, message: 'Title is required' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="rubrics"
              label="Rubrik"
              rules={[{ required: true, message: 'Rubrik is required' }]}
            >
              <Editor minHeight="500px" toolbarImageUploader={false} />
            </Form.Item>

            <Form.Item>
              <Button htmlType="submit" block>
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Tabs.TabPane>
      </Tabs>
    </Drawer>
  )
}
