import {
  AntdList,
  Button,
  Icons,
  Popconfirm,
  Space,
  Table,
  Tag,
  TextField,
  useModalForm,
  useTable,
} from '@pankod/refine-antd'
import React from 'react'
import { useDelete } from '@pankod/refine-core'

import { TCommonError, TLMSCommonError } from 'src/interfaces/common'
import {
  JurnalPendampinganIndividuRequest,
  JurnalPendampinganIndividuResponse,
} from './types'
import { TableTitle } from '@components/TableTitle'
import { ModalForm } from './ModalForm'
import { useGetProgramDetail } from '@resources/lms-management/program/utils'
import { showErrorNotification } from '@resources/angkatan-ppg-management/utils'

type JurnalPendampinganIndividuListProps = {
  programId: string | number
}
export const JurnalPendampinganIndividuList = ({
  programId,
}: JurnalPendampinganIndividuListProps) => {
  const { isFetching, isPPConfigFinalized: isFinalized } =
    useGetProgramDetail(programId)
  const { tableProps } = useTable<
    JurnalPendampinganIndividuResponse,
    TCommonError
  >({
    resource: `pp/${programId}/jurnal-pendampingan-individu`,
    dataProviderName: 'lms',
  })
  const {
    modalProps: createModalProps,
    formProps: createFormProps,
    show: createShow,
    close: createClose,
  } = useModalForm<
    JurnalPendampinganIndividuResponse,
    TLMSCommonError,
    JurnalPendampinganIndividuRequest
  >({
    action: 'create',
    resource: `pp/${programId}/jurnal-pendampingan-individu`,
    dataProviderName: 'lms',
    autoResetForm: true,
    redirect: false,
    successNotification: {
      message: 'Jurnal pendampingan successfully created',
      type: 'success',
      description: 'Successful',
    },
    errorNotification: (error) =>
      showErrorNotification(
        error,
        'There was a problem when creating jurnal pendampingan'
      ),
  })
  const {
    modalProps: editModalProps,
    formProps: editFormProps,
    show: editShow,
    close: editClose,
  } = useModalForm<
    JurnalPendampinganIndividuResponse,
    TLMSCommonError,
    JurnalPendampinganIndividuRequest
  >({
    action: 'edit',
    dataProviderName: 'lms',
    resource: `pp/${programId}/jurnal-pendampingan-individu`,
    autoResetForm: true,
    redirect: false,
    successNotification: {
      message: 'Jurnal pendampingan successfully edited',
      type: 'success',
      description: 'Successful',
    },
    errorNotification: (error) =>
      showErrorNotification(
        error,
        'There was a problem when editing jurnal pendampingan'
      ),
  })
  const { mutateAsync: doDelete } = useDelete()
  return (
    <>
      <Table
        {...tableProps}
        loading={tableProps.loading && isFetching}
        rowKey="id"
        scroll={{ x: 760 }}
        title={() => (
          <TableTitle
            title="Daftar Pendampingan Individu"
            extra={
              <Button
                icon={<Icons.PlusOutlined />}
                onClick={() => createShow()}
                disabled={isFinalized}
              >
                Tambah Jurnal
              </Button>
            }
          />
        )}
      >
        <Table.Column title="ID" dataIndex="id" fixed="left" width={80} />
        <Table.Column
          title="Nama Pendampingan Individu"
          dataIndex="title"
          width={220}
          fixed="left"
        />
        <Table.Column
          title="Jurnal Form"
          dataIndex="jurnalForm"
          render={(data) => (
            <AntdList
              dataSource={data}
              size="small"
              renderItem={(value: { title: string; id: string }) => (
                <AntdList.Item>
                  <TextField
                    value={value.title}
                    copyable={{ text: value.id, tooltips: 'Copy ID' }}
                    ellipsis={{ tooltip: value.title }}
                    style={{ width: 250 }}
                  />
                </AntdList.Item>
              )}
            />
          )}
          width={250}
        />
        <Table.Column
          title="Penilaian Form"
          dataIndex="penilaianForm"
          render={(data) => (
            <AntdList
              dataSource={data}
              size="small"
              renderItem={(value: { title: string; id: string }) => (
                <AntdList.Item>
                  <TextField
                    value={value.title}
                    copyable={{ text: value.id, tooltips: 'Copy ID' }}
                    ellipsis={{ tooltip: value.title }}
                    style={{ width: 250 }}
                  />
                </AntdList.Item>
              )}
            />
          )}
          width={250}
        />
        <Table.Column
          width={150}
          title="Form Upload Murid"
          dataIndex="studentUpload"
          render={({
            isActive,
          }: JurnalPendampinganIndividuResponse['studentUpload']) => (
            <Tag color={isActive ? 'success' : 'default'}>
              {isActive ? 'Aktif' : 'Nonaktif'}
            </Tag>
          )}
        />
        <Table.Column<JurnalPendampinganIndividuResponse>
          width={100}
          render={(_, record) => (
            <Space direction="vertical">
              <Button
                icon={<Icons.EditOutlined />}
                block
                size="small"
                onClick={() => editShow(record.id)}
                disabled={isFinalized}
              >
                Edit
              </Button>
              <Popconfirm
                title="Apakah Anda yakin ingin menghapus pendampingan individu ini?"
                okButtonProps={{ danger: true }}
                okText="Hapus"
                cancelText="Kembali"
                disabled={isFinalized}
                onConfirm={async () =>
                  doDelete({
                    id: record.id,
                    resource: `pp/${programId}/jurnal-pendampingan-individu`,
                    dataProviderName: 'lms',
                    invalidates: ['list'],
                    successNotification: {
                      message: 'Pendampingan individu successfuly removed',
                      type: 'success',
                      description: 'Successful',
                    },
                    errorNotification: (error: any) =>
                      showErrorNotification(
                        error,
                        'There was a problem when removing pendampingan individu'
                      ),
                  })
                }
              >
                <Button
                  danger
                  icon={<Icons.DeleteOutlined />}
                  block
                  disabled={isFinalized}
                  size="small"
                >
                  Hapus
                </Button>
              </Popconfirm>
            </Space>
          )}
        />
      </Table>

      <ModalForm
        formProps={createFormProps}
        modalProps={createModalProps}
        mode="create"
        onClose={createClose}
      />

      <ModalForm
        formProps={editFormProps}
        modalProps={editModalProps}
        mode="edit"
        onClose={editClose}
      />
    </>
  )
}
