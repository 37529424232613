import React, { useEffect } from 'react'
import { Form, Modal, Input, Select, useForm } from '@pankod/refine-antd'
import { useDelete, useGetIdentity } from '@pankod/refine-core'

import { TLMSCommonError } from 'src/interfaces/common'

import type { TLMSRoleOption, TLMSModalType, TLMSAdminRole } from '../types'

type TModalLMSAdminProps = {
  showModal: TLMSModalType
  setShowModal: (value: TLMSModalType) => void
  roleOptions: TLMSRoleOption[]
  onModalInit?: (form: any) => void
}

const ROLE_OPTIONS_RULES: Record<
  TLMSAdminRole,
  Record<'create' | 'update' | 'delete', TLMSAdminRole[]>
> = {
  'super-admin': {
    create: [
      'admin-pusat',
      'admin-entitas',
      'super-admin',
      'admin-lptk',
      'instruktur-webinar-pgp',
    ],
    update: [
      'admin-pusat',
      'admin-entitas',
      'super-admin',
      'admin-lptk',
      'instruktur-webinar-pgp',
    ],
    delete: [
      'admin-pusat',
      'admin-entitas',
      'super-admin',
      'admin-lptk',
      'instruktur-webinar-pgp',
    ],
  },
  'admin-pusat': {
    create: ['admin-entitas', 'admin-lptk', 'instruktur-webinar-pgp'],
    update: ['admin-entitas', 'admin-lptk', 'instruktur-webinar-pgp'],
    delete: ['admin-entitas', 'admin-lptk', 'instruktur-webinar-pgp'],
  },
  'admin-entitas': {
    create: ['instruktur-webinar-pgp'],
    update: ['instruktur-webinar-pgp'],
    delete: ['instruktur-webinar-pgp'],
  },
  'admin-lptk': {
    create: [],
    delete: [],
    update: [],
  },
  'instruktur-webinar-pgp': {
    create: [],
    update: [],
    delete: [],
  },
}

export const ModalLMSAdmin: React.FC<TModalLMSAdminProps> = (props) => {
  const { showModal = 'create', setShowModal, roleOptions, onModalInit } = props
  const [confirmationForm, setConfirmationForm] = React.useState<string>('')

  const { mutateAsync, isLoading } = useDelete()

  const { form, formProps, saveButtonProps } = useForm({
    action: 'create',
    resource: 'admins',
    dataProviderName: 'lms',
    redirect: false,
    onMutationSuccess: () => {
      form.resetFields()
      setShowModal(null)
    },
    successNotification: {
      message: `Success ${(showModal || 'create').toUpperCase()} User's Role!`,
      type: 'success',
    },
    errorNotification(error) {
      return {
        description: 'Error',
        type: 'error',
        message:
          (error as TLMSCommonError).response?.data.error.details?.[0]
            ?.message ||
          `There was a problem when ${showModal || 'create'} admin user`,
      }
    },
  })

  useEffect(() => {
    onModalInit?.(form)
  }, [form, onModalInit])

  const { data } = useGetIdentity<{ user: { LMSRole: TLMSAdminRole } }>()
  const LMSRole = data?.user?.LMSRole || ''

  const availableRoleOptions = React.useMemo(() => {
    if (!LMSRole) return []
    const availableRoles = ROLE_OPTIONS_RULES[LMSRole][showModal || 'update']
    return roleOptions.filter((role) => availableRoles.includes(role.value))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleOptions, showModal])

  const isModalTypeNeedConfirmation =
    showModal === 'update' || showModal === 'delete'

  const handleDelete = async () => {
    await mutateAsync(
      {
        id: '',
        resource: 'admins',
        dataProviderName: 'lms',
        values: {
          email: form.getFieldValue('email'),
          role: form.getFieldValue('role'),
        },
      },
      {
        onSuccess: () => {
          form.resetFields()
          setShowModal(null)
        },
      }
    )
  }

  return (
    <Modal
      visible={!!showModal}
      title={`${showModal?.toUpperCase()} User's Role`}
      okText={`${showModal?.toUpperCase()} Role`}
      onCancel={() => {
        form.resetFields()
        setConfirmationForm('')
        setShowModal(null)
      }}
      onOk={() => {
        if (isModalTypeNeedConfirmation) {
          if (showModal === 'delete') {
            handleDelete()
            return
          }

          if (confirmationForm === form.getFieldValue('email')) {
            saveButtonProps.onClick()
          }
          return
        }
        saveButtonProps.onClick()
      }}
      okButtonProps={{
        danger: isModalTypeNeedConfirmation,
        disabled:
          isModalTypeNeedConfirmation &&
          confirmationForm !== form.getFieldValue('email'),
        loading: isLoading,
      }}
    >
      <Form
        {...formProps}
        layout="vertical"
        validateMessages={{ required: '${label} must be filled.' }}
      >
        <Form.Item
          label="User Email"
          name="email"
          rules={[
            {
              required: true,
              type: 'email',
              message: '${label} is not a valid email.',
            },
            // email is belajar.id
            // {
            //   pattern: /@belajar\.id$/,
            //   message: '${label} must be a belajar.id email.',
            // },
            // email is not registered
            // {
            //   validator: async (_, value) => {
            //     // TODO: complete this
            //   },
            // },
          ]}
        >
          <Input
            type="email"
            autoComplete="off"
            disabled={showModal === 'update' || showModal === 'delete'}
          />
        </Form.Item>
        <Form.Item
          label="Role"
          name="role"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            disabled={showModal === 'delete'}
            options={availableRoleOptions}
          />
        </Form.Item>
      </Form>

      {isModalTypeNeedConfirmation && (
        <>
          <p>Isi alamat email yang hendak diubah sebagai konfirmasi</p>
          <Input
            className="mt-2"
            type="email"
            autoComplete="off"
            placeholder="Alamat email dari akun yang akan diubah"
            value={confirmationForm}
            onChange={(e) => setConfirmationForm(e.target.value)}
          />
        </>
      )}
    </Modal>
  )
}
