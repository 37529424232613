import React from 'react'
import { useNode, Node } from '@craftjs/core'
import clsx from 'clsx'
import { Form, Input, InputNumber } from '@pankod/refine-antd'
import { capitalize } from 'lodash'
import {
  Checkbox,
  CheckboxGroup as LibraryCheckboxGroup,
} from '@instructure/ui-checkbox'
import { Controller } from 'react-hook-form'

import {
  CheckboxGroupCustomProps,
  TCheckboxGroup,
  WIDGET_NAME,
} from '@components/FormBuilder/schema'
import {
  mappedWidgetSettingsError,
  widgetSettingsOnValueChange,
  getWidgetSettingsFields,
} from '@components/FormBuilder/utils'
import { useFormContext } from '@components/FormBuilder/formContext'
import MenuOptions, {
  defaultOptions,
  optionsParser,
} from '../utils/MenuOptions'
import ContentForm from '@components/ContentBank/ContentForm'

export const defaultProps: TCheckboxGroup = {
  label: 'Checkbox label',
  name: 'checkboxName',
  options: defaultOptions,
}

const CheckboxGroup = ({ label, name, options, helper }: TCheckboxGroup) => {
  const {
    connectors: { connect, drag },
  } = useNode()
  const { control } = useFormContext()

  return (
    <div
      ref={(ref) => connect(drag(ref!))}
      className={clsx(
        'mb-3 last:mb-0',
        'widget-component',
        'widget-component--checkbox-group',
        'text-default'
      )}
    >
      <Controller
        name={name}
        control={control}
        render={({
          field: { name, onChange, value },
          fieldState: { error },
        }) => (
          <LibraryCheckboxGroup
            name={name}
            description={
              <div
                className="form-label ql-editor"
                dangerouslySetInnerHTML={{ __html: label }}
              />
            }
            messages={
              error?.message
                ? [{ text: capitalize(error.message), type: 'error' }]
                : [{ text: helper || null, type: 'hint' }]
            }
            onChange={onChange}
            value={value}
          >
            {optionsParser(options).map(({ label, value }, i) => (
              <Checkbox key={i} label={label} value={value} />
            ))}
          </LibraryCheckboxGroup>
        )}
      />
    </div>
  )
}

const CheckboxGroupSettings = () => {
  const {
    actions: { setProp, setCustom },
    props,
    custom,
  } = useNode((node) => ({
    props: node.data.props,
    custom: node.data.custom,
  }))
  const errors = mappedWidgetSettingsError(custom.errors)
  return (
    <Form
      onValuesChange={(changedValue) =>
        widgetSettingsOnValueChange(changedValue, custom, setCustom)
      }
      fields={getWidgetSettingsFields(props)}
      layout="vertical"
    >
      <Form.Item
        label="Name Key"
        name="name"
        validateStatus={errors['name'] && 'error'}
        help={errors['name']}
      >
        <Input
          autoComplete="off"
          value={props.name}
          onChange={(e) =>
            setProp(
              (props: TCheckboxGroup) =>
                (props.name = e.target.value.replaceAll(' ', ''))
            )
          }
        />
      </Form.Item>
      <Form.Item
        label="Label"
        name="label"
        validateStatus={errors['label'] && 'error'}
        help={errors['label']}
      >
        <ContentForm
          index="widgetText"
          showDeleteButton={false}
          showIndentation={false}
          showUploader={false}
          showTitle={false}
          value={props.text}
          onChange={(text) =>
            setProp((props: TCheckboxGroup) => (props.label = text))
          }
        />
      </Form.Item>
      <Form.Item
        label="Options"
        name="options"
        validateStatus={errors['options'] && 'error'}
        help={errors['options']}
      >
        <MenuOptions
          onChange={(e) =>
            setProp((props: TCheckboxGroup) => (props.options = e))
          }
        />
      </Form.Item>
      <Form.Item
        label="Minimum Selected"
        name="minSelected"
        validateStatus={errors['minSelected'] && 'error'}
        help={errors['minSelected'] || '0 is equal as no limitation'}
      >
        <InputNumber
          autoComplete="off"
          value={props.minSelected}
          onChange={(e) =>
            setProp((props: TCheckboxGroup) => {
              props.minSelected = isNaN(e) ? undefined : Number(e)
              props.isRequired = Number(e) > 0
            })
          }
        />
      </Form.Item>
      <Form.Item
        label="Maximum Selected"
        name="maxSelected"
        validateStatus={errors['maxSelected'] && 'error'}
        help={errors['maxSelected'] || '0 is equal as no limitation'}
      >
        <InputNumber
          autoComplete="off"
          value={props.maxSelected}
          onChange={(e) =>
            setProp(
              (props: TCheckboxGroup) =>
                (props.maxSelected = isNaN(e) ? undefined : Number(e))
            )
          }
        />
      </Form.Item>
      <Form.Item
        label="Helper Text"
        name="helper"
        validateStatus={errors['helper'] && 'error'}
        help={errors['helper']}
      >
        <Input
          autoComplete="off"
          value={props.helper}
          onChange={(e) =>
            setProp((props: TCheckboxGroup) => (props.helper = e.target.value))
          }
        />
      </Form.Item>
    </Form>
  )
}

CheckboxGroup.craft = {
  rules: {
    canDrop: (targetNode: Node) => {
      return targetNode.data.name === WIDGET_NAME.Container
    },
  },
  props: defaultProps,
  custom: CheckboxGroupCustomProps,
  related: {
    settings: CheckboxGroupSettings,
  },
}

export { CheckboxGroup }
