import {
  ModalProps,
  FormProps,
  Modal,
  Form,
  Input,
  Select,
  SelectProps,
  DatePicker,
  Space,
  InputNumber,
  Button,
  Icons,
} from '@pankod/refine-antd'
import React from 'react'
import 'dayjs/locale/id'
import dayjs, { Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import locale from 'antd/lib/locale/id_ID'

import { FormSelectComponent } from '../FormSelectComponent'
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Jakarta')

type ModalFormProps = {
  modalProps: ModalProps
  formProps: FormProps
  mode: 'create' | 'edit'
  onClose: () => void
}
const ModalForm = ({
  modalProps,
  formProps,
  mode,
  onClose,
}: ModalFormProps) => {
  const isEditMode = mode === 'edit'
  const selectedEvaluatedRole = Form.useWatch('evaluatedRole', formProps.form)
  const evaluatedRole: SelectProps['options'] = [
    {
      label: 'Fasilitator',
      value: 'instruktur',
    },
    {
      label: 'Pengajar Praktik',
      value: 'asisten',
    },
  ]

  const evaluatorRole: SelectProps['options'] = [
    {
      label: 'Admin Kelas (Satker/UPT)',
      value: 'admin_kelas',
    },
    {
      label: 'Diri Sendiri',
      value: 'self',
    },
    {
      label: 'Fasilitator',
      value: 'instruktur',
      disabled: selectedEvaluatedRole === 'instruktur',
    },
    {
      label: 'Murid (CGP)',
      value: 'peserta',
    },
    {
      label: 'Pengajar Praktik',
      value: 'asisten',
      disabled: selectedEvaluatedRole === 'asisten',
    },
  ]

  const formType = Form.useWatch('type', formProps?.form)
  const evaluatorRoles = Form.useWatch('evaluatorRoles', formProps?.form)
  const isAllEvaluatorRoleSelected =
    evaluatorRoles?.length === evaluatorRole.length - 1

  return (
    <Modal
      {...modalProps}
      onCancel={onClose}
      title={isEditMode ? 'Edit Penilaian Kinerja' : 'Buat Penilaian Kinerja'}
      cancelText="Batal"
      okText="Simpan"
      afterClose={() => {
        formProps.form?.resetFields()
        formProps.form?.setFields([{ name: 'evaluatorRoles', value: [] }])
      }}
      bodyStyle={{ maxHeight: 700, overflow: 'auto' }}
    >
      <Form
        {...formProps}
        initialValues={{
          ...formProps?.initialValues?.data,
          penilaian_period: [
            formProps?.initialValues?.data?.startDate,
            formProps?.initialValues?.data?.endDate,
          ],
        }}
        layout="vertical"
        validateMessages={{ required: '${label} harus diisi.' }}
        onFinish={({ penilaian_period, form, ...data }) => {
          formProps.onFinish?.({
            ...data,
            startDate: penilaian_period[0],
            endDate: penilaian_period[1],
            formId: form.id,
          })
        }}
      >
        <Form.Item
          label="Nama Penilaian"
          name="name"
          required
          rules={[{ required: true }]}
        >
          <Input autoComplete="off" />
        </Form.Item>
        <Form.Item
          label="Role yang Dinilai"
          name="evaluatedRole"
          required
          rules={[{ required: true }]}
          getValueFromEvent={(value) => {
            formProps.form?.setFields([{ name: 'evaluatorRoles', value: [] }])
            return value
          }}
        >
          <Select options={evaluatedRole} showSearch={false} />
        </Form.Item>
        <Form.Item
          label="Tipe Penilaian"
          name="type"
          required
          rules={[{ required: true }]}
          getValueFromEvent={(e) => {
            formProps.form?.setFields([
              { name: 'form', value: [] },
              { name: 'evaluatorRoles', value: [] },
            ])
            return e
          }}
        >
          <Select
            options={[
              {
                label: 'Qualitative',
                value: 'qualitative',
              },
              {
                label: 'Quantitative',
                value: 'quantitative',
              },
            ]}
            showSearch={false}
          />
        </Form.Item>

        <Form.Item
          label="Aktor penilai"
          required
          help={
            !selectedEvaluatedRole || !formType
              ? 'Pilih role yang dinilai dan tipe form terlebih dulu.'
              : undefined
          }
        >
          <Form.List
            name="evaluatorRoles"
            rules={[
              {
                validator(_, value) {
                  if (!value?.length)
                    return Promise.reject('Aktor penilai tidak boleh kosong.')
                  return Promise.resolve()
                },
              },
              {
                validator(_, value) {
                  const totalWeight = value?.reduce?.(
                    (
                      total: number,
                      current?: { role: string; weight: number }
                    ) => total + (current?.weight || 0),
                    0
                  )
                  if (totalWeight > 100)
                    return Promise.reject(
                      'Total bobot tidak boleh lebih dari 100.'
                    )
                  return Promise.resolve()
                },
              },
            ]}
          >
            {(fields, { add, remove }, { errors }) => {
              return (
                <>
                  {fields.map(({ key, name, ...rest }) => {
                    return (
                      <Space
                        key={key}
                        className="flex gap-2 w-full !items-start mb-2 last-of-type:mb-3"
                      >
                        <Form.Item
                          {...rest}
                          name={[name, 'role']}
                          rules={[
                            { required: true, message: 'Role harus diisi' },
                          ]}
                          help="Role aktor"
                        >
                          <Select
                            options={evaluatorRole.map(
                              ({ value, label, disabled }) => {
                                return {
                                  value,
                                  label,
                                  disabled:
                                    disabled ||
                                    evaluatorRoles.some(
                                      (evaluatorRole?: {
                                        role: string
                                        weight: number
                                      }) => evaluatorRole?.role === value
                                    ),
                                }
                              }
                            )}
                            disabled={!selectedEvaluatedRole || !formType}
                            showSearch={false}
                            placeholder={`Aktor penilai`}
                            className="!w-[220px]"
                          />
                        </Form.Item>
                        {formType === 'quantitative' && (
                          <Form.Item
                            {...rest}
                            name={[name, 'weight']}
                            initialValue={0}
                            help="Bobot"
                          >
                            <InputNumber
                              precision={2}
                              max={100}
                              min={0}
                              controls={false}
                              addonAfter="%"
                            />
                          </Form.Item>
                        )}
                        <Button
                          icon={<Icons.MinusCircleOutlined />}
                          danger
                          type="dashed"
                          className="ml-2"
                          onClick={() => remove(name)}
                        />
                      </Space>
                    )
                  })}
                  <div className="w-full">
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      icon={<Icons.PlusOutlined />}
                      disabled={
                        !selectedEvaluatedRole ||
                        !formType ||
                        isAllEvaluatorRoleSelected
                      }
                    >
                      Tambah aktor penilai
                    </Button>
                    <Form.ErrorList errors={errors} />
                  </div>
                </>
              )
            }}
          </Form.List>
        </Form.Item>

        <Form.Item
          label="Pilih Form"
          name="form"
          required
          rules={[
            {
              validator(_, value) {
                if (!value?.id)
                  return Promise.reject('Form tidak boleh kosong.')
                return Promise.resolve()
              },
            },
          ]}
          help={!formType ? 'Pilih tipe form terlebih dahulu.' : undefined}
        >
          <FormSelectComponent
            disabled={!formType}
            key={formType}
            formTypeOptions={
              formType === 'quantitative'
                ? [
                    {
                      label: 'Likert',
                      value: 'LIKERT',
                    },
                    {
                      label: 'Quantitative',
                      value: 'QUANTITATIVE',
                    },
                  ]
                : undefined
            }
          />
        </Form.Item>
        <Form.Item
          label="Periode Penilaian"
          name="penilaian_period"
          rules={[
            { required: true },
            {
              validator(_, value) {
                if (!value || !value[0] || !value[1]) {
                  return Promise.reject(
                    new Error('Periode Penilaian harus diisi.')
                  )
                }
                return Promise.resolve()
              },
            },
          ]}
          getValueProps={(dates) => ({
            value: dates
              ? [
                  dates[0] ? dayjs(dates[0]) : undefined,
                  dates[1] ? dayjs(dates[1]) : undefined,
                ]
              : [],
          })}
          getValueFromEvent={(date) => {
            if (!date) return [null, null]
            const [startDate, endDate]: [Dayjs, Dayjs] = date
            return [
              startDate.tz().startOf('date').toISOString(),
              endDate.tz().endOf('date').toISOString(),
            ]
          }}
        >
          <DatePicker.RangePicker
            format="DD MMM YYYY"
            locale={locale.DatePicker}
            allowEmpty={[false, false]}
            disabledDate={(date) => date.isBefore(dayjs().startOf('date'))}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default ModalForm
