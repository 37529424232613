import {
  Popconfirm,
  Space,
  Checkbox,
  TextField,
  Button,
  Icons,
} from '@pankod/refine-antd'
import React, { useState } from 'react'

type SyncClassesButtonProps = {
  onConfirm: (withBlueprint: boolean) => Promise<any>
  isLoading: boolean
}
const SyncClassesButton = ({
  isLoading,
  onConfirm,
}: SyncClassesButtonProps) => {
  const [checked, setChecked] = useState(false)
  return (
    <Popconfirm
      title={
        <Space direction="vertical">
          <div>Apakah Anda yakin ingin melakukan sinkronisasi kelas?</div>
          <Checkbox
            checked={checked}
            disabled={isLoading}
            onChange={() => setChecked((v) => !v)}
          >
            <TextField value="Dengan Blueprint" />
          </Checkbox>
        </Space>
      }
      okText="Sinkronisasi"
      cancelText="Batal"
      onConfirm={async () => await onConfirm(checked)}
      onVisibleChange={(visible) => {
        if (visible) setChecked(false)
      }}
    >
      <Button icon={<Icons.SyncOutlined />} loading={isLoading}>
        Sinkronisasi Kelas
      </Button>
    </Popconfirm>
  )
}

export default SyncClassesButton
