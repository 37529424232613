import { unparse } from 'papaparse'

type GenerateTemplate = (
  fields: Array<string | { name: string; optional?: boolean }>,
  exampleCount?: number
) => string
export const generateTemplate: GenerateTemplate = (
  fields,
  exampleCount = 3
) => {
  const data: string[][] = []

  const stringOnlyFields: string[] = fields.map((fieldList) => {
    if (typeof fieldList === 'string') {
      return fieldList
    }
    return fieldList.name
  })

  const datum = stringOnlyFields.reduce((result, header) => {
    result.push(`${header}_data`)
    return result
  }, [] as string[])

  for (let i = 0; i < exampleCount; i++) {
    data.push(datum.map((d) => d + `_${i + 1}`))
  }

  return unparse({
    fields: stringOnlyFields,
    data,
  })
}

export const downloadTemplate = function (
  data: string,
  title?: string,
  filePrefix: string = 'csv_template'
) {
  // Creating a Blob for having a csv file format
  // and passing the data with type
  const blob = new Blob([data], { type: 'text/csv' })

  // Creating an object for downloading url
  const url = window.URL.createObjectURL(blob)

  // Creating an anchor(a) tag of HTML
  const a = document.createElement('a')

  // Passing the blob downloading url
  a.setAttribute('href', url)

  // Setting the anchor tag attribute for downloading
  // and passing the download file name
  a.setAttribute(
    'download',
    `${filePrefix}${title ? `_${title.toLowerCase()}` : ''}.csv`
  )

  // Performing a download with click
  a.click()
}
