import { Descriptions } from '@pankod/refine-antd'
import React from 'react'

type SummarySectionProps = {
  userEmail?: string
  userName?: string
  origin: { localProgramName?: string; className?: string }
  targetClass: { id?: string; name?: string }
  targetClassGroup: { id?: string; name?: string }
  targetLocalProgram: { id?: string; name?: string }
  showProgramLocal?: boolean
}
const SummarySection = ({
  targetClass,
  targetClassGroup,
  targetLocalProgram,
  userName,
  userEmail,
  origin,
  showProgramLocal,
}: SummarySectionProps) => {
  return (
    <Descriptions
      title="Ringkasan Perpindahan Peserta Kelas"
      bordered
      layout="vertical"
      column={2}
    >
      <Descriptions.Item
        labelStyle={{ fontWeight: 'bold' }}
        label="Nama Peserta"
      >
        {userName}
      </Descriptions.Item>
      <Descriptions.Item
        labelStyle={{ fontWeight: 'bold' }}
        label="Email Peserta"
      >
        {userEmail}
      </Descriptions.Item>
      {showProgramLocal && (
        <>
          <Descriptions.Item
            labelStyle={{ fontWeight: 'bold' }}
            label="Program Lokal Awal"
          >
            {origin.localProgramName}
          </Descriptions.Item>
          <Descriptions.Item
            labelStyle={{ fontWeight: 'bold' }}
            label="Tujuan Program Lokal"
          >
            {targetLocalProgram.name}
          </Descriptions.Item>
        </>
      )}
      <Descriptions.Item labelStyle={{ fontWeight: 'bold' }} label="Kelas Awal">
        {origin.className}
      </Descriptions.Item>
      <Descriptions.Item
        labelStyle={{ fontWeight: 'bold' }}
        label="Tujuan Kelas"
      >
        {targetClass.name}
      </Descriptions.Item>
      {targetClassGroup?.name && (
        <Descriptions.Item
          labelStyle={{ fontWeight: 'bold' }}
          label="Tujuan Kelompok Murid"
        >
          {targetClassGroup.name}
        </Descriptions.Item>
      )}
    </Descriptions>
  )
}

export default SummarySection
