import { Drawer, Empty, Spin, Tabs } from '@pankod/refine-antd'
import React from 'react'
import { useList } from '@pankod/refine-core'

import { kapabilitasMapper } from '@components/DataTableParticipants/blocks/utils'
import { CertificateTemplate } from '@resources/lms-management/certificate-template/types'
import TemplatePanel from './TemplatePanel'

type TemplateCertificateDrawer = {
  visible: boolean
  programId?: number | string | string[]
  programLocalId?: number | string | string[]
  onClose: () => void
}

export const TemplateCertificateDrawer = ({
  onClose,
  visible,
  programId,
  programLocalId,
}: TemplateCertificateDrawer) => {
  const { data, isLoading } = useList<CertificateTemplate>({
    resource: `programs/${programId}/certificate-templates`,
    dataProviderName: 'lms',
    queryOptions: {
      enabled: !!programId && !!programLocalId && visible,
    },
  })
  const handleClose = () => {
    onClose()
  }
  return (
    <Drawer
      visible={visible}
      title={`Template Sertifikat`}
      onClose={handleClose}
      width={'60%'}
      destroyOnClose
      maskClosable={false}
    >
      {isLoading && (
        <div className="absolute top-0 w-full h-full z-10 left-0 bg-black opacity-35">
          <Spin size="large" className="!absolute left-1/2 top-1/4 z-20" />
        </div>
      )}
      <Tabs type="card" destroyInactiveTabPane>
        {!data?.data?.length && (
          <Tabs.TabPane key={'empty'} tabKey="Kosong">
            <Empty description="Tidak ada template" />
          </Tabs.TabPane>
        )}
        {data?.data?.map(
          ({ id, kapabilitas, backTemplateUrl, frontTemplateUrl }) => {
            return (
              <Tabs.TabPane
                key={id}
                tabKey={kapabilitas}
                tab={kapabilitasMapper(kapabilitas)}
              >
                <TemplatePanel
                  templateUrl={{
                    back: backTemplateUrl,
                    front: frontTemplateUrl,
                  }}
                  templateId={id}
                  programLocalId={programLocalId}
                />
              </Tabs.TabPane>
            )
          }
        )}
      </Tabs>
    </Drawer>
  )
}
