import { AuthProvider } from '@pankod/refine-core'
import { CredentialResponse, googleLogout } from '@react-oauth/google'
import nookies from 'nookies'
import dayjs from 'dayjs'
import { notification } from '@pankod/refine-antd'

import { fetchGuruToken, fetchLMSRole } from './auth'
import { TCommonError } from './interfaces/common'
import { SESSION } from './constant'

const logoutNotification = (isNotAuthorized: boolean = false) => {
  if (isNotAuthorized) {
    notification.open({
      message: 'Anda tidak dapat mengakses halaman ini!',
      description:
        'Silakan login kembali menggunakan akun yang memiliki akses.',
      type: 'error',
      duration: 8,
    })
    return
  }

  notification.open({
    message: 'Anda berhasil log out!',
    type: 'success',
  })
}

export const authProvider: AuthProvider = {
  login: async ({ credential }: CredentialResponse) => {
    const tokenId = credential
    if (tokenId) {
      try {
        const guruSessionData = await fetchGuruToken({ tokenId })
        if (!guruSessionData.user) return Promise.reject()

        let { user } = guruSessionData

        const { LMSRole, lptkId } = await fetchLMSRole({
          guruToken: user.guruToken,
        })
        user.user = {
          ...user.user,
          LMSRole,
          lptkId,
        }

        const expiresIn = dayjs(user.expiredAt).diff()
        nookies.set(null, SESSION.USER_SESSION, JSON.stringify(user), {
          maxAge: expiresIn,
          path: '/',
        })

        return Promise.resolve()
      } catch (error) {
        return Promise.reject()
      }
    }

    return Promise.reject()
  },
  logout: (param?: { redirectPath: { cause: string } }) => {
    sessionStorage.clear()
    googleLogout()
    nookies.destroy(null, SESSION.USER_SESSION)

    const isNotAuthorized =
      param?.redirectPath.cause === 'notAuthorized' || false

    logoutNotification(isNotAuthorized)
    return Promise.resolve()
  },
  checkError: (error: TCommonError) => {
    if (error && error.statusCode === 401) {
      return Promise.reject({ cause: 'notAuthorized' })
    }

    return Promise.resolve()
  },
  checkAuth: async (ctx) => {
    const cookies = nookies.get(ctx)
    const userSession = cookies[SESSION.USER_SESSION]
    return userSession ? Promise.resolve() : Promise.reject()
  },

  getPermissions: () => Promise.resolve(),
  getUserIdentity: async () => {
    const user = nookies.get()[SESSION.USER_SESSION]
    if (user) {
      const parsedUser = JSON.parse(user)
      return Promise.resolve(parsedUser)
    }
    return Promise.reject()
  },
}
