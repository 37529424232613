import {
  Form,
  FormProps,
  Input,
  Modal,
  ModalProps,
  Select,
} from '@pankod/refine-antd'
import React from 'react'

type RombelModalFormProps = {
  formProps: FormProps
  modalProps: ModalProps
  mode: 'create' | 'edit'
  onClose: () => void
}
export const RombelModalForm = ({
  formProps,
  modalProps,
  mode,
  onClose,
}: RombelModalFormProps) => {
  return (
    <Modal
      {...modalProps}
      onCancel={onClose}
      title={mode === 'create' ? 'Buat Rombel Baru' : 'Edit Rombel'}
      okText="Simpan"
      cancelText="Kembali"
    >
      <Form
        {...formProps}
        layout="vertical"
        validateMessages={{ required: '${label} harus diisi.' }}
        initialValues={(() => {
          return formProps?.initialValues?.data || {}
        })()}
      >
        <Form.Item
          label="Nama Rombel"
          name="name"
          required
          rules={[{ required: true }]}
        >
          <Input autoComplete="off" placeholder="Tulis nama Rombel" />
        </Form.Item>
        <Form.Item
          label="Kategori"
          name={mode === 'create' ? 'type' : undefined}
          required
          rules={[{ required: true }]}
          preserve
        >
          <Select
            allowClear={false}
            disabled={mode === 'edit'}
            value={
              mode === 'edit'
                ? formProps.form?.getFieldValue('type')
                : undefined
            }
            options={[
              { label: 'Utama', value: 'UTAMA' },
              { label: 'PPL', value: 'PPL' },
              { label: 'SEMINAR', value: 'SEMINAR' },
              { label: 'KEPEMIMPINAN', value: 'KEPEMIMPINAN' },
            ]}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}
