import { Table, TextField, useTable } from '@pankod/refine-antd'
import { useResource } from '@pankod/refine-core'

import { parseDate } from '../../utils/dateParser'
import {
  InstructorWebinarSchedule,
  SessionTime,
} from '@resources/webinar-management/types'
import PopConfirmCancelWebinar from '@resources/webinar-management/blocks/PopConfirmCancelWebinar'

export const TableInstructorScheduledWebinar = () => {
  const { id } = useResource()

  const { tableProps } = useTable<any>({
    resource: 'instructor/webinars-schedule',
    dataProviderName: 'lms',
    permanentFilter: [{ field: 'webinarId', operator: 'eq', value: id }],
  })

  return (
    <Table
      {...tableProps}
      loading={tableProps.loading}
      rowKey="id"
      title={() => <h3>Jadwal Anda di webinar ini: </h3>}
    >
      <Table.Column
        dataIndex={'date'}
        title="Tanggal"
        render={(value) => {
          const date = parseDate(value)
          return <TextField value={`${date.format('DD MMMM YYYY')}`} />
        }}
      />

      <Table.Column
        dataIndex={'webinarSchedule'}
        title="Waktu"
        render={(value) => {
          return (
            value &&
            Object.entries<SessionTime>(value)
              .filter(([key]) => key === 'wib')
              .map(([key, { startTime, endTime }]) => {
                return (
                  <p
                    key={key}
                    className="last:mb-0"
                  >{`${startTime} - ${endTime} ${key.toUpperCase()}`}</p>
                )
              })
          )
        }}
      />

      <Table.Column
        dataIndex={'name'}
        title="Kelas"
        render={(value) => <TextField value={value} />}
      />

      <Table.Column<InstructorWebinarSchedule>
        width={100}
        render={(_, record) => (
          <PopConfirmCancelWebinar wkgId={record.webinarKelasGroupId} />
        )}
      />
    </Table>
  )
}
