import { Button, Descriptions, Icons, Show, Tabs } from '@pankod/refine-antd'
import {
  IResourceComponentsProps,
  useCreate,
  useResource,
  useShow,
  useUpdate,
} from '@pankod/refine-core'
import Head from 'next/head'
import React from 'react'
import { useRouter } from 'next/router'
import dayjs from 'dayjs'

import { trackTabChange } from 'src/helpers/url'
import { TableDasusProgramLocalList } from './block/TableDasusProgramLocalList'
import { TabProgramConfiguration } from './block/TabProgramConfiguration'
import { TableDasusDashboardTabContent } from './TableDasusDashboardTabContent'

export const DasusProgramShow: React.FC<IResourceComponentsProps> = ({
  options,
}) => {
  const router = useRouter()
  const { activeTab = null } = router.query || {}

  const resource = useResource()
  const id = resource.id as number
  const [activeKey, setActiveKey] = React.useState(
    (activeTab as string) || 'config'
  )

  const { queryResult } = useShow({
    id,
    resource: 'program-dasus',
    dataProviderName: 'lms',
  })

  const { mutateAsync } = useUpdate()
  const { mutateAsync: createMutate } = useCreate()

  const handleFinalizeDasusProgram = (value: any) => {
    const tempValue = { ...value }

    const { ptmData } = tempValue

    if (ptmData) {
      tempValue.ptmData = ptmData.map((item: any) => ({
        ...item,
        dateRanges: item.dateRanges.map((date: any) => {
          if (typeof date === 'string') {
            return dayjs(date).toISOString()
          }

          return dayjs(date?.value).toISOString()
        }),
      }))
    }

    mutateAsync({
      resource: `program-dasus/${id}`,
      id: 'template-course',
      values: tempValue,
      dataProviderName: 'lms',
    }).then(() => {
      queryResult.refetch()

      createMutate({
        resource: `program-dasus/${id}/finalize`,
        values: {},
        dataProviderName: 'lms',
      }).then(() => {
        queryResult.refetch()
      })
    })
  }

  return (
    <>
      <Show
        title="Program Detail"
        isLoading={queryResult.isLoading}
        headerButtons={() => (
          <Button
            onClick={() => {
              window.location.reload()
            }}
            icon={<Icons.ReloadOutlined />}
          >
            refresh
          </Button>
        )}
      >
        <Head>
          <title>LMS Admin | {options?.label} </title>
        </Head>

        <Descriptions
          bordered
          column={1}
          size="small"
          title={queryResult.data?.data?.data?.name}
          className="mb-4"
        >
          <Descriptions.Item label="Tanggal Mulai">
            {queryResult.data?.data?.data?.startDate}
          </Descriptions.Item>
          <Descriptions.Item label="Tanggal Selesai">
            {queryResult.data?.data?.data?.endDate}
          </Descriptions.Item>
        </Descriptions>

        <Tabs
          defaultActiveKey="config"
          activeKey={activeKey}
          destroyInactiveTabPane
          onChange={(activeKey) => {
            setActiveKey(activeKey)
            trackTabChange(router, activeKey)
          }}
        >
          <Tabs.TabPane tab="Konfigurasi Template" key="config">
            <TabProgramConfiguration
              dasusProgramId={id}
              isReadOnly={queryResult.data?.data?.data?.isFinalized}
              onFinalize={handleFinalizeDasusProgram}
            />
          </Tabs.TabPane>

          <Tabs.TabPane
            tab="Program Lokal"
            key="programLocal"
            disabled={!queryResult.data?.data?.data?.isFinalized}
          >
            <TableDasusProgramLocalList dasusProgramId={id} />
          </Tabs.TabPane>

          <Tabs.TabPane
            tab="Dashboard"
            key="dashboard"
            disabled={!queryResult.data?.data?.data?.isFinalized}
          >
            <TableDasusDashboardTabContent
              dasusProgramId={id}
              title={queryResult.data?.data?.data?.name}
            />
          </Tabs.TabPane>
        </Tabs>
      </Show>
    </>
  )
}
