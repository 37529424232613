import {
  Table,
  TextField,
  Spin,
  Tag,
  Form,
  Button,
  Icons,
  Input,
  DatePicker,
  Switch,
  Select,
  Empty,
} from '@pankod/refine-antd'
import dayjs from 'dayjs'
import 'dayjs/locale/id'
import locale from 'antd/lib/locale/id_ID'
import React from 'react'

export type TabContentConfigPTMFIProps = {
  dasusProgramId: string | number
  fi: any
  ptm: any
  isLoading?: boolean
  isReadOnly?: boolean
  onApply?: (value: any) => void
}

// TODO: [DASUS] - Submit not implemented

export const TabContentConfigPTMFI = (props: TabContentConfigPTMFIProps) => {
  const [currentPTMDateRange, setCurrentPTMDateRange] = React.useState<any>([])
  const { fi, ptm, isLoading, isReadOnly = true, onApply } = props
  const [formPTM] = Form.useForm()
  const [formFI] = Form.useForm()

  const [isFormLocked, setisFormLocked] = React.useState(false)

  React.useEffect(() => {
    if (!isLoading) {
      formPTM.setFieldsValue({
        ptm,
      })

      formFI.setFieldsValue({
        fi,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  const getDateRangeOptionsBasedonIndex = (index: number) => {
    // get start date and end date from PTM
    const startDate = formPTM.getFieldValue(['ptm', index, 'startDate'])
    const endDate = formPTM.getFieldValue(['ptm', index, 'endDate'])

    const start = dayjs(startDate)
    const end = dayjs(endDate)

    const dayList = []

    for (let dt = start; dt <= end; dt = dt.add(1, 'day')) {
      dayList.push(dt.format('YYYY-MM-DD'))
    }

    setCurrentPTMDateRange(
      dayList.map((date) => {
        return {
          label: dayjs(date).format('dddd YYYY-MM-DD'),
          value: dayjs(date).format('YYYY-MM-DD'),
        }
      })
    )
  }

  return (
    <Spin spinning={isLoading}>
      {!isReadOnly ? (
        <div>
          <div>
            <h2>Konfigurasi Template PTM & FI</h2>
            <Form
              form={formPTM}
              onFinish={(values) => {
                if (isFormLocked) {
                  setisFormLocked(false)
                } else {
                  onApply?.(values)

                  setisFormLocked(true)
                }
              }}
              layout="vertical"
            >
              <Form.List
                name="ptm"
                children={(fields, { add, remove }) => (
                  <>
                    <div className="flex justify-end mb-4">
                      <Button
                        icon={<Icons.PlusOutlined />}
                        onClick={() => {
                          add()
                        }}
                        disabled={isFormLocked || fields.length === 4}
                      >
                        Tambah Template PTM
                      </Button>
                    </div>

                    <div>
                      {fields.length === 0 && (
                        <Empty
                          description="Belum ada PTM / FI"
                          className="my-4"
                        />
                      )}
                      {fields.map((field, index) => (
                        <div key={field.key} className="flex justify-between">
                          <Form.Item
                            label="Nama PTM"
                            name={[index, 'name']}
                            rules={[
                              {
                                required: true,
                                message: 'Title is required',
                              },
                            ]}
                            initialValue={`PTM ${index + 1}`}
                          >
                            <Input disabled={isFormLocked} />
                          </Form.Item>

                          <Form.Item
                            label="Batas Tanggal awal"
                            name={[index, 'startDate']}
                            rules={[
                              {
                                required: true,
                                message: 'Date is required',
                              },
                            ]}
                            getValueProps={(date) => ({
                              value: date ? dayjs(date) : null,
                            })}
                            getValueFromEvent={(date) => {
                              return date?.toISOString()
                            }}
                            initialValue={dayjs()}
                          >
                            <DatePicker
                              picker="date"
                              locale={locale.DatePicker}
                              disabled={isFormLocked}
                            />
                          </Form.Item>

                          <Form.Item
                            label="Batas Tanggal Akhir"
                            name={[index, 'endDate']}
                            getValueProps={(date) => ({
                              value: date ? dayjs(date) : null,
                            })}
                            getValueFromEvent={(date) => {
                              return date?.toISOString()
                            }}
                            initialValue={dayjs().add(5, 'day')}
                          >
                            <DatePicker
                              picker="date"
                              locale={locale.DatePicker}
                              disabled={isFormLocked}
                            />
                          </Form.Item>

                          <Form.Item
                            label="Tanggal Pilihan"
                            name={[index, 'dateRanges']}
                            rules={[
                              {
                                required: true,
                                message: 'Please select a date.',
                              },
                            ]}
                            className="max-w-[10vw]"
                          >
                            <Select
                              mode="multiple"
                              options={
                                currentPTMDateRange.length > 0
                                  ? currentPTMDateRange
                                  : getDateRangeOptionsBasedonIndex(index)
                              }
                              disabled={isFormLocked}
                              onDropdownVisibleChange={(open) => {
                                if (open) {
                                  getDateRangeOptionsBasedonIndex(index)
                                }
                              }}
                            />
                          </Form.Item>
                          <Form.Item
                            name={[index, 'isActive']}
                            label="Aktif"
                            valuePropName="checked"
                            initialValue={true}
                          >
                            <Switch disabled={isFormLocked} />
                          </Form.Item>

                          <Button
                            danger
                            disabled={isFormLocked}
                            onClick={() => remove(index)}
                            icon={<Icons.DeleteOutlined />}
                          />
                        </div>
                      ))}
                    </div>
                  </>
                )}
              />

              <Form.List
                name="fi"
                children={(fields, { add, remove }) => (
                  <>
                    <div className="flex justify-end mb-4">
                      <Button
                        icon={<Icons.PlusOutlined />}
                        onClick={() => {
                          add()
                        }}
                        disabled={isFormLocked || fields.length === 2}
                      >
                        Tambah Template FI
                      </Button>
                    </div>

                    <div>
                      {fields.map((field, index) => (
                        <div key={field.key} className="flex justify-between">
                          <Form.Item
                            label="Nama FI"
                            name={[index, 'name']}
                            rules={[
                              {
                                required: true,
                                message: 'Title is required',
                              },
                            ]}
                            initialValue={`FI ${index + 1}`}
                          >
                            <Input disabled={isFormLocked} />
                          </Form.Item>

                          <Form.Item
                            label="Tanggal awal"
                            name={[index, 'startDate']}
                            rules={[
                              {
                                required: true,
                                message: 'Date is required',
                              },
                            ]}
                            getValueProps={(date) => ({
                              value: date ? dayjs(date) : null,
                            })}
                            getValueFromEvent={(date) => {
                              return date?.toISOString()
                            }}
                            initialValue={dayjs()}
                          >
                            <DatePicker
                              picker="date"
                              locale={locale.DatePicker}
                              disabled={isFormLocked}
                            />
                          </Form.Item>

                          <Form.Item
                            label="Tanggal Akhir"
                            name={[index, 'endDate']}
                            getValueProps={(date) => ({
                              value: date ? dayjs(date) : null,
                            })}
                            getValueFromEvent={(date) => {
                              return date?.toISOString()
                            }}
                            initialValue={dayjs().add(5, 'day')}
                          >
                            <DatePicker
                              picker="date"
                              locale={locale.DatePicker}
                              disabled={isFormLocked}
                            />
                          </Form.Item>

                          <Form.Item
                            name={[index, 'isActive']}
                            label="Aktif"
                            valuePropName="checked"
                            initialValue={true}
                          >
                            <Switch />
                          </Form.Item>

                          <Button
                            danger
                            onClick={() => remove(index)}
                            icon={<Icons.DeleteOutlined />}
                            disabled={isFormLocked}
                          />
                        </div>
                      ))}
                    </div>

                    <Button htmlType="submit" block className="my-4">
                      {isFormLocked ? 'enable edit' : 'apply changes'}
                    </Button>
                  </>
                )}
              />
            </Form>
          </div>
        </div>
      ) : (
        <>
          <h3>List PTM</h3>
          <Table dataSource={ptm} rowKey="id">
            <Table.Column
              dataIndex="name"
              title="Nama PTM"
              render={(value) => <TextField value={value} />}
            />
            <Table.Column
              dataIndex="startDate"
              title="Tanggal Mulai"
              render={(value) => (
                <TextField value={dayjs(value).format('DD MMM YYYY')} />
              )}
            />
            <Table.Column
              dataIndex="endDate"
              title="Tanggal Selesai"
              render={(value) => (
                <TextField value={dayjs(value).format('DD MMM YYYY')} />
              )}
            />

            <Table.Column
              dataIndex="dateRanges"
              title="Tanggal Pilihan"
              render={(value) =>
                value.map((date: any) => {
                  return (
                    <Tag key={date} className="p-2">
                      {dayjs(date).format('DD MMM')}
                    </Tag>
                  )
                })
              }
            />
          </Table>

          {/* <SortableFormPTM /> */}

          <div className="flex justify-between my-2">
            <h3>List FI</h3>
          </div>

          {/* <SortableFormPTM /> */}

          <Table dataSource={fi} rowKey="id">
            <Table.Column
              dataIndex="name"
              title="Nama FI"
              render={(value) => <TextField value={value} />}
            />
            <Table.Column
              dataIndex="startDate"
              title="Tanggal Mulai"
              render={(value) => (
                <TextField value={dayjs(value).format('DD MMM YYYY')} />
              )}
            />
            <Table.Column
              dataIndex="endDate"
              title="Tanggal Selesai"
              render={(value) => (
                <TextField value={dayjs(value).format('DD MMM YYYY')} />
              )}
            />
          </Table>
        </>
      )}
    </Spin>
  )
}
