import dayjs, { Dayjs } from 'dayjs'
import { range, union } from 'lodash'
import moment from 'moment'
import { extendMoment } from 'moment-range'

// @ts-expect-error
const { range: momentRange } = extendMoment(moment)

const FULL_MINUTE = 60
const FULL_HOUR = 24

const getDisabledTimeSpans = (unavailableTimes: ([Dayjs, Dayjs] | [])[]) => {
  return (
    unavailableTimes.reduce((total, [startDayjs, endDayjs]) => {
      if (!startDayjs || !endDayjs) return total

      const startHr = startDayjs.get('hour')
      const startMn = startDayjs.get('minute')
      const endHr = endDayjs.get('hour')
      const endMn = endDayjs.get('minute')

      const isStartHrEqualEndHr = startHr === endHr

      const spanHour = range(startHr, endHr + 1)
      spanHour.forEach((hour) => {
        if (!total[hour]) total[hour] = []
        if (hour !== startHr && hour !== endHr) {
          total[hour] = range(0, FULL_MINUTE)
        } else {
          const currentTotalHour = total[hour]
          if (hour === startHr) {
            total[hour] = union(
              currentTotalHour,
              range(startMn, isStartHrEqualEndHr ? endMn + 1 : FULL_MINUTE)
            )
          }
          if (hour === endHr) {
            total[hour] = union(
              currentTotalHour,
              range(isStartHrEqualEndHr ? startMn : 0, endMn + 1)
            )
          }
        }
      })
      return total
    }, {} as Record<number, number[]>) || {}
  )
}

const getClosestNextStartTime = (
  currentStartTime: Dayjs,
  unavailableTimes: ([Dayjs, Dayjs] | [])[]
): Dayjs => {
  const sortedNextStartTime =
    unavailableTimes
      .reduce((acc, [startDayjs]) => {
        if (startDayjs && currentStartTime.isBefore(startDayjs))
          return [...acc, startDayjs]
        return acc
      }, [] as Dayjs[])
      .sort((a, b) => a.unix() - b.unix()) || []
  return sortedNextStartTime[0] || dayjs().endOf('d')
}

/**
 * Generate list of disabled time in an object, with hour as the key and array of minutes as the value
 *
 * @param unavailableTimes Set of tuples consist of [startTime, endTime] in `Dayjs`
 * @param type Whether current user is picking `start` time or `end` time
 * @param currentSelectedStart Current selected start time when user picking end time (in `Dayjs`)
 * @returns Record of hours, in each hour consist array of disabled minutes
 */
export const generateDisabledTimeSpan = (
  unavailableTimes: ([Dayjs, Dayjs] | [])[] | undefined,
  type: 'start' | 'end',
  currentSelectedStart?: Dayjs
): Record<number, number[]> => {
  if (!unavailableTimes) return {}

  /** Find and block all time between each startTime -- endTime */
  const disabledTimeSpans = getDisabledTimeSpans(unavailableTimes)

  if (type === 'end' && !!currentSelectedStart) {
    const currentSelectedStartHour = currentSelectedStart.get('hour')
    disabledTimeSpans[currentSelectedStartHour] = union(
      disabledTimeSpans[currentSelectedStartHour],
      range(0, currentSelectedStart.get('minute') + 1)
    )

    /** Disabling hour before currentSelectedStart */
    const startSpanHour = range(0, currentSelectedStartHour + 1)
    startSpanHour.forEach((hour) => {
      if (currentSelectedStartHour === hour) {
        disabledTimeSpans[hour] = union(
          disabledTimeSpans[hour],
          range(0, currentSelectedStart.get('minute'))
        )
      } else {
        disabledTimeSpans[hour] = range(0, FULL_MINUTE)
      }
    })

    /** Find closest next startTime based on currentSelectedStart */
    const closestNextStartTime = getClosestNextStartTime(
      currentSelectedStart,
      unavailableTimes
    )

    /** Disable hour after next closest startTime */
    const closestHour = closestNextStartTime.get('hour')
    const closestMnts = closestNextStartTime.get('minutes')
    const endSpanHour = range(closestHour, FULL_HOUR)
    endSpanHour.forEach((hour) => {
      if (hour === closestHour) {
        disabledTimeSpans[hour] = union(
          disabledTimeSpans[hour],
          range(closestMnts, FULL_MINUTE)
        )
      } else {
        disabledTimeSpans[hour] = range(0, FULL_MINUTE)
      }
    })
  }

  return disabledTimeSpans
}

export const isRangeOverlaps = (
  timeRangeA: { startTime: string; endTime: string },
  timeRangeB: { startTime: string; endTime: string }
): boolean => {
  const rangeA = momentRange(
    moment(timeRangeA.startTime, 'HH:mm'),
    moment(timeRangeA.endTime, 'HH:mm')
  )
  const rangeB = momentRange(
    moment(timeRangeB.startTime, 'HH:mm'),
    moment(timeRangeB.endTime, 'HH:mm')
  )

  return rangeA.overlaps(rangeB)
}
