import { Col, Row } from '@pankod/refine-antd'
import React from 'react'
import Sticky from 'react-stickynode'

import { OutsideClickProvider } from 'src/hooks/useOnClickOutside'
import Toolbox from './Toolbox'
import WidgetSettings from './WidgetSettings'

type TWidgetPanelProps = {
  contentWrapperRef: React.RefObject<HTMLDivElement>
  isPreview?: boolean
  stickyTop?: number | string
}
const WidgetPanel = ({
  contentWrapperRef,
  isPreview = false,
  stickyTop = 88,
}: TWidgetPanelProps) => {
  return (
    <Sticky top={stickyTop}>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Toolbox isPreview={isPreview} />
        </Col>
        <Col span={24}>
          <OutsideClickProvider>
            <WidgetSettings contentWrapperRef={contentWrapperRef} />
          </OutsideClickProvider>
        </Col>
      </Row>
    </Sticky>
  )
}

export default WidgetPanel
