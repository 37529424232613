import { Canvas, FabricText, Textbox } from 'fabric'
import { nanoid } from 'nanoid'

import {
  CANVAS_BROKEN_IMAGE_BASE64,
  CANVAS_CONFIG,
  CANVAS_PREVIEW_DOM_ID,
  CONTROL_CONFIG,
  FONT_FACE,
} from '../constants'
import { SVGParsingOutput } from '../types'
import { orderObjectEvent } from './events'
import {
  CertificateTemplateVariables,
  CertificateTemplateVariablesWithDefault,
  RAWCertificateTemplatePayload,
} from '../../types'
import { exportObjectToSVG } from './exportSVG'

export type CanvasTabObject = {
  id: string
  canvasObj: {
    version: string
    objects: any[]
  }
}

export const createCanvas = (canvasElementId: string) =>
  new Canvas(canvasElementId, {
    height: CANVAS_CONFIG.height,
    width: CANVAS_CONFIG.width,
    renderOnAddRemove: true,
    preserveObjectStacking: true,
  })

export const fixTspanPosSVGObjImport = ({
  output,
  canvas,
  customVariableKey,
}: {
  output: SVGParsingOutput
  canvas?: Canvas | null
  customVariableKey?: string
}) => {
  const { objects, elements } = output
  objects.forEach((obj, index) => {
    if (obj && obj instanceof FabricText) {
      const currentElement = elements[index]
      if (
        currentElement.children.length > 0 &&
        currentElement.children[0].tagName === 'tspan'
      ) {
        const tspan = currentElement.children[0]
        const hasMoreTspanChildren = Array.from(currentElement.children)
          .slice(1)
          .some((child) => child.tagName === 'tspan')

        if (hasMoreTspanChildren) {
          const textOfTspan = Array.from(currentElement.children)
            .map((child) => child.textContent)
            .join('\n')

          obj.text = textOfTspan
        }

        // @ts-expect-error
        const { x, y } = tspan.attributes
        obj.left += Number(x.value)
        obj.top += Number(y.value)
      }

      let text
      if (obj.customObjectType === 'Textbox') {
        text = new Textbox(obj.text, {
          snapAngle: CONTROL_CONFIG.snapAngle,
          snapThreshold: CONTROL_CONFIG.snapThreshold,
          editable: true,
          customId: nanoid(),
          customVariableKey: obj.customVariableKey || customVariableKey,
          customObjectType: 'Textbox',
          fontFamily: obj.fontFamily,
          fontSize: obj.fontSize,
          fontWeight: obj.fontWeight,
          fontStyle: obj.fontStyle,
          left: obj.left,
          top: obj.top,
          width: obj.width,
          underline: obj.underline,
        })
        text.setControlsVisibility({
          mt: false,
          mb: false,
        })
      } else {
        text = new FabricText(obj.text, {
          snapAngle: CONTROL_CONFIG.snapAngle,
          snapThreshold: CONTROL_CONFIG.snapThreshold,
          editable: false,
          customId: nanoid(),
          customVariableKey: obj.customVariableKey || customVariableKey,
          customObjectType: 'Text',
          fontFamily: obj.fontFamily,
          fontSize: obj.fontSize,
          fontWeight: obj.fontWeight,
          fontStyle: obj.fontStyle,
          left: obj.left,
          top: obj.top,
          width: obj.width,
          underline: obj.underline,
        })
        text.setControlsVisibility({
          mt: false,
          mb: false,
          ml: false,
          mr: false,
        })
      }
      return canvas?.add(text)
    }

    if (obj) {
      obj.customId = nanoid()
      obj.customVariableKey = obj.customVariableKey || customVariableKey
      canvas?.add(obj)
    }
  })
}

export const deleteObject = (
  canvas?: Canvas | null,
  onObjectDeleted?: () => void
) => {
  if (!canvas) return
  const objects = canvas.getActiveObjects()
  canvas.remove(...objects)
  canvas.discardActiveObject()
  onObjectDeleted?.()
}

export const exportSVG = (canvas: Canvas | null) => {
  if (!canvas) return
  const svgString = String(canvas.toSVG())
  const blob = new Blob([svgString], { type: 'image/svg+xml' })
  const url = URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.href = url
  a.download = `exported_page.svg`
  a.click()
}

export const createCanvasOnTab = ({
  canvasTabObject,
  activeTab,
  saveState,
  syncTab,
}: // shouldSaveOnObjectInit,
{
  canvasTabObject: CanvasTabObject[]
  activeTab: number
  saveState: (
    canvas: Canvas | null,
    activeTab: number,
    isUndoRedo: boolean
  ) => void
  syncTab: (index: number) => void
  shouldSaveOnObjectInit?: boolean
}) => {
  const newCanvas = createCanvas(canvasTabObject[activeTab]?.id)

  if (canvasTabObject[activeTab]?.canvasObj) {
    newCanvas?.loadFromJSON(canvasTabObject[activeTab].canvasObj)
    newCanvas?.renderAll()
  }

  // newCanvas?.on('object:added', () => {
  //   shouldSaveOnObjectInit && saveState(newCanvas, activeTab, true)
  //   shouldSaveOnObjectInit && syncTab(activeTab)
  // })

  // newCanvas?.on('after:render', () => {
  //   saveState(newCanvas, activeTab, false)
  //   // syncTab(activeTab)
  // })

  newCanvas?.on('selection:updated', () => {
    saveState(newCanvas, activeTab, false)
  })

  newCanvas?.on('selection:created', () => {
    saveState(newCanvas, activeTab, false)
  })

  newCanvas?.on('selection:cleared', () => {
    saveState(newCanvas, activeTab, false)
    syncTab(activeTab)
  })

  return newCanvas
}

export const fontLoader = async () => {
  const fontFaces = FONT_FACE.Inter.map(
    ({ style, url, weight }) =>
      new FontFace('Inter', `url(${url})`, {
        style,
        weight,
      })
  )

  await Promise.all(fontFaces.map((ff) => ff.load()))
  for (const ff of fontFaces) {
    document?.fonts.add(ff)
  }
}

export const moveActiveObjectUp = (canvas: Canvas | null) => {
  if (!canvas) return
  const activeObjects = canvas.getActiveObjects()
  if (activeObjects.length !== 1) return
  canvas.bringObjectForward(activeObjects[0])
  document.dispatchEvent(orderObjectEvent)
}

export const clearAllSelection = (canvas: Canvas | null) => {
  if (!canvas) return
  canvas.discardActiveObject()
}

export const moveActiveObjectDown = (canvas: Canvas | null) => {
  if (!canvas) return
  const activeObjects = canvas.getActiveObjects()
  if (activeObjects.length !== 1) return
  canvas.sendObjectBackwards(activeObjects[0])
  document.dispatchEvent(orderObjectEvent)
}

export const generateSVGPreview = async (
  canvasTabObject: CanvasTabObject[]
) => {
  if (!canvasTabObject) return
  const generateSVGString = async (
    page: 'canvas-front-page' | 'canvas-back-page'
  ): Promise<string> => {
    const tabIndex = page === 'canvas-front-page' ? 0 : 1

    const canvas = createCanvas(CANVAS_PREVIEW_DOM_ID[page])
    const canvasObj = canvasTabObject[tabIndex]?.canvasObj

    if (canvasObj) {
      await canvas.loadFromJSON(canvasObj)
      canvas.renderAll()
    }
    await canvas.dispose()

    const { svgString } = await exportObjectToSVG(canvasObj)

    return svgString
  }

  return {
    front: await generateSVGString('canvas-front-page'),
    back: await generateSVGString('canvas-back-page'),
  }
}

export const generateSVGPreviewFromActiveTab = async (
  canvasActiveTabObject: CanvasTabObject
) => {
  if (!canvasActiveTabObject) return
  const generateSVGString = async (): Promise<string> => {
    // since this any tab, we can point to any dom preview, in this case we will use the front page
    const canvas = createCanvas(CANVAS_PREVIEW_DOM_ID['canvas-front-page'])
    const canvasObj = canvasActiveTabObject?.canvasObj

    if (canvasObj) {
      await canvas.loadFromJSON(canvasObj)
      await canvas.renderAll()
    }
    const svgString = String(canvas.toSVG())

    await canvas.dispose()

    return svgString
  }

  return await generateSVGString()
}

/** @deprecated use `exportObjectToSVG()` instead */
export const getCertificatePayloadFromActiveTabObject = async (
  activeTabObject: CanvasTabObject,
  variables: CertificateTemplateVariables | undefined
): Promise<RAWCertificateTemplatePayload> => {
  const SEARCH_VALUE_KEY_LENGTH_LIMIT = 100
  const { userVariable } = variables || {}
  const svgString =
    (await generateSVGPreviewFromActiveTab(activeTabObject)) || ''

  const svgStringVarList = Array.from(
    new Set(
      (svgString?.match(/{{(.*?)}}/g) || []).map((item) =>
        item.replace(/{{|}}|\s/g, '')
      )
    )
  )

  let updatedSvgString = svgString

  userVariable &&
    userVariable.forEach((item) => {
      const key = item.value.slice(0, SEARCH_VALUE_KEY_LENGTH_LIMIT)
      const value = `{{ attributes['${item.key}'] }}`
      const regex = new RegExp(`${key}[^"<]*`, 'g')
      updatedSvgString = updatedSvgString?.replaceAll(regex, value)
    })

  return {
    template: updatedSvgString,
    usedSystemVariables: svgStringVarList,
  }
}

const getPosFromMatrixTransform = (matrixString: string) => {
  const matches = matrixString.match(/\(([^)]+)\)/)?.[1]

  if (matches) {
    const matrix = matches.split(' ').map(Number)
    const xPrime = matrix[0] * 0 + matrix[2] * 0 + matrix[4]
    const yPrime = matrix[1] * 0 + matrix[3] * 0 + matrix[5]
    return { x: xPrime, y: yPrime }
  } else {
    return null
  }
}
// TODO: REMINDER: if Image filled with attribute that are not parsed, it will crash the canvas instance!!, add a fallback for this
export const translateSVGCustomVarToTemplate = (
  svgString: string,
  variables: CertificateTemplateVariablesWithDefault | undefined,
  showOutlinedVariable = false
): string => {
  const { userVariable } = variables || {}
  const baseDiv = document.createElement('div')
  baseDiv.innerHTML = svgString
  const elements = Array.from(
    baseDiv.querySelectorAll(
      '[data-variablekey]'
    ) as unknown as HTMLCollectionOf<HTMLElement>
  )

  if (showOutlinedVariable) {
    elements.forEach((element) => {
      const root = element.getRootNode()
      element.classList.add('certificate-preview__tag-variable')
      const variableKey = element.getAttribute('data-variablekey') || ''

      if (element.tagName === 'text') {
        const matrixString =
          element.parentElement?.getAttribute('transform') || ''
        const position = getPosFromMatrixTransform(matrixString)
        if (position) {
          const tooltip = document.createElement('div')
          tooltip.style.position = 'absolute'
          tooltip.style.top = position.y + 'px'
          tooltip.style.left = position.x + 'px'
          tooltip.classList.add('certificate-preview__tag-variable-key')
          if (variableKey.startsWith("attributes['")) {
            tooltip.classList.add(
              'certificate-preview__tag-variable-key--custom'
            )
          }
          tooltip.innerText = extractVariableKey(variableKey)
          root.appendChild(tooltip)
        }
      }

      if (element.tagName === 'image') {
        const matrixString =
          element.parentElement?.getAttribute('transform') || ''
        const position = getPosFromMatrixTransform(matrixString)
        if (position) {
          const tooltip = document.createElement('div')
          tooltip.style.position = 'absolute'
          tooltip.style.top = position.y + 'px'
          tooltip.style.left = position.x + 'px'
          tooltip.classList.add('certificate-preview__tag-variable-key')
          if (variableKey.startsWith("attributes['")) {
            tooltip.classList.add(
              'certificate-preview__tag-variable-key--custom'
            )
          }
          tooltip.innerText = extractVariableKey(variableKey)
          root.appendChild(tooltip)
        }
      }
    })
  }

  let updatedSvgString = baseDiv.innerHTML
  if (userVariable) {
    userVariable.forEach((item) => {
      const regex = new RegExp(`{{\\s*attributes\\['${item.key}'\\]\\s*}}`, 'g')
      updatedSvgString = updatedSvgString.replace(
        regex,
        item.value || item.defaultValue || ''
      )
    })
  }

  // value for broken image when not translated
  updatedSvgString = updatedSvgString.replace(
    /{{\s*attributes\['(.*?)'\]\s*}}/g,
    CANVAS_BROKEN_IMAGE_BASE64
  )

  return updatedSvgString
}

export const getSVGStringFromUrl = async (
  url: string,
  variables:
    | CertificateTemplateVariables
    | CertificateTemplateVariablesWithDefault
    | undefined,
  showOutlinedVariable = false
): Promise<string> => {
  if (!url) {
    // throw new Error('URL is required to fetch SVG')
    return ''
  }
  const response = await fetch(url)
  if (!response.ok) {
    throw new Error(`Failed to fetch SVG from URL: ${response.statusText}`)
  }

  const svgText = await response.text()

  // TODO: REMINDER: if Image filled with attribute that are not translated, it will crash the canvas instance!!, add a fallback for this
  const replacedWithVariablesSVGString = translateSVGCustomVarToTemplate(
    svgText,
    variables,
    showOutlinedVariable
  )

  return replacedWithVariablesSVGString
}

export const getObjByKey = (
  canvasTabObject: CanvasTabObject[],
  key: string,
  variables: CertificateTemplateVariables | undefined,
  callback?: (filteredObj: any[]) => void
) => {
  const BASE64_SEARCH_LIMIT = 80
  const { userVariable } = variables || {}

  // Find value by key in userVariable
  const currentDeletedKey = userVariable?.find((item) => item.key === key)
  const { type, value } = currentDeletedKey || {}

  const filteredTabObj = canvasTabObject.map((tab) => {
    const filteredObjects = tab.canvasObj.objects.filter((obj) => {
      if (type === 'IMAGE' && obj?.src) {
        return (
          obj?.src.slice(0, BASE64_SEARCH_LIMIT) ===
          value?.slice(0, BASE64_SEARCH_LIMIT)
        )
      } else {
        return obj?.text === value
      }
    })

    return filteredObjects
  })

  // Ensure the callback is called with the updated canvasTabObject

  callback && callback(filteredTabObj)

  // Return the updated canvasTabObject
  return filteredTabObj
}

export const extractVariableKey = (text: string) => {
  if (text.startsWith("attributes['")) {
    const match = text.match(/attributes\['(.*?)'\]/)

    if (match) {
      const result = match[1]
      return result
    }
  }
  return text
}
