import {
  ModalProps,
  FormProps,
  Modal,
  Form,
  Input,
  Space,
  Select,
  InputNumber,
  Button,
  Icons,
  useSelect,
  SelectProps,
} from '@pankod/refine-antd'
import React from 'react'
import { useResource } from '@pankod/refine-core'

import { PenilaianKinerjaDetail, PenilaianKinerjaField } from './types'

type ModalFormProps = {
  modalProps: ModalProps
  formProps: FormProps
  mode: 'create' | 'edit'
  onClose: () => void
}
const ModalForm = ({
  formProps,
  modalProps,
  mode,
  onClose,
}: ModalFormProps) => {
  const { id: programId } = useResource()
  const selectedEvaluatedRole = Form.useWatch('evaluatedRole', formProps.form)
  const selectedPenilaianKerjaIds = Form.useWatch(
    'penilaianKinerjaIds',
    formProps.form
  )
  const evaluatedRole: SelectProps['options'] = [
    {
      label: 'Fasilitator',
      value: 'instruktur',
    },
    {
      label: 'Pengajar Praktik',
      value: 'asisten',
    },
  ]
  const evaluatedFilterRole: Record<string, string> = {
    instruktur: 'PENILAIAN_KINERJA_EVALUATED_ROLE_INSTRUKTUR',
    asisten: 'PENILAIAN_KINERJA_EVALUATED_ROLE_ASISTEN',
  }
  const { selectProps } = useSelect({
    resource: `programs/${programId}/penilaian-kinerja`,
    dataProviderName: 'lms',
    optionLabel: 'name',
    optionValue: 'id',
    filters: [
      {
        field: 'pageSize',
        operator: 'eq',
        value: -1,
      },
      {
        field: 'hasCategory',
        operator: 'eq',
        value: 'PENILAIAN_KINERJA_HAS_CATEGORY_FALSE',
      },
      {
        field: 'type',
        operator: 'eq',
        value: 'PENILAIAN_KINERJA_TYPE_QUANTITATIVE',
      },
      {
        field: 'evaluatedRole',
        operator: 'eq',
        value:
          evaluatedFilterRole[selectedEvaluatedRole] ||
          'PENILAIAN_KINERJA_EVALUATED_ROLE_ALL',
      },
    ],
    queryOptions: {
      enabled: !!modalProps.visible && !!selectedEvaluatedRole,
    },
    defaultValueQueryOptions: {
      enabled: false,
    },
  })

  const isEditMode = mode === 'edit'

  const initialPenilaianKinerjaIds =
    formProps?.initialValues?.data?.penilaianKinerjas.map(
      ({ id, weight }: PenilaianKinerjaField) => ({ id, weight })
    ) || []

  let penilaianKinerjaSelectOptions: SelectProps['options'] =
    selectProps.options || []

  if (selectedEvaluatedRole === formProps?.initialValues?.data?.evaluatedRole) {
    penilaianKinerjaSelectOptions = (
      formProps?.initialValues?.data?.penilaianKinerjas.map(
        ({ id, name }: PenilaianKinerjaDetail) => ({
          value: id,
          label: name,
        })
      ) || []
    ).concat(penilaianKinerjaSelectOptions)
  }

  return (
    <Modal
      {...modalProps}
      onCancel={onClose}
      title={
        isEditMode
          ? 'Edit Kategori Penilaian Kinerja'
          : 'Buat Kategori Penilaian Kinerja'
      }
      cancelText="Batal"
      okText="Simpan"
      afterClose={() => {
        formProps.form?.resetFields()
        formProps.form?.setFields([{ name: 'penilaianKinerjaIds', value: [] }])
      }}
      bodyStyle={{ maxHeight: 700, overflow: 'auto' }}
    >
      <Form
        {...formProps}
        initialValues={{
          ...formProps?.initialValues?.data,
          penilaianKinerjaIds: initialPenilaianKinerjaIds,
        }}
        layout="vertical"
        validateMessages={{ required: '${label} harus diisi.' }}
        onFinish={(data) => {
          formProps.onFinish?.(data)
        }}
      >
        <Form.Item
          label="Nama Kategori"
          name="name"
          required
          rules={[{ required: true }]}
        >
          <Input autoComplete="off" />
        </Form.Item>
        <Form.Item
          label="Role yang Dinilai"
          name="evaluatedRole"
          required
          rules={[{ required: true }]}
          getValueFromEvent={(value) => {
            formProps.form?.setFields([
              { name: 'penilaianKinerjaIds', value: [] },
            ])
            return value
          }}
        >
          <Select options={evaluatedRole} showSearch={false} />
        </Form.Item>
        <Form.Item
          label="Daftar penilaian kinerja"
          help={
            !selectedEvaluatedRole
              ? 'Pilih role yang dinilai terlebih dulu.'
              : undefined
          }
          required
        >
          <Form.List
            name="penilaianKinerjaIds"
            rules={[
              {
                validator(_, value) {
                  if (!value?.length)
                    return Promise.reject(
                      'Daftar penilaian kinerja tidak boleh kosong.'
                    )
                  return Promise.resolve()
                },
              },
              {
                validator(_, value) {
                  const totalWeight = value?.reduce?.(
                    (
                      total: number,
                      current?: { role: string; weight: number }
                    ) => total + (current?.weight || 0),
                    0
                  )
                  if (totalWeight > 100)
                    return Promise.reject(
                      'Total bobot tidak boleh lebih dari 100.'
                    )
                  return Promise.resolve()
                },
              },
            ]}
          >
            {(fields, { add, remove }, { errors }) => {
              return (
                <>
                  {fields.map(({ key, name, ...rest }) => {
                    return (
                      <Space
                        key={key}
                        className="flex gap-2 w-full !items-start mb-2 last-of-type:mb-3"
                      >
                        <Form.Item
                          {...rest}
                          name={[name, 'id']}
                          rules={[
                            {
                              required: true,
                              message: 'Penilaian kinerja harus dipilih',
                            },
                          ]}
                          help="Penilaian kinerja"
                        >
                          <Select
                            {...selectProps}
                            options={penilaianKinerjaSelectOptions?.map(
                              (val) => ({
                                ...val,
                                disabled: selectedPenilaianKerjaIds?.some(
                                  (value: PenilaianKinerjaField) =>
                                    value?.id == val.value
                                ),
                              })
                            )}
                            showSearch={false}
                            placeholder={`Pilih penilaian kinerja`}
                            className="!w-[220px]"
                          />
                        </Form.Item>
                        <Form.Item
                          {...rest}
                          name={[name, 'weight']}
                          help="Bobot"
                        >
                          <InputNumber
                            precision={2}
                            max={100}
                            min={0}
                            controls={false}
                            addonAfter="%"
                          />
                        </Form.Item>
                        <Button
                          icon={<Icons.MinusCircleOutlined />}
                          danger
                          type="dashed"
                          className="ml-2"
                          onClick={() => remove(name)}
                        />
                      </Space>
                    )
                  })}
                  <div className="w-full">
                    <Button
                      type="dashed"
                      onClick={() => add({ weight: 0 })}
                      icon={<Icons.PlusOutlined />}
                      disabled={!selectedEvaluatedRole}
                    >
                      Tambah penilaian kinerja
                    </Button>
                    <Form.ErrorList errors={errors} />
                  </div>
                </>
              )
            }}
          </Form.List>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default ModalForm
