import { useNode, Node } from '@craftjs/core'
import React from 'react'
import clsx from 'clsx'

import { Text } from '@wartek-id/text'

import { ContainerCustomProps, WIDGET_NAME } from '../../schema'

/** Base widget without craftjs */
export const BaseWidget = React.forwardRef<
  HTMLDivElement,
  { className?: string }
>(({ children, className }, ref) => (
  <div
    ref={ref}
    className={clsx(className, 'px-3 py-4 mb-3 bg-surface-default last:mb-0')}
  >
    {!children && (
      <Text
        color="informational"
        className="italic mb-0 text-center border-dashed p-4"
      >
        empty container
      </Text>
    )}
    {children}
  </div>
))
BaseWidget.displayName = 'Container'

const Container = (props: Record<string, any>) => {
  const {
    actions: { setCustom },
    connectors: { connect, drag },
    isHovered,
    isError,
  } = useNode((node) => ({
    isSelected: node.events.selected,
    isHovered: node.events.hovered,
    isError: node.data.custom.errors?.length > 0 || 0,
  }))

  if (isHovered && isError) {
    setCustom(
      (custom: { errors: { label: string; message: string }[] }) =>
        (custom.errors = [])
    )
  }

  return (
    <BaseWidget
      ref={(ref) => connect(drag(ref!))}
      className={clsx(
        'widget-component widget-component--container',
        'last:mb-0'
      )}
      {...props}
    />
  )
}

Container.craft = {
  rules: {
    canDrop: (targetNode: Node) => {
      return targetNode.data.name === WIDGET_NAME.ContentWrapper
    },
  },
  custom: ContainerCustomProps,
}

export { Container }
