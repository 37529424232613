import {
  Button,
  Drawer,
  Icons,
  Popconfirm,
  Space,
  Table,
  TableColumnGroupType,
  TableColumnType,
  Tag,
  useModalForm,
  useTable,
} from '@pankod/refine-antd'
import React, { useCallback, useState } from 'react'
import { useDelete } from '@pankod/refine-core'
import dayjs from 'dayjs'

import { PeriodePenilaianRequest, PeriodePenilaianResponse } from './types'
import { TCommonError, TLMSCommonError } from 'src/interfaces/common'
import { showErrorNotification } from '@resources/angkatan-ppg-management/utils'
import { secondToMinuteText } from '@resources/lms-management/program/utils'
import ModalForm from './ModalForm'

export const usePeriodePenilaianDrawer = () => {
  const [showId, setShowId] = useState<{
    show: boolean
    programId: string | number
    programName: string
  }>({ show: false, programId: '', programName: '' })
  const onClose = useCallback(() => {
    setShowId((state) => ({
      ...state,
      show: false,
    }))
  }, [])

  return {
    show: (id: number | string, name: string) => {
      setShowId({ show: true, programId: id, programName: name })
    },
    visible: !!showId.show,
    id: showId.programId,
    name: showId.programName,
    onClose,
  }
}

type PeriodePenilaianDrawerProps = {
  visible: boolean
  id: number | string
  onClose: () => void
  name: string
}

const PeriodePenilaianDrawer = ({
  name,
  id,
  onClose,
  visible,
}: PeriodePenilaianDrawerProps) => {
  const { mutateAsync: doDelete } = useDelete()
  const { tableProps } = useTable<PeriodePenilaianResponse, TCommonError>({
    resource: `programs/${id}/periode-penilaian`,
    dataProviderName: 'lms',
    queryOptions: { enabled: visible && !!id },
  })

  const {
    modalProps: createModalProps,
    formProps: createFormProps,
    show: createShow,
    close: createClose,
  } = useModalForm<
    PeriodePenilaianResponse,
    TLMSCommonError,
    PeriodePenilaianRequest
  >({
    action: 'create',
    resource: `programs/${id}/periode-penilaian`,
    dataProviderName: 'lms',
    autoResetForm: true,
    redirect: false,
    successNotification: {
      message: 'Periode penilaian berhasil dibuat',
      type: 'success',
      description: 'Sukses',
    },
    errorNotification: (error) =>
      showErrorNotification(
        error,
        'There was a problem when creating periode penilaian'
      ),
  })

  const {
    modalProps: editModalProps,
    formProps: editFormProps,
    show: editShow,
    close: editClose,
  } = useModalForm<
    PeriodePenilaianResponse,
    TLMSCommonError,
    PeriodePenilaianRequest
  >({
    action: 'edit',
    resource: `programs/${id}/periode-penilaian`,
    dataProviderName: 'lms',
    autoResetForm: true,
    redirect: false,
    successNotification: {
      message: 'Periode penilaian berhasil dibuat',
      type: 'success',
      description: 'Sukses',
    },
    errorNotification: (error) =>
      showErrorNotification(
        error,
        'There was a problem when updating periode penilaian'
      ),
  })

  const columns: (
    | TableColumnType<PeriodePenilaianResponse>
    | TableColumnGroupType<PeriodePenilaianResponse>
  )[] = [
    { title: 'ID', dataIndex: 'id', key: '_id' },
    { title: 'Nama Periode', dataIndex: 'name', key: '_name' },
    {
      title: 'Tanggal Periode',
      children: [
        {
          title: 'Mulai',
          dataIndex: 'startAt',
          render: (value) => dayjs(value).format('DD MMM YYYY'),
        },
        {
          title: 'Akhir',
          dataIndex: 'endAt',
          render: (value) => dayjs(value).format('DD MMM YYYY'),
        },
      ],
    },
    {
      title: 'Durasi Asinkronus (Async)',
      dataIndex: 'asyncDuration',
      render: (value) => secondToMinuteText(value),
    },
    {
      title: 'Durasi Sinkronus (Sync)',
      dataIndex: 'syncDuration',
      render: (value) => secondToMinuteText(value),
    },
    {
      title: 'Modul',
      dataIndex: ['modules'],
      width: '300px',
      render: (value: PeriodePenilaianResponse['modules']) => (
        <Space wrap size={'small'}>
          {value.map(({ id, name }) => (
            <Tag key={id}>{name}</Tag>
          ))}
        </Space>
      ),
    },
    {
      key: 'action',
      render: (_, record) => (
        <Space size="small" direction="vertical">
          <Button
            icon={<Icons.EditOutlined />}
            onClick={() => editShow(record.id)}
            size="small"
            block
          >
            Ubah
          </Button>
          <Popconfirm
            title="Apakah Anda yakin ingin menghapus periode penilaian ini?"
            okText="Hapus"
            cancelText="Batal"
            okButtonProps={{ danger: true }}
            placement="topRight"
            zIndex={1000}
            onConfirm={async () =>
              doDelete({
                id: record.id,
                resource: `programs/${id}/periode-penilaian`,
                dataProviderName: 'lms',
                invalidates: ['list'],
                successNotification: {
                  message: 'Periode penilaian berhasil dihapus',
                  type: 'success',
                  description: 'Sukses',
                },
                errorNotification: (error: any) =>
                  showErrorNotification(
                    error,
                    'There was a problem when removing periode penilaian'
                  ),
              })
            }
          >
            <Button size="small" block danger icon={<Icons.DeleteOutlined />}>
              Hapus
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ]

  return (
    <Drawer
      title={`Daftar Periode Penilaian | ${name}`}
      visible={visible}
      onClose={onClose}
      destroyOnClose
      width="70%"
      extra={
        <Space>
          <Button
            type="primary"
            icon={<Icons.PlusOutlined />}
            onClick={() => {
              createShow()
            }}
          >
            Tambah Periode
          </Button>
        </Space>
      }
    >
      <Table {...tableProps} columns={columns} rowKey="id" bordered />
      <ModalForm
        formProps={createFormProps}
        modalProps={createModalProps}
        mode="create"
        onClose={createClose}
      />
      <ModalForm
        formProps={editFormProps}
        modalProps={editModalProps}
        mode="edit"
        onClose={editClose}
      />
    </Drawer>
  )
}

export default PeriodePenilaianDrawer
