import {
  Button,
  Descriptions,
  Modal,
  Popconfirm,
  Space,
  Steps,
  TextField,
  Tooltip,
} from '@pankod/refine-antd'
import { useCreate, useGetIdentity } from '@pankod/refine-core'
import React, { useCallback, useState } from 'react'

import LocalProgramSelect from './LocalProgramSelect'
import ClassSelect from './ClassSelect'
import SummarySection from './SummarySection'
import { showErrorNotification } from '@resources/angkatan-ppg-management/utils'
import { successMigrateNotification } from '../utils'
import ClassGroupSelect from './ClassGroupSelect'

const defaultState = {
  localProgramId: undefined,
  programId: undefined,
  classId: undefined,
  userId: undefined,
  userName: undefined,
  userEmail: undefined,
  lmsRole: '',
}

type UseActorMigrationModalParams = {
  localProgramId: string
  programId: string
  classId: string
}

type UseActorMigrationModalState = {
  localProgramId?: string
  programId?: string
  classId?: string
  userId?: string
  userName?: string
  userEmail?: string
  userRole?: string
  lmsRole: string
}

type UseActorMigrationModalReturn = {
  show: (data: {
    userId: string
    userName: string
    userEmail: string
    userRole: string
  }) => void
  onClose: () => void
  id: UseActorMigrationModalState
  visible: boolean
}

export const useActorMigrationModal = (
  params: UseActorMigrationModalParams
): UseActorMigrationModalReturn => {
  const [id, setId] = useState<UseActorMigrationModalState>({
    ...defaultState,
    ...params,
  })

  useGetIdentity({
    queryOptions: {
      onSuccess: (data) => {
        const lmsRole = data?.user?.LMSRole || ''
        setId((state) => ({ ...state, lmsRole }))
      },
    },
  })

  const onClose = useCallback(() => {
    setId((state) => ({ ...state, userId: undefined }))
  }, [])

  const show = useCallback((data) => {
    setId((state) => ({ ...state, ...data }))
  }, [])

  return {
    show,
    onClose,
    id,
    visible: !!id.userId,
  }
}

const ALLOWED_CLASS_GROUP_STEP_ROLE = ['asisten', 'peserta']

type ActorMigrationModalProps = Omit<UseActorMigrationModalReturn, 'show'> & {
  initialLocalProgram?: {
    id: string
    name: string
  }
  initialClass?: {
    id: string
    name: string
  }
  onMigrationSettled: () => void
}
const ActorMigrationModal = ({
  onClose,
  visible,
  id,
  initialLocalProgram,
  initialClass,
  onMigrationSettled,
}: ActorMigrationModalProps) => {
  const [targetLocalProgram, setTargetLocalProgram] = useState<{
    id?: string
    name?: string
  }>({
    id: undefined,
    name: undefined,
  })
  const [targetClass, setTargetClass] = useState<{
    id?: string
    name?: string
  }>({
    id: undefined,
    name: undefined,
  })
  const [targetClassGroup, setTargetClassGroup] = useState<{
    id?: string
    name?: string
  }>({
    id: undefined,
    name: undefined,
  })
  const [current, setCurrent] = useState(0)
  const { mutateAsync: doSubmit } = useCreate()

  const next = () => {
    setCurrent(current + 1)
  }

  const prev = () => {
    setCurrent(current - 1)
  }

  const steps = [
    ...(id.lmsRole !== 'admin-entitas'
      ? [
          {
            title: 'Tujuan Program Lokal',
            content: (
              <LocalProgramSelect
                programId={id.programId}
                selected={targetLocalProgram}
                onSelect={setTargetLocalProgram}
                initialLocalProgram={initialLocalProgram}
              />
            ),
            key: 'localProgramStep',
            description: targetLocalProgram.name,
          },
        ]
      : []),
    {
      title: 'Tujuan Kelas',
      content: (
        <ClassSelect
          programId={id.programId}
          localProgramId={
            id.lmsRole !== 'admin-entitas'
              ? targetLocalProgram.id
              : initialLocalProgram?.id
          }
          selected={targetClass}
          onSelect={setTargetClass}
          classId={id.classId}
        />
      ),
      key: 'classStep',
      description: targetClass.name,
    },
    ...(ALLOWED_CLASS_GROUP_STEP_ROLE.includes(id.userRole || '')
      ? [
          {
            title: 'Tujuan Kelompok Murid',
            content: (
              <ClassGroupSelect
                localProgramId={
                  id.lmsRole !== 'admin-entitas'
                    ? targetLocalProgram.id
                    : initialLocalProgram?.id
                }
                selected={targetClassGroup}
                onSelect={setTargetClassGroup}
                classId={targetClass.id}
              />
            ),
            key: 'classGroupStep',
            description: targetClassGroup.name,
          },
        ]
      : []),
    {
      title: 'Ringkasan',
      content: (
        <SummarySection
          origin={{
            localProgramName: initialLocalProgram?.name,
            className: initialClass?.name,
          }}
          targetClass={targetClass}
          showProgramLocal={id.lmsRole !== 'admin-entitas'}
          targetLocalProgram={targetLocalProgram}
          targetClassGroup={targetClassGroup}
          userEmail={id.userEmail}
          userName={id.userName}
        />
      ),
      key: 'summaryStep',
      description: '\n',
    },
  ]

  const handleClose = () => {
    setCurrent(0)
    setTargetClass({ id: undefined, name: undefined })
    setTargetClassGroup({ id: undefined, name: undefined })
    setTargetLocalProgram({ id: undefined, name: undefined })
    onClose()
  }

  const handleSubmit = async () => {
    return doSubmit(
      {
        resource: `programs/${id.programId}/local-programs/${id.localProgramId}/classes/${id.classId}/participants/migrate`,
        dataProviderName: 'lms',
        values: {
          userId: id.userId,
          destinationLocalProgramId: Number(targetLocalProgram.id),
          destinationClassId: Number(targetClass.id),
          destinationGroupId: targetClassGroup?.id
            ? Number(targetClassGroup.id)
            : undefined,
        },
        successNotification: false,
        errorNotification: (err) =>
          showErrorNotification(
            err,
            'Terdapat gangguan saat memindahkan peserta kelas'
          ),
      },
      {
        onSuccess: () => {
          successMigrateNotification({ type: 'move', programId: id.programId })
        },
        onSettled: () => {
          onMigrationSettled()
          handleClose()
        },
      }
    )
  }

  const cancelButtonProps = {
    onClick: () => {
      if (steps[current].key === 'classGroupStep') {
        current > 0 ? prev() : onClose()
        setTargetClassGroup({ id: undefined, name: undefined })
        return
      }
      if (steps[current].key === 'classStep') {
        current > 0 ? prev() : onClose()
        setTargetClass({ id: undefined, name: undefined })
        return
      }
      if (steps[current].key === 'localProgramStep') {
        onClose()
        setTargetLocalProgram({ id: undefined, name: undefined })
        return
      }

      prev()
    },
  }

  const okButtonProps = {
    onClick: () => {
      if (
        steps[current].key === 'classStep' &&
        !targetLocalProgram.id &&
        initialLocalProgram
      ) {
        setTargetLocalProgram(initialLocalProgram)
      }

      if (current < steps.length - 1) {
        next()
        return
      }
    },
    disabled:
      (steps[current].key === 'classStep' && !targetClass.id) ||
      (steps[current].key === 'localProgramStep' && !targetLocalProgram.id),
  }

  return (
    <Modal
      visible={visible}
      onCancel={handleClose}
      maskClosable={false}
      destroyOnClose
      width={'1000px'}
      title="Pindah Peserta Kelas"
      footer={
        <Space>
          <Button {...cancelButtonProps} key={`${current}-cancelButton`}>
            {(() => {
              if (current > 0) return 'Kembali'
              return 'Batal'
            })()}
          </Button>
          <Popconfirm
            disabled={steps[current].key !== 'summaryStep'}
            title="Apakah Anda yakin ingin menyimpan perpindahan peserta kelas ini?"
            onConfirm={handleSubmit}
            okText="Ya"
            cancelText="Tidak"
          >
            <Button
              {...okButtonProps}
              type="primary"
              key={`${current}-okButton`}
            >
              {(() => {
                if (steps[current].key !== 'summaryStep') return 'Selanjutnya'
                return 'Simpan'
              })()}
            </Button>
          </Popconfirm>
        </Space>
      }
    >
      <Steps current={current} className="!mb-8">
        {steps.map((item, i) => (
          <Steps.Step
            key={item.key}
            title={item.title}
            description={
              item.description ? (
                <Tooltip title={item.description} placement="bottom">
                  <TextField
                    ellipsis
                    className="select-none"
                    value={item.description}
                    type={current === i ? undefined : 'secondary'}
                  />
                </Tooltip>
              ) : (
                <i>Kosong</i>
              )
            }
          />
        ))}
      </Steps>
      {steps[current].key !== 'summaryStep' && (
        <Descriptions bordered layout="vertical" className="mb-6" size="small">
          <Descriptions.Item label="Nama Peserta">
            {id.userName}
          </Descriptions.Item>
          <Descriptions.Item label="Email Peserta">
            {id.userEmail}
          </Descriptions.Item>
        </Descriptions>
      )}
      <div className="steps-content">{steps[current].content}</div>
    </Modal>
  )
}

export default ActorMigrationModal
