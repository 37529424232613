import {
  Button,
  useSelect,
  Divider,
  Form,
  Input,
  Select,
  Icons,
  Spin,
} from '@pankod/refine-antd'
import { useList, useUpdate } from '@pankod/refine-core'
import React from 'react'

import { DrawerManagePesertaKelasDasus } from './DrawerManagePeserta'

export type FormBulkClassFasilAssignProps = {
  dasusProgramId: string | number
  dasusProgramLocalId: string | number
}

type classDrawerState = {
  visible: boolean
  classId: string | number
  className: string
}

export const FormBulkClassFasilAssign = (
  props: FormBulkClassFasilAssignProps
) => {
  const [formClassFasil] = Form.useForm()
  const { dasusProgramId, dasusProgramLocalId } = props

  const [selectedClassDrawer, setSelectedClassDrawer] =
    React.useState<classDrawerState>({
      visible: false,
      classId: '',
      className: '',
    })

  const { mutateAsync: updateClassFasil } = useUpdate()

  const CLASS_RESOURCE = `program-dasus/${dasusProgramId}/local-programs/${dasusProgramLocalId}/facilitators`
  const { data, isLoading } = useList({
    resource: CLASS_RESOURCE,
    dataProviderName: 'lms',
    queryOptions: {
      enabled: !!dasusProgramId,
    },
  })

  const classTemplateData = data?.data || []

  const { selectProps: fasilSelectProps } = useSelect({
    resource: `programs/${dasusProgramId}/participants`,
    optionLabel: 'email',
    optionValue: 'email',

    filters: [
      {
        field: 'pageSize',
        operator: 'eq',
        value: -1,
      },
      {
        field: 'kapabilitas',
        operator: 'eq',
        value: 'instruktur',
      },
    ],
  })

  const handleApplyClassFasilAssign = (values: any) => {
    updateClassFasil({
      resource: `program-dasus/${dasusProgramId}/local-programs/${dasusProgramLocalId}`,
      id: 'facilitators',
      values: values,
      dataProviderName: 'lms',
      successNotification: (e: any) => {
        return {
          message: 'Success',
          description: e.data.message,
          type: 'success',
        }
      },
      errorNotification: (errorRes: any) => {
        const responseErr = errorRes?.response?.data?.error?.message || ''

        return {
          message: responseErr,
          description: 'Failed',
          type: 'error',
        }
      },
    })
  }

  if (isLoading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <Spin />
      </div>
    )
  }

  return (
    <Form
      form={formClassFasil}
      layout="horizontal"
      onFinish={handleApplyClassFasilAssign}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 18 }}
      initialValues={{
        classes: classTemplateData,
      }}
    >
      <DrawerManagePesertaKelasDasus
        visible={selectedClassDrawer.visible}
        onClose={() => {
          setSelectedClassDrawer({
            visible: false,
            classId: '',
            className: '',
          })
        }}
        programLocalClassId={selectedClassDrawer.classId}
        programId={dasusProgramId}
        programLocalId={dasusProgramLocalId}
        className={selectedClassDrawer.className}
      />

      {classTemplateData.map((classData, classIndex) => (
        <div key={classData.id}>
          <div className=" flex-row justify-between hidden">
            <h2>{classData.name}</h2>
            <Button
              icon={<Icons.EditOutlined />}
              onClick={() => {
                setSelectedClassDrawer({
                  visible: true,
                  classId: classData.id as string,
                  className: classData.name as string,
                })
              }}
            >
              Manage Peserta Kelas
            </Button>
          </div>
          <h3>PTM Data</h3>
          <Form.Item hidden name={['classes', classIndex, 'id']}>
            <Input />
          </Form.Item>
          <Form.Item hidden name={['classes', classIndex, 'name']}>
            <Input />
          </Form.Item>

          {classData.ptmList.map((ptm: any, ptmIndex: any) => (
            <>
              <Form.Item
                key={`${classIndex}-PTM-${ptmIndex}`}
                label={ptm.name}
                name={['classes', classIndex, 'ptmList', ptmIndex, 'email']}
                rules={[{ required: true }]}
              >
                <Select
                  {...fasilSelectProps}
                  filterOption={(input, option) =>
                    (option!.label as unknown as string)
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                />
              </Form.Item>
              <Form.Item
                key={`${classIndex}-PTM-${ptmIndex}`}
                label={'additional'}
                name={[
                  'classes',
                  classIndex,
                  'ptmList',
                  ptmIndex,
                  'additionalFasil',
                ]}
              >
                <Select
                  mode="multiple"
                  {...fasilSelectProps}
                  filterOption={(input, option) =>
                    (option!.label as unknown as string)
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  placeholder={`Additional Fasil for ${ptm.name}`}
                />
              </Form.Item>
              <Form.Item
                hidden
                name={['classes', classIndex, 'ptmList', ptmIndex, 'id']}
              >
                <Input />
              </Form.Item>
              <Form.Item
                hidden
                name={['classes', classIndex, 'ptmList', ptmIndex, 'name']}
              >
                <Input />
              </Form.Item>

              <Form.Item
                hidden
                name={['classes', classIndex, 'ptmList', ptmIndex, 'startDate']}
              >
                <Input />
              </Form.Item>

              <Form.Item
                hidden
                name={['classes', classIndex, 'ptmList', ptmIndex, 'endDate']}
              >
                <Input />
              </Form.Item>

              <Form.Item
                hidden
                name={[
                  'classes',
                  classIndex,
                  'ptmList',
                  ptmIndex,
                  'dateRanges',
                ]}
              >
                <Input />
              </Form.Item>
            </>
          ))}
          <h3>FI Data</h3>
          {classData.fiList.map((fi: any, fiIndex: any) => (
            <>
              <Form.Item
                key={`${classIndex}-FI-${fiIndex}`}
                label={fi.name}
                name={['classes', classIndex, 'fiList', fiIndex, 'email']}
                rules={[{ required: true }]}
              >
                <Select
                  {...fasilSelectProps}
                  filterOption={(input, option) =>
                    (option!.label as unknown as string)
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  onSearch={() => {}}
                />
                {/* <Input /> */}
              </Form.Item>
              <Form.Item
                key={`${classIndex}-FI-${fiIndex}`}
                name={[
                  'classes',
                  classIndex,
                  'fiList',
                  fiIndex,
                  'additionalFasil',
                ]}
                label={'additional'}
              >
                <Select
                  mode="multiple"
                  {...fasilSelectProps}
                  filterOption={(input, option) =>
                    (option!.label as unknown as string)
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  onSearch={() => {}}
                  placeholder={`Additional Fasil for ${fi.name}`}
                />
                {/* <Input /> */}
              </Form.Item>

              <Form.Item
                hidden
                name={['classes', classIndex, 'fiList', fiIndex, 'id']}
              >
                <Input />
              </Form.Item>
              <Form.Item
                hidden
                name={['classes', classIndex, 'fiList', fiIndex, 'name']}
              >
                <Input />
              </Form.Item>
            </>
          ))}
          <Divider />
        </div>
      ))}

      <Button
        type="primary"
        htmlType="submit"
        // onClick={() => {
        //   console.log('submit')
        // }}
        block
      >
        Submit
      </Button>
    </Form>
  )
}
