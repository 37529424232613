import {
  Descriptions,
  Button,
  Icons,
  Table,
  TextField,
  Space,
  EditButton,
  useTable,
  useModalForm,
} from '@pankod/refine-antd'
import React from 'react'
import { useCan, useDelete } from '@pankod/refine-core'

import { PopDeleteConfirm } from '@resources/lms-management/components/PopDeleteConfirm'
import ShowClassButton from './ShowKelasButton'
import { TLMSClass, TLMSClassRequest } from '../types'
import { TCommonError } from 'src/interfaces/common'
import { ModalForm } from './ModalForm'
import { PROGRAM_LOCAL_STATE } from '@resources/lms-management/program-lokal/types'
import { showErrorNotification } from '@resources/angkatan-ppg-management/utils'

type TClassTableListProps = {
  programId?: number
  programLocalId?: number
  hideTitle?: boolean
  programLocalStatus?: PROGRAM_LOCAL_STATE
}
export const TableClassList = ({
  hideTitle = false,
  programId,
  programLocalId,
  programLocalStatus,
}: TClassTableListProps) => {
  const CLASS_RESOURCE = `programs/${programId}/local-programs/${programLocalId}/classes`
  const { tableProps } = useTable<TLMSClass, TCommonError>({
    resource: CLASS_RESOURCE,
    dataProviderName: 'lms',
    queryOptions: {
      enabled: !!programId,
    },
  })

  const { mutateAsync } = useDelete()

  const {
    modalProps: createModalProps,
    formProps: createFormProps,
    show: createShow,
    close: createClose,
  } = useModalForm<TLMSClass, TCommonError, TLMSClassRequest>({
    action: 'create',
    resource: CLASS_RESOURCE,
    dataProviderName: 'lms',
    autoResetForm: true,
    redirect: false,
    successNotification: {
      message: 'Kelas successfully created',
      type: 'success',
      description: 'Successful',
    },
    errorNotification: (err) =>
      showErrorNotification(err, 'There was an error when creating Kelas'),
  })

  const {
    modalProps: editModalProps,
    formProps: editFormProps,
    show: editShow,
    close: editClose,
  } = useModalForm<TLMSClass, TCommonError, TLMSClassRequest>({
    action: 'edit',
    resource: CLASS_RESOURCE,
    dataProviderName: 'lms',
    autoResetForm: true,
    redirect: false,
    successNotification: {
      message: 'Kelas successfully updated',
      type: 'success',
      description: 'Successful',
    },
    errorNotification: (err) =>
      showErrorNotification(err, 'There was an error when updating Kelas'),
  })

  const { data: canEdit } = useCan({
    action: 'edit',
    resource: 'lms-class-management',
  })

  const { data: canDelete } = useCan({
    action: 'delete',
    resource: 'lms-class-management',
  })

  const { data: canCreate } = useCan({
    action: 'create',
    resource: 'lms-class-management',
  })

  const disableEditAction =
    !canEdit?.can || programLocalStatus === PROGRAM_LOCAL_STATE.PUBLISHED

  const disableDeleteAction =
    !canDelete?.can || programLocalStatus === PROGRAM_LOCAL_STATE.PUBLISHED

  const disableCreateAction =
    !canCreate?.can || programLocalStatus === PROGRAM_LOCAL_STATE.PUBLISHED

  return (
    <>
      <Descriptions
        title={hideTitle ? null : 'Kelas'}
        extra={
          <Button
            disabled={disableCreateAction}
            onClick={() => createShow()}
            icon={<Icons.PlusOutlined />}
          >
            New Kelas
          </Button>
        }
      >
        <Descriptions.Item span={1}>
          <Table {...tableProps} rowKey="id" className="w-full">
            <Table.Column
              dataIndex="id"
              title="ID"
              render={(value) => <TextField value={value} />}
            />
            <Table.Column
              dataIndex="name"
              title="Name"
              render={(value) => <TextField value={value} />}
            />
            <Table.Column<TLMSClass>
              title="Actions"
              render={(_, record) => (
                <Space>
                  <ShowClassButton
                    block
                    size="small"
                    recordItemId={record.id}
                    programId={programId}
                    programLocalId={programLocalId}
                  />
                  <EditButton
                    size="small"
                    recordItemId={record.id}
                    disabled={disableEditAction}
                    onClick={() => {
                      editShow(record.id)
                    }}
                  />
                  <PopDeleteConfirm
                    title={`Are you sure want to delete Kelas "${record.name}"?`}
                    placeholder="Tulis ulang nama Kelas"
                    onConfirm={() =>
                      mutateAsync({
                        id: record.id,
                        resource: CLASS_RESOURCE,
                        dataProviderName: 'lms',
                      })
                    }
                    okButtonProps={{
                      danger: true,
                      type: 'default',
                    }}
                    okText="Delete"
                    validateValue={record.name}
                    errorMessage="Kelas name did not match"
                    disabled={disableDeleteAction}
                  >
                    <Button
                      size="small"
                      danger
                      block
                      disabled={disableDeleteAction}
                      icon={<Icons.DeleteOutlined />}
                    >
                      Delete
                    </Button>
                  </PopDeleteConfirm>
                </Space>
              )}
            />
          </Table>
        </Descriptions.Item>
      </Descriptions>

      <ModalForm
        formProps={createFormProps}
        modalProps={createModalProps}
        mode="create"
        onClose={() => {
          createFormProps.form?.resetFields()
          createClose()
        }}
      />

      <ModalForm
        formProps={editFormProps}
        modalProps={editModalProps}
        mode="edit"
        onClose={() => {
          editFormProps.form?.resetFields()
          editClose()
        }}
      />
    </>
  )
}
