import { Button, Icons } from '@pankod/refine-antd'
import React from 'react'
import { useCustom } from '@pankod/refine-core'

import { downloadTemplate } from './ModalParseCSV/generateCSVTemplate'

type ExportReportButtonProps = {
  url: string
  fileName: string
  title?: string
  prefixFileName?: string
  can?: boolean
  size?: 'small' | 'middle'
}

const ExportReportButton = ({
  can = false,
  fileName,
  prefixFileName = 'lms',
  url,
  title = 'Ekspor Laporan',
  size,
}: ExportReportButtonProps) => {
  const { refetch: fetchReport, isFetching } = useCustom<string>({
    url,
    dataProviderName: 'lms',
    method: 'get',
    queryOptions: {
      enabled: false,
    },
  })
  return (
    <>
      {can && (
        <Button
          icon={<Icons.ExportOutlined />}
          size={size}
          type="dashed"
          loading={isFetching}
          download
          onClick={async () => {
            const { data } = await fetchReport()
            const csv = data?.data
            if (!csv) return
            downloadTemplate(csv, fileName, prefixFileName)
          }}
        >
          {title}
        </Button>
      )}
    </>
  )
}

export default ExportReportButton
