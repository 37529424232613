import {
  useTable,
  useTableProps,
  useTableReturnType,
} from '@pankod/refine-antd'
import { BaseRecord, HttpError } from '@pankod/refine-core'

type TableQueryResultWithoutData = Omit<
  useTableReturnType['tableQueryResult'],
  'data'
>

type TableQueryResultMeta<
  TData extends BaseRecord = BaseRecord,
  TMeta = {}
> = TableQueryResultWithoutData & {
  data?: {
    data: TData[]
    total: any
    meta: TMeta & {
      currentPage: number
      pageSize: number
      totalPages: number
      totalItems: number
    }
  }
}

const useTableWithMeta = <
  TData extends BaseRecord = BaseRecord,
  TError extends HttpError = HttpError,
  TSearchVariables = unknown,
  TMeta = {}
>(
  props: useTableProps<TData, TError, TSearchVariables>
) => {
  const { tableQueryResult, tableProps, setFilters, filters, ...rest } =
    useTable(props)
  const tableQueryResultMeta: TableQueryResultMeta<TData, TMeta> =
    tableQueryResult as TableQueryResultMeta<TData, TMeta>

  tableProps.pagination = {
    current: tableQueryResultMeta.data?.meta?.currentPage || 1,
    pageSize: tableQueryResultMeta.data?.meta?.pageSize || 10,
    total:
      tableQueryResultMeta.data?.meta?.totalItems ||
      tableQueryResultMeta.data?.data?.length,
    onChange(page, pageSize) {
      setFilters([
        {
          field: 'page',
          operator: 'eq',
          value: page,
        },
        {
          field: 'pageSize',
          operator: 'eq',
          value: pageSize,
        },
        ...(filters || []),
      ])
    },
  }
  return {
    tableQueryResult: tableQueryResultMeta,
    tableProps,
    setFilters,
    filters,
    ...rest,
  }
}

export default useTableWithMeta
