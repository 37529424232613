import {
  Button,
  Descriptions,
  Divider,
  Icons,
  Popconfirm,
  Tabs,
} from '@pankod/refine-antd'
import React from 'react'
import { useList } from '@pankod/refine-core'

// import { TabContentDasusBahanAjar } from './TabContentDasusBahanAjar'
// import { TabContentDasusFI } from './TabContentDasusFI'
import { TabContentConfigPTMFI } from './TabContentConfigPTMFI'
import { DrawerSelector } from './form-configuration/DrawerSelector'

export type TabProgramConfigurationProps = {
  dasusProgramId: string | number
  isReadOnly?: boolean
  onFinalize: (value: any) => void
}

type DrawerState = {
  visible: boolean
  type: string
}

export const TabProgramConfiguration = (
  props: TabProgramConfigurationProps
) => {
  const { dasusProgramId, isReadOnly, onFinalize } = props
  const [selectedDrawer, setSelectedDrawer] = React.useState<DrawerState>({
    visible: false,
    type: '',
  })

  const [tempTemplateCourseList, setTempTemplateCourseList] = React.useState({
    // bahanAjar: [],
    fiData: [],
    ptmData: [],
  })

  const handleShowDrawer = (type: string) =>
    setSelectedDrawer({
      visible: true,
      type: type,
    })

  const handleCloseDrawer = () =>
    setSelectedDrawer({
      visible: false,
      type: '',
    })

  const { isLoading } = useList({
    resource: `program-dasus/${dasusProgramId}/template-course`,
    dataProviderName: 'lms',
    queryOptions: {
      onSuccess(data: any) {
        setTempTemplateCourseList(data?.data)
      },
    },
  })

  // const handleApplyBahanAjarChanges = (values: any) => {
  //   setTempTemplateCourseList({
  //     ...tempTemplateCourseList,
  //     bahanAjar: values,
  //   })
  // }

  const handleFinalizeDasusTemplateForm = () => {
    const tempPayload = { ...tempTemplateCourseList }

    // @ts-ignore
    delete tempPayload.name

    onFinalize(tempPayload)
  }

  return (
    <div className="mt-2">
      <Tabs defaultActiveKey="peserta" destroyInactiveTabPane>
        {/* {!isReadOnly && (
          <Tabs.TabPane
            tab="Bahan Ajar"
            key="bahan-ajar"
            className="min-h-[300px]"
          >
            <TabContentDasusBahanAjar
              dasusProgramId={dasusProgramId}
              bahanAjar={tempTemplateCourseList?.bahanAjar}
              isLoading={isLoading}
              onApply={(values) => {
                handleApplyBahanAjarChanges(values)
              }}
              isReadOnly={isReadOnly}
            />
          </Tabs.TabPane>
        )} */}
        <Tabs.TabPane tab="PTM & FI" key="ptm-fi" className="min-h-[300px]">
          <TabContentConfigPTMFI
            dasusProgramId={dasusProgramId}
            fi={tempTemplateCourseList?.fiData}
            ptm={tempTemplateCourseList?.ptmData}
            isLoading={isLoading}
            isReadOnly={isReadOnly}
            onApply={(values) => {
              setTempTemplateCourseList({
                ...tempTemplateCourseList,
                ptmData: values.ptm,
                fiData: values.fi,
              })
            }}
          />
        </Tabs.TabPane>
      </Tabs>

      {!isReadOnly && (
        <Popconfirm
          title="Apakah Anda yakin ingin finalisasi template ? template yg sudah final tidak bisa di edit!"
          okButtonProps={{ danger: true }}
          okText="Finalize"
          cancelText="Batal"
          placement="topRight"
          onConfirm={handleFinalizeDasusTemplateForm}
        >
          <Button
            icon={<Icons.WarningFilled />}
            block
            danger
            size="small"
            disabled={
              tempTemplateCourseList.fiData?.length === 0 ||
              tempTemplateCourseList.ptmData?.length === 0
            }
          >
            Finalize Template PTM FI
          </Button>
        </Popconfirm>
      )}

      <Divider />

      <Descriptions
        bordered
        column={1}
        size="small"
        title={'Konfigurasi Form'}
        className="mb-4"
      >
        <Descriptions.Item label="PTM">
          <Divider />
        </Descriptions.Item>

        <Descriptions.Item label="Form Penilaian Partisipasi">
          <Button onClick={() => handleShowDrawer('FormPenilaianPartisipasi')}>
            Manage Form
          </Button>
        </Descriptions.Item>
        <Descriptions.Item label="Form Penilaian Diri">
          <Button onClick={() => handleShowDrawer('FormPenilaianDiri')}>
            Manage Form
          </Button>
        </Descriptions.Item>
        <Descriptions.Item label="Form Umpan Balik">
          <Button
            onClick={() => {
              handleShowDrawer('FormUmpanBalik')
            }}
          >
            Manage Form
          </Button>
        </Descriptions.Item>

        <Descriptions.Item label="Form Bahan Ajar">
          <Button
            onClick={() => {
              handleShowDrawer('FormBahanAjar')
            }}
          >
            Manage Form
          </Button>
        </Descriptions.Item>

        <Descriptions.Item label="Form Penilaian Refleksi">
          <Button onClick={() => handleShowDrawer('FormRefleksi')}>
            Manage Form
          </Button>
        </Descriptions.Item>

        <Descriptions.Item label="Form Jurnal Fasilitasi">
          <Button onClick={() => handleShowDrawer('FormJurnalFasilitasi')}>
            Manage Form
          </Button>
        </Descriptions.Item>

        <Descriptions.Item label="Form Penilaian Kinerja">
          <Button onClick={() => handleShowDrawer('formPenilaianKinerja')}>
            Manage Form
          </Button>
        </Descriptions.Item>

        <Descriptions.Item label="Form Produk Belajar">
          <Button onClick={() => handleShowDrawer('FormProdukBelajar')}>
            Manage Form
          </Button>
        </Descriptions.Item>

        <Descriptions.Item label="FI">
          <Divider />
        </Descriptions.Item>

        <Descriptions.Item label="Form Jurnal Fasilitator">
          <Button onClick={() => handleShowDrawer('FormJurnalFasilitator')}>
            Manage Form
          </Button>
        </Descriptions.Item>

        <Descriptions.Item label="Form Penilaian Coaching">
          <Button onClick={() => handleShowDrawer('FormPenilaianCoaching')}>
            Manage Form
          </Button>
        </Descriptions.Item>

        <Descriptions.Item label="Form Penilaian Observasi">
          <Button onClick={() => handleShowDrawer('FormPenilaianObservasi')}>
            Manage Form
          </Button>
        </Descriptions.Item>
      </Descriptions>

      <DrawerSelector
        type={selectedDrawer.type}
        visible={selectedDrawer.visible}
        onClose={handleCloseDrawer}
        programId={dasusProgramId}
        ptmData={tempTemplateCourseList.ptmData}
      />
    </div>
  )
}
