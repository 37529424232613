import { Button } from '@pankod/refine-antd'
import { ShowButtonProps } from '@pankod/refine-antd/dist/components/buttons/show'
import { useRouterContext, useNavigation } from '@pankod/refine-core'
import React from 'react'

export const ShowRombelButton = ({
  angkatanId,
  rombelId,
  ...props
}: ShowButtonProps & {
  angkatanId: number | string
  rombelId: number | string
}) => {
  const { Link } = useRouterContext()
  const { showUrl: generateShowUrl } = useNavigation()
  const listUrl = generateShowUrl('rombel-ppg-management', rombelId)
  return (
    <Link
      to={`${listUrl}?angkatanId=${angkatanId}`}
      replace={false}
      onClick={(e: React.PointerEvent<HTMLButtonElement>) => {
        if (props.onClick) {
          e.preventDefault()
          props.onClick(e)
        }
      }}
    >
      <Button {...props} />
    </Link>
  )
}
