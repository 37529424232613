import {
  Button,
  Descriptions,
  Modal,
  Popconfirm,
  Space,
  Steps,
} from '@pankod/refine-antd'
import React, { useCallback, useState } from 'react'
import { useInvalidate, useCreate } from '@pankod/refine-core'

import InstructorTable from './InstructorTable'
import InstructorSchedule from './InstructorSchedule'
import { showErrorNotification } from '@resources/angkatan-ppg-management/utils'
import { parseDate } from '@resources/webinar-management/utils/dateParser'

const defaultState = {
  localProgramId: undefined,
  webinarId: undefined,
  webinarKelasGroupId: undefined,
  instructorEmail: undefined,
  instructorName: undefined,
  executionDate: undefined,
  position: undefined,
}

type UseInstructorScheduleModalReturn = {
  localProgramId?: string
  webinarId?: string
  webinarKelasGroupId?: string
  instructorEmail?: string
  instructorName?: string
  executionDate?: string | null
  position?: number
}
export const useInstructorScheduleModal = () => {
  const [id, setId] = useState<UseInstructorScheduleModalReturn>(defaultState)

  const onClose = useCallback(() => {
    setId(defaultState)
  }, [])

  const show = useCallback(
    (data: {
      localProgramId?: string
      webinarId?: string
      webinarKelasGroupId?: string
      instructorEmail?: string
      instructorName?: string
      executionDate?: string | null
      position?: number
    }) => {
      setId(data)
    },
    []
  )

  return {
    show,
    onClose,
    visible: !!id.localProgramId && !!id.webinarId && !!id.webinarKelasGroupId,
    id,
  }
}

const getScheduleStepDescription = ({
  session,
}: {
  session: { date: string; sessionId: string; position?: number }
}) => {
  if (session.date) {
    return `${parseDate(session.date).format('DD MMM YYYY')}, Sesi ${
      session.position
    }`
  }

  return '-'
}

type InstructorScheduleModalProps = {
  visible?: boolean
  onClose?: () => void
  id?: UseInstructorScheduleModalReturn
}
const InstructorScheduleModal = ({
  visible,
  onClose,
  id,
}: InstructorScheduleModalProps) => {
  const {
    localProgramId,
    webinarId,
    webinarKelasGroupId,
    instructorEmail,
    instructorName,
    executionDate,
    position,
  } = id || {}
  const [user, setUser] = useState<{ name: string; userId: string }>({
    name: '',
    userId: '',
  })
  const [session, setSession] = useState<{
    date: string
    sessionId: string
    position?: number
  }>({ date: '', sessionId: '', position: undefined })
  const [current, setCurrent] = useState(0)
  const { mutateAsync, isLoading } = useCreate<{
    date: string
    sessionId: string
    userId: string
  }>()
  const invalidate = useInvalidate()

  const next = () => {
    setCurrent(current + 1)
  }

  const prev = () => {
    setCurrent(current - 1)
  }

  const steps = [
    {
      title: 'Pilih Instruktur',
      description: `${user.name || '-'}`,
      content: (
        <InstructorTable
          enabled={
            !!visible &&
            !!localProgramId &&
            !!webinarId &&
            !!webinarKelasGroupId
          }
          onSelected={setUser}
          resource={`local-programs/${localProgramId}/webinars/${webinarId}/class-group/${webinarKelasGroupId}`}
          selected={user}
          initialInstructorEmail={instructorEmail}
        />
      ),
      key: 'instructor',
    },
    {
      title: 'Pilih Jadwal',
      description: getScheduleStepDescription({
        session,
      }),
      content: (
        <InstructorSchedule
          enabled={
            !!localProgramId &&
            !!webinarId &&
            !!webinarKelasGroupId &&
            !!user.userId
          }
          onError={prev}
          onSelected={setSession}
          resource={`local-programs/${localProgramId}/webinars/${webinarId}/class-group/${webinarKelasGroupId}/sessions?userId=${user.userId}`}
          selected={session}
          initialSchedule={{
            date: executionDate || '',
            position: +(position || ''),
          }}
        />
      ),
      key: 'schedule',
    },
  ]

  const handleClose = () => {
    setCurrent(0)
    setUser({ name: '', userId: '' })
    setSession({ date: '', sessionId: '', position: undefined })
    onClose?.()
  }

  const cancelButtonProps = {
    onClick: () => {
      if (current > 0) {
        prev()
        setSession({ date: '', sessionId: '', position: undefined })
        return
      }
      handleClose()
      return
    },
  }

  const okButtonProps = {
    disabled:
      (current < steps.length - 1 && !user.userId) ||
      (current >= steps.length - 1 && !session.sessionId),
    onClick: () => {
      if (current < steps.length - 1) {
        return next()
      }
    },
  }

  const handleSave = async () => {
    return mutateAsync(
      {
        resource: `local-programs/${localProgramId}/webinars/${webinarId}/class-group/${webinarKelasGroupId}/assign`,
        values: {
          date: session.date,
          sessionId: session.sessionId.split('|')[0],
          userId: user.userId,
        },
        dataProviderName: 'lms',
        successNotification: {
          message: 'Perubahan instruktur webinar berhasil disimpan',
          type: 'success',
          description: 'Sukses',
        },
        errorNotification: (err) =>
          showErrorNotification(
            err,
            'Terdapat gangguan saat menyimpan perubahan instruktur dan jadwal webinar'
          ),
      },
      {
        onSettled: () => {
          invalidate({
            invalidates: ['detail', 'list'],
            dataProviderName: 'lms',
            resource: `local-programs/${localProgramId}/webinars`,
            id: webinarId,
          })
          handleClose()
        },
      }
    )
  }

  return (
    <Modal
      visible={visible}
      onCancel={handleClose}
      title="Ubah Instruktur Webinar"
      maskClosable={false}
      destroyOnClose
      width={'80%'}
      footer={
        <Space>
          <Button {...cancelButtonProps}>
            {(() => {
              if (current > 0) return 'Kembali'
              return 'Batal'
            })()}
          </Button>
          <Popconfirm
            disabled={
              current < steps.length - 1 ||
              (current < steps.length - 1 && !user.userId) ||
              (current >= steps.length - 1 && !session.sessionId)
            }
            title="Apakah Anda yakin ingin menyimpan perubahan jadwal ini?"
            placement="topRight"
            okText="Ya"
            cancelText="Tidak"
            onConfirm={handleSave}
          >
            <Button type="primary" {...okButtonProps} loading={isLoading}>
              {(() => {
                if (current < steps.length - 1) return 'Selanjutnya'
                return 'Simpan'
              })()}
            </Button>
          </Popconfirm>
        </Space>
      }
    >
      <Descriptions layout="vertical" className="mb-6" bordered>
        <Descriptions.Item label="Instruktur Awal">
          {instructorName || <i>Belum ada instruktur</i>}
        </Descriptions.Item>
        <Descriptions.Item label="Jadwal Awal">
          {!!executionDate ? (
            `${parseDate(executionDate).format('DD MMM YYYY')}${
              !!position ? `, Sesi ${position}` : ''
            }`
          ) : (
            <i>Belum ada jadwal</i>
          )}
        </Descriptions.Item>
      </Descriptions>
      <Steps current={current} className="!mb-8">
        {steps.map((item) => (
          <Steps.Step
            key={item.key}
            title={item.title}
            description={item.description}
          />
        ))}
      </Steps>
      <div className="steps-content">{steps[current].content}</div>
    </Modal>
  )
}

export default InstructorScheduleModal
