import { Popover, Button, Icons, Divider, Empty } from '@pankod/refine-antd'
import { useOne } from '@pankod/refine-core'
import React from 'react'

import { TParticipantQueue } from '@components/DataTableParticipants/types'

type TParticipantEntryQueueProps = {
  programId: string
  rerenderKey?: number
}

const { LoadingOutlined } = Icons

export const ParticipantEntryQueue = ({
  programId,
}: TParticipantEntryQueueProps) => {
  const { data: participantQueue } = useOne<TParticipantQueue>({
    resource: `programs/${programId}/participants`,
    id: 'queue',
    dataProviderName: 'lms',
  })

  return (
    <Popover
      content={
        <div>
          {participantQueue?.data?.total === 0 ? (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="Belum Ada Akun yang didaftarkan"
            />
          ) : (
            <>
              <p>{participantQueue?.data?.total} Akun didalam antrian </p>
              <Divider style={{ marginBottom: '8px', marginTop: '8px' }} />
              {participantQueue?.data?.accounts?.map((user: string) => {
                return (
                  <div
                    className="mb-2 flex justify-between items-center"
                    key={user}
                  >
                    <span className=" mr-4">{user}</span>
                    <LoadingOutlined style={{ fontSize: 16 }} spin />
                  </div>
                )
              })}
            </>
          )}
        </div>
      }
      placement="bottom"
      trigger="click"
    >
      <Button className="mr-4" type="link">
        {participantQueue?.data?.total || 0} Akun sedang didaftarkan
      </Button>
    </Popover>
  )
}
