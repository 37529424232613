import {
  Form,
  FormProps,
  Input,
  ListProps,
  Modal,
  ModalProps,
  Table,
  useTable,
} from '@pankod/refine-antd'
import React, { useMemo } from 'react'

import { TLMSAdmin } from '@resources/lms-management/admin/types'

type AddAdminModalProps = {
  name?: string
  modalProps: ModalProps
  formProps: FormProps
  adminListProps: ListProps<TLMSAdmin>
}

const AddAdminModal = ({
  modalProps,
  formProps,
  adminListProps,
  name,
}: AddAdminModalProps) => {
  const { tableQueryResult: queryAdminResult, tableProps } = useTable<{
    email: string
    disabled: boolean
  }>({
    resource: 'admins',
    dataProviderName: 'lms',
    queryOptions: {
      enabled: modalProps.visible,
    },
    permanentFilter: [
      {
        field: 'role',
        operator: 'eq',
        value: 'admin-entitas',
      },
    ],
  })

  const addAdminList = useMemo(() => {
    const currentAdmins =
      adminListProps.dataSource?.map(({ email }) => email) || []
    return (
      queryAdminResult?.data?.data?.reduce((admins, admin) => {
        return [
          ...admins,
          { email: admin.email, disabled: currentAdmins.includes(admin.email) },
        ]
      }, [] as { email: string; disabled: boolean }[]) || []
    )
  }, [adminListProps.dataSource, queryAdminResult?.data?.data])

  const selectedRowKeys = Form.useWatch('emails', formProps?.form)

  return (
    <Modal {...modalProps} title={`Add Admin Entitas "${name}"`} okText="Add">
      <Form {...formProps} layout="vertical">
        <Form.Item hidden name="emails">
          <Input />
        </Form.Item>
        <Table
          {...tableProps}
          dataSource={addAdminList}
          rowKey="email"
          columns={[{ title: 'Email', dataIndex: 'email' }]}
          rowSelection={{
            selectedRowKeys: selectedRowKeys || [],
            onChange(selectedRowKeys) {
              formProps?.form?.setFieldsValue({ emails: selectedRowKeys || [] })
            },
            preserveSelectedRowKeys: true,
            getCheckboxProps: (record: { disabled: boolean }) => ({
              disabled: record.disabled,
            }),
          }}
        />
      </Form>
    </Modal>
  )
}

export default AddAdminModal
