export const LOCAL_STORAGE_THEME_VAR = 'lms-admin-theme'
export enum THEME {
  LIGHT = 'light',
  DARK = 'dark',
}

export const toggleTheme = () => {
  if (typeof window === 'undefined') return

  let theme: THEME = THEME.DARK
  if (
    (localStorage.getItem(LOCAL_STORAGE_THEME_VAR) || THEME.DARK) === THEME.DARK
  ) {
    theme = THEME.LIGHT
  }

  localStorage.setItem(LOCAL_STORAGE_THEME_VAR, theme)
  window.location.reload()
}

export const getTheme = () => {
  if (typeof window === 'undefined') return THEME.DARK

  return localStorage.getItem(LOCAL_STORAGE_THEME_VAR) || THEME.DARK
}
