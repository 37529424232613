import { useLogin } from '@pankod/refine-core'
import { AntdLayout, notification, Typography } from '@pankod/refine-antd'
import { GoogleLogin, CredentialResponse } from '@react-oauth/google'
import Head from 'next/head'

import { BASE_PATH } from 'src/constant'
const { Title } = Typography
const Login: React.FC = () => {
  const { mutate: login } = useLogin<CredentialResponse>()

  return (
    <AntdLayout
      style={{
        background: `radial-gradient(50% 50% at 50% 50%, #bbb 0%, #333 100%)`,
        backgroundSize: 'cover',
      }}
    >
      <Head>
        <title>LMS Admin | Login</title>
      </Head>
      <div style={{ height: '100vh', display: 'flex' }}>
        <div style={{ margin: 'auto', textAlign: 'center' }}>
          <div
            style={{
              marginBottom: '28px',
              display: 'flex',
              justifyContent: 'space-between',
              marginLeft: '4px',
            }}
          >
            <Title
              level={2}
              style={{
                margin: '0',
              }}
            >
              LMS Admin
            </Title>
            <img src={`${BASE_PATH}/dashkit.svg`} alt="logo" />
          </div>
          <GoogleLogin
            shape="pill"
            onSuccess={(credentialResponse) => {
              login(credentialResponse)
            }}
            onError={() => {
              notification.open({
                description: 'Silakan ulangi kembali',
                message: 'Gagal login',
                type: 'error',
              })
            }}
          />
        </div>
      </div>
    </AntdLayout>
  )
}

export default Login
