import { SerializedNodes } from '@craftjs/core'

import {
  TCheckboxGroup,
  TDatePicker,
  TLikertQuestion,
  TRadioGroup,
  TRichTextEditor,
  TSelect,
  TTextArea,
  TTextInput,
  TNumberInput,
  WIDGET_NAME,
} from './schema'

const TEXT_INPUT_TYPE = {
  email: 'email',
  uri: 'uri',
}

const FORM_SCHEMA_CONFIG: Partial<
  Record<keyof typeof WIDGET_NAME, (props: any) => any>
> = {
  [WIDGET_NAME.TextInput]: (props: TTextInput) => ({
    type: 'string',
    minLength: props.minChar ?? undefined,
    maxLength: props.maxChar ?? undefined,
    format: TEXT_INPUT_TYPE[props.type as keyof typeof TEXT_INPUT_TYPE],
  }),
  [WIDGET_NAME.NumberInput]: (props: TNumberInput) => {
    return {
      type: 'number',
      minimum: props.minValue ?? undefined,
      maximum: props.maxValue ?? undefined,
    }
  },
  [WIDGET_NAME.DatePicker]: (props: TDatePicker) => ({
    type: 'string',
    format: 'date',
    formatMinimum: props.minValue || undefined,
    formatMaximum: props.maxValue || undefined,
  }),
  [WIDGET_NAME.Select]: (_props: TSelect) => ({
    type: 'string',
  }),
  [WIDGET_NAME.RichTextEditor]: (_props: TRichTextEditor) => ({
    type: 'string',
  }),
  [WIDGET_NAME.TextArea]: (props: TTextArea) => ({
    type: 'string',
    minLength: props.minChar ?? undefined,
    maxLength: props.maxChar ?? undefined,
  }),
  [WIDGET_NAME.CheckboxGroup]: (props: TCheckboxGroup) => ({
    type: 'array',
    items: {
      type: 'string',
    },
    minItems: props.minSelected ?? undefined,
    maxItems: props.maxSelected ?? undefined,
  }),
  [WIDGET_NAME.RadioGroup]: (_props: TRadioGroup) => ({
    type: 'string',
  }),
  [WIDGET_NAME.LikertQuestion]: (_props: TLikertQuestion) => ({
    type: 'string',
  }),
}

export const formToSchemaParser = (serializedNodes: SerializedNodes) => {
  const requiredFields: string[] = []
  const parsedProperties = Object.values(serializedNodes).reduce(
    (result, { custom, props }) => {
      if (props.isRequired) requiredFields.push(props.name)
      const schema = FORM_SCHEMA_CONFIG[custom.name as WIDGET_NAME]?.(props)
      if (schema) {
        return {
          ...result,
          [props.name]: schema,
        }
      }
      return result
    },
    {}
  )

  return {
    type: 'object',
    properties: JSON.parse(JSON.stringify(parsedProperties)),
    required: requiredFields,
  }
}
