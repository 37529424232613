import {
  Button,
  EditButton,
  Icons,
  Result,
  Show,
  Space,
  Tabs,
  useModalForm,
} from '@pankod/refine-antd'
import {
  useResource,
  useOne,
  useNavigation,
  IResourceComponentsProps,
  useDelete,
  useCan,
  useUpdate,
} from '@pankod/refine-core'
import Head from 'next/head'
import { useRouter } from 'next/router'
import React from 'react'

import {
  TCommonError,
  TCommonResponse,
  TLMSCommonError,
} from 'src/interfaces/common'
import { PopDeleteConfirm } from '../components/PopDeleteConfirm'
import { PROGRAM_STATE, TLMSProgramDetail } from '../program/types'
import { ModalForm } from './blocks/ModalForm'
import { NestedBreadcrumb } from '../components/NestedBreadcrumb'
import TabProgramLocalDetail from './blocks/TabProgramLocalDetail'
import {
  PROGRAM_LOCAL_STATE,
  TLMSProgramLocal,
  TLMSProgramLocalRequest,
} from './types'
import { TableClassList } from '../class/blocks/TableList'
import {
  TableParticipantAllocation,
  TableParticipantList,
} from '@components/DataTableParticipants'
import useGetACL from 'src/hooks/useGetACL'
import { ClassGroup } from '../class-group/ClassGroup'
import { trackTabChange } from 'src/helpers/url'
import { showErrorNotification } from '@resources/angkatan-ppg-management/utils'
import { TableTimespendList } from './blocks/TableTimespendList'
import { useProgramStore } from '../program/store'
import CertificateApprovalList from './blocks/CertificateApprovalList'
import ConfigurationButton from './blocks/ConfigurationButton'

const PROGRAM_RESOURCE = 'programs'

export const LMSProgramLocalManagementShow: React.FC<
  IResourceComponentsProps
> = ({ options }) => {
  const router = useRouter()
  const { id } = useResource({
    resourceNameOrRouteName: 'lms-program-local-management',
  })
  const [, , lmsRole] = useGetACL()
  const [activeKey, setActiveKey] = React.useState(
    router.query.defaultTab || 'detail'
  )
  const { listUrl, goBack, showUrl } = useNavigation()
  const programId = router.query.programId as string
  const PROGRAM_LOCAL_RESOURCE = `${PROGRAM_RESOURCE}/${programId}/local-programs`
  const { data: programLocalData, isLoading } = useOne<
    TCommonResponse<TLMSProgramLocal>,
    TCommonError
  >({
    resource: PROGRAM_LOCAL_RESOURCE,
    dataProviderName: 'lms',
    id,
    queryOptions: {
      enabled: !!programId,
    },
  })

  const { data: toggleCertificateData } = useOne<{ data: { active: boolean } }>(
    {
      dataProviderName: 'lms',
      id: 'certificate-toggle',
      resource: `programs/${programId}`,
    }
  )

  const handleSetActiveKey = (key: string) => {
    setActiveKey(key)
    trackTabChange(router, key, 'defaultTab')
  }

  const { data: canShowProgram } = useCan({
    action: 'show',
    resource: 'lms-program-management',
  })

  const { setProgram, isOwner } = useProgramStore((store) => store)

  const { data: programData } = useOne<
    TCommonResponse<TLMSProgramDetail>,
    TCommonError
  >({
    resource: PROGRAM_RESOURCE,
    dataProviderName: 'lms',
    id: programId,
    queryOptions: {
      enabled: Boolean(!!programId && !!canShowProgram?.can),
      onSuccess: (data) => {
        setProgram({
          isOwner: Boolean(data.data.data.programOwner),
          entityId: data.data.data.entityId,
        })
      },
    },
  })

  const {
    modalProps: editModalProps,
    formProps: editFormProps,
    show: editShow,
    close: editClose,
  } = useModalForm<TLMSProgramLocal, TLMSCommonError, TLMSProgramLocalRequest>({
    action: 'edit',
    autoSubmitClose: true,
    resource: PROGRAM_LOCAL_RESOURCE,
    dataProviderName: 'lms',
    autoResetForm: true,
    redirect: false,
    successNotification: {
      message: 'Program local successfully edited',
      type: 'success',
      description: 'Successful',
    },
    errorNotification: (error) =>
      showErrorNotification(
        error,
        'There was a problem when editing program local'
      ),
  })

  const { mutateAsync, isLoading: isUpdatingStatus } = useUpdate<
    {},
    TLMSCommonError,
    TLMSProgramLocalRequest
  >()

  const handleUpdateStatus = () => {
    if (!programLocalData?.data.data) return
    const data = programLocalData.data.data
    mutateAsync({
      id,
      resource: PROGRAM_LOCAL_RESOURCE,
      values: {
        entityId: data.entity.id,
        name: data.name,
        programId: data.programId,
        status:
          data.status === PROGRAM_LOCAL_STATE.PUBLISHED
            ? PROGRAM_LOCAL_STATE.DRAFT
            : PROGRAM_LOCAL_STATE.PUBLISHED,
      },
      dataProviderName: 'lms',
      successNotification: {
        message: 'Program local successfully updated',
        type: 'success',
        description: 'Successful',
      },
      errorNotification: (error) =>
        showErrorNotification(
          error,
          'There was a problem when updating program local'
        ),
    })
  }

  const { mutateAsync: doDelete } = useDelete()

  const { data: canDelete } = useCan({
    action: 'delete',
    resource: 'lms-program-local-management',
  })

  const { data: canAccessProgram } = useCan({
    action: 'show',
    resource: 'lms-program-management',
  })

  const { data: canEdit } = useCan({
    action: 'edit',
    resource: 'lms-program-local-management',
  })

  const { data: canUpdateStatus } = useCan({
    action: 'field',
    resource: 'lms-program-local-management',
    params: { field: 'status' },
  })

  const { data: canAccessClassGroup } = useCan({
    action: 'show',
    resource: 'lms-class-group-management',
  })

  const { data: canAccessCertificateApproval } = useCan({
    action: 'list',
    resource: 'certificate-approval',
  })

  if (!programId)
    return (
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
      />
    )

  const disableDeleteAction =
    programData?.data.data.status !== PROGRAM_STATE.PREPARATION ||
    (!canDelete?.can && !isOwner)

  const disableEditAction =
    programData?.data.data.status !== PROGRAM_STATE.PREPARATION ||
    (!canEdit?.can && !isOwner)

  const disableUpdateStatus =
    !canUpdateStatus?.can ||
    programData?.data.data.status !== PROGRAM_STATE.PREPARATION

  const showAllocationTab = lmsRole !== 'admin-entitas' || isOwner
  const showCertificateApproval =
    canAccessCertificateApproval?.can &&
    toggleCertificateData?.data?.data?.active

  const showTimespendTab = false

  return (
    <>
      <Show
        title="Program Lokal Detail"
        resource={PROGRAM_LOCAL_RESOURCE}
        dataProviderName="lms"
        isLoading={isLoading}
        canDelete={false}
        breadcrumb={
          <NestedBreadcrumb
            injectedItems={[
              ...(canAccessProgram?.can
                ? [
                    {
                      label: 'Program',
                      href: listUrl('lms-program-management'),
                    },
                  ]
                : []),
              {
                label: 'Program Lokal',
                href: canAccessProgram?.can
                  ? showUrl('lms-program-management', programId) +
                    `?activeTab=programLocal`
                  : listUrl('lms-program-local-management'),
              },
              {
                label: 'Show',
              },
            ]}
          />
        }
        headerButtons={() => (
          <Space>
            {activeKey === 'detail' && (
              <>
                <EditButton
                  onClick={() => {
                    editShow(id)
                  }}
                  disabled={disableEditAction}
                >
                  Edit Program Lokal
                </EditButton>
                <PopDeleteConfirm
                  title={`Apakah Anda yakin ingin menghapus Program Lokal "${programLocalData?.data.data.name}"?`}
                  placeholder="Tulis ulang nama Program Lokal"
                  errorMessage="Nama Program Lokal tidak sama"
                  placement="bottomRight"
                  onConfirm={() =>
                    doDelete(
                      {
                        id,
                        resource: PROGRAM_LOCAL_RESOURCE,
                        dataProviderName: 'lms',
                        successNotification: {
                          message: 'Program Lokal berhasil dihapus',
                          type: 'success',
                          description: 'Sukses',
                        },
                        errorNotification: (err) =>
                          showErrorNotification(
                            err,
                            'Terdapat gangguan saat menghapus program lokal'
                          ),
                      },
                      {
                        onSuccess: () => goBack(),
                      }
                    )
                  }
                  okButtonProps={{
                    danger: true,
                  }}
                  okText="Hapus"
                  cancelText="Batal"
                  validateValue={programLocalData?.data.data.name!}
                  disabled={disableDeleteAction}
                >
                  <Button
                    danger
                    icon={<Icons.DeleteOutlined />}
                    disabled={disableDeleteAction}
                  >
                    Hapus Program Lokal
                  </Button>
                </PopDeleteConfirm>
              </>
            )}
          </Space>
        )}
      >
        <Head>
          <title>LMS Admin | {options?.label} </title>
        </Head>
        <Tabs
          defaultActiveKey="detail"
          activeKey={String(activeKey)}
          onChange={handleSetActiveKey}
          destroyInactiveTabPane
          tabBarExtraContent={{
            left: (
              <ConfigurationButton programId={programId} programLocalId={id} />
            ),
          }}
        >
          <Tabs.TabPane tab="Detail" key="detail">
            <TabProgramLocalDetail
              canUpdateStatus={!disableUpdateStatus}
              onUpdateStatus={handleUpdateStatus}
              data={programLocalData?.data.data}
              isUpdatingStatus={isUpdatingStatus}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Kelas" key="kelas">
            <TableClassList
              programId={programLocalData?.data.data.programId}
              programLocalId={programLocalData?.data.data.id}
              programLocalStatus={programLocalData?.data?.data?.status}
            />
          </Tabs.TabPane>

          {canAccessClassGroup?.can && (
            <Tabs.TabPane tab="Kelompok Kelas Webinar" key="kelompok-kelas">
              <ClassGroup
                programId={programLocalData?.data.data.programId}
                programLocalId={programLocalData?.data.data.id}
              />
            </Tabs.TabPane>
          )}

          <Tabs.TabPane tab="Peserta" key="peserta">
            <TableParticipantList
              pageType="PROGRAM_LOKAL_LIST"
              programStatus={programData?.data?.data?.status}
            />
          </Tabs.TabPane>

          {showAllocationTab && (
            <Tabs.TabPane tab="Alokasi Peserta" key="alokasi-peserta">
              <TableParticipantAllocation
                disableAllocation={
                  (programData
                    ? programData?.data.data.status !==
                      PROGRAM_STATE.PREPARATION
                    : false) &&
                  programLocalData?.data.data.status !==
                    PROGRAM_LOCAL_STATE.PUBLISHED
                }
              />
            </Tabs.TabPane>
          )}

          {showTimespendTab && (
            <Tabs.TabPane tab="Timespend" key="timespend">
              <TableTimespendList
                programId={programLocalData?.data.data.programId}
                localProgramId={programLocalData?.data.data.id}
              />
            </Tabs.TabPane>
          )}

          {showCertificateApproval && (
            <Tabs.TabPane
              tab="Pengabsahan Sertifikat (Approval)"
              key="pengabsahan-sertifikat"
            >
              <CertificateApprovalList
                programId={programLocalData?.data.data.programId}
                localProgramId={programLocalData?.data.data.id}
              />
            </Tabs.TabPane>
          )}
        </Tabs>
      </Show>

      {!disableEditAction && (
        <ModalForm
          formProps={editFormProps}
          modalProps={editModalProps}
          mode="edit"
          programName={programData?.data.data.name}
          onClose={() => {
            editFormProps.form?.resetFields()
            editClose()
          }}
        />
      )}
    </>
  )
}
