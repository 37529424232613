import React from 'react'
import { useNode, Node } from '@craftjs/core'
import { Controller, ControllerRenderProps, FieldError } from 'react-hook-form'
import clsx from 'clsx'
import { FormField } from '@instructure/ui-form-field'
import dynamic from 'next/dynamic'
import { Form, Input, Switch } from '@pankod/refine-antd'
import 'react-quill/dist/quill.snow.css'
import RQ, { ReactQuillProps } from 'react-quill'
import { capitalize } from 'lodash'

type TForwardedReactQuillProps = { forwardedRef: any } & ReactQuillProps

import {
  mappedWidgetSettingsError,
  widgetSettingsOnValueChange,
  getWidgetSettingsFields,
} from '@components/FormBuilder/utils'
import {
  RichTextEditorCustomProps,
  TRichTextEditor,
  WIDGET_NAME,
} from '@components/FormBuilder/schema'
import { useFormContext } from '@components/FormBuilder/formContext'
import ContentForm from '@components/ContentBank/ContentForm'

export const defaultProps: TRichTextEditor = {
  label: 'Rich text editor label',
  name: 'richTextEditorName',
}

const BaseWidget = ({
  name,
  onBlur,
  onChange,
  value,
  placeholder,
  label,
  error,
  helper,
}: Omit<ControllerRenderProps, 'ref'> & {
  label: string
  placeholder?: string
  error?: FieldError
  helper?: string
}) => {
  /** Had to do this because ReactQuill give `document is not defined` error */
  const ReactQuill: React.ComponentType<TForwardedReactQuillProps> =
    React.useMemo(
      () =>
        dynamic(
          async () => {
            const { default: RQ } = await import('react-quill')
            const { default: ImageCompress } = await import(
              'quill-image-compress'
            )
            RQ.Quill.register('modules/imageCompress', ImageCompress)
            // eslint-disable-next-line react/display-name
            return function forwardRef({ forwardedRef, ...props }) {
              return <RQ ref={forwardedRef} {...props} />
            }
          },
          { ssr: false }
        ),
      []
    )
  const quillRef = React.useRef<RQ>(null)
  const modules = React.useMemo(
    () => ({
      toolbar: [
        [{ header: [1, 2, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        [
          { list: 'ordered' },
          { list: 'bullet' },
          { indent: '-1' },
          { indent: '+1' },
        ],
        ['link', 'image'],
      ],
      imageCompress: {
        quality: 0.9,
        maxWidth: 700,
        maxHeight: 700,
        imageType: 'image/png', // default
        debug: process.env.NODE_ENV === 'development',
        suppressErrorLogging: false, // default
      },
    }),
    []
  )

  return (
    <FormField
      label={
        <div
          className="form-label ql-editor"
          dangerouslySetInnerHTML={{ __html: label }}
        />
      }
      id={name}
      messages={[
        {
          text: error?.message ? capitalize(error.message) : helper,
          type: error?.message ? 'error' : 'hint',
        },
      ]}
    >
      <ReactQuill
        forwardedRef={quillRef}
        theme="snow"
        className={clsx(
          'widget-rich-text-editor',
          error?.message && 'widget-rich-text-editor__error'
        )}
        value={value}
        onChange={(content, _delta, _source, editor) => {
          if (editor.getLength() <= 1) return onChange('')
          onChange(content)
        }}
        onBlur={onBlur}
        id={name}
        placeholder={placeholder}
        modules={modules}
        formats={[
          'header',
          'font',
          'size',
          'bold',
          'italic',
          'underline',
          'align',
          'strike',
          'script',
          'background',
          'list',
          'bullet',
          'indent',
          'link',
          'image',
          'color',
        ]}
      />
    </FormField>
  )
}

export const RichTextEditor = ({
  name,
  placeholder,
  label,
  helper,
}: TRichTextEditor) => {
  const {
    connectors: { connect, drag },
  } = useNode()
  const { control } = useFormContext()

  return (
    <div
      ref={(ref) => connect(drag(ref!))}
      className={clsx('mb-5 last:mb-0', 'widget-component', 'text-default')}
    >
      <Controller
        name={name}
        control={control}
        render={({
          field: { name, onChange, onBlur, value },
          fieldState: { error },
        }) => {
          return (
            <BaseWidget
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              name={name}
              placeholder={placeholder}
              label={label}
              error={error}
              helper={helper}
            />
          )
        }}
      />
    </div>
  )
}

const RichTextEditorSettings = () => {
  const {
    actions: { setProp, setCustom },
    props,
    custom,
  } = useNode((node) => ({
    props: node.data.props,
    custom: node.data.custom,
  }))
  const errors = mappedWidgetSettingsError(custom.errors)
  return (
    <Form
      onValuesChange={(changedValue) =>
        widgetSettingsOnValueChange(changedValue, custom, setCustom)
      }
      fields={getWidgetSettingsFields(props)}
      layout="vertical"
    >
      <Form.Item
        label="Name Key"
        name="name"
        validateStatus={errors['name'] && 'error'}
        help={errors['name']}
      >
        <Input
          autoComplete="off"
          value={props.name}
          onChange={(e) =>
            setProp(
              (props: TRichTextEditor) =>
                (props.name = e.target.value.replaceAll(' ', ''))
            )
          }
        />
      </Form.Item>
      <Form.Item
        label="Label"
        name="label"
        validateStatus={errors['label'] && 'error'}
        help={errors['label']}
      >
        <ContentForm
          index="widgetText"
          showDeleteButton={false}
          showIndentation={false}
          showUploader={false}
          showTitle={false}
          value={props.text}
          onChange={(text) =>
            setProp((props: TRichTextEditor) => (props.label = text))
          }
        />
      </Form.Item>
      <Form.Item
        label="Required"
        name="isRequired"
        validateStatus={errors['isRequired'] && 'error'}
        help={errors['isRequired']}
      >
        <Switch
          checked={props.isRequired}
          onChange={(e) =>
            setProp((props: TRichTextEditor) => (props.isRequired = e))
          }
        />
      </Form.Item>
      <Form.Item
        label="Placeholder"
        name="placeholder"
        validateStatus={errors['placeholder'] && 'error'}
        help={errors['placeholder']}
      >
        <Input
          autoComplete="off"
          value={props.placeholder}
          onChange={(e) =>
            setProp(
              (props: TRichTextEditor) => (props.placeholder = e.target.value)
            )
          }
        />
      </Form.Item>
      <Form.Item
        label="Helper Text"
        name="helper"
        validateStatus={errors['helper'] && 'error'}
        help={errors['helper']}
      >
        <Input
          autoComplete="off"
          value={props.helper}
          onChange={(e) =>
            setProp((props: TRichTextEditor) => (props.helper = e.target.value))
          }
        />
      </Form.Item>
    </Form>
  )
}

RichTextEditor.craft = {
  rules: {
    canDrop: (targetNode: Node) => {
      return targetNode.data.name === WIDGET_NAME.Container
    },
  },
  props: defaultProps,
  custom: RichTextEditorCustomProps,
  related: {
    settings: RichTextEditorSettings,
  },
}
