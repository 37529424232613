import {
  Button,
  Descriptions,
  Icons,
  Popconfirm,
  Space,
  Tag,
} from '@pankod/refine-antd'
import React, { useState } from 'react'

import { PROGRAM_LOCAL_STATE, TLMSProgramLocal } from '../types'
import DrawerUnallocatedResource from './DrawerUnallocatedResource'

const TabProgramLocalDetail = ({
  data,
  onUpdateStatus,
  canUpdateStatus,
  isUpdatingStatus,
}: {
  data?: TLMSProgramLocal
  onUpdateStatus: () => void
  canUpdateStatus: boolean
  isUpdatingStatus: boolean
}) => {
  const [showUnallocated, setShowUnallocated] = useState(false)
  const isPublished = data?.status === PROGRAM_LOCAL_STATE.PUBLISHED

  return (
    <>
      <Descriptions
        bordered
        column={2}
        className="mt-4 mb-6"
        labelStyle={{ fontWeight: 600 }}
        title="Program Lokal"
        extra={
          <Space>
            {!isPublished && (
              <Button
                type="dashed"
                icon={<Icons.DeleteRowOutlined />}
                onClick={() => setShowUnallocated(true)}
              >
                Daftar Belum Teralokasi
              </Button>
            )}
            <Popconfirm
              placement="topRight"
              onConfirm={onUpdateStatus}
              disabled={!canUpdateStatus || isUpdatingStatus}
              title={
                <>
                  Apakah Anda yakin ingin mengubah status program lokal menjadi{' '}
                  <strong>{isPublished ? 'Draft' : 'Published'}</strong>?
                </>
              }
              okText="Ubah Status"
              cancelText="Batal"
            >
              <Button
                disabled={!canUpdateStatus || isUpdatingStatus}
                loading={isUpdatingStatus}
                type={isPublished ? 'default' : 'primary'}
              >
                {isPublished ? 'Ubah menjadi Draft' : 'Ubah menjadi Published'}
              </Button>
            </Popconfirm>
          </Space>
        }
      >
        <Descriptions.Item span={2} label="ID">
          {data?.id}
        </Descriptions.Item>
        <Descriptions.Item span={2} label="Nama">
          {data?.name}
        </Descriptions.Item>
        <Descriptions.Item span={2} label="Slug">
          {data?.slug}
        </Descriptions.Item>
        <Descriptions.Item span={2} label="Status">
          <Tag
            className="uppercase"
            color={isPublished ? 'success' : 'default'}
          >
            {data?.status}
          </Tag>
        </Descriptions.Item>
      </Descriptions>
      <DrawerUnallocatedResource
        visible={showUnallocated}
        onClose={() => setShowUnallocated(false)}
      />
    </>
  )
}

export default TabProgramLocalDetail
