import {
  Button,
  EditButton,
  Form,
  Icons,
  Input,
  // Icons,
  Space,
  Table,
  TextField,
  useModalForm,
  useTable,
} from '@pankod/refine-antd'
import { useCustomMutation, useInvalidate, useList } from '@pankod/refine-core'
import { useEffect, useMemo } from 'react'

import { ModalFormDosen } from './ModalFormDosen'
import ModalParseCSV from '@components/ModalParseCSV'
import { TLMSCommonError } from 'src/interfaces/common'
import useGetACL from 'src/hooks/useGetACL'
import { downloadTemplate } from '@components/ModalParseCSV/generateCSVTemplate'
import { showErrorNotification } from '@resources/angkatan-ppg-management/utils'
// import { PopDeleteConfirm } from '@resources/lms-management/components/PopDeleteConfirm'

type DosenListProps = {
  lptkId: string | number
  isMasterData?: boolean
  enableFilter?: boolean
}

export const DosenList = (props: DosenListProps) => {
  const { lptkId, isMasterData, enableFilter } = props
  const { mutate } = useCustomMutation()

  const [, , lmsRole] = useGetACL()

  const { tableProps, setFilters } = useTable({
    syncWithLocation: true,
    resource: `lptk/${lptkId}/dosen`,
    dataProviderName: 'lms',
  })

  const invalidate = useInvalidate()

  useEffect(() => {
    setFilters([
      {
        field: 'email',
        operator: 'eq',
        value: '',
      },
    ])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const {
    modalProps: createModalProps,
    formProps: createFormProps,
    submit: createSubmit,
    show: createShow,
  } = useModalForm({
    action: 'create',
    resource: `lt/lptk/${lptkId}/dosen`,
    submit: (formValue) => {
      return {
        dosen: [formValue],
      }
    },
    dataProviderName: 'lms',
    autoResetForm: true,
    redirect: false,

    errorNotification: (err) =>
      showErrorNotification(err, 'There was an error when adding dosen'),
    onMutationSuccess: () => {
      invalidate({
        resource: `lptk/${lptkId}/dosen`,
        dataProviderName: 'lms',
        invalidates: ['list'],
      })
    },
  })

  const {
    modalProps: editModalProps,
    formProps: editFormProps,
    show: editShow,
  } = useModalForm({
    action: 'edit',
    resource: `lptk/${lptkId}/dosen`,
    dataProviderName: 'lms',
    autoResetForm: true,
    redirect: false,

    errorNotification: (err) =>
      showErrorNotification(err, 'There was an error when updating dosen data'),
    onMutationSuccess: () => {
      invalidate({
        resource: `lptk/${lptkId}/dosen`,
        dataProviderName: 'lms',
        invalidates: ['list'],
      })
    },
  })

  const {
    modalProps: bulkCreateModalProps,
    formProps: bulkCreateFormProps,
    submit: bulkCreateSubmit,
    show: bulkCreateShow,
    close: bulkCreateClose,
  } = useModalForm({
    action: 'create',
    resource: `lt/lptk/${lptkId}/dosen`,
    submit: (formValue) => {
      return {
        dosen: formValue.data,
      }
    },
    errorNotification(error: any) {
      return {
        description: 'Error',
        type: 'error',
        message:
          (error as TLMSCommonError).response?.data.error.details?.[0]
            ?.message ||
          (error as TLMSCommonError).response?.data.error.message ||
          'There was a problem when uploading Pamong',
      }
    },
    dataProviderName: 'lms',
    autoResetForm: true,
    redirect: false,
    onMutationSuccess: () => {
      invalidate({
        resource: `lptk/${lptkId}/dosen`,
        dataProviderName: 'lms',
        invalidates: ['list'],
      })
    },
  })

  const { data: studyFieldList } = useList({
    resource: 'kv?key=lms_ppg_study_field',
    dataProviderName: 'microlearning',
    queryOptions: {
      enabled: !!bulkCreateModalProps.visible,
    },
  })

  const studyFieldMap = useMemo(() => {
    const { data } = studyFieldList || {}
    const studyFieldMap = new Map()

    data?.forEach((item: any) => {
      studyFieldMap.set(item.label, item.value)
    })

    return studyFieldMap
  }, [studyFieldList])

  const handleDownloadData = async () => {
    await mutate({
      // @ts-ignore hack to mutate get
      method: 'get',
      url: `${process.env.LMS_API_URL}/lptk/${lptkId}/dosen/download`,

      dataProviderName: 'lms',
      successNotification(data: any) {
        const dateDDMMYYYY = new Date().toLocaleDateString('id-ID', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        })

        data &&
          downloadTemplate(data?.data as string, dateDDMMYYYY, 'DATA_DOSEN')

        return {
          message: 'Memulai Download Data',
          description: 'Memulai proses download',
          type: 'success',
        }
      },
    })
  }

  return (
    <>
      <div title={'List Dosen'}>
        <div className="flex flex-row w-full justify-between items-center gap-2 mb-4">
          <div>
            {enableFilter && (
              <Form layout="inline">
                <Form.Item name="email">
                  <Input
                    prefix={<Icons.SearchOutlined className="mr-4" />}
                    onPressEnter={(e) => {
                      setFilters([
                        {
                          field: 'email',
                          operator: 'eq',
                          // @ts-ignore
                          value: e.target.value,
                        },
                      ])
                    }}
                    placeholder="Cari nama atau email"
                    autoComplete="off"
                    allowClear
                  />
                </Form.Item>
              </Form>
            )}
          </div>
          <div className="flex gap-2">
            <Button onClick={bulkCreateShow}>Unggah CSV</Button>
            <Button onClick={createShow}>Tambah Dosen</Button>
            {lmsRole === 'admin-lptk' && (
              <Button onClick={handleDownloadData} className="ml-4">
                Download Data
              </Button>
            )}
          </div>
        </div>
        <Table
          {...tableProps}
          rowKey="id"
          pagination={{
            ...tableProps.pagination,
            showSizeChanger: true,
          }}
        >
          <Table.Column
            dataIndex="id"
            title="ID"
            render={(value) => <TextField value={value} />}
          />
          <Table.Column
            dataIndex="name"
            title="Nama"
            render={(value) => <TextField value={value} />}
          />
          <Table.Column
            dataIndex="email"
            title="Email"
            render={(value) => <TextField value={value} />}
          />
          {!isMasterData && (
            <Table.Column
              dataIndex="encodedId"
              title="encoded ID"
              render={(value) => <TextField value={value} />}
            />
          )}
          <Table.Column
            dataIndex="phone"
            title="No. Hp"
            render={(value) => <TextField value={value} />}
          />
          <Table.Column
            dataIndex="studyField"
            title="Bidang Studi"
            render={(value) => <TextField value={value} />}
          />

          <Table.Column
            dataIndex="nidnNidk"
            title="NIDN / NIDK"
            render={(value) => <TextField value={value} />}
          />

          <Table.Column
            title="Actions"
            render={(_, record: any) => (
              <Space direction="vertical">
                <EditButton
                  size="small"
                  block
                  onClick={() => editShow(record.id)}
                />
                {/* <PopDeleteConfirm
                  title={`Are you sure want to delete Dosen "${record.name}"?`}
                  placeholder="Tulis ulang nama Dosen"
                  onConfirm={() =>
                    mutateAsync({
                      id: record.id,
                      resource: `lptk/${lptkId}/dosen`,
                      dataProviderName: 'lms',
                    })
                  }
                  okButtonProps={{
                    danger: true,
                    type: 'default',
                  }}
                  okText="Delete"
                  validateValue={record.name}
                  errorMessage="Dosen name did not match"
                >
                  <Button
                    size="small"
                    danger
                    block
                    icon={<Icons.DeleteOutlined />}
                  >
                    Delete
                  </Button>
                </PopDeleteConfirm> */}
              </Space>
            )}
          />
        </Table>
      </div>
      <ModalFormDosen
        modalProps={createModalProps}
        formProps={createFormProps}
        mode="create"
        onSubmit={createSubmit}
      />

      <ModalFormDosen
        modalProps={editModalProps}
        formProps={editFormProps}
        mode="edit"
      />

      <ModalParseCSV
        modalProps={bulkCreateModalProps}
        formProps={bulkCreateFormProps}
        onClose={bulkCreateClose}
        onSubmit={bulkCreateSubmit}
        validCSVHeaders={['name', 'email', 'phone', 'studyField', 'nidnNidk']}
        dataIndex="dosen"
        presetValidate={[
          {
            name: 'studyField',
            mapEntry: studyFieldMap,
          },
        ]}
      />
    </>
  )
}
