import { useNode } from '@craftjs/core'
import { Empty } from '@pankod/refine-antd'
import clsx from 'clsx'
import React from 'react'

import { ContentWrapperCustomProps, WIDGET_NAME } from '../../schema'

const ContentWrapper: React.FC & { craft: any } = ({ children }) => {
  const {
    connectors: { connect, drag },
  } = useNode()
  return (
    <div
      style={{ backgroundColor: '#E5E5E5', padding: 0, minHeight: 'inherit' }}
      ref={(ref) => connect(drag(ref!))}
      data-widgetcomponent={WIDGET_NAME.ContentWrapper}
      className={clsx(
        !children && 'min-h-[612px] flex align-middle justify-center'
      )}
    >
      {children}
      {!children && (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          className="border border-dashed border-default p-4 !m-4 w-full grid place-content-center"
          description={
            <div className="text-default">
              Drag from Widget Components to add <br />
              <b>Container</b> / <b>(Generic) Page Card Widget</b>
            </div>
          }
        />
      )}
    </div>
  )
}

ContentWrapper.craft = {
  rules: {
    canDrag: () => false,
  },
  custom: ContentWrapperCustomProps,
}

export { ContentWrapper }
