import { Card, Icons, Space } from '@pankod/refine-antd'
import React, { useState } from 'react'

import { SemesterTabPane } from './SemesterTabPane'
import { KelolaRombelButton } from '../KelolaRombelButton'
import { DrawerKelolaNilai } from '../DrawerKelolaNilai'

type SemesterDetailProps = {
  angkatanId: number
  lptkId: number
}
export const SemesterDetail = ({ angkatanId, lptkId }: SemesterDetailProps) => {
  const [semester, setSemester] = useState<string>('1')

  return (
    <Card
      title="Detail Angkatan"
      extra={
        <Space direction="horizontal" className="gap-4">
          <DrawerKelolaNilai angkatanId={angkatanId} lptkId={lptkId}>
            Kelola Nilai Mutu
          </DrawerKelolaNilai>
          <KelolaRombelButton
            angkatanId={angkatanId}
            icon={<Icons.PartitionOutlined />}
          >
            Kelola Rombel
          </KelolaRombelButton>
        </Space>
      }
      onTabChange={(key) => {
        const [, , currentSemester] = key.split('_')
        setSemester(currentSemester || '1')
      }}
      tabList={[
        {
          key: `${lptkId}_${angkatanId}_1`,
          tab: 'Semester 1',
        },
        {
          key: `${lptkId}_${angkatanId}_2`,
          tab: 'Semester 2',
        },
      ]}
    >
      <SemesterTabPane
        angkatanId={angkatanId}
        lptkId={lptkId}
        semester={semester}
      />
    </Card>
  )
}
