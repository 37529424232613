import { Form, Popconfirm, Input, Button } from '@pankod/refine-antd'
import { useInvalidate, useUpdate } from '@pankod/refine-core'

import { showErrorNotification } from '@resources/angkatan-ppg-management/utils'

export const PopConfirmScheduleForm = ({
  record,
  formType,
  title,
  disabled,
}: any) => {
  const [form] = Form.useForm()
  const { mutateAsync } = useUpdate()
  const invalidate = useInvalidate()

  const regex = /^(http|https):\/\/[^ "]+$/

  const singleFormValue = Form.useWatch(formType, form)

  return (
    <Popconfirm
      icon={null}
      className="mb-2"
      disabled={disabled}
      title={
        <Form
          form={form}
          key={`${formType}-${record.id}`}
          layout="vertical"
          initialValues={{
            [formType]: record[formType],
          }}
        >
          <Form.Item
            label={`URL untuk ${title}`}
            className="!mb-0"
            name={formType}
            rules={[
              {
                validator(_, value) {
                  // if valid url regex
                  if (!regex.test(value)) {
                    return Promise.reject(new Error('URL tidak valid'))
                  }

                  return Promise.resolve()
                },
              },
            ]}
          >
            <Input placeholder={`Masukkan URL ${title}`} />
          </Form.Item>
        </Form>
      }
      onVisibleChange={(visible) => {
        if (visible) form.resetFields()
      }}
      okButtonProps={{
        disabled:
          !regex.test(singleFormValue) || singleFormValue === record[formType],
      }}
      onConfirm={async () => {
        const newFormValue = form.getFieldValue(formType)

        await mutateAsync(
          {
            resource: `instructor/webinars-schedule`,
            dataProviderName: 'lms',
            id: record.webinarKelasGroupId,
            values: {
              [formType]: newFormValue,
            },
            successNotification: () => {
              // close modal
              form.resetFields()

              return {
                type: 'success',
                message: `Berhasil mengubah URL ${title}`,
              }
            },
            errorNotification: (err) =>
              showErrorNotification(err, 'Terdapat gangguan saat mengubah URL'),
          },
          {
            onSuccess: () => {
              invalidate({
                dataProviderName: 'lms',
                invalidates: ['list'],
                resource: 'instructor/webinars-schedule',
              })
            },
          }
        )
      }}
    >
      <Button disabled={disabled}>
        {record[formType] ? 'Ubah' : 'Tambah'} URL {title}
      </Button>
    </Popconfirm>
  )
}
