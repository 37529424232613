import {
  DateField,
  Drawer,
  Icons,
  Table,
  Tag,
  TextField,
} from '@pankod/refine-antd'
import { useResource, HttpError } from '@pankod/refine-core'
import React, { useCallback, useState } from 'react'

import useTableWithMeta from 'src/hooks/useTableWithMeta'
import { PresensiPesertaStatistic } from '../../../types'

export const usePesertaDrawer = () => {
  const [showId, setShowId] = useState<{
    show: boolean
    kelasId: string | number
    kelasName: string
  }>({ show: false, kelasId: '', kelasName: '' })
  const onClose = useCallback(() => {
    setShowId((state) => ({
      ...state,
      show: false,
    }))
  }, [])

  return {
    show: (id: number | string, kelasName: string) => {
      setShowId({ show: true, kelasId: id, kelasName })
    },
    visible: !!showId.show,
    id: showId.kelasId,
    kelasName: showId.kelasName,
    onClose,
  }
}

const getTagStatus = (state: PresensiPesertaStatistic['presensi']) => {
  switch (state) {
    case 'PRESENSI_PESERTA_ALPHA':
      return (
        <Tag icon={<Icons.CloseCircleOutlined />} color="error">
          Absen
        </Tag>
      )
    case 'PRESENSI_PESERTA_HADIR':
      return (
        <Tag icon={<Icons.CheckCircleOutlined />} color="success">
          Hadir
        </Tag>
      )
    case 'PRESENSI_PESERTA_TERLAMBAT':
      return (
        <Tag icon={<Icons.ClockCircleOutlined />} color="warning">
          Terlambat
        </Tag>
      )
    default:
      return <Tag icon={<Icons.WarningOutlined />}>Belum Diisi</Tag>
  }
}

type PesertaDrawerProps = {
  visible: boolean
  id: number | string
  onClose: () => void
  kelasName: string
  aktivitasId: number | string
  moduleId: number | string
}

const PesertaDrawer = ({
  aktivitasId,
  id,
  kelasName,
  moduleId,
  onClose,
  visible,
}: PesertaDrawerProps) => {
  const { id: programId } = useResource()
  const { tableProps, tableQueryResult } = useTableWithMeta<
    PresensiPesertaStatistic,
    HttpError,
    unknown,
    { lastUpdatedAt: string }
  >({
    dataProviderName: 'lms',
    resource: `programs/${programId}/presensi-murid/program-modules/${moduleId}/aktivitas/${aktivitasId}/kelas/${id}/murid`,
    queryOptions: {
      enabled: !!programId && !!id,
    },
  })
  const handleClose = () => {
    onClose()
  }
  const lastUpdatedAt = tableQueryResult?.data?.meta?.lastUpdatedAt
  return (
    <Drawer
      title={`Daftar Peserta | Kelas: ${kelasName}`}
      visible={visible}
      onClose={handleClose}
      width={'70%'}
      destroyOnClose
    >
      <Table
        {...tableProps}
        rowKey="userId"
        bordered
        footer={() =>
          lastUpdatedAt && (
            <TextField
              italic
              value={
                <div className="flex gap-2 items-center">
                  Diperbarui pada:
                  <DateField
                    value={lastUpdatedAt}
                    format="DD MMM YYYY HH:mm:ss"
                  />
                </div>
              }
            />
          )
        }
        columns={[
          {
            title: 'Nama Peserta',
            dataIndex: 'nama',
          },
          {
            title: 'Status Presensi',
            dataIndex: 'presensi',
            render: (value) => (
              <div className="uppercase">{getTagStatus(value)}</div>
            ),
          },
        ]}
      />
    </Drawer>
  )
}

export default PesertaDrawer
