const trimDecimalString = (value?: string | number): string => {
  if (!value) return '0'
  const str = String(value)
  const regex = /(?:\.\w{2})/g
  const result = regex.exec(str)
  if (!result) return str
  return str.split('.', 1)[0] + result[0]
}

export default trimDecimalString
