import {
  // Button,
  // Descriptions,
  // EditButton,
  // Icons,
  Result,
  Show,
  // Space,
  Tabs,
  // Tag,
  // useModalForm,
} from '@pankod/refine-antd'
import {
  useResource,
  // useOne,
  useNavigation,
  IResourceComponentsProps,
  // useDelete,
  // useCan,
} from '@pankod/refine-core'
import Head from 'next/head'
import { useRouter } from 'next/router'
import React from 'react'

// import { PopDeleteConfirm } from '@components/PopDeleteConfirm'
import { trackTabChange } from 'src/helpers/url'
// import { showErrorNotification } from '@resources/angkatan-ppg-management/utils'
import { NestedBreadcrumb } from '@resources/lms-management/components/NestedBreadcrumb'
// import { ModalProgramLokalForm } from './block/ModalProgramLocalForm'
// import { TableDasusKelasList } from './block/TableDasusKelasList'
import { FormBulkClassFasilAssign } from './block/FormBulkClassFasilAssign'
const PROGRAM_RESOURCE = 'program-dasus'

export const DasusProgramLocalManagementShow: React.FC<
  IResourceComponentsProps
> = ({ options }) => {
  const router = useRouter()
  const { id } = useResource({
    resourceNameOrRouteName: 'lms-program-local-management',
  })
  // const [activeKey, setActiveKey] = React.useState(
  //   router.query.defaultTab || 'kelas'
  // )

  // const mockDetailProgramData = {
  //   data: {
  //     id: '1',
  //     name: 'Program 1',
  //     startDate: '2021-01-01',
  //     endDate: '2021-01-10',
  //     status: 'berjalan',
  //   },
  // }

  const { listUrl, showUrl } = useNavigation()
  const dasusProgramId = router.query.dasusProgramId as string
  const PROGRAM_LOCAL_RESOURCE = `${PROGRAM_RESOURCE}/${dasusProgramId}/local-programs`
  // const { data: programLocalData, isLoading } = useOne({
  //   resource: PROGRAM_LOCAL_RESOURCE,
  //   dataProviderName: 'lms',
  //   id,
  //   queryOptions: {
  //     enabled: !!dasusProgramId,
  //   },
  // })

  // const { data: canShowProgram } = useCan({
  //   action: 'show',
  //   resource: 'dasus-program-management',
  // })

  // const { data: programData } = useOne({
  //   resource: PROGRAM_RESOURCE,
  //   dataProviderName: 'lms',
  //   id: dasusProgramId,
  //   queryOptions: {
  //     enabled: Boolean(!!dasusProgramId && !!canShowProgram?.can),
  //   },
  // })

  // const {
  //   modalProps: editModalProps,
  //   formProps: editFormProps,
  //   show: editShow,
  //   close: editClose,
  // } = useModalForm({
  //   action: 'edit',
  //   autoSubmitClose: true,
  //   resource: PROGRAM_LOCAL_RESOURCE,
  //   dataProviderName: 'lms',
  //   autoResetForm: true,
  //   redirect: false,
  //   successNotification: {
  //     message: 'Program local successfully edited',
  //     type: 'success',
  //     description: 'Successful',
  //   },
  //   errorNotification: (error) =>
  //     showErrorNotification(
  //       error,
  //       'There was a problem when editing program local'
  //     ),
  // })

  // const data = mockDetailProgramData.data

  // const { mutateAsync: doDelete } = useDelete()

  if (!dasusProgramId)
    return (
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
      />
    )

  return (
    <>
      <Show
        title="Program Local Detail"
        resource={PROGRAM_LOCAL_RESOURCE}
        dataProviderName="lms"
        // isLoading={isLoading}
        canDelete={false}
        breadcrumb={
          <NestedBreadcrumb
            injectedItems={[
              {
                label: 'Program',
                href: listUrl('dasus-program-management'),
              },
              {
                label: dasusProgramId,
                href:
                  showUrl('dasus-program-management', dasusProgramId) +
                  `?activeTab=programLocal`,
              },
              {
                label: `Program Local - ${id}`,
              },
            ]}
          />
        }
        // headerButtons={() => (
        //   <Space>
        //     {activeKey === 'detail' && (
        //       <>
        //         <EditButton
        //           onClick={() => {
        //             // editShow(id)
        //           }}
        //         >
        //           Edit Program Local
        //         </EditButton>
        //         <PopDeleteConfirm
        //           title={`Are you sure want to delete Program Local "${programLocalData?.data.data.name}"?`}
        //           placeholder="Type Program Local name to confirm"
        //           errorMessage="Program Local Name did not match"
        //           placement="bottomRight"
        //           onConfirm={() =>
        //             doDelete(
        //               {
        //                 id,
        //                 resource: PROGRAM_LOCAL_RESOURCE,
        //                 dataProviderName: 'lms',
        //                 successNotification: {
        //                   message: 'Program Local successfully deleted',
        //                   type: 'success',
        //                   description: 'Successful',
        //                 },
        //                 errorNotification: (err) =>
        //                   showErrorNotification(
        //                     err,
        //                     'There was a problem when deleting Program Local'
        //                   ),
        //               },
        //               {
        //                 onSuccess: () => goBack(),
        //               }
        //             )
        //           }
        //           okButtonProps={{
        //             danger: true,
        //             type: 'default',
        //           }}
        //           okText="Delete"
        //           validateValue={programLocalData?.data.data.name!}
        //         >
        //           <Button danger icon={<Icons.DeleteOutlined />}>
        //             Delete Program Local
        //           </Button>
        //         </PopDeleteConfirm>
        //       </>
        //     )}
        //   </Space>
        // )}
      >
        <Head>
          <title>LMS Admin | {options?.label} </title>
        </Head>
        <Tabs
          defaultActiveKey="manage"
          destroyInactiveTabPane
          onChange={(activeKey) => {
            // setActiveKey(activeKey)
            trackTabChange(router, activeKey)
          }}
        >
          <Tabs.TabPane tab="Manage Class Fasil" key="manage">
            <FormBulkClassFasilAssign
              dasusProgramId={dasusProgramId}
              dasusProgramLocalId={id}
            />
          </Tabs.TabPane>
        </Tabs>
      </Show>
    </>
  )
}
