import {
  Descriptions,
  Divider,
  Form,
  List,
  Select,
  Table,
  TextField,
  useTable,
} from '@pankod/refine-antd'
import {
  IResourceComponentsProps,
  GetListResponse,
  CrudFilters,
  useOne,
  useList,
  useGetIdentity,
} from '@pankod/refine-core'
import Head from 'next/head'
import React from 'react'
import { useRouter } from 'next/router'
import clsx from 'clsx'

import { TFilterForm } from '@components/ContentFilter'
import { TCommonError } from 'src/interfaces/common'
import { TLMSProgram } from '@resources/lms-management/program/types'

const GRADE_REPORT_RESOURCE = 'grades/programs'

export const ShowStudentsGradeReportList: React.FC<
  IResourceComponentsProps<GetListResponse<TLMSProgram>>
> = ({ options }) => {
  const router = useRouter()
  const { programId } = router.query
  const { data } = useGetIdentity()
  const lmsRole = data?.user?.LMSRole || ''
  const isCurrentUserAdminEntitas = lmsRole === 'admin-entitas'

  const [selectedProgramOptions, setSelectedProgramOptions] = React.useState({
    programId: (programId as string) || '',
    localProgramId: '',
    classId: '',
  })

  const {
    data: programList,
    isFetching: isProgramListFetching,
    isFetched: isProgramListFetched,
  } = useList({
    resource: 'grades/programs',
    queryOptions: {
      enabled: lmsRole !== 'admin-entitas',
    },
    config: {
      filters: [
        {
          field: 'pageSize',
          operator: 'eq',
          value: -1,
        },
      ],
    },
  })

  const {
    data: localProgramList,
    isFetching: isLocalProgramFetching,
    isFetched: isLocalProgramFetched,
  } = useList({
    resource: `${isCurrentUserAdminEntitas ? 'entities/' : ''}programs/${
      selectedProgramOptions.programId
    }/local-programs`,
    queryOptions: {
      enabled: !!selectedProgramOptions.programId,
    },
    config: {
      filters: [
        {
          field: 'pageSize',
          operator: 'eq',
          value: -1,
        },
      ],
    },
  })

  const { data: gradePredicate, isFetched: isPredicateFetched } = useOne({
    resource: `programs/${selectedProgramOptions.programId}`,
    id: 'grades',
    queryOptions: {
      enabled: !!selectedProgramOptions.programId,
    },
  })

  const { data: classList, isFetched: isClassListFetched } = useList({
    resource: `programs/${selectedProgramOptions.programId}/local-programs/${selectedProgramOptions.localProgramId}/classes`,
    queryOptions: {
      enabled:
        !!selectedProgramOptions.programId &&
        !!selectedProgramOptions.localProgramId,
    },
    config: {
      filters: [
        {
          field: 'pageSize',
          operator: 'eq',
          value: -1,
        },
      ],
    },
  })

  const { tableProps, searchFormProps, setFilters } = useTable<
    TLMSProgram,
    TCommonError,
    TFilterForm<TLMSProgram>
  >({
    resource: `${GRADE_REPORT_RESOURCE}/${selectedProgramOptions.programId}/students`,
    dataProviderName: 'lms',
    queryOptions: {
      enabled: !!selectedProgramOptions.programId,
    },

    onSearch: (params) => {
      const filters: CrudFilters = []
      const { classId, localProgramId }: any = params

      filters.push({
        field: 'classId',
        operator: 'eq',
        value: classId,
      })

      filters.push({
        field: 'localProgramId',
        operator: 'eq',
        value: localProgramId,
      })
      return filters
    },
  })

  const getGradesLabelByRange = (userGrade: number) => {
    const grade = gradePredicate?.data.data.find((grade: any) => {
      const roundedGrade = Math.floor(userGrade)

      return roundedGrade >= grade.from && roundedGrade <= grade.to
    })

    return grade?.label
  }

  return (
    <>
      <List
        headerProps={{
          onBack: () => {
            router.back()
          },
        }}
        title="Daftar Nilai Murid"
      >
        <Head>
          <title>LMS Admin | {options?.label}</title>
        </Head>

        <div className="max-w-sm">
          {gradePredicate?.data.data?.length > 0 && (
            <Descriptions
              title="Predikat Rentang Nilai"
              column={1}
              bordered
              size="small"
            >
              {gradePredicate?.data.data.map((grade: any) => {
                return (
                  <Descriptions.Item label={grade.label} key={grade.label}>
                    {grade.from} - {grade.to}
                  </Descriptions.Item>
                )
              })}
            </Descriptions>
          )}
        </div>

        <Divider />

        <div
          className={clsx(
            'flex  mb-4',
            !selectedProgramOptions.programId
              ? 'pt-[5vh] text-center justify-center flex-col'
              : 'flex-row'
          )}
        >
          {!selectedProgramOptions.programId && (
            <>
              <div>
                <h2>
                  Pilih{' '}
                  {isCurrentUserAdminEntitas ? 'Program Lokal' : 'Program'}{' '}
                  untuk melihat daftar nilai:
                </h2>
              </div>

              <div>
                <Select
                  style={{
                    width: '50vw',
                  }}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option: any) =>
                    (option?.children ?? '')
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  size="large"
                  placeholder={`Cari Program ${
                    isCurrentUserAdminEntitas ? 'Lokal' : ''
                  }`}
                  loading={
                    isCurrentUserAdminEntitas
                      ? isLocalProgramFetching || !isLocalProgramFetched
                      : isProgramListFetching || !isProgramListFetched
                  }
                  onChange={(_, options: any) => {
                    const { value, programId } = options

                    if (isCurrentUserAdminEntitas) {
                      setFilters([
                        {
                          field: 'localProgramId',
                          operator: 'eq',
                          value: value,
                        },
                        {
                          field: 'classId',
                          operator: 'eq',
                          value: null,
                        },
                      ])

                      setSelectedProgramOptions({
                        localProgramId: value,
                        programId: `${programId}`,
                        classId: '',
                      })
                      return
                    }

                    setSelectedProgramOptions({
                      localProgramId: '',
                      programId: `${value}`,
                      classId: '',
                    })
                  }}
                >
                  {isCurrentUserAdminEntitas
                    ? isLocalProgramFetched &&
                      localProgramList?.data?.map((localProgram) => {
                        return (
                          <Select.Option
                            value={localProgram.id}
                            programId={localProgram.programId}
                            key={`initial-local-program-options-${localProgram.id}`}
                          >
                            {localProgram.name}
                          </Select.Option>
                        )
                      })
                    : isProgramListFetched &&
                      programList?.data?.map((programData) => {
                        return (
                          <Select.Option
                            value={programData.id}
                            key={`initial-program-list-options-${programData.id}`}
                          >
                            {programData.name}
                          </Select.Option>
                        )
                      })}
                </Select>
              </div>
            </>
          )}

          {selectedProgramOptions.programId && (
            <>
              {!isCurrentUserAdminEntitas && (
                <div>
                  <Select
                    style={{
                      width: '300px',
                      marginRight: '1rem',
                    }}
                    placeholder="Cari Program"
                    loading={isProgramListFetching || !isProgramListFetched}
                    value={selectedProgramOptions.programId}
                    onChange={async (_, options: any) => {
                      const { value } = options

                      setFilters([
                        {
                          field: 'classId',
                          operator: 'eq',
                          value: null,
                        },
                      ])

                      setFilters([
                        {
                          field: 'localProgramId',
                          operator: 'eq',
                          value: null,
                        },
                      ])

                      await setSelectedProgramOptions({
                        localProgramId: '',
                        programId: `${value}`,
                        classId: '',
                      })

                      searchFormProps?.form?.resetFields()
                    }}
                  >
                    {isProgramListFetched &&
                      programList?.data?.map((programData) => {
                        return (
                          <Select.Option
                            value={programData.id}
                            key={`program-list-options-${programData.id}`}
                          >
                            {programData.name}
                          </Select.Option>
                        )
                      })}
                  </Select>
                </div>
              )}

              <Form {...searchFormProps} layout="inline">
                <Form.Item
                  name="localProgramId"
                  initialValue={
                    selectedProgramOptions.localProgramId || undefined
                  }
                >
                  <Select
                    style={{
                      width: '300px',
                    }}
                    placeholder="Pilih Program Lokal"
                    loading={isLocalProgramFetching || !isLocalProgramFetched}
                    onChange={(_, options: any) => {
                      const { value, programId } = options

                      setFilters([
                        {
                          field: 'classId',
                          operator: 'eq',
                          value: null,
                        },
                      ])

                      searchFormProps?.form?.resetFields(['classId'])

                      searchFormProps?.form?.submit()

                      setSelectedProgramOptions({
                        localProgramId: value,
                        programId: `${programId}`,
                        classId: '',
                      })
                    }}
                  >
                    {isLocalProgramFetched &&
                      localProgramList?.data?.map((localProgram) => {
                        return (
                          <Select.Option
                            value={localProgram.id}
                            programId={localProgram.programId}
                            key={`localprogrma-options-${localProgram.id}`}
                          >
                            {localProgram.name}
                          </Select.Option>
                        )
                      })}
                  </Select>
                </Form.Item>
                <Form.Item name="classId">
                  <Select
                    placeholder={
                      selectedProgramOptions.localProgramId
                        ? 'Pilih Kelas'
                        : 'Pilih Program Lokal terlebih dahulu'
                    }
                    style={{
                      marginLeft: 8,
                      width: '300px',
                    }}
                    onChange={(value) => {
                      searchFormProps?.form?.submit()
                      setSelectedProgramOptions((prev) => ({
                        ...prev,
                        classId: value,
                      }))
                    }}
                    disabled={!selectedProgramOptions.localProgramId}
                  >
                    {isClassListFetched &&
                      classList?.data?.map((studentClass) => {
                        return (
                          <Select.Option
                            value={studentClass.id}
                            key={`student-options-${studentClass.id}`}
                          >
                            {studentClass.name}
                          </Select.Option>
                        )
                      })}
                  </Select>
                </Form.Item>
              </Form>
            </>
          )}
        </div>

        {!!selectedProgramOptions.programId && (
          <Table {...tableProps} rowKey="userId">
            <Table.Column
              dataIndex="name"
              title="Nama"
              render={(value) => <TextField value={value} />}
            />
            <Table.Column
              dataIndex="email"
              title="Email"
              render={(value) => <TextField value={value} />}
            />
            <Table.Column
              dataIndex="classInfo"
              title="Kelas"
              render={(value) => <TextField value={value.name} />}
            />
            <Table.Column
              dataIndex="finalScore"
              title="nilai"
              render={(value) => <TextField value={value} />}
            />
            {isPredicateFetched && (
              <Table.Column
                title="Predikat"
                render={(_, record: any) => (
                  <TextField value={getGradesLabelByRange(record.finalScore)} />
                )}
              />
            )}
          </Table>
        )}
      </List>
    </>
  )
}
