import { Select, SelectProps } from '@pankod/refine-antd'
import React from 'react'

type SelectLPTKProps = {
  selectProps: SelectProps
  loading: boolean
  value?: number
  onChange: (props: number) => void
}
const SelectLPTK = ({
  selectProps,
  onChange,
  loading,
  value,
}: SelectLPTKProps) => {
  return (
    <Select<any>
      {...selectProps}
      showSearch
      onChange={(lptkId) => {
        onChange(lptkId)
      }}
      value={value && selectProps.options?.length ? value : null}
      style={{ width: 300 }}
      size="large"
      placeholder="Cari LPTK"
      loading={loading}
      disabled={loading}
      onSearch={() => {}}
      filterOption={(input, option) => {
        return ((option?.label ?? '') as string)
          .toLowerCase()
          .includes(input.toLowerCase())
      }}
    />
  )
}

export default SelectLPTK
