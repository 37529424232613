import {
  // Button,
  DatePicker,
  // EditButton,
  Form,
  // Icons,
  Input,
  List,
  Modal,
  ShowButton,
  Space,
  Table,
  TextField,
  useModalForm,
  useTable,
} from '@pankod/refine-antd'
import {
  IResourceComponentsProps,
  GetListResponse,
  CrudFilters,
  // useRouterContext,
  // useNavigation,
} from '@pankod/refine-core'
import dayjs, { Dayjs } from 'dayjs'
import Head from 'next/head'
import React from 'react'

import { TFilterForm } from '@components/ContentFilter'
import { TCommonError } from 'src/interfaces/common'

const DASUS_PROGRAM_RESOURCE = 'program-dasus'

export const DasusProgramList: React.FC<
  IResourceComponentsProps<GetListResponse<any>>
> = ({ options }) => {
  // const { Link } = useRouterContext()
  // const { showUrl: generateShowUrl } = useNavigation()

  // const getDasusProgramConfigURL = (programId: string) => {
  //   return `${generateShowUrl(
  //     'dasus-program-management',
  //     programId!
  //   )}?activeTab=config`
  // }

  const { tableProps } = useTable<any, TCommonError, TFilterForm<any>>({
    syncWithLocation: true,

    resource: DASUS_PROGRAM_RESOURCE,
    dataProviderName: 'lms',
    onSearch: (params) => {
      const filters: CrudFilters = []
      const { status, name } = params
      filters.push(
        {
          field: 'status',
          operator: 'eq',
          value: status,
        },
        {
          field: 'name',
          operator: 'eq',
          value: name,
        },
        {
          field: 't',
          operator: 'eq',
          value: new Date().getTime(),
        }
      )
      return filters
    },
  })

  const {
    modalProps: createModalProps,
    formProps: createFormProps,
    // show: createShow,
    close: createClose,
    submit: createSubmit,
    // visible: isCreateVisible,
  } = useModalForm({
    action: 'create',
    resource: DASUS_PROGRAM_RESOURCE,
    dataProviderName: 'lms',
    autoResetForm: true,
    submit: ({ periode, ...formValues }) => {
      const [startDate, enDate] = periode as unknown as [Dayjs, Dayjs]
      return {
        name: formValues.name,
        startDate: startDate.startOf('day').format('YYYY-MM-DD'),
        endDate: enDate.endOf('day').format('YYYY-MM-DD'),
      }
    },
    redirect: false,
  })

  // const {
  //   modalProps: editModalProps,
  //   formProps: editFormProps,
  //   show: editShow,
  //   submit: editSubmit,
  //   // visible: isEditVisible,
  // } = useModalForm({
  //   action: 'edit',
  //   resource: DASUS_PROGRAM_RESOURCE,
  //   dataProviderName: 'lms',
  //   autoResetForm: true,
  //   submit: ({ periode, ...formValues }) => {
  //     const [startDate, enDate] = periode as unknown as [Dayjs, Dayjs]
  //     return {
  //       name: formValues.name,
  //       startDate: startDate.startOf('day').format('YYYY-MM-DD'),
  //       endDate: enDate.endOf('day').format('YYYY-MM-DD'),
  //     }
  //   },
  //   redirect: false,
  // })

  return (
    <>
      <List
        title="Dasus Program Management"
        // createButtonProps={{
        //   children: 'Create New Program',
        //   onClick: () => {
        //     createShow()
        //   },
        // }}
      >
        <Head>
          <title>Program Dasus | {options?.label}</title>
        </Head>

        <Table {...tableProps} rowKey="id">
          <Table.Column
            dataIndex="id"
            title="ID"
            render={(value) => <TextField value={value} />}
          />
          <Table.Column
            dataIndex="name"
            title="Nama Program"
            render={(value) => <TextField value={value} />}
          />
          <Table.Column
            dataIndex="startDate"
            title="Start Date"
            render={(value) => (
              <TextField value={dayjs(value).format('DD MMM YYYY')} />
            )}
          />
          <Table.Column
            dataIndex="endDate"
            title="End Date"
            render={(value) => (
              <TextField value={dayjs(value).format('DD MMM YYYY')} />
            )}
          />
          <Table.Column<any>
            title="Actions"
            render={(_, record) => (
              <Space direction="vertical">
                <ShowButton block size="small" recordItemId={record.id} />
                {/* <EditButton
                  size="small"
                  block
                  recordItemId={record.id}
                  onClick={() => {
                    editShow(record.id)
                  }}
                /> */}
                {/* <Link to={getDasusProgramConfigURL(record.id)} replace={false}>
                  <Button size="small" block icon={<Icons.SettingOutlined />}>
                    Config
                  </Button>
                </Link> */}
              </Space>
            )}
          />
        </Table>
      </List>

      {/* <Modal
        {...editModalProps}
        title="Edit Program"
        okButtonProps={{
          onClick: async () => {
            const data = await editSubmit()
            editFormProps.onFinish(data)
          },
        }}
      >
        <Form
          {...editFormProps}
          initialValues={(() => {
            const { startDate, endDate, ...rest } =
              editFormProps?.initialValues?.data || {}
            const periode = [dayjs(startDate), dayjs(endDate)]

            return {
              ...rest,
              periode,
              // blueprintId: blueprint?.id,
            }
          })()}
          layout="vertical"
          validateMessages={{ required: '${label} must be filled.' }}
        >
          <Form.Item
            label="Name"
            name="name"
            required
            rules={[{ required: true }]}
          >
            <Input autoComplete="off" />
          </Form.Item>
          <Form.Item
            label="Periode"
            name="periode"
            rules={[{ required: true }]}
            hidden
          >
            <DatePicker.RangePicker />
          </Form.Item>
        </Form>
      </Modal> */}

      <Modal
        {...createModalProps}
        title="Create New Program"
        okButtonProps={{
          onClick: async () => {
            const data = await createSubmit()
            createFormProps.onFinish(data)
          },
        }}
        onCancel={() => {
          createClose()
          createFormProps.form.resetFields()
        }}
      >
        <Form
          {...createFormProps}
          layout="vertical"
          validateMessages={{ required: '${label} must be filled.' }}
        >
          <Form.Item
            label="Name"
            name="name"
            required
            rules={[{ required: true }]}
          >
            <Input autoComplete="off" />
          </Form.Item>
          <Form.Item
            label="Periode"
            name="periode"
            rules={[{ required: true }]}
          >
            <DatePicker.RangePicker />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}
