import { Button, Form, Modal, Space, Steps } from '@pankod/refine-antd'
import {
  OpenNotificationParams,
  useCreate,
  useCustomMutation,
} from '@pankod/refine-core'
import React, { useCallback, useState } from 'react'

import EmailVerification from './EmailVerification'
import ParticipantData from './ParticipantData'
import { showErrorNotification } from '@resources/angkatan-ppg-management/utils'
import { successMigrateNotification } from '../utils'

type UseActorAdditionModalParams = {
  programId: string
  localProgramId: string
  classId: string
}

type UseActorAdditionModalReturn = {
  show: () => void
  onClose: () => void
  visible: boolean
  identifier: UseActorAdditionModalParams
}

export const useActorAdditionModal = (
  params: UseActorAdditionModalParams
): UseActorAdditionModalReturn => {
  const [visible, setVisible] = useState(false)
  const show = useCallback(() => {
    setVisible(true)
  }, [])
  const onClose = useCallback(() => {
    setVisible(false)
  }, [])
  return {
    show,
    onClose,
    visible,
    identifier: params,
  }
}

type ActorAdditionModalProps = Omit<UseActorAdditionModalReturn, 'show'> & {
  onAdditionSettled: () => void
}
const ActorAdditionModal = ({
  visible,
  onClose,
  identifier,
  onAdditionSettled,
}: ActorAdditionModalProps) => {
  const [current, setCurrent] = useState(0)
  const {
    mutateAsync: getDapodik,
    data: dapodikInfo,
    isLoading: isVerifying,
  } = useCustomMutation()
  const [formEmail] = Form.useForm()
  const [formParticipant] = Form.useForm()
  const { mutateAsync: doSubmit } = useCreate()
  const hasDapodik = !!dapodikInfo?.data?.data

  const next = () => {
    setCurrent(current + 1)
  }

  const prev = () => {
    setCurrent(current - 1)
  }

  const handleClose = () => {
    onClose()
    setCurrent(0)
    formEmail.resetFields()
    formParticipant.resetFields()
  }

  const handleGetDapodik = async () => {
    await formEmail.validateFields()
    return getDapodik({
      method: 'post',
      url: `${process.env.NEXT_PUBLIC_LMS_API_URL}/programs/${identifier.programId}/participants/dapodik`,
      values: {
        email: formEmail.getFieldValue('email'),
      },
    })
  }

  const handleSubmit = async () => {
    await formParticipant.validateFields()
    const data = formParticipant.getFieldsValue()
    const notificationConfig: {
      successNotification: false | OpenNotificationParams
      errorNotification: (error?: unknown) => OpenNotificationParams
    } = {
      successNotification: false,
      errorNotification: (err) =>
        showErrorNotification(
          err,
          'Terdapat gangguan saat mendaftarkan peserta'
        ),
    }
    const onSuccess = () => {
      successMigrateNotification({
        type: 'add',
        programId: identifier.programId,
      })
      handleClose()
    }
    if (hasDapodik) {
      return doSubmit(
        {
          resource: `programs/${identifier.programId}/local-programs/${identifier.localProgramId}/classes/${identifier.classId}/participants/additional`,
          dataProviderName: 'lms',
          values: {
            email: data.email,
            roleKelas: data.roleKelas,
          },
          ...notificationConfig,
        },
        {
          onSuccess,
          onSettled: () => {
            onAdditionSettled()
          },
        }
      )
    }
    return doSubmit(
      {
        resource: `programs/${identifier.programId}/local-programs/${identifier.localProgramId}/classes/${identifier.classId}/participants/insert-additional`,
        dataProviderName: 'lms',
        values: {
          roleKelas: data.roleKelas,
          name: data.name,
          email: data.email,
          jenisPtk: data.jenisPtk,
          nuptk: data.nuptk,
          npsn: data.npsn,
          jenjang: data.jenjang,
          kodeProvinsi: data.provinsi.kode,
          kodeKabupaten: data.kabupaten.kode,
          kodeKecamatan: data.kecamatan.kode,
          mataPelajaran: data.mataPelajaran,
        },
        ...notificationConfig,
      },
      {
        onSuccess,
        onSettled: () => {
          onAdditionSettled()
        },
      }
    )
  }

  const steps = [
    {
      title: 'Verifikasi Email',
      content: (
        <EmailVerification form={formEmail} programId={identifier.programId} />
      ),
      key: 'verifyEmailStep',
    },
    {
      title: 'Data Peserta',
      content: (
        <ParticipantData
          isReadOnly={hasDapodik}
          form={formParticipant}
          {...identifier}
        />
      ),
      key: 'actorDataStep',
    },
  ]

  const okButtonProps = {
    onClick: async () => {
      if (steps[current].key === 'verifyEmailStep') {
        const { data } = await handleGetDapodik()
        formParticipant.setFieldsValue({
          ...data.data,
          email: formEmail.getFieldValue('email'),
        })
        next()
        return
      }

      return handleSubmit()
    },
    loading: isVerifying,
  }

  const cancelButtonProps = {
    onClick: () => {
      if (steps[current].key === 'verifyEmailStep') {
        handleClose()
        return
      }
      formParticipant.resetFields()
      prev()
    },
  }

  return (
    <Modal
      title="Tambah Peserta Kelas"
      visible={visible}
      onCancel={handleClose}
      maskClosable={false}
      width="700px"
      style={{ top: '60px' }}
      footer={
        <Space>
          <Button {...cancelButtonProps}>
            {(() => {
              if (current > 0) return 'Kembali'
              return 'Batal'
            })()}
          </Button>
          <Button {...okButtonProps} type="primary" key={`${current}-okButton`}>
            {(() => {
              if (steps[current].key !== 'actorDataStep') return 'Verifikasi'
              return 'Simpan'
            })()}
          </Button>
        </Space>
      }
    >
      <Steps current={current} className="!mb-8">
        {steps.map((item) => (
          <Steps.Step key={item.key} title={item.title} />
        ))}
      </Steps>
      <div className="steps-content">{steps[current].content}</div>
    </Modal>
  )
}

export default ActorAdditionModal
