import { useDelete, useInvalidate, useResource } from '@pankod/refine-core'
import React from 'react'
import {
  Button,
  Icons,
  Table,
  TableColumnGroupType,
  TableColumnType,
  Tag,
  useModalForm,
  useTable,
} from '@pankod/refine-antd'

import { TCommonError } from 'src/interfaces/common'
import { LPTKBatchResponse } from '../../types'
import { HeaderTitle } from '@components/HeaderTableTitle'
import { PopDeleteConfirm } from '@resources/lms-management/components/PopDeleteConfirm'
import { TableTitle } from '@components/TableTitle'
import { LPTKAssignModalForm } from './LPTKAssignModalForm'
import { showErrorNotification } from '@resources/angkatan-ppg-management/utils'

export const LPTKTab = () => {
  const resource = useResource()
  const id = resource.id as number
  const invalidate = useInvalidate()

  const { tableProps, tableQueryResult } = useTable<
    LPTKBatchResponse,
    TCommonError
  >({
    dataProviderName: 'lms',
    resource: `angkatan/${id}/lptk`,
  })

  const { modalProps, formProps, show, close } = useModalForm({
    action: 'create',
    resource: `angkatan/${id}/lptk/assignment`,
    dataProviderName: 'lms',
    warnWhenUnsavedChanges: true,
    redirect: false,
    autoResetForm: true,
    autoSubmitClose: true,
    successNotification: {
      message: 'LPTK successfully added',
      type: 'success',
      description: 'Successful',
    },
    errorNotification: (err) =>
      showErrorNotification(err, 'There was an error when adding LPTK'),
    onMutationSuccess: () => {
      invalidate({
        resource: `angkatan/${id}/lptk`,
        dataProviderName: 'lms',
        invalidates: ['list'],
      })
    },
  })
  const { mutateAsync: doUnassign, isLoading } = useDelete()

  const tableColumns: (
    | TableColumnType<LPTKBatchResponse>
    | TableColumnGroupType<LPTKBatchResponse>
  )[] = [
    {
      title: <HeaderTitle align="left" title="ID" />,
      key: 'id',
      dataIndex: 'id',
      width: '5%',
    },
    {
      title: <HeaderTitle align="left" title="Nama LPTK" />,
      key: 'name',
      dataIndex: 'name',
    },
    {
      title: <HeaderTitle align="left" title="Status Semester 1" />,
      key: 'firstSemesterFinalized',
      dataIndex: 'firstSemesterFinalized',
      render: (_, record) => {
        return (
          <Tag color={record.firstSemesterFinalized ? 'green' : 'yellow'}>
            {record.firstSemesterFinalized ? 'Final' : 'Belum Final'}
          </Tag>
        )
      },
    },
    {
      title: <HeaderTitle align="left" title="Status Semester 2" />,
      key: 'secondSemesterFinalized',
      dataIndex: 'secondSemesterFinalized',
      render: (_, record) => {
        return (
          <Tag color={record.secondSemesterFinalized ? 'green' : 'yellow'}>
            {record.secondSemesterFinalized ? 'Final' : 'Belum Final'}
          </Tag>
        )
      },
    },
    {
      key: 'actions',
      align: 'center',
      width: '10%',
      render: (_, record) => (
        <PopDeleteConfirm
          children={
            <Button icon={<Icons.DeleteOutlined />} danger>
              Hapus
            </Button>
          }
          title="Apakah Anda yakin ingin menghapus LPTK ini?"
          okText="Hapus"
          cancelText="Kembali"
          okButtonProps={{ danger: true, loading: isLoading }}
          onConfirm={async () => {
            return doUnassign({
              resource: `angkatan/${id}/lptk/assignment`,
              id: record.id,
              successNotification: {
                message: 'LPTK successfuly removed',
                type: 'success',
                description: 'Successful',
              },
              errorNotification: (err) =>
                showErrorNotification(
                  err,
                  'There was a problem when removing LPTK'
                ),
            }).then(() => {
              invalidate({
                resource: `angkatan/${id}/lptk`,
                dataProviderName: 'lms',
                invalidates: ['list'],
              })
            })
          }}
        />
      ),
    },
  ]

  return (
    <>
      <Table
        {...tableProps}
        loading={tableQueryResult.isFetching}
        bordered
        title={() => (
          <TableTitle
            title="Daftar LPTK"
            extra={
              <Button
                icon={<Icons.PlusOutlined />}
                type="primary"
                onClick={() => show()}
              >
                Tambah LPTK
              </Button>
            }
          />
        )}
        columns={tableColumns}
        rowKey="id"
      />

      <LPTKAssignModalForm
        formProps={formProps}
        modalProps={modalProps}
        currentLptkIds={tableQueryResult?.data?.data?.map(({ id }) => id) || []}
        onClose={close}
      />
    </>
  )
}
