import {
  Button,
  Divider,
  Drawer,
  Form,
  Icons,
  Input,
  InputNumber,
  Select,
  Slider,
  useForm,
} from '@pankod/refine-antd'
import { ListButtonProps } from '@pankod/refine-antd/dist/components/buttons/list'
import React from 'react'

import { showErrorNotification } from '@resources/angkatan-ppg-management/utils'

const DECIMAL_PRECISION = 0.01

export const DrawerKelolaNilai = ({
  angkatanId,
  lptkId,
  ...props
}: ListButtonProps & {
  angkatanId: number | string
  lptkId: number | string
}) => {
  const [drawerVisible, setDrawerVisible] = React.useState(false)
  const [selectedMinimalGradeLabel, setSelectedMinimalGradeLabel] =
    React.useState('C')
  const { formProps, queryResult } = useForm({
    action: 'edit',
    resource: `angkatan/${angkatanId}/lptk/${lptkId}`,
    dataProviderName: 'lms',
    id: 'nilai',
    errorNotification: (err) =>
      showErrorNotification(err, 'Terdapat gangguan saat mengubah nilai mutu'),
    successNotification: (e: any) => {
      return {
        message: 'Berhasil Melakukan Perubahan Nilai Mutu',
        description: e.data.message || 'Berhasil mengubah nilai mutu',
        type: 'success',
      }
    },
    queryOptions: {
      onSuccess: (data) => {
        const savedGradeConfig = data?.data
        if (!savedGradeConfig) {
          setSelectedMinimalGradeLabel('C')

          return
        }

        setSelectedMinimalGradeLabel(savedGradeConfig.minimalPassingLabel)
      },
    },
    redirect: false,
  })

  const defaultConfig = {
    gradeConfig: [
      {
        label: 'A',
        minScore: 80,
      },
      {
        label: 'B',
        minScore: 70,
      },
      {
        label: 'C',
        minScore: 60,
      },
      {
        label: 'D',
        minScore: 50,
      },
      {
        label: 'E',
        minScore: 0,
      },
    ],
    minimalPassingLabel: 60,
  }

  const getMaxScore = (index: number) => {
    if (!gradeConfigForm) {
      return 0
    }
    if (index === 0) {
      return 100
    }

    return (gradeConfigForm[index - 1].minScore - DECIMAL_PRECISION).toFixed(2)
  }

  const getGradeMarks = () => {
    if (!gradeConfigForm) {
      return {
        0: 'TIDAK LULUS',
        100: 'LULUS',
      }
    }

    const marks: any = {}
    gradeConfigForm.forEach((item: any) => {
      if (!item || item.minScore === 0) {
        marks[0] = {
          label: (
            <strong>
              {item ? item.label : ''} ({0})
            </strong>
          ),
          toolbar: true,
          style: {
            color: '#f50',
            maxWidth: '80px',
          },
        }
        return
      }

      marks[item.minScore] = item.label
    })

    marks[100] = {
      label: <strong>(100)</strong>,
      style: {
        color: '#87d068',
      },
    }

    return marks
  }

  const gradeConfigForm = Form.useWatch('gradeConfig', formProps?.form) as any
  const minimalPassingLabel = Form.useWatch(
    'minimalPassingLabel',
    formProps?.form
  ) as any

  const getActualSelectedMinimalGradeValue = () => {
    const selectedGrade = gradeConfigForm?.find(
      (item: any) => item && item.label === minimalPassingLabel
    )

    return (selectedGrade?.minScore as number) || 60
  }

  const getMovingMinimalScore = () => {
    const selectedGrade = gradeConfigForm?.find(
      (item: any) => item && item.label === selectedMinimalGradeLabel
    )

    return selectedGrade?.minScore || 60
  }

  return (
    <div>
      <Button
        {...props}
        icon={<Icons.BarChartOutlined />}
        onClick={() => setDrawerVisible(true)}
      />

      <Drawer
        visible={drawerVisible}
        onClose={() => setDrawerVisible(false)}
        width={700}
        title="Kelola Nilai Mutu"
      >
        <div className="px-4">
          <div className="flex flex-row justify-between mt-8">
            <p className="font-bold m-0">Tidak Lulus</p>
            <p className="font-bold m-0">Lulus</p>
          </div>
          <Slider
            range
            max={100}
            min={0}
            marks={getGradeMarks()}
            tooltip={{
              open: true,
            }}
            // @ts-ignore
            value={[0, getMovingMinimalScore() || 1, 100]}
            trackStyle={[
              {
                backgroundColor: '#f50',
              },
              {
                backgroundColor: '#87d068',
              },
            ]}
            dots={false}
          />
        </div>

        <Divider>Pengaturan Rentang Nilai</Divider>

        <div className="flex flex-row w-full px-4">
          <p className="w-[380px] mr-8">Label Predikat</p>

          <p>Rentang Nilai</p>
        </div>
        <Form
          {...formProps}
          layout="vertical"
          initialValues={
            queryResult?.data?.data?.gradeConfig?.length > 0
              ? queryResult?.data?.data
              : defaultConfig
          }
        >
          <Form.List name="gradeConfig">
            {(fields, { add, remove }) => (
              <>
                <div className="flex flex-col w-full px-4">
                  {fields.map((field, index) => (
                    <>
                      <div
                        className="flex flex-row w-full justify-between items-start mb-4"
                        key={index}
                      >
                        <Form.Item
                          name={[field.name, 'label']}
                          className="w-[375px]"
                          initialValue={''}
                        >
                          <Input size="large" />
                        </Form.Item>

                        <Form.Item
                          name={[field.name, 'minScore']}
                          initialValue={0}
                          className="w-[30%]"
                          rules={[
                            {
                              validator: (_, value) => {
                                const currentIndexIsLast =
                                  index === gradeConfigForm.length - 1

                                const nextIndexValue =
                                  gradeConfigForm[index + 1]?.minScore || 0

                                if (
                                  !currentIndexIsLast &&
                                  value <= nextIndexValue
                                ) {
                                  return Promise.reject(
                                    new Error(
                                      'Nilai predikat tidak boleh lebih kecil dari predikat setelahnya'
                                    )
                                  )
                                }

                                if (value >= getMaxScore(index)) {
                                  return Promise.reject(
                                    new Error('Rentang tidak valid')
                                  )
                                }

                                const currentMaxScoreInvalid =
                                  !currentIndexIsLast &&
                                  Number(getMaxScore(index + 1)) <= 0
                                if (currentMaxScoreInvalid) {
                                  return Promise.reject(
                                    new Error('Rentang Nilai Tidak Valid')
                                  )
                                }

                                return Promise.resolve()
                              },
                            },
                          ]}
                        >
                          <InputNumber
                            precision={2}
                            max={Number(getMaxScore(index)) - 1}
                            min={0}
                            step={0.5}
                            disabled={index === fields.length - 1}
                            size="large"
                            addonAfter={
                              <p className="m-0">
                                sampai <b>{getMaxScore(index)}</b>
                              </p>
                            }
                          />
                        </Form.Item>

                        {gradeConfigForm &&
                        !(index === gradeConfigForm.length - 1) ? (
                          <Button
                            danger
                            size="large"
                            onClick={() => remove(field.name)}
                            icon={<Icons.DeleteOutlined />}
                          ></Button>
                        ) : (
                          <div className="ml-4" />
                        )}
                      </div>
                    </>
                  ))}

                  <Button
                    block
                    onClick={() => add()}
                    icon={<Icons.PlusOutlined />}
                    className="mb-8"
                  >
                    Tambah Predikat Baru
                  </Button>
                </div>
              </>
            )}
          </Form.List>

          <Divider>Pilih Batas Nilai Lulus</Divider>

          <div className="px-4">
            <Form.Item
              name="minimalPassingLabel"
              className="w-full"
              rules={[
                {
                  validator: (_, value) => {
                    const selectedItem = gradeConfigForm.find(
                      (item: any) => item.label === value
                    )
                    if (!selectedItem || !selectedItem.label) {
                      return Promise.reject(
                        new Error(
                          'Pilihan Batas Nilai tidak valid, silahkan pilih ulang'
                        )
                      )
                    }
                    return Promise.resolve()
                  },
                },
              ]}
            >
              <Select
                size="large"
                options={
                  (gradeConfigForm &&
                    gradeConfigForm.slice(0, -1).map((item: any) => ({
                      label: item ? item.label : '',
                      value: item ? item.label : '',
                    }))) ||
                  []
                }
                onChange={(_, option) => {
                  // @ts-ignore
                  setSelectedMinimalGradeLabel(option.label)
                }}
                defaultValue={getActualSelectedMinimalGradeValue()}
              />
            </Form.Item>
          </div>

          <Button
            className="mt-8"
            size="large"
            onClick={() => {
              formProps.form?.submit()
            }}
            block
          >
            Simpan
          </Button>
        </Form>
      </Drawer>
    </div>
  )
}
