import { Card } from '@pankod/refine-antd'
import React from 'react'

type TContentAreaAcard = {
  title: string
  children?: React.ReactNode
  extra?: React.ReactNode
}
const ContentAreaCard = React.forwardRef<HTMLDivElement, TContentAreaAcard>(
  ({ children, title, extra }, ref) => {
    return (
      <Card
        ref={ref}
        title={title}
        bodyStyle={{
          backgroundColor: '#E5E5E5',
          padding: 0,
          position: 'relative',
          minHeight: '640px',
        }}
        headStyle={{
          borderBottomWidth: '2px',
        }}
        extra={extra}
      >
        {children}
      </Card>
    )
  }
)
ContentAreaCard.displayName = 'ContentAreaCard'

export default ContentAreaCard
