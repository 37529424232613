import {
  Form,
  FormProps,
  Input,
  Modal,
  ModalProps,
  Radio,
  Select,
  Spin,
  useSelect,
} from '@pankod/refine-antd'
import React from 'react'

import { LPTKResponse } from '@resources/basic-ppg-management/lptk/types'

type TModalFormProps = {
  modalProps: ModalProps
  formProps: FormProps
  mode: 'create' | 'edit'
  onClose: () => void
  initialValue: any
}
export const ModalFormLPTKRoles = ({
  modalProps,
  formProps,
  onClose,
  initialValue,
}: TModalFormProps) => {
  const { selectProps, queryResult } = useSelect<LPTKResponse>({
    resource: 'lptk',
    optionLabel: 'name',
    optionValue: 'id',
    dataProviderName: 'lms',
    queryOptions: {
      enabled: modalProps.visible,
    },
    defaultValueQueryOptions: {
      enabled: false,
    },
    filters: [
      {
        field: 'pageSize',
        operator: 'eq',
        value: -1,
      },
    ],
  })

  React.useEffect(() => {
    if (modalProps.visible) {
      formProps.form?.setFieldsValue({
        lptkId: initialValue?.lptkId,
        role: initialValue?.role,
        name: initialValue?.name,
        email: initialValue?.email,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalProps.visible])

  return (
    <Modal
      {...modalProps}
      onCancel={onClose}
      title={`Tambah User Ke Role LPTK`}
      width={450}
      style={{
        zIndex: 101,
      }}
    >
      <Spin spinning={selectProps.loading}>
        <Form
          {...formProps}
          layout="vertical"
          validateMessages={{ required: '${label} must be filled.' }}
        >
          <Form.Item
            label="Pilih LPTK"
            name="lptkId"
            required
            rules={[{ required: true }]}
          >
            <Select
              {...selectProps}
              allowClear
              loading={queryResult.isFetching}
              onSearch={() => {}}
              filterOption={(input, option) =>
                (option!.label as unknown as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            />
          </Form.Item>
          <Form.Item
            label="Pilih Role"
            name="role"
            required
            rules={[{ required: true }]}
          >
            <Radio.Group>
              <Radio value="ADMIN">Admin LPTK</Radio>
              <Radio value="KOORDINATOR">Koordinator LPTK</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            label="Nama User"
            name="name"
            required
            rules={[{ required: true }]}
          >
            <Input autoComplete="off" />
          </Form.Item>
          <Form.Item
            label="Email User"
            name="email"
            required
            rules={[
              { required: true },
              {
                required: true,
                type: 'email',
                message: '${label} is not a valid email.',
              },
              {
                pattern: /belajar\.id$/,
                message: '${label} must be a belajar.id email.',
              },
            ]}
          >
            <Input autoComplete="off" />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  )
}
