import { Radio } from '@pankod/refine-antd'
import { TriangleAlert } from 'lucide-react'
import React from 'react'

type PageRadioProps = {
  onChange: (value: string, index: number) => void
  showFrontPageAlert?: boolean
  showBackPageAlert?: boolean
}

export const PageRadio = (props: PageRadioProps) => {
  const { onChange, showFrontPageAlert, showBackPageAlert } = props
  return (
    <div className="absolute top-7 m-2 p-2 rounded bg-white shadow z-10 select-none">
      <Radio.Group
        defaultValue="front"
        buttonStyle="outline"
        onChange={(e) => {
          const indexOfSelected = e.target.value === 'front' ? 0 : 1
          onChange(e.target.value, indexOfSelected)
        }}
      >
        <Radio.Button value="front">
          <div className="flex flex-row items-center">
            Halaman Depan{' '}
            {showFrontPageAlert && (
              <TriangleAlert className="ml-2 " size="18" />
            )}
          </div>
        </Radio.Button>
        <Radio.Button value="back">
          <div className="flex flex-row items-center">
            Halaman Belakang
            {showBackPageAlert && <TriangleAlert className="ml-2" size="18" />}
          </div>
        </Radio.Button>
      </Radio.Group>
    </div>
  )
}
