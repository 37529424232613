import { Form, Input, Modal, ModalProps } from '@pankod/refine-antd'
import React, { useEffect } from 'react'

import { UseUrlDataModalReturn } from '../hooks'
import { url } from 'src/helpers/validator'

type UrlDataModalProps = {
  data: UseUrlDataModalReturn['data']
  modalProps: ModalProps
  onSubmit: UseUrlDataModalReturn['onSubmit']
  isLoading: boolean
}
export const UrlDataModal = ({
  data,
  modalProps,
  onSubmit,
  isLoading,
}: UrlDataModalProps) => {
  const [form] = Form.useForm()
  const handleClose = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    form.resetFields()
    modalProps.onCancel?.(e)
  }

  useEffect(() => {
    if (modalProps.visible) {
      form.setFields([
        {
          name: 'webinarUrl',
          value: data?.webinarUrl,
        },
        {
          name: 'inquiryUrl',
          value: data?.inquiryUrl,
        },
        {
          name: 'reportUrl',
          value: data?.reportUrl,
        },
      ])
    }
  }, [
    data?.inquiryUrl,
    data?.reportUrl,
    data?.webinarUrl,
    form,
    modalProps.visible,
  ])

  return (
    <Modal
      {...modalProps}
      title="Ubah Data Link"
      destroyOnClose
      maskClosable={false}
      onCancel={handleClose}
      okButtonProps={{
        loading: isLoading,
      }}
      okText="Simpan"
      cancelText="Kembali"
      onOk={async () => {
        await form.validateFields()
        form.submit()
      }}
    >
      <Form
        form={form}
        onFinish={(values) => onSubmit(values)}
        layout="vertical"
      >
        <Form.Item
          rules={[
            {
              validator: (_, value) => url(value, true),
              message: 'Format URL tidak valid.',
            },
          ]}
          name="webinarUrl"
          label="Link Webinar"
        >
          <Input autoComplete="off" />
        </Form.Item>
        <Form.Item
          rules={[
            {
              validator: (_, value) => url(value, true),
              message: 'Format URL tidak valid.',
            },
          ]}
          name="inquiryUrl"
          label="Link Pertanyaan"
        >
          <Input autoComplete="off" />
        </Form.Item>
        <Form.Item
          rules={[
            {
              validator: (_, value) => url(value, true),
              message: 'Format URL tidak valid.',
            },
          ]}
          name="reportUrl"
          label="Link Laporan"
        >
          <Input autoComplete="off" />
        </Form.Item>
      </Form>
    </Modal>
  )
}
