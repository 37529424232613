import { Button, Icons } from '@pankod/refine-antd'
import { ShowButtonProps } from '@pankod/refine-antd/dist/components/buttons/show'
import {
  useCan,
  useNavigation,
  useResource,
  useRouterContext,
} from '@pankod/refine-core'
import React from 'react'

type TShowWebinarButtonProps = ShowButtonProps & {
  programId?: number | string
}
const RESOURCE_NAME = 'lms-webinar-management'
const ShowWebinarButton = ({
  programId,
  recordItemId,
  ignoreAccessControlProvider = false,
  ...props
}: TShowWebinarButtonProps) => {
  const accessControlEnabled = !ignoreAccessControlProvider
  const { Link } = useRouterContext()
  const { id, resource } = useResource({
    resourceName: RESOURCE_NAME,
    resourceNameOrRouteName: RESOURCE_NAME,
    recordItemId,
  })
  const { showUrl: generateShowUrl } = useNavigation()
  const showUrl = generateShowUrl(RESOURCE_NAME, id!)

  const { data } = useCan({
    resource: RESOURCE_NAME,
    action: 'show',
    params: { id, resource },
    queryOptions: {
      enabled: accessControlEnabled,
    },
  })

  return (
    <Link
      to={`${showUrl}?programId=${programId}`}
      replace={false}
      onClick={(e: React.PointerEvent<HTMLButtonElement>) => {
        if (data?.can === false) {
          e.preventDefault()
          return
        }
        if (props.onClick) {
          e.preventDefault()
          props.onClick(e)
        }
      }}
    >
      <Button
        {...props}
        disabled={props.disabled || !programId || data?.can === false}
        icon={<Icons.EyeOutlined />}
      >
        {!props.hideText && props.children}
      </Button>
    </Link>
  )
}

export default ShowWebinarButton
