import { Space, TextField, Icons, Tooltip } from '@pankod/refine-antd'
import React from 'react'

type SelectOptionLabelProps = {
  id: string
  label: string
  type: string
}
const SelectOptionLabel = ({ id, label, type }: SelectOptionLabelProps) => {
  return (
    <Space size="small">
      <TextField
        className="!mx-0 !border-none font-mono"
        type="secondary"
        value={
          <>
            <Icons.NumberOutlined className="mr-1" />
            {id}
          </>
        }
      />
      <Tooltip title={label}>
        <TextField value={label} className="!max-w-[250px]" ellipsis />
      </Tooltip>
      <TextField value={type} type="secondary" italic className="mr-1" />
    </Space>
  )
}

export default SelectOptionLabel
