import { SerializedNodes, useEditor } from '@craftjs/core'
import { message as AntdMessage, Form } from '@pankod/refine-antd'
import _ from 'lodash'

import { validateContentSchema } from './utils'
import { formToSchemaParser } from './formUtils'
import { useFormContext } from './formContext'
import { WIDGET_NAME } from './schema'
import { compressContentSchema } from 'utils/contentSchemaCompressor'

export const useGenerateWidgetData = () => {
  const { formInformationInstance } = useFormContext()
  const likertOptions = Form.useWatch('likertOptions', formInformationInstance)
  const { actions, query } = useEditor()
  let totalLikertQuestion = 0
  let possibleMaxLikertValue = 0

  return async () => {
    const serializedNodes: SerializedNodes & {
      [key: string]: { displayName: string; nodes: string[]; type?: any }
    } = query.getSerializedNodes()
    const parsedLikertOptions = likertOptions?.map(
      (label: string, i: number) => ({ label, value: String(i + 1) })
    )
    if (likertOptions) {
      const { containerNodeIds, likertWidgetIds } = Object.entries(
        serializedNodes
      ).reduce(
        (prev, [key, node]) => {
          if (
            node.displayName === WIDGET_NAME.Container ||
            (node.type?.resolvedName as string) === WIDGET_NAME.Container
          ) {
            return {
              ...prev,
              containerNodeIds: node.nodes,
            }
          }

          if (
            node.displayName === WIDGET_NAME.LikertQuestion ||
            (node.type?.resolvedName as string) === WIDGET_NAME.LikertQuestion
          ) {
            serializedNodes[key].props.options = parsedLikertOptions

            possibleMaxLikertValue = parsedLikertOptions.length
            totalLikertQuestion++

            return {
              ...prev,
              likertWidgetIds: [...prev.likertWidgetIds, key],
            }
          }

          return prev
        },
        { likertWidgetIds: [], containerNodeIds: [] } as {
          likertWidgetIds: string[]
          containerNodeIds: string[]
        }
      )

      const sortedLikertWidgetIds = _.intersection(
        containerNodeIds,
        likertWidgetIds
      )

      Object.entries(serializedNodes).forEach(([key, node]) => {
        if (
          node.displayName === WIDGET_NAME.LikertQuestion ||
          (node.type?.resolvedName as string) === WIDGET_NAME.LikertQuestion
        ) {
          const name = `likertQuestion${
            sortedLikertWidgetIds.findIndex((value) => value === key) + 1
          }`

          // console.log(key, sortedLikertWidgetIds)
          serializedNodes[key].props.name = name
        }
      })
    }

    try {
      Object.keys(serializedNodes).forEach((key) => {
        /** Initiate/reset errors information field */
        actions.setCustom(key, (custom: any) => (custom.errors = []))
      })
      // console.log('here')
      await validateContentSchema(serializedNodes)

      const contentSchema = compressContentSchema(serializedNodes)

      const validationSchema = formToSchemaParser(serializedNodes)

      const likertInfo = {
        totalMaxLikertValue: possibleMaxLikertValue * totalLikertQuestion,
        totalLikertQuestion,
      }

      return Promise.resolve({
        contentSchema,
        validationSchema,
        ...(!!likertOptions && { likertInfo }),
      })
    } catch (error) {
      console.error(error)
      ;(
        error as { validation: string; message: string; path: string[] }[]
      ).forEach(({ message, path: [id, label] }, i) => {
        AntdMessage.error(`${message} (Component ID ${id})`)
        /** Set errors to the related widget/component id  */
        actions.setCustom(id, (custom) => {
          custom.errors = [...custom.errors, { label, message }]
        })
        /** Autoselect first error's node */
        if (i === 0) {
          actions.selectNode(id)
        }
      })
      return Promise.reject(false)
    }
  }
}
