import {
  Button,
  useTable,
  Table,
  Icons,
  Space,
  useModalForm,
  Input,
} from '@pankod/refine-antd'
import {
  useDelete,
  useNavigation,
  useCreate,
  useInvalidate,
  useOne,
} from '@pankod/refine-core'
import React, { useEffect } from 'react'
import { useRouter } from 'next/router'

import { HeaderTitle } from '@components/HeaderTableTitle'
import { useErrorBoundary } from '@components/ErrorBoundary/hook'
import {
  showErrorNotification,
  useGetLptkId,
} from '@resources/angkatan-ppg-management/utils'
import { ModalAddMatkulRombel } from './ModalAddMatkulRombel'
import { TableTitle } from '@components/TableTitle'
import { ModalFilterCheckForm } from './ModalFilterCheckForm'
import { PopDeleteConfirm } from '@resources/lms-management/components/PopDeleteConfirm'

type MataKuliahTabContentProps = {
  rombelId: string | number
  rombelType: 'UTAMA' | 'PPL' | 'SEMINAR' | 'KEPEMIMPINAN'
  isPreventTabChange?: any
}

export const MataKuliahTabContent = (props: MataKuliahTabContentProps) => {
  const { data: kvData } = useOne<any>({
    resource: 'kv?key=feature_flag',
    id: '',
    dataProviderName: 'microlearning',
  })
  const { enableRombelSyncRelatedDataMutation = true } =
    (kvData?.data?.data as any) || {}
  const dosenFormSearchRef = React.useRef<any>()
  const pamongFormSearchRef = React.useRef<any>()
  const adminKelasFormSearchRef = React.useRef<any>()
  const instrukturFormSearchRef = React.useRef<any>()

  const { showBoundary } = useErrorBoundary()
  const { rombelId, rombelType } = props

  const router = useRouter()
  const { angkatanId, activeTab } = router.query
  const [lptkId] = useGetLptkId()
  const { list } = useNavigation()

  const [mutatedMatkulRecord, setMutatedMatkulRecord] = React.useState<any>()
  const [isModalSelectionVisible, setIsModalSelectionVisible] = React.useState({
    visible: false,
    type: '',
  })

  const [currentSelectedRecord, setSelectedRecord] = React.useState<any>()

  const { tableProps, tableQueryResult } = useTable({
    resource: `angkatan/${angkatanId}/lptk/${lptkId}/rombel/${rombelId}/mata-kuliah`,
    dataProviderName: 'lms',
    queryOptions: {
      enabled: !!lptkId && !!angkatanId && !!rombelId,
      onError(err) {
        showBoundary({
          status: err.statusCode,
          onBackClick: () => list('angkatan-ppg-management'),
        })
      },
    },
  })

  const { mutateAsync: doDelete } = useDelete()
  const { mutateAsync: doCreate } = useCreate()
  const invalidate = useInvalidate()

  const isRombelPPL = rombelType === 'PPL'

  useEffect(() => {
    const handleRouteChange = () => {
      if (
        activeTab === 'mata-kuliah' &&
        Object.keys(mutatedMatkulRecord || {}).length > 0
      ) {
        if (
          !window.confirm(
            'Perubahan Data Aktor belum diaplikasikan, Silahkan Klik Apply Perubahan Aktor Terlebih Dahulu'
          )
        ) {
          // User denied, cancel navigation
          router.events.emit('routeChangeError')
          throw 'routeChange aborted.'
        }
      }
    }

    router.events.on('routeChangeStart', handleRouteChange)

    props.isPreventTabChange(
      activeTab === 'mata-kuliah' &&
        Object.keys(mutatedMatkulRecord || {}).length > 0
    )

    // Clean up the subscription on unmount
    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, mutatedMatkulRecord, router.events])

  const {
    modalProps: addMatkulModalProps,
    formProps: formMatkulModalProps,
    submit: submitMatkulModalProps,
    show: showMatkulModalProps,
  } = useModalForm({
    action: 'create',
    resource: `angkatan/${angkatanId}/lptk/${lptkId}/rombel/${rombelId}/mata-kuliah`,
    submit: (formValue) => {
      return {
        mataKuliahId: formValue.mataKuliahId,
        semester: formValue.semester,
      }
    },
    dataProviderName: 'lms',
    autoResetForm: true,
    redirect: false,
    errorNotification: (error) =>
      showErrorNotification(error, 'There was a problem when adding Matkul'),
  })

  const {
    tableProps: dosenSelectionTable,
    tableQueryResult: dosenSelectionQueryResult,
    setFilters: dosenSelectionFilter,
  } = useTable({
    resource: `lptk/${lptkId}/dosen`,
    dataProviderName: 'lms',
    permanentFilter: [
      {
        field: 'isActive',
        operator: 'eq',
        value: true,
      },
    ],
    queryOptions: {
      enabled:
        isModalSelectionVisible.visible &&
        isModalSelectionVisible.type === 'dosen',
    },
  })

  const {
    tableProps: pamongSelectionTable,
    tableQueryResult: pamongSelectionQueryResult,
    setFilters: pamongSelectionFilter,
  } = useTable({
    syncWithLocation: false,
    resource: `lptk/${lptkId}/pamong`,
    dataProviderName: 'lms',
    permanentFilter: [
      {
        field: 'isActive',
        operator: 'eq',
        value: true,
      },
    ],
    queryOptions: {
      enabled:
        isModalSelectionVisible.visible &&
        isModalSelectionVisible.type === 'guruPamong',
    },
  })

  const {
    tableProps: adminKelasSelectionTable,
    tableQueryResult: adminKelasSelectionQueryResult,
    setFilters: adminKelasSelectionFilter,
  } = useTable({
    syncWithLocation: false,
    resource: `lptk/${lptkId}/class-admin`,
    dataProviderName: 'lms',
    permanentFilter: [
      {
        field: 'isActive',
        operator: 'eq',
        value: true,
      },
    ],
    queryOptions: {
      enabled:
        isModalSelectionVisible.visible &&
        isModalSelectionVisible.type === 'adminKelas',
    },
  })

  const {
    tableProps: instrukturSelectionTable,
    tableQueryResult: instrukturSelectionQueryResult,
    setFilters: instrukturSelectionFilter,
  } = useTable({
    resource: `lptk/${lptkId}/instruktur`,
    dataProviderName: 'lms',
    permanentFilter: [
      {
        field: 'isActive',
        operator: 'eq',
        value: true,
      },
    ],
    queryOptions: {
      enabled:
        isModalSelectionVisible.visible &&
        isModalSelectionVisible.type === 'instruktur',
    },
  })

  const handleAlterTableData = (selectedRecords: any, type: string) => {
    const newMutatedMatkulRecord = { ...mutatedMatkulRecord }
    if (Array.isArray(selectedRecords) && selectedRecords.length > 0) {
      newMutatedMatkulRecord[currentSelectedRecord?.id] = {
        ...currentSelectedRecord,
        actorList: {
          ...currentSelectedRecord?.actorList,
          [type]: selectedRecords,
        },
      }
    } else {
      if (selectedRecords)
        newMutatedMatkulRecord[currentSelectedRecord?.id] = {
          ...currentSelectedRecord,
          actorList: {
            ...currentSelectedRecord?.actorList,
            [type]: selectedRecords[0],
          },
        }
    }

    setMutatedMatkulRecord(newMutatedMatkulRecord)

    setIsModalSelectionVisible({
      visible: false,
      type: '',
    })
  }

  const handleAppendMatkulRecord = (record: any, type: string) => {
    setMutatedMatkulRecord((prev: any) => ({
      ...prev,

      [record.id]: {
        ...record,
      },
    }))

    setSelectedRecord(record)
    setIsModalSelectionVisible({
      visible: true,
      type,
    })
  }

  const handleApplyMatkulActorChange = (record: any) => {
    const newMatkulRecord = { ...mutatedMatkulRecord }

    const { dosen, adminKelas, guruPamong, instruktur } =
      newMatkulRecord?.[record.id].actorList

    doCreate({
      resource: `angkatan/${angkatanId}/lptk/${lptkId}/rombel/${rombelId}/mata-kuliah/${record.id}/actor`,
      dataProviderName: 'lms',
      values: {
        dosenIds: Array.isArray(dosen)
          ? dosen.map((item: any) => item?.id)
          : dosen?.id,
        instrukturIds: Array.isArray(instruktur)
          ? instruktur.map((item: any) => item?.id)
          : instruktur?.id,
        adminKelasIds: Array.isArray(adminKelas)
          ? adminKelas.map((item: any) => item?.id)
          : adminKelas?.id,
        ...(isRombelPPL && {
          guruPamongIds: Array.isArray(guruPamong)
            ? guruPamong.map((item: any) => item?.id)
            : guruPamong?.id,
        }),
      },
      successNotification: () => {
        invalidate({
          resource: `angkatan/${angkatanId}/lptk/${lptkId}/rombel/${rombelId}/mata-kuliah`,
          dataProviderName: 'lms',
          invalidates: ['list'],
        })
        return {
          message: 'Mata kuliah successfuly updated',
          type: 'success',
          description: 'Successful',
        }
      },

      errorNotification: (error) =>
        showErrorNotification(
          error,
          'There was a problem when updating Mata Kuliah'
        ),
    })

    delete newMatkulRecord[record.id]
    setMutatedMatkulRecord(newMatkulRecord)
  }

  const columns = [
    {
      title: <HeaderTitle align="left" title="Nama Mata Kuliah" />,
      key: 'name',
      dataIndex: 'name',
    },
    {
      title: <HeaderTitle align="left" title="Semester" />,
      key: 'semester',
      dataIndex: 'semester',
    },
    {
      title: <HeaderTitle align="left" title="Dosen" />,
      key: 'dosen',
      dataIndex: 'dosen',
      render: (_: unknown, record: any) => {
        const isMutated = mutatedMatkulRecord?.[record.id]
        const currentRecord = isMutated || record

        return (
          <div className="flex flex-row gap-4 items-center">
            {(currentRecord?.actorList?.dosen &&
              `${currentRecord?.actorList?.dosen
                .map((item: any) => item?.email)
                .join(', ')}`) ||
              'BELUM DI SET'}

            {enableRombelSyncRelatedDataMutation && (
              <Button
                icon={<Icons.EditOutlined />}
                size="small"
                onClick={() => handleAppendMatkulRecord(currentRecord, 'dosen')}
              />
            )}
          </div>
        )
      },
    },
    {
      title: <HeaderTitle align="left" title="Admin Kelas" />,
      key: 'adminKelas',
      dataIndex: 'adminKelas',
      render: (_: unknown, record: any) => {
        const isMutated = mutatedMatkulRecord?.[record.id]
        const currentRecord = isMutated || record

        return (
          <div className="flex flex-row gap-4 items-center">
            {(currentRecord?.actorList?.adminKelas &&
              `${currentRecord?.actorList?.adminKelas
                .map((item: any) => item?.email)
                .join(', ')}`) ||
              'BELUM DI SET'}

            {enableRombelSyncRelatedDataMutation && (
              <Button
                icon={<Icons.EditOutlined />}
                size="small"
                onClick={() =>
                  handleAppendMatkulRecord(currentRecord, 'adminKelas')
                }
              />
            )}
          </div>
        )
      },
    },
    ...(isRombelPPL
      ? [
          {
            title: <HeaderTitle align="left" title="Guru Pamong" />,
            key: 'guruPamong',
            dataIndex: 'guruPamong',
            render: (_: unknown, record: any) => {
              const isMutated = mutatedMatkulRecord?.[record.id]
              const currentRecord = isMutated || record
              return (
                <div className="flex flex-row gap-4 items-center justify-between">
                  {(currentRecord?.actorList?.guruPamong &&
                    `${currentRecord?.actorList?.guruPamong
                      .map((item: any) => item?.email)
                      .join(', ')}`) ||
                    'BELUM DI SET'}

                  {enableRombelSyncRelatedDataMutation && (
                    <Button
                      icon={<Icons.EditOutlined />}
                      size="small"
                      onClick={() =>
                        handleAppendMatkulRecord(currentRecord, 'guruPamong')
                      }
                    />
                  )}
                </div>
              )
            },
          },
        ]
      : []),
    {
      ...(process.env.NEXT_PUBLIC_ENALBE_PPG_INSTRUKTUR_AKTOR === 'true' && {
        title: <HeaderTitle align="left" title="Instruktur" />,
        key: 'instruktur',
        dataIndex: 'instruktur',
        render: (_: unknown, record: any) => {
          const isMutated = mutatedMatkulRecord?.[record.id]
          const currentRecord = isMutated || record

          return (
            <div className="flex flex-row gap-4 items-center">
              {(currentRecord?.actorList?.instruktur &&
                `${currentRecord?.actorList?.instruktur
                  .map((item: any) => item?.email)
                  .join(', ')}`) ||
                'BELUM DI SET'}

              {enableRombelSyncRelatedDataMutation && (
                <Button
                  icon={<Icons.EditOutlined />}
                  size="small"
                  onClick={() =>
                    handleAppendMatkulRecord(currentRecord, 'instruktur')
                  }
                />
              )}
            </div>
          )
        },
      }),
    },

    {
      key: 'action',
      width: '40%',
      fixed: 'right',
      render: (_: any, record: any) => (
        <Space direction="vertical">
          {enableRombelSyncRelatedDataMutation && (
            <PopDeleteConfirm
              validateValue={record.name}
              title="Apakah Anda yakin ingin menghapus mata kuliah ini?, silahkan ketik ulang nama matakuliah untuk konfirmasi!"
              placement="topRight"
              onConfirm={() => {
                return doDelete({
                  id: record.id,
                  resource: `angkatan/${angkatanId}/lptk/${lptkId}/rombel/${rombelId}/mata-kuliah`,
                  dataProviderName: 'lms',
                  invalidates: ['list'],
                  successNotification: {
                    message: 'Mata kuliah successfuly removed',
                    type: 'success',
                    description: 'Successful',
                  },
                  errorNotification: (error) =>
                    showErrorNotification(
                      error,
                      'There was a problem when removing Mata Kuliah'
                    ),
                })
              }}
              children={
                <Button danger icon={<Icons.DeleteOutlined />} size="small">
                  Hapus
                </Button>
              }
              okText="Hapus"
              cancelText="Kembali"
              okButtonProps={{ danger: true }}
            />
          )}

          {mutatedMatkulRecord?.[record.id] && (
            <Button
              icon={<Icons.EditOutlined />}
              size="small"
              onClick={() => {
                handleApplyMatkulActorChange(record)
              }}
            >
              Apply Perubahan Aktor
            </Button>
          )}
        </Space>
      ),
    },
  ]

  const MultipleFormFilterComponent = (
    searchFormRef: any,
    stateSetter: any
  ) => (
    <div className="flex flex-row gap-4 items-center" ref={searchFormRef}>
      <Input
        placeholder="Cari Berdasarkan Nama"
        allowClear
        onKeyDown={(e: any) => {
          if (e.key === 'Enter') {
            stateSetter([
              {
                field: 'name',
                operator: 'eq',
                value: e.target.value,
              },
            ])
          }
        }}
      />

      <Input
        placeholder="Cari Berdasarkan Email"
        allowClear
        onKeyDown={(e: any) => {
          if (e.key === 'Enter') {
            stateSetter([
              {
                field: 'email',
                operator: 'eq',
                value: e.target.value,
              },
            ])
          }
        }}
      />
    </div>
  )

  const handleClearModalFilterSearchForm = (
    searchFormRef: any,
    stateSetter: any
  ) => {
    stateSetter([
      {
        field: 'email',
        operator: 'eq',
        value: null,
      },
      {
        field: 'name',
        operator: 'eq',
        value: null,
      },
    ])

    const targetInputClearIcon = searchFormRef?.current?.querySelectorAll(
      '.ant-input-clear-icon'
    )

    targetInputClearIcon?.forEach((input: any) => {
      input.click()
    })

    setIsModalSelectionVisible({
      visible: false,
      type: '',
    })
  }

  return (
    <>
      <Table
        {...tableProps}
        // @ts-ignore
        columns={columns}
        rowKey="id"
        size="small"
        loading={tableQueryResult.isFetching}
        title={() => (
          <TableTitle
            title="Daftar Mata Kuliah"
            extra={
              enableRombelSyncRelatedDataMutation && (
                <Button
                  onClick={showMatkulModalProps}
                  icon={<Icons.PlusOutlined />}
                >
                  Tambah Matkul
                </Button>
              )
            }
          />
        )}
      />

      <ModalAddMatkulRombel
        modalProps={addMatkulModalProps}
        formProps={formMatkulModalProps}
        onSubmit={submitMatkulModalProps}
        angkatanId={angkatanId as string}
        lptkId={lptkId}
        rombelId={rombelId as string}
      />

      <ModalFilterCheckForm
        key={'modal-check-dosen'}
        isVisible={
          isModalSelectionVisible.visible &&
          isModalSelectionVisible.type === 'dosen'
        }
        onClose={() =>
          handleClearModalFilterSearchForm(
            dosenFormSearchRef,
            dosenSelectionFilter
          )
        }
        tableProps={dosenSelectionTable}
        tableQueryResult={dosenSelectionQueryResult}
        filterComponent={MultipleFormFilterComponent(
          dosenFormSearchRef,
          dosenSelectionFilter
        )}
        modalTitle="Ubah Dosen Matakuliah"
        onSubmit={(selectedRecords: any) => {
          handleAlterTableData(selectedRecords, 'dosen')
        }}
        initialValues={
          mutatedMatkulRecord?.[currentSelectedRecord?.id]?.actorList?.dosen
        }
      />

      <ModalFilterCheckForm
        key={'modal-check-instruktur'}
        isVisible={
          isModalSelectionVisible.visible &&
          isModalSelectionVisible.type === 'instruktur'
        }
        onClose={() =>
          handleClearModalFilterSearchForm(
            instrukturFormSearchRef,
            instrukturSelectionFilter
          )
        }
        tableProps={instrukturSelectionTable}
        tableQueryResult={instrukturSelectionQueryResult}
        filterComponent={MultipleFormFilterComponent(
          instrukturFormSearchRef,
          instrukturSelectionFilter
        )}
        modalTitle="Ubah instruktur Matakuliah"
        onSubmit={(selectedRecords: any) => {
          handleAlterTableData(selectedRecords, 'instruktur')
        }}
        initialValues={
          mutatedMatkulRecord?.[currentSelectedRecord?.id]?.actorList
            ?.instruktur
        }
      />

      <ModalFilterCheckForm
        key={'modal-check-gurupamong'}
        isVisible={
          isModalSelectionVisible.visible &&
          isModalSelectionVisible.type === 'guruPamong'
        }
        onClose={() =>
          handleClearModalFilterSearchForm(
            pamongFormSearchRef,
            pamongSelectionFilter
          )
        }
        tableProps={pamongSelectionTable}
        tableQueryResult={pamongSelectionQueryResult}
        filterComponent={MultipleFormFilterComponent(
          pamongFormSearchRef,
          pamongSelectionFilter
        )}
        modalTitle="Ubah Guru Pamong Matakuliah"
        onSubmit={(selectedRecords: any) => {
          handleAlterTableData(selectedRecords, 'guruPamong')
        }}
        initialValues={
          mutatedMatkulRecord?.[currentSelectedRecord?.id]?.actorList
            ?.guruPamong
        }
      />

      <ModalFilterCheckForm
        key={'modal-check-adminkelas'}
        isVisible={
          isModalSelectionVisible.visible &&
          isModalSelectionVisible.type === 'adminKelas'
        }
        onClose={() =>
          handleClearModalFilterSearchForm(
            adminKelasFormSearchRef,
            adminKelasSelectionFilter
          )
        }
        tableProps={adminKelasSelectionTable}
        tableQueryResult={adminKelasSelectionQueryResult}
        filterComponent={MultipleFormFilterComponent(
          adminKelasFormSearchRef,
          adminKelasSelectionFilter
        )}
        modalTitle="Ubah Admin Kelas Matakuliah"
        onSubmit={(selectedRecords: any) => {
          handleAlterTableData(selectedRecords, 'adminKelas')
        }}
        initialValues={
          mutatedMatkulRecord?.[currentSelectedRecord?.id]?.actorList
            ?.adminKelas
        }
      />
    </>
  )
}
