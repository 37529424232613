import React from 'react'
import { Tabs } from '@pankod/refine-antd'

import PresensiPesertaTable from './PresensiPesertaTable'
import LaporanPresensi from './presensi/LaporanPresensi'
import LaporanLokakarya from './lokakarya/LaporanLokakarya'

const TabLaporanPresensi = () => {
  return (
    <div>
      <Tabs tabPosition="left" className="!mt-4">
        <Tabs.TabPane tab="Presensi Seluruh Peserta" key="peserta">
          <PresensiPesertaTable />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Uraian Laporan Presensi" key="presensi">
          <LaporanPresensi />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Uraian Presensi Lokakarya" key="lokakarya">
          <LaporanLokakarya />
        </Tabs.TabPane>
      </Tabs>
    </div>
  )
}

export default TabLaporanPresensi
