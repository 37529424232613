import {
  Button,
  Descriptions,
  Icons,
  Modal,
  Show,
  Spin,
  Table,
  TableColumnGroupType,
  TableColumnType,
  useTable,
} from '@pankod/refine-antd'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { useCreate, useCustomMutation } from '@pankod/refine-core'

import { TableTitle } from '@components/TableTitle'
import { HeaderTitle } from '@components/HeaderTableTitle'
import { TCommonError } from 'src/interfaces/common'
import { CmsPpgSimpkbSyncQueueDetail } from '@apis/model/types'
import { showErrorNotification } from '@resources/angkatan-ppg-management/utils'
import { downloadTemplate } from '@components/ModalParseCSV/generateCSVTemplate'

export const SimpkbConfirmationDetail = () => {
  const router = useRouter()
  const { angkatanId, id } = router.query

  const { mutate } = useCustomMutation()

  const [confirmationModalVisible, setConfirmationModalVisible] =
    useState(false)
  const { tableProps, tableQueryResult } = useTable<
    CmsPpgSimpkbSyncQueueDetail,
    TCommonError
  >({
    dataProviderName: 'lms',
    resource: `simpkb/sync-queues/${id}`,
    permanentFilter: [
      {
        field: 'angkatanId',
        operator: 'eq',
        value: angkatanId,
      },
    ],
    queryOptions: {
      enabled: !!angkatanId && !!id,
    },
    errorNotification: (err) =>
      showErrorNotification(err, 'Terjadi Kesalahan ketika memuat data'),
  })

  const {
    tableProps: questionableTableProp,
    tableQueryResult: questionableTableQueryResult,
  } = useTable<CmsPpgSimpkbSyncQueueDetail, TCommonError>({
    dataProviderName: 'lms',
    resource: `simpkb/sync-queues/${id}/questionable-data`,
    permanentFilter: [
      {
        field: 'angkatanId',
        operator: 'eq',
        value: angkatanId,
      },
    ],
    queryOptions: {
      enabled: !!angkatanId && !!id,
    },
    errorNotification: (err) =>
      showErrorNotification(err, 'Terjadi Kesalahan ketika memuat data'),
  })

  const { mutateAsync: doCreate } = useCreate()

  const data: CmsPpgSimpkbSyncQueueDetail =
    tableProps.dataSource as CmsPpgSimpkbSyncQueueDetail

  const columns: (
    | TableColumnType<CmsPpgSimpkbSyncQueueDetail>
    | TableColumnGroupType<CmsPpgSimpkbSyncQueueDetail>
  )[] = [
    {
      title: <HeaderTitle align="left" title="nama" />,
      key: 'name',
      dataIndex: 'name',
    },
    {
      title: <HeaderTitle align="left" title="Email Belajar Id" />,
      key: 'emailBelajar',
      dataIndex: 'emailBelajar',
    },
    {
      title: <HeaderTitle align="left" title="LPTK" />,
      key: 'lptk',
      dataIndex: 'lptk',
    },
    {
      title: <HeaderTitle align="left" title="Bidang Studi" />,
      key: 'studyField',
      dataIndex: 'studyField',
    },
    {
      title: <HeaderTitle align="left" title="Kategory" />,
      key: 'category',
      dataIndex: 'category',
    },

    {
      title: <HeaderTitle align="left" title="Action" />,
      key: 'action',
      dataIndex: 'action',
    },
  ]

  return (
    <Show
      title="Detail Konfirmasi Data Sync SIMPKB"
      resource={'ppg-lptk-simpkb-sync-confirmation-detail'}
      dataProviderName="lms"
      canDelete={false}
      headerButtons={<></>}
      contentProps={{ size: 'small' }}
    >
      <Head>
        <title>LMS Admin | SIMPKB Sync Detail </title>
      </Head>

      <Spin spinning={tableQueryResult.isFetching}>
        <div className="flex flex-row gap-2 items-start justify-between">
          <Descriptions bordered column={1} className="mb-9" title="info sync">
            <Descriptions.Item label="Date">
              {data?.startedDate}
            </Descriptions.Item>
            <Descriptions.Item label="Start">
              {data?.startedTime}
            </Descriptions.Item>
            <Descriptions.Item label="User">
              {data?.startedBy}
            </Descriptions.Item>
            <Descriptions.Item label="Status Sinkronisasi">
              {data?.status}
            </Descriptions.Item>
          </Descriptions>

          <Descriptions
            bordered
            column={1}
            className="mb-9"
            title="Hasil Pemrosesan"
          >
            <Descriptions.Item label="status">
              {data?.resultInfo?.status}
            </Descriptions.Item>
            <Descriptions.Item label="message">
              {data?.resultInfo?.message}
            </Descriptions.Item>

            <Descriptions.Item label="Late Entry">
              {data?.confirmationInfo?.lateEntryCount}
            </Descriptions.Item>

            <Descriptions.Item label="Changed LPTK">
              {data?.confirmationInfo?.changedLptkCount}
            </Descriptions.Item>

            <Descriptions.Item label="Changed Belajar Id">
              {data?.confirmationInfo?.changedBelajaridEmailCount}
            </Descriptions.Item>
          </Descriptions>
        </div>

        <Table
          {...questionableTableProp}
          loading={questionableTableQueryResult.isFetching}
          columns={columns}
          rowKey="id"
          title={() => (
            <TableTitle
              title="Daftar Peserta"
              extra={
                <div className="flex flex-row gap-2 items-center justify-end">
                  <Button
                    icon={<Icons.SyncOutlined />}
                    type="ghost"
                    onClick={() => setConfirmationModalVisible(true)}
                    disabled={!data?.canProcess}
                  >
                    Proses Data
                  </Button>
                  <Button
                    icon={<Icons.ExportOutlined />}
                    type="ghost"
                    onClick={() =>
                      mutate({
                        // @ts-ignore hack to mutate get
                        method: 'get',
                        url: `${process.env.LMS_API_URL}/simpkb/sync-queues/${id}/questionable-data/download`,

                        dataProviderName: 'lms',
                        successNotification(data: any) {
                          data &&
                            downloadTemplate(
                              data?.data as string,
                              `${id}-questionable-data`,
                              'DATA_SIMPKB'
                            )

                          return {
                            message: 'Memulai Download Data',
                            description: 'Memulai proses download',
                            type: 'success',
                          }
                        },
                      })
                    }
                    disabled={!data?.canExport}
                  >
                    Export Data
                  </Button>
                </div>
              }
            />
          )}
        />
      </Spin>

      <Modal
        title="Konfirmasi Proses"
        visible={confirmationModalVisible}
        onCancel={() => setConfirmationModalVisible(false)}
        onOk={() => {
          doCreate({
            resource: `simpkb/sync-queues/${id}/trigger-sync`,
            dataProviderName: 'lms',
            values: {},
            successNotification: (e: any) => {
              setConfirmationModalVisible(false)

              return {
                message: 'Success',
                description: e.data.message,
                type: 'success',
              }
            },

            errorNotification: (err) =>
              showErrorNotification(
                err,
                'Terjadi Kesalahan ketika memproses data'
              ),
          })
        }}
      >
        <p>Anda akan memproses data peserta dengan detail sebagai berikut:</p>
        <ul className="mx-4">
          <li>
            Menambahkan
            <b> {data?.confirmationInfo?.lateEntryCount} Peserta Baru</b>
          </li>
          <li>
            Mengubah Data LPTK
            <b> {data?.confirmationInfo?.changedLptkCount} Peserta</b>
          </li>
          <li>
            Mengubah Data Email
            <b> {data?.confirmationInfo?.changedBelajaridEmailCount} Peserta</b>
          </li>
          <li>
            Menambahkan LPTK tidak konsisten
            <b> {data?.confirmationInfo?.inconsistentLptkCount} LPTK</b>
          </li>
        </ul>
        <p>
          *Data yang diproses adalah data dengan kategori <b>Perubahan LPTK,</b>{' '}
          <b>Perubahan email belajar.id, </b> dan <b> LPTK tidak konsisten</b>
          <br />
          <br />
          Apakah Anda yakin ingin memproses data?”
        </p>
      </Modal>
    </Show>
  )
}
