import { WIDGET_NAME } from '@components/FormBuilder/schema'
import {
  CheckboxGroup,
  DatePicker,
  LikertQuestion,
  NumberInput,
  RadioGroup,
  RichTextEditor,
  Select,
  TextArea,
  TextInput,
  defaultCheckboxGroupProps,
  defaultDatePickerProps,
  defaultLikertQuestionProps,
  defaultNumberInputProps,
  defaultRadioGroupProps,
  defaultRichTextEditorProps,
  defaultSelectProps,
  defaultTextAreaProps,
  defaultTextInputProps,
} from '@components/FormBuilder/widgets'
import { TGenerateToolboxButtonsParams } from './utils'

const formWidgets: TGenerateToolboxButtonsParams['widget'][] = [
  {
    name: WIDGET_NAME.TextInput,
    widget: TextInput,
    defaultProps: defaultTextInputProps,
    canBePlacedOnRoot: false,
  },
  {
    name: WIDGET_NAME.NumberInput,
    widget: NumberInput,
    defaultProps: defaultNumberInputProps,
    canBePlacedOnRoot: false,
  },
  {
    name: WIDGET_NAME.TextArea,
    widget: TextArea,
    defaultProps: defaultTextAreaProps,
    canBePlacedOnRoot: false,
  },
  {
    name: WIDGET_NAME.DatePicker,
    widget: DatePicker,
    defaultProps: defaultDatePickerProps,
    canBePlacedOnRoot: false,
  },
  {
    name: WIDGET_NAME.Select,
    widget: Select,
    defaultProps: defaultSelectProps,
    canBePlacedOnRoot: false,
  },
  {
    name: WIDGET_NAME.CheckboxGroup,
    widget: CheckboxGroup,
    defaultProps: defaultCheckboxGroupProps,
    canBePlacedOnRoot: false,
  },
  {
    name: WIDGET_NAME.RadioGroup,
    widget: RadioGroup,
    defaultProps: defaultRadioGroupProps,
    canBePlacedOnRoot: false,
  },
  {
    name: WIDGET_NAME.RichTextEditor,
    widget: RichTextEditor,
    defaultProps: defaultRichTextEditorProps,
    canBePlacedOnRoot: false,
  },
  {
    name: WIDGET_NAME.LikertQuestion,
    widget: LikertQuestion,
    defaultProps: defaultLikertQuestionProps,
    canBePlacedOnRoot: false,
    toolboxOptions: {
      span: 3,
    },
  },
]

export default formWidgets
