import { Editor, Element, Frame } from '@craftjs/core'
import { Col, Edit, Row } from '@pankod/refine-antd'
import {
  IResourceComponentsProps,
  useOne,
  useResource,
} from '@pankod/refine-core'
import Head from 'next/head'
import React, { useMemo, useRef } from 'react'

import PageInformation from '@components/FormBuilder/editor/FormInformation'
import { ContentWrapper } from '@components/FormBuilder/widgets'
import { useUpdatePage } from './hooks'
import { EditorRenderer, WidgetPanel } from '@components/FormBuilder/editor'
import resolver from '@components/FormBuilder/resolver'
import SkeletonLoading from '@components/FormBuilder/SkeletonLoading'
import ContentAreaCard from '@components/FormBuilder/ContentAreaCard'
import { TFormGeneratorDetail } from './interface'
import { TCommonError, TCommonResponse } from 'src/interfaces/common'
import { decompressContentSchema } from 'utils/contentSchemaCompressor'

const CLEdit: React.FC<IResourceComponentsProps> = ({ options }) => {
  const resource = useResource()
  const contentWrapperRef = useRef<HTMLDivElement>(null)
  const { data, isFetching } = useOne<
    TCommonResponse<TFormGeneratorDetail>,
    TCommonError
  >({
    id: resource.id!,
    resource: 'forms',
  })
  const { updateForm, updateLoading } = useUpdatePage()

  const schema = useMemo(() => {
    if (!data?.data?.data.contentSchema) return undefined

    return decompressContentSchema(data.data.data.contentSchema)
  }, [data?.data?.data.contentSchema])

  const metaForm = JSON.parse(data?.data?.data.metaForm || '{}')

  return (
    <Edit
      title="Edit Form"
      footerButtons={<></>}
      isLoading={updateLoading}
      headerButtons={<></>}
    >
      <Head>
        <title>LMS Admin | {options?.label}</title>
      </Head>
      <Editor
        onRender={EditorRenderer}
        resolver={resolver}
        key={data?.data.data.id || 'content'}
      >
        <Row gutter={[24, 24]} wrap={false}>
          <Col flex="auto">
            <WidgetPanel contentWrapperRef={contentWrapperRef} />
          </Col>
          <Col flex="50%">
            {(!schema || isFetching) && (
              <ContentAreaCard title="Content Area" ref={contentWrapperRef}>
                <SkeletonLoading />
              </ContentAreaCard>
            )}
            {schema && !isFetching && (
              <ContentAreaCard title="Content Area" ref={contentWrapperRef}>
                <Frame key={resource.id} data={schema}>
                  <Element is={ContentWrapper} canvas />
                </Frame>
              </ContentAreaCard>
            )}
          </Col>
          <Col flex="20%">
            <PageInformation
              formInitialValues={{
                ...metaForm,
                id: data?.data.data.id,
                formType: data?.data.data.formType,
              }}
              onSaveClick={(formData) => updateForm(formData)}
            />
          </Col>
        </Row>
      </Editor>
    </Edit>
  )
}

export default CLEdit
