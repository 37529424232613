import { Descriptions, Show, Tabs } from '@pankod/refine-antd'
import {
  IResourceComponentsProps,
  useResource,
  useShow,
} from '@pankod/refine-core'
import Head from 'next/head'
import React from 'react'
import { useRouter } from 'next/router'

import { DetailTab } from './blocks/DetailTab'
import { LPTKTab } from './blocks/LPTKTab'
import { trackTabChange } from 'src/helpers/url'
import { TCommonResponse } from 'src/interfaces/common'
import { BatchResponse } from './types'
import { ParticipantTab } from './blocks/PesertaTab'
import { SimpkbTab } from './blocks/SimpkbTab'
// import { MatakuliahTab } from './blocks/MatakuliahTab'

const BATCH_RESOURCE = 'angkatan'

export const BatchShow: React.FC<IResourceComponentsProps> = ({ options }) => {
  const resource = useResource()
  const id = resource.id as number
  const router = useRouter()
  const { activeTab = null } = router.query || {}
  const [activeKey, setActiveKey] = React.useState(
    (activeTab as string) || 'detail'
  )

  const { queryResult } = useShow<TCommonResponse<BatchResponse>>({
    id,
    resource: BATCH_RESOURCE,
    dataProviderName: 'lms',
  })

  const data = queryResult?.data?.data?.data

  return (
    <Show
      title="Detail Angkatan"
      resource={BATCH_RESOURCE}
      dataProviderName="lms"
      canDelete={false}
      headerButtons={<></>}
      contentProps={{ size: 'small' }}
    >
      <Head>
        <title>LMS Admin | {options?.label} </title>
      </Head>

      <Descriptions bordered column={1} className="mb-9">
        <Descriptions.Item label="ID">{data?.id}</Descriptions.Item>
        <Descriptions.Item label="Slug">{data?.slug}</Descriptions.Item>
        <Descriptions.Item label="Nama Angkatan">
          {data?.name}
        </Descriptions.Item>
      </Descriptions>

      <Tabs
        defaultActiveKey="detail"
        activeKey={activeKey}
        destroyInactiveTabPane
        onChange={(activeKey) => {
          setActiveKey(activeKey)
          trackTabChange(router, activeKey)
        }}
      >
        <Tabs.TabPane tab="Detail" key="detail" className="mt-4">
          <DetailTab />
        </Tabs.TabPane>
        <Tabs.TabPane tab="LPTK" key="lptk" className="mt-4">
          <LPTKTab />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Peserta" key="peserta" className="mt-4">
          <ParticipantTab />
        </Tabs.TabPane>
        <Tabs.TabPane tab="API SIMPKB" key="simpkb" className="mt-4">
          <SimpkbTab
            tahun={data?.tahun}
            gelombang={data?.gelombang}
            angkatanId={id}
            // @ts-ignore
            canSync={queryResult?.data?.data?.canSync}
            refetchAngkatan={queryResult.refetch}
          />
        </Tabs.TabPane>
        {/* <Tabs.TabPane tab="Mata Kuliah" key="mata-kuliah" className="mt-4">
          <MatakuliahTab />
        </Tabs.TabPane> */}
      </Tabs>
    </Show>
  )
}
