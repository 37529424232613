import { useErrorBoundary as useReactErrorBoundary } from 'react-error-boundary'

type ShowErrorBoundaryParam = {
  status: number | 'error'
  title?: string
  subtitle?: string
  onBackClick?: () => void
  backButtonText?: string
}
export const useErrorBoundary = () => {
  const { showBoundary, resetBoundary } = useReactErrorBoundary()
  const errorCollection: Record<
    number | string,
    { title?: string; subtitle?: string }
  > = {
    403: { title: 'Anda tidak memiliki akses' },
    404: { title: 'Data tidak ditemukan' },
    500: { title: 'Terjadi kendala pada server' },
  }
  return {
    showBoundary: (params: ShowErrorBoundaryParam) => {
      const error = errorCollection[params.status || 'error'] || {
        status: 'error',
        title: 'Terjadi gangguan pada sistem',
      }
      return showBoundary({
        ...error,
        ...params,
      })
    },
    resetBoundary,
  }
}
