import { Nodes, useEditor } from '@craftjs/core'
import React, { useEffect } from 'react'
import _ from 'lodash'
import { Descriptions, TextField } from '@pankod/refine-antd'

import { WIDGET_NAME } from '../schema'

type OPTION = { label: string; value: string }

const getMaxTotalFromNodes = (nodes: Nodes): number => {
  return Object.values(nodes).reduce((prev, node) => {
    const name = node.data.custom.name as WIDGET_NAME
    const props = node.data.props
    switch (name) {
      case WIDGET_NAME.Select:
      case WIDGET_NAME.RadioGroup:
        const optionsValue: number[] =
          props.options
            ?.filter?.(({ value }: OPTION) => !Number.isNaN(value))
            .map(({ value }: OPTION) => Number(value)) || []

        return prev + (_.max(optionsValue) || 0)
      case WIDGET_NAME.NumberInput:
        return prev + Number(props.maxValue || 0)
      default:
        return prev
    }
  }, 0)
}

type QuantitativeTotalMax = {
  onTotalChange: (value: number) => void
}
const QuantitativeTotalMax = ({ onTotalChange }: QuantitativeTotalMax) => {
  const { quantitativeMaxTotal } = useEditor((state) => ({
    quantitativeMaxTotal: getMaxTotalFromNodes(state.nodes),
  }))

  useEffect(() => {
    onTotalChange(quantitativeMaxTotal)
  }, [onTotalChange, quantitativeMaxTotal])

  return (
    <Descriptions bordered>
      <Descriptions.Item label="Total Maximum Value">
        <TextField className="whitespace-nowrap" value={quantitativeMaxTotal} />
      </Descriptions.Item>
    </Descriptions>
  )
}

export default QuantitativeTotalMax
