import {
  Button,
  Divider,
  Drawer,
  Form,
  Icons,
  Input,
  Select,
  Spin,
  Tabs,
  useTable,
} from '@pankod/refine-antd'
import React from 'react'
import { useUpdate } from '@pankod/refine-core'

import { Editor } from '@components/TUIEditor'

export type DrawerFormJurnalFasilitatorProps = {
  visible: boolean
  drawerProps?: any
  onClose: () => void
  programId: string
}

// TODO: [DASUS] - Flat Criterias to questions

export const DrawerFormJurnalFasilitator = (
  props: DrawerFormJurnalFasilitatorProps
) => {
  const { visible, onClose, programId } = props
  const [formJurnalFasilitator] = Form.useForm()
  const [jurnalFasilitatorTempData, setJurnalFasilitatorTempData] =
    React.useState<any>({})

  const [formRubrik] = Form.useForm()

  const [pertanyaanList, setPertanyaanList] = React.useState<any[]>([])
  const [hasMutated, setHasMutated] = React.useState(false)

  const [criteriaFormState, setCriteriaFormState] = React.useState({
    mode: 'create',
    visible: false,
    defaultData: null,
  })

  const { tableProps } = useTable({
    resource: `pgp-dasus/programs/${programId}/fi/jurnal_fasilitator`,
    dataProviderName: 'lms',
    queryOptions: {
      enabled: !!visible,
      onSuccess(data: any) {
        setTimeout(() => {
          setJurnalFasilitatorTempData(data)
          setPertanyaanList(data?.questions)

          setCriteriaFormState({
            ...criteriaFormState,
            visible: false,
            // @ts-ignore
            defaultData: {
              questions: data?.questions,
              rubrics: data?.rubrics,
              title: data?.title,
            },
          })
        }, 100)

        setTimeout(() => {
          formJurnalFasilitator.resetFields()
        }, 100)
      },
    },
  })

  const { mutateAsync: updateJurnalFasilitator } = useUpdate()

  const handleAddPertanyaanList = () => {
    const newList = [...pertanyaanList]
    newList.push(newList.length)

    setPertanyaanList(newList)
    setHasMutated(true)
  }

  const handleRemovePertanyaanList = (index: number) => {
    const newList = pertanyaanList.filter((item) => item !== index)
    setPertanyaanList(newList)
  }

  const handleUpdateCriteria = (data: any) => {
    setJurnalFasilitatorTempData({
      ...jurnalFasilitatorTempData,
      ...data,
    })

    setHasMutated(true)
  }

  const handleUpdateRubrics = (data: any) => {
    // update from jurnalFasilitatorTempData
    setJurnalFasilitatorTempData({
      ...jurnalFasilitatorTempData,
      rubrics: data.rubrics,
    })

    setHasMutated(true)
  }

  const handleApplyFormChanges = () => {
    const tempPayload = { ...jurnalFasilitatorTempData }

    delete tempPayload.total

    updateJurnalFasilitator({
      resource: `pgp-dasus/programs/${programId}/fi`,
      id: 'jurnal_fasilitator',
      values: tempPayload,
      dataProviderName: 'lms',
      successNotification: (e: any) => {
        return {
          message: 'Success',
          description: e.data.message,
          type: 'success',
        }
      },
      errorNotification: (errorRes: any) => {
        const responseErr = errorRes?.response?.data?.error?.message || ''

        return {
          message: responseErr,
          description: 'Failed',
          type: 'error',
        }
      },
    }).then(() => {
      setHasMutated(false)
    })
  }

  const OPTIONS_CHOICES_TYPE = ['checkbox', 'multiple_choices']

  const optionsSelectionForm = Form.useWatch('questions', formJurnalFasilitator)

  const getOptionsType = (index: number) => {
    if (optionsSelectionForm) {
      return optionsSelectionForm[index]?.type
    }
    return 'multiple_choices'
  }

  const isOptionsTypeChoices = (index: number) => {
    return OPTIONS_CHOICES_TYPE.includes(getOptionsType(index))
  }

  return (
    <Drawer
      visible={visible}
      closable={false}
      width="50%"
      className="relative"
      destroyOnClose

      // {...props.drawerProps}
    >
      <div className="flex justify-end items-end">
        <Button
          size="small"
          icon={<Icons.CloseOutlined />}
          onClick={() => {
            if (hasMutated) {
              window.confirm(
                'Are you sure you want to close this form? Your changes will be lost'
              ) && onClose()
            } else {
              onClose()
            }
          }}
        />
      </div>
      <Tabs defaultActiveKey="criteria" destroyInactiveTabPane>
        <Tabs.TabPane tab="Jurnal Fasilitator" key="criteria">
          <div className="max-h-[82vh] overflow-y-auto">
            <Spin spinning={!!tableProps.loading}>
              <Divider />

              <div className="flex justify-between items-center">
                <h3>FI Jurnal Fasilitator</h3>

                {/* <Button
                  onClick={handleCloseCriteriaForm}
                  icon={<Icons.CloseOutlined />}
                /> */}
              </div>

              <div className="p-8">
                <Form
                  form={formJurnalFasilitator}
                  layout="horizontal"
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 15 }}
                  size="small"
                  initialValues={criteriaFormState?.defaultData || {}}
                  onFinish={(values: any) => {
                    handleUpdateCriteria(values)
                  }}
                  validateTrigger="onChange"
                >
                  {/* hidden id */}
                  <Form.Item name="id" hidden>
                    <Input />
                  </Form.Item>

                  <Form.Item name="scorings" hidden>
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="title"
                    label="Criteria / Title Name"
                    rules={[{ required: true, message: 'Title is required' }]}
                  >
                    <Input />
                  </Form.Item>

                  <Button
                    className="my-4"
                    block
                    onClick={() => handleAddPertanyaanList()}
                  >
                    Tambah Pertanyaan
                  </Button>

                  {pertanyaanList.map((_, pertanyaanIndex) => (
                    <div
                      key={`pertanyaan-${pertanyaanIndex}`}
                      className="flex flex-col w-full p-2 border rounded-md mt-4"
                    >
                      <div className="flex justify-end mb-4">
                        <Button
                          type="text"
                          icon={<Icons.CloseOutlined />}
                          onClick={() =>
                            handleRemovePertanyaanList(pertanyaanIndex)
                          }
                        />
                      </div>

                      <Input.Group>
                        <Form.Item
                          name={['questions', pertanyaanIndex, 'content']}
                          label="Content"
                          rules={[
                            {
                              required: true,
                              // message: 'Title is required',
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>

                        <Form.Item
                          name={['questions', pertanyaanIndex, 'type']}
                          label="Tipe"
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <Select
                            options={[
                              {
                                label: 'Multiple Choice',
                                value: 'multiple_choices',
                              },
                              {
                                label: 'Checkbox',
                                value: 'checkbox',
                              },
                              {
                                label: 'Text Area',
                                value: 'text_area',
                              },
                              {
                                label: 'Input Text',
                                value: 'text',
                              },
                            ]}
                          />
                        </Form.Item>
                      </Input.Group>

                      {isOptionsTypeChoices(pertanyaanIndex) && (
                        <Form.List
                          name={['questions', pertanyaanIndex, 'choices']}
                          key={`questions-${pertanyaanIndex}`}
                        >
                          {(fields, { add, remove }) => (
                            <>
                              <div className="flex justify-end mr-[18px]">
                                <Button
                                  size="small"
                                  onClick={() => {
                                    add()
                                  }}
                                >
                                  Tambah Opsi Jawabaset n
                                </Button>
                              </div>

                              <Divider />

                              <div className="overflow-y-auto max-h-[30vw]">
                                {fields.map((field, index) => {
                                  return (
                                    <div
                                      key={`field-list-scoring-${index}`}
                                      className="flex flex-row items-center w-full"
                                    >
                                      <div className="flex w-full flex-col">
                                        <Form.Item
                                          {...field}
                                          label="Text jawaban"
                                          className="w-full !mb-2"
                                          name={[field.name]}
                                          key={`${field.key}-text-${index}`}
                                          rules={[
                                            {
                                              required: true,
                                              message: 'text is required',
                                            },
                                          ]}
                                        >
                                          <Input />
                                        </Form.Item>
                                      </div>

                                      <Button
                                        danger
                                        onClick={() => remove(field.name)}
                                        icon={<Icons.DeleteOutlined />}
                                      />
                                    </div>
                                  )
                                })}
                              </div>
                            </>
                          )}
                        </Form.List>
                      )}
                    </div>
                  ))}

                  <Button className="mt-8" htmlType="submit" block>
                    Apply Questions Changes
                  </Button>
                </Form>
              </div>
            </Spin>
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Rubrik" key="rubrik">
          <h3 className="mb-8">Form Rubrik</h3>
          <div className="max-h-[65vh] overflow-y-auto p-2 mt-4">
            <Form
              form={formRubrik}
              layout="vertical"
              initialValues={jurnalFasilitatorTempData || {}}
              onFinish={(values: any) => {
                handleUpdateRubrics(values)
              }}
            >
              <Form.Item
                name="rubrics"
                label="Rubrik"
                rules={[{ required: true, message: 'Title is required' }]}
              >
                <Editor minHeight="300px" toolbarImageUploader={false} />
              </Form.Item>

              <Button htmlType="submit" block>
                Apply Changes
              </Button>
            </Form>
          </div>
        </Tabs.TabPane>
      </Tabs>

      <div className="absolute bottom-0 flex p-4 w-[95%]">
        <Button
          onClick={handleApplyFormChanges}
          block
          className="mt-2"
          disabled={!hasMutated}
        >
          Apply Form Jurnal Fasilitator Changes
        </Button>
      </div>
    </Drawer>
  )
}
