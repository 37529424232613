import Quill from 'react-quill'

import { IMAGE_MIME_LIST } from 'src/constant'

export const getAcceptedFile = (type: 'quill-editor' | 'document') => {
  switch (type) {
    case 'quill-editor':
      return 'image/*'
    default:
      return '*'
  }
}

type THandleAttachmentParams = {
  quillRef: React.RefObject<Quill>
  fileUrl: string | null
  fileType: string
  selectionIndex?: number
}
export const handleAttachment = ({
  quillRef,
  fileUrl,
  fileType,
  selectionIndex,
}: THandleAttachmentParams) => {
  if (!fileType || !fileUrl) return

  const quillObj = quillRef?.current?.getEditor()
  const range = selectionIndex || quillObj?.getSelection()?.index || 0

  quillObj?.setSelection(range, 0)

  if (IMAGE_MIME_LIST.includes(fileType)) {
    quillRef.current?.editor?.insertEmbed(range, 'image', fileUrl)
    return
  }

  const textLink = prompt('Text showed for linked document:')
  if (!textLink) return

  quillRef.current?.editor?.insertText(range, textLink, 'link', fileUrl)
}

export const formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'align',
  'strike',
  'script',
  'blockquote',
  'background',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'color',
  'code-block',
]

export function extractYTIdFromUrl(url?: string) {
  if (!url) return ''

  let regExp =
    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
  let match = url.match(regExp)
  if (match && match[7].length === 11) {
    return match[7]
  }
  return ''
}
