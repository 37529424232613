import { Icons } from '@pankod/refine-antd'

const { UploadOutlined } = Icons

type TQuillToolBarProps = {
  id: number | string
  image?: boolean
  uploader?: boolean
  video?: boolean
  link?: boolean
  indent?: boolean
  list?: boolean
  header?: boolean
}
const QuillToolbar = ({
  id,
  uploader = false,
  link = true,
  indent = true,
  list = true,
  header = false,
  image,
}: TQuillToolBarProps) => (
  <div id={`toolbar-${id}`} className="ql-toolbar ql-snow">
    {header && (
      <span className="ql-formats">
        <select
          className="ql-header"
          defaultValue={''}
          onChange={(e) => e.persist()}
        >
          <option value="1"></option>
          <option value="2"></option>
          <option value="3"></option>
          <option value="4"></option>
          <option value="5"></option>
          <option selected></option>
        </select>
      </span>
    )}
    <span className="ql-formats">
      <button title="Toggle bold text" className="ql-bold" />
      <button title="Toggle italic text" className="ql-italic" />
      <button title="Toggle underline text" className="ql-underline" />
      <button title="Toggle strikethrough text" className="ql-strike" />
    </span>
    {(list || indent) && (
      <>
        <span className="ql-formats">
          {list && (
            <>
              <button
                title="Insert ordered list"
                className="ql-list"
                value="ordered"
              />
              <button
                title="Insert bullet list"
                className="ql-list"
                value="bullet"
              />
            </>
          )}

          {indent && (
            <>
              <button
                title="Decrease indentation"
                className="ql-indent"
                value="-1"
              />
              <button
                title="Increase indentation"
                className="ql-indent"
                value="+1"
              />
            </>
          )}
        </span>
      </>
    )}

    <span className="ql-formats">
      {image && <button title="Upload image" className="ql-image" />}
      {link && (
        <button
          title="Insert URL link to the selected text"
          className="ql-link"
        />
      )}
      {uploader && (
        <button
          title="Upload or add file from media library"
          className="ql-uploader"
        >
          <UploadOutlined />
        </button>
      )}
    </span>
  </div>
)

export default QuillToolbar
