import {
  Button,
  Descriptions,
  Icons,
  Modal,
  Steps,
  useTableReturnType,
} from '@pankod/refine-antd'
import {
  CrudFilters,
  LogicalFilter,
  useGetIdentity,
  useResource,
} from '@pankod/refine-core'
import React, { useState } from 'react'

import LocalProgramSelect from '@components/DataTableParticipants/blocks/ActorMigrationModal/LocalProgramSelect'
import ClassSelect from '@components/DataTableParticipants/blocks/ActorMigrationModal/ClassSelect'

const getTextByField = (field: ModalFilterIdMigrationTableProps['field']) => {
  switch (field) {
    case 'localProgramIdFrom':
      return 'Program Local Origin'
    case 'localProgramIdTo':
      return 'Program Local Destination'
    case 'kelasIdFrom':
      return 'Class Origin'
    case 'kelasIdTo':
      return 'Class Destination'
    default:
      return '-'
  }
}

export const useModalFilterIdMigrationTable = () => {
  const [field, setField] =
    useState<ModalFilterIdMigrationTableProps['field']>(null)

  const show = (field: ModalFilterIdMigrationTableProps['field']) => {
    setField(field)
  }

  const onClose = () => {
    setField(null)
  }

  return {
    show,
    onClose,
    visible: !!field,
    field,
  }
}

type ModalFilterIdMigrationTableProps = {
  currentFilters: LogicalFilter[]
  setFilters: useTableReturnType['setFilters']
  visible: boolean
  field:
    | 'localProgramIdFrom'
    | 'localProgramIdTo'
    | 'kelasIdFrom'
    | 'kelasIdTo'
    | null
  onClose: () => void
}
const ModalFilterIdMigrationTable = ({
  currentFilters,
  setFilters,
  visible,
  field,
  onClose,
}: ModalFilterIdMigrationTableProps) => {
  const { id: programId } = useResource()
  const { data } = useGetIdentity()
  const LMSRole = data?.user?.LMSRole || ''
  const localProgramResource =
    LMSRole === 'admin-entitas'
      ? `entities/programs/${programId}/local-programs`
      : `programs/${programId}/local-programs`
  const [id, setId] = useState<{
    localProgramIdFrom: { id?: string; name?: string }
    localProgramIdTo: { id?: string; name?: string }
    kelasIdFrom: { id?: string; name?: string }
    kelasIdTo: { id?: string; name?: string }
  }>({
    localProgramIdFrom: {
      id: currentFilters.find((data) => data.field === 'localProgramIdFrom')
        ?.value,
      name: '',
    },
    localProgramIdTo: {
      id: currentFilters.find((data) => data.field === 'localProgramIdTo')
        ?.value,
      name: '',
    },
    kelasIdFrom: {
      id: currentFilters.find((data) => data.field === 'kelasIdFrom')?.value,
      name: '',
    },
    kelasIdTo: {
      id: currentFilters.find((data) => data.field === 'kelasIdTo')?.value,
      name: '',
    },
  })
  const [current, setCurrent] = useState(0)

  const steps = [
    {
      title: 'Program Local',
      key: 'localProgramStep',
      content: (
        <LocalProgramSelect
          onSelect={(data) => {
            const isFrom = field?.includes('IdFrom')
            setId((prev) => ({
              ...prev,
              [isFrom ? 'localProgramIdFrom' : 'localProgramIdTo']: data,
            }))
          }}
          selected={
            field?.includes('IdFrom')
              ? { id: id['localProgramIdFrom'].id }
              : { id: id['localProgramIdTo'].id }
          }
          resource={localProgramResource}
          programId={programId}
        />
      ),
    },
    {
      title: 'Class',
      key: 'classStep',
      content: (
        <ClassSelect
          onSelect={(data) => {
            setId((prev) => ({ ...prev, [field || '']: data }))
          }}
          selected={field ? { id: id[field].id } : {}}
          programId={programId}
          localProgramId={
            field === 'kelasIdFrom'
              ? id['localProgramIdFrom'].id
              : id['localProgramIdTo'].id
          }
        />
      ),
    },
  ]

  return (
    <Modal
      visible={visible}
      width={600}
      onCancel={() => {
        setId((prev) => ({
          ...prev,
          [field || '']: {
            id: currentFilters.find((data) => data.field === field)?.value,
          },
        }))
        setCurrent(0)
        setId({
          kelasIdFrom: {},
          kelasIdTo: {},
          localProgramIdFrom: {},
          localProgramIdTo: {},
        })
        onClose()
      }}
      destroyOnClose
      okText="Clear"
      cancelText="Filter"
      cancelButtonProps={{
        onClick: () => {
          if (!field) return
          setFilters((prev) => {
            const filtered: CrudFilters = (prev as LogicalFilter[]).filter(
              (data) => {
                if (field.startsWith('localProgram')) {
                  return (
                    data.field !== 'kelasIdFrom' &&
                    data.field !== 'kelasIdTo' &&
                    data.field !== field &&
                    data.field !== 'page'
                  )
                }
                return (
                  data.field !== 'localProgramIdFrom' &&
                  data.field !== 'localProgramIdTo' &&
                  data.field !== field &&
                  data.field !== 'page'
                )
              }
            )
            filtered.push(
              {
                field: String(field),
                operator: 'eq',
                value: id[field].id,
              },
              { field: 'page', operator: 'eq', value: 1 }
            )
            return filtered
          })
          setCurrent(0)
          setId({
            kelasIdFrom: {},
            kelasIdTo: {},
            localProgramIdFrom: {},
            localProgramIdTo: {},
          })
          onClose()
        },
        icon: <Icons.FilterOutlined />,
        type: 'primary',
        disabled:
          (field === 'kelasIdFrom' &&
            (!id.kelasIdFrom.id || !id.localProgramIdFrom.id)) ||
          (field === 'kelasIdTo' &&
            (!id.kelasIdTo.id || !id.localProgramIdTo.id)),
      }}
      okButtonProps={{
        onClick: () => {
          setFilters((previousFilters) => [
            ...(previousFilters as LogicalFilter[]).filter(
              (data) => data.field !== field && data.field !== 'page'
            ),
            { field: 'page', operator: 'eq', value: 1 },
          ])
          setId((prev) => ({ ...prev, [field || '']: {} }))
          setCurrent(0)
          onClose()
        },
        type: 'ghost',
        danger: true,
      }}
      title={<>Filter by {getTextByField(field)}</>}
    >
      {field?.startsWith('kelasId') && (
        <Steps current={current}>
          {steps.map(({ title, key }) => (
            <Steps.Step key={key} title={title} />
          ))}
        </Steps>
      )}
      <Descriptions bordered className="my-6">
        {field && (
          <Descriptions.Item
            label={`Current ${getTextByField(field)} ID Selected`}
          >
            {currentFilters?.find((filter) => filter.field === field)?.value ||
              '-'}
          </Descriptions.Item>
        )}
      </Descriptions>
      {steps[current].content}
      {field?.startsWith('kelasId') && (
        <div className="flex justify-end">
          <Button
            onClick={() => {
              if (current === 0) {
                setCurrent(1)
              } else {
                setId((prev) => ({
                  ...prev,
                  kelasIdFrom: {},
                  kelasIdTo: {},
                }))
                setCurrent(0)
              }
            }}
            type={current === 0 ? 'primary' : 'ghost'}
            disabled={
              field.includes('From')
                ? !id.localProgramIdFrom.id
                : !id.localProgramIdTo.id
            }
          >
            {current === 0 ? 'Next' : 'Back'}
          </Button>
        </div>
      )}
    </Modal>
  )
}

export default ModalFilterIdMigrationTable
