import { useCustom, useCustomMutation } from '@pankod/refine-core'
import { AxiosInstance, AxiosRequestHeaders } from 'axios'
import nookies from 'nookies'

import { PenilaianWebinarDrawerState } from './PenilaianWebinarDrawer'
import { InstructorWebinarSchedule } from '@resources/webinar-management/types'
import { SESSION } from 'src/constant'

export const useGetFormResponse = ({
  encodedUserId,
  ids,
  selectedPage,
}: {
  encodedUserId: string
  ids: PenilaianWebinarDrawerState
  selectedPage: {
    formId: string | null
    type: 'qualitative' | 'quantitative'
    penilaianId: string | null
    penilaianName: string | null
  }
}) => {
  return useCustom({
    method: 'get',
    url: `/api/forms/${selectedPage.formId}`,
    queryOptions: {
      enabled: !!selectedPage.formId,
      select: (data) => {
        const response = JSON.parse(
          data?.data?.formResponseData?.data?.[0]?.payload || '{}'
        )
        const formValue = response?.data
        return { data: { formValue, meta: { ...response?.meta } } }
      },
    },
    config: {
      filters: [
        {
          field: 'formIdentifier',
          operator: 'eq',
          value: `${ids.webinarId}_${ids.webinarKelasGroupId}_${selectedPage.penilaianId}_${encodedUserId}_${selectedPage.type}_penilaianWebinar`,
        },
        {
          field: 'formLimit',
          operator: 'eq',
          value: 1,
        },
      ],
    },
  })
}

export type FormSubmissionStatusBase = {
  formId: string
  senderId: string
  formStateId: string
}

enum FormSubmissionStateEnum {
  FORM_EMPTY = 'FORM_EMPTY',
  FORM_DRAFT = 'FORM_DRAFT',
  FORM_FINISHED = 'FORM_FINISHED',
}

export type FormSubmissionState = keyof typeof FormSubmissionStateEnum

export type FormSubmissionStatusRequest = FormSubmissionStatusBase
export type FormSubmissionStatusResponse = FormSubmissionStatusBase & {
  state: FormSubmissionState
}

const getFormIdentifier = ({
  encodedUserId,
  moduleId,
  webinarKelasGroupId,
  webinarPenilaianId,
  formType,
}: {
  moduleId: string
  webinarKelasGroupId: string
  webinarPenilaianId: string
  encodedUserId: string
  formType: 'qualitative' | 'quantitative'
}) =>
  `${moduleId}_${webinarKelasGroupId}_${webinarPenilaianId}_${encodedUserId}_${formType}_penilaianWebinar`

const generateFormsSubmissionRequest = (params: {
  webinar: InstructorWebinarSchedule[]
  userId: string
}) => {
  const formStateObject: Record<string, FormSubmissionState> = {}
  const forms = params.webinar.reduce(
    (prev, { webinar, webinarKelasGroupId, penilaianWebinar }, i) => {
      if (!penilaianWebinar) return prev
      const penilaianWebinarList = penilaianWebinar.indicators.reduce(
        (prev, { id: moduleId, formKuantitatif, formKualitatif }, j) => {
          const forms = []
          let formStateId = `${i}_${j}_formKuantitatif`
          formStateObject[formStateId] = formKuantitatif.status
          forms.push({
            formId: formKuantitatif.id,
            senderId: getFormIdentifier({
              moduleId: webinar.id,
              encodedUserId: params.userId,
              webinarPenilaianId: moduleId,
              webinarKelasGroupId,
              formType: 'quantitative',
            }),
            formStateId,
          })
          if (formKualitatif) {
            formStateId = `${i}_${j}_formKualitatif`
            formStateObject[formStateId] = formKualitatif.status
            forms.push({
              formId: formKualitatif.id,
              senderId: getFormIdentifier({
                moduleId: webinar.id,
                encodedUserId: params.userId,
                webinarPenilaianId: moduleId,
                webinarKelasGroupId,
                formType: 'qualitative',
              }),
              formStateId,
            })
          }
          return [...prev, ...forms]
        },
        [] as FormSubmissionStatusBase[]
      )
      return [...prev, ...penilaianWebinarList]
    },
    [] as FormSubmissionStatusBase[]
  )
  return {
    formStateObject,
    forms,
  }
}

const useQueryFormSubmissionStatus = () => {
  const { mutateAsync, reset } = useCustomMutation<
    { data: FormSubmissionStatusResponse[] },
    any,
    { forms: FormSubmissionStatusRequest[] }
  >()
  const getFormSubmissionStatus = async ({
    forms,
  }: {
    forms: FormSubmissionStatusRequest[]
  }) => {
    return (
      await mutateAsync({
        url: `/api/forms-response-submission`,
        method: 'post',
        values: { forms },
      })
    ).data.data
  }
  return { getFormSubmissionStatus, reset }
}

export const useQueryJadwalWebinar = () => {
  const { getFormSubmissionStatus, reset } = useQueryFormSubmissionStatus()
  const encodedUserId = JSON.parse(nookies.get()[SESSION.USER_SESSION])?.user
    ?.id

  return {
    resetFormSubmission: reset,
    ...useCustom<{
      data: InstructorWebinarSchedule[]
      meta: { shouldPoll: boolean }
    }>({
      url: `${process.env.NEXT_PUBLIC_LMS_API_URL}/instructor/webinars-schedule`,
      method: 'get',
      dataProviderName: 'lms',
      queryOptions: {
        refetchInterval(data) {
          return data?.data?.meta?.shouldPoll ? 5000 : false
        },
      },
      config: {
        filters: [{ field: 'pageSize', operator: 'eq', value: -1 }],
      },
      metaData: {
        customQueryFn: async ({
          headers,
          axiosInstance,
          url,
        }: {
          headers: AxiosRequestHeaders
          axiosInstance: AxiosInstance
          url: string
        }) => {
          const data = await axiosInstance.get(url, { headers })
          const { formStateObject, forms } = generateFormsSubmissionRequest({
            webinar: data.data.data,
            userId: encodedUserId,
          })

          const formState = await getFormSubmissionStatus({ forms })

          let shouldPoll = false
          formState?.forEach(({ formStateId, state }) => {
            const isPending =
              formStateObject[formStateId] !== 'FORM_FINISHED' &&
              formStateObject[formStateId] !== state
            shouldPoll = shouldPoll || isPending
            const [i, j, type] = formStateId?.split('_')
            data.data.data[+i].penilaianWebinar.indicators[+j][type].isPending =
              isPending
          })

          data.data.meta = {
            ...data.data.meta,
            shouldPoll,
          }
          return data
        },
      },
    }),
  }
}
