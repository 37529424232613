import moment from 'moment'

moment.locale('id')

export const parseDate = (dateStr: string | null) => {
  return moment(dateStr, [
    moment.ISO_8601,
    'llll',
    'LLLL',
    'lll',
    'LLL',
    'll',
    'LL',
    'l',
    'L',
  ])
}

export const subtractHours = (timeString: string, numToSubtract: number) => {
  return moment(timeString, 'HH:mm')
    .subtract(numToSubtract, 'hours')
    .format('HH:mm')
}

export const isBeforeToday = (dateStr: string, timeStr: string) => {
  return moment(`${dateStr} ${timeStr}`).isBefore(moment())
}
