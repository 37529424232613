import { useEffect } from 'react'
import { useEditor, useNode } from '@craftjs/core'

import { pascalToTextCase } from 'src/helpers/text'

const EditorRenderer: React.FC<{
  render: React.ReactElement<any, string | React.JSXElementConstructor<any>>
}> = ({ render }) => {
  const { dom, id, isError, name } = useNode((node) => ({
    dom: node.dom,
    name: node.data.custom.name || node.data.displayName,
    isError: node.data.custom.errors?.length > 0 || 0,
  }))
  const { isActive } = useEditor((_, query) => ({
    isActive: query.getEvent('selected').contains(id),
  }))
  useEffect(() => {
    if (dom) {
      dom.dataset.widgetname = pascalToTextCase(name)

      if (isActive) dom.classList.add('widget-component__selected')
      else dom.classList.remove('widget-component__selected')

      if (isError) dom.classList.add('widget-component__error')
      else dom.classList.remove('widget-component__error')
    }
  }, [dom, isActive, isError, name])

  return <>{render}</>
}

export default EditorRenderer
