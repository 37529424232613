import { notification } from '@pankod/refine-antd'
import { StringAdapter } from 'casbin'
import { useEffect, useState } from 'react'
import nookies from 'nookies'
import lz from 'lzutf8'

import { SESSION } from 'src/constant'
import { UserSession } from 'types/user'

type TLoadingState = 'idle' | 'loading' | 'success' | 'error'
// TODO: adjust the return type
type TGetLMSAdminRole = string | null | undefined
type UserLPTKId = number | null | undefined

export type ACLDataAsMap = [
  StringAdapter,
  TLoadingState,
  TGetLMSAdminRole,
  UserLPTKId
]

export type GetACLAsObject = {
  stringAdapter: StringAdapter
  loadingState: TLoadingState
  lmsRole: TGetLMSAdminRole
  userLPTKId: UserLPTKId
}

const useGetACL = (): ACLDataAsMap => {
  const [ACLData, setACLData] = useState(new StringAdapter(''))
  const [loadACLState, setLoadState] = useState<TLoadingState>('idle')
  const [lmsRole, setLMSRole] = useState<string | null | undefined>()
  const [userLPTKId, setUserLPTKId] = useState<number | null | undefined>()

  useEffect(() => {
    const fetchACL = async () => {
      setLoadState('loading')
      try {
        const response = await fetch(`${process.env.BASE_PATH}/api/acl`)
        if (!response.ok) {
          throw new Error(response.statusText)
        }
        const data = await response.json()
        const adapter = lz.decompress(lz.decodeBase64(data.data.adapter))
        setACLData(new StringAdapter(adapter))
        setLoadState('success')
        getLMSRoles()
        getUserLPTK()
      } catch (e) {
        notification.error({ message: 'Error fetching ACL data' })
        setLoadState('error')
      }
    }

    fetchACL()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getLMSRoles = () => {
    if (lmsRole) {
      return lmsRole
    }

    const userSession =
      nookies.get()[SESSION.USER_SESSION] ||
      JSON.stringify({ user: { LMSRole: null } })
    const { user }: UserSession = JSON.parse(userSession)

    setLMSRole(user.LMSRole as string)

    return user.LMSRole as string
  }

  const getUserLPTK = () => {
    if (userLPTKId) {
      return userLPTKId
    }

    const userSession =
      nookies.get()[SESSION.USER_SESSION] ||
      JSON.stringify({ user: { lptkId: null } })
    const { user }: UserSession = JSON.parse(userSession)

    setUserLPTKId(user.lptkId)

    return user.lptkId
  }

  return [ACLData, loadACLState, lmsRole, userLPTKId] as ACLDataAsMap
}

export default useGetACL
