import {
  FormProps,
  ModalProps,
  Modal,
  Form,
  TableColumnGroupType,
  TableColumnType,
  Table,
  useTable,
  TableProps,
  message,
  Tag,
  Select,
} from '@pankod/refine-antd'
import React, { useState } from 'react'

import { PesertaResponse } from '@resources/angkatan-ppg-management/types'
import { TCommonError } from 'src/interfaces/common'
import { SelectBidangStudyOptions } from '@resources/basic-ppg-management/lptk/blocks/SelectLPTKDataOptions'
import {
  getMatrikulasiStatus,
  getRegisteredMatrikulasiStatus,
} from '@resources/basic-ppg-management/utils/matrikulasi_status_map'

type PesertaAddModalFormProps = {
  formProps: FormProps
  modalProps: ModalProps
  onClose: () => void
  isSubmitting: boolean
  angkatanId?: number | string
  lptkId: number | null
  rombelId?: number
  onRefresh: () => void
}
export const PesertaAddModalForm = ({
  formProps,
  modalProps,
  onClose,
  lptkId,
  angkatanId,
  rombelId,
  isSubmitting,
  onRefresh,
}: PesertaAddModalFormProps) => {
  const [pesertaIds, setPesertaIds] = useState<Set<React.Key>>(new Set())
  const { tableProps, tableQueryResult, setFilters } = useTable<
    PesertaResponse,
    TCommonError
  >({
    dataProviderName: 'lms',
    resource: `angkatan/${angkatanId}/lptk/${lptkId}/rombel/${rombelId}/peserta/available-list`,
    queryOptions: {
      enabled: modalProps.visible,
      onSuccess: (data: any) => {
        /**
         * This is a workaround for a bug in refine-antd
         * where and the pagination internal state still next page
         * and the API still return correct empty data.
         * causing the table to be stuck in empty without control for next/prev pages.
         */

        const paginationAnomaly = {
          currentPageInvalid:
            data.meta?.currentPage > data.meta?.totalPages &&
            data.meta.totalPages > 0,
          dataLengthInvalid: data.data.length === 0 && data.total > 0,
        }

        if (
          paginationAnomaly.currentPageInvalid ||
          paginationAnomaly.dataLengthInvalid
        ) {
          onRefresh()
        }
      },
    },
  })

  const MAHASISWA_STATUS_MAP: any = {
    TERDAFTAR: 'Terdaftar',
    TIDAK_LAPOR_DIRI: 'Tidak Lapor Diri',
    MENGUNDURKAN_DIRI: 'Mengundurkan Diri',
    EMPTY: 'Belum Diketahui',
  }

  const columns: (
    | TableColumnType<PesertaResponse>
    | TableColumnGroupType<PesertaResponse>
  )[] = [
    {
      title: 'Nama',
      key: 'name',
      dataIndex: 'name',
    },
    {
      title: 'Email',
      key: 'email',
      dataIndex: 'email',
    },
    {
      title: 'Bidang Studi',
      key: 'bidangStudi',
      dataIndex: 'bidangStudi',
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (status: string) => (
        <Tag>{MAHASISWA_STATUS_MAP[status || 'EMPTY']}</Tag>
      ),
    },
    {
      title: 'Pendaftaran Matrikulasi',
      key: 'isMatrikulasi',
      dataIndex: 'isMatrikulasi',
      render: (status: boolean) => (
        <Tag color={getRegisteredMatrikulasiStatus(status).color}>
          {getRegisteredMatrikulasiStatus(status).text}
        </Tag>
      ),
    },

    {
      title: 'Status Matrikulasi',
      key: 'matrikulasi',
      dataIndex: 'matrikulasi',
      render: (status: string) => (
        <Tag color={getMatrikulasiStatus(status).color}>
          {getMatrikulasiStatus(status).text}
        </Tag>
      ),
    },
    {
      title: 'Lulusan',
      key: 'lulusan',
      dataIndex: 'lulusan',
      render: (lulusan: string) => <Tag>{lulusan}</Tag>,
    },
  ]
  const rowSelection: TableProps<PesertaResponse>['rowSelection'] = {
    hideSelectAll: false,
    onSelect: (peserta) => {
      if (pesertaIds.has(peserta.id)) {
        setPesertaIds((values) => {
          values.delete(peserta.id)
          return new Set(values)
        })
        return
      }

      setPesertaIds((values) => {
        values.add(peserta.id)
        return new Set(values)
      })
    },
    selectedRowKeys: Array.from(pesertaIds),
    onSelectAll: (selected, _selectedRows, _changeRows) => {
      if (selected) {
        setPesertaIds((values) => {
          const newValues = new Set(values)
          tableProps.dataSource?.forEach((item: any) => {
            if (item?.id) {
              newValues.add(item.id)
            }
          })
          return new Set(newValues)
        })
        return
      }

      setPesertaIds((values) => {
        const newValues = new Set(values)
        tableProps.dataSource?.forEach((item: any) => {
          if (item?.id) {
            newValues.delete(item.id)
          }
        })
        return new Set(newValues)
      })
    },
  }

  return (
    <Modal
      {...modalProps}
      onCancel={onClose}
      afterClose={() => {
        setPesertaIds((values) => {
          values.clear()
          return new Set(values)
        })
      }}
      title="Tambah Peserta"
      okText="Simpan"
      cancelText="Kembali"
      maskClosable={false}
      okButtonProps={{
        onClick: () => {
          formProps.form?.setFieldsValue({
            pesertaIds: Array.from(pesertaIds),
          })
          formProps.form?.submit()
        },
        loading: isSubmitting,
      }}
    >
      <div className="flex flex-row justify-center gap-2 mb-4">
        <p>Filter By: </p>
        <Select
          className="w-[15vw] ml-4"
          placeholder="Pendaftaran Matrikulasi"
          allowClear
          onChange={(value: any) => {
            setFilters([
              {
                field: 'isMatrikulasi',
                operator: 'eq',
                value: value,
              },
            ])
          }}
          options={[
            { label: 'Terdaftar', value: true },
            { label: 'Tidak Terdaftar', value: false },
          ]}
        />
        <Select
          className="w-[15vw] ml-4"
          placeholder="Matrikulasi"
          allowClear
          onChange={(value: any) => {
            setFilters([
              {
                field: 'matrikulasi',
                operator: 'eq',
                value: value,
              },
            ])
          }}
          options={[
            { label: 'Selesai', value: 'SELESAI' },
            { label: 'Belum Selesai', value: 'BELUM_SELESAI' },
          ]}
        />
        <SelectBidangStudyOptions
          className="w-[15vw]"
          placeholder="Filter Bidang Studi"
          allowClear
          onChange={(value: any) => {
            setFilters([
              {
                field: 'bidangStudi',
                operator: 'eq',
                value: value,
              },
            ])
          }}
        />
      </div>

      <Form
        {...formProps}
        onFinishFailed={({ errorFields }) => {
          errorFields.forEach(({ errors }) => {
            message.error(errors[0])
          })
        }}
      >
        <Table
          {...tableProps}
          rowSelection={rowSelection}
          size="small"
          rowKey="id"
          columns={columns}
          loading={tableQueryResult.isFetching}
        />

        <Form.Item
          name="pesertaIds"
          hidden
          rules={[
            {
              validator(_, value) {
                if (!value || !value?.length)
                  return Promise.reject('Minimal 1 peserta harus dipilih')
                return Promise.resolve()
              },
            },
          ]}
        >
          <select />
        </Form.Item>
      </Form>
    </Modal>
  )
}
