import React from 'react'
import { Tag, TextField } from '@pankod/refine-antd'
import dayjs from 'dayjs'

import { InstructorWebinarPenilaianStatus } from '@resources/webinar-management/types'

export const TagPenilaianStatus = ({
  status,
  date,
}: {
  status?: InstructorWebinarPenilaianStatus
  date: string
}) => {
  if (!status)
    return <TextField value="Tidak ada penilaian" italic type="secondary" />
  if (status === 'NOT_STARTED') return <Tag>BELUM DIMULAI</Tag>
  if (status === 'AVAILABLE' && dayjs().isAfter(dayjs(date).add(7, 'day')))
    return <Tag color="error">TERLAMBAT</Tag>
  if (status === 'AVAILABLE') return <Tag color="warning">PERLU DINILAI</Tag>
  return <Tag color="success">LENGKAP</Tag>
}
