import {
  Button,
  Divider,
  Drawer,
  EditButton,
  Form,
  Icons,
  Input,
  Space,
  Spin,
  Table,
  Tabs,
  TextField,
  useTable,
} from '@pankod/refine-antd'
import React from 'react'
import { useUpdate } from '@pankod/refine-core'

import { Editor } from '@components/TUIEditor'

export type DrawerFormPenilaianPartisipasiProps = {
  visible: boolean
  drawerProps?: any
  onClose: () => void
  programId: string
}

export const DrawerFormPenilaianPartisipasi = (
  props: DrawerFormPenilaianPartisipasiProps
) => {
  const { visible, onClose, programId } = props
  const [formCriteria] = Form.useForm()
  const [PenilaianPartisipasiTempData, setPenilaianPartisipasiTempData] =
    React.useState<any>({})

  const [formRubrik] = Form.useForm()

  const [hasMutated, setHasMutated] = React.useState(false)

  const [criteriaFormState, setCriteriaFormState] = React.useState({
    mode: 'create',
    visible: false,
    defaultData: null,
  })

  const { tableProps, tableQueryResult } = useTable({
    resource: `pgp-dasus/programs/${programId}/ptm/penilaian_partisipasi`,
    dataProviderName: 'lms',
    queryOptions: {
      enabled: !!visible,
      onSuccess(data) {
        setPenilaianPartisipasiTempData(data)
      },
    },
  })

  const { mutateAsync: updatePenilaianPartisipasi } = useUpdate()

  const handleEditCriteria = async (defaultData: any) => {
    setTimeout(() => {
      defaultData.scorings = defaultData.scorings.filter((item: any) => !!item)

      formCriteria.resetFields()
    }, 0)

    setCriteriaFormState({
      mode: 'edit',
      visible: true,
      defaultData,
    })
  }

  const handleCloseCriteriaForm = () => {
    formCriteria.resetFields()

    setCriteriaFormState({
      mode: 'create',
      visible: false,
      defaultData: null,
    })
  }

  const handleCreateCriteria = () => {
    setTimeout(() => {
      formCriteria.resetFields()
    }, 0)
    setCriteriaFormState({
      mode: 'create',
      visible: true,
      defaultData: null,
    })
  }

  const handleDeleteCriteria = async (id: string) => {
    // delete from PenilaianPartisipasiTempData
    const newData = PenilaianPartisipasiTempData.criterias.filter(
      (item: any) => item.id !== id
    )
    await applyAndRefetch({
      ...PenilaianPartisipasiTempData,
      criterias: newData,
    })
  }

  const handleUpdateCriteria = async (data: any) => {
    const criteriaId = data.id

    data.scorings = data.scorings.filter((item: any) => !!item)
    // update from PenilaianPartisipasiTempData
    const newData = PenilaianPartisipasiTempData.criterias.map((item: any) => {
      if (item.id === criteriaId) {
        return data
      }
      return item
    })

    await applyAndRefetch({
      ...PenilaianPartisipasiTempData,
      criterias: newData,
    })
  }

  const handleAddCriteria = async (data: any) => {
    // add to PenilaianPartisipasiTempData
    const newData = PenilaianPartisipasiTempData.criterias.concat(data)

    await applyAndRefetch({
      ...PenilaianPartisipasiTempData,
      criterias: newData,
    })
  }

  const handleUpdateRubrics = async (data: any) => {
    await applyAndRefetch({
      ...PenilaianPartisipasiTempData,
      rubrics: data.rubrics,
    })
  }

  const applyAndRefetch = async (data: any) => {
    await handleImmediateUpdateForm(data)
    handleCloseCriteriaForm()
  }
  const handleImmediateUpdateForm = (payload: any) => {
    delete payload.total

    updatePenilaianPartisipasi({
      resource: `pgp-dasus/programs/${programId}/ptm`,
      id: 'penilaian_partisipasi',
      values: payload,
      dataProviderName: 'lms',
      successNotification: (e: any) => {
        return {
          message: 'Success',
          description: e.data.message,
          type: 'success',
        }
      },
      errorNotification: (errorRes: any) => {
        const responseErr = errorRes?.response?.data?.error?.message || ''

        return {
          message: responseErr,
          description: 'Failed',
          type: 'error',
        }
      },
    }).then(async () => {
      await tableQueryResult.refetch()
      setHasMutated(false)
    })
  }

  return (
    <Drawer
      visible={visible}
      closable={false}
      width="50%"
      className="relative"
      destroyOnClose
    >
      <div className="flex justify-end items-end">
        <Button
          size="small"
          icon={<Icons.CloseOutlined />}
          onClick={() => {
            if (hasMutated) {
              window.confirm(
                'Are you sure you want to close this form? Your changes will be lost'
              ) && onClose()
            } else {
              onClose()
            }
          }}
        />
      </div>
      <Tabs defaultActiveKey="criteria" destroyInactiveTabPane>
        <Tabs.TabPane tab="Penilaian partisipasi" key="criteria">
          <div>
            {criteriaFormState.visible ? (
              <Spin spinning={!!tableProps.loading}>
                <div className="flex justify-between items-center mt-2">
                  <h3>
                    {criteriaFormState.mode.toUpperCase()} Form Penilaian
                    partisipasi
                  </h3>

                  <Button
                    onClick={handleCloseCriteriaForm}
                    icon={<Icons.CloseOutlined />}
                  />
                </div>

                <div className="max-h-[65vh] overflow-y-auto p-2 mt-4">
                  <Form
                    form={formCriteria}
                    layout="horizontal"
                    labelCol={{ span: 5 }}
                    wrapperCol={{ span: 15 }}
                    size="small"
                    initialValues={criteriaFormState.defaultData || {}}
                    onFinish={(values: any) => {
                      if (criteriaFormState.mode === 'edit') {
                        handleUpdateCriteria(values)
                      } else {
                        handleAddCriteria(values)
                      }
                    }}
                    validateTrigger="onChange"
                  >
                    {/* hidden id */}
                    <Form.Item name="id" hidden>
                      <Input />
                    </Form.Item>

                    <Form.Item
                      name="title"
                      label="Criteria / Title Name"
                      rules={[{ required: true, message: 'Title is required' }]}
                    >
                      <Input />
                    </Form.Item>

                    <div className="flex flex-col w-full p-2 border rounded-md mt-4">
                      <Form.List name={['scorings']}>
                        {(fields, { add, remove }) => (
                          <>
                            <div className="flex justify-end mr-[18px]">
                              <Button
                                size="small"
                                onClick={() => {
                                  add()
                                }}
                              >
                                Tambah Scoring
                              </Button>
                            </div>

                            <Divider />

                            <div className="overflow-y-auto max-h-[30vw]">
                              {fields.map((field, index) => {
                                return (
                                  <>
                                    <div
                                      key={`field-list-scoring-${index}`}
                                      className="flex flex-row items-center w-full"
                                    >
                                      <Form.Item
                                        {...field}
                                        label="Value"
                                        className="w-full "
                                        name={[index, 'value']}
                                        key={`${field.key}-text-${index}-value`}
                                        rules={[
                                          {
                                            required: true,
                                          },
                                        ]}
                                      >
                                        <Input />
                                      </Form.Item>

                                      <Form.Item
                                        {...field}
                                        name={[index, 'point']}
                                        key={`${field.key}-text-${index}-point`}
                                        rules={[
                                          {
                                            required: true,
                                          },
                                        ]}
                                      >
                                        <Input type="number" />
                                      </Form.Item>

                                      <Form.Item>
                                        <Button
                                          danger
                                          className="mr-4"
                                          onClick={() => remove(field.name)}
                                          icon={<Icons.DeleteOutlined />}
                                        />
                                      </Form.Item>
                                    </div>
                                    <Divider />
                                  </>
                                )
                              })}
                            </div>
                          </>
                        )}
                      </Form.List>
                    </div>

                    <div className="absolute bottom-[-40px] w-full">
                      <Button htmlType="submit" block>
                        Apply Criteria Changes
                      </Button>
                    </div>
                  </Form>
                </div>
              </Spin>
            ) : (
              <>
                <div className="flex flex-end justify-end mb-4">
                  <Button onClick={handleCreateCriteria}>
                    Tambah Kriteria
                  </Button>
                </div>
                <Table
                  dataSource={PenilaianPartisipasiTempData?.criterias}
                  rowKey="id"
                  className="w-full"
                >
                  <Table.Column
                    dataIndex="title"
                    title="title"
                    render={(value) => <TextField value={value} />}
                  />
                  <Table.Column
                    title="Actions"
                    render={(_, record: any) => (
                      <Space>
                        <EditButton
                          size="small"
                          recordItemId={record.id}
                          onClick={() => {
                            handleEditCriteria(record)
                          }}
                        />
                        <Button
                          size="small"
                          danger
                          onClick={() => handleDeleteCriteria(record.id)}
                          icon={<Icons.DeleteOutlined />}
                        />
                      </Space>
                    )}
                  />
                </Table>
              </>
            )}
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Rubrik" key="rubrik">
          <h3 className="mb-8">Form Rubrik</h3>

          <Form
            form={formRubrik}
            layout="vertical"
            initialValues={PenilaianPartisipasiTempData || {}}
            onFinish={(values: any) => {
              handleUpdateRubrics(values)
            }}
          >
            <Form.Item
              name="rubrics"
              label="Rubrics"
              rules={[{ required: true }]}
            >
              <Editor minHeight="200" toolbarImageUploader={false} />
            </Form.Item>
            <Button htmlType="submit" block>
              Apply Changes
            </Button>
          </Form>
        </Tabs.TabPane>
      </Tabs>
    </Drawer>
  )
}
