import {
  ModalProps,
  FormProps,
  Modal,
  Form,
  Select,
  useSelect,
} from '@pankod/refine-antd'
import React from 'react'

import { LPTKResponse } from '@resources/basic-ppg-management/lptk/types'

type TModalFormProps = {
  modalProps: ModalProps
  formProps: FormProps
  currentLptkIds: number[]
  onClose: () => void
}
export const LPTKAssignModalForm = ({
  formProps,
  modalProps,
  onClose,
  currentLptkIds,
}: TModalFormProps) => {
  const { selectProps, queryResult } = useSelect<LPTKResponse>({
    resource: 'lptk',
    optionLabel: 'name',
    optionValue: 'id',
    dataProviderName: 'lms',
    queryOptions: {
      enabled: modalProps.visible,
      select: (data) => {
        const filteredData = data.data.filter(
          ({ id }) => !currentLptkIds.includes(id)
        )
        return {
          data: filteredData,
          total: filteredData.length,
        }
      },
    },
    defaultValueQueryOptions: {
      enabled: false,
    },
    filters: [
      {
        field: 'pageSize',
        operator: 'eq',
        value: -1,
      },
    ],
  })
  return (
    <Modal
      {...modalProps}
      onCancel={onClose}
      title="Tambah LPTK Baru"
      okText="Simpan"
      cancelText="Kembali"
    >
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Pilih LPTK baru"
          name="lptkId"
          required
          rules={[{ required: true, message: 'Tidak boleh kosong.' }]}
        >
          <Select
            {...selectProps}
            allowClear
            loading={queryResult.isFetching}
            mode="multiple"
            onSearch={() => {}}
            filterOption={(input, option) =>
              (option!.label as unknown as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}
