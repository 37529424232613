import { Collapse, TextField } from '@pankod/refine-antd'
import React from 'react'

type NotificationCollapsibleMessageProps = {
  message: string
  render: React.ReactNode
  header?: string
}

const NotificationCollapsibleMessage = ({
  message,
  render,
  header = 'Detail',
}: NotificationCollapsibleMessageProps) => {
  return (
    <div className="flex flex-col gap-3">
      <TextField value={message} />
      {render && (
        <Collapse>
          <Collapse.Panel header={header} key={'detail'}>
            {render}
          </Collapse.Panel>
        </Collapse>
      )}
    </div>
  )
}

export default NotificationCollapsibleMessage
