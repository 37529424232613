import {
  Button,
  Icons,
  Space,
  Table,
  TextField,
  useTable,
} from '@pankod/refine-antd'
import {
  CrudFilters,
  useNavigation,
  useRouterContext,
} from '@pankod/refine-core'
import React from 'react'

import { TFilterForm } from '@components/ContentFilter'
import { TCommonError } from 'src/interfaces/common'

const DASUS_PROGRAM_LOCAL_RESOURCE = 'program-dasus'

export type TTableDasusProgramLocalListProps = {
  dasusProgramId: string | number
}

export const TableDasusProgramLocalList = (
  props: TTableDasusProgramLocalListProps
) => {
  const { dasusProgramId } = props
  const { Link } = useRouterContext()
  const { showUrl: generateShowUrl } = useNavigation()

  const { tableProps } = useTable<any, TCommonError, TFilterForm<any>>({
    resource: `${DASUS_PROGRAM_LOCAL_RESOURCE}/${dasusProgramId}/local-programs`,
    dataProviderName: 'lms',
    onSearch: (params) => {
      const filters: CrudFilters = []
      const { status, name } = params
      filters.push(
        {
          field: 'status',
          operator: 'eq',
          value: status,
        },
        {
          field: 'name',
          operator: 'eq',
          value: name,
        },
        {
          field: 't',
          operator: 'eq',
          value: new Date().getTime(),
        }
      )
      return filters
    },
  })

  const getDasusProgramLocalShowURL = (programId: string) => {
    return `${generateShowUrl(
      'dasus-program-local-management',
      programId!
    )}?dasusProgramId=${dasusProgramId}`
  }

  return (
    <>
      <Table {...tableProps} rowKey="id">
        <Table.Column
          dataIndex="id"
          title="ID"
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="name"
          title="Nama Program"
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="isSynced"
          title="isSynced"
          render={(value) => <TextField value={String(value)} />}
        />
        <Table.Column<any>
          title="Actions"
          render={(_, record) => (
            <Space direction="vertical">
              <Link to={getDasusProgramLocalShowURL(record.id)} replace={false}>
                <Button {...props} icon={<Icons.EyeOutlined />}>
                  show
                </Button>
              </Link>
            </Space>
          )}
        />
      </Table>
    </>
  )
}
