import {
  Button,
  Descriptions,
  Divider,
  List,
  Table,
  useModalForm,
} from '@pankod/refine-antd'
import Head from 'next/head'
import { useState } from 'react'

import ModalParseCSV from '@components/ModalParseCSV'
import { TLMSCommonError } from 'src/interfaces/common'
import { downloadTemplate } from '@components/ModalParseCSV/generateCSVTemplate'

type EmailList = {
  fullName: string
  email: string
  userId: string
  loginId: string
}

export const OpsToolkitCSVUploaderList = () => {
  const [emailList, setEmailList] = useState<EmailList[]>([])
  const { modalProps, submit, formProps, show, close } = useModalForm({
    action: 'create',
    resource: `ops-tools/user-encoded-list`,
    dataProviderName: 'lms',
    autoResetForm: true,
    redirect: false,
    onMutationSuccess(data) {
      setEmailList(data.data.data)
    },
    submit: (formValue: any) => {
      return {
        emails: formValue.data.map((item: any) => item.email),
      }
    },
    successNotification: {
      message: 'CSV successfuly validated and processed',
      type: 'success',
      description: 'Successful',
    },
    errorNotification: (error) => {
      return {
        description: 'Error',
        type: 'error',
        message:
          (error as TLMSCommonError).response?.data.error.details?.[0]
            ?.message ||
          (error as TLMSCommonError).response?.data.error.message ||
          'There was a problem when processing CSV',
      }
    },
  })

  const csvHeaders = ['email']

  return (
    <List title="Bulk User CSV Encoded Getter">
      <Head>
        <title>User CSV Encoded ID Getter</title>
      </Head>

      <div className="mt-8 ">
        <div className="flex">
          <ul className="text-left max-w-md">
            <li>Max Email to process: 500 email</li>
            <li>Email must be valid</li>
          </ul>
        </div>

        <Button onClick={show}>
          {emailList.length > 0 ? 'Re-Upload CSV' : 'Open Upload Wizard'}
        </Button>

        <Divider />
        {emailList.length > 0 && (
          <div className="mt-8">
            <h3>Result</h3>

            <Descriptions bordered column={1} size="small" className="mb-4">
              <Descriptions.Item label="Total Email">
                {emailList.length}
              </Descriptions.Item>
            </Descriptions>

            <div className="flex justify-end">
              <Button
                className="mb-4"
                onClick={() => {
                  const csvHeaders = [
                    'Email',
                    'Full Name',
                    'User Id',
                    'Login Id',
                  ]
                  const csvData = emailList.map((item) => [
                    item.email,
                    item.fullName,
                    item.userId,
                    item.loginId,
                  ])
                  const csvString = [csvHeaders, ...csvData]
                    .map((row) => row.join(','))
                    .join('\n')

                  const dateDDMMYYYY = new Date().toLocaleDateString('id-ID', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                  })

                  downloadTemplate(csvString, dateDDMMYYYY, 'user-encoded-id')
                }}
              >
                Download CSV Result
              </Button>
            </div>

            <Table
              dataSource={emailList}
              columns={[
                {
                  title: 'Email',
                  dataIndex: 'email',
                  key: 'email',
                },
                {
                  title: 'Full Name',
                  dataIndex: 'fullName',
                  key: 'fullName',
                },
                {
                  title: 'User ID',
                  dataIndex: 'userId',
                  key: 'userId',
                },
                {
                  title: 'Login ID',
                  dataIndex: 'loginId',
                  key: 'loginId',
                },
              ]}
            />
          </div>
        )}
      </div>

      <ModalParseCSV
        formProps={formProps}
        modalProps={modalProps}
        onSubmit={submit}
        onClose={close}
        validCSVHeaders={csvHeaders}
        title="Encoded ID Getters"
        okButtonText="Validate CSV Data"
        maxEntries={500}
        presetValidate={[
          {
            name: 'email',
            optional: false,
            rules: (value: string) => {
              const emailRegex = /\S+@\S+\.\S+/
              return {
                name: 'email Regex',
                result: emailRegex.test(value),
              }
            },
          },
        ]}
      />
    </List>
  )
}
