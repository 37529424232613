import { Dropdown, Menu, Icons, Button } from '@pankod/refine-antd'
import React, { useState } from 'react'

import { SetupCertificateDrawer } from './SetupCertificateDrawer'
import { TemplateCertificateDrawer } from './TemplateCertificateDrawer'

type ConfigurationButtonProps = {
  programId?: number | string | string[]
  programLocalId?: number | string | string[]
}

const ConfigurationButton = ({
  programId,
  programLocalId,
}: ConfigurationButtonProps) => {
  const [setupCertificateShow, setSetupCertificateShow] = useState(false)
  const [templateCertificateShow, setTemplateCertificateShow] = useState(false)
  return (
    <>
      <Dropdown
        trigger={['click']}
        overlay={
          <Menu
            items={[
              {
                type: 'group',
                key: 'Sertifikat',
                label: 'Sertifikat',
                children: [
                  {
                    icon: <Icons.FileDoneOutlined />,
                    label: 'Pengaturan Sertifikat',
                    key: 'Pengaturan Sertifikat',
                    onClick: () => {
                      setSetupCertificateShow(true)
                    },
                  },
                  // TODO: template certificate toggle on in production
                  ...(process.env.APP_ENV !== 'production'
                    ? [
                        {
                          icon: <Icons.ProfileOutlined />,
                          label: 'Template Sertifikat',
                          key: 'Template Sertifikat',
                          onClick: () => {
                            setTemplateCertificateShow(true)
                          },
                        },
                      ]
                    : []),
                ],
              },
            ]}
          />
        }
      >
        <Button className="mr-5" icon={<Icons.SettingOutlined />}>
          Konfigurasi Program Lokal
        </Button>
      </Dropdown>
      <SetupCertificateDrawer
        programId={programId}
        programLocalId={programLocalId}
        visible={setupCertificateShow}
        onClose={() => setSetupCertificateShow(false)}
      />
      <TemplateCertificateDrawer
        programId={programId}
        programLocalId={programLocalId}
        visible={templateCertificateShow}
        onClose={() => setTemplateCertificateShow(false)}
      />
    </>
  )
}

export default ConfigurationButton
