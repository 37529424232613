import React from 'react'
import { DatePicker } from '@pankod/refine-antd'

import { OutsideClickContext } from 'src/hooks/useOnClickOutside'

type TDatePickerWs = any
const DatePickerWS = (props: TDatePickerWs) => {
  const { setEnabled: setEnableClickOutsideArena } =
    React.useContext(OutsideClickContext)
  return (
    <DatePicker
      {...props}
      onOpenChange={(open) => {
        setEnableClickOutsideArena({ enabled: !open })
      }}
    />
  )
}

export default DatePickerWS
