import { Button, Table, TextField, useTable } from '@pankod/refine-antd'
import { CrudFilters, useCreate } from '@pankod/refine-core'
import React from 'react'

import { TFilterForm } from '@components/ContentFilter'
import { TCommonError } from 'src/interfaces/common'
import { downloadTemplate } from '@components/ModalParseCSV/generateCSVTemplate'

const DASUS_PROGRAM_LOCAL_RESOURCE = 'pgp-dasus'

export type TTableDasusDashboardTabContentProps = {
  dasusProgramId: string | number
  title?: string
}

export const TableDasusDashboardTabContent = (
  props: TTableDasusDashboardTabContentProps
) => {
  const { dasusProgramId, title } = props

  const { tableProps } = useTable<any, TCommonError, TFilterForm<any>>({
    resource: `${DASUS_PROGRAM_LOCAL_RESOURCE}/programs/${dasusProgramId}/attendances`,
    dataProviderName: 'lms',
    onSearch: (params) => {
      const filters: CrudFilters = []
      const { status, name } = params
      filters.push(
        {
          field: 'status',
          operator: 'eq',
          value: status,
        },
        {
          field: 'name',
          operator: 'eq',
          value: name,
        },
        {
          field: 't',
          operator: 'eq',
          value: new Date().getTime(),
        }
      )
      return filters
    },
  })

  const { mutateAsync: doExport } = useCreate()

  // https://api.staging.belajar.id/guru/lms-be/cms/programs/503/sumber-data-dasus-attendance/export
  const handleExportSumberData = async () => {
    return await doExport({
      resource: `programs/${dasusProgramId}/sumber-data-dasus-attendance/export`,
      values: {},
      successNotification: {
        message: 'Sumber Data berhasil diexport',
        type: 'success',
        description: 'Sukses',
      },
    }).then((res) => {
      // @ts-expect-error
      downloadTemplate(res.data, title, '[SUMBER_DATA]')
    })
  }

  // https://api.staging.belajar.id/guru/lms-be/cms/programs/503/rekap-dasus-attendance/export
  const handleExportRekapKehadiran = async () => {
    return await doExport({
      resource: `programs/${dasusProgramId}/rekap-dasus-attendance/export`,
      values: {},
      successNotification: {
        message: 'Rekap Kehadiran berhasil diexport',
        type: 'success',
        description: 'Sukses',
      },
    }).then((res) => {
      // @ts-expect-error
      downloadTemplate(res.data, title, '[REKAP_KEHADIRAN]')
    })
  }

  return (
    <>
      <div className="flex flex-row gap-2 mb-4 justify-end">
        <Button onClick={handleExportSumberData}>Export Sumber Data</Button>
        <Button onClick={handleExportRekapKehadiran}>
          Export Rekap Kehadiran
        </Button>
      </div>

      <Table {...tableProps} rowKey="programLokal">
        <Table.Column
          dataIndex="programLokal"
          title="Program Lokal"
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="peserta"
          title="Peserta"
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="kelas"
          title="Kelas"
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="hadir"
          title="Hadir"
          render={(value) => <TextField value={String(value)} />}
        />
        <Table.Column
          dataIndex="hadirTerlambat"
          title="Hadir Terlambat"
          render={(value) => <TextField value={String(value)} />}
        />
        <Table.Column
          dataIndex="tidakHadir"
          title="Tidak Hadir"
          render={(value) => <TextField value={String(value)} />}
        />
        <Table.Column
          dataIndex="belumDiisi"
          title="Belum Diisi"
          render={(value) => <TextField value={String(value)} />}
        />
      </Table>
    </>
  )
}
