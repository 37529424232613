import {
  Button,
  Drawer,
  Form,
  // Icons,
  Select,
  Tooltip,
  Tree,
  TreeNodeProps,
  useSelect,
} from '@pankod/refine-antd'
import React, { useCallback, useState } from 'react'
import { useCustomMutation } from '@pankod/refine-core'

import { PopDeleteConfirm } from '@components/PopDeleteConfirm'
import {
  handleUncheckPembobotanDependencies,
  handleUncheckModule,
} from './utils'
import { showErrorNotification } from '@resources/angkatan-ppg-management/utils'

export const useProgramCopyConfigDrawer = () => {
  const [showId, setShowId] = useState<{
    show: boolean
    programId: string | number
    programName: string
  }>({ show: false, programId: '', programName: '' })
  const onClose = useCallback(() => {
    setShowId((state) => ({
      ...state,
      show: false,
    }))
  }, [])

  return {
    show: (id: number | string, name: string) => {
      setShowId({ show: true, programId: id, programName: name })
    },
    visible: !!showId.show,
    id: showId.programId,
    name: showId.programName,
    onClose,
  }
}

type ProgramCopyConfigDrawerProps = {
  id: number | string
  name: string
  onClose: () => void
  visible: boolean
}

const ProgramCopyConfigDrawer = ({
  id,
  name,
  onClose,
  visible,
}: ProgramCopyConfigDrawerProps) => {
  const { selectProps } = useSelect({
    resource: 'programs',
    dataProviderName: 'lms',
    optionLabel: 'name',
    optionValue: 'id',
    filters: [
      {
        field: 'pageSize',
        operator: 'eq',
        value: -1,
      },
      {
        field: 'status',
        operator: 'eq',
        value: 'persiapan',
      },
    ],
    queryOptions: {
      enabled: !!visible,
    },
    defaultValueQueryOptions: {
      enabled: false,
    },
  })
  const { mutateAsync } = useCustomMutation()
  const [form] = Form.useForm()
  const listTargetProgramId = Form.useWatch('listTargetProgramId', form)
  const treeListConfigurationType = Form.useWatch(
    'treeListConfigurationType',
    form
  )

  // const shouldDisablePembobotan = [
  //   'PROGRAM_CONFIGURATION_JURNAL_PEMANTAUAN,PROGRAM_CONFIGURATION_PENDAMPINGAN_INDIVIDU,PROGRAM_CONFIGURATION_LOKA_KARYA',
  //   'PROGRAM_CONFIGURATION_PRESENSI',
  // ].some((val) => !treeListConfigurationType?.includes(val))

  const shouldDisableProgramModuleChild = !treeListConfigurationType?.includes(
    'PROGRAM_CONFIGURATION_PROGRAM_MODULE'
  )

  const treeNode: TreeNodeProps[] = [
    {
      title: 'Program Module',
      key: 'PROGRAM_CONFIGURATION_PROGRAM_MODULE',
      children: [
        {
          title: 'Pengajar Praktik (PP)',
          key: 'PROGRAM_CONFIGURATION_JURNAL_PEMANTAUAN,PROGRAM_CONFIGURATION_PENDAMPINGAN_INDIVIDU,PROGRAM_CONFIGURATION_LOKA_KARYA',
          disabled: shouldDisableProgramModuleChild,
        },
        {
          title: 'Presensi (Aktivitas)',
          key: 'PROGRAM_CONFIGURATION_PRESENSI',
          disabled: shouldDisableProgramModuleChild,
        },
        // {
        //   title: (
        //     <>
        //       Pembobotan Nilai PGP
        //       {shouldDisablePembobotan && (
        //         <Tooltip
        //           placement="bottom"
        //           title={
        //             <div>
        //               Konfigurasi harus dipilih:
        //               <ul className="pl-5">
        //                 <li>Pengajar Praktik (PP)</li>
        //                 <li>Presensi (Aktivitas)</li>
        //               </ul>
        //             </div>
        //           }
        //         >
        //           <Icons.InfoCircleOutlined className="ml-2" />
        //         </Tooltip>
        //       )}
        //     </>
        //   ),
        //   key: 'PROGRAM_CONFIGURATION_PEMBOBOTAN_NILAI_PGP',
        //   disabled: shouldDisablePembobotan || shouldDisableProgramModuleChild,
        // },
        {
          title: 'Jurnal Fasilitasi',
          key: 'PROGRAM_CONFIGURATION_JURNAL_FASILITASI',
          disabled: shouldDisableProgramModuleChild,
        },
        {
          title: 'Periode Penilaian',
          key: 'PROGRAM_CONFIGURATION_PERIODE_PENILAIAN',
          disabled: shouldDisableProgramModuleChild,
        },
      ],
    },
    {
      title: 'Penilaian Kinerja',
      key: 'PROGRAM_CONFIGURATION_PENILAIAN_KINERJA',
    },
    {
      title: 'Sertifikat',
      key: 'PROGRAM_CONFIGURATION_SERTIFIKAT',
    },
    {
      title: 'Umpan Balik',
      key: 'PROGRAM_CONFIGURATION_UMPAN_BALIK',
    },
  ]

  const hasEmptyField =
    !listTargetProgramId ||
    !treeListConfigurationType ||
    listTargetProgramId?.length === 0 ||
    treeListConfigurationType?.length === 0

  return (
    <Drawer
      title={
        <>
          Salin Konfigurasi Program <br />| {`${name}`}
        </>
      }
      visible={visible}
      onClose={onClose}
      destroyOnClose
      afterVisibleChange={(visible) => {
        if (!visible) form.resetFields()
      }}
      width="500px"
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="listTargetProgramId"
          label="Target Program"
          required
          rules={[{ required: true, message: 'Target program harus dipilih' }]}
        >
          <Select
            {...selectProps}
            options={selectProps.options?.map(({ label, value }) => ({
              label: `[${value}] ${label}`,
              value,
              disabled: value == id,
            }))}
            mode="multiple"
            filterOption={(input, option) =>
              ((option?.label ?? '') as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            showSearch
            onSearch={() => {}}
          />
        </Form.Item>
        <Form.Item
          name="treeListConfigurationType"
          label="Pilih Konfigurasi"
          required
          rules={[{ required: true, message: 'Konfigurasi harus dipilih' }]}
        >
          <Tree
            checkable
            selectable={false}
            checkStrictly
            defaultExpandAll
            treeData={treeNode.map(
              ({ title, key, children, tooltip, ...rest }) => {
                return {
                  title: !!tooltip ? (
                    <Tooltip placement="left" title={tooltip}>
                      {title}
                    </Tooltip>
                  ) : (
                    title
                  ),
                  key,
                  children,
                  ...rest,
                }
              }
            )}
            checkedKeys={{
              checked: treeListConfigurationType,
              halfChecked: [],
            }}
            onCheck={(value, { halfCheckedKeys, node }) => {
              if (!Array.isArray(value)) {
                handleUncheckPembobotanDependencies(
                  node.key as string,
                  node.checked,
                  value
                )
                handleUncheckModule(node.key as string, node.checked, value)
                form.setFields([
                  {
                    name: 'treeListConfigurationType',
                    value: [...value.checked, ...(halfCheckedKeys || [])],
                  },
                ])
              }

              return value
            }}
          />
        </Form.Item>
      </Form>
      <PopDeleteConfirm
        title="Apakah Anda yakin ingin menyalin konfigurasi program ini ke program tujuan?"
        validateValue={listTargetProgramId?.sort().join(',') || ''}
        validatorMatcher={(inputValue, validateValue) => {
          const inputProgramIds =
            inputValue?.split(',')?.map((value) => value.trim()) || []
          return inputProgramIds?.sort()?.join(',') === validateValue
        }}
        placeholder='Tulis Program ID tujuan pisahkan dengan koma (eg. "101, 103, 75")'
        okText="Konfirmasi"
        cancelText="Batal"
        errorMessage="Program ID tujuan belum sesuai."
        placement="bottom"
        disabled={hasEmptyField}
        onConfirm={async () => {
          await form.validateFields()
          const payload = {
            listTargetProgramId,
            listConfigurationType: treeListConfigurationType.reduce(
              (result: string[], value: string) => {
                return [...result, ...[...value.split(',')]]
              },
              []
            ),
          }
          return await mutateAsync(
            {
              values: payload,
              method: 'post',
              url: `${process.env.NEXT_PUBLIC_LMS_API_URL}/programs/${id}/copy-configuration`,
              dataProviderName: 'lms',
              errorNotification: (err) =>
                showErrorNotification(
                  err,
                  'There was a problem when copying program config'
                ),
              successNotification: {
                message: 'Salin konfigurasi program berhasil',
                type: 'success',
                description: 'Sukses',
              },
            },
            {
              onSuccess: onClose,
            }
          )
        }}
      >
        <Button className="mt-2" disabled={hasEmptyField} type="primary">
          Salin Konfigurasi
        </Button>
      </PopDeleteConfirm>
    </Drawer>
  )
}

export default ProgramCopyConfigDrawer
