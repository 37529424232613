import React from 'react'
import { useNode, Node } from '@craftjs/core'
import clsx from 'clsx'
import { Form, Input, InputNumber, Switch } from '@pankod/refine-antd'
import {
  TextArea as LibraryTextArea,
  TextAreaProps,
} from '@instructure/ui-text-area'
import { Controller } from 'react-hook-form'
import { capitalize } from 'lodash'

import {
  TextAreaCustomProps,
  TTextArea,
  WIDGET_NAME,
} from '@components/FormBuilder/schema'
import {
  mappedWidgetSettingsError,
  widgetSettingsOnValueChange,
  getWidgetSettingsFields,
} from '@components/FormBuilder/utils'
import { useFormContext } from '@components/FormBuilder/formContext'
import ContentForm from '@components/ContentBank/ContentForm'

export const defaultProps: TTextArea = {
  label: 'Textarea label',
  name: 'textareaName',
}

type Message = Exclude<TextAreaProps['messages'], undefined>

const TextArea = ({
  label,
  name,
  helper,
  placeholder,
  maxChar,
  minChar,
}: TTextArea) => {
  const {
    connectors: { connect, drag },
  } = useNode()
  const { control } = useFormContext()

  return (
    <div
      ref={(ref) => connect(drag(ref!))}
      className={clsx('mb-3 last:mb-0', 'widget-component', 'text-default')}
    >
      <Controller
        name={name}
        control={control}
        render={({
          field: { name, onChange, onBlur, value },
          fieldState: { error },
        }) => (
          <>
            <LibraryTextArea
              label={
                <div
                  className="form-label ql-editor"
                  dangerouslySetInnerHTML={{ __html: label }}
                />
              }
              value={value}
              name={name}
              onChange={onChange}
              onBlur={onBlur}
              placeholder={placeholder}
              maxLength={maxChar}
              minLength={minChar}
              messages={
                [
                  {
                    text: error ? capitalize(error?.message) : helper,
                    type: error ? 'error' : 'hint',
                  },
                ] as Message
              }
            />
          </>
        )}
      />
    </div>
  )
}

const TextAreaSettings = () => {
  const {
    actions: { setProp, setCustom },
    props,
    custom,
  } = useNode((node) => ({
    props: node.data.props,
    custom: node.data.custom,
  }))
  const errors = mappedWidgetSettingsError(custom.errors)
  return (
    <Form
      onValuesChange={(changedValue) =>
        widgetSettingsOnValueChange(changedValue, custom, setCustom)
      }
      fields={getWidgetSettingsFields(props)}
      layout="vertical"
    >
      <Form.Item
        label="Name Key"
        name="name"
        validateStatus={errors['name'] && 'error'}
        help={errors['name']}
      >
        <Input
          autoComplete="off"
          value={props.name}
          onChange={(e) =>
            setProp(
              (props: TTextArea) =>
                (props.name = e.target.value.replaceAll(' ', ''))
            )
          }
        />
      </Form.Item>
      <Form.Item
        label="Label"
        name="label"
        validateStatus={errors['label'] && 'error'}
        help={errors['label']}
      >
        <ContentForm
          index="widgetText"
          showDeleteButton={false}
          showIndentation={false}
          showUploader={false}
          showTitle={false}
          value={props.text}
          onChange={(text) =>
            setProp((props: TTextArea) => (props.label = text))
          }
        />
      </Form.Item>
      <Form.Item
        label="Required"
        name="isRequired"
        validateStatus={errors['isRequired'] && 'error'}
        help={errors['isRequired']}
      >
        <Switch
          checked={props.isRequired}
          onChange={(e) =>
            setProp((props: TTextArea) => (props.isRequired = e))
          }
        />
      </Form.Item>
      <Form.Item
        label="Placeholder"
        name="placeholder"
        validateStatus={errors['placeholder'] && 'error'}
        help={errors['placeholder']}
      >
        <Input
          autoComplete="off"
          value={props.placeholder}
          onChange={(e) =>
            setProp((props: TTextArea) => (props.placeholder = e.target.value))
          }
        />
      </Form.Item>
      <Form.Item
        label="Minimum Character"
        name="minChar"
        validateStatus={errors['minChar'] && 'error'}
        help={errors['minChar']}
      >
        <InputNumber
          autoComplete="off"
          value={props.minChar}
          onChange={(e) =>
            setProp(
              (props: TTextArea) =>
                (props.minChar = isNaN(e) ? undefined : Number(e))
            )
          }
        />
      </Form.Item>
      <Form.Item
        label="Maximum Character"
        name="maxChar"
        validateStatus={errors['maxChar'] && 'error'}
        help={errors['maxChar'] || '0 is equal as no limitation'}
      >
        <InputNumber
          autoComplete="off"
          value={props.maxChar}
          onChange={(e) =>
            setProp(
              (props: TTextArea) =>
                (props.maxChar = isNaN(e) ? undefined : Number(e))
            )
          }
        />
      </Form.Item>
      <Form.Item
        label="Helper Text"
        name="helper"
        validateStatus={errors['helper'] && 'error'}
        help={errors['helper']}
      >
        <Input
          autoComplete="off"
          value={props.helper}
          onChange={(e) =>
            setProp((props: TTextArea) => (props.helper = e.target.value))
          }
        />
      </Form.Item>
    </Form>
  )
}

TextArea.craft = {
  rules: {
    canDrop: (targetNode: Node) => {
      return targetNode.data.name === WIDGET_NAME.Container
    },
  },
  props: defaultProps,
  custom: TextAreaCustomProps,
  related: {
    settings: TextAreaSettings,
  },
}

export { TextArea }
