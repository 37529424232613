import { Canvas, loadSVGFromString, FabricImage } from 'fabric'
import { nanoid } from 'nanoid'
import { message, RcFile, Upload, UploadProps } from '@pankod/refine-antd'

import { CANVAS_CONFIG, CONTROL_CONFIG } from '../constants'
import { fixTspanPosSVGObjImport } from './canvas'

type AddImage = (
  props:
    | {
        file: RcFile
        canvas: Canvas | null
        base64String?: never
        customVariableKey?: string
      }
    | {
        file?: never
        canvas: Canvas | null
        base64String: string
        customVariableKey?: string
      }
) => string | null

export const addPlaceholderImage = (props: {
  canvas: Canvas | null
  base64String: string
  customVariableKey?: string
}) => {
  const { base64String, canvas, customVariableKey } = props
  if (!canvas) return null
  const customId = nanoid()
  FabricImage.fromURL(base64String, undefined, {
    customId,
    customVariableKey,
    customObjectType: 'Image',
    snapAngle: CONTROL_CONFIG.snapAngle,
    snapThreshold: CONTROL_CONFIG.snapThreshold,
  }).then((output) => {
    canvas.add(output)
    canvas.setActiveObject(output)
  })
  return customId
}

export const addImage: AddImage = (props) => {
  const { file, canvas, base64String, customVariableKey } = props
  canvas?.discardActiveObject()
  const customId = nanoid()
  if (base64String) {
    FabricImage.fromURL(base64String, undefined, {
      customId,
      snapAngle: CONTROL_CONFIG.snapAngle,
      snapThreshold: CONTROL_CONFIG.snapThreshold,
      customVariableKey,
      customObjectType: 'Image',
    }).then((output) => {
      canvas?.add(output)
      canvas?.setActiveObject(output)
    })
    return customId
  }

  if (!file) return null
  // if file is svg then load it as svg string
  if (file.type === 'image/svg+xml') {
    const reader = new FileReader()
    reader.onloadend = () => {
      loadSVGFromString(reader.result as string, (element, fabricObject) => {
        const variableKey = element.getAttribute('data-variablekey')
        const objectType = element.getAttribute('data-objecttype')
        const objectWidth = element.getAttribute('data-objectwidth')
        if (variableKey) {
          fabricObject.customVariableKey = variableKey
        }
        if (objectWidth) {
          fabricObject.width = +objectWidth
        }
        fabricObject.customObjectType = objectType || undefined
        return fabricObject
      }).then((output) => {
        fixTspanPosSVGObjImport({
          output,
          canvas,
        })
      })
    }
    reader.readAsText(file)
  } else {
    const reader = new FileReader()
    reader.onloadend = () => {
      FabricImage.fromURL(reader.result as string, undefined, {
        customId,
        snapAngle: CONTROL_CONFIG.snapAngle,
        snapThreshold: CONTROL_CONFIG.snapThreshold,
        customVariableKey,
        customObjectType: 'Image',
      }).then((output) => {
        canvas?.add(output)
        canvas?.setActiveObject(output)
      })
    }
    reader.readAsDataURL(file)
  }
  return customId
}

export const generateUploadPropsFn = (props: {
  onLoaded: (result: string | ArrayBuffer | null) => void
  onError?: (type: 'TYPE_INVALID' | 'SIZE_INVALID', message: string) => void
  onRemove?: () => void
}): UploadProps => {
  const MAX_SIZE_IN_MB = CANVAS_CONFIG.imageSizeLimitInMB
  const ALLOWED_TYPE = CANVAS_CONFIG.imageAllowedType
  return {
    beforeUpload: (file) => {
      const isFileSizeValid = file.size / 1024 / 1024 < MAX_SIZE_IN_MB
      const isFileTypeValid = ALLOWED_TYPE.includes(file.type)

      if (!isFileSizeValid) {
        const messageText = 'Ukuran file harus < ' + MAX_SIZE_IN_MB + 'MB'
        message.error(messageText + '!')
        props.onError?.('SIZE_INVALID', messageText + '.')
        return Upload.LIST_IGNORE
      }

      if (!isFileTypeValid) {
        const messageText = 'Tipe file tidak sesuai'
        message.error(messageText + '!')
        props.onError?.('TYPE_INVALID', messageText + '.')
        return Upload.LIST_IGNORE
      }
      const reader = new FileReader()
      reader.onloadend = () => {
        props.onLoaded(reader.result)
      }
      reader.readAsDataURL(file)
      return false
    },
    multiple: false,
    listType: 'picture',
    maxCount: 1,
    onRemove: () => props.onRemove?.(),
  }
}
