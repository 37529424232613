import {
  DatePicker,
  Form,
  FormProps,
  Modal,
  ModalProps,
} from '@pankod/refine-antd'
import React from 'react'
import dayjs from 'dayjs'
import 'dayjs/locale/id'
import locale from 'antd/lib/locale/id_ID'

import { SemesterResponse } from '@resources/angkatan-ppg-management/types'

// const disabledDate = (current: Dayjs, targetDate: Dayjs = dayjs()) =>
//   current.isBefore(targetDate.endOf('day'))

type SemesterUpdateModalFormProps = {
  formProps: FormProps
  modalProps: ModalProps
  onClose: () => void
}
export const SemesterUpdateModalForm = ({
  formProps,
  modalProps,
  onClose,
}: SemesterUpdateModalFormProps) => {
  return (
    <Modal
      {...modalProps}
      onCancel={onClose}
      title={
        'Ubah Jadwal Semester ' + formProps.form?.getFieldValue('semester')
      }
      okText="Simpan"
      cancelText="Kembali"
      width={520}
    >
      <Form<SemesterResponse['jadwal']>
        {...formProps}
        layout="vertical"
        validateMessages={{ required: '${label} harus diisi.' }}
      >
        <Form.Item
          label="Tanggal Mulai Perkuliahan"
          name={['jadwal', 'startDate']}
          getValueProps={(date) => ({
            value: date ? dayjs(date) : null,
          })}
          required
          rules={[{ required: true }]}
          getValueFromEvent={(date) => {
            return date?.toISOString()
          }}
        >
          <DatePicker
            // disabledDate={disabledDate}
            locale={locale.DatePicker}
            format={'DD MMM YYYY'}
            showToday={false}
          />
        </Form.Item>
        <Form.Item<SemesterResponse['jadwal']>
          label="Periode UTS"
          name={['jadwal', 'utsDate']}
          required
          rules={[
            { required: true },
            {
              validator(_rule, value, _callback) {
                if (!value.startDate || !value.endDate) {
                  return Promise.reject(
                    'Tanggal Periode UTS tidak boleh kosong.'
                  )
                }
                return Promise.resolve()
              },
            },
          ]}
          getValueProps={(value: SemesterResponse['jadwal']['utsDate']) => {
            if (!value?.endDate || !value?.endDate)
              return { value: [undefined, undefined] }

            return {
              value: [dayjs(value?.startDate), dayjs(value?.endDate)],
            }
          }}
          getValueFromEvent={([startDate, endDate]) => {
            return {
              startDate: startDate?.toISOString(),
              endDate: endDate?.toISOString(),
            }
          }}
        >
          <DatePicker.RangePicker
            picker="date"
            format="DD MMM YYYY"
            allowEmpty={[false, false]}
            locale={locale.DatePicker}
            // disabledDate={disabledDate}
          />
        </Form.Item>
        <Form.Item<SemesterResponse['jadwal']>
          label="Periode UAS"
          name={['jadwal', 'uasDate']}
          required
          rules={[
            { required: true },

            {
              validator(_rule, value, _callback) {
                if (!value.startDate || !value.endDate) {
                  return Promise.reject(
                    'Tanggal Periode UAS tidak boleh kosong.'
                  )
                }
                return Promise.resolve()
              },
            },
          ]}
          getValueProps={(value: SemesterResponse['jadwal']['uasDate']) => {
            if (!value?.endDate || !value?.endDate)
              return { value: [undefined, undefined] }

            return {
              value: [dayjs(value?.startDate), dayjs(value?.endDate)],
            }
          }}
          getValueFromEvent={([startDate, endDate]) => {
            return {
              startDate: startDate?.toISOString(),
              endDate: endDate?.toISOString(),
            }
          }}
        >
          <DatePicker.RangePicker
            picker="date"
            format="DD MMM YYYY"
            allowEmpty={[false, false]}
            locale={locale.DatePicker}
            // disabledDate={disabledDate}
          />
        </Form.Item>
        <Form.Item<SemesterResponse['jadwal']>
          label="Periode Penilaian Akhir"
          name={['jadwal', 'penilaianAkhirDate']}
          required
          rules={[
            { required: true },
            // {
            //   validator(_, value) {
            //     const semesterStartDate = formProps.form?.getFieldValue([
            //       'jadwal',
            //       'startDate',
            //     ])
            //     if (
            //       semesterStartDate &&
            //       value.startDate &&
            //       dayjs(value.startDate).isBefore(dayjs(semesterStartDate))
            //     ) {
            //       return Promise.reject(
            //         'Harus dilakukan setelah Tanggal Mulai Perkuliahan.'
            //       )
            //     }
            //     return Promise.resolve()
            //   },
            // },
            {
              validator(_rule, value, _callback) {
                if (!value.startDate || !value.endDate) {
                  return Promise.reject(
                    'Tanggal Periode Penilaian tidak boleh kosong.'
                  )
                }
                return Promise.resolve()
              },
            },
          ]}
          getValueProps={(
            value: SemesterResponse['jadwal']['penilaianAkhirDate']
          ) => {
            if (!value?.endDate || !value?.endDate)
              return { value: [undefined, undefined] }

            return {
              value: [dayjs(value?.startDate), dayjs(value?.endDate)],
            }
          }}
          getValueFromEvent={([startDate, endDate]) => {
            return {
              startDate: startDate?.toISOString(),
              endDate: endDate?.toISOString(),
            }
          }}
          dependencies={['jadwal', 'startDate']}
        >
          <DatePicker.RangePicker
            picker="date"
            format="DD MMM YYYY"
            allowEmpty={[false, false]}
            locale={locale.DatePicker}
            // disabledDate={(current) => {
            //   const semesterStartDate = formProps.form?.getFieldValue([
            //     'jadwal',
            //     'startDate',
            //   ])

            //   if (semesterStartDate)
            //     return disabledDate(current, dayjs(semesterStartDate))
            //   return disabledDate(current)
            // }}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}
