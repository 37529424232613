import { AntdBreadcrumb, Icons } from '@pankod/refine-antd'
import { BreadcrumbsType, useRouterContext } from '@pankod/refine-core'
import React from 'react'
export const RombelBreadcrumb = ({ items }: { items: BreadcrumbsType[] }) => {
  const { Link } = useRouterContext()
  return (
    <AntdBreadcrumb>
      <AntdBreadcrumb.Item>
        <Link to="/">
          <Icons.HomeOutlined />
        </Link>
      </AntdBreadcrumb.Item>
      {items.map(({ label, href, icon }) => {
        return (
          <AntdBreadcrumb.Item key={label}>
            {icon}
            {href ? <Link to={href}>{label}</Link> : label}
          </AntdBreadcrumb.Item>
        )
      })}
    </AntdBreadcrumb>
  )
}
