import {
  Button,
  Divider,
  Drawer,
  EditButton,
  Form,
  Icons,
  Input,
  Select,
  Space,
  Spin,
  Table,
  Tabs,
  TextField,
  useTable,
} from '@pankod/refine-antd'
import React from 'react'
import { useUpdate } from '@pankod/refine-core'

import { Editor } from '@components/TUIEditor'

export type DrawerFormPenilaianDiriProps = {
  visible: boolean
  drawerProps?: any
  onClose: () => void
  programId: string
}

export const DrawerFormPenilaianDiri = (
  props: DrawerFormPenilaianDiriProps
) => {
  const { visible, onClose, programId } = props
  const [formCriteria] = Form.useForm()
  const [PenilaianDiriTempData, setPenilaianDiriTempData] = React.useState<any>(
    {}
  )

  const [formRubrik] = Form.useForm()

  const [pertanyaanList, setPertanyaanList] = React.useState<any[]>([])
  const [hasMutated, setHasMutated] = React.useState(false)

  const [criteriaFormState, setCriteriaFormState] = React.useState({
    mode: 'create',
    visible: false,
    defaultData: null,
  })

  const { tableProps, tableQueryResult } = useTable({
    resource: `pgp-dasus/programs/${programId}/ptm/penilaian_diri`,
    dataProviderName: 'lms',
    queryOptions: {
      enabled: !!visible,
      onSuccess(data) {
        setPenilaianDiriTempData(data)
      },
    },
  })

  const { mutateAsync: updatePenilaianDiri } = useUpdate()

  const handleEditCriteria = async (defaultData: any) => {
    setTimeout(() => {
      defaultData.questions = defaultData.questions.filter(
        (item: any) => !!item
      )

      formCriteria.resetFields()

      setPertanyaanList(
        defaultData?.questions?.map((_: any, index: number) => index) ?? []
      )
    }, 0)

    setCriteriaFormState({
      mode: 'edit',
      visible: true,
      defaultData,
    })
  }

  const handleCloseCriteriaForm = () => {
    formCriteria.resetFields()
    setPertanyaanList([])
    setCriteriaFormState({
      mode: 'create',
      visible: false,
      defaultData: null,
    })
  }

  const handleCreateCriteria = () => {
    setTimeout(() => {
      formCriteria.resetFields()
    }, 0)
    setCriteriaFormState({
      mode: 'create',
      visible: true,
      defaultData: null,
    })
  }

  const handleAddPertanyaanList = () => {
    const newList = [...pertanyaanList]
    newList.push(newList.length)

    setPertanyaanList(newList)
    setHasMutated(true)
  }

  const handleRemovePertanyaanList = (index: number) => {
    const newList = pertanyaanList.filter((item) => item !== index)
    setPertanyaanList(newList)
  }

  const handleDeleteCriteria = async (id: string) => {
    // delete from PenilaianDiriTempData

    const newData = PenilaianDiriTempData.criterias.filter((item: any) => {
      return item.id !== id
    })

    await applyAndRefetch({
      ...PenilaianDiriTempData,
      criterias: newData,
    })
  }

  const handleUpdateCriteria = async (data: any) => {
    const criteriaId = data.id
    data.questions = data.questions.filter((item: any) => !!item)

    const newData = PenilaianDiriTempData.criterias.map((item: any) => {
      if (!item.id && item.title === data.title) {
        return data
      }

      if (!!item.id && item.id === criteriaId) {
        return data
      }
      return item
    })

    await applyAndRefetch({
      ...PenilaianDiriTempData,
      criterias: newData,
    })
  }

  const handleAddCriteria = async (data: any) => {
    // add to PenilaianDiriTempData
    const newData = PenilaianDiriTempData.criterias.concat(data)
    setPenilaianDiriTempData({
      ...PenilaianDiriTempData,
      criterias: newData,
    })

    await applyAndRefetch({
      ...PenilaianDiriTempData,
      criterias: newData,
    })
  }

  const handleUpdateRubrics = async (data: any) => {
    // update from PenilaianDiriTempData

    await applyAndRefetch({
      ...PenilaianDiriTempData,
      rubrics: data.rubrics,
      instruction: data.instruction,
    })
  }

  const applyAndRefetch = async (data: any) => {
    await handleImmediateUpdateForm(data)
    handleCloseCriteriaForm()
  }

  const handleImmediateUpdateForm = (payload: any) => {
    delete payload.total
    updatePenilaianDiri({
      resource: `pgp-dasus/programs/${programId}/ptm`,
      id: 'penilaian_diri',
      values: payload,
      dataProviderName: 'lms',
      successNotification: (e: any) => {
        return {
          message: 'Success',
          description: e.data.message,
          type: 'success',
        }
      },
      errorNotification: (errorRes: any) => {
        const responseErr = errorRes?.response?.data?.error?.message || ''

        return {
          message: responseErr,
          description: 'Failed',
          type: 'error',
        }
      },
    }).then(async () => {
      await tableQueryResult.refetch()
      setHasMutated(false)
    })
  }

  const OPTIONS_CHOICES_TYPE = ['checkbox', 'multiple_choices']

  const optionsSelectionForm = Form.useWatch('questions', formCriteria)

  const getOptionsType = (index: number) => {
    if (optionsSelectionForm) {
      return optionsSelectionForm[index]?.type
    }
    return 'multiple_choices'
  }

  const isOptionsTypeChoices = (index: number) => {
    return OPTIONS_CHOICES_TYPE.includes(getOptionsType(index))
  }

  return (
    <Drawer
      visible={visible}
      closable={false}
      width="50%"
      className="relative"
      destroyOnClose
    >
      <div className="flex justify-end items-end">
        <Button
          size="small"
          icon={<Icons.CloseOutlined />}
          onClick={() => {
            if (hasMutated) {
              window.confirm(
                'Are you sure you want to close this form? Your changes will be lost'
              ) && onClose()
            } else {
              onClose()
            }
          }}
        />
      </div>
      <Tabs defaultActiveKey="criteria" destroyInactiveTabPane>
        <Tabs.TabPane tab="Penilaian Diri" key="criteria">
          <div>
            {criteriaFormState.visible ? (
              <Spin spinning={!!tableProps.loading}>
                <div className="flex justify-between items-center mt-2">
                  <h3>
                    {criteriaFormState.mode.toUpperCase()} Form Penilaian Diri
                  </h3>

                  <Button
                    onClick={handleCloseCriteriaForm}
                    icon={<Icons.CloseOutlined />}
                  />
                </div>

                <div className="max-h-[65vh] overflow-y-auto p-2 mt-4">
                  <Form
                    form={formCriteria}
                    layout="horizontal"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 15 }}
                    size="small"
                    initialValues={criteriaFormState.defaultData || {}}
                    onFinish={(values: any) => {
                      if (criteriaFormState.mode === 'edit') {
                        handleUpdateCriteria(values)
                      } else {
                        handleAddCriteria(values)
                      }
                    }}
                    validateTrigger="onChange"
                  >
                    {/* hidden id */}
                    <Form.Item name="id" hidden>
                      <Input />
                    </Form.Item>

                    <Form.Item name="scorings" hidden>
                      <Input />
                    </Form.Item>

                    <Form.Item
                      name="title"
                      label="Criteria / Title Name"
                      rules={[{ required: true, message: 'Title is required' }]}
                    >
                      <Input />
                    </Form.Item>

                    <Button
                      className="my-4"
                      block
                      onClick={() => handleAddPertanyaanList()}
                    >
                      Tambah Pertanyaan
                    </Button>

                    {pertanyaanList.map((pertanyaanIndex) => (
                      <div
                        key={`pertanyaan-${pertanyaanIndex}`}
                        className="flex flex-col w-full p-2 border rounded-md mt-4"
                      >
                        <div className="flex justify-end mb-4">
                          <Button
                            type="text"
                            icon={<Icons.CloseOutlined />}
                            onClick={() =>
                              handleRemovePertanyaanList(pertanyaanIndex)
                            }
                          />
                        </div>

                        <Input.Group>
                          <Form.Item
                            name={['questions', pertanyaanIndex, 'content']}
                            label="Content"
                            rules={[
                              {
                                required: true,
                                message: 'Title is required',
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>

                          <Form.Item
                            name={['questions', pertanyaanIndex, 'type']}
                            label="Tipe"
                            rules={[
                              {
                                required: true,
                              },
                            ]}
                          >
                            <Select
                              options={[
                                {
                                  label: 'Multiple Choice',
                                  value: 'multiple_choices',
                                },
                                {
                                  label: 'Checkbox',
                                  value: 'checkbox',
                                },
                                {
                                  label: 'Text Area',
                                  value: 'text_area',
                                },
                                {
                                  label: 'Input Text',
                                  value: 'text',
                                },
                              ]}
                            />
                          </Form.Item>
                        </Input.Group>

                        {isOptionsTypeChoices(pertanyaanIndex) && (
                          <Form.List
                            name={['questions', pertanyaanIndex, 'choices']}
                            key={`questions-${pertanyaanIndex}`}
                          >
                            {(fields, { add, remove }) => (
                              <>
                                <div className="flex justify-end mr-[18px]">
                                  <Button
                                    size="small"
                                    onClick={() => {
                                      add()
                                    }}
                                  >
                                    Tambah Opsi Jawabaset n
                                  </Button>
                                </div>

                                <Divider />

                                <div className="overflow-y-auto max-h-[30vw]">
                                  {fields.map((field, index) => {
                                    return (
                                      <div
                                        key={`field-list-scoring-${index}`}
                                        className="flex flex-row items-center w-full"
                                      >
                                        <div className="flex w-full flex-col">
                                          <Form.Item
                                            {...field}
                                            label="Text jawaban"
                                            className="w-full !mb-2"
                                            name={[field.name]}
                                            key={`${field.key}-text-${index}-value`}
                                            rules={[
                                              {
                                                required: true,
                                                message: 'text is required',
                                              },
                                            ]}
                                          >
                                            <Input />
                                          </Form.Item>
                                        </div>

                                        <Button
                                          danger
                                          onClick={() => remove(field.name)}
                                          icon={<Icons.DeleteOutlined />}
                                        />
                                      </div>
                                    )
                                  })}
                                </div>
                              </>
                            )}
                          </Form.List>
                        )}
                      </div>
                    ))}

                    <div className="absolute bottom-[-40px] w-full">
                      <Button htmlType="submit" block>
                        Apply Criteria Changes
                      </Button>
                    </div>
                  </Form>
                </div>
              </Spin>
            ) : (
              <>
                <div className="flex flex-end justify-end mb-4">
                  <Button onClick={handleCreateCriteria}>
                    Tambah Kriteria
                  </Button>
                </div>
                <Table
                  dataSource={PenilaianDiriTempData?.criterias}
                  rowKey="id"
                  className="w-full"
                >
                  <Table.Column
                    dataIndex="title"
                    title="title"
                    render={(value) => <TextField value={value} />}
                  />
                  <Table.Column
                    title="Actions"
                    render={(_, record: any) => (
                      <Space>
                        <EditButton
                          size="small"
                          recordItemId={record.id}
                          onClick={() => {
                            handleEditCriteria(record)
                          }}
                        />
                        <Button
                          size="small"
                          danger
                          onClick={() => handleDeleteCriteria(record.id)}
                          icon={<Icons.DeleteOutlined />}
                        />
                      </Space>
                    )}
                  />
                </Table>
              </>
            )}
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Rubrik" key="rubrik">
          <h3 className="mb-8">Form Rubrik</h3>
          <div className="max-h-[65vh] overflow-y-auto p-2 mt-4">
            <Form
              form={formRubrik}
              layout="vertical"
              initialValues={PenilaianDiriTempData || {}}
              onFinish={(values: any) => {
                handleUpdateRubrics(values)
              }}
            >
              <Form.Item
                name="rubrics"
                label="Rubrik"
                rules={[{ required: true, message: 'Title is required' }]}
              >
                <Editor minHeight="200" toolbarImageUploader={false} />
              </Form.Item>

              <Form.Item
                name="instruction"
                label="Instruction"
                rules={[{ required: true, message: 'Title is required' }]}
              >
                <Editor minHeight="200" toolbarImageUploader={false} />
              </Form.Item>

              <Button htmlType="submit" block>
                Apply Changes
              </Button>
            </Form>
          </div>
        </Tabs.TabPane>
      </Tabs>
    </Drawer>
  )
}
