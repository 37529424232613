import { useResource, HttpError } from '@pankod/refine-core'
import React, { useCallback, useState } from 'react'
import {
  Button,
  DateField,
  Drawer,
  Icons,
  Space,
  Table,
  TextField,
} from '@pankod/refine-antd'

import useTableWithMeta from 'src/hooks/useTableWithMeta'
import { PresensiStatistic } from '../../../types'
import PesertaDrawer, { usePesertaDrawer } from './PesertaDrawer'

export const useKelasDrawer = () => {
  const [showId, setShowId] = useState<{
    show: boolean
    aktivitasId: string | number
    aktivitasName: string
  }>({ show: false, aktivitasId: '', aktivitasName: '' })
  const onClose = useCallback(() => {
    setShowId((state) => ({
      ...state,
      show: false,
    }))
  }, [])

  return {
    show: (id: number | string, aktivitasName: string) => {
      setShowId({ show: true, aktivitasId: id, aktivitasName })
    },
    visible: !!showId.show,
    id: showId.aktivitasId,
    aktivitasName: showId.aktivitasName,
    onClose,
  }
}

type KelasDrawerProps = {
  visible: boolean
  id: number | string
  onClose: () => void
  aktivitasName: string
  moduleId: number | string
}

const KelasDrawer = ({
  aktivitasName,
  id,
  moduleId,
  onClose,
  visible,
}: KelasDrawerProps) => {
  const { id: programId } = useResource()
  const { tableProps, tableQueryResult } = useTableWithMeta<
    PresensiStatistic,
    HttpError,
    unknown,
    { lastUpdatedAt: string }
  >({
    dataProviderName: 'lms',
    resource: `programs/${programId}/presensi-murid/program-modules/${moduleId}/aktivitas/${id}/kelas`,
    queryOptions: {
      enabled: !!programId && !!id,
    },
  })
  const { show, ...pesertaDrawerProps } = usePesertaDrawer()
  const handleClose = () => {
    onClose()
  }
  const lastUpdatedAt = tableQueryResult?.data?.meta?.lastUpdatedAt

  return (
    <Drawer
      title={`Daftar Kelas | Aktivitas: ${aktivitasName}`}
      visible={visible}
      onClose={handleClose}
      width={'70%'}
      destroyOnClose
    >
      <Table
        {...tableProps}
        rowKey="id"
        bordered
        footer={() =>
          lastUpdatedAt && (
            <TextField
              italic
              value={
                <div className="flex gap-2 items-center">
                  Diperbarui pada:
                  <DateField
                    value={lastUpdatedAt}
                    format="DD MMM YYYY HH:mm:ss"
                  />
                </div>
              }
            />
          )
        }
        columns={[
          { title: 'Nama Kelas', dataIndex: 'nama' },
          {
            title: 'Statistik',
            children: [
              {
                title: (
                  <Space>
                    <Icons.CheckCircleOutlined />
                    <TextField value="Hadir" />
                  </Space>
                ),
                dataIndex: ['statistik', 'hadir'],
                align: 'center',
              },
              {
                title: (
                  <Space>
                    <Icons.ClockCircleOutlined />
                    <TextField value="Terlambat" />
                  </Space>
                ),
                dataIndex: ['statistik', 'terlambat'],
                align: 'center',
              },
              {
                title: (
                  <Space>
                    <Icons.CloseCircleOutlined />
                    <TextField value="Absen" />
                  </Space>
                ),
                dataIndex: ['statistik', 'alpha'],
                align: 'center',
              },
              {
                title: (
                  <Space>
                    <Icons.WarningOutlined />
                    <TextField value="Belum Diisi" />
                  </Space>
                ),
                dataIndex: ['statistik', 'kosong'],
                align: 'center',
              },
            ],
          },
          {
            title: 'Laporan Peserta',
            align: 'center',
            render: (_, record) => (
              <Button
                icon={<Icons.UnorderedListOutlined />}
                onClick={() => {
                  show(record.id, record.nama)
                }}
              >
                Lihat Daftar Peserta
              </Button>
            ),
          },
        ]}
      />
      <PesertaDrawer
        {...pesertaDrawerProps}
        aktivitasId={id}
        moduleId={moduleId}
        key={pesertaDrawerProps.id}
      />
    </Drawer>
  )
}

export default KelasDrawer
