import {
  Form,
  Input,
  Table,
  TableColumnGroupType,
  TableColumnType,
  TextField,
  useTable,
} from '@pankod/refine-antd'
import { CrudFilters } from '@pankod/refine-core'
import React from 'react'
import dayjs from 'dayjs'

import { TLMSClass } from '@resources/lms-management/class/types'

type ClassSelectProps = {
  programId?: string
  localProgramId?: string
  classId?: string
  selected: { id?: string; name?: string }
  onSelect: (data: { id: string; name: string }) => void
}
const ClassSelect = ({
  programId,
  localProgramId,
  onSelect,
  selected,
  classId,
}: ClassSelectProps) => {
  const { searchFormProps, tableProps, tableQueryResult } = useTable<TLMSClass>(
    {
      resource: `programs/${programId}/local-programs/${localProgramId}/classes`,
      dataProviderName: 'lms',
      queryOptions: {
        enabled: !!programId,
      },
      onSearch(data: any) {
        const filters: CrudFilters = []
        filters.push(
          {
            field: 'name',
            operator: 'eq',
            value: data.query,
          },
          {
            field: 't',
            operator: 'eq',
            value: dayjs().unix(),
          }
        )
        return filters
      },
    }
  )

  const columns: (TableColumnType<any> | TableColumnGroupType<any>)[] = [
    {
      title: 'ID',
      key: 'id',
      dataIndex: 'id',
      width: 80,
      render: (value) => (
        <TextField
          value={value}
          type={String(value) === classId ? 'secondary' : undefined}
        />
      ),
    },
    {
      title: 'Nama Kelas',
      key: 'name',
      dataIndex: 'name',
      render: (value, record) => (
        <TextField
          value={value}
          type={String(record.id) === classId ? 'secondary' : undefined}
        />
      ),
    },
  ]

  return (
    <>
      <Form {...searchFormProps}>
        <Form.Item name="query">
          <Input.Search
            style={{ width: '30%', minWidth: '220px' }}
            allowClear
            autoComplete="off"
            loading={tableQueryResult.isFetching}
            onSearch={() => searchFormProps?.form?.submit()}
            placeholder="Nama kelas"
          />
        </Form.Item>
      </Form>
      <Table
        {...tableProps}
        rowSelection={{
          type: 'radio',
          onChange: (_, record) => {
            onSelect({ id: String(record[0].id), name: record[0].name })
          },
          selectedRowKeys: selected.id ? [selected.id] : undefined,
          columnTitle: 'Pilih',
          columnWidth: 45,
          getCheckboxProps(record) {
            return {
              name: record.name,
              disabled: String(record.id) === classId,
            }
          },
        }}
        rowKey="id"
        columns={columns}
        scroll={{ y: 400 }}
      />
    </>
  )
}

export default ClassSelect
