import {
  Button,
  EditButton,
  Form,
  Icons,
  Input,
  // Icons,
  Space,
  Table,
  TextField,
  useModalForm,
  useTable,
} from '@pankod/refine-antd'
import { useInvalidate, useList } from '@pankod/refine-core'
import { useEffect, useMemo } from 'react'

import { ModalFormAdminKelas } from './ModalFormAdminKelas'
import ModalParseCSV from '@components/ModalParseCSV'
import { showErrorNotification } from '@resources/angkatan-ppg-management/utils'
// import { PopDeleteConfirm } from '@resources/lms-management/components/PopDeleteConfirm'

type AdminKelasListProps = {
  lptkId: string | number
  isMasterData?: boolean
  enableFilter?: boolean
}

export const AdminKelasList = (props: AdminKelasListProps) => {
  const { lptkId, isMasterData, enableFilter } = props

  const { tableProps, setFilters } = useTable({
    syncWithLocation: true,
    resource: `lptk/${lptkId}/class-admin`,
    dataProviderName: 'lms',
  })

  const invalidate = useInvalidate()

  useEffect(() => {
    setFilters([
      {
        field: 'email',
        operator: 'eq',
        value: '',
      },
    ])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const {
    modalProps: createModalProps,
    formProps: createFormProps,
    submit: createSubmit,
    show: createShow,
  } = useModalForm({
    action: 'create',
    resource: `lt/lptk/${lptkId}/class-admin`,
    submit: (formValue) => {
      return {
        classAdmin: [formValue],
      }
    },

    errorNotification: (err) =>
      showErrorNotification(err, 'There was an error when adding admin kelas'),

    onMutationSuccess: () => {
      invalidate({
        resource: `lptk/${lptkId}/class-admin`,
        dataProviderName: 'lms',
        invalidates: ['list'],
      })
    },
    dataProviderName: 'lms',
    autoResetForm: true,
    redirect: false,
  })

  const {
    modalProps: editModalProps,
    formProps: editFormProps,
    show: editShow,
  } = useModalForm({
    action: 'edit',
    resource: `lptk/${lptkId}/class-admin`,
    dataProviderName: 'lms',
    autoResetForm: true,

    errorNotification: (err) =>
      showErrorNotification(
        err,
        'There was an error when updating class admin'
      ),
    onMutationSuccess: () => {
      invalidate({
        resource: `lptk/${lptkId}/class-admin`,
        dataProviderName: 'lms',
        invalidates: ['list'],
      })
    },
    redirect: false,
  })

  const {
    modalProps: bulkCreateModalProps,
    formProps: bulkCreateFormProps,
    submit: bulkCreateSubmit,
    show: bulkCreateShow,
    close: bulkCreateClose,
  } = useModalForm({
    action: 'create',
    resource: `lt/lptk/${lptkId}/class-admin`,
    submit: (formValue) => {
      return {
        classAdmin: formValue.data,
      }
    },

    errorNotification: (err) =>
      showErrorNotification(err, 'There was an error when adding Admin Kelas'),
    onMutationSuccess: () => {
      invalidate({
        resource: `lptk/${lptkId}/class-admin`,
        dataProviderName: 'lms',
        invalidates: ['list'],
      })
    },
    dataProviderName: 'lms',
    autoResetForm: true,
    redirect: false,
  })

  const { data: studyFieldList } = useList({
    resource: 'kv?key=lms_ppg_study_field',
    dataProviderName: 'microlearning',
    queryOptions: {
      enabled: !!bulkCreateModalProps.visible,
    },
  })

  const studyFieldMap = useMemo(() => {
    const { data } = studyFieldList || {}
    const studyFieldMap = new Map()

    data?.forEach((item: any) => {
      studyFieldMap.set(item.label, item.value)
    })

    return studyFieldMap
  }, [studyFieldList])

  return (
    <>
      <div title={'List Admin Kelas'}>
        <div className="flex flex-row w-full justify-between items-center gap-2 mb-4">
          <div>
            <div>
              {enableFilter && (
                <Form layout="inline">
                  <Form.Item name="email">
                    <Input
                      prefix={<Icons.SearchOutlined className="mr-4" />}
                      onPressEnter={(e) => {
                        setFilters([
                          {
                            field: 'email',
                            operator: 'eq',
                            // @ts-ignore
                            value: e.target.value,
                          },
                        ])
                      }}
                      placeholder="Cari nama atau email"
                      autoComplete="off"
                      allowClear
                    />
                  </Form.Item>
                </Form>
              )}
            </div>
          </div>
          <div>
            <Button onClick={bulkCreateShow}>Unggah CSV</Button>
            <Button onClick={createShow}>Tambah Admin Kelas</Button>
          </div>
        </div>
        <Table
          {...tableProps}
          rowKey="id"
          pagination={{
            ...tableProps.pagination,
            showSizeChanger: true,
          }}
        >
          <Table.Column
            dataIndex="id"
            title="ID"
            render={(value) => <TextField value={value} />}
          />
          <Table.Column
            dataIndex="name"
            title="Nama"
            render={(value) => <TextField value={value} />}
          />
          <Table.Column
            dataIndex="email"
            title="Email"
            render={(value) => <TextField value={value} />}
          />
          {!isMasterData && (
            <Table.Column
              dataIndex="encodedId"
              title="encoded ID"
              render={(value) => <TextField value={value} />}
            />
          )}
          <Table.Column
            dataIndex="phone"
            title="No. Hp"
            render={(value) => <TextField value={value} />}
          />
          <Table.Column
            dataIndex="studyField"
            title="Bidang Studi"
            render={(value) => <TextField value={value} />}
          />

          <Table.Column
            title="Actions"
            render={(_, record: any) => (
              <Space direction="vertical">
                <EditButton
                  size="small"
                  block
                  onClick={() => editShow(record.id)}
                />
                {/* <PopDeleteConfirm
                  title={`Are you sure want to delete Admin Kelas "${record.name}"?`}
                  placeholder="Tulis ulang nama Admin Kelas"
                  onConfirm={() =>
                    mutateAsync({
                      id: record.id,
                      resource: `lptk/${lptkId}/class-admin`,
                      dataProviderName: 'lms',
                    })
                  }
                  okButtonProps={{
                    danger: true,
                    type: 'default',
                  }}
                  okText="Delete"
                  validateValue={record.name}
                  errorMessage="Admin Kelas name did not match"
                >
                  <Button
                    size="small"
                    danger
                    block
                    icon={<Icons.DeleteOutlined />}
                  >
                    Delete
                  </Button>
                </PopDeleteConfirm> */}
              </Space>
            )}
          />
        </Table>
      </div>
      <ModalFormAdminKelas
        modalProps={createModalProps}
        formProps={createFormProps}
        mode="create"
        onSubmit={createSubmit}
      />

      <ModalFormAdminKelas
        modalProps={editModalProps}
        formProps={editFormProps}
        mode="edit"
      />

      <ModalParseCSV
        modalProps={bulkCreateModalProps}
        formProps={bulkCreateFormProps}
        onClose={bulkCreateClose}
        onSubmit={bulkCreateSubmit}
        validCSVHeaders={['name', 'email', 'phone', 'studyField']}
        dataIndex="classAdmin"
        presetValidate={[
          {
            name: 'studyField',
            mapEntry: studyFieldMap,
          },
        ]}
      />
    </>
  )
}
