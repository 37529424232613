import { AntdLayout, Icons, Result, Skeleton, Space } from '@pankod/refine-antd'
import React from 'react'

import { Title } from '@components/layout/title'

const DashboardLoader = () => {
  return (
    <AntdLayout style={{ minHeight: '100vh', flexDirection: 'row' }}>
      <AntdLayout.Sider width={250}>
        <Title collapsed={false} />
      </AntdLayout.Sider>
      <AntdLayout>
        <AntdLayout.Header className="text-right !px-6">
          <Space>
            <Skeleton.Avatar size="large" className="mt-3" active />
          </Space>
        </AntdLayout.Header>
        <AntdLayout.Content>
          <Result
            className="mt-6"
            icon={<Icons.SmileTwoTone className="animate-pulse" />}
            title={<div>Loading..</div>}
          />
        </AntdLayout.Content>
      </AntdLayout>
    </AntdLayout>
  )
}

export default DashboardLoader
