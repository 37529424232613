import { Canvas, FabricText } from 'fabric'
import { nanoid } from 'nanoid'

import { CONTROL_CONFIG } from '../constants'

export const addText = (props: {
  canvas: Canvas | null
  value: string
  customVariableKey?: string
}) => {
  if (!props.canvas) return null
  let isCustomFontAvailable = false
  try {
    isCustomFontAvailable = document?.fonts.check('italic bold 16px Inter')
  } catch (e) {
    console.error('Load Font Error: ', e)
  }
  const { canvas } = props
  canvas.discardActiveObject()
  const customId = nanoid()
  const text = new FabricText(props.value, {
    snapAngle: CONTROL_CONFIG.snapAngle,
    snapThreshold: CONTROL_CONFIG.snapThreshold,
    editable: true,
    width: 200,
    textAlign: 'left',
    customId,
    customVariableKey: props.customVariableKey,
    customObjectType: 'Text',
    fontSize: 20,
    left: 100,
    top: 80,
    ...(isCustomFontAvailable ? { fontFamily: 'Inter' } : {}),
  })
  text.setControlsVisibility({
    mt: false,
    mb: false,
    ml: false,
    mr: false,
  })
  canvas?.add(text)
  canvas?.bringObjectToFront(text)
  canvas?.setActiveObject(text)
  return customId
}
