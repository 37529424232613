import {
  Heading,
  Text,
  defaultHeadingProps,
  defaultTextProps,
} from '@components/FormBuilder/widgets'
import { WIDGET_NAME } from '@components/FormBuilder/schema'
import { TGenerateToolboxButtonsParams } from './utils'

const generalWidgets: TGenerateToolboxButtonsParams['widget'][] = [
  {
    name: WIDGET_NAME.Heading,
    widget: Heading,
    defaultProps: defaultHeadingProps,
    canBePlacedOnRoot: false,
  },
  {
    name: WIDGET_NAME.Text,
    widget: Text,
    defaultProps: defaultTextProps,
    canBePlacedOnRoot: false,
  },
]

export default generalWidgets
