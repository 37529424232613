import urlRegex from 'url-regex'

export const url = (url: string, allowEmpty: boolean = true) => {
  if (allowEmpty && (!url || url === '')) return Promise.resolve()

  const isValid = urlRegex({ exact: true, strict: false }).test(url)
  if (isValid) return Promise.resolve()
  return Promise.reject()
}

export const isHtmlTagEmpty = (htmlString: string) => {
  const test = document.createElement('div')
  test.innerHTML = htmlString
  return (
    test.innerText.trim() === '' &&
    test.getElementsByTagName('img').length === 0
  )
}

export const phone = (phone: string, allowEmpty: boolean = true) => {
  if (allowEmpty && (!phone || phone === '')) return Promise.resolve()

  const regex = new RegExp(
    /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,8}$/,
    'g'
  )
  const isValid = regex.test(phone)
  if (isValid) return Promise.resolve()
  return Promise.reject()
}
