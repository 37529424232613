import {
  DatePicker,
  Form,
  FormProps,
  Input,
  Modal,
  ModalProps,
  Select,
  SelectProps,
} from '@pankod/refine-antd'
import React from 'react'
import dayjs, { Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import locale from 'antd/lib/locale/id_ID'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Jakarta')
import { FormSelectComponent } from '../FormSelectComponent'

type ModalFormProps = {
  modalProps: ModalProps
  formProps: FormProps
  mode: 'create' | 'edit'
  onClose: () => void
}
const ModalForm = ({
  modalProps,
  formProps,
  mode,
  onClose,
}: ModalFormProps) => {
  const isEditMode = mode === 'edit'
  const evaluatorRole: SelectProps['options'] = [
    {
      label: 'Admin Kelas (Satker/UPT)',
      value: 'admin_kelas',
    },
    {
      label: 'Fasilitator',
      value: 'instruktur',
    },
    {
      label: 'Murid (CGP)',
      value: 'peserta',
    },
    {
      label: 'Pengajar Praktik',
      value: 'asisten',
    },
  ]
  return (
    <Modal
      {...modalProps}
      onCancel={onClose}
      title={isEditMode ? 'Edit Umpan Balik' : 'Buat Umpan Balik'}
      cancelText="Batal"
      okText="Simpan"
      afterClose={() => formProps.form?.resetFields()}
      bodyStyle={{ maxHeight: 700, overflow: 'auto' }}
    >
      <Form
        {...formProps}
        initialValues={{
          ...formProps?.initialValues?.data,
          penilaian_period: [
            formProps?.initialValues?.data?.startDate,
            formProps?.initialValues?.data?.endDate,
          ],
        }}
        layout="vertical"
        validateMessages={{ required: '${label} harus diisi.' }}
        onFinish={({ penilaian_period, form, ...data }) => {
          formProps.onFinish?.({
            ...data,
            startDate: penilaian_period[0],
            endDate: penilaian_period[1],
            formId: form.id,
          })
        }}
      >
        <Form.Item
          label="Nama Umpan Balik"
          name="name"
          required
          rules={[{ required: true }]}
        >
          <Input autoComplete="off" />
        </Form.Item>
        <Form.Item
          label="Aktor yang Mengisi"
          name="evaluatorRoles"
          required
          rules={[{ required: true }]}
        >
          <Select options={evaluatorRole} mode="multiple" showSearch={false} />
        </Form.Item>
        <Form.Item
          label="Pilih Form"
          name="form"
          required
          rules={[{ required: true }]}
        >
          <FormSelectComponent />
        </Form.Item>
        <Form.Item
          label="Periode Penilaian"
          name="penilaian_period"
          rules={[
            { required: true },
            {
              validator(_, value) {
                if (!value || !value[0] || !value[1]) {
                  return Promise.reject(
                    new Error('Periode Penilaian harus diisi.')
                  )
                }
                return Promise.resolve()
              },
            },
          ]}
          getValueProps={(dates) => ({
            value: dates
              ? [
                  dates[0] ? dayjs(dates[0]) : undefined,
                  dates[1] ? dayjs(dates[1]) : undefined,
                ]
              : [],
          })}
          getValueFromEvent={(date) => {
            if (!date) return [null, null]
            const [startDate, endDate]: [Dayjs, Dayjs] = date
            return [
              startDate.tz().startOf('date').toISOString(),
              endDate.tz().endOf('date').toISOString(),
            ]
          }}
        >
          <DatePicker.RangePicker
            format="DD MMM YYYY"
            locale={locale.DatePicker}
            allowEmpty={[false, false]}
            disabledDate={(date) => date.isBefore(dayjs().startOf('date'))}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default ModalForm
