import { Button, Result } from '@pankod/refine-antd'
import { LayoutWrapper, useNavigation } from '@pankod/refine-core'
import Head from 'next/head'
import React from 'react'
import {
  FallbackProps,
  ErrorBoundary as ReactErrorBoundary,
} from 'react-error-boundary'

type ErrorBoundaryProps = {
  children: React.ReactNode
  resetKeys: string[]
}

export const ErrorBoundary = ({ children, resetKeys }: ErrorBoundaryProps) => {
  return (
    <ReactErrorBoundary
      FallbackComponent={FallbackComponent}
      resetKeys={resetKeys}
    >
      {children}
    </ReactErrorBoundary>
  )
}

const FallbackComponent = ({ error, resetErrorBoundary }: FallbackProps) => {
  const { goBack } = useNavigation()
  const { onBackClick, backButtonText, ...rest } = error
  return (
    <LayoutWrapper>
      <Head>
        <title>LMS Admin</title>
      </Head>
      <Result
        {...rest}
        extra={
          <Button
            onClick={() => {
              resetErrorBoundary()
              if (onBackClick) {
                return onBackClick()
              }

              goBack()
            }}
          >
            {backButtonText ? backButtonText : 'Kembali'}
          </Button>
        }
      />
    </LayoutWrapper>
  )
}
