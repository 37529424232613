import {
  Button,
  Icons,
  Popconfirm,
  Table,
  TableColumnGroupType,
  TableColumnType,
  Tag,
  useModalForm,
  useTable,
} from '@pankod/refine-antd'
import React from 'react'
import { useCreate, useInvalidate, useOne } from '@pankod/refine-core'

import { TableTitle } from '@components/TableTitle'
import { PesertaResponse } from '@resources/angkatan-ppg-management/types'
import { TCommonError } from 'src/interfaces/common'
import { PesertaAddModalForm } from './PesertaAddModalForm'
import { showErrorNotification } from '@resources/angkatan-ppg-management/utils'
import { PopDeleteConfirm } from '@resources/lms-management/components/PopDeleteConfirm'
import {
  getMatrikulasiStatus,
  getRegisteredMatrikulasiStatus,
} from '@resources/basic-ppg-management/utils/matrikulasi_status_map'

type PesertaTabContentProps = {
  angkatanId?: number | string
  lptkId: number | null
  rombelId?: number
}

export const PesertaTabContent = ({
  angkatanId,
  lptkId,
  rombelId,
}: PesertaTabContentProps) => {
  const { data: kvData } = useOne<any>({
    resource: 'kv?key=feature_flag',
    id: '',
    dataProviderName: 'microlearning',
  })
  const { enableRombelSyncRelatedDataMutation = true } =
    (kvData?.data?.data as any) || {}
  const PESERTA_RESOURCE = `angkatan/${angkatanId}/lptk/${lptkId}/rombel/${rombelId}/peserta`
  const { tableProps, tableQueryResult } = useTable<
    PesertaResponse,
    TCommonError
  >({
    dataProviderName: 'lms',
    resource: PESERTA_RESOURCE,
  })
  const { modalProps, formProps, show, close, formLoading } = useModalForm({
    action: 'create',
    resource: PESERTA_RESOURCE,
    dataProviderName: 'lms',
    warnWhenUnsavedChanges: true,
    redirect: false,
    autoResetForm: true,
    autoSubmitClose: true,
    invalidates: ['list'],
    successNotification: {
      message: 'Peserta successfully added',
      type: 'success',
      description: 'Successful',
    },
    errorNotification: (error) =>
      showErrorNotification(error, 'There was a problem when adding Rombel'),
  })

  const { mutate: doUpdateStatus, isLoading } = useCreate()

  const invalidate = useInvalidate()

  const [rerenderKey, setRerenderKey] = React.useState(0)

  const handleModalRefresh = () => {
    setRerenderKey((prev) => prev + 1)
  }

  const MAHASISWA_STATUS_MAP: any = {
    TERDAFTAR: 'Terdaftar',
    TIDAK_LAPOR_DIRI: 'Tidak Lapor Diri',
    MENGUNDURKAN_DIRI: 'Mengundurkan Diri',
    EMPTY: 'Belum Diketahui',
  }

  const columns: (
    | TableColumnType<PesertaResponse>
    | TableColumnGroupType<PesertaResponse>
  )[] = [
    {
      title: 'Nama',
      key: 'name',
      dataIndex: 'name',
    },
    {
      title: 'Email',
      key: 'email',
      dataIndex: 'email',
    },
    {
      title: 'Bidang Studi',
      key: 'bidangStudi',
      dataIndex: 'bidangStudi',
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',

      render: (status: string) => (
        <Tag>{MAHASISWA_STATUS_MAP[status] || status}</Tag>
      ),
    },
    {
      title: 'Pendaftaran Matrikulasi',
      key: 'isMatrikulasi',
      dataIndex: 'isMatrikulasi',
      render: (status: boolean) => (
        <Tag color={getRegisteredMatrikulasiStatus(status).color}>
          {getRegisteredMatrikulasiStatus(status).text}
        </Tag>
      ),
    },
    {
      title: 'Status Matrikulasi',
      key: 'matrikulasi',
      dataIndex: 'matrikulasi',
      render: (status: string) => (
        <Tag color={getMatrikulasiStatus(status).color}>
          {getMatrikulasiStatus(status).text}
        </Tag>
      ),
    },
    {
      title: 'Lulusan',
      key: 'lulusan',
      dataIndex: 'lulusan',
      render: (lulusan: string) => <Tag>{lulusan}</Tag>,
    },
    {
      title: 'Akses Kelas',
      key: 'enrollmentStatus',
      dataIndex: 'enrollmentStatus',
      render: (status: string) => <Tag>{status}</Tag>,
    },
    {
      render: (_, record: any) => {
        return (
          enableRombelSyncRelatedDataMutation && (
            <div className="flex flex-row gap-4">
              <PopDeleteConfirm
                title="“Perhatian !!, nilai di semester berjalan akan ikut terhapus, apakah anda yakin ?, Ketik ‘Ya’  untuk melanjutkan”"
                okText="Hapus"
                validateValue="Ya"
                cancelText="Kembali"
                okButtonProps={{ danger: true, loading: isLoading }}
                placement="topRight"
                onConfirm={async () => {
                  return doUpdateStatus({
                    resource: `${PESERTA_RESOURCE}/${record.id}/enrollment-status`,
                    values: {
                      enrollmentStatus: 'DELETED',
                    },
                    dataProviderName: 'lms',
                    successNotification: () => {
                      invalidate({
                        resource: PESERTA_RESOURCE,
                        dataProviderName: 'lms',
                        invalidates: ['list'],
                      })

                      return {
                        message: 'Peserta successfuly removed',
                        type: 'success',
                        description: 'Successful',
                      }
                    },
                    errorNotification: (error) =>
                      showErrorNotification(
                        error,
                        'There was a problem when removing Peserta'
                      ),
                  })
                }}
              >
                <Button
                  className="mr-4"
                  icon={<Icons.DeleteOutlined />}
                  danger
                  size="small"
                >
                  Hapus
                </Button>
              </PopDeleteConfirm>
              {record.enrollmentStatus !== 'INACTIVE' && (
                <Popconfirm
                  title="Apakah kamu yakin ingin nonaktifkan peserta ini ?"
                  okText="set Inactive"
                  cancelText="Kembali"
                  okButtonProps={{ danger: true, loading: isLoading }}
                  placement="topRight"
                  onConfirm={async () => {
                    return doUpdateStatus({
                      resource: `${PESERTA_RESOURCE}/${record.id}/enrollment-status`,
                      values: {
                        enrollmentStatus: 'INACTIVE',
                      },

                      dataProviderName: 'lms',
                      successNotification: () => {
                        invalidate({
                          resource: PESERTA_RESOURCE,
                          dataProviderName: 'lms',
                          invalidates: ['list'],
                        })

                        return {
                          message: 'Peserta successfuly removed',
                          type: 'success',
                          description: 'Successful',
                        }
                      },
                      errorNotification: (error) =>
                        showErrorNotification(
                          error,
                          'There was a problem when removing Peserta'
                        ),
                    })
                  }}
                >
                  <Button
                    className="mr-4"
                    icon={<Icons.EditOutlined />}
                    size="small"
                  >
                    Non Aktifkan
                  </Button>
                </Popconfirm>
              )}
            </div>
          )
        )
      },
    },
  ]

  return (
    <>
      <Table
        {...tableProps}
        size="small"
        rowKey="id"
        title={() => (
          <TableTitle
            title="Daftar Peserta"
            extra={
              enableRombelSyncRelatedDataMutation && (
                <Button icon={<Icons.PlusOutlined />} onClick={() => show()}>
                  Tambah Peserta
                </Button>
              )
            }
          />
        )}
        columns={columns}
        loading={tableQueryResult.isFetching}
      />

      <PesertaAddModalForm
        formProps={formProps}
        lptkId={lptkId}
        modalProps={modalProps}
        onClose={close}
        angkatanId={angkatanId}
        rombelId={rombelId}
        isSubmitting={formLoading}
        onRefresh={handleModalRefresh}
        key={rerenderKey}
      />
    </>
  )
}
