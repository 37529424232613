import { CANVAS_PREVIEW_DOM_ID } from '../constants'
import { createCanvas } from './canvas'

export const exportObjectToSVG = async (canvasObject: {
  version: string
  objects: any[]
}) => {
  const canvas = createCanvas(CANVAS_PREVIEW_DOM_ID['canvas-front-page'])
  await canvas.loadFromJSON({ ...canvasObject })
  let index = 0
  const usedSystemVariables: string[] = []
  const result = canvas?.toSVG(undefined, (svg) => {
    const object: {
      customObjectType: string
      customVariableKey?: string
      width: number
    } = canvasObject.objects[index]
    const { customObjectType: type, customVariableKey: key, width } = object
    if (key !== undefined) {
      if (type === 'Image') {
        svg = svg.replace(/(xlink:href=")[^"]*(")/, `$1{{ ${key} }}$2`)
        svg = svg.replace('<image ', `<image data-variablekey="${key}" `)
      }
      if (type === 'Text') {
        svg = svg.replace(/(<tspan[^>]*>)(.*?)(<\/tspan>)/, `$1{{ ${key} }}$3`)
        svg = svg.replace('<text ', `<text data-variablekey="${key}" `)
      }

      if (!key.startsWith('attributes[')) {
        usedSystemVariables.push(key)
      }
    }

    if (['Text', 'Textbox'].includes(type)) {
      svg = svg.replace(
        '<text ',
        `<text data-objecttype="${type}" data-objectwidth="${width}" `
      )
    }

    if (type === 'Image') {
      svg = svg.replace(
        '<image ',
        `<image data-objecttype="${type}" data-objectwidth="${width}" `
      )
    }

    index++
    return svg
  })
  await canvas.dispose()
  return { svgString: result, usedSystemVariables }
}
