import {
  Radio,
  Table,
  TableColumnGroupType,
  TableColumnType,
  TextField,
  useTable,
} from '@pankod/refine-antd'
import React from 'react'

import { KelompokMurid } from '@resources/lms-management/class/types'

type ClassGroupSelectProps = {
  localProgramId?: string
  classId?: string
  classGroupId?: string
  selected: { id?: string; name?: string }
  onSelect: (data: { id?: string; name?: string }) => void
}

const ClassGroupSelect = ({
  onSelect,
  selected,
  classId,
  localProgramId,
}: ClassGroupSelectProps) => {
  const { tableProps } = useTable<KelompokMurid>({
    resource: `local-programs/${localProgramId}/classes/${classId}/kelompok-murid`,
    dataProviderName: 'lms',
    queryOptions: {
      enabled: !!localProgramId && !!classId,
    },
    hasPagination: false,
  })

  const columns: (TableColumnType<any> | TableColumnGroupType<any>)[] = [
    {
      title: 'ID',
      key: 'id',
      dataIndex: 'id',
      width: 80,
      render: (value) => (
        <TextField
          value={value}
          type={String(value) === classId ? 'secondary' : undefined}
        />
      ),
    },
    {
      title: 'Nama Kelompok Murid',
      key: 'name',
      dataIndex: 'name',
      render: (value, record) => (
        <TextField
          value={value}
          type={String(record.id) === classId ? 'secondary' : undefined}
        />
      ),
    },
  ]

  return (
    <Table
      {...tableProps}
      rowSelection={{
        type: 'radio',
        onChange: (_, record) => {
          onSelect({ id: String(record[0].id), name: record[0].name })
        },
        selectedRowKeys: selected.id ? [selected.id] : undefined,
        columnTitle: 'Pilih',
        columnWidth: 45,
        getCheckboxProps(record) {
          return {
            name: record.name,
          }
        },
        renderCell(value, record) {
          return (
            <Radio
              checked={value}
              onClick={() => {
                if (!value) {
                  onSelect({ id: String(record.id), name: record.name })
                } else {
                  onSelect({ id: undefined, name: undefined })
                }
              }}
            />
          )
        },
      }}
      rowKey="id"
      columns={columns}
      scroll={{ y: 400 }}
    />
  )
}

export default ClassGroupSelect
