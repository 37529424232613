export const getNameInitial = (name: string = '', length: number = 2) => {
  return name
    .split(' ')
    .map((n) => n.charAt(0).toUpperCase())
    .join('')
    .substring(0, length)
}

export const getCapitalizedWords = (words: string = '') =>
  words
    .trim()
    .toLowerCase()
    .replace(/\w\S*/g, (w) => w.replace(/^\w/, (c) => c.toUpperCase()))

// Copied from guru-fe
/**
 * Replace `\n` to `<br />` in a string
 *
 * @param {string} str Input text
 * @param {boolean} replaceMode Use replace instead of insert
 * @param {boolean} isXhtml Use XHTML
 * @return {string} Filtered text
 */
export function nl2br(
  str: string,
  replaceMode: boolean = true,
  isXhtml: boolean = true
): string {
  let breakTag = isXhtml ? '<br />' : '<br>'
  let replaceStr = replaceMode ? '$1' + breakTag : '$1' + breakTag + '$2'
  return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, replaceStr)
}

/**
 * Transform PascalCase into Text Case
 * @param text Text that will be transformed e.g `HelloWorld`
 * @returns Transformed text e.g `Hello World`
 */
export const pascalToTextCase = (text: string) =>
  text.replace(/([a-z])([A-Z])/g, '$1 $2')
