import { Button, Icons, Table } from '@pankod/refine-antd'
import React from 'react'
import { useCustomMutation, useInvalidate } from '@pankod/refine-core'

import { LPTKSingleResponse } from '../../types'
import { PopDeleteConfirm } from '@resources/lms-management/components/PopDeleteConfirm'
import {
  AdminLPTKModalForm,
  useAssignAdminLptkModalForm,
} from './AdminLPTKModalForm'

type AdminLPTKTableProps = {
  dataSource?: LPTKSingleResponse['admins']
  lptkId: number
}
export const AdminLPTKTable = ({ dataSource, lptkId }: AdminLPTKTableProps) => {
  const { mutateAsync: doUnassign, isLoading } = useCustomMutation()
  const { show: showAssignAdminLptk, ...adminLptkModalFormProps } =
    useAssignAdminLptkModalForm({ lptkId })
  const invalidate = useInvalidate()

  return (
    <>
      <div className="flex flex-row w-full justify-end items-center gap-2 mb-4">
        <Button onClick={showAssignAdminLptk}>Tambah Admin</Button>
      </div>
      <Table dataSource={dataSource} rowKey="email">
        <Table.Column title="Email" dataIndex="email" />
        <Table.Column title="Nama" dataIndex="name" width="50%" />
        <Table.Column
          render={(_, record: { name: string; email: string }) => (
            <PopDeleteConfirm
              title="Apakah Anda yakin ingin menghapus admin ini?"
              onConfirm={() => {
                return doUnassign({
                  method: 'post',
                  url: `${process.env.NEXT_PUBLIC_LMS_API_URL}/lptk/${lptkId}/unassign-admin`,
                  values: {
                    email: record.email,
                  },
                  successNotification: {
                    message: 'Admin LPTK successfuly removed',
                    type: 'success',
                    description: 'Successful',
                  },
                  errorNotification: () => {
                    return {
                      description: 'Error',
                      type: 'error',
                      message: 'There was a problem when removing Admin LPTK',
                    }
                  },
                }).then(() => {
                  invalidate({
                    resource: 'lptk',
                    id: lptkId,
                    dataProviderName: 'lms',
                    invalidates: ['detail'],
                  })
                })
              }}
              children={
                <Button danger icon={<Icons.DeleteOutlined />}>
                  Hapus
                </Button>
              }
              validateValue={record.email}
              placeholder="Tulis ulang email admin"
              okText="Hapus"
              cancelText="Kembali"
              okButtonProps={{ danger: true, loading: isLoading }}
            />
          )}
        />
      </Table>
      <AdminLPTKModalForm
        {...adminLptkModalFormProps}
        currentAdmins={dataSource?.map(({ email }) => email) || []}
      />
    </>
  )
}
