import { Typography } from '@pankod/refine-antd'
import React, { ReactElement } from 'react'

type TableTitleProps = {
  title: string
  extra?: ReactElement
}
export const TableTitle = ({ extra, title }: TableTitleProps) => {
  return (
    <div className="flex justify-between items-center mb-1">
      <Typography.Title level={5} className="flex items-center !m-0">
        {title}
      </Typography.Title>
      {extra ? extra : null}
    </div>
  )
}
