import { Icons } from '@pankod/refine-antd'
import { ResourceProps } from '@pankod/refine-core'

import { AdminLPTKList } from './lptk/admin-lptk-layout'

export const lptkResource: ResourceProps[] = [
  {
    name: 'master-lptk-ppg-management',
    icon: <Icons.SettingOutlined />,
    options: {
      label: 'Master',
      route: 'master-lptk-ppg-management',
    },
  },
  {
    name: 'master-lptk-dosen-ppg-management',
    parentName: 'master-lptk-ppg-management',
    options: {
      label: 'Dosen',
      route: 'lptk-dosen-ppg-management',
      key: 'dosen',
    },
    list: AdminLPTKList,
  },
  // TODO: DELETE THIS ONCE BE READY
  // @ts-ignore
  {
    ...(process.env.NEXT_PUBLIC_ENALBE_PPG_INSTRUKTUR_AKTOR === 'true' && {
      name: 'master-lptk-instruktur-ppg-management',
      parentName: 'master-lptk-ppg-management',
      options: {
        label: 'Instruktur',
        route: 'lptk-instruktur-ppg-management',
        key: 'instruktur',
      },
      list: AdminLPTKList,
    }),
  },
  {
    name: 'master-lptk-pamong-ppg-management',
    parentName: 'master-lptk-ppg-management',
    options: {
      label: 'Guru Pamong',
      route: 'lptk-pamong-ppg-management',
      key: 'guru-pamong',
    },
    list: AdminLPTKList,
  },
  {
    name: 'master-lptk-admin-kelas-ppg-management',
    parentName: 'master-lptk-ppg-management',
    options: {
      label: 'Admin Kelas',
      route: 'lptk-admin-kelas-ppg-management',
      key: 'admin-kelas',
    },
    list: AdminLPTKList,
  },
  // {
  //   name: 'master-lptk-sekolah-ppg-management',
  //   parentName: 'master-lptk-ppg-management',
  //   options: {
  //     label: 'Sekolah',
  //     route: 'lptk-sekolah-ppg-management',
  //     key: 'sekolah',
  //   },
  //   list: AdminLPTKList,
  // },
  {
    name: 'master-lptk-mahasiswa-ppg-management',
    parentName: 'master-lptk-ppg-management',
    options: {
      label: 'Mahasiswa',
      route: 'lptk-mahasiswa-ppg-management',
      key: 'mahasiswa',
    },
    list: AdminLPTKList,
  },
]
