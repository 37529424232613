import {
  Button,
  Card,
  Divider,
  Empty,
  Icons,
  message,
  Modal,
  Space,
  Tooltip,
  Typography,
} from '@pankod/refine-antd'
import React from 'react'
import { useEditor } from '@craftjs/core'

import useOnClickOutside from 'src/hooks/useOnClickOutside'
import { pascalToTextCase } from 'src/helpers/text'
import { WIDGET_NAME } from '../schema'

const { confirm } = Modal

type TWidgetSettings = {
  contentWrapperRef: React.RefObject<HTMLDivElement>
}
const WidgetSettings = ({ contentWrapperRef }: TWidgetSettings) => {
  const widgetSettingsRef = React.useRef<HTMLDivElement>(null)
  const { selected, actions } = useEditor((state, query) => {
    const [currentNodeId] = state.events.selected
    let selected

    if (currentNodeId) {
      selected = {
        id: currentNodeId,
        name: state.nodes[currentNodeId].data.name,
        settings:
          state.nodes[currentNodeId].related &&
          state.nodes[currentNodeId].related.settings,
        isDeletable:
          query.node(currentNodeId).isDeletable() &&
          state.nodes[currentNodeId].data.name !== WIDGET_NAME.Container,
      }
    }

    return {
      selected,
    }
  })
  useOnClickOutside([contentWrapperRef, widgetSettingsRef], () => {
    if (selected?.id !== '') actions.selectNode('')
  })

  const showConfirm = () => {
    confirm({
      title: `Are you sure delete ${selected?.name} widget?`,
      content: `Widget ID: ${selected?.id}`,
      okType: 'danger',
      okText: 'Yes',
      cancelText: 'No',
      icon: <Icons.ExclamationCircleOutlined />,
      onOk() {
        if (!selected) return

        actions.delete(selected.id)
      },
      onCancel() {
        actions.selectNode(selected?.id)
      },
    })
  }

  return (
    <div ref={widgetSettingsRef} className="max-h-[400px] overflow-auto">
      <Card
        title={
          <Space>
            <Typography.Text>Widget Settings</Typography.Text>
            {selected && (
              <Tooltip
                title={
                  <div>
                    <article>Widget ID: {selected.id}</article>
                    <article>
                      Widget Name: {pascalToTextCase(selected.name)}
                    </article>
                  </div>
                }
              >
                <Icons.InfoCircleOutlined
                  onClick={() => {
                    navigator.clipboard.writeText(
                      `Widget ID: ${selected.id} | Widget Name: ${selected.name}`
                    )
                    message.info('Widget Information copied!')
                  }}
                />
              </Tooltip>
            )}
          </Space>
        }
        size="small"
        extra={
          <Space>
            <Button
              icon={<Icons.UndoOutlined />}
              onClick={() => actions.history.undo()}
              size="small"
            >
              Undo
            </Button>
            <Divider type="vertical" />
            <Button
              danger
              disabled={!selected?.isDeletable || !selected}
              block
              icon={<Icons.DeleteOutlined />}
              size="small"
              onClick={() => {
                if (!selected) return

                showConfirm()
              }}
            >
              Delete
            </Button>
          </Space>
        }
      >
        {selected ? (
          <>
            {selected.settings && React.createElement(selected.settings)}
            {!selected.settings && (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="No Widget Settings Available"
              />
            )}
          </>
        ) : (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="No Widget Component Selected"
          />
        )}
      </Card>
    </div>
  )
}

export default WidgetSettings
