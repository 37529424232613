import {
  Button,
  DatePicker,
  Form,
  Icons,
  Space,
  useTableReturnType,
} from '@pankod/refine-antd'
import { LogicalFilter } from '@pankod/refine-core'
import dayjs from 'dayjs'
import React, { useEffect } from 'react'

type TableDateRangeFilterProps = {
  close: () => void
  setFilters: useTableReturnType['setFilters']
  dataIndex: { start: string; end: string }
  currentFilters: LogicalFilter[]
  visible: boolean
}
const TableDateRangeFilter = ({
  close,
  setFilters,
  dataIndex,
  currentFilters,
  visible,
}: TableDateRangeFilterProps) => {
  const [form] = Form.useForm()

  useEffect(() => {
    if (visible) {
      const start = currentFilters.find(
        ({ field }) => field === dataIndex.start
      )?.value
      const end = currentFilters.find(
        ({ field }) => field === dataIndex.end
      )?.value
      if (!start) {
        form.resetFields()
        return
      }
      form.setFields([
        { name: 'dateRangePicker', value: [dayjs(start), dayjs(end)] },
      ])
    }
  }, [currentFilters, dataIndex.end, dataIndex.start, form, visible])

  const clearFilters = () => {
    setFilters((prevFilters) => [
      ...(prevFilters as LogicalFilter[]).filter(
        ({ field }) =>
          field !== dataIndex.start &&
          field !== dataIndex.end &&
          field !== 'page'
      ),
      { field: 'page', operator: 'eq', value: 1 },
    ])
    close()
  }

  return (
    <div className="p-2 grid grid-cols-1">
      <Form
        form={form}
        layout="vertical"
        onFinish={(values) => {
          const { dateRangePicker } = values
          if (!dateRangePicker) {
            clearFilters()
            return
          }

          const [start, end] = dateRangePicker
          setFilters([
            {
              field: dataIndex.start,
              operator: 'eq',
              value: dayjs(start).startOf('date').toISOString(),
            },
            {
              field: dataIndex.end,
              operator: 'eq',
              value: dayjs(end).endOf('date').toISOString(),
            },
            { field: 'page', operator: 'eq', value: 1 },
          ])
          close()
        }}
      >
        <Form.Item name="dateRangePicker" label="Pick date">
          <DatePicker.RangePicker allowEmpty={[false, false]} />
        </Form.Item>
      </Form>
      <Space className="justify-self-end">
        <Button
          onClick={form.submit}
          icon={<Icons.FilterOutlined />}
          type="primary"
          size="small"
        >
          Filter
        </Button>
        <Button
          onClick={() => {
            form.resetFields()
            clearFilters()
          }}
          size="small"
          danger
        >
          Clear
        </Button>
      </Space>
    </div>
  )
}

export default TableDateRangeFilter
