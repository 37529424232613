import {
  Button,
  // Icon,
  // Descriptions,
  // Divider,
  List,
  Popconfirm,
  Select,
  Space,
  Table,
  useSelect,
  useTable,
  // useModalForm,
} from '@pankod/refine-antd'
import { useCreate } from '@pankod/refine-core'
import Head from 'next/head'
import { useState } from 'react'

export const PPGSyncProgressList = () => {
  const [selectedAngkatan, setSelectedAngkatan] = useState<string | undefined>(
    undefined
  )

  const { mutate: doPatch } = useCreate()

  const { selectProps: angkatanSelectProps, queryResult: angkatanQueryResult } =
    useSelect({
      resource: 'angkatan',
      optionLabel: 'name',
      optionValue: 'id',
      filters: [
        {
          field: 'pageSize',
          operator: 'eq',
          value: '-1',
        },
      ],
      defaultValueQueryOptions: {
        enabled: false,
      },
    })

  const { selectProps: lptkSelectProps, queryResult: lptkQueryResult } =
    useSelect({
      resource: `angkatan/${selectedAngkatan}/lptk`,
      optionLabel: 'name',
      optionValue: 'id',
      filters: [
        {
          field: 'pageSize',
          operator: 'eq',
          value: '-1',
        },
      ],
      queryOptions: {
        enabled: !!selectedAngkatan,
      },
      defaultValueQueryOptions: {
        enabled: false,
      },
    })

  const { tableProps, setFilters, tableQueryResult } = useTable({
    resource: `ppg_failed_sync_progress`,
    dataProviderName: 'lms',
  })

  return (
    <List title="PPG Sync Progress">
      <Head>
        <title>User CSV Encoded ID Getter</title>
      </Head>

      <div className="flex flex-row items-center w-full my-8 gap-4">
        <Select
          {...angkatanSelectProps}
          allowClear
          loading={angkatanQueryResult.isFetching}
          className="w-[300px]"
          filterOption={(input, option) =>
            (option!.label as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          onSelect={(value: any) => {
            setSelectedAngkatan(value as string)
            setFilters([
              {
                field: 'angkatan',
                operator: 'eq',
                value,
              },
            ])

            setFilters([
              {
                field: 'lptk',
                operator: 'eq',
                value: null,
              },
            ])
          }}
          onClear={() => {
            setSelectedAngkatan(undefined)
            setFilters([
              {
                field: 'angkatan',
                operator: 'eq',
                value: null,
              },
            ])
            setFilters([
              {
                field: 'lptk',
                operator: 'eq',
                value: null,
              },
            ])
          }}
        />

        {lptkQueryResult?.data && lptkQueryResult.data.data.length > 0 && (
          <Select
            {...lptkSelectProps}
            key={selectedAngkatan}
            className="w-[300px] ml-4"
            allowClear
            loading={lptkQueryResult.isFetching}
            filterOption={(input, option) =>
              (option!.label as unknown as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            onSelect={(value: any) => {
              setFilters([
                {
                  field: 'lptk',
                  operator: 'eq',
                  value,
                },
              ])
            }}
          />
        )}
      </div>

      <Table
        {...tableProps}
        columns={[
          {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
          },
          {
            title: 'Batch ID',
            dataIndex: 'batchId',
            key: 'batchId',
          },
          {
            title: 'Tanggal',
            dataIndex: 'createdAt',
            key: 'createdAt',
          },
          {
            title: 'Angkatan',
            dataIndex: 'batchName',
            key: 'batchName',
          },
          {
            title: 'LPTK',
            dataIndex: 'lptkName',
            key: 'lptkName',
          },
          {
            title: 'Semester',
            dataIndex: 'semester',
            key: 'semester',
          },

          {
            title: 'progress',
            dataIndex: 'progress',
            key: 'progress',
          },

          {
            title: 'status',
            dataIndex: 'status',
            key: 'status',
          },

          {
            title: 'importStatus',
            dataIndex: 'importStatus',
            key: 'importStatus',
          },

          {
            title: 'errorMessage',
            dataIndex: 'errors',
            key: 'errors',
          },
          {
            title: 'Action',
            dataIndex: 'id',
            key: 'id',
            render: (_, record) => {
              return (
                <Space>
                  <Popconfirm
                    title="Apakah anda yakin ingin mengubah status ke `COMPLETED_WITH_MESSAGE` ?"
                    onConfirm={async () => {
                      await doPatch({
                        resource: `ppg_failed_sync_progress/${record.id}`,
                        values: {
                          type: 'COMPLETED_WITH_MESSAGE',
                        },
                        metaData: {
                          httpMethod: 'patch',
                        },
                        successNotification: () => {
                          tableQueryResult.refetch()
                          return {
                            message: 'Berhasil mengubah status',
                            description:
                              'status berubah menjadi COMPLETED_WITH_MESSAGE',
                            type: 'success',
                          }
                        },
                      })
                    }}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button>Set Complete</Button>
                  </Popconfirm>

                  <Popconfirm
                    title="Apakah anda yakin ingin mengubah status ke `INIT` ?"
                    onConfirm={async () => {
                      await doPatch({
                        resource: `ppg_failed_sync_progress/${record.id}`,
                        values: {
                          type: 'INIT',
                        },
                        metaData: {
                          httpMethod: 'patch',
                        },
                        successNotification: () => {
                          tableQueryResult.refetch()
                          return {
                            message: 'Berhasil mengubah status',
                            description: 'status berubah menjadi INIT',
                            type: 'success',
                          }
                        },
                      })
                    }}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button>Set INIT</Button>
                  </Popconfirm>
                </Space>
              )
            },
          },
        ]}
      />
    </List>
  )
}
