import { DrawerFormPenilaianDiri } from './drawer/DrawerFormPenilaianDiri'
import { DrawerFormPenilaianRefleksi } from './drawer/DrawerFormRefleksi'
import { DrawerFormUmpanBalik } from './drawer/DrawerFormUmpanBalik'
import { DrawerFormPenilaianPartisipasi } from './drawer/DrawerFormPenilaianPartisipasi'
import { DrawerFormBahanAjar } from './drawer/DrawerFormBahanAjar'
import { DrawerFormJurnalFasilitasi } from './drawer/DrawerFormJurnalFasilitasi'
import { DrawerFormPenilaianKinerja } from './drawer/DrawerFormPenilaianKinerja'
import { DrawerFormJurnalFasilitator } from './drawer/DrawerFormJurnalFasilitator'
import { DrawerFormPenilaianCoaching } from './drawer/DrawerFormPenilaianCoaching'
import { DrawerFormPenilaianObservasi } from './drawer/DrawerFormPenilaianObservasi'
import { DrawerFormProdukBelajar } from './drawer/DrawerFormProdukBelajar'

export type DrawerSelector = {
  type: string
  visible: boolean
  onClose: () => void
  programId: string | number
  ptmData?: any
}

export const DrawerSelector = (props: DrawerSelector) => {
  const { type, visible, onClose: handleParentClose, programId } = props

  const FormList: any = {
    FormPenilaianPartisipasi: DrawerFormPenilaianPartisipasi,
    FormRefleksi: DrawerFormPenilaianRefleksi,
    FormUmpanBalik: DrawerFormUmpanBalik,
    FormPenilaianDiri: DrawerFormPenilaianDiri,
    FormBahanAjar: DrawerFormBahanAjar,
    FormJurnalFasilitasi: DrawerFormJurnalFasilitasi,
    formPenilaianKinerja: DrawerFormPenilaianKinerja,
    FormJurnalFasilitator: DrawerFormJurnalFasilitator,
    FormPenilaianCoaching: DrawerFormPenilaianCoaching,
    FormPenilaianObservasi: DrawerFormPenilaianObservasi,
    FormProdukBelajar: DrawerFormProdukBelajar,
  }

  const FormComponent = FormList[type]

  if (FormComponent) {
    return (
      <FormComponent
        visible={visible}
        onClose={handleParentClose}
        programId={programId}
        ptmData={props.ptmData}
      />
    )
  }

  return null
}
