import {
  Button,
  DatePicker,
  Form,
  FormInstance,
  Icons,
} from '@pankod/refine-antd'
import React, { useState } from 'react'
import dayjs, { Dayjs } from 'dayjs'

import {
  generateDisabledTimeSpan,
  isRangeOverlaps,
} from '../../utils/timepickerUtils'
import { WebinarTimeRange } from '../../types'

const DEFAULT_SESSION = [
  { startTime: '13:00', endTime: '14:30' },
  { startTime: '15:30', endTime: '17:30' },
]

type IsOverlapParam = {
  time: [Dayjs, Dayjs] | []
  currentTime: [string, string]
}

const isOverlap = ({
  time: [startTimeA, endTimeA],
  currentTime: [startB, endB],
}: IsOverlapParam) => {
  if (!startTimeA || !endTimeA) return false
  const startA = startTimeA.format('HH:mm')
  const endA = endTimeA.format('HH:mm')
  return isRangeOverlaps(
    { startTime: startA, endTime: endA },
    { startTime: startB, endTime: endB }
  )
}

type ScheduleSessionProps = {
  timezone: 'wib' | 'wita' | 'wit'
  name: string
  mode: 'create' | 'edit'
  form?: FormInstance
  unavailableTime?: ([Dayjs, Dayjs] | [])[]
  hasInstructorSelectionStarted: boolean
}
export const ScheduleSession = ({
  name,
  timezone,
  form,
  mode,
  unavailableTime,
  hasInstructorSelectionStarted,
}: ScheduleSessionProps) => {
  const [startTime, setStartTime] = useState<Dayjs[]>([])
  const isEditMode = mode === 'edit'
  const sessionValues: WebinarTimeRange[] =
    form?.getFieldValue?.([name, timezone]) || []
  return (
    <Form.List
      name={[name, timezone]}
      initialValue={isEditMode ? undefined : DEFAULT_SESSION}
      rules={[
        {
          validator: async (_, sessions) => {
            if (!sessions || sessions.length < 1 || !sessions[0]) {
              return Promise.reject(new Error('Minimum 1 sesi.'))
            }

            return Promise.resolve()
          },
        },
        {
          validator: async (_, sessions) => {
            const uniqueSessionLength = {
              startTime: new Set(
                sessions?.map((session: WebinarTimeRange) => session?.startTime)
              ).size,
              endTime: new Set(
                sessions?.map((session: WebinarTimeRange) => session?.endTime)
              ).size,
            }
            const [isStartTimeDuplicate, isEndTimeDuplicate] = [
              uniqueSessionLength.startTime !== sessions.length,
              uniqueSessionLength.endTime !== sessions.length,
            ]
            if (isStartTimeDuplicate || isEndTimeDuplicate) {
              return Promise.reject(
                new Error(
                  'Setiap sesi tidak boleh memiliki duplikat waktu mulai atau waktu selesai.'
                )
              )
            }

            return Promise.resolve()
          },
        },
      ]}
    >
      {(fields, { add, remove }, { errors }) => {
        return (
          <>
            {fields.map((field, index) => {
              return (
                <Form.Item
                  {...{
                    labelCol: {
                      xs: { span: 24 },
                      sm: { span: 4 },
                    },
                    wrapperCol: {
                      xs: { span: 24 },
                      sm: { span: 20 },
                    },
                  }}
                  label={`Sesi ${index + 1}`}
                  key={field.key}
                  required
                >
                  <Form.Item
                    {...field}
                    validateTrigger={['onChange', 'onBlur']}
                    noStyle
                    getValueProps={(dateString?: {
                      startTime: string
                      endTime: string
                    }) => {
                      if (!dateString) return { value: [] }
                      const [startHour, startMinute] =
                        dateString.startTime.split(':')
                      const startDate = dayjs()
                        .set('hour', +startHour)
                        .set('minute', +startMinute)
                      const [endHour, endMinute] = dateString.endTime.split(':')
                      const endDate = dayjs()
                        .set('hour', +endHour)
                        .set('minute', +endMinute)
                      return {
                        value: [startDate, endDate],
                      }
                    }}
                    getValueFromEvent={(dateDayjs?: [Dayjs, Dayjs]) => {
                      if (!dateDayjs) return undefined
                      return {
                        startTime: dayjs(dateDayjs[0]).format('HH:mm'),
                        endTime: dayjs(dateDayjs[1]).format('HH:mm'),
                      }
                    }}
                    rules={[
                      {
                        required: true,
                        message: `Sesi harus diisi.`,
                      },
                      {
                        validator(_, value) {
                          if (value && value.startTime === value.endTime) {
                            return Promise.reject(
                              new Error(
                                'Jam mulai tidak boleh sama dengan jam selesai.'
                              )
                            )
                          }

                          return Promise.resolve()
                        },
                      },
                      {
                        validator(_, value) {
                          if (!unavailableTime || !value)
                            return Promise.resolve()
                          const filteredUnavailableTime =
                            unavailableTime.filter((_, idx) => idx !== index)
                          const hasConflicts = filteredUnavailableTime.some(
                            (time) => {
                              return isOverlap({
                                time,
                                currentTime: [value.startTime, value.endTime],
                              })
                            }
                          )
                          if (hasConflicts) {
                            return Promise.reject(
                              'Jam mulai dan jam selesai tidak boleh beririsan dengan jam sesi lain.'
                            )
                          }

                          return Promise.resolve()
                        },
                      },
                    ]}
                  >
                    <DatePicker.RangePicker
                      disabled={hasInstructorSelectionStarted}
                      format="HH:mm"
                      picker="time"
                      placeholder={['Mulai', 'Selesai']}
                      allowClear={false}
                      suffixIcon={timezone.toLocaleUpperCase()}
                      style={{ width: '30%' }}
                      onChange={() => {
                        form?.validateFields()
                      }}
                      onCalendarChange={(value, _, info) => {
                        if (info.range === 'start') {
                          setStartTime((arr) => {
                            const m = [...arr]
                            if (!value?.[0]) return m
                            m[index] = value?.[0]
                            return m
                          })
                        }
                      }}
                      disabledTime={(_, type) => {
                        const filteredUnavailableTime = unavailableTime?.filter(
                          (_, idx) => idx !== index
                        )
                        const disabledTimeSpan = generateDisabledTimeSpan(
                          filteredUnavailableTime,
                          type,
                          startTime[index]
                        )
                        const disabledHours = Object.entries(
                          disabledTimeSpan
                        ).reduce((total, [hour, minutes]) => {
                          if (minutes?.length === 60) {
                            return [...total, +hour]
                          }
                          return total
                        }, [] as number[])

                        return {
                          disabledHours: () => disabledHours,
                          disabledMinutes: (h) => {
                            return disabledTimeSpan[h]
                          },
                        }
                      }}
                    />
                  </Form.Item>
                  {fields.length > 1 ? (
                    <Button
                      icon={<Icons.MinusCircleOutlined />}
                      className="ml-1"
                      disabled={hasInstructorSelectionStarted}
                      danger
                      size="small"
                      type="text"
                      onClick={() => {
                        remove(field.name)
                        form?.validateFields()
                      }}
                    />
                  ) : null}
                </Form.Item>
              )
            })}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                icon={<Icons.PlusOutlined />}
                disabled={
                  hasInstructorSelectionStarted ||
                  sessionValues.some((v) => !Boolean(v))
                }
              >
                Tambah sesi
              </Button>
              <Form.ErrorList errors={errors} />
            </Form.Item>
          </>
        )
      }}
    </Form.List>
  )
}
