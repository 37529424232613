import { Icons } from '@pankod/refine-antd'
import { ResourceProps } from '@pankod/refine-core'

import { BatchList } from './batch/list'
import { BatchShow } from './batch/show'
import { LPTKList } from './lptk/list'
import { LPTKShow } from './lptk/show'
import { MataKuliahList } from './mata-kuliah/list'
import { LPTKRolesManagementList } from './lptk-roles/list'
import { SimpkbConfirmationDetail } from './batch/simpkb-sync-detail/confirmation-detail'

export const basicPpgManagementResource: ResourceProps[] = [
  {
    name: 'basic-ppg-management',
    icon: <Icons.SettingOutlined />,
    options: {
      label: 'Basic PPG Management',
      route: 'basic-ppg-management',
    },
  },
  {
    name: 'batch-ppg-management',
    parentName: 'basic-ppg-management',
    options: {
      label: 'Angkatan',
      route: 'batch-ppg-management',
    },
    list: BatchList,
    show: BatchShow,
  },
  {
    name: 'lptk-ppg-management',
    parentName: 'basic-ppg-management',
    options: {
      label: 'LPTK',
      route: 'lptk-ppg-management',
    },
    list: LPTKList,
    show: LPTKShow,
  },
  {
    name: 'mata-kuliah-ppg-management',
    parentName: 'basic-ppg-management',
    options: {
      label: 'Mata Kuliah',
      route: 'mata-kuliah-ppg-management',
    },
    list: MataKuliahList,
  },
  {
    name: 'ppg-lptk-role-management',
    parentName: 'basic-ppg-management',
    options: {
      label: 'LPTK Role Management',
      route: 'ppg-lptk-role-management',
    },
    list: LPTKRolesManagementList,
  },
  {
    name: 'ppg-lptk-simpkb-sync-confirmation-detail',
    parentName: 'basic-ppg-management',
    options: {
      label: 'LPTK - SIMPKB Sync Confirmation Detail',
      route: 'ppg-lptk-simpkb-sync-confirmation-detail',
      hide: true,
    },
    show: SimpkbConfirmationDetail,
  },
]
