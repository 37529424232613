import { Form, FormInstance, Input } from '@pankod/refine-antd'
import React from 'react'

type EmailVerificationProps = {
  programId?: string
  form: FormInstance
}
const EmailVerification = ({ form }: EmailVerificationProps) => {
  return (
    <Form form={form} layout="vertical">
      <Form.Item
        name="email"
        required
        rules={[
          { type: 'email', message: 'Email tidak valid' },
          { required: true, message: 'Email tidak boleh kosong' },
        ]}
        label="Masukkan email"
      >
        <Input autoComplete="off" />
      </Form.Item>
    </Form>
  )
}

export default EmailVerification
