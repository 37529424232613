import { Table } from '@pankod/refine-antd'
import React from 'react'

import NotificationCollapsibleMessage from '@components/NotificationCollapsibleMessage'

type ERROR_DIGITS_MAP = {
  [key: string]: string
}

type SERVICE_CODE_MAP = {
  [key: string]: {
    name: string
    method: ERROR_DIGITS_MAP
  }
}

type MESSAGE_CODE_TEMPLATE_MAP = {
  [key: string]: {
    [key: string]: string
  }
}

type FIELD_HANDLER_TYPE = {
  [key: string]: {
    [key: string]: (field: string) => React.ReactNode
  }
}

type TranslateOutput = {
  systemMessage: string
  userMessage: string
  isValidCode: boolean
}

// CMS FEATURE CODE
const FEATURE_CODE: ERROR_DIGITS_MAP = {
  UNKNOWN: 'UNKNOWN',
  '01': 'PPG',
  '02': 'PP',
  '03': 'PGP',
  '04': 'WEBINAR',
  '05': 'Common',
  '06': 'PGP Dasus',
}

// SERVICE CODE
const SERVICE_CODE: SERVICE_CODE_MAP = {
  UNKNOWN: {
    name: 'UNKNOWN',
    method: {
      '00': 'UNKNOWN',
    },
  },
  '01': {
    name: 'AKTOR_LPTK',
    method: {
      '01': 'CREATE',
      '02': 'DELETE',
      '03': 'LIST',
      '04': 'UPDATE',
    },
  },
  '02': {
    name: 'ANGKATAN',
    method: {
      '01': 'CREATE',
      '02': 'GET',
      '03': 'LIST_BY_LPTK_ID',
      '04': 'LIST',
      '05': 'UPDATE',
      '06': 'update_gelombang',
    },
  },
  '03': {
    name: 'ANGKATAN_PESERTA',
    method: {
      '01': 'IMPORT',
      '02': 'LIST',
      '03': 'REMOVE',
    },
  },
  '04': {
    name: 'ANGKATAN_ROMBEL_PESERTA',
    method: {
      '01': 'ASSIGN',
      '02': 'LIST_AVAILABLE',
      '03': 'LIST_ROMBEL_PESERTA',
      '04': 'UNASSIGN',
    },
  },
  '05': {
    name: 'ANGKATAN_SEMESTER',
    method: {
      '01': 'ASSIGN_LPTK',
      '02': 'TRIGGER_SIS_IMPORT',
      '03': 'GET',
      '04': 'LIST_LPTK',
      '05': 'LIST_UNASSIGNED_MATKUL',
      '06': 'UNASSIGN_LPTK',
      '07': 'UPDATE',
    },
  },
  '06': {
    name: 'CLASS_ADMIN',
    method: {
      '01': 'CREATE',
      '02': 'GET',
      '03': 'LIST',
      '04': 'UPDATE',
    },
  },
  '07': {
    name: 'COURSE_ASSIGNMENT',
    method: {
      '01': 'ASSIGN_LPTK',
      '02': 'FINALIZE',
      '03': 'GET',
      '04': 'LIST_LPTK',
      '05': 'LIST_UNASSIGNED_MATKUL',
      '06': 'UNASSIGN_LPTK',
      '07': 'UPDATE',
    },
  },
  '08': {
    name: 'LECTURER',
    method: {
      '01': 'ASSIGN_LPTK',
      '02': 'FINALIZE',
      '03': 'GET',
      '04': 'LIST_LPTK',
      '05': 'LIST_UNASSIGNED_MATKUL',
      '06': 'UNASSIGN_LPTK',
      '07': 'UPDATE',
    },
  },
  '09': {
    name: 'LPTK',
    method: {
      '01': 'ASSIGN_ADMIN',
      '02': 'CREATE',
      '03': 'DELETE',
      '04': 'GET',
      '05': 'LIST_ADMIN',
      '06': 'LIST',
      '07': 'LIST_STUDENT',
      '08': 'LIST_VACANT_ADMIN',
      '09': 'UNASSIGN_ADMIN',
    },
  },
  '10': {
    name: 'MATKUL',
    method: {
      '01': 'ASSIGN_ADMIN',
      '02': 'CREATE',
      '03': 'DELETE',
      '04': 'GET',
      '05': 'LIST_ADMIN',
      '06': 'list_blueprint_options',
      '07': 'LIST_STUDENT',
      '08': 'LIST_VACANT_ADMIN',
      '09': 'UNASSIGN_ADMIN',
    },
  },
  '11': {
    name: 'PESERTA',
    method: {
      '01': 'ENTITY',
      '02': 'EXPORT',
      '03': 'IMPORT',
    },
  },
  '12': {
    name: 'ROMBEL',
    method: {
      '01': 'CREATE',
      '02': 'DELETE',
      '03': 'GET',
      '04': 'LIST_ANGKATAN_BY_LPTK',
      '05': 'UPDATE',
    },
  },
  '13': {
    name: 'SCHOOL',
    method: {
      '01': 'CREATE',
      '02': 'DELETE',
      '03': 'GET',
      '04': 'LIST',
      '05': 'UPDATE',
    },
  },
  '14': {
    name: 'SYNC_PROGRESS',
    method: {
      '01': 'POPULATE_DATA',
      '02': 'PROCESS',
      '03': 'SIS_IMPORT',
      '04': 'UPDATE_STATUS',
    },
  },
  '15': {
    name: 'TUTOR',
    method: {
      '01': 'CREATE',
      '02': 'GET',
      '03': 'LIST',
      '04': 'UPDATE',
    },
  },
  '16': {
    name: 'ANGKATAN_SIMPKB',
    method: {
      '01': 'init_sync',
      '02': 'list_sync',
      '03': 'trigger_sync',
      '04': 'get_sync_detail',
    },
  },
  '17': {
    name: 'WEBINAR_INSTRUCTOR',
    method: {
      '01': 'CREATE',
    },
  },
  '18': {
    name: 'WEBINAR_INSTRUCTOR_SCHEDULE',
    method: {
      '01': 'UPDATE',
    },
  },
  '19': {
    name: 'PPG_VALIDATOR',
    method: {
      '01': 'base.go',
    },
  },
  '20': {
    name: 'CANVAS_WORKER',
    method: {
      '01': 'base.go',
    },
  },
  '21': {
    name: 'PPG_INSTRUCTOR',
    method: {
      '01': 'create',
      '02': 'get',
      '03': 'update',
    },
  },
  '22': {
    name: 'PPG_DASUS',
    method: {
      '01': 'create_bahan_ajar',
      '02': 'create_template_course',
      '03': 'finalize_program',
      '04': 'get_bahan_ajar',
      '05': 'get_penilaian_coaching_template',
      '06': 'get_program_template_data',
      '07': 'get_program',
      '08': 'get_template_course',
      '09': 'list_facilitators',
      '10': 'list_program_local',
      '11': 'list_programs',
      '12': 'put_coruse_ptm_fi',
      '13': 'put_courses',
      '14': 'put_facilitators',
      '15': 'sync_program',
      '16': 'upsert_penilaian_coaching_template',
      '17': 'upsert_program_template_data',
    },
  },
  '24': {
    name: 'PROGRAM_LOCAL',
    method: {
      '01': 'update',
    },
  },
}

type RenderTableProps<T = any> = {
  dataSource: T
  columns: Array<{ title: string; dataIndex: string }>
  rowKey: string
}
const RenderTable = <T extends readonly object[]>({
  columns,
  dataSource,
  rowKey,
}: RenderTableProps<T>) => (
  <Table
    rowKey={rowKey}
    size="small"
    pagination={false}
    scroll={{ y: 150 }}
    dataSource={dataSource}
    bordered
    columns={columns}
  />
)

const FIELD_HANDLER: FIELD_HANDLER_TYPE = {
  '2401': {
    '01': (field) => {
      try {
        const fieldArray: { id: string; email: string }[] = JSON.parse(
          String(field)
        )
        return (
          <RenderTable
            rowKey={'id'}
            dataSource={fieldArray}
            columns={[
              { title: 'User ID', dataIndex: 'id' },
              { title: 'Email', dataIndex: 'email' },
            ]}
          />
        )
      } catch (e) {
        console.error(e)
        return null
      }
    },
    '02': (field) => {
      try {
        const fieldArray: { id: string; name: string }[] = JSON.parse(
          String(field)
        )
        return (
          <RenderTable
            rowKey={'id'}
            dataSource={fieldArray}
            columns={[
              { title: 'ID Kelas', dataIndex: 'id' },
              { title: 'Nama Kelas', dataIndex: 'name' },
            ]}
          />
        )
      } catch (e) {
        console.error(e)
        return null
      }
    },
  },
}

// because service code + method is unique, so the feature code is ignored
// map of service code + method + XX last digits to indicate string templates
const FALLBACK_MESSAGE =
  'Terjadi kesalahan dalam sistem kami (unknown error code)'
const MESSAGE_CODE_TEMPLATE: MESSAGE_CODE_TEMPLATE_MAP = {
  '1001': {
    '01': 'util map error on constructBulkCreateParam',
    '02': 'data contains invalid lptk id',
  },
  '1005': {
    '01': 'duplicate data found',
    '02': 'Tidak dapat mengupdate data mapping MK karena sudah di sync ke angkatan',
    '03': 'batch id %id tidak dapat ditambah karena sudah final',
    '04': 'batch id %id tidak dapat diupdate karena sudah final',
  },
  '1006': { '01': 'Mata kuliah tidak ditemukan' },
  '1102': { '01': 'angkatan_id tidak valid' },
  '1103': {
    '01': 'Peserta ID empty',
    '02': 'Peserta ID invalid',
    '03': 'LPTK ID empty',
    '04': 'LPTK ID invalid',
    '05': 'Resign date invalid',
    '06': 'Resign file invalid',
  },
  '1201': {
    '01': 'tipe kelompok belajar tidak valid',
    '02': 'angkatan atau lptk id tidak valid',
    '03': 'lptk semester already finalized',
  },
  '1202': {
    '01': 'operation failed successfully',
    '02': 'Mata kuliah telah diassign pada kelompok belajar ini',
    '03': 'Kelompok belajar tidak ditemukan',
    '04': 'Angkatan ID tidak valid',
    '05': 'LPTK ID tidak valid',
    '06': 'Semester 1 sudah final',
    '07': 'Semester 2 sudah final',
    '08': 'Tidak dapat menghapus rombel yang memiliki riwayat peserta / mata kuliah',
  },
  '1203': {
    '01': 'angkatan atau lptk id tidak valid',
    '02': 'rombel id tidak valid',
  },
  '1204': { '01': 'semester angkatan tidak ditemukan' },
  '1205': {
    '01': 'angkatan atau lptk id tidak valid',
    '02': 'lptk semester already finalized',
    '03': 'Kelompok belajar tidak ditemukan',
    '04': 'Angkatan ID tidak valid',
    '05': 'LPTK ID tidak valid',
  },
  '1301': {
    '01': 'lptk id tidak valid',
    '02': 'LPTK ID tidak boleh kosong',
    '03': 'city id tidak valid',
    '04': 'province id tidak valid',
  },
  '1302': { '01': 'school tidak ditemukan' },
  '1303': { '01': 'ppg school tidak ditemukan' },
  '1305': {
    '01': 'name tidak boleh kosong',
    '02': 'address tidak boleh kosong',
    '03': 'jenjang tidak boleh kosong',
    '04': 'city id tidak boleh kosong',
    '05': 'province id tidak boleh kosong',
    '06': 'city id tidak valid',
    '07': 'province id tidak valid',
    '08': 'school tidak ditemukan',
  },
  '1501': {
    '01': 'LPTK ID tidak boleh kosong',
    '02': 'Data tidak boleh kosong',
    '03': 'error on index %d, detect email duplicated on another row',
    '04': 'email tidak boleh kosong',
    '05': 'name tidak boleh kosong',
    '06': 'phone tidak boleh kosong',
    '07': 'studyField tidak boleh kosong',
    '08': 'lptk id tidak valid',
    '09': 'email, ppg_lptk_id already exist',
  },
  '1502': { '01': 'ppg tutor tidak ditemukan' },
  '1504': {
    '10': 'kesalahan pada csv baris %d, email tidak valid',
    '01': 'email tidak boleh kosong',
    '02': 'name tidak boleh kosong',
    '03': 'phone tidak boleh kosong',
    '04': 'studyField tidak boleh kosong',
    '05': 'study field tidak valid',
    '06': 'email not registered',
    '07': 'tutor tidak ditemukan',
    '08': 'email, ppg_lptk_id already exist',
    '09': 'invalid lptk id or school id',
  },
  '1601': {
    '01': 'sudah ada sinkronisasi yang berjalan',
    '02': 'angkatan tidak ditemukan',
    '03': 'gelombang pada angkatan tidak boleh kosong',
    '04': 'tahun pada angkatan tidak boleh kosong',
    '05': 'tahun dan gelombang pada angkatan tidak/belum memiliki mahasiswa',
  },
  '1602': { '01': 'angkatan tidak ditemukan' },
  '1603': {
    '01': 'simpkb queue tidak ditemukan',
    '02': 'cron triggerer error bad request',
    '03': 'Status not Done Tagging',
  },
  '1604': { '01': 'simpkb queue tidak ditemukan' },
  '1701': {
    '01': 'Instruktur telah memilih sesi webinar pada webinar kelas group %failedUnassignEligibleModules',
    '02': 'Modul %failedUnassignEligibleModules tidak valid',
    '03': 'User dengan email %email tidak ditemukan',
    '04': 'Email instruktur tidak valid',
  },
  '1801': {
    '01': 'Instruktur webinar tidak ditemukan',
    '02': 'Anda tidak memiliki akses ke webinar',
    '03': 'Link laporan tidak dapat diubah sebelum webinar berakhir',
    '04': 'Link webinar tidak dapat diubah setelah webinar berakhir',
    '05': 'Link pertanyaan tidak dapat diubah setelah webinar berakhir',
    '06': 'Link rekaman YouTube tidak valid',
  },
  '1901': {
    '01': '',
    '02': 'Perubahan Semester %x tidak dapat dilakukan pada diluar tgl %tgl_awal, &tgl_akhir',
  },
  '2001': { '01': 'Last Canvas Worker scaling is still in progress' },
  '2101': {
    '10': 'lptk id atau school id tidak valid',
    '11': 'kesalahan pada csv baris %d, email tidak valid',
    '12': 'Category Tidak boleh kosong',
    '01': 'LPTK ID tidak boleh kosong',
    '02': 'Data tidak boleh kosong',
    '03': 'instructor email is duplicated',
    '04': 'name tidak boleh kosong',
    '05': 'email tidak boleh kosong',
    '06': 'phone tidak boleh kosong',
    '07': 'study field tidak boleh kosong',
    '08': 'study field tidak valid',
    '09': 'email, ppg_lptk_id sudah ada',
  },
  '2102': { '01': 'ppg lecturer tidak ditemukan' },
  '2103': {
    '01': 'email tidak terdaftar',
    '02': 'name tidak boleh kosong',
    '03': 'phone tidak boleh kosong',
    '04': 'study field tidak boleh kosong',
    '05': 'study field tidak valid',
    '06': 'instructor tidak ditemukan',
    '07': 'email, ppg_lptk_id already exist',
    '08': 'category tidak boleh kosong',
  },
  '2201': { '01': 'program tidak ditemukan' },
  '2202': { '01': 'program tidak ditemukan' },
  '2203': { '01': '' },
  '2204': { '01': 'program tidak ditemukan' },
  '2205': { '01': '' },
  '2206': { '01': '' },
  '2207': { '01': '' },
  '2208': { '01': 'program tidak ditemukan' },
  '2209': { '01': '' },
  '2210': { '01': '' },
  '2211': { '01': '' },
  '2212': { '01': '', '02': 'invalid ptm index by name' },
  '2213': { '01': 'program lokal tidak ditemukan' },
  '2214': { '01': 'data fasilitator %s tidak tersedia' },
  '2215': { '01': 'program tidak ditemukan' },
  '2216': { '01': '' },
  '2217': { '01': '' },
  '0101': {
    '01': 'kolom nama tidak boleh kosong',
    '02': 'kolom email tidak boleh kosong',
    '03': 'input email bukan belajar.id',
    '04': 'input LPTK tidak valid / ditemukan dalam dataset',
  },
  '0102': { '01': 'input aktor tidak valid / ditemukan dalam dataset' },
  '0104': {
    '01': 'input aktor tidak valid / ditemukan dalam dataset',
    '02': 'email sudah pernah terdaftar',
  },
  '0201': {
    '01': 'kolom nama tidak boleh kosong',
    '02': 'tanggal mulai semester pertama tidak boleh kosong',
    '03': 'tanggal akhir semester pertama tidak boleh kosong',
    '04': 'tangga mulai semester kedua tidak boleh kosong',
    '05': 'tanggal akhir semester kedua tidak boleh kosong',
  },
  '0205': {
    '01': 'kolom nama tidak boleh kosong',
    '02': 'tangga mulai semester kedua tidak boleh kosong',
    '03': 'tanggal akhir semester pertama tidak boleh kosong',
    '04': 'tangga mulai semester kedua tidak boleh kosong',
    '05': 'tanggal akhir semester kedua tidak boleh kosong',
  },
  '0206': {
    '01': 'angkatan tidak ditemukan',
    '02': 'tahun sudah ada',
    '03': 'gelombang sudah ada',
    '04': 'tahun harus positif',
    '05': 'gelombang harus positif',
    '06': 'id harus positif',
  },
  '0301': {
    '10': "'status' %status pada index %index - tidak valid",
    '11': 'Data Angkatan Tidak Ditemukan',
    '01': 'Data participant tidak boleh kosong',
    '02': 'kolom bidang study (Study Field) tidak valid',
    '03': 'id LPTK %id - belum terdaftar di angkatan ini',
    '04': "'Email' %email - belum terdaftar di PMM",
    '05': "'name' baris %index - tidak boleh kosong",
    '06': "'phone' pada baris %index - tidak boleh kosong",
    '07': "'email' baris %index - tidak boleh kosong",
    '08': "'LPTK id' baris %index - tidak boleh kosong",
    '09': "'Participant email' %email tidak boleh duplikat",
  },
  '0303': {
    '01': 'Hapus peserta angkatan, gagal  - Data tidak ditemukan',
    '02': 'Unassign peserta gagal - peserta masih terdaftar pada study group',
  },
  '0401': {
    '01': 'angkatan atau lptk id tidak valid',
    '02': 'Semester LPTK sudah final',
    '03': 'rombongan belajar tidak valid',
    '04': 'angkatan dan rombongan belajar tidak cocok',
    '05': 'peserta sudah ditempatkan pada rombongan belajar dengan tipe sama',
    '06': 'angkatan dan peserta tidak cocok',
  },
  '0402': {
    '01': 'Angkatan atau LPTK tidak ditemukan',
    '02': 'Rombel tidak ditemukan',
  },
  '0403': {
    '01': 'angkatan tidak ditemukan',
    '02': 'lptk batch tidak ditemukan',
    '03': 'rombel tidak ditemukan',
  },
  '0404': {
    '01': 'angkatan tidak ditemukan',
    '02': 'lptk batch tidak ditemukan',
    '03': 'rombel tidak ditemukan',
    '04': 'participant tidak ditemukan',
  },
  '0501': { '01': 'Angkatan tidak ditemukan', '02': 'Lptk tidak ditemukan' },
  '0502': {
    '01': 'Data tidak ditemukan atau sudah final',
    '02': 'Tidak bisa melakukan sis import',
    '03': 'Isi semua jadwal terlebih dahulu',
  },
  '0503': { '01': 'semester angkatan tidak ditemukan' },
  '0504': { '01': 'Angkatan tidak ditemukan' },
  '0505': { '01': 'Rombel tidak ditemukan' },
  '0506': {
    '01': 'Angkatan Semester tidak ditemukan',
    '02': 'Angkatan Semester sudah ada',
  },
  '0507': {
    '10': 'uas end date semester',
    '11': 'penilaian akhir start date semester',
    '12': 'penilaian akhir end date semester',
    '13': 'uts start date cannot after than end date',
    '14': 'uas start date cannot after than end date',
    '15': 'penilaian akhir start date cannot after than end date',
    '01': 'semester angkatan tidak ditemukan',
    '02': 'semester 1 sudah final',
    '03': 'semester 2 sudah final',
    '04': 'semester angkatan tidak ditemukan',
    '05': 'semester angkatan tidak ditemukan',
    '06': 'start date semester',
    '07': 'uts start date semester',
    '08': 'uts end date semester',
    '09': 'uas start date semester',
  },
  '0508': {
    '01': 'Tidak dapat menggunakan label nilai yang sama',
    '02': 'Urutan nilai minimum tidak sesuai',
    '03': 'Batas nilai lulus tidak sesuai',
    '04': 'Angkatan LPTK tidak ditemukan',
  },
  '0509': { '01': 'Angkatan LPTK tidak ditemukan' },
  '0601': {
    '10': 'invalid lptk id or school id',
    '11': 'kesalahan pada csv baris %d, email tidak valid',
    '01': 'LPTK ID tidak boleh kosong',
    '02': 'Data tidak boleh kosong',
    '03': 'class admin email is duplicated on index %d',
    '04': "email can't be empty on index %d",
    '05': "name can't be empty on index %d",
    '06': "phone can't be empty on index %d",
    '07': "studyField can't be empty on index %d",
    '08': 'invalid studyField on index %d',
    '09': 'email, ppg_lptk_id already exist',
  },
  '0602': { '01': 'ppg lecturer not found' },
  '0604': {
    '01': "email can't be empty",
    '02': "name can't be empty",
    '03': "phone can't be empty",
    '04': "studyField can't be empty",
    '05': 'invalid study field',
    '06': 'email not registered',
    '07': 'dosen not found',
    '08': 'email, ppg_lptk_id already exist',
  },
  '0701': {
    '01': 'course assignment not found',
    '02': 'Cannot assign tutor to non PPL course',
    '03': 'class admin not found',
    '04': 'lecturer not found',
    '05': 'tutor not found',
    '06': 'course assignment tidak ditemukan',
    '07': 'instruktur tidak ditemukan',
  },
  '0706': {
    '01': 'semester angkatan tidak ditemukan',
    '02': 'semester 1 already final',
    '03': 'semester 2 already final',
  },
  '0702': {
    '01': 'Mata kuliah sudah diambil pada semester lain',
    '02': 'semester angkatan tidak ditemukan',
    '03': 'semester %d already final',
    '04': 'matkul angkatan tidak ditemukan',
    '05': 'rombel dan matkul gagal divalidasi',
    '06': 'semester course tidak valid',
    '07': 'rombel dan matkul tidak cocok',
    '08': 'lptk id tidak valid',
  },
  '0703': { '01': 'course assignment tidak ditemukan' },
  '0704': { '01': 'semester angkatan tidak ditemukan' },
  '0705': { '01': 'semester angkatan tidak ditemukan' },
  '0801': {
    '10': 'lptk id atau school id tidak valid',
    '11': 'kesalahan pada csv baris %d, email tidak valid',
    '01': 'LPTK ID tidak boleh kosong',
    '02': 'Data tidak boleh kosong',
    '03': 'lecture email is duplicated',
    '04': 'name tidak boleh kosong',
    '05': 'email tidak boleh kosong',
    '06': 'phone tidak boleh kosong',
    '07': 'study field tidak boleh kosong',
    '08': 'study field tidak valid',
    '09': 'email, ppg_lptk_id sudah ada',
  },
  '0802': { '01': 'ppg lecturer tidak ditemukan' },
  '0804': {
    '01': 'email tidak terdaftar',
    '02': 'name tidak boleh kosong',
    '03': 'phone tidak boleh kosong',
    '04': 'study field tidak boleh kosong',
    '05': 'study field tidak valid',
    '06': 'dosen tidak ditemukan',
    '07': 'email, ppg_lptk_id already exist',
  },
  '0901': {
    '01': 'Admin LPTK not found',
    '02': 'User admin with email %s not found',
    '03': 'User admin with email %s already assigned to LPTK',
  },
  '0902': { '01': 'name tidak boleh kosong' },
  '0903': {
    '01': 'LPTK already have admin',
    '02': 'LPTK already have resource',
    '03': 'LPTK tidak ditemukan',
  },
  '0904': { '01': 'PPG LPTK tidak ditemukan' },
  '0909': { '01': 'User not admin-lptk', '02': 'User Admin tidak ditemukan' },
  '0910': { '01': 'LPTK tidak ditemukan' },
  '2401': {
    '01': 'Terdapat peserta yang belum masuk ke dalam kelas',
    '02': 'Terdapat kelas yang belum memiliki asisten/instruktur',
  },
}

export const translateErrorCode = (
  errorCode: string = '',
  info: string | null = null,
  field: string | null = null
): TranslateOutput => {
  if (!errorCode) {
    return {
      systemMessage: 'ERR: NO_ERR_CODE_SPECIFIED',
      userMessage: FALLBACK_MESSAGE,
      isValidCode: false,
    }
  }

  const featureCode = errorCode.slice(0, 2) || 'UNKNOWN'
  const serviceCode = errorCode.slice(2, 4) || 'UNKNOWN'
  const serviceMethodCode = errorCode.slice(4, 6) || 'UNKNOWN'
  const messageCode = errorCode.slice(6, 8) || 'UNKNOWN'

  const featureString = FEATURE_CODE[featureCode] || 'UNKNOWN_FEATURE'
  const serviceString = SERVICE_CODE[serviceCode]?.name || 'UNKNOWN_SERVICE'
  const serviceMethodString =
    SERVICE_CODE[serviceCode]?.method[serviceMethodCode] || 'UNKNOWN_METHOD'

  let userMessage = MESSAGE_CODE_TEMPLATE[serviceCode + serviceMethodCode]
    ? MESSAGE_CODE_TEMPLATE[serviceCode + serviceMethodCode][messageCode] ||
      FALLBACK_MESSAGE
    : FALLBACK_MESSAGE

  const systemMessage = `${errorCode}: ${featureString}_${serviceString}_${serviceMethodString}`

  if (field && FIELD_HANDLER[serviceCode + serviceMethodCode]) {
    // @ts-expect-error
    userMessage = (
      <NotificationCollapsibleMessage
        message={userMessage}
        render={FIELD_HANDLER[serviceCode + serviceMethodCode][messageCode]?.(
          field
        )}
      />
    )
  }

  if (info) {
    try {
      const infoObj = JSON.parse(String(info))
      Object.keys(infoObj).forEach((key) => {
        userMessage = userMessage.replace(
          new RegExp(`%${key}`, 'g'),
          infoObj[key]
        )
      })
    } catch (e) {
      // @ts-ignore
      console.error(e)
    }
  }

  const foundInMap = userMessage !== FALLBACK_MESSAGE
  const message = {
    systemMessage,
    userMessage: userMessage,
    isValidCode: foundInMap,
  }

  return message
}
