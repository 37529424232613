export const BASE_PATH = process.env.BASE_PATH
export const ORIGIN_URL = process.env.ORIGIN_URL

export const SESSION = { USER_SESSION: 'userSession' } as const

export const IMAGE_MIME_LIST = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/svg+xml',
  'image/gif',
]

export const PDF_MIME_LIST = ['application/pdf', 'application/x-pdf']
