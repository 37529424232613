import { Show } from '@pankod/refine-antd'
import {
  IResourceComponentsProps,
  useCan,
  useCreate,
  useCustomMutation,
  useDelete,
  useInvalidate,
  useNavigation,
  useOne,
  useResource,
} from '@pankod/refine-core'
import Head from 'next/head'
import React from 'react'
import { useRouter } from 'next/router'

import { NestedBreadcrumb } from '../components/NestedBreadcrumb'
import { TCommonError, TCommonResponse } from 'src/interfaces/common'
import {
  CertificateTemplate,
  CertificateTemplateVariables,
  CertificateTemplatePayload,
  UserVariable,
} from './types'
import FabricWrapper from './fabric'
import { showErrorNotification } from '@resources/angkatan-ppg-management/utils'

export const CertificateTemplateShow: React.FC<IResourceComponentsProps> = ({
  options,
}) => {
  const { id } = useResource()
  const { query } = useRouter()
  const { data: canAccessProgram } = useCan({
    action: 'show',
    resource: 'lms-program-management',
  })
  const { listUrl, showUrl } = useNavigation()
  const invalidate = useInvalidate()
  const programId = query.programId as string

  const { data: templateData, isFetching: templateFetching } = useOne<
    TCommonResponse<CertificateTemplate>
  >({
    id,
    resource: `programs/${programId}/certificate-templates`,
    dataProviderName: 'lms',
    queryOptions: {
      enabled: !!programId && !!id,
    },
  })

  const { data: variableData, isFetching: variableFetching } = useOne<
    TCommonResponse<CertificateTemplateVariables>
  >({
    id: 'variables',
    resource: `programs/${programId}/certificate-templates/${id}`,
    dataProviderName: 'lms',
    queryOptions: {
      enabled: !!programId && !!id,
    },
  })
  const { mutateAsync: doCreateVariable } = useCreate<
    {},
    TCommonError,
    UserVariable
  >()

  const { mutateAsync: doSave, isLoading: isSaving } = useCustomMutation()
  const { mutateAsync: doPublish, isLoading: isPublishing } = useCreate()
  const { mutateAsync: doDelete } = useDelete()

  const handleSaveCertificate = async (
    payload: CertificateTemplatePayload,
    shouldExport = false
  ) => {
    const { page, template, usedSystemVariables } = payload
    const { kapabilitas = 'instruktur' } = templateData?.data?.data || {}

    const formData = new FormData()
    formData.append('page', page)

    const svgBlob = new Blob([template], { type: 'image/svg+xml' })
    formData.append(
      'template',
      svgBlob,
      `${programId}_${id}_${kapabilitas}_${page}_template.svg`
    )

    if (shouldExport) {
      const url = URL.createObjectURL(svgBlob)
      const a = document.createElement('a')
      a.href = url
      a.download = `${programId}_${id}_${kapabilitas}_${page}_template.svg`
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
      URL.revokeObjectURL(url)
      return
    }

    usedSystemVariables.forEach((item) => {
      formData.append('usedSystemVariables', item)
    })

    return doSave({
      url: `${process.env.LMS_API_URL}/programs/${programId}/certificate-templates/${id}`,
      method: 'post',
      values: formData,
      config: {
        headers: {
          'content-type': 'multipart/form-data',
        },
      },
      successNotification: {
        message: 'Perubahan template berhasil disimpan',
        type: 'success',
        description: 'Sukses',
      },
      errorNotification: (error: any) =>
        showErrorNotification(
          error,
          'There was a uploading certificate template error'
        ),
    })
  }

  const handlePublishCertificate = async () => {
    return await doPublish(
      {
        resource: `programs/${programId}/certificate-templates/${id}/publish`,
        values: {},
        dataProviderName: 'lms',
        successNotification: {
          message: 'Template berhasil dipublish!',
          type: 'success',
          description: 'Sukses',
        },
      },
      {
        onSuccess: () => {
          invalidate({
            invalidates: ['detail'],
            dataProviderName: 'lms',
            id,
            resource: `programs/${programId}/certificate-templates`,
          })
        },
      }
    )
  }

  return (
    <>
      <Show
        title="Template Sertifikat"
        headerButtons={() => null}
        breadcrumb={
          <NestedBreadcrumb
            injectedItems={[
              ...(canAccessProgram?.can
                ? [
                    {
                      label: 'Program',
                      href: listUrl('lms-program-management'),
                    },
                  ]
                : []),
              {
                label: programId,
                href: canAccessProgram?.can
                  ? showUrl('lms-program-management', programId)
                  : listUrl('lms-program-local-management'),
              },
              {
                label: 'Template Sertifikat',
                href:
                  listUrl('certificate-templates') + `?programId=${programId}`,
              },
              {
                label: 'Show',
              },
            ]}
          />
        }
      >
        <Head>
          <title>LMS Admin | {options?.label}</title>
        </Head>

        <div>
          <FabricWrapper
            onPublish={handlePublishCertificate}
            onSave={handleSaveCertificate}
            onExport={(payload) => handleSaveCertificate(payload, true)}
            navigatorLoading={isSaving || isPublishing}
            onDeleteVariable={(customKey) =>
              doDelete(
                {
                  resource: `programs/${programId}/certificate-templates/${id}/variables`,
                  id: customKey,
                  dataProviderName: 'lms',
                  successNotification: {
                    message: 'Komponen custom variable berhasil dihapus',
                    type: 'success',
                    description: 'Sukses',
                  },
                },
                {
                  onSuccess: () => {
                    invalidate({
                      invalidates: ['all'],
                      dataProviderName: 'lms',
                      id: 'variables',
                      resource: `programs/${programId}/certificate-templates/${id}`,
                    })
                  },
                }
              )
            }
            onCreateVariable={(values) =>
              doCreateVariable(
                {
                  resource: `programs/${programId}/certificate-templates/${id}/variables`,
                  values,
                  dataProviderName: 'lms',
                  successNotification: {
                    message: 'Komponen custom variable berhasil ditambahkan',
                    type: 'success',
                    description: 'Sukses',
                  },
                },
                {
                  onSuccess: () => {
                    invalidate({
                      invalidates: ['detail'],
                      dataProviderName: 'lms',
                      id: 'variables',
                      resource: `programs/${programId}/certificate-templates/${id}`,
                    })
                  },
                }
              )
            }
            variables={variableData?.data?.data}
            templates={templateData?.data?.data}
            loading={templateFetching || variableFetching}
          />
        </div>
      </Show>
    </>
  )
}
