import {
  Button,
  Divider,
  Drawer,
  Empty,
  Form,
  Input,
  InputNumber,
  Space,
  Tabs,
  Tag,
  useForm,
} from '@pankod/refine-antd'
import React, { useCallback, useState } from 'react'

import { showErrorNotification } from '@resources/angkatan-ppg-management/utils'

export const usePembobotanPenilaianPPGConfigDrawer = () => {
  const [showId, setShowId] = useState<{
    show: boolean
    programId: string | number
  }>({ show: false, programId: '' })
  const onClose = useCallback(() => {
    setShowId((state) => ({
      ...state,
      show: false,
    }))
  }, [])

  return {
    show: (id: number | string) => {
      setShowId({ show: true, programId: id })
    },
    visible: !!showId.show,
    id: showId.programId,
    onClose,
  }
}

type PembobotanPenilaianPPGConfigDrawerProps = {
  visible: boolean
  id: number | string
  onClose: () => void
  isFinalized?: boolean
}

const PembobotanPenilaianPPGConfigDrawer = ({
  onClose,
  id,
  visible,
  isFinalized,
}: PembobotanPenilaianPPGConfigDrawerProps) => {
  const [currentTotalPercentage, setCurrentTotalPercentage] = useState(1)

  const { formProps, queryResult, onFinish, saveButtonProps } = useForm({
    resource: `program/${id}/pembobotan`,
    dataProviderName: 'lms',
    action: 'edit',
    queryOptions: {
      enabled: visible,
    },
    metaData: {
      httpMethod: 'post',
    },
    redirect: false,
    onMutationSuccess() {},
    successNotification: () => {
      return {
        message: 'Pembobotan berhasil disimpan',
        type: 'success',
        description: 'Sukses',
      }
    },
    errorNotification: (errorRes: any) =>
      showErrorNotification(
        errorRes,
        'Terjadi kesalahan pada sistem kami, silahkan coba beberapa saat lagi'
      ),
  })
  const formLabelMap: any = {
    lokaKarya: 'Loka Karya',
    pendampinganIndividu: 'Pendampingan Individu',
    presensi: 'Presensi',
  }

  const getFormValueSumPercentage = () => {
    const formValue = formProps.form?.getFieldsValue()

    let totalPercentage = 0
    Object.keys(formValue as any[any]).forEach((key: any) => {
      if (key === 'canvasFinalScore') {
        // @ts-ignore
        totalPercentage += Number(formValue[key])
      } else {
        formValue &&
          // @ts-ignore
          formValue[key].forEach((item: any) => {
            // item has key kehadiranPercentage ? them add to total percenage

            if (item.kehadiran?.percentage) {
              totalPercentage += Number(item.kehadiran?.percentage)
            }

            if (key !== 'presensi') {
              item.penilaians &&
                item.penilaians.forEach((penilaian: any) => {
                  totalPercentage += Number(penilaian.percentage)
                })
            } else {
              item.aktivitas &&
                item.aktivitas.forEach((penilaian: any) => {
                  totalPercentage += Number(penilaian.percentage)
                })
            }
          })
      }
    })

    setCurrentTotalPercentage(totalPercentage)
  }

  const generateUniqueKey = (pre: string) => {
    return `${pre}_${new Date().getTime()}`
  }

  React.useEffect(() => {
    if (queryResult?.isFetched) getFormValueSumPercentage()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryResult?.isFetched])

  return (
    <Drawer
      title="Pembobotan Penilaian PGP"
      visible={visible}
      onClose={onClose}
      destroyOnClose
      width="50%"
      extra={
        typeof isFinalized !== 'undefined' ? (
          <Space>
            Status:
            <Tag color={isFinalized ? 'processing' : 'default'}>
              {isFinalized ? 'FINALIZED' : 'DRAFT'}
            </Tag>
          </Space>
        ) : null
      }
    >
      <Tabs>
        <Tabs.TabPane
          tab="Pembobotan Penilaian PGP"
          key="pembobotanPenilaianPGP"
        >
          {queryResult?.data?.data ? (
            <Form
              {...formProps}
              layout="vertical"
              onFinish={(data: any) => {
                data.presensi?.map((item: any) => {
                  item.aktivitas = item.aktivitas.map((aktivitas: any) => {
                    delete aktivitas.nama

                    return aktivitas
                  })

                  return item
                })

                onFinish({
                  ...data,
                  lokaKarya: data.lokaKarya?.map(
                    ({ kehadiran, ...rest }: any) => ({
                      ...rest,
                      kehadiranPercentage: kehadiran.percentage,
                    })
                  ),
                })
              }}
              style={{ marginTop: 32 }}
              onBlur={getFormValueSumPercentage}
            >
              {queryResult &&
                Object.keys(queryResult?.data?.data || {}).map(
                  (key, keyIndex) => {
                    if (key === 'canvasFinalScore') {
                      return (
                        <div key={`${key}-canvas-final-score`} className="mt-8">
                          <Divider plain>
                            <h3>Canvas Course</h3>
                          </Divider>
                          <Space
                            className="w-full flex flex-row  items-center"
                            align="baseline"
                          >
                            <div className="font-extrabold w-[200px]">
                              Final Score (Grade)
                            </div>
                            <div className="ml-3">
                              <Form.Item name={'canvasFinalScore'}>
                                <InputNumber
                                  placeholder="Persentase penilaian"
                                  min={0}
                                  max={100}
                                  formatter={(value) => `${value}%`}
                                  parser={(value: any) =>
                                    value!.replace('%', '')
                                  }
                                />
                              </Form.Item>
                            </div>
                          </Space>
                        </div>
                      )
                    }

                    const deepClonedObjectPerKey = JSON.parse(
                      JSON.stringify(queryResult?.data?.data[key])
                    )

                    if (key !== 'presensi') {
                      return deepClonedObjectPerKey.map(
                        (item: any, itemIndex: number) => {
                          return (
                            <div
                              key={`${keyIndex}-${generateUniqueKey(key)}-${
                                item.id
                              }-${itemIndex})}`}
                              className="mt-8"
                            >
                              {itemIndex === 0 && (
                                <Divider plain>
                                  <h3>{formLabelMap[key]}</h3>
                                </Divider>
                              )}

                              <Form.Item name={[key, itemIndex, 'id']} hidden>
                                <Input />
                              </Form.Item>

                              <div className="w-full mb-4 font-extrabold">
                                {item.nama}
                              </div>

                              {key === 'lokaKarya' && (
                                <Space
                                  className="w-full flex flex-row  items-center"
                                  align="baseline"
                                >
                                  <p className="w-[210px]">
                                    {item.kehadiran.nama}
                                  </p>
                                  <Form.Item
                                    name={[
                                      key,
                                      itemIndex,
                                      'kehadiran',
                                      'percentage',
                                    ]}
                                  >
                                    <InputNumber
                                      placeholder="Persentase penilaian"
                                      min={0}
                                      max={100}
                                      formatter={(value) => `${value}%`}
                                      parser={(value: any) =>
                                        value!.replace('%', '')
                                      }
                                    />
                                  </Form.Item>
                                </Space>
                              )}

                              <Form.List
                                name={[key, itemIndex, 'penilaians']}
                                initialValue={item.penilaian.map(
                                  (formItem: any) => ({
                                    id: formItem.id,
                                    percentage: formItem.percentage,
                                  })
                                )}
                              >
                                {(fields) => {
                                  if (fields.length === 0) {
                                    return (
                                      <div className="ml-8">
                                        <p className="w-full">-</p>
                                      </div>
                                    )
                                  }

                                  return fields.map((field) => {
                                    return (
                                      <Space
                                        key={`${
                                          item.penilaian[field.name].id
                                        }-${generateUniqueKey(key)}-${
                                          field.key
                                        }`}
                                        className="w-full flex flex-row  items-center"
                                        align="baseline"
                                      >
                                        <p className="w-[200px]">
                                          {item.penilaian[field.name].nama}
                                        </p>

                                        <Form.Item
                                          name={[field.name, 'id']}
                                          hidden
                                        >
                                          <Input />
                                        </Form.Item>
                                        <Form.Item
                                          name={[field.name, 'percentage']}
                                        >
                                          <InputNumber
                                            placeholder="Persentase penilaian"
                                            min={0}
                                            max={100}
                                            formatter={(value) => `${value}%`}
                                            parser={(value: any) =>
                                              value!.replace('%', '')
                                            }
                                          />
                                        </Form.Item>
                                      </Space>
                                    )
                                  })
                                }}
                              </Form.List>
                            </div>
                          )
                        }
                      )
                    }
                    // to displasy name label
                    const tempObjectPerKey = JSON.parse(
                      JSON.stringify(deepClonedObjectPerKey)
                    )

                    return deepClonedObjectPerKey.map(
                      (item: any, itemIndex: number) => {
                        return (
                          <div
                            key={`${keyIndex}-${itemIndex}-${generateUniqueKey(
                              key
                            )}-${item.id}`}
                            className="mt-8"
                          >
                            {itemIndex === 0 && (
                              <Divider plain>
                                <h3>{formLabelMap[key]}</h3>
                              </Divider>
                            )}

                            <Form.Item name={[key, itemIndex, 'id']} hidden>
                              <Input />
                            </Form.Item>

                            <div className="w-full mb-4 font-extrabold">
                              {item.nama}
                            </div>

                            <Form.List
                              name={[key, itemIndex, 'aktivitas']}
                              initialValue={item.aktivitas.map(
                                (formItem: any) => {
                                  delete formItem.nama

                                  return {
                                    id: formItem.id,
                                    percentage: formItem.percentage,
                                  }
                                }
                              )}
                            >
                              {(fields, fieldIndex) => {
                                return fields.map((field) => {
                                  const itemLabel =
                                    tempObjectPerKey[itemIndex].aktivitas[
                                      field.name
                                    ].nama

                                  return (
                                    <Space
                                      key={`${fieldIndex}-${generateUniqueKey(
                                        key
                                      )}-${fieldIndex}-${field.key}`}
                                      className="w-full flex flex-row  items-center"
                                      align="baseline"
                                    >
                                      <p className="w-[200px]">{itemLabel}</p>
                                      <Form.Item
                                        name={[field.name, 'id']}
                                        hidden
                                      >
                                        <Input />
                                      </Form.Item>

                                      <Form.Item
                                        name={[field.name, 'percentage']}
                                      >
                                        <InputNumber
                                          placeholder="Persentase penilaian"
                                          min={0}
                                          max={100}
                                          formatter={(value) => `${value}%`}
                                          parser={(value: any) =>
                                            value!.replace('%', '')
                                          }
                                        />
                                      </Form.Item>
                                    </Space>
                                  )
                                })
                              }}
                            </Form.List>
                          </div>
                        )
                      }
                    )
                  }
                )}

              <div className="flex flex-row items-center">
                <p className="mt-8 ml-8 font-extrabold w-[200px]">
                  Total Persentase
                </p>

                <p className="mt-8 ml-8 font-extrabold">
                  {currentTotalPercentage}%
                </p>
              </div>

              {/* <Button
                block
                style={{ marginTop: 32 }}
                onClick={getFormValueSumPercentage}
              >
                Kalkulasi & Validasi total persentase
              </Button> */}

              <Button
                {...saveButtonProps}
                htmlType="submit"
                block
                style={{ marginTop: 32 }}
                disabled={
                  currentTotalPercentage > 100 || currentTotalPercentage <= 0
                }
                onClick={() => {}}
              >
                Simpan
              </Button>

              {(currentTotalPercentage > 100 ||
                currentTotalPercentage <= 0) && (
                <p className=" font-extrabold text-red-500">
                  Total persentase harus minimal 0% atau maksimum 100%
                </p>
              )}
            </Form>
          ) : (
            <Empty />
          )}
        </Tabs.TabPane>
      </Tabs>
    </Drawer>
  )
}

export default PembobotanPenilaianPPGConfigDrawer
