import { Button, Icons } from '@pankod/refine-antd'
import { ListButtonProps } from '@pankod/refine-antd/dist/components/buttons/list'
import { useRouterContext, useNavigation } from '@pankod/refine-core'
import React from 'react'

export const KelolaRombelButton = ({
  angkatanId,
  ...props
}: ListButtonProps & { angkatanId: number | string }) => {
  const { Link } = useRouterContext()
  const { listUrl: generateListUrl } = useNavigation()
  const listUrl = generateListUrl('rombel-ppg-management')
  return (
    <Link
      to={`${listUrl}?angkatanId=${angkatanId}`}
      replace={false}
      onClick={(e: React.PointerEvent<HTMLButtonElement>) => {
        if (props.onClick) {
          e.preventDefault()
          props.onClick(e)
        }
      }}
    >
      <Button {...props} icon={<Icons.PartitionOutlined />} />
    </Link>
  )
}
