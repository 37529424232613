const DEFAULT_HEIGHT = 'auto'
const DEFAULT_MAX_UPLOAD_IMAGE_HEIGHT = 700
const DEFAULT_MAX_UPLOAD_IMAGE_WIDTH = 700
const DEFAULT_TOOLBAR_ITEMS = [
  ['heading', 'bold', 'italic', 'strike'],
  ['hr', 'quote'],
  ['ul', 'ol', 'indent', 'outdent'],
  ['table', 'image', 'link'],
]
const DEFAULT_EDITOR_TYPE = 'wysiwyg'
const DEFAULT_PREVIEW_STYLE = 'vertical'
const DEFAULT_VALUE_TYPE = 'html'

export {
  DEFAULT_HEIGHT,
  DEFAULT_MAX_UPLOAD_IMAGE_HEIGHT,
  DEFAULT_MAX_UPLOAD_IMAGE_WIDTH,
  DEFAULT_TOOLBAR_ITEMS,
  DEFAULT_EDITOR_TYPE,
  DEFAULT_PREVIEW_STYLE,
  DEFAULT_VALUE_TYPE,
}
