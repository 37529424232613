import React from 'react'
import { AppProps } from 'next/app'
import { Refine, RefineProps } from '@pankod/refine-core'
import routerProvider from '@pankod/refine-nextjs-router'
import {
  notificationProvider,
  ReadyPage,
  ErrorComponent,
  Icons,
  Result,
  Button,
  ConfigProvider,
  Empty,
} from '@pankod/refine-antd'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { appWithTranslation, useTranslation } from 'next-i18next'
import nookies from 'nookies'
import { InstUISettingsProvider } from '@instructure/emotion'
import { generateInstanceCounterMap } from '@instructure/ui-react-utils'

import {
  Title,
  Header,
  Sider,
  Footer,
  Layout,
  OffLayoutArea,
} from '@components/layout'
import Homepage from '@components/pages/Homepage'
import Login from '@components/pages/Login'
/** Start of resource list */
import DashboardLoader from '@components/pages/DashboardLoader'
import { LMSAdminManagementList } from '@resources/lms-management/admin'
import {
  LMSEntityManagementList,
  LMSEntityManagementShow,
} from '@resources/lms-management/entity'
import {
  LMSProgramManagementList,
  LMSProgramManagementShow,
} from '@resources/lms-management/program'
import {
  LMSProgramLocalManagementList,
  LMSProgramLocalManagementShow,
} from '@resources/lms-management/program-lokal'
import { LMSClassManagementShow } from '@resources/lms-management/class'
import { LMSWebinarManagementShow } from '@resources/lms-management/webinar'
import FormCreate from '@resources/form-generator/create'
import FormList from '@resources/form-generator/list'
import FormEdit from '@resources/form-generator/edit'
/** End of resource list */
import dataProvider from 'src/dataProvider'
import { SESSION } from 'src/constant'
import { authProvider } from 'src/authProvider'
import { enforcer } from '../src/accessControl'
import '@wartek-id/design-tokens/build/guru-desktop/css/color.css'
import '@wartek-id/design-tokens/build/guru-desktop/css/font.css'
import '@wartek-id/design-tokens/build/guru-desktop/css/size.css'
import '@wartek-id/design-tokens/build/guru-desktop/css/elevation.css'
import 'styles/global.css'
import { UserSession } from 'types/user'
import useGetACL from 'src/hooks/useGetACL'
import { LOCAL_STORAGE_THEME_VAR, THEME } from 'src/helpers/theme'
import { FormContextProvider } from '@components/FormBuilder/formContext'
import {
  WebinarScheduleManagementList,
  WebinarEvidenceManagementList,
  WebinarClassScheduleShow,
} from '@resources/webinar-management'
import { ShowStudentsGradeReportList } from '@resources/lms-management/grade-report'
import { basicPpgManagementResource } from '@resources/basic-ppg-management/resource'
import {
  AngkatanPPGList,
  RombelList,
  RombelShow,
} from '@resources/angkatan-ppg-management'
import { lptkResource } from '@resources/basic-ppg-management/lptk-resource'
import { ErrorBoundary } from '@components/ErrorBoundary'
if (
  typeof window !== 'undefined' &&
  localStorage.getItem(LOCAL_STORAGE_THEME_VAR) === THEME.LIGHT
) {
  import('styles/theme-light.less')
} else {
  import('styles/theme-dark.less')
}
import 'styles/default.less'
import { opsToolkitManagementResource } from '@resources/ops-toolkit/resource'
import { dasusManagementResource } from '@resources/dasus-management/resource'
import {
  CertificateTemplateList,
  CertificateTemplateShow,
} from '@resources/lms-management/certificate-template'

const LMS_API_URL = process.env.LMS_API_URL
const MICROLEARNING_API_URL = process.env.MICROLEARNING_API_URL
const clientId = process.env.NEXT_PUBLIC_GOOGLE_ID

const reactQueryOptions: Exclude<
  RefineProps['options'],
  undefined
>['reactQuery'] = {
  clientConfig: {
    defaultOptions: {
      queries: {
        /**
         * Set default max query retry to 1 (globally),
         * lowering load time when error occured from the server.
         * Default was 3.
         */
        retry: 1,
      },
    },
  },
}

function MyApp({ Component, pageProps, router }: AppProps): JSX.Element {
  const [ACLData, loadACLState] = useGetACL()
  const [LMSRole, setLMSRole] = React.useState<string | null>(null)
  const userSession = nookies.get()[SESSION.USER_SESSION]
  const { t, i18n } = useTranslation()

  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  }

  if (loadACLState === 'idle' || loadACLState === 'loading') {
    return <DashboardLoader />
  }

  if (loadACLState === 'error') {
    return (
      <Result
        status="500"
        title="500"
        subTitle="Sorry, something went wrong. Please try again later."
        extra={
          <Button onClick={() => location.reload()} type="primary">
            Reload
          </Button>
        }
      />
    )
  }

  return (
    <InstUISettingsProvider instanceCounterMap={generateInstanceCounterMap()}>
      <GoogleOAuthProvider clientId={clientId}>
        <ConfigProvider
          renderEmpty={() => (
            <Empty
              className="!my-2"
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="Tidak ada data"
            />
          )}
        >
          <Refine
            i18nProvider={i18nProvider}
            routerProvider={routerProvider}
            notificationProvider={notificationProvider}
            DashboardPage={Homepage}
            ReadyPage={ReadyPage}
            catchAll={<ErrorComponent />}
            dataProvider={{
              /** Both data provider still provided for fallback */
              default: dataProvider(LMS_API_URL),
              lms: dataProvider(LMS_API_URL),
              microlearning: dataProvider(MICROLEARNING_API_URL),
            }}
            authProvider={authProvider}
            LoginPage={Login}
            options={{ reactQuery: reactQueryOptions, disableTelemetry: true }}
            accessControlProvider={{
              can: async ({ resource, action, params }) => {
                if (!userSession) {
                  return Promise.reject()
                }
                const { user }: UserSession = JSON.parse(userSession)
                setLMSRole(user.LMSRole)
                /**
                 * Groups returned here has a potential bug due to maximum only show 10 groups/roles (Guru Core Login API)
                 * See more: https://wartek.atlassian.net/wiki/spaces/MOL/pages/2192343075/CMS+untuk+banding+validasi+aksi+nyata#Consequences
                 * */
                const roles = [user.LMSRole]
                  .concat(user.groups.map(({ name }) => name))
                  .filter(Boolean)

                if (action === 'field') {
                  const can = await enforcer(ACLData).then((e) =>
                    e.enforce(roles, `${resource}/${params?.field}`, action)
                  )
                  return Promise.resolve({ can })
                }

                return Promise.resolve({
                  can:
                    resource === 'dashboard' ||
                    (await enforcer(ACLData).then((e) =>
                      e.enforce(roles, resource, action)
                    )),
                })
              },
            }}
            resources={[
              {
                name: 'form-generator',
                icon: <Icons.FormOutlined />,
                options: {
                  label: 'LTI Form Generator',
                  route: 'form-generator',
                },
                list: FormList,
                create: FormCreate,
                edit: FormEdit,
              },
              {
                name: 'LMS-management',
                icon: <Icons.SolutionOutlined />,
                options: {
                  label: 'LMS Management',
                  route: 'lms-management',
                },
              },
              {
                name: 'lms-admin-management',
                parentName: 'LMS-management',
                list: LMSAdminManagementList,
                options: {
                  label: 'Admin',
                  route: 'lms-admin-management',
                },
                canDelete: true,
              },
              {
                name: 'lms-entity-management',
                parentName: 'LMS-management',
                list: LMSEntityManagementList,
                show: LMSEntityManagementShow,
                options: {
                  label: 'Entitas',
                  route: 'lms-entity-management',
                },
                canDelete: true,
              },
              {
                name: 'lms-program-management',
                parentName: 'LMS-management',
                list: LMSProgramManagementList,
                show: LMSProgramManagementShow,
                options: {
                  label: 'Program',
                  route: 'lms-program-management',
                  include: [
                    '/lms-program-local-management',
                    '/lms-class-management',
                    '/lms-webinar-management',
                    '/certificate-templates',
                    ...(LMSRole === 'admin-entitas' ? ['/grade-report'] : []),
                  ],
                },
                canDelete: true,
              },
              {
                name: 'lms-program-local-management',
                parentName: 'LMS-management',
                list: LMSProgramLocalManagementList,
                show: LMSProgramLocalManagementShow,
                options: {
                  label: 'Program Lokal',
                  route: 'lms-program-local-management',
                  hide: true,
                },
                canDelete: true,
              },
              {
                name: 'certificate-templates',
                parentName: 'LMS-management',
                list: CertificateTemplateList,
                show: CertificateTemplateShow,
                options: {
                  label: 'Certificate Templates',
                  route: 'certificate-templates',
                  hide: true,
                },
                canDelete: true,
              },
              {
                name: 'lms-class-management',
                parentName: 'LMS-management',
                show: LMSClassManagementShow,
                options: {
                  label: 'Kelas',
                  route: 'lms-class-management',
                  hide: true,
                },
                canDelete: true,
              },
              {
                name: 'lms-webinar-management',
                parentName: 'LMS-management',
                show: LMSWebinarManagementShow,
                options: {
                  label: 'Webinar',
                  route: 'lms-webinar-management',
                  hide: true,
                },
                canDelete: true,
              },
              // FOR INSTRUCTOR
              {
                name: 'webinar-schedule-management',
                icon: <Icons.VideoCameraOutlined />,
                options: {
                  label: 'Daftar Webinar',
                  route: 'webinar-schedule-management',
                },
                list: WebinarScheduleManagementList,
                show: WebinarClassScheduleShow,
              },
              {
                name: 'webinar-schedule-list',
                icon: <Icons.UnorderedListOutlined />,
                options: {
                  label: 'Jadwal Webinar',
                  route: 'webinar-schedules',
                },
                list: WebinarEvidenceManagementList,
              },
              // Program Reporting
              {
                name: 'program-grades-report-list',
                parentName: 'LMS-management',
                options: {
                  label: 'Grade Report',
                  route: 'grade-report',
                  hide: LMSRole === 'admin-entitas',
                },
                list: ShowStudentsGradeReportList,
              },
              // Basic PPG Management (Batch & LPTK)
              ...basicPpgManagementResource,
              ...lptkResource,
              // Angkatan PPG Management,
              {
                name: 'angkatan-ppg-management',
                options: {
                  label: 'Angkatan',
                  route: 'angkatan-ppg',
                  include: ['/rombel-ppg'],
                },
                list: AngkatanPPGList,
              },
              {
                name: 'rombel-ppg-management',
                parentName: 'angkatan-ppg-management',
                options: {
                  label: 'Rombel',
                  route: 'rombel-ppg',
                  hide: true,
                },
                list: RombelList,
                show: RombelShow,
              },

              ...opsToolkitManagementResource,
              ...dasusManagementResource,
            ]}
            Title={Title}
            Header={Header}
            Sider={Sider}
            Footer={Footer}
            Layout={Layout}
            OffLayoutArea={OffLayoutArea}
          >
            <ErrorBoundary resetKeys={[router.asPath]}>
              <FormContextProvider>
                <Component {...pageProps} />
              </FormContextProvider>
            </ErrorBoundary>
          </Refine>
        </ConfigProvider>
      </GoogleOAuthProvider>
    </InstUISettingsProvider>
  )
}

export default appWithTranslation(MyApp)
