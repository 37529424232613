import { Icons } from '@pankod/refine-antd'
import { ResourceProps } from '@pankod/refine-core'

import { DasusProgramList } from './program-management/list'
import { DasusProgramShow } from './program-management/show'
import { DasusProgramLocalManagementList } from './program-local-management/list'
import { DasusProgramLocalManagementShow } from './program-local-management/show'
import { DasusClassManagementShow } from './class-management/show'

export const dasusManagementResource: ResourceProps[] = [
  {
    name: 'dasus-management',
    icon: <Icons.SettingOutlined />,
    options: {
      label: 'Dasus Management',
      route: 'dasus-management',
    },
  },
  {
    name: 'dasus-program-management',
    parentName: 'dasus-management',
    options: {
      label: 'Program',
      route: 'dasus-program-management',
      include: ['/dasus-program-local-management', '/dasus-class-management'],
    },
    list: DasusProgramList,
    show: DasusProgramShow,
  },

  {
    name: 'dasus-program-local-management',
    parentName: 'dasus-management',
    list: DasusProgramLocalManagementList,
    show: DasusProgramLocalManagementShow,
    options: {
      label: 'Program Local Dasus',
      route: 'dasus-program-local-management',
      hide: true,
    },
    canDelete: true,
  },
  {
    name: 'dasus-class-management',
    parentName: 'dasus-management',
    show: DasusClassManagementShow,
    options: {
      label: 'Kelas',
      route: 'dasus-class-management',
      hide: true,
    },
    canDelete: true,
  },
]
