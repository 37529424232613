import {
  Checkbox,
  Form,
  FormProps,
  Modal,
  ModalProps,
  Radio,
  Spin,
} from '@pankod/refine-antd'
import React from 'react'
import { useList } from '@pankod/refine-core'

import { AssignmentResponse } from '../types'
import { MAX_KELOMPOK_MURID_MEMBER_COUNT } from '../config'

type TModalFormProps = {
  modalProps: ModalProps
  formProps: FormProps
  mode: 'create' | 'edit'
  programName?: string
  onClose: () => void
  localProgramId: string | undefined
  classId: string | undefined
  assignMode: { type: string; currentMemberCount?: number }
}
export const ModalFormAssignMurid = ({
  modalProps,
  formProps,
  assignMode,
  onClose,
  localProgramId,
  classId,
}: TModalFormProps) => {
  const { data, isLoading } = useList<AssignmentResponse>({
    resource: `local-programs/${localProgramId}/classes/${classId}/kelompok-murid/assignment-list`,
    dataProviderName: 'lms',
    queryOptions: {
      enabled: modalProps.visible,
    },
  })
  const currentSelectedMurid = Form.useWatch('daftarMuridId', formProps.form)
  const hasReachMaxMurid =
    (currentSelectedMurid?.length || 0) +
      (assignMode.currentMemberCount || 0) >=
    MAX_KELOMPOK_MURID_MEMBER_COUNT
  return (
    <Modal
      {...modalProps}
      onCancel={onClose}
      title={`Tambah ${assignMode.type} Ke Kelompok`}
      width={450}
      style={{
        zIndex: 101,
      }}
    >
      <Spin spinning={isLoading}>
        <Form
          {...formProps}
          onFinish={(values) => {
            if (values.daftarPengajarPraktikId) {
              values.daftarPengajarPraktikId = [values.daftarPengajarPraktikId]
            }
            formProps.onFinish?.(values)
          }}
          // change pengajarPraktikId to array

          layout="vertical"
          validateMessages={{ required: '${label} must be filled.' }}
        >
          {assignMode.type === 'pengajar-praktik' && (
            <Form.Item
              label="Daftar Pengajar Praktik tersedia"
              name="daftarPengajarPraktikId"
              // change value to array
            >
              <Radio.Group>
                <div className="h-[400px] overflow-y-auto flex flex-col w-full">
                  {/* @ts-ignore */}
                  {data?.data?.daftarPengajarPraktik?.map((item: any) => {
                    return (
                      <Radio
                        key={item.userId}
                        value={item.userId}
                        style={{
                          marginLeft: '0px',
                          marginBottom: 8,
                          display: 'flex',
                        }}
                      >
                        {item.name} - {item.email}
                      </Radio>
                    )
                  })}
                </div>
              </Radio.Group>
            </Form.Item>
          )}
          {assignMode.type === 'murid' && (
            <Form.Item label="Daftar Murid tersedia" name="daftarMuridId">
              <Checkbox.Group>
                <div className="h-[400px] overflow-y-auto flex flex-col w-full">
                  {/* @ts-ignore */}
                  {data?.data?.daftarMurid?.map((item: any) => {
                    return (
                      <Checkbox
                        key={item.userId}
                        value={item.userId}
                        disabled={
                          hasReachMaxMurid &&
                          !currentSelectedMurid?.includes(item.userId)
                        }
                        style={{
                          marginLeft: '0px',
                          marginBottom: 8,
                          display: 'flex',
                        }}
                      >
                        {item.name} - {item.email}
                      </Checkbox>
                    )
                  })}
                </div>
              </Checkbox.Group>
            </Form.Item>
          )}
        </Form>
      </Spin>
    </Modal>
  )
}
