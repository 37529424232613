import {
  Button,
  DatePicker,
  EditButton,
  Form,
  Icons,
  Input,
  Modal,
  Show,
  Space,
  useModalForm,
  Tabs,
} from '@pankod/refine-antd'
import {
  IResourceComponentsProps,
  useCan,
  useCreate,
  useDelete,
  useGetIdentity,
  useNavigation,
  useResource,
  useShow,
  useUpdate,
} from '@pankod/refine-core'
import dayjs, { Dayjs } from 'dayjs'
import Head from 'next/head'
import React, { useEffect } from 'react'
import { useRouter } from 'next/router'

import { TCommonError, TCommonResponse } from 'src/interfaces/common'
import { PopDeleteConfirm } from '../components/PopDeleteConfirm'
import { PROGRAM_STATE, TLMSProgramDetail, TLMSProgramRequest } from './types'
import { getAvailableStatus } from './utils'
import { TabProgramDetail } from './sections/TabProgramDetail'
import { ProgramLocalTableList } from '../program-lokal/blocks/TableList'
import { TableParticipantList } from '@components/DataTableParticipants'
import { WebinarTableList } from '../webinar/blocks/TableList'
import { trackTabChange } from 'src/helpers/url'
import { showErrorNotification } from '@resources/angkatan-ppg-management/utils'
import CanvasMigrationTable from './sections/CanvasMigrationTable'
import ConfigurationButton from './sections/blocks/ConfigurationButton'
import TabNilaiAkhir from './sections/TabFinalScore'
import TabLaporanPresensi from './sections/laporan-presensi/TabLaporanPresensi'
import TabLaporanPenilaianKinerja from './sections/laporan-penilaian-kinerja/TabLaporanPenilaianKinerja'
import { useProgramStore } from './store'

const PROGRAM_RESOURCE = 'programs'

export const LMSProgramManagementShow: React.FC<IResourceComponentsProps> = ({
  options,
}) => {
  const router = useRouter()
  const { activeTab = null } = router.query || {}

  const setProgram = useProgramStore(({ setProgram }) => setProgram)
  const resource = useResource()
  const { mutateAsync: doDelete } = useDelete()
  const { mutateAsync: doCreate, isLoading: isCreateLoading } = useCreate()
  const id = resource.id as number
  const [activeKey, setActiveKey] = React.useState(
    (activeTab as string) || 'detail'
  )
  const { list } = useNavigation()

  const { queryResult } = useShow<TCommonResponse<TLMSProgramDetail>>({
    id,
    resource: PROGRAM_RESOURCE,
    dataProviderName: 'lms',
  })
  useEffect(() => {
    if (queryResult.isSuccess) {
      setProgram({
        isOwner: Boolean(queryResult.data.data.data.programOwner),
        entityId: queryResult.data.data.data.entityId,
      })
    }
  }, [
    queryResult.data?.data.data.entityId,
    queryResult.data?.data.data.programOwner,
    queryResult.isSuccess,
    setProgram,
  ])

  const {
    modalProps: editModalProps,
    formProps: editFormProps,
    show: editShow,
    submit: editSubmit,
  } = useModalForm<TLMSProgramDetail, TCommonError, TLMSProgramRequest>({
    action: 'edit',
    resource: PROGRAM_RESOURCE,
    dataProviderName: 'lms',
    autoResetForm: true,
    submit: ({ periode, ...formValues }) => {
      const [startDate, endDate] = periode as unknown as [Dayjs, Dayjs]
      return {
        name: formValues.name,
        status: formValues.status,
        startDate: startDate.startOf('day').format('YYYY-MM-DD'),
        endDate: endDate.endOf('day').format('YYYY-MM-DD'),
      }
    },
    successNotification: {
      message: 'Program berhasil diubah',
      type: 'success',
      description: 'Sukses',
    },
    errorNotification: (error) =>
      showErrorNotification(error, 'Terdapat gangguan saat mengubah program'),
    redirect: false,
  })

  const data = queryResult.data?.data.data

  const { data: identityData, isLoading } = useGetIdentity()
  const lmsRole = identityData?.user?.LMSRole || ''
  const status = getAvailableStatus(data?.status)

  const { mutateAsync, isLoading: isUpdatingStatus } = useUpdate<
    {},
    TCommonError,
    TLMSProgramRequest
  >()

  const { data: canAccessPresensiReport } = useCan({
    action: 'show',
    resource: 'lms-presensi-report',
  })

  const { data: canAccessPenilaianKinerjaReport } = useCan({
    action: 'show',
    resource: 'penilaian-kinerja-report',
  })

  const { data: canAccessWebinar } = useCan({
    action: 'show',
    resource: 'lms-webinar-management',
  })

  const { data: canEditProgram } = useCan({
    action: 'edit',
    resource: 'lms-program-management',
  })

  const { data: canDeleteProgram } = useCan({
    action: 'delete',
    resource: 'lms-program-management',
  })

  const { data: canConfigProgram } = useCan({
    action: 'field',
    resource: 'lms-program-management',
    params: {
      field: 'configuration',
    },
  })

  const { data: canConfigureOfflineDasus } = useCan({
    action: 'show',
    resource: 'dasus-management',
  })

  const isOwner = useProgramStore(({ isOwner }) => isOwner)

  const handleUpdateStatus = () => {
    if (!data || !status.nextState) return
    mutateAsync({
      id,
      resource: PROGRAM_RESOURCE,
      values: {
        endDate: data.endDate,
        name: data.name,
        startDate: data.startDate,
        status: status.nextState,
      },
      dataProviderName: 'lms',
      successNotification: {
        message: 'Program berhasil diperbarui',
        type: 'success',
        description: 'Sukses',
      },
      errorNotification: (err) =>
        showErrorNotification(
          err,
          'Terdapat gangguan saat memperbarui Program'
        ),
    })
  }

  const handleCreateDasusOffline = () => {
    return doCreate(
      {
        resource: `pgp-dasus/programs/${id}/sync`,
        values: {
          programId: id,
        },
        dataProviderName: 'lms',
        successNotification: {
          message: 'Program Dasus successfully created',
          type: 'success',
          description: 'Successful',
        },

        errorNotification: (err) =>
          showErrorNotification(
            err,
            'There was a problem when creating Program Dasus'
          ),
      },
      {
        onSuccess: () => {
          router.push(`/dasus-program-management/show/${id}`)
        },
      }
    )
  }

  const isAdminEntitas = !isLoading && lmsRole === 'admin-entitas'
  const showPesertaTab = !isAdminEntitas || isOwner

  return (
    <>
      <Show
        title="Program Detail"
        isLoading={queryResult.isLoading}
        headerButtons={() => (
          <Space>
            {activeKey === 'detail' && (
              <>
                {canConfigureOfflineDasus && (
                  <Button
                    disabled={isCreateLoading}
                    onClick={() => {
                      handleCreateDasusOffline()
                    }}
                  >
                    Konfigure program dasus
                  </Button>
                )}

                <EditButton
                  onClick={() => {
                    editShow(id)
                  }}
                  disabled={
                    queryResult.data?.data.data.status !==
                      PROGRAM_STATE.PREPARATION ||
                    (!canEditProgram?.can && !isOwner)
                  }
                >
                  Edit Program
                </EditButton>
                <PopDeleteConfirm
                  title={`Apakah Anda yakin ingin menghapus Program "${queryResult.data?.data?.data.name}"?`}
                  placeholder="Tulis ulang nama Program"
                  errorMessage="Nama program tidak sama"
                  disabled={
                    queryResult.data?.data.data.status !==
                      PROGRAM_STATE.PREPARATION ||
                    (!canDeleteProgram?.can && !isOwner)
                  }
                  onConfirm={() =>
                    doDelete(
                      {
                        id,
                        resource: PROGRAM_RESOURCE,
                        dataProviderName: 'lms',
                        successNotification: {
                          message: 'Program berhasil dihapus',
                          type: 'success',
                          description: 'Sukses',
                        },
                        errorNotification: (error) =>
                          showErrorNotification(
                            error,
                            'Terdapat gangguan saat menghapus program'
                          ),
                      },
                      {
                        onSuccess: () =>
                          list('lms-program-management', 'replace'),
                      }
                    )
                  }
                  okButtonProps={{
                    danger: true,
                    type: 'default',
                  }}
                  okText="Delete"
                  validateValue={queryResult.data?.data?.data.name!}
                >
                  <Button
                    danger
                    icon={<Icons.DeleteOutlined />}
                    disabled={
                      queryResult.data?.data.data.status !==
                        PROGRAM_STATE.PREPARATION ||
                      (!canDeleteProgram?.can && !isOwner)
                    }
                  >
                    Delete Program
                  </Button>
                </PopDeleteConfirm>
              </>
            )}
          </Space>
        )}
      >
        <Head>
          <title>LMS Admin | {options?.label} </title>
        </Head>
        <Tabs
          defaultActiveKey="detail"
          activeKey={activeKey}
          destroyInactiveTabPane
          onChange={(activeKey) => {
            setActiveKey(activeKey)
            trackTabChange(router, activeKey)
          }}
          tabBarExtraContent={{
            left: (canConfigProgram?.can || isOwner) && (
              <ConfigurationButton
                className="mr-5"
                programId={id}
                initialSisIdAccount={queryResult.data?.data?.data.sisAccountId}
                programName={queryResult.data?.data.data.name}
                programStatus={queryResult.data?.data.data.status}
                ppConfigStage={queryResult.data?.data.data.ppConfigStage}
                penilaianKinerjaConfigStage={
                  queryResult.data?.data.data.penilaianKinerjaConfigStage
                }
                jurnalFasilitasiConfigStage={
                  queryResult.data?.data.data.jurnalFasilitasiConfigStage
                }
                umpanBalikConfigStage={
                  queryResult.data?.data.data.umpanBalikConfigStage
                }
                timeTrackerActive={
                  queryResult.data?.data.data.timeTrackerActive
                }
              />
            ),
          }}
        >
          <Tabs.TabPane tab="Detail" key="detail">
            <TabProgramDetail
              onUpdateStatus={handleUpdateStatus}
              data={data}
              status={status}
              isUpdatingStatus={isUpdatingStatus}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Program Lokal" key="programLocal">
            <ProgramLocalTableList
              programResourceName={PROGRAM_RESOURCE}
              programId={data?.id}
              programName={data?.name}
              programStatus={data?.status}
            />
          </Tabs.TabPane>
          {showPesertaTab && (
            <Tabs.TabPane tab="Peserta" key="peserta">
              <TableParticipantList
                pageType="PROGRAM_LIST"
                programStatus={queryResult.data?.data.data.status}
              />
            </Tabs.TabPane>
          )}
          <Tabs.TabPane tab="Canvas Migration" key="canvasSync">
            <CanvasMigrationTable />
          </Tabs.TabPane>
          {(canAccessWebinar?.can || isOwner) && (
            <Tabs.TabPane tab="Webinar" key="webinar">
              <WebinarTableList />
            </Tabs.TabPane>
          )}
          {canAccessPresensiReport?.can && (
            <Tabs.TabPane tab="Laporan Presensi" key="presensiReport">
              <TabLaporanPresensi />
            </Tabs.TabPane>
          )}
          {(canAccessPenilaianKinerjaReport?.can || isOwner) && (
            <Tabs.TabPane
              tab="Laporan Penilaian Kinerja"
              key="penilaianKinerjaReport"
            >
              <TabLaporanPenilaianKinerja />
            </Tabs.TabPane>
          )}
          <Tabs.TabPane tab="Nilai Akhir" key="nilaiAkhir">
            <TabNilaiAkhir programId={queryResult.data?.data.data.id} />
          </Tabs.TabPane>
        </Tabs>
      </Show>

      <Modal
        {...editModalProps}
        title="Edit Program"
        okButtonProps={{
          onClick: async () => {
            const data = await editSubmit()
            editFormProps.onFinish(data)
          },
        }}
      >
        <Form
          {...editFormProps}
          initialValues={(() => {
            const { startDate, endDate, ...rest } =
              editFormProps?.initialValues?.data || {}
            const periode = [dayjs(startDate), dayjs(endDate)]

            return {
              ...rest,
              periode,
            }
          })()}
          layout="vertical"
          validateMessages={{ required: '${label} must be filled.' }}
        >
          <Form.Item
            label="Name"
            name="name"
            required
            rules={[{ required: true }]}
          >
            <Input autoComplete="off" />
          </Form.Item>
          <Form.Item
            label="Periode"
            name="periode"
            rules={[{ required: true }]}
          >
            <DatePicker.RangePicker />
          </Form.Item>
          <Form.Item name="status" hidden>
            <Input value={PROGRAM_STATE.PREPARATION} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}
