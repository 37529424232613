import { Button, Space } from '@pankod/refine-antd'
import clsx from 'clsx'
import { Canvas, FabricObject } from 'fabric'
import { Grab, Hand, Minus, Plus } from 'lucide-react'
import React from 'react'

type CanvasFooterProps = {
  targetWrapperId: string
  // TODO: better debounce this
  initialScale?: number
  initialPan?: { x: number; y: number }
  onPan?: () => void
  canvas?: Canvas | null
  className?: string
  defaultPanEnabled?: boolean
}

export const CanvasFooter = (props: CanvasFooterProps) => {
  const {
    canvas,
    targetWrapperId,
    onPan,
    initialScale,
    initialPan,
    className,
    defaultPanEnabled,
  } = props
  const INITIAL_SCALE = initialScale ?? 0.7
  const SCALE_STEP = 0.1
  const [defaultZoom, setDefaultZoom] = React.useState(INITIAL_SCALE)
  const [togglePan, setTogglePan] = React.useState(defaultPanEnabled || false)
  const [isGrabbing, setGrabbing] = React.useState(false)

  React.useEffect(() => {
    setDefaultZoom(INITIAL_SCALE)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [INITIAL_SCALE])

  // init fit
  React.useEffect(() => {
    const canvasWrapper = document.getElementById(targetWrapperId)

    if (canvasWrapper) {
      if (!!initialPan) {
        canvasWrapper.style.transform = `scale(${defaultZoom}) translate(${initialPan?.x}px, ${initialPan?.y}px)`
      } else {
        canvasWrapper.style.transform = `scale(${defaultZoom})`
      }
      canvasWrapper.style.transition = 'transform 0.5s'
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultZoom])

  // init pan
  React.useEffect(() => {
    const canvasWrapper = document.getElementById(targetWrapperId)
    let currentX = initialPan?.x ?? 0
    let currentY = initialPan?.y ?? 0
    if (togglePan && canvasWrapper) {
      let allCanvasObj: FabricObject[] | null = null
      if (canvas) {
        allCanvasObj = canvas.getObjects()
        allCanvasObj.forEach((obj) => {
          obj.set({
            selectable: false,
            selected: false,
            isLocked: true,
          })
        })
      }

      const onMouseDown = (e: any) => {
        let lastX = e.clientX
        let lastY = e.clientY
        setGrabbing(true)

        const onMouseMove = (e: any) => {
          const deltaX = e.clientX - lastX
          const deltaY = e.clientY - lastY
          lastX = e.clientX
          lastY = e.clientY

          currentX += deltaX / defaultZoom
          currentY += deltaY / defaultZoom

          canvasWrapper.style.transform = `scale(${defaultZoom}) translate(${currentX}px, ${currentY}px)`
          canvasWrapper.style.transition = 'none'
          onPan?.()
          if (canvas) {
            canvas.selection = false
          }
        }

        const onMouseUp = () => {
          setGrabbing(false)
          if (canvas) {
            canvas.selection = true
          }
          document.removeEventListener('mousemove', onMouseMove)
          document.removeEventListener('mouseup', onMouseUp)
        }

        document.addEventListener('mousemove', onMouseMove)
        document.addEventListener('mouseup', onMouseUp)
      }

      canvasWrapper.addEventListener('mousedown', onMouseDown)

      return () => {
        canvasWrapper.removeEventListener('mousedown', onMouseDown)

        allCanvasObj &&
          allCanvasObj.forEach((obj) => {
            obj.set({
              selectable: true,
              isLocked: false,
            })
          })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canvas, togglePan, defaultZoom])

  return (
    <div
      className={clsx(
        'absolute bottom-7 m-2 p-2 rounded bg-white shadow z-10 select-none',
        togglePan && 'peer canvas-grab',
        isGrabbing && 'peer canvas-grabbing',
        className
      )}
    >
      <Space direction="horizontal">
        <Button
          icon={togglePan ? <Grab /> : <Hand />}
          onClick={() =>
            setTogglePan((state) => {
              if (!state) {
                canvas?.discardActiveObject()
              }
              return !state
            })
          }
          type={togglePan ? 'primary' : 'default'}
        />
        <Button
          icon={<Minus className="h-5 w-5 mt-[3px]" />}
          onClick={() => setDefaultZoom(defaultZoom - SCALE_STEP)}
        />
        <span className="text-black">{Math.ceil(defaultZoom * 100)}%</span>
        <Button
          icon={<Plus className="h-5 w-5 mt-[3px]" />}
          onClick={() => setDefaultZoom(defaultZoom + SCALE_STEP)}
        />
      </Space>
    </div>
  )
}
