import {
  Button,
  Icons,
  Modal,
  Space,
  Table,
  TextField,
  useTable,
} from '@pankod/refine-antd'
import {
  CrudFilters,
  useCreate,
  useDelete,
  useInvalidate,
} from '@pankod/refine-core'
import React from 'react'
import { useRouter } from 'next/router'

import { TLMSParticipant, TBulkParticipantPayload } from './types'
import { TFilterForm } from '@components/ContentFilter'
import { TCommonError } from 'src/interfaces/common'
import { SideFilterParticipantForm } from './blocks/SideFilterParticipantForm'
import { WithParticipantFilterContext } from 'src/hooks/context/useParticipantFilterOptionsContext'
import { kapabilitasMapper, kapabilitasTags } from './blocks/utils'
import { arrayParticipantRoleMapper } from './utils'
import { PopDeleteConfirm } from '@components/PopDeleteConfirm'
import { showErrorNotification } from '@resources/angkatan-ppg-management/utils'

const { PlusOutlined, DeleteOutlined } = Icons

type TTableParticipantListProps = {
  pageType?: string
  disableAllocation?: boolean
}

// TODO: MAKE THIS REUSABLE for other pages
export const TableParticipantAllocation = ({
  pageType,
  disableAllocation = false,
}: TTableParticipantListProps) => {
  // const { onFinish } = useForm()
  const router = useRouter()
  // TODO: GET ACTUAL PROGRAM ID
  const { programId = '', id, programLocalId = null } = router.query || {}
  const [showParticipantRoleModal, setShowParticipantRoleModal] =
    React.useState(false)
  const localProgramId = programLocalId || id
  const invalidate = useInvalidate()
  const allocUrl = !programLocalId
    ? 'participants'
    : `classes/${id}/participants/unassigned`

  const [selectedUser, setSelectedUser] =
    React.useState<TBulkParticipantPayload>([])
  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([])
  const { tableProps, searchFormProps } = useTable<
    TLMSParticipant,
    TCommonError,
    TFilterForm<any>
  >({
    resource: `programs/${programId}/local-programs/${localProgramId}/${allocUrl}`,
    dataProviderName: 'lms',
    permanentFilter:
      pageType !== 'PROGRAM_LOKAL_KELAS_LIST'
        ? [
            {
              field: 'assigned',
              operator: 'eq',
              value: false,
            },
          ]
        : [],

    onSearch: (params) => {
      const filters: CrudFilters = []
      const formParams = params
      const { searchMode = 'name' } = params

      const constructFilters = (key: string, value: any) => {
        if (key === 'searchMode') return

        if (key === 'searchQuery') {
          filters.push({
            field: searchMode === 'name' ? 'email' : 'name',
            operator: 'eq',
            value: '',
          })

          filters.push({
            field: searchMode,
            operator: 'eq',
            value,
          })

          return
        }

        filters.push({
          field: key,
          operator: 'eq',
          value,
        })
      }

      Object.entries(formParams).forEach(([key, value]) => {
        constructFilters(key, value)
      })

      return filters
    },
  })

  const { mutate: createParticipant } = useCreate()
  const { mutateAsync: doDelete } = useDelete()

  // rowSelection objects indicates the need for row selection
  const rowSelection: any = {
    onChange: (selectedRowKeys: string[], selectedRow: TLMSParticipant[]) => {
      const selectedUserData: TBulkParticipantPayload = selectedRow.map(
        (item: TLMSParticipant) => ({
          email: item.email,
          name: item.name,
          userId: item.userId || '',
          kapabilitas: arrayParticipantRoleMapper(item.kapabilitas),
          roleKelas: arrayParticipantRoleMapper(item.kapabilitas),
        })
      )
      setSelectedRowKeys(selectedRowKeys)
      setSelectedUser(selectedUserData)
    },
    selectedRowKeys,
  }
  const generateDataKeyColumn = (data: any) => {
    const keyColumn = data?.map((item: TLMSParticipant) => {
      return {
        key: item.email,
        ...item,
      }
    })
    return keyColumn || []
  }

  const handleAddParticipant = (record?: TLMSParticipant) => {
    const payload = record
      ? [
          {
            email: record.email,
            name: record.name,
            userId: record.userId || '',
          },
        ]
      : selectedUser.map((item) => {
          return {
            email: item.email,
            name: item.name,
            userId: item.userId || '',
          }
        })

    createParticipant(
      {
        resource: `programs/${programId}/local-programs/${localProgramId}/participants`,
        dataProviderName: 'lms',
        values: {
          users: payload,
        },
        successNotification: (e: any) => {
          // TODO: if bulk, show success notification for each participant

          return {
            message: 'Success',
            description: e.data.message,
            type: 'success',
          }
        },
        errorNotification: (e: any) => {
          const responseErr = e?.response?.data?.error?.message || ''

          return {
            message: 'Error assigning participant',
            description: responseErr,
            type: 'error',
          }
        },
      },
      {
        onSuccess: () => {
          setSelectedUser([])
          setSelectedRowKeys([])
        },
      }
    )
  }

  const handleSubmitParticipantToClass = () => {
    const payload = selectedUser.map((item) => {
      return {
        email: item.email,
        name: item.name,
        userId: item.userId || '',
      }
    })

    createParticipant(
      {
        resource: `programs/${programId}/local-programs/${localProgramId}/classes/${id}/participants`,
        dataProviderName: 'lms',
        values: {
          users: payload,
        },
        successNotification: () => {
          setShowParticipantRoleModal(false)
          setSelectedUser([])
          return {
            message: 'Peserta berhasil dialokasikan',
            description: 'Sukses',
            type: 'success',
          }
        },
        errorNotification: (e) =>
          showErrorNotification(
            e,
            'Terdapat gangguan saat melakukan alokasi peserta'
          ),
      },
      {
        onSettled: () => {
          invalidate({
            invalidates: ['list'],
            resource: `programs/${programId}/local-programs/${localProgramId}/${allocUrl}`,
            dataProviderName: 'lms',
          })
        },
      }
    )
  }

  const handleAssignSingleParticipantToClass = (record: TLMSParticipant) => {
    if (pageType === 'PROGRAM_LOKAL_KELAS_LIST') {
      setSelectedRowKeys([record.email])
      setSelectedUser([
        {
          email: record.email,
          name: record.name,
          userId: record.userId || '',
          kapabilitas: arrayParticipantRoleMapper(record.kapabilitas),
          roleKelas: arrayParticipantRoleMapper(record.kapabilitas),
        },
      ])

      setShowParticipantRoleModal(true)
    } else {
      handleAddParticipant(record)
    }
  }

  const handleDeleteParticipant = async (
    record: TLMSParticipant,
    programId: string
  ) => {
    const values = { userId: record.userId, programId }
    return doDelete(
      {
        dataProviderName: 'lms',
        resource: `programs/${programId}`,
        id: 'participants',
        values,
        successNotification: {
          message: 'Peserta berhasil dihapus',
          type: 'success',
          description: 'Sukses',
        },
        errorNotification: (err) =>
          showErrorNotification(
            err,
            'Terdapat gangguan saat menghapus peserta'
          ),
      },
      {
        onSuccess: () => {
          invalidate({
            invalidates: ['list'],
            resource: `programs/${programId}/local-programs/${localProgramId}/${allocUrl}`,
            dataProviderName: 'lms',
          })
        },
      }
    )
  }

  return (
    <WithParticipantFilterContext>
      <SideFilterParticipantForm
        programId={programId as string}
        formInstance={searchFormProps}
      >
        {pageType === 'PROGRAM_LOKAL_KELAS_LIST' && (
          <Modal
            title="Konfirmasi Penambahan Partisipan Kelas"
            visible={showParticipantRoleModal}
            onCancel={() => setShowParticipantRoleModal(false)}
            destroyOnClose
            width={700}
            footer={
              <Space>
                <Button
                  className="mt-4"
                  onClick={() => setShowParticipantRoleModal(false)}
                >
                  Batal
                </Button>
                <Button
                  type="primary"
                  className="mt-4"
                  onClick={handleSubmitParticipantToClass}
                >
                  Tambah Partisipan
                </Button>
              </Space>
            }
          >
            <Table
              dataSource={selectedUser}
              rowKey={'email'}
              pagination={false}
              size="small"
              bordered
              columns={[
                {
                  title: 'User ID',
                  dataIndex: 'userId',
                },
                {
                  title: 'Partisipan',
                  dataIndex: 'name',
                  render: (value, record) => (
                    <div className="flex flex-col gap-0">
                      <TextField value={value} />
                      <TextField value={record.email} type="secondary" />
                    </div>
                  ),
                },
                {
                  title: 'Role',
                  dataIndex: 'kapabilitas',
                  width: 150,
                  render: (value) => {
                    return kapabilitasMapper(value)
                  },
                },
              ]}
            />
          </Modal>
        )}
        <Table
          {...tableProps}
          rowSelection={{ ...rowSelection }}
          dataSource={generateDataKeyColumn(tableProps.dataSource)}
          footer={() =>
            selectedUser.length > 0 && (
              <Button
                type="primary"
                onClick={() => {
                  pageType === 'PROGRAM_LOKAL_KELAS_LIST'
                    ? setShowParticipantRoleModal(true)
                    : handleAddParticipant()
                }}
              >
                <PlusOutlined />
                Add Selected Participants ({selectedUser.length})
              </Button>
            )
          }
          {...(disableAllocation && {
            rowSelection: undefined,
          })}
        >
          <Table.Column
            dataIndex="userId"
            title="User ID"
            render={(value) => <TextField value={value} />}
          />
          <Table.Column
            dataIndex="email"
            title="E-mail"
            render={(value) => <TextField value={value} />}
          />
          <Table.Column
            dataIndex="name"
            title="Nama"
            render={(value) => <TextField value={value} />}
          />
          <Table.Column
            dataIndex="participantType"
            title="Tipe"
            render={(value) => <TextField value={value} />}
          />
          <Table.Column
            dataIndex="jenjang"
            title="Jenjang"
            render={(value) => <TextField value={value} />}
          />
          <Table.Column
            dataIndex="provinsi"
            title="Provinsi"
            render={(value) => <TextField value={value} />}
          />
          <Table.Column
            dataIndex="kapabilitas"
            title="Kapabilitas"
            render={kapabilitasTags}
          />
          {!disableAllocation && (
            <Table.Column
              title="Actions"
              render={(_, record: any) => (
                <Space direction="vertical">
                  <Button
                    type="primary"
                    className="italic"
                    size="small"
                    onClick={() => {
                      handleAssignSingleParticipantToClass(record)
                    }}
                    icon={<PlusOutlined />}
                    block
                  >
                    Assign
                  </Button>
                  <PopDeleteConfirm
                    title="Apakah Anda yakin ingin menghapus peserta ini?"
                    okText="Hapus"
                    cancelText="Batal"
                    okButtonProps={{ danger: true }}
                    onConfirm={() =>
                      handleDeleteParticipant(record, String(programId))
                    }
                    placement="topRight"
                  >
                    <Button danger icon={<DeleteOutlined />} block size="small">
                      Hapus
                    </Button>
                  </PopDeleteConfirm>
                </Space>
              )}
            />
          )}
        </Table>
      </SideFilterParticipantForm>
    </WithParticipantFilterContext>
  )
}
