import {
  Button,
  Form,
  Icons,
  Input,
  Table,
  TableColumnGroupType,
  TableColumnType,
  useTable,
} from '@pankod/refine-antd'
import React from 'react'
import { CrudFilters } from '@pankod/refine-core'
import dayjs from 'dayjs'

import { TLMSProgramLocal } from '@resources/lms-management/program-lokal/types'

type LocalProgramSelectProps = {
  programId?: string
  selected: { id?: string; name?: string }
  onSelect: (data: { id: string; name: string }) => void
  initialLocalProgram?: {
    id: string
    name: string
  }
  resource?: string
}
const LocalProgramSelect = ({
  programId,
  onSelect,
  selected,
  initialLocalProgram,
  resource = `programs/${programId}/local-programs`,
}: LocalProgramSelectProps) => {
  const [form] = Form.useForm()
  const originCheckboxSelected = Form.useWatch('origin', form)
  const { tableProps, searchFormProps, tableQueryResult } =
    useTable<TLMSProgramLocal>({
      resource,
      dataProviderName: 'lms',
      queryOptions: {
        enabled: !!programId,
      },
      onSearch(data: any) {
        const filters: CrudFilters = []
        filters.push(
          {
            field: 'name',
            operator: 'eq',
            value: data.query,
          },
          {
            field: 't',
            operator: 'eq',
            value: dayjs().unix(),
          }
        )
        return filters
      },
      initialPageSize: 10,
    })

  const columns: (TableColumnType<any> | TableColumnGroupType<any>)[] = [
    {
      title: 'ID',
      key: 'id',
      dataIndex: 'id',
      width: 80,
    },
    {
      title: 'Nama Program Lokal',
      key: 'name',
      dataIndex: 'name',
    },
  ]

  return (
    <>
      <Form {...searchFormProps}>
        <Form.Item name="query">
          <Input.Search
            style={{ width: '30%', minWidth: '220px' }}
            allowClear
            autoComplete="off"
            loading={tableQueryResult.isFetching}
            onSearch={() => searchFormProps?.form?.submit()}
            placeholder="Nama program lokal"
          />
        </Form.Item>
      </Form>
      <Table
        {...tableProps}
        rowSelection={{
          type: 'radio',
          onChange: (_, record) => {
            form.setFieldsValue({ origin: false })
            onSelect({ id: String(record[0].id), name: record[0].name })
          },
          selectedRowKeys: selected.id ? [selected.id] : undefined,
          columnTitle: 'Pilih',
          columnWidth: 45,
          getCheckboxProps(record) {
            return {
              name: record.name,
              disabled: originCheckboxSelected,
            }
          },
        }}
        rowKey="id"
        columns={columns}
        footer={() =>
          initialLocalProgram && (
            <Button
              icon={<Icons.SelectOutlined />}
              onClick={() => {
                onSelect(initialLocalProgram)
              }}
            >
              Gunakan Program Lokal Awal
            </Button>
          )
        }
        scroll={{ y: 400 }}
      />
    </>
  )
}

export default LocalProgramSelect
