import {
  DatePicker,
  Divider,
  Form,
  FormProps,
  Input,
  Modal,
  ModalProps,
  Switch,
} from '@pankod/refine-antd'
import React from 'react'
import locale from 'antd/lib/locale/id_ID'
import dayjs, { Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

import { FormSelectComponent } from '../../FormSelectComponent'
import { Editor } from '@components/TUIEditor'

import 'dayjs/locale/id'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Jakarta')

type ModalFormProps = {
  modalProps: ModalProps
  formProps: FormProps
  mode: 'create' | 'edit'
  onClose: () => void
}
export const ModalForm = ({
  formProps,
  modalProps,
  mode,
  onClose,
}: ModalFormProps) => {
  const isEditMode = mode === 'edit'
  const studentUpload = Form.useWatch(['studentUpload'], formProps?.form)

  return (
    <Modal
      {...modalProps}
      onCancel={onClose}
      title={
        isEditMode
          ? 'Edit Jurnal Pendampingan Individu'
          : 'Buat Jurnal Pendampingan Individu'
      }
      cancelText="Batal"
      okText="Simpan"
      afterClose={() => formProps.form?.resetFields()}
      bodyStyle={{ maxHeight: 700, overflow: 'auto' }}
    >
      <Form
        {...formProps}
        initialValues={formProps?.initialValues?.data}
        layout="vertical"
        validateMessages={{ required: '${label} harus diisi.' }}
        onFinish={({ jurnalForm, penilaianForm, studentUpload, ...data }) => {
          formProps.onFinish?.({
            ...data,
            studentUpload: studentUpload.isActive
              ? studentUpload
              : {
                  isActive: false,
                  startDate: null,
                  endDate: null,
                  description: studentUpload.description || '',
                },
            jurnalFormId: jurnalForm?.map(({ id }: { id: string }) => id),
            penilaianFormId: penilaianForm?.map(({ id }: { id: string }) => id),
          })
        }}
      >
        <Form.Item
          label="Nama Pendampingan Individu"
          name="title"
          required
          rules={[{ required: true }]}
        >
          <Input autoComplete="off" />
        </Form.Item>
        <Form.Item
          label="Deskripsi"
          name="description"
          required
          rules={[{ required: true }]}
        >
          <Editor minHeight="80px" />
        </Form.Item>
        <Form.Item
          label="Langkah-langkah Pendampingan"
          name="langkahPendampingan"
          required
          rules={[{ required: true }]}
        >
          <Editor minHeight="80px" />
        </Form.Item>
        <Form.Item
          label="Form Jurnal"
          name="jurnalForm"
          required
          rules={[{ required: true }]}
        >
          <FormSelectComponent multiple />
        </Form.Item>
        <Form.Item label="Form Penilaian" name="penilaianForm">
          <FormSelectComponent
            multiple
            defaultFormType={'LIKERT'}
            formTypeOptions={[
              {
                label: 'Likert',
                value: 'LIKERT',
              },
              {
                label: 'Quantitative',
                value: 'QUANTITATIVE',
              },
            ]}
          />
        </Form.Item>
        <Divider orientation="left">Form Upload Murid</Divider>
        <Form.Item
          label="Fitur Form Upload Murid"
          name={['studentUpload', 'isActive']}
          required
          rules={[{ required: true }]}
          valuePropName="checked"
          initialValue={mode === 'create' ? false : undefined}
        >
          <Switch />
        </Form.Item>
        {studentUpload?.isActive && (
          <>
            <Form.Item
              label="Periode Waktu Upload"
              name="studentUpload"
              getValueProps={(dates: {
                startDate: string | null
                endDate: string | null
              }) => ({
                value: dates
                  ? [
                      dates.startDate ? dayjs(dates.startDate) : undefined,
                      dates.endDate ? dayjs(dates.endDate) : undefined,
                    ]
                  : [],
              })}
              getValueFromEvent={(date) => {
                if (!date)
                  return {
                    ...studentUpload,
                    startDate: null,
                    endDate: null,
                  }
                const [startDate, endDate]: [Dayjs, Dayjs] = date
                return {
                  ...studentUpload,
                  startDate: startDate.tz().startOf('date').toISOString(),
                  endDate: endDate.tz().endOf('date').toISOString(),
                }
              }}
            >
              <DatePicker.RangePicker
                format="DD MMM YYYY"
                locale={locale.DatePicker}
                allowClear={true}
                allowEmpty={[false, false]}
              />
            </Form.Item>
            <Form.Item
              label="Deskripsi"
              name={['studentUpload', 'description']}
              required
              rules={[{ required: true }]}
            >
              <Editor minHeight="80px" />
            </Form.Item>
          </>
        )}
      </Form>
    </Modal>
  )
}
