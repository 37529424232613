import {
  Button,
  DateField,
  Drawer,
  Icons,
  Space,
  Table,
  TextField,
} from '@pankod/refine-antd'
import { HttpError, useResource } from '@pankod/refine-core'
import React, { useCallback, useState } from 'react'

import useTableWithMeta from 'src/hooks/useTableWithMeta'
import { PresensiStatistic } from '../../../types'
import KelasDrawer, { useKelasDrawer } from './KelasDrawer'

export const useAktivitasDrawer = () => {
  const [showId, setShowId] = useState<{
    show: boolean
    moduleId: string | number
    moduleName: string
  }>({ show: false, moduleId: '', moduleName: '' })
  const onClose = useCallback(() => {
    setShowId((state) => ({
      ...state,
      show: false,
    }))
  }, [])

  return {
    show: (id: number | string, moduleName: string) => {
      setShowId({ show: true, moduleId: id, moduleName })
    },
    visible: !!showId.show,
    id: showId.moduleId,
    moduleName: showId.moduleName,
    onClose,
  }
}

type AktivitasDrawerProps = {
  visible: boolean
  id: number | string
  onClose: () => void
  moduleName: string
}

const AktivitasDrawer = ({
  id,
  moduleName,
  onClose,
  visible,
}: AktivitasDrawerProps) => {
  const { id: programId } = useResource()
  const { tableProps, tableQueryResult } = useTableWithMeta<
    PresensiStatistic,
    HttpError,
    unknown,
    { lastUpdatedAt: string }
  >({
    dataProviderName: 'lms',
    resource: `programs/${programId}/presensi-murid/program-modules/${id}/aktivitas`,
    queryOptions: {
      enabled: !!programId && !!id,
    },
  })
  const { show, ...kelasDrawerProps } = useKelasDrawer()
  const handleClose = () => {
    onClose()
  }
  const lastUpdatedAt = tableQueryResult?.data?.meta?.lastUpdatedAt
  return (
    <Drawer
      title={`Daftar Aktivitas | Modul: ${moduleName}`}
      visible={visible}
      onClose={handleClose}
      width={'70%'}
      destroyOnClose
    >
      <Table
        {...tableProps}
        rowKey="id"
        footer={() =>
          lastUpdatedAt && (
            <TextField
              italic
              value={
                <div className="flex gap-2 items-center">
                  Diperbarui pada:
                  <DateField
                    value={lastUpdatedAt}
                    format="DD MMM YYYY HH:mm:ss"
                  />
                </div>
              }
            />
          )
        }
        columns={[
          { title: 'Nama Aktivitas', dataIndex: 'nama' },
          {
            title: 'Statistik',
            children: [
              {
                title: (
                  <Space>
                    <Icons.CheckCircleOutlined />
                    <TextField value="Hadir" />
                  </Space>
                ),
                dataIndex: ['statistik', 'hadir'],
                align: 'center',
              },
              {
                title: (
                  <Space>
                    <Icons.ClockCircleOutlined />
                    <TextField value="Terlambat" />
                  </Space>
                ),
                dataIndex: ['statistik', 'terlambat'],
                align: 'center',
              },
              {
                title: (
                  <Space>
                    <Icons.CloseCircleOutlined />
                    <TextField value="Absen" />
                  </Space>
                ),
                dataIndex: ['statistik', 'alpha'],
                align: 'center',
              },
              {
                title: (
                  <Space>
                    <Icons.WarningOutlined />
                    <TextField value="Belum Diisi" />
                  </Space>
                ),
                dataIndex: ['statistik', 'kosong'],
                align: 'center',
              },
            ],
          },
          {
            title: 'Laporan Kelas',
            align: 'center',
            render: (_, record) => (
              <Button
                icon={<Icons.UnorderedListOutlined />}
                onClick={() => {
                  show(record.id, record.nama)
                }}
              >
                Lihat Daftar Kelas
              </Button>
            ),
          },
        ]}
        bordered
      />
      <KelasDrawer
        {...kelasDrawerProps}
        moduleId={id}
        key={kelasDrawerProps.id}
      />
    </Drawer>
  )
}

export default AktivitasDrawer
