import { Form, FormProps, Input, Modal, ModalProps } from '@pankod/refine-antd'
import React from 'react'

type ModalDasusClassFormProps = {
  modalProps: ModalProps
  formProps: FormProps
  mode: 'create' | 'edit'
  programName?: string
  onClose: () => void
}
export const ModalDasusClassForm = ({
  modalProps,
  formProps,
  mode,
  onClose,
}: ModalDasusClassFormProps) => {
  return (
    <Modal
      {...modalProps}
      onCancel={onClose}
      title={mode === 'create' ? 'Create New Kelas' : 'Edit Kelas'}
    >
      <Form
        {...formProps}
        layout="vertical"
        validateMessages={{ required: '${label} must be filled.' }}
        initialValues={(() => {
          const { entity, ...rest } = formProps?.initialValues?.data || {}
          return {
            ...rest,
            entityId: entity?.id,
          }
        })()}
      >
        <Form.Item
          label="Kelas Name"
          name="name"
          required
          rules={[{ required: true }]}
        >
          <Input autoComplete="off" />
        </Form.Item>
      </Form>
    </Modal>
  )
}
