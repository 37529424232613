import React from 'react'
import {
  TableColumnType,
  TableColumnGroupType,
  Table,
  Button,
  Icons,
  useTable,
  // Tag,
  Descriptions,
  Card,
  useForm,
  Form,
  Popconfirm,
  InputNumber,
} from '@pankod/refine-antd'
import { useCreate, useResource } from '@pankod/refine-core'
import Link from 'next/link'

import { HeaderTitle } from '@components/HeaderTableTitle'
import { TableTitle } from '@components/TableTitle'
import { TCommonError } from 'src/interfaces/common'
import { CmsPpgSimpkbSyncQueue } from '@apis/model/types'
import { showErrorNotification } from '@resources/angkatan-ppg-management/utils'

type SimpkbTabProps = {
  tahun?: number
  gelombang?: number
  angkatanId: number
  canSync?: boolean
  refetchAngkatan: () => void
}

export const SimpkbTab = (props: SimpkbTabProps) => {
  const { tahun, gelombang, angkatanId, canSync, refetchAngkatan } = props

  const resource = useResource()
  const id = resource.id as number

  const MAP_AVAILABLE_STATUS = [
    'DONE_TAGGING',
    'CREATING_USER',
    'FAILED_CREATING_USER',
    'DONE_CREATING_USER',
    'SYNCING',
    'FAILED_SYNCING',
    'DONE_SYNCING',
    'SKIPPED',
  ]

  // TODO: Change this later
  const { tableProps, tableQueryResult } = useTable<
    CmsPpgSimpkbSyncQueue,
    TCommonError
  >({
    dataProviderName: 'lms',
    resource: `simpkb/sync-queues`,
    permanentFilter: [
      {
        field: 'angkatanId',
        operator: 'eq',
        value: id,
      },
    ],

    errorNotification: (err) =>
      showErrorNotification(err, 'Terjadi Kesalahan ketika memuat data'),
  })

  const { mutateAsync: doSync } = useCreate()

  const columns: (
    | TableColumnType<CmsPpgSimpkbSyncQueue>
    | TableColumnGroupType<CmsPpgSimpkbSyncQueue>
  )[] = [
    {
      title: <HeaderTitle align="left" title="id" />,
      key: 'id',
      dataIndex: 'id',
    },
    {
      title: <HeaderTitle align="left" title="Date" />,
      key: 'startedDate',
      dataIndex: 'startedDate',
    },
    {
      title: <HeaderTitle align="left" title="User" />,
      key: 'startedBy',
      dataIndex: 'startedBy',
    },
    {
      title: <HeaderTitle align="left" title="Start" />,
      key: 'startedTime',
      dataIndex: 'startedTime',
    },
    {
      title: <HeaderTitle align="left" title="Status" />,
      key: 'status',
      dataIndex: 'status',
    },
    {
      title: <HeaderTitle align="left" title="Questionable Data" />,
      key: 'failedReason',
      dataIndex: 'failedReason',
      render: (_, record) => {
        if (MAP_AVAILABLE_STATUS.includes(record.status as string)) {
          return (
            <Link
              href={`/ppg-lptk-simpkb-sync-confirmation-detail/show/${record.id}?angkatanId=${angkatanId}`}
            >
              <a>See Details</a>
            </Link>
          )
        }
        return null
      },
    },
    // {
    //   key: 'action',
    //   render: (_, record) => <h2>asdasd</h2>,
    // },
  ]

  const { formProps: gelombangUpdateForm, onFinish: gelombangFormOnFinish } =
    useForm({
      action: 'create',
      resource: `angkatan/${angkatanId}/gelombang`,
      dataProviderName: 'lms',
      redirect: false,
      metaData: {
        httpMethod: 'patch',
      },
      onMutationSuccess() {
        refetchAngkatan()
      },

      errorNotification: (err) =>
        showErrorNotification(
          err,
          'Terjadi Kesalahan melakukan update gelombang'
        ),
    })

  const isTahunGelombangSet = (tahun as number) > 0 || (gelombang as number) > 0

  return (
    <>
      {isTahunGelombangSet ? (
        <Descriptions bordered column={1} className="mb-9">
          <Descriptions.Item label="Tahun">{tahun}</Descriptions.Item>
          <Descriptions.Item label="Gelombang">{gelombang}</Descriptions.Item>
        </Descriptions>
      ) : (
        <div className="mb-9">
          <Card>
            <div className="flex flex-col items-center mb-4">
              <h2>Setup Tahun dan Gelombang Angkatan</h2>
            </div>
            <Form
              {...gelombangUpdateForm}
              onFinish={gelombangFormOnFinish}
              layout="horizontal"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 8 }}
            >
              <Form.Item
                label="Tahun"
                name="tahun"
                rules={[{ required: true }]}
              >
                <InputNumber
                  placeholder="Tahun SIMPKB"
                  min={1}
                  controls={false}
                  precision={0}
                  style={{ width: '250px' }}
                />
              </Form.Item>
              <Form.Item
                label="Gelombang"
                name="gelombang"
                rules={[{ required: true }]}
              >
                <InputNumber
                  placeholder="Gelombang (1-100)"
                  min={1}
                  max={100}
                  controls={false}
                  precision={0}
                  style={{ width: '250px' }}
                />
              </Form.Item>
              <div className="flex flex-col items-center">
                <p className="text-xs text-surface-informational-default">
                  mohon pastikan data yang di setup sudah benar, data yang
                  diinput tidak bisa diubah lagi
                </p>
                <Popconfirm
                  title="Apakah Anda yakin ingin kembali telah memasukan data yang sesuai ? data yang sudah dimasukkan tidak dapat diubah lagi"
                  key="back"
                  onConfirm={() => {
                    gelombangUpdateForm.form?.submit()
                  }}
                  okText="Ya"
                  okButtonProps={{ style: { width: '50px' } }}
                  cancelText="Tidak"
                  placement="bottom"
                >
                  <Button>Submit Data Tahun dan Gelombang</Button>
                </Popconfirm>
              </div>
            </Form>
          </Card>
        </div>
      )}

      <Table
        {...tableProps}
        loading={tableQueryResult.isFetching}
        columns={columns}
        bordered
        // rowKey="id"
        title={() => (
          <TableTitle
            title="Pipeline Sinkronisasi"
            extra={
              <Button
                icon={<Icons.SyncOutlined />}
                type="primary"
                onClick={() => {
                  doSync({
                    resource: `simpkb/sync-queues`,
                    dataProviderName: 'lms',
                    values: {
                      angkatanId: id,
                    },
                    successNotification: () => {
                      refetchAngkatan()

                      // TODO: change this later
                      return {
                        message: 'Sync Started',
                        type: 'success',
                        description: 'Successful',
                      }
                    },

                    errorNotification: (err) =>
                      showErrorNotification(
                        err,
                        'Terjadi Kesalahan ketika melakukan sync data'
                      ),
                  })
                }}
                disabled={!canSync}
              >
                Sinkronisasi Data SIMPKB
              </Button>
            }
          />
        )}
      />
    </>
  )
}
