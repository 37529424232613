import { TimeZoneDifference, TimeZoneOption } from './types'

export const TIMEZONE_OPTIONS: TimeZoneOption[] = [
  {
    label: 'WIB',
    value: 'wib',
  },
  {
    label: 'WITA',
    value: 'wita',
  },
  {
    label: 'WIT',
    value: 'wit',
  },
]

export const TIMEZONE_DIFFERENCE_MAP: TimeZoneDifference = {
  wib: {
    wit: 2,
    wita: 1,
  },
  wita: {
    wit: 1,
    wib: -1,
  },
  wit: {
    wib: -2,
    wita: -1,
  },
}
