import {
  Button,
  Form,
  Icons,
  Input,
  List,
  Modal,
  ShowButton,
  Space,
  Table,
  TextField,
  useModalForm,
  useTable,
} from '@pankod/refine-antd'
import {
  IResourceComponentsProps,
  GetListResponse,
  CrudFilters,
  useDelete,
} from '@pankod/refine-core'
import React from 'react'
import Head from 'next/head'

import Filter, { TFilterForm } from '@components/ContentFilter'
import { TCommonError } from 'src/interfaces/common'
import { TLMSEntity, TLMSEntityRequest } from './types'
import { PopDeleteConfirm } from '../components/PopDeleteConfirm'

const ENTITIES_RESOURCE = 'entities'

export const LMSEntityManagementList: React.FC<
  IResourceComponentsProps<GetListResponse<any>>
> = ({ options }) => {
  const { tableProps, searchFormProps } = useTable<
    TLMSEntity,
    TCommonError,
    TFilterForm
  >({
    resource: ENTITIES_RESOURCE,
    dataProviderName: 'lms',
    queryOptions: {
      initialData: {
        data: [
          // mock user data
          {
            id: 1,
            name: 'Guru Jabar',
            slug: 'guru-jabar',
          },
          {
            id: 2,
            name: 'Guru Jateng',
            slug: 'guru-jateng',
          },
        ],
        total: 0,
      },
    },
    onSearch: (params) => {
      const filters: CrudFilters = []
      const { keyword } = params
      filters.push(
        {
          field: 'keyword',
          operator: 'eq',
          value: keyword,
        },
        {
          field: 't',
          operator: 'eq',
          value: new Date().getTime(),
        }
      )
      return filters
    },
    syncWithLocation: true,
  })

  const {
    modalProps: createModalProps,
    formProps: createFormProps,
    show: createShow,
    close: createClose,
  } = useModalForm<TLMSEntity, TCommonError, TLMSEntityRequest>({
    action: 'create',
    dataProviderName: 'lms',
    resource: ENTITIES_RESOURCE,
    redirect: false,
  })

  const { mutateAsync } = useDelete()

  return (
    <>
      <List
        title="LMS Entitas Management"
        createButtonProps={{
          children: 'Create New Entitas',
          onClick: () => {
            createShow()
          },
        }}
      >
        <Head>
          <title>LMS Admin | {options?.label}</title>
        </Head>

        <Filter
          formProps={searchFormProps}
          items={[
            {
              label: 'Entitas',
              name: 'keyword',
              placeholder: 'Search entitas name',
            },
          ]}
        />

        <Table {...tableProps} rowKey="id">
          <Table.Column
            dataIndex="id"
            title="ID"
            render={(value) => <TextField value={value} />}
          />
          <Table.Column
            dataIndex="slug"
            title="Slug"
            render={(value) => <TextField value={value} />}
          />
          <Table.Column
            dataIndex="name"
            title="Name"
            render={(value) => <TextField value={value} />}
          />
          <Table.Column<TLMSEntity>
            title="Actions"
            render={(_, record) => (
              <Space>
                <ShowButton size="small" recordItemId={record.id} />
                <PopDeleteConfirm
                  title={`Are you sure want to delete Entitas "${record.name}"?`}
                  placeholder="Tulis ulang nama Entitas"
                  onConfirm={() =>
                    mutateAsync({
                      id: record.id,
                      resource: ENTITIES_RESOURCE,
                      dataProviderName: 'lms',
                    })
                  }
                  okButtonProps={{
                    danger: true,
                    type: 'default',
                  }}
                  okText="Delete"
                  validateValue={record.name}
                  errorMessage="Entitas name did not match"
                >
                  <Button size="small" danger icon={<Icons.DeleteOutlined />}>
                    Delete
                  </Button>
                </PopDeleteConfirm>
              </Space>
            )}
          />
        </Table>
      </List>

      <Modal
        {...createModalProps}
        title="Create New Entitas"
        onCancel={() => {
          createClose()
          createFormProps.form.resetFields()
        }}
      >
        <Form
          {...createFormProps}
          layout="vertical"
          validateMessages={{ required: '${label} must be filled.' }}
        >
          <Form.Item
            label="Entitas Name"
            name="name"
            required
            rules={[{ required: true }]}
          >
            <Input autoComplete="off" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}
