import {
  Button,
  EditButton,
  Form,
  Icons,
  Input,
  Space,
  Table,
  TextField,
  useModalForm,
  useTable,
} from '@pankod/refine-antd'
import { useCustomMutation, useList, useInvalidate } from '@pankod/refine-core'
import { useEffect, useMemo } from 'react'

import { ModalFormInstruktur } from './ModalFormInstruktur'
import ModalParseCSV from '@components/ModalParseCSV'
import useGetACL from 'src/hooks/useGetACL'
import { downloadTemplate } from '@components/ModalParseCSV/generateCSVTemplate'
import { showErrorNotification } from '@resources/angkatan-ppg-management/utils'
// import { PopDeleteConfirm } from '@resources/lms-management/components/PopDeleteConfirm'

type InstrukturProps = {
  lptkId: string | number
  isMasterData?: boolean
  enableFilter?: boolean
}

export const Instruktur = (props: InstrukturProps) => {
  const { lptkId, isMasterData, enableFilter } = props
  const { mutate } = useCustomMutation()

  const invalidate = useInvalidate()

  const [, , lmsRole] = useGetACL()
  const { tableProps, setFilters } = useTable({
    syncWithLocation: true,
    resource: `lptk/${lptkId}/instruktur`,
    dataProviderName: 'lms',
  })

  useEffect(() => {
    setFilters([
      {
        field: 'email',
        operator: 'eq',
        value: '',
      },
    ])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const {
    modalProps: createModalProps,
    formProps: createFormProps,
    submit: createSubmit,
    show: createShow,
  } = useModalForm({
    action: 'create',
    resource: `lptk/${lptkId}/instruktur`,
    submit: (formValue) => {
      return {
        instruktur: [formValue],
      }
    },

    errorNotification: (err) =>
      showErrorNotification(err, 'There was an error when adding instruktur'),

    onMutationSuccess: () => {
      invalidate({
        resource: `lptk/${lptkId}/instruktur`,
        dataProviderName: 'lms',
        invalidates: ['list'],
      })
    },
    dataProviderName: 'lms',
    autoResetForm: true,
    redirect: false,
  })

  const {
    modalProps: editModalProps,
    formProps: editFormProps,
    show: editShow,
  } = useModalForm({
    action: 'edit',
    resource: `lptk/${lptkId}/instruktur`,

    errorNotification: (err) =>
      showErrorNotification(err, 'There was an error when updating instruktur'),

    onMutationSuccess: () => {
      invalidate({
        resource: `lptk/${lptkId}/instruktur`,
        dataProviderName: 'lms',
        invalidates: ['list'],
      })
    },
    dataProviderName: 'lms',
    autoResetForm: true,
    redirect: false,
  })

  const {
    modalProps: bulkCreateModalProps,
    formProps: bulkCreateFormProps,
    submit: bulkCreateSubmit,
    show: bulkCreateShow,
    close: bulkCreateClose,
  } = useModalForm({
    action: 'create',
    resource: `lptk/${lptkId}/instruktur`,
    submit: (formValue) => {
      return {
        instruktur: formValue.data,
      }
    },

    errorNotification: (err) =>
      showErrorNotification(
        err,
        'There was an error when bulk adding instruktur'
      ),

    onMutationSuccess: () => {
      invalidate({
        resource: `lptk/${lptkId}/instruktur`,
        dataProviderName: 'lms',
        invalidates: ['list'],
      })
    },
    dataProviderName: 'lms',
    autoResetForm: true,
    redirect: false,
  })

  const { data: studyFieldList } = useList({
    resource: 'kv?key=lms_ppg_study_field',
    dataProviderName: 'microlearning',
    queryOptions: {
      enabled: !!bulkCreateModalProps.visible,
    },
  })

  const studyFieldMap = useMemo(() => {
    const { data } = studyFieldList || {}
    const studyFieldMap = new Map()

    data?.forEach((item: any) => {
      studyFieldMap.set(item.label, item.value)
    })

    return studyFieldMap
  }, [studyFieldList])

  const handleDownloadData = async () => {
    await mutate({
      // @ts-ignore hack to mutate get
      method: 'get',
      url: `${process.env.LMS_API_URL}/lptk/${lptkId}/instruktur/download`,
      dataProviderName: 'lms',
      successNotification(data: any) {
        const dateDDMMYYYY = new Date().toLocaleDateString('id-ID', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        })

        const formatDate = dateDDMMYYYY.split('/').join('-')

        data &&
          downloadTemplate(
            data?.data as string,
            `instruktur_${formatDate}`,
            String(lptkId)
          )

        return {
          message: 'Memulai Download Data',
          description: 'Memulai proses download',
          type: 'success',
        }
      },
    })
  }
  // create map of data

  // const { mutateAsync } = useDelete()

  return (
    <>
      <div title={'List Guru instruktur'}>
        <div className="flex flex-row w-full justify-between items-center gap-2 mb-4">
          <div>
            <div>
              {enableFilter && (
                <Form layout="inline">
                  <Form.Item name="email">
                    <Input
                      prefix={<Icons.SearchOutlined className="mr-4" />}
                      onPressEnter={(e) => {
                        setFilters([
                          {
                            field: 'email',
                            operator: 'eq',
                            // @ts-ignore
                            value: e.target.value,
                          },
                        ])
                      }}
                      placeholder="Cari nama atau email"
                      autoComplete="off"
                      allowClear
                    />
                  </Form.Item>
                </Form>
              )}
            </div>
          </div>
          <div className="flex gap-2">
            <Button onClick={bulkCreateShow}>Unggah CSV</Button>
            <Button onClick={createShow}>Tambah instruktur</Button>

            {lmsRole === 'admin-lptk' && (
              <Button onClick={handleDownloadData} className="ml-4">
                Download Data
              </Button>
            )}
          </div>
        </div>
        <Table
          {...tableProps}
          rowKey="id"
          pagination={{
            ...tableProps.pagination,
            showSizeChanger: true,
          }}
        >
          <Table.Column
            dataIndex="id"
            title="ID"
            render={(value) => <TextField value={value} />}
          />
          <Table.Column
            dataIndex="name"
            title="Nama"
            render={(value) => <TextField value={value} />}
          />
          <Table.Column
            dataIndex="email"
            title="Email"
            render={(value) => <TextField value={value} />}
          />
          {!isMasterData && (
            <Table.Column
              dataIndex="encodedId"
              title="encoded ID"
              render={(value) => <TextField value={value} />}
            />
          )}
          <Table.Column
            dataIndex="phone"
            title="No. Hp"
            render={(value) => <TextField value={value} />}
          />
          <Table.Column
            dataIndex="studyField"
            title="Bidang Studi"
            render={(value) => <TextField value={value} />}
          />
          <Table.Column
            dataIndex="nidn"
            title="NIDN / NIDK"
            render={(value) => <TextField value={value} />}
          />
          <Table.Column
            dataIndex="category"
            title="Kategori"
            render={(value) => <TextField value={value} />}
          />

          <Table.Column
            title="Actions"
            render={(_, record: any) => (
              <Space direction="vertical">
                <EditButton
                  size="small"
                  block
                  onClick={() => editShow(record.id)}
                />
              </Space>
            )}
          />
        </Table>
      </div>
      <ModalFormInstruktur
        modalProps={createModalProps}
        formProps={createFormProps}
        mode="create"
        onSubmit={createSubmit}
        lptkId={lptkId}
      />

      <ModalFormInstruktur
        modalProps={editModalProps}
        formProps={editFormProps}
        mode="edit"
        lptkId={lptkId}
      />

      <ModalParseCSV
        modalProps={bulkCreateModalProps}
        formProps={bulkCreateFormProps}
        onClose={bulkCreateClose}
        onSubmit={bulkCreateSubmit}
        validCSVHeaders={[
          'name',
          'email',
          'phone',
          'studyField',
          {
            name: 'nidn',
            optional: true,
          },
          'category',
        ]}
        dataIndex="instruktur"
        presetValidate={[
          {
            name: 'studyField',
            mapEntry: studyFieldMap,
          },
        ]}
      />
    </>
  )
}
