import { Form, FormProps, Input, Modal, ModalProps } from '@pankod/refine-antd'
import React from 'react'

type TModalFormProps = {
  modalProps: ModalProps
  formProps: FormProps
  programName?: string
  onClose: () => void
}
export const ModalFormCreateKelompokKelas = ({
  modalProps,
  formProps,
  onClose,
}: TModalFormProps) => {
  return (
    <Modal
      {...modalProps}
      onCancel={onClose}
      title="Tambah Kelompok Murid baru"
    >
      <Form
        {...formProps}
        layout="vertical"
        validateMessages={{ required: '${label} must be filled.' }}
      >
        <Form.Item
          label="Nama Kelompok Murid"
          name="name"
          required
          rules={[{ required: true }]}
        >
          <Input autoComplete="off" />
        </Form.Item>
      </Form>
    </Modal>
  )
}
